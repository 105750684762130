import TimeRow from '../Model/TimePeriodRowM';
import TimeSearchRowM from '../Model/TimeSearchRowM';
import ApprovalLine from '../Approver/ApprovalLine';
import ApproverCellDetailLine from '../Approver/ApproverCellDetailLine';
// import { isNullOrUndefined } from 'util';
import EDays from '../Model/EDays';
import EIntDays from '../Model/EIntDays';
import Period from '../Model/Period';
import GetAllPeriodsAsAdminPeriodsDto from '../Model/AdminPeriodsDto';
import { TimeState } from '../Model/State';
import { WorkDay } from '../Workaround/TimeApiWorkaround';
import { isNullOrUndefined } from '../helpers/isNullOrUndefined';
import { TimeEntry, TimeSearchRow } from '../api/generated/data-contracts';

export const daysStringArr: string[] = [EDays.Monday, EDays.Tuesday, EDays.Wednesday, EDays.Thursday, EDays.Friday, EDays.Saturday, EDays.Sunday];

export const WorkDaysTypes = (workDays: any[]) => {
    const mapped: WorkDay[] = [];

    if (workDays != undefined) {
        workDays.forEach(workDay => {
            mapped.push({
                date: new Date(workDay.date),
                start: workDay.start ? new Date(workDay.start) : null,
                end: workDay.end ? new Date(workDay.end) : null,
            });
        });
    }

    return mapped;
};

export function FormatValue(value: number) {
    const options = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    };
    let newvalue: string = value.toString();
    const locale: string = navigator.languages[0];

    const fnewvalue: number = parseFloat(newvalue);
    if (isNaN(fnewvalue)) {
        return '';
    }
    if (fnewvalue === 0) {
        return '';
    }
    newvalue = Number(fnewvalue).toLocaleString(locale, options);
    return newvalue;
}
let commapunktum = '';
export function GetNumberSep() {
    if (commapunktum !== '') {
        return commapunktum;
    }

    const nval: number = 2.2;
    const options = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    };
    const locale: string = navigator.languages[0];

    const newvalue = nval.toLocaleString(locale, options);

    commapunktum = newvalue.replace(/2/g, '');
    return commapunktum;
}
export function Format2DecimalValue(value: string) {
    const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    };
    const locale: string = navigator.languages[0];

    const fnewvalue: number = parseFloat(value);
    if (isNaN(fnewvalue)) {
        return Number(0).toLocaleString(locale, options);
    }

    const newvalue = fnewvalue.toLocaleString(locale, options);
    return newvalue;
}

export function NumberMax2DecimalFormatAsString(val: number) {
    const options = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    };
    const locale: string = navigator.languages[0];
    return val.toLocaleString(locale, options);
}

export function UnformatValue(value: string) {
    if (value === '-') {
        return 0;
    }

    let newvalue: string = value.toString();
    const locale: string = navigator.languages[0];

    if (locale.toLowerCase().indexOf('en') >= 0) {
        newvalue = newvalue.replace(/,/g, '');
    }
    if (locale.toLowerCase().indexOf('da') >= 0) {
        newvalue = newvalue.replace(/\./g, '');
        newvalue = newvalue.replace(',', '.');
    }
    if (locale.toLowerCase().indexOf('se') >= 0) {
        //not tested!!
        newvalue = newvalue.replace(/ /g, '');
        newvalue = newvalue.replace(',', '.');
    }

    const fnewvalue: number = parseFloat(newvalue);
    // console.log("UnformatValue Return " + fnewvalue);
    return fnewvalue;
}
export function GetMonthNameShort(value: number) {
    const month = [];
    month[0] = 'Jan';
    month[1] = 'Feb';
    month[2] = 'Mar';
    month[3] = 'Apr';
    month[4] = 'May';
    month[5] = 'Jun';
    month[6] = 'Jul';
    month[7] = 'Aug';
    month[8] = 'Sep';
    month[9] = 'Oct';
    month[10] = 'Nov';
    month[11] = 'Dec';
    return month[value];
}
export function SortTimeRows(times: TimeRow[]): TimeRow[] {
    const rtnTimes: TimeRow[] = internalSortTimeRows(times);
    return rtnTimes;
}
export function SortTimeSearchRows(times: TimeSearchRow[]): TimeSearchRow[] {
    return internalSortTimeRows(times);
}
export function SortDetaillinesSearchRows(times: ApproverCellDetailLine[]): ApproverCellDetailLine[] {
    return internalSortTimeRows(times);
}
export function GetDetailsListGroups(times: any[], oldgroups?: any[]): any[] {
    console.log(times);
    const grouped = [];
    if (times.length > 0) {
        let lastProjectName = times[0].projectName;
        let lastProjectNumber = times[0].projectNumber;
        let lastProjectSource = times[0].projectSource;
        let lastProjectId = times[0].projectId;
        let lastMetadata1 = times[0].metadata1;
        let groupStartIndex = 0,
            groupCount = 0;
        let noOfGroups = 0;
        let collapsed: boolean = false;

        for (let i = 0; i < times.length; i++) {
            if (lastProjectName !== times[i].projectName) {
                noOfGroups++;
                if (!isNullOrUndefined(oldgroups)) {
                    const oldgroup = oldgroups.find(g => g.key === lastProjectId);
                    if (!isNullOrUndefined(oldgroup)) {
                        collapsed = oldgroup.isCollapsed;
                    } else {
                        collapsed = true;
                    }
                }

                const pName = lastProjectName + `${lastProjectNumber ? ` (${lastProjectNumber})` : ''}`;
                grouped.push({
                    key: lastProjectId,
                    name: pName,
                    startIndex: groupStartIndex,
                    count: groupCount,
                    level: 0,
                    isCollapsed: collapsed,
                    metadata1: lastMetadata1,
                    projectSource: lastProjectSource,
                });

                lastProjectName = times[i].projectName;
                lastProjectNumber = times[i].projectNumber;
                lastProjectSource = times[i].projectSource;
                lastProjectId = times[i].projectId;
                lastMetadata1 = times[i].metadata1;
                groupCount = 0;
                groupStartIndex = i;
            }
            groupCount = groupCount + 1;
        }

        noOfGroups++;
        if (!isNullOrUndefined(oldgroups)) {
            const oldgroup = oldgroups.find(g => g.key === lastProjectId);
            if (!isNullOrUndefined(oldgroup)) {
                collapsed = oldgroup.isCollapsed;
            } else {
                collapsed = true;
            }
        }

        const pName = lastProjectName + `${lastProjectNumber ? ` (${lastProjectNumber})` : ''}`;
        grouped.push({
            key: lastProjectId,
            name: pName,
            startIndex: groupStartIndex,
            count: groupCount,
            level: 0,
            isCollapsed: collapsed,
            metadata1: lastMetadata1,
            projectSource: lastProjectSource,
        });

        if (isNullOrUndefined(oldgroups)) {
            if (times.length >= 30 && noOfGroups >= 4) {
                collapsed = true;
                for (let i = 0; i < grouped.length; i++) {
                    grouped[i].isCollapsed = true;
                }
            }
        }
    }
    return grouped;
}
export function GetApproverListDetailsListGroups(appprovallines: ApprovalLine[]): any[] {
    const grouped = [];
    if (appprovallines.length > 0) {
        let lastPeriodName = appprovallines[0].periodName;
        let lastPeriodId = appprovallines[0].periodId;
        let lastPeriodState = appprovallines[0].periodState;
        let groupStartIndex = 0,
            groupCount = 0;

        for (let i = 0; i < appprovallines.length; i++) {
            if (lastPeriodName !== appprovallines[i].periodName) {
                grouped.push({ key: lastPeriodId, name: `${lastPeriodName} - [${lastPeriodState}]`, startIndex: groupStartIndex, count: groupCount, level: 0 });

                lastPeriodName = appprovallines[i].periodName;
                lastPeriodId = appprovallines[i].periodId;
                lastPeriodState = appprovallines[i].periodState;
                groupCount = 0;
                groupStartIndex = i;
            }
            groupCount = groupCount + 1;
        }
        grouped.push({ key: lastPeriodId, name: `${lastPeriodName} - [${lastPeriodState}]`, startIndex: groupStartIndex, count: groupCount, level: 0 });
    }
    return grouped;
}
export function GetApproverDetailsListGroups(times: any[]): any[] {
    const grouped = [];
    if (times.length > 0) {
        let lastProjectName = times[0].projectName;
        const lastProjectNumber = times[0].projectNumber;
        let lastProjectSource = times[0].projectSource;
        let lastProjectId = times[0].projectId;
        let groupStartIndex = 0,
            groupCount = 0;

        for (let i = 0; i < times.length; i++) {
            if (lastProjectName !== times[i].projectName) {
                grouped.push({
                    key: lastProjectId,
                    name: lastProjectName + `${lastProjectNumber ? ` (${lastProjectNumber})` : ''}`,
                    startIndex: groupStartIndex,
                    count: groupCount,
                    level: 0,
                    projectSource: lastProjectSource,
                });

                lastProjectName = times[i].projectName;
                lastProjectSource = times[i].projectSource;
                lastProjectId = times[i].projectId;
                groupCount = 0;
                groupStartIndex = i;
            }
            groupCount = groupCount + 1;
        }
        grouped.push({
            key: lastProjectId,
            name: lastProjectName + `${lastProjectNumber ? ` (${lastProjectNumber})` : ''}`,
            startIndex: groupStartIndex,
            count: groupCount,
            level: 0,
            projectSource: lastProjectSource,
        });
    }
    return grouped;
}
function internalSortTimeRows(times: any[]): any[] {
    const sortedRows: TimeRow[] = [...times].sort((a: TimeRow, b: TimeRow) => {
        if (a.projectOrder !== 0 || b.projectOrder !== 0) {
            if (a.projectOrder < b.projectOrder) {
                return -1;
            } else if (a.projectOrder > b.projectOrder) {
                return 1;
            } else {
                if (a.projectName < b.projectName) {
                    return -1;
                } else if (a.projectName > b.projectName) {
                    return 1;
                } else {
                    if ((a.taskOrder !== 9999 && a.taskOrder !== undefined) || (b.taskOrder !== 9999 && b.taskOrder !== undefined)) {
                        return a.taskOrder < b.taskOrder ? -1 : 1;
                    } else {
                        return a.taskName < b.taskName ? -1 : 1;
                    }
                }
            }
        } else {
            if (a.projectName < b.projectName) {
                return -1;
            } else if (a.projectName > b.projectName) {
                return 1;
            } else {
                if ((a.taskOrder !== 9999 && a.taskOrder !== undefined) || (b.taskOrder !== 9999 && b.taskOrder !== undefined)) {
                    return a.taskOrder < b.taskOrder ? -1 : 1;
                } else {
                    return a.taskName < b.taskName ? -1 : 1;
                }
            }
        }
    });
    return sortedRows;
}

export const GetDaysFromDates = (timeEntries: TimeEntry[]) => {};

export function getEmptyGuid(): string {
    return '00000000-0000-0000-0000-000000000000';
}
export function createNewGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
export const Debounce = (func, wait) => {
    let timeout;

    return function executedFunction(...args) {
        clearTimeout(timeout);
        const later = () => {
            timeout = null;
            func(...args);
        };
        timeout = setTimeout(later, wait);
    };
};
export function getWeekStartDate(d: Date): Date {
    const startOfWeek = new Date(d);
    while (startOfWeek.getDay() !== 1) {
        //monday
        startOfWeek.setDate(startOfWeek.getDate() - 1);
    }
    return startOfWeek;
}
export function getWeekEndDate(d: Date): Date {
    const endOfWeek = new Date(d);
    while (endOfWeek.getDay() !== 0) {
        endOfWeek.setDate(endOfWeek.getDate() + 1);
    }
    return endOfWeek;
}
export function getWebDateString(d: Date): string {
    return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
}

// export function validateTimeInputByConfig(input: string, timeLowerLimitConfig: string): string {
//   let lowerLimitConfigFloat = Number.parseFloat(timeLowerLimitConfig);
//   let inputAsFloat = Number.parseFloat(input);
//   if (inputAsFloat > lowerLimitConfigFloat) {
//     return input;
//   }
//   return 'ERROR';
// }
export function fistTwoLetters(txt: string): string {
    const firstLetter = txt[0].toUpperCase();
    let secondLetter = txt[1].toUpperCase();

    const otherWords = txt.split(' ');
    for (let i = 1; i < otherWords.length; i++) {
        if (otherWords[i] !== undefined && otherWords[i] != null && otherWords[i].length > 0) {
            if (/[a-zA-Z]/.test(otherWords[i][0])) {
                secondLetter = otherWords[i][0].toUpperCase();
                break;
            }
        }
    }
    return firstLetter + secondLetter;
}
export function fistThreeLetters(txt: string): string {
    const firstLetter = txt[0].toUpperCase();
    let secondLetter = txt[1].toUpperCase();
    let thirdLetter = txt[2].toUpperCase();

    const otherWords = txt.split(' ');
    for (let i = 1; i < otherWords.length; i++) {
        if (otherWords[i] !== undefined && otherWords[i] != null && otherWords[i].length > 0) {
            if (/[a-zA-Z]/.test(otherWords[i][0])) {
                if (i === 2) {
                    secondLetter = otherWords[i][0].toUpperCase();
                }
                if (i === 3) {
                    thirdLetter = otherWords[i][0].toUpperCase();
                    break;
                }
            }
        }
    }
    return firstLetter + secondLetter + thirdLetter;
}
export function hoursString(no: number): string {
    try {
        if (no >= 2) {
            return ' Hours ';
        } else {
            return ' Hour ';
        }
    } catch (ex) {
        return ' Hour(s) ';
    }
}

export function lastworkedweekstring(daysago: number): string {
    try {
        if (daysago <= 1) {
            return 'You worked on this today';
        } else if (daysago <= 2) {
            return 'You worked on this yesterday';
        } else if (daysago <= 7) {
            return 'You worked on this ' + daysago + ' days ago';
        } else if (daysago <= 14) {
            return 'You worked on this one week ago';
        } else {
            return 'You worked on this ' + Math.round(daysago / 14) + ' weeks ago';
        }
    } catch (ex) {
        return 'You worked on this ' + Math.round(daysago / 14) + ' weeks ago';
    }
}
export function GetTotalFromTimeRowInDayArray(arr: TimeRow[], d: Date): number {
    let res: number = 0;
    arr.forEach((n: TimeRow) => {
        // if (n.timeEntries != undefined && n.timeEntries != null) {
        n.timeEntries.forEach((te: TimeEntry) => {
            if (new Date(te.date).getTime() === d.getTime()) {
                res += te.hours;
            }
        });
        // }
    });
    if (res === 0) {
        return null;
    }
    return res;
}
export function GetTotalFromTimeEntriesArray(arr: TimeEntry[]): number {
    return arr.reduce((a, { hours }) => a + hours, 0);
}

export function GetTotalFromTimeRowArray(arr: TimeRow[], reportingPeriodId: string = null) {
    let res: number = 0;
    arr.forEach((n: TimeRow) => {
        // if (n.timeEntries != undefined && n.timeEntries != null) {
        if (reportingPeriodId != null) {
            n.timeEntries
                .filter(te => te.userReportingPeriod.id === reportingPeriodId)
                .forEach((te: TimeEntry) => {
                    res += te.hours;
                });
        } else {
            n.timeEntries.forEach((te: TimeEntry) => {
                res += te.hours;
            });
        }
        // }
    });
    if (res === 0) {
        return null;
    }
    return res;
}

// export function GetTotalFromTimeEntriesArray(arr: TimeEntry[]) {
//   let res: number = 0;
//   arr.forEach((te: TimeEntry) => {
//       res += te.hours;
//   });
//   if (res === 0) return null;
//   return res;
// }

export function getParameterByName(name: string, url: string = window.location.href): string {
    const washetname = name.toLowerCase().replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + washetname + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) {
        return '';
    }
    if (!results[2]) {
        return '';
    }
    const param = decodeURIComponent(results[2].replace(/\+/g, ' '));
    return param.replace('/', '');
}

export function DayToEIntDays(day: string) {
    switch (day) {
        case EDays.Monday:
            return EIntDays.Monday;
        case EDays.Tuesday:
            return EIntDays.Tuesday;
        case EDays.Wednesday:
            return EIntDays.Wednesday;
        case EDays.Thursday:
            return EIntDays.Thursday;
        case EDays.Friday:
            return EIntDays.Friday;
        case EDays.Saturday:
            return EIntDays.Saturday;
        case EDays.Sunday:
            return EIntDays.Sunday;
        default:
            return null;
    }
}

export function SundayFirstToMondayFirst(day: number) {
    switch (day) {
        case 1:
            return EIntDays.Monday;
        case 2:
            return EIntDays.Tuesday;
        case 3:
            return EIntDays.Wednesday;
        case 4:
            return EIntDays.Thursday;
        case 5:
            return EIntDays.Friday;
        case 6:
            return EIntDays.Saturday;
        case 0:
            return EIntDays.Sunday;
        default:
            return null;
    }
}

export const IsWeekend = (date: Date) => {
    return date.getDay() === 6 || date.getDay() === 0;
};

export const IsDeadline = (date: Date, deadlineDate: Date) => {
    return new Date(date).removeTimeZone().getTime() === new Date(deadlineDate).setUTCTime().getTime();
};

export const IsHoliday = (date: Date, calendar: any) => {
    const currentDate = new Date(date);
    currentDate.setHours(0, 0, 0, 0);
    const isHoliday = calendar?.holidays && calendar?.holidays.filter(_ => new Date(_.date).getTime() === currentDate.getTime()).length > 0;
    return isHoliday;
};

export const GetHolidayName = (date: Date, calendar: any) => {
    const currentDate = new Date(date);
    currentDate.setHours(0, 0, 0, 0);
    const holiday = calendar.holidays.find(_ => new Date(_.date).getTime() === currentDate.getTime()); //calendar?.holidays && calendar?.holidays.filter(_ => new Date(_.date).getTime() == currentDate.getTime()).length > 0;
    return holiday;
};

export function IsDatesNoTimeEqual(dateOne: Date, dateTwo: Date) {
    // debugger;
    //console.debug("IsDatesNoTimeEqual");
    try {
        const dateOneNew = new Date(dateOne);
        dateOneNew.setHours(12, 0, 0, 0);
        const dateTwoNew = new Date(dateTwo);
        dateTwoNew.setHours(12, 0, 0, 0);
        return dateOneNew.toISOString().split('T')[0] === dateTwoNew.toISOString().split('T')[0];
    } catch (error) {
        console.log('Failed to compare dates', error, dateOne, dateTwo);
        return false;
    }
}

export function MathToFixed(number, precision) {
    // console.log(Number((+(Math.round(+(number + 'e' + precision)) + 'e' + -precision)).toFixed(precision)));
    return Number((+(Math.round(+(number + 'e' + precision)) + 'e' + -precision)).toFixed(precision));
}
export function RoundToDecOrEmpty(number, precision): string {
    if (number === undefined || number == null || number === isNaN || number === '') {
        return '';
    }
    return Number((+(Math.round(+(number + 'e' + precision)) + 'e' + -precision)).toFixed(precision)).toString();
}
export function IsFirstDateLessEqualThanSecondDate(first: Date, second: Date): boolean {
    const firstd = new Date(first);
    firstd.setHours(0, 0, 0);
    const secondd = new Date(second);
    secondd.setHours(0, 0, 0);
    return firstd <= secondd;
}
export function IsFirstDateEqualToSecondDate(first: Date, second: Date): boolean {
    const firstd = new Date(first);
    firstd.setHours(0, 0, 0);
    const secondd = new Date(second);
    secondd.setHours(0, 0, 0);
    return firstd === secondd;
}
export function DateAsYYYYmmDD(date: Date): string {
    const xYear = date.getFullYear();
    const xMonth = date.getMonth() + 1;
    const xDate = date.getDate();

    return xYear + '-' + (xMonth < 10 ? '0' + xMonth : xMonth) + '-' + (xDate < 10 ? '0' + xDate : xDate);

    // return date.getFullYear() + '-' + (date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth()) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
}
export function IsWeekDay(date: Date): boolean {
    const day = date.getDay();
    if (day === 0 || day === 6) {
        return false;
    }
    return true;
}
export function DateAsStartOfDay(date: Date): Date {
    const dateToMod = new Date(date);

    dateToMod.setHours(0, 0, 0, 0);

    return dateToMod;
}
export function GetAdminPeriodsDtoAsPeriod(apDto: GetAllPeriodsAsAdminPeriodsDto): Period {
    return new Period(apDto.id, apDto.name, apDto.startDate, apDto.endDate, apDto.state, apDto.deadlineDate);
}
export function GetButtonColorClass(s: Date, e: Date, n: Date, ts: TimeState): string {
    if (s > n) {
        // Start < Now == Future
        if (ts != TimeState.rejected) {
            return 'periodPickerButton-Green';
        }
        return 'periodPickerButton-Red';
    }
    if (s <= n && e > n) {
        // Start <= Now && End >= Now == Present
        if (ts != TimeState.rejected) {
            return 'periodPickerButton-Green';
        }
        return 'periodPickerButton-Red';
    }
    if (e < n) {
        // End < Now == Past
        if (ts != TimeState.approved && ts != TimeState.submitted) {
            return 'periodPickerButton-Red';
        }
        return 'periodPickerButton-Green';
    }

    // Default to normal/colorless
    return '';
}
export function ShowRememberSubmitCallout(e: Date, n: Date, ts: TimeState, pc: boolean, nc: boolean, us: Date): boolean {
    if (nc) {
        return false;
    }
    if (pc) {
        return false;
    }
    if (us > e) {
        return false;
    }
    if (e < n) {
        // End < Now == Past
        if (ts != TimeState.approved && ts != TimeState.submitted) {
            return true;
        }
        return false;
    }

    // Default to false
    return false;
}

export function getCookie(cname: string): string {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}
export function setCookie(cname: string, cvalue: string, exdays: number): void {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

/* check for object in array and add item
if ((users.some(function(el) {return el.key === approvalline.userId;}))==false)
                      {
                          users.push({ key: approvalline.userId, text: approvalline.personName });
                      }
*/

declare global {
    interface Date {
        removeTimeZone(): Date;
        getISOStringMidnight(): string;
        setUTCTime(): Date;
    }
}

Date.prototype.removeTimeZone = function (): Date {
    const userTimezoneOffset = this.getTimezoneOffset() * 60000;
    const nonTimezoneStart = new Date(this.getTime() - userTimezoneOffset);
    return nonTimezoneStart;
};

Date.prototype.getISOStringMidnight = function (): string {
    const currentMonth = this.getMonth() + 1;
    return `${this.getFullYear()}-${currentMonth < 10 ? 0 : ''}${currentMonth}-${this.getDate() < 10 ? 0 : ''}${this.getDate()}T00:00:00.000Z`;
};

Date.prototype.setUTCTime = function (): Date {
    this.setHours(0);
    this.setMinutes(0);
    this.setSeconds(0);
    this.setMilliseconds(0);

    return this;
};
