import React, { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { Icon, IIconStyles } from '@fluentui/react/lib/Icon';
import { Separator } from '@fluentui/react/lib/Separator';
import { useUiContext } from '../../components/Contexts/UiContext';
import AdminCalendar from './AdminCalendar';
import { DefaultButton, PrimaryButton, ScrollablePane } from '@fluentui/react';
import { createNewGuid } from '../../components/Utils';
import { Calendar } from '../../Model/NewModels/Calendar';
import { Holiday } from '../../Model/NewModels/Holiday';

const setHolidaysDateToIsoString = (calenders: Calendar[]): Calendar[] => {
    const updatedCalenders = calenders.map(c => {
        return {
            ...c,
            holidays: c.holidays.map(h => {
                return {
                    ...h,
                    date: new Date(h.date).getISOStringMidnight(),
                } as Holiday;
            }),
        } as Calendar;
    });
    return updatedCalenders;
};

interface propsAdminCalendars {
    calendars: Calendar[];
}

function AdminCalendars(props: propsAdminCalendars) {
    const uiCtx = useUiContext();

    const [calendars, setCalendars] = useState<Calendar[]>([]);
    const [isDefaultChanged, setIsDefaultChanged] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const sortedCalendars = props.calendars.sort((a, b) => (b.isDefault as any) - (a.isDefault as any) || a.name.localeCompare(b.name));
        for (const cal of sortedCalendars) {
            cal.holidays?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        }
        setCalendars(sortedCalendars);
        setLoading(false);
    }, [props.calendars]);

    const RemoveCalendar = (c: Calendar) => {
        setCalendars(calendars.filter(cal => cal.id != c.id));
    };

    const IsDefaultChanged = () => {
        setIsDefaultChanged(isDefaultChanged + 1);
    };

    const CalendarUpdated = (id: string, name: string, language: string, isDefault: boolean, holidays: Holiday[]) => {
        let boolIsDefaultUpdated = false;
        const updatedCals = calendars;
        for (let i = 0; i < updatedCals.length; i++) {
            if (updatedCals[i].id == id) {
                updatedCals[i].name = name;
                updatedCals[i].language = language;
                updatedCals[i].isDefault = isDefault;
                updatedCals[i].holidays = holidays;
            }
            if (updatedCals[i].id != id && isDefault == true) {
                updatedCals[i].isDefault = false;
                boolIsDefaultUpdated = true;
            }
        }
        setCalendars(updatedCals);
        if (boolIsDefaultUpdated) {
            IsDefaultChanged();
        }
    };

    const saveChanges = () => {
        setLoading(true);
        uiCtx.timeApi.setCalendars(setHolidaysDateToIsoString(calendars)).then(cals => {
            console.log('saved');
            setLoading(false);
        });
        // let updatedCalendars = calendars.sort((a, b) => ((b.isDefault as any) - (a.isDefault as any) || a.name.localeCompare(b.name)));
        // updatedCalendars.ma
        const sortedCalendars = calendars.sort((a, b) => (b.isDefault as any) - (a.isDefault as any) || a.name.localeCompare(b.name));
        for (const cal of sortedCalendars) {
            cal.holidays.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        }
        setCalendars(sortedCalendars);
    };
    const AddCalendarClick = () => {
        const newCalendar: Calendar = { id: createNewGuid(), name: 'New Calendar', language: 'N/A', isDefault: false, holidays: [] };
        setCalendars(prevState => [...prevState, newCalendar]);
    };
    return (
        <div style={outerDivStyle}>
            <ScrollablePane style={outerDivStyle}>
                <h3 style={{ marginTop: 20 }}>Calendars</h3>
                <Separator>
                    <Icon iconName="ProjectCollection" styles={separatorIconStyles} />
                </Separator>
                <br />
                {loading ? (
                    <Stack className="logindialog" horizontalAlign="center" verticalAlign="center">
                        <Spinner label="Loading..." />
                    </Stack>
                ) : (
                    <>
                        {uiCtx.user.originalUser.isAdmin && isDefaultChanged > -1 ? (
                            <div style={{ display: 'inline-block' }}>
                                <Stack tokens={{ childrenGap: 10 }}>
                                    {calendars.map((calendar, index) => {
                                        return (
                                            <AdminCalendar key={index} Calendar={calendar} IsDefault={calendar.isDefault} RemoveCalendar={RemoveCalendar} CalendarUpdated={CalendarUpdated} /> // IsDefaultChanged={IsDefaultChanged} />
                                        );
                                    })}
                                    <Separator>
                                        <Stack tokens={{ childrenGap: 10 }}>
                                            <Stack.Item align="center">
                                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                                    <DefaultButton text="Add Calendar" onClick={AddCalendarClick} />
                                                    <PrimaryButton onClick={saveChanges} text="Save Calendars" />
                                                </Stack>
                                            </Stack.Item>
                                        </Stack>
                                    </Separator>
                                </Stack>
                                <br />
                                <br />
                                <br />
                            </div>
                        ) : (
                            <div style={personaAprroverConfigContainerDivStyle}>
                                <h3>Access Denied.</h3>
                            </div>
                        )}
                    </>
                )}
            </ScrollablePane>
        </div>
    );
}

export default AdminCalendars;

const outerDivStyle = {
    margin: '50px 0px 0px 0px',
    display: 'block',
    justifyContent: 'center',
    textAlign: 'center',
    // backgroundColor: 'white',
} as React.CSSProperties;

const personaAprroverConfigContainerDivStyle = {
    display: 'inline-flex',
};

const separatorIconStyles: IIconStyles = {
    root: {
        fontSize: '24px',
        height: '24px',
        width: '24px',
    },
};
