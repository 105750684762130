import { useId } from '@fluentui/react-hooks';
import React, { Dispatch, useState } from 'react';
import { useUiContext } from '../../../components/Contexts/UiContext';
import { mergeStyleSets } from '@fluentui/merge-styles';
import { Stack, Label, TooltipHost, TextField, PrimaryButton, DefaultButton, FocusTrapCallout, Text, IconButton, Link } from '@fluentui/react';
import { FocusZone, FocusZoneTabbableElements } from '@fluentui/react-focus';
import { FontWeights } from '@fluentui/theme';
import { ContentSection } from '../../../components/LayoutElements';
import { useEnvironment } from '../../../Authentication/hooks/useEnvironment';

interface IProps {
    config: Record<string, any>;
    setDataverseUrl: Dispatch<React.SetStateAction<string>>;
    isDataverse: boolean;
    dataverseUrl: string;
}

export const Environment = (props: IProps) => {
    const [showCallout, setShowCallout] = useState(false);
    const calloutbuttonId = useId();
    const uiCtx = useUiContext();

    const [validUrl, setValidUrl] = useState<boolean>(true);
    const [addBtnText, setaddBtnText] = useState<string>('Add/Update Endpoint');
    const [removeBtnText, setRemoveBtnText] = useState<string>('Remove Endpoint');
    const [dataverseUrl, setDataverseUrl] = useState<string>(props.dataverseUrl);

    const {
        env: { clientId },
    } = useEnvironment();

    const isUrlValid = (value: string) => {
        // eslint-disable-next-line no-useless-escape
        if (/^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(value)) {
            setValidUrl(true);
            return '';
        }
        setValidUrl(false);
        return 'url is not valid';
    };

    const AddEndpointClick = () => {
        setaddBtnText('Please Wait...');

        uiCtx.timeApi.setDataverseURL(dataverseUrl).then(ep => {
            props.setDataverseUrl(ep);
            setaddBtnText('Add/Update Endpoint');
        });
    };

    const RemoveEndpointClick = () => {
        setRemoveBtnText('Please Wait...');

        uiCtx.timeApi.removeDataverseURL().then(ep => {
            setDataverseUrl('');
            props.setDataverseUrl(ep);
            setRemoveBtnText('Remove Endpoint');
        });
    };

    return (
        <ContentSection>
            <Stack tokens={{ childrenGap: 10 }}>
                <Stack tokens={{ childrenGap: 10 }} horizontal>
                    <Label>Dataverse environment url: </Label>
                    <Stack style={{ width: '500px' }}>
                        <TooltipHost
                            styles={{ root: { textAlign: 'left' } }}
                            content={
                                'The dataverse environment url. You can find this by opening up the app you want to integrate to in Power Apps and look in the url of the browser'
                            }
                        >
                            <TextField onChange={(e, value) => setDataverseUrl(value)} value={dataverseUrl} onGetErrorMessage={value => isUrlValid(value)} />
                        </TooltipHost>
                    </Stack>
                    <PrimaryButton
                        text={addBtnText}
                        disabled={validUrl === false || addBtnText === 'Please Wait...' || props.dataverseUrl === dataverseUrl}
                        onClick={AddEndpointClick}
                    />
                    {props.isDataverse && <DefaultButton text={removeBtnText} disabled={removeBtnText === 'Please Wait...'} onClick={RemoveEndpointClick} />}
                </Stack>
                <Stack tokens={{ childrenGap: 10 }} horizontalAlign="center" horizontal>
                    <Text style={{ marginTop: 5 }} variant="mediumPlus">
                        To synchronize with PowerPPM you must grant access for Time for Teams
                    </Text>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Stack.Item align="center">
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <DefaultButton
                                    id={calloutbuttonId}
                                    text="How to grant access"
                                    onClick={() => {
                                        setShowCallout(true);
                                    }}
                                />
                                {showCallout && (
                                    <FocusTrapCallout
                                        style={{ width: 800, minHeight: 300, backgroundColor: 'var(--palette-white)', color: 'var(--body-contentColor)' }}
                                        target={`#${calloutbuttonId}`}
                                        className={styles.callout}
                                    >
                                        <Text block variant="xLarge" className={styles.title}>
                                            How to grant access
                                        </Text>
                                        <Text block variant="medium" style={{ marginBottom: '12px' }}>
                                            To synchronize users with the Dataverse environment {dataverseUrl} you must grant access for Time for Teams.
                                            Complete the following steps to grant access.
                                        </Text>
                                        <ol>
                                            <li>
                                                <Text block variant="medium">
                                                    {'Navigate to the New Application User page: '}
                                                    <Link
                                                        href={
                                                            dataverseUrl +
                                                            '/main.aspx?settingsonly=true&pagetype=entityrecord&etn=systemuser&formid=d26924ef-4d80-46f7-ab1d-e6ae80a54ce8'
                                                        }
                                                        target="_blank"
                                                    >
                                                        {dataverseUrl + '/...'}
                                                    </Link>
                                                </Text>
                                            </li>
                                            <li>
                                                <Text block variant="medium">
                                                    {'Copy/Paste the Client ID '}
                                                    <IconButton
                                                        iconProps={{ iconName: 'Copy' }}
                                                        checked={false}
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(clientId);
                                                        }}
                                                    />
                                                    {' into the Application ID page and click Save.'}
                                                </Text>
                                                <Text block variant="smallPlus" style={{ marginBottom: '12px' }}>
                                                    {'Note: When save is complete the user will get populated with information form the AD.'}
                                                </Text>
                                            </li>
                                            <li>
                                                <Text block variant="medium">
                                                    {'Click on MANAGE ROLES in the ribbon and Assing the role System Administrator.'}
                                                </Text>
                                            </li>
                                        </ol>
                                        <Text block variant="medium" style={{ marginBottom: '12px' }}>
                                            {'You have now granted access for Time for Teams.'}
                                        </Text>
                                        <Text block variant="smallPlus">
                                            {
                                                'Note: If you do not want to grant Time for Teams System Administrator you can create another role with read/write permission on the resources table and assign it instead.'
                                            }
                                        </Text>

                                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                                            <Stack className={styles.buttons}>
                                                <Stack.Item align="center">
                                                    <PrimaryButton onClick={() => setShowCallout(!showCallout)}>Close</PrimaryButton>
                                                </Stack.Item>
                                            </Stack>
                                        </FocusZone>
                                    </FocusTrapCallout>
                                )}
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </Stack>
            </Stack>
        </ContentSection>
    );
};

const styles = mergeStyleSets({
    callout: {
        padding: '20px 24px',
    },
    title: {
        marginBottom: 12,
        fontWeight: FontWeights.semilight,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 20,
    },
});
