import * as React from 'react';
import { memo, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useUiContext } from '../components/Contexts/UiContext';
import Period from '../Model/Period';
import ReportPeriodRowM from '../Model/ReportPeriodRowM';
import { TimeState } from '../Model/State';
import TimePeriodRowM from '../Model/TimePeriodRowM';
import { daysArr, periodFakeDay } from './mobileConstants';
import { IMyTimeViewDay, IMyTimeViewTask, IPeriodDay, IProjects } from './mobileTypes';
import { addValueToTotalValue, round2Number } from './mobileUtils';
import { useMobile } from './useMobile';
import moment from 'moment';
import { polyfill } from './smoothscroll';
import { createCtx } from '../components/Contexts/createCtx';
(window as any).__forceSmoothScrollPolyfill__ = true;

// // kick off the polyfill!
polyfill();

export interface IMobileContext {
    allProjects: IProjects[];
    selectedTimePeriod: ReportPeriodRowM;
    setSelectedTimePeriod: React.Dispatch<React.SetStateAction<ReportPeriodRowM>>;
    allTimePeriods: Period[];
    setSelectedTimePeriodByDate: (start: Date) => void;
    timeState: TimeState;
    setTimeState: React.Dispatch<React.SetStateAction<TimeState>>;
    periodComment: string;
    setPeriodComment: React.Dispatch<React.SetStateAction<string>>;
    validationErrors: any[];
    setValidationErrors: React.Dispatch<React.SetStateAction<any[]>>;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isFirstOpenAfterProjectsOverview: React.MutableRefObject<boolean>;
    onUpdateSuccessHighlightRow: (taskId: string, dayString: string | null, date: Date | null, workTypeId: string | undefined) => void;
    dateFormat: string;
    isPeriodView: boolean;
    selectedPeriodDays: IPeriodDay[];
    showAsPercent: boolean;
}

const [useMobileCtx, MobileCtxProvider] = createCtx<IMobileContext>();

export const useMobileContext = () => useMobileCtx();

export const MobileContextProvider: React.FC = memo(props => {
    const uiCtx = useUiContext();

    const [allTimePeriods, setAllTimePeriods] = useState<Period[]>();
    const [selectedTimePeriod, setSelectedTimePeriod] = useState<ReportPeriodRowM | null>();
    const [allItems, setAllItems] = useState<any>();
    const [allProjects, setAllProjects] = useState<any>([]);
    const [timeState, setTimeState] = useState<TimeState>();
    const [periodComment, setPeriodComment] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);
    const [selectedPeriodDays, setSelectedPeriodDays] = useState<IPeriodDay[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const isFirstOpenAfterProjectsOverview = useRef(true);

    // const validator = useRef(new Validator()).current;

    const selectedDate = useMobile(state => state.selectedDate);

    const isPeriodView = useMemo(() => {
        const newIsPeriodView = uiCtx.gridView === 'period';
        useMobile.setState({ isPeriodView: newIsPeriodView });
        return newIsPeriodView;
    }, [uiCtx.gridView]);

    const dateFormat = useMemo(() => {
        const dformat = uiCtx.allConfig.find(_ => _.key.toLowerCase() === 'shortdateformat');
        let newDateFormat = 'D[.] MMM';
        if (dformat !== undefined && dformat != null && dformat.value.length >= 2) {
            newDateFormat = dformat.value;
        }
        useMobile.setState({ dateFormat: newDateFormat, timeLowerLimitConfig: uiCtx.timeLowerLimitConfig });
        return newDateFormat;
    }, [uiCtx.timeLowerLimitConfig]);

    const showAsPercent = useMemo(() => {
        return uiCtx.gridInputType === 'percent';
    }, [uiCtx.gridInputType]);

    useEffect(() => {
        uiCtx.timeApi.getPeriods().then(periods => setAllTimePeriods(periods));
    }, []);

    useEffect(() => {
        console.debug('allTimePeriods', allTimePeriods);
    }, [allTimePeriods]);

    useEffect(() => {
        console.debug('selectedTimePeriod', selectedTimePeriod);
    }, [selectedTimePeriod]);

    useEffect(() => {
        console.debug('selectedDate', selectedDate);
    }, [selectedDate]);

    useEffect(() => {
        // get init period
        const today = new Date();
        setSelectedTimePeriodByDate(today);
    }, []);

    useEffect(() => {
        if (!allTimePeriods || !selectedDate || selectedTimePeriod === undefined) {
            return;
        }
        // if (!allTimePeriods) return;

        // checks if the new selected date should trigger a new selected period
        const thisTimePeriod = allTimePeriods.find(
            p => selectedDate.getTime() >= new Date(p.startDate).getTime() && selectedDate.getTime() <= new Date(p.endDate).getTime(),
        );
        // if (thisTimePeriod && (new Date(thisTimePeriod.startDate).getDate() !== new Date(selectedTimePeriod.start).getDate() || new Date(thisTimePeriod.startDate).getMonth() !== new Date(selectedTimePeriod.start).getMonth())) {
        if (thisTimePeriod) {
            if (
                !selectedTimePeriod ||
                new Date(thisTimePeriod.startDate).getDate() !== new Date(selectedTimePeriod.start).getDate() ||
                new Date(thisTimePeriod.startDate).getMonth() !== new Date(selectedTimePeriod.start).getMonth()
            ) {
                useMobile.getState().setIsloading({ active: true, overlay: true });
                setSelectedTimePeriodByDate(getCleanDate(new Date(thisTimePeriod.startDate)));
            }
        } else {
            setSelectedTimePeriod(null);
            setAllProjects([]);
            useMobile.setState({
                myTimeView: [],
                totalValues: { all: 0 },
                allTasks: new Map(),
            });
        }
    }, [selectedDate]);

    useEffect(() => {
        if (!allItems || selectedTimePeriod === undefined) {
            return;
        }

        if (selectedTimePeriod === null) {
            setAllProjects([]);
            useMobile.setState({
                myTimeView: [],
                totalValues: { all: 0 },
                allTasks: new Map(),
            });
            if (uiCtx.loading) {
                uiCtx.setLoading(false);
            }
            return;
        }

        const newAllTasks = new Map();
        const newAllProjects: any[] = [];
        const newTotalValues = { all: 0 };
        const periodDays = getDatesBetweenDates(getCleanDate(selectedTimePeriod.start), getCleanDate(selectedTimePeriod.finish), dateFormat);
        const myDaysArr: any = isPeriodView
            ? [
                  {
                      dayName: periodFakeDay,
                      dayDate: selectedTimePeriod.start,
                      dateString: periodFakeDay,
                      isExpanded: true,
                      projects: [],
                  },
              ]
            : periodDays;

        setSelectedPeriodDays(
            periodDays.map(day => ({
                dayName: day.dayName,
                dayDate: day.dayDate,
                dateString: day.dateString,
            })),
        );

        const allPinnedTasks = allItems.filter(task => task.pinned);

        const newMyTimeView: IMyTimeViewDay[] = myDaysArr.map(day => {
            const newProjects: any = [];

            allPinnedTasks.forEach(task => {
                const indexOfProject = newProjects.findIndex(project => project.projectId === task.projectId);

                if (indexOfProject === -1) {
                    newProjects.push({
                        projectId: task.projectId,
                        projectName: task.projectName,
                        // projectOrder: task.projectOrder,
                        // projectSource: task.projectSource,
                        // projectWorkType: task.projectWorkType,
                        dayName: day.dayName,
                        dayDate: day.dayDate,
                        dateString: day.dateString,
                        pinned: task.pinned,
                        isExpanded: false,
                        tasks: [
                            {
                                taskKey: `${task.taskId}_${task.workType?.id}`,
                                taskId: task.taskId,
                                taskName: task.taskName,
                                workType: task.workType,
                                projectId: task.projectId,
                                dayName: day.dayName,
                                dayDate: day.dayDate,
                                dateString: day.dateString,
                            },
                        ],
                    });
                } else {
                    newProjects[indexOfProject].tasks.push({
                        taskKey: `${task.taskId}_${task.workType?.id}`,
                        taskId: task.taskId,
                        taskName: task.taskName,
                        workType: task.workType,
                        projectId: task.projectId,
                        dayName: day.dayName,
                        dayDate: day.dayDate,
                        dateString: day.dateString,
                    });
                }
            });

            return {
                ...day,
                projects: newProjects,
            };
        });

        allItems.forEach(item => {
            newAllTasks.set(`${item.taskId}_${item.workType?.id}`, item);

            item.timeEntries.forEach(timeEntry => {
                // creating stuff for the myTimeView
                const dateString = isPeriodView ? periodFakeDay : moment(new Date(timeEntry.date)).format(dateFormat);
                const dayName = isPeriodView ? periodFakeDay : daysArr[new Date(timeEntry.date).getDay()];
                const dayIndex = isPeriodView ? 0 : newMyTimeView.findIndex(day => day.dateString === dateString);
                const thisTimeEntries = item.timeEntries.filter(te => te.id === timeEntry.id);
                const indexOfProject = newMyTimeView[dayIndex].projects.findIndex(project => project.projectId === item.projectId);

                // muligvis lave det her til referencer til tasks i allItems, så vi kun skal opdatere i allItems?
                if (indexOfProject === -1) {
                    newMyTimeView[dayIndex].projects.push({
                        projectId: item.projectId,
                        projectName: item.projectName,
                        // projectOrder: item.projectOrder,
                        // projectSource: item.projectSource,
                        // projectWorkType: item.projectWorkType,
                        pinned: item.pinned,
                        isExpanded: false,
                        dateString,
                        dayName,
                        dayDate: timeEntry.date,
                        flagged: timeEntry.flagged,
                        tasks: [
                            {
                                taskKey: `${item.taskId}_${item.workType?.id}`,
                                taskId: item.taskId,
                                taskName: item.taskName,
                                projectId: item.projectId,
                                workType: item.workType,
                                dateString,
                                dayName,
                                dayDate: timeEntry.date,
                            },
                        ],
                    });
                } else {
                    const indexOfTask = newMyTimeView[dayIndex].projects[indexOfProject].tasks.findIndex(
                        task => `${task.taskId}_${task.workType.id}` === `${item.taskId}_${item.workType.id}`,
                    );
                    if (indexOfTask === -1) {
                        newMyTimeView[dayIndex].projects[indexOfProject].tasks.push({
                            taskKey: `${item.taskId}_${item.workType?.id}`,
                            taskId: item.taskId,
                            taskName: item.taskName,
                            projectId: item.projectId,
                            workType: item.workType,
                            dateString,
                            dayName,
                            dayDate: timeEntry.date,
                        });
                    }
                }

                // creating totals/values object
                if (thisTimeEntries?.[0]) {
                    newTotalValues['all'] = addValueToTotalValue(newTotalValues['all'], thisTimeEntries[0].hours);

                    if (!newTotalValues[dateString]) {
                        newTotalValues[dateString] = { value: round2Number(thisTimeEntries[0].hours) };
                    } else {
                        newTotalValues[dateString].value = addValueToTotalValue(newTotalValues[dateString].value, thisTimeEntries[0].hours);
                    }
                    if (!newTotalValues[dateString][item.projectId]) {
                        newTotalValues[dateString][item.projectId] = { value: round2Number(thisTimeEntries[0].hours) };
                    } else {
                        newTotalValues[dateString][item.projectId].value = addValueToTotalValue(
                            newTotalValues[dateString][item.projectId].value,
                            thisTimeEntries[0].hours,
                        );
                    }
                    if (isPeriodView && newTotalValues[dateString][item.projectId][`${item.taskId}_${item.workType?.id}`]) {
                        newTotalValues[dateString][item.projectId][`${item.taskId}_${item.workType?.id}`] = addValueToTotalValue(
                            newTotalValues[dateString][item.projectId][`${item.taskId}_${item.workType?.id}`],
                            thisTimeEntries[0].hours,
                        );
                    } else {
                        newTotalValues[dateString][item.projectId][`${item.taskId}_${item.workType?.id}`] = round2Number(thisTimeEntries[0].hours);
                    }
                }
            });

            // creating allProjects for the projectsOverview / search bar
            const indexOfProject = newAllProjects.findIndex(project => project.projectId === item.projectId);

            if (indexOfProject === -1) {
                newAllProjects.push({
                    projectId: item.projectId,
                    projectName: item.projectName,
                    tasks: [{ ...item }],
                });
            } else {
                const indexOfTask = newAllProjects[indexOfProject].tasks.findIndex(
                    task => `${task.taskId}_${task.workType.id}` === `${item.taskId}_${item.workType.id}`,
                );

                if (indexOfTask === -1) {
                    newAllProjects[indexOfProject].tasks.push({ ...item });
                }
            }
        });

        // sorts the projects & tasks in the myTimeView/TimeOverview
        const sortedMyTimeView = newMyTimeView.map(day => ({
            ...day,
            projects: day.projects
                .map(project => ({
                    ...project,
                    tasks: project.tasks.sort((a: IMyTimeViewTask, b: IMyTimeViewTask) => (a.taskName > b.taskName ? 1 : -1)),
                }))
                .sort((a: any, b: any) => (a.projectName > b.projectName ? 1 : -1)),
        }));

        console.debug('myTimeView', sortedMyTimeView);

        const sortedAllProjects = newAllProjects
            .map(project => ({
                ...project,
                tasks: [...project.tasks.sort((a: TimePeriodRowM, b: TimePeriodRowM) => (a.taskName > b.taskName ? 1 : -1))],
            }))
            .sort((a: any, b: any) => (a.projectName > b.projectName ? 1 : -1));

        setAllProjects(sortedAllProjects);

        useMobile.setState({
            myTimeView: sortedMyTimeView,
            totalValues: newTotalValues,
            allTasks: newAllTasks,
        });

        if (!selectedDate && !isPeriodView) {
            const todaysDate = getTodaysDate();
            useMobile.getState().setSelectedDate({ newSelectedDate: todaysDate });
        }

        if (uiCtx.loading) {
            uiCtx.setLoading(false);
        }

        useMobile.getState().setIsloading({ active: false, overlay: false });
    }, [allItems]);

    const setSelectedTimePeriodByDate = (start: Date) => {
        const startOfDay = getCleanDate(start);

        uiCtx.timeApi
            .getPeriodByDates(startOfDay.getISOStringMidnight())
            .then(rpPeriod => {
                if (!rpPeriod) {
                    return;
                }

                const period = {
                    periodId: rpPeriod.period.id,
                    name: rpPeriod.period.name,
                    state: Number(rpPeriod.state),
                    reportPeriodId: rpPeriod.id,
                    start: rpPeriod.period.startDate,
                    finish: rpPeriod.period.endDate,
                    isClosed: rpPeriod.period.state === 1 || rpPeriod.period.state === 2,
                    workDays: rpPeriod.workDays,
                };

                const newSelectPeriod: ReportPeriodRowM = new ReportPeriodRowM(
                    period.periodId,
                    period.name,
                    period.state,
                    period.reportPeriodId,
                    new Date(period.start),
                    new Date(period.finish),
                    period.isClosed,
                    period.workDays,
                );
                console.debug('newSelectPeriod', newSelectPeriod);

                if (period.state === 0) {
                    setTimeState(TimeState.open);
                } else if (period.state === 1) {
                    setTimeState(TimeState.submitted);
                } else if (period.state === 3) {
                    setTimeState(TimeState.rejected);
                } else {
                    setTimeState(TimeState.approved);
                }

                setPeriodComment(rpPeriod.comment || '');

                return newSelectPeriod;
            })
            .then(period => {
                uiCtx.timeApi.getTimeByReportPeriodCustomPeriod(period.reportPeriodId).then(json => {
                    const newAllItems: TimePeriodRowM[] = [];
                    if (json) {
                        json.map((time: any) => {
                            //TODO: Better way to cast dates?
                            newAllItems.push(
                                new TimePeriodRowM(
                                    time.assignmentId,
                                    time.timeId,
                                    time.projectId,
                                    time.projectName,
                                    time.projectNumber,
                                    time.projectSource,
                                    time.projectClosed,
                                    time.taskId,
                                    time.taskName,
                                    time.taskStart,
                                    time.taskEnd,
                                    time.taskClosed,
                                    time.taskIsDefault,
                                    time.taskDescription,
                                    time.pinned,
                                    '',
                                    time.projectOrder,
                                    time.taskOrder,
                                    time.plannedWork,
                                    time.remainingWork,
                                    time.actualWork,
                                    time.timeEntries.map(_ => {
                                        return { ..._, date: new Date(_.date) };
                                    }),
                                    time.workType,
                                    time.projectWorkType,
                                ),
                            );
                        });

                        setSelectedTimePeriod(period);
                        setAllItems(newAllItems);
                    }

                    console.debug('newAllItems', newAllItems);
                });
            })
            .catch(err => console.log('error: ', err));
    };

    const onUpdateSuccessHighlightRow = useCallback(
        (taskId: string, dayString: string | null, date: Date | null, workTypeId: string | undefined) => {
            const dateString = dayString || moment(new Date(date)).format(dateFormat);
            const taskRow = document.getElementById(`${taskId}_${workTypeId}_${dateString}`);
            if (!taskRow) return;
            if (!taskRow.classList.contains('taskIsUpdated')) {
                taskRow.classList.add('taskIsUpdated');
            } else {
                taskRow.classList.add('restartTaskIsUpdated');
            }
        },
        [dateFormat],
    );

    return (
        <MobileCtxProvider
            value={{
                allProjects,
                selectedTimePeriod,
                setSelectedTimePeriod,
                allTimePeriods,
                setSelectedTimePeriodByDate,
                timeState,
                setTimeState,
                periodComment,
                setPeriodComment,
                validationErrors,
                setValidationErrors,
                isLoading,
                setIsLoading,
                isFirstOpenAfterProjectsOverview,
                onUpdateSuccessHighlightRow,
                dateFormat,
                isPeriodView,
                selectedPeriodDays,
                showAsPercent,
            }}
        >
            {props.children}
        </MobileCtxProvider>
    );
});

const getTodaysDate = () => {
    const today = new Date();
    return getCleanDate(today);
};
export const getCleanDate = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate()).setUTCTime();
};

const getDatesBetweenDates = (startDate: Date, endDate: Date, dateFormat: string) => {
    const dates = [];

    const theDate = new Date(startDate);
    while (theDate < endDate) {
        const dayName = daysArr[new Date(theDate).getDay()];

        dates.push({
            dayName,
            dayDate: getCleanDate(theDate),
            dateString: moment(new Date(theDate)).format(dateFormat),
            isExpanded: getExpandedToday(theDate),
            projects: [],
        });

        theDate.setDate(theDate.getDate() + 1);
    }

    const endDateDayName = daysArr[new Date(endDate).getDay()];
    dates.push({
        dayName: endDateDayName,
        dayDate: getCleanDate(endDate),
        dateString: moment(new Date(endDate)).format(dateFormat),
        isExpanded: getExpandedToday(endDate),
        projects: [],
    });

    return dates;
};

const getExpandedToday = (date: Date): boolean => {
    const today = new Date();
    if (new Date(date).getDate() === new Date(today).getDate() && new Date(date).getMonth() === new Date(today).getMonth()) {
        return true;
    }
    return false;
};
