import React, { useRef, useState } from 'react';
import { useId } from '@fluentui/react-hooks';
import { ConfigDictionary } from './utils';
import { Callout, DetailsList, DetailsListLayoutMode, FontWeights, IconButton, SelectionMode, Spinner, Stack, Text, TextField, mergeStyleSets } from '@fluentui/react';
import { ApiCalls } from '../../api/api';
import { ActiveDirectoryGroup } from '../../api/generated/data-contracts';
import { useQuery } from '@tanstack/react-query';
import { GET_AD_GROUPS } from '../../constants/queryKeys';

interface ADLookupProps {
    setConfig: React.Dispatch<React.SetStateAction<Record<ConfigDictionary, string>>>;
}

export const ADLookup = ({ setConfig }: ADLookupProps) => {
    const searchbtnid = useId('callout-button');
    const [searchOpen, setsearchOpen] = useState<boolean>(false);

    const { data: adGroups, isFetching: loadingADGroups } = useQuery({
        queryKey: [GET_AD_GROUPS],
        queryFn: async () => {
            const data = await ApiCalls.getAdGroups().then(r => r.data);
            setFilteredADGroups(data);
            return data;
        },
        placeholderData: useRef([] as ActiveDirectoryGroup[]).current,
    });

    const [filteredADGroups, setFilteredADGroups] = useState<ActiveDirectoryGroup[]>([]);

    if (loadingADGroups) {
        return <Spinner label="Getting groups, please be patient..." />;
    }

    return (
        <>
            <IconButton
                id={searchbtnid}
                iconProps={{ iconName: 'Search' }}
                checked={false}
                styles={{ root: { height: 30, width: 30 } }}
                onClick={() => {
                    setsearchOpen(true);
                }}
            />

            {searchOpen && (
                <Callout target={`#${searchbtnid}`} onDismiss={() => setsearchOpen(false)} className={styles.callout}>
                    <>
                        <Text block variant="xLarge" className={styles.title}>
                            Search for Active Directory Group
                        </Text>
                        <TextField
                            label=""
                            placeholder="Type to search"
                            onChange={(e, v) => {
                                setFilteredADGroups(
                                    adGroups.filter((ag: any) => ag.name.toLowerCase().indexOf(v.toLowerCase()) >= 0 || (v.length >= 25 && ag.id.toLowerCase().indexOf(v.toLowerCase()) >= 0)),
                                );
                            }}
                        ></TextField>
                        <Stack style={{ height: 500 }}>
                            <DetailsList
                                items={filteredADGroups}
                                columns={[{ key: 'name', name: 'AD Group Name', fieldName: 'name', minWidth: 100, maxWidth: 200, isResizable: true }]}
                                selectionMode={SelectionMode.single}
                                layoutMode={DetailsListLayoutMode.justified}
                                compact={true}
                                onActiveItemChanged={e =>
                                    setConfig(prevState => ({
                                        ...prevState,
                                        ADSyncO365_GroupName: e.name,
                                    }))
                                }
                            />
                        </Stack>
                    </>
                </Callout>
            )}
        </>
    );
};

const styles = mergeStyleSets({
    button: {
        width: 130,
    },
    callout: {
        width: 520,
        maxWidth: '90%',
        padding: '20px 24px',
    },
    title: {
        marginBottom: 12,
        fontWeight: FontWeights.semilight,
    },
    link: {
        display: 'block',
        marginTop: 20,
    },
});
