import { IModalProps } from "@fluentui/react";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import Dialog, { DialogFooter } from "@fluentui/react/lib/Dialog";
import { Stack } from "@fluentui/react/lib/Stack";
import { Text } from "@fluentui/react/lib/Text";
import React from "react";
import { useState } from "react";
import { TimeWarning } from "../Model/TimeWarning";

const modelProps: IModalProps = {
    isBlocking: false,
    topOffsetFixed: true,
    // dragOptions: {
    //   moveMenuItemText: 'Move',
    //   closeMenuItemText: 'Close',
    //   menu: ContextualMenu,
    //   keepInBounds: true,
    // }
};

const tokens = {
    sectionStack: {
      childrenGap: 10,
    },
    headingStack: {
      childrenGap: 12,
    },
    buttonStack: {
      childrenGap: 10
    },
    contentStack: {
      childrenGap: 5
    }
};

interface IProps {
  warning: TimeWarning | null
}

export const WarningDialog: React.FC<IProps> = (props) => {
    const [showTechnicalDetails, setShowTechnicalDetails] = useState(false);
    
    function toggleDetails(ev: React.MouseEvent<HTMLButtonElement>): void {
        setShowTechnicalDetails(!showTechnicalDetails);
    }

    const refreshPage = () => {
        window.location.reload();
    }
  
    function copyToClipboard() {
      navigator.clipboard.writeText(`${props.warning.header}\n${props.warning.description}`)
    }

  return (
    <>
      <Dialog containerClassName={'errorDialog'} /*dialogContentProps={{title: "We are very sorry, but an error occured"}}*/ hidden={props.warning?.description ? false : true} modalProps={modelProps}>
        <Stack tokens={tokens.sectionStack}>
          <Stack horizontalAlign={"center"} tokens={tokens.headingStack}>
            <Text variant={"xLargePlus"}>{props.warning.header}</Text>
            <Text variant={"mediumPlus"}>{props.warning.description}</Text>
          </Stack>
          <DialogFooter>
            <Stack verticalAlign={"center"} horizontalAlign={"center"}>
              <Stack horizontal tokens={{childrenGap: 8}}>
                <DefaultButton onClick={copyToClipboard} text="Copy details" />
                <PrimaryButton onClick={refreshPage} text="Reload page" />
                
              </Stack>
            </Stack>
            <Stack>
                <Text>{new Date().toLocaleString()}</Text>
            </Stack>
          </DialogFooter>
        </Stack>
      </Dialog>        
    </>
  )
}