import React, { useEffect, useState } from 'react';
import TimeRow from '../Model/TimePeriodRowM';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { Callout, DefaultButton, DirectionalHint, FontWeights, PrimaryButton, Text, TextField } from '@fluentui/react';
import { useTarget, useId } from '@fluentui/react-hooks';
import { makeStyles, useTheme } from '@fluentui/react';
import { constants } from '../Styles/constants';

interface propsMyTimeDelete {
    deleteMyTime: Function;
    Time: TimeRow;
    enabled: boolean;
}
function MyTimeDelete(props: propsMyTimeDelete) {
    const targetId = useId();
    const styles = getStyles();

    const [showDeleteClickedCallOut, setShowDeleteClickedCallOut] = useState<boolean>(false);

    let clicked = e => {
        if (props.enabled) {
            setShowDeleteClickedCallOut(true);
        }
        // props.deleteMyTime(props.Time);
    };

    const onYesClick = (): void => {
        setShowDeleteClickedCallOut(false);
        props.deleteMyTime(props.Time);
    };

    const onNoClick = (): void => {
        setShowDeleteClickedCallOut(false);
    };

    return (
        <div className={props.enabled ? 'rowDeleteContainer' : 'rowDeleteContainerDisabled'} onClick={clicked}>
            <FontIcon id={targetId} iconName="Delete" className={props.enabled ? 'rowDeleteIcon' : 'rowDeleteIconDisabled'} />
            {showDeleteClickedCallOut && (
                <Callout
                    target={`#${targetId}`}
                    className={styles.callout}
                    ariaLabelledBy="{labelId}"
                    ariaDescribedBy="{descriptionId}"
                    role="alertdialog"
                    gapSpace={0}
                    directionalHint={DirectionalHint.topCenter}
                    // onDismiss={()=>{setShowTotalChangedCallOut(false);}}
                    setInitialFocus
                    dismissOnTargetClick={false}
                >
                    <div className={styles.header}>
                        <Text className={styles.title}>Are you sure you want to delete this line and the registered hours for the shown period?</Text>
                    </div>
                    <div className={styles.inner} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <PrimaryButton text={'Yes'} onClick={onYesClick} className="calloutYesBtn" />
                        <DefaultButton text={'No'} onClick={onNoClick} className="calloutNoBtn" />
                    </div>
                </Callout>
            )}
        </div>
    );
}

export default MyTimeDelete;

const getStyles = makeStyles(theme => ({
    root: [
        'cellInner',
        {
            position: 'relative',
            height: '100%',
            width: '100%',
            maxWidth: constants.cellInputMaxWidth,
            margin: '0 auto',
            display: 'flex',
        },
    ],
    buttonArea: {
        verticalAlign: 'top',
        display: 'inline-block',
        textAlign: 'center',
        margin: '0 100px',
        minWidth: 130,
        height: 32,
    },
    callout: {
        maxWidth: 800,
        minWidth: 300,
    },
    header: {
        padding: '18px 24px 12px',
        color: theme.semanticColors.bodyText,
    },
    title: [
        theme.fonts.mediumPlus,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
        },
    ],
    inner: {
        height: '100%',
        padding: '0 24px 20px',
    },
    actions: {
        position: 'relative',
        marginTop: 20,
        width: '100%',
        whiteSpace: 'nowrap',
    },
    subtext: [
        theme.fonts.small,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
        },
    ],
    link: [
        theme.fonts.medium,
        {
            color: theme.palette.neutralPrimary,
        },
    ],
}));
