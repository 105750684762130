import { useCallback, useMemo, useState } from 'react';
import { Callout, Checkbox, DirectionalHint, FontWeights, IconButton, Stack, Text, TooltipHost, makeStyles, useTheme } from '@fluentui/react';
import { useRowMetaContext } from '../../../context/RowMetaContext';
import { StateDropdown } from './components/StateDropdown';
import { ActivityDropdown } from './components/ActivityDropdown';
import { getLocaleFormattedDate } from '../../../../../../../_helpers/getLocaleFormattedDate';
import { UserReportingPeriodState } from '../../../../../../../api/generated/data-contracts';
import { TimeEntryApprovalDtoState, useRateState, useSelectedRowIdState } from '../../../../../../../_grid/grid2';
import { useGridLayoutContext } from '../../../context/LayoutProvider';
import { useTimeUIConfig } from '../../../../../../../hooks/useTimeUIConfig';

export const ApprovalRow = ({ row }: { row: TimeEntryApprovalDtoState }) => {
    const { gridRowStyles } = useGridLayoutContext();
    const { config } = useTimeUIConfig();
    return (
        <div style={gridRowStyles}>
            <RowHeader />
            <HoursColumn />
            <DateColumn />
            {config?.approval?.projectManagerApproval?.useRates && <RateColumn />}
            <CommentColumn />
            {config?.approval?.projectManagerApproval?.useRates && <ActivityDropdown />}
            <StateDropdown />
        </div>
    );
};

const RowHeader = () => {
    const { row } = useRowMetaContext<TimeEntryApprovalDtoState>();
    const { nestingLevel } = useRowMetaContext();
    const [selected, setSelected] = useSelectedRowIdState(row.id);

    const onChange = useCallback(
        (e: any, newValue?: boolean) => {
            setSelected(Boolean(newValue));
        },
        [setSelected],
    );
    return (
        <Stack style={{ paddingLeft: 20 * nestingLevel + 50 }} horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
            <Checkbox checked={selected} onChange={onChange} />
            <Text>{row.name}</Text>
        </Stack>
    );
};

const RateColumn = () => {
    const { row } = useRowMetaContext<TimeEntryApprovalDtoState>();
    const [rate] = useRateState(row.id);
    return (
        <Stack>
            <Text>{rate}</Text>
        </Stack>
    );
};

const HoursColumn = () => {
    const { row } = useRowMetaContext<TimeEntryApprovalDtoState>();
    const hours = useMemo(() => row.timeEntry.hours.toNumber(), [row.timeEntry.hours]);
    return (
        <Stack>
            <Text>{hours}</Text>
        </Stack>
    );
};

const DateColumn = () => {
    const { row } = useRowMetaContext<TimeEntryApprovalDtoState>();
    const date = useMemo(() => {
        return getLocaleFormattedDate(row.timeEntry.date);
    }, [row.timeEntry.date]);

    return (
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
            <Text>{date}</Text> <UserPeriod userPeriodState={row.userPeriodState} />
        </Stack>
    );
};

const UserPeriod = ({ userPeriodState }: { userPeriodState: UserReportingPeriodState }) => {
    const theme = useTheme();
    const { color, text } = useMemo(() => {
        switch (userPeriodState) {
            case 'Open':
            case 'Submitted':
                return {
                    color: theme.palette.yellow,
                    text: 'This time registration awaits approval by the manager.',
                };
            case 'Approved':
                return {
                    color: theme.palette.green,
                    text: 'This time registration has been approved by the manager.',
                };
            case 'Rejected':
            default:
                return {
                    color: theme.palette.red,
                    text: 'This time registration has been rejected by the manager.',
                };
        }
    }, [theme.palette.green, theme.palette.red, theme.palette.yellow, userPeriodState]);
    return (
        <TooltipHost content={text}>
            <div style={{ cursor: 'help', userSelect: 'none', backgroundColor: color, height: '1rem', width: '1rem', borderRadius: '100%' }} />
        </TooltipHost>
    );
};

const CommentColumn = () => {
    const { row } = useRowMetaContext<TimeEntryApprovalDtoState>();
    const [showComment, setShowComment] = useState(false);
    const id = 'comment-' + row.id;
    const styles = getStyles();
    const theme = useTheme();
    return (
        <Stack>
            {Boolean(row.timeEntry.comment) && <IconButton iconProps={{ iconName: 'Comment' }} id={id} onClick={() => setShowComment(true)} />}
            <Callout
                hidden={!showComment}
                target={`#${id}`}
                onDismiss={() => setShowComment(false)}
                ariaLabelledBy="{labelId}"
                ariaDescribedBy="{descriptionId}"
                role="alertdialog"
                gapSpace={0}
                directionalHint={DirectionalHint.leftBottomEdge}
                setInitialFocus
                dismissOnTargetClick={true}
                style={{
                    backgroundColor: theme.semanticColors.disabledBackground,
                }}
                calloutMaxWidth={400}
            >
                <Stack>
                    <div className={styles.header}>
                        <Text className={styles.title}>Comment</Text>
                    </div>
                    <div className={styles.inner}>{row.timeEntry.comment || ''}</div>
                </Stack>
            </Callout>
        </Stack>
    );
};

const getStyles = makeStyles(theme => ({
    callout: {
        maxWidth: 800,
        minWidth: 300,
    },
    header: {
        padding: '18px 24px 12px',
        color: theme.semanticColors.bodyText,
    },
    title: [
        theme.fonts.mediumPlus,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
        },
    ],
    inner: {
        height: '100%',
        padding: '0 24px 20px',
    },
}));
