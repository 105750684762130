import React, { useEffect, useState } from 'react';
import TimeRow from '../Model/TimePeriodRowM'
import { FontIcon } from '@fluentui/react/lib/Icon';
import '../Styles/MyTimeAdd.scss';
interface propsMyTimeAdd {
    pinned: boolean,
    timeId:string,
    isMyTime:boolean,
    pinMyTime:Function,
    Time:TimeRow
  }
function MyTimeAdd(props:propsMyTimeAdd) {
    const [pinned, setPinned] = useState<boolean>(false);
    const [groupiconrotation, setGroupiconrotation] = useState<string>("rotate(-90deg)");
    const [opacity, setOpacity] = useState<number>(.4);

    let clicked = (e) =>
    {
        if (pinned === false)
        {
            setPinned(true);
            //props.Time.pinned = true;
            props.pinMyTime(props.Time, true);
        } else
        {
            //if (!props.isMyTime)
            {
                setPinned(false);
            }
            //props.Time.pinned = false;
            props.pinMyTime(props.Time, false);
        }
    }
    useEffect(() => {
        setPinned(props.pinned);
    }, [props.pinned]);
    
    useEffect(() => {
        
        if (!pinned)
        {
            setGroupiconrotation("rotate(-90deg)");
            setOpacity(.4);
        } else 
        {
            setGroupiconrotation("rotate(-45deg)");
            setOpacity(1);
        }
        
    }, [pinned]);
    return (
        <div className={`pinContainer${pinned ? " activePin" : ""}`} onClick={clicked} >
           <FontIcon iconName="Pin" className="pinIcon" style={{
                                        transform:groupiconrotation,
                                        opacity: opacity
                                    }} />
        </div>
        
    );
}

export default MyTimeAdd;