import React, { useMemo, useRef } from 'react';
import { IPersonaProps, PersonaPresence, PersonaSize } from '@fluentui/react';
import AdminPicker from './AdminPicker';
import { useUiContext } from '../../../../components/Contexts/UiContext';
import { ContentSection, PageContent, PageHeader, SectionTitle } from '../../../../components/LayoutElements';
import { SpinLoaderSlow } from '../../../../components/SpinLoader';
import { GET_USERS } from '../../../../constants/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { TimeUser } from '../../../../api/generated/data-contracts';

export function AdminAdminConfig() {
    const { timeApi, setLoading } = useUiContext();

    const defaultData = useRef<TimeUser[]>([]).current;

    const { data: allUsers = defaultData, isLoading } = useQuery([GET_USERS], (): Promise<TimeUser[]> => timeApi.getUsers(), {
        onSuccess: () => {
            setLoading(false);
        },
        placeholderData: defaultData,
    });

    const { allUsersPersonas, currentAdminPersonas } = useMemo((): {
        allUsersPersonas: IPersonaProps[];
        currentAdminPersonas: IPersonaProps[];
    } => {
        return {
            allUsersPersonas: allUsers.map(user => {
                return mapUserToPersona(user);
            }),

            currentAdminPersonas: allUsers
                .filter(user => user.isAdmin === true)
                .map(user => {
                    return mapUserToPersona(user);
                }),
        };
    }, [allUsers]);

    if (isLoading) {
        return <SpinLoaderSlow />;
    }

    console.log('allUsersPersonas', allUsersPersonas);

    return (
        <>
            <PageHeader title="Admin configuration" icon="Admin" />

            <PageContent>
                <ContentSection alignContent="center">
                    <SectionTitle title="Add or remove an admin in this list" />
                    <AdminPicker allUsersPersonas={allUsersPersonas} currentAdminPersonas={currentAdminPersonas} />
                </ContentSection>
            </PageContent>
        </>
    );

    function mapUserToPersona(userPicker: any) {
        const assumedInitials = userPicker.principalName.split('@')[0].toUpperCase();
        const initialsBasedOnName = userPicker.name?.substring(0, 1) + userPicker.surname?.substring(0, 1);

        // If the user has a principal name with more than 4 characters, use the initials based on the name
        // Otherwise use the initials from the principal name
        let initials = assumedInitials.length > 4 ? initialsBasedOnName : assumedInitials;

        return {
            //imageUrl: 'https://outlook.office.com/owa/service.svc/s/GetPersonaPhoto?email=' + userPicker.email + '&UA=0&size=HR48x48',
            imageInitials: initials,
            text: userPicker.name,
            secondaryText: userPicker.userNumber ?? userPicker.jobTitle ?? '',
            optionalText: userPicker.principalName,
            showSecondaryText: true,
            size: PersonaSize.size40,
            presence: PersonaPresence.none,
            imageAlt: userPicker.name + ', ' + userPicker.jobTitle,
            itemID: userPicker.id,
        } as IPersonaProps;
    }
}
