import { useMemo, useCallback, useState } from 'react';
import { Activity } from '../../../../../../../../api/generated/data-contracts';
import { Dropdown, IDropdownOption, IRenderFunction, Stack, TextField } from '@fluentui/react';
import { ApiCalls } from '../../../../../../../../api/api';
import { useMutation } from '@tanstack/react-query';
import { useActivities } from '../../../../context/ActivitiesProvider';
import { useRowMetaContext } from '../../../../context/RowMetaContext';
import { TimeEntryApprovalDtoState, useActivityState, useRateState } from '../../../../../../../../_grid/grid2';
import { CUSTOM_RATE_ID } from '../../../../CONSTANTS';

const SET_NULL = 'SET_NULL';

export const ActivityDropdown = () => {
    const activities = useActivities();
    const { row } = useRowMetaContext<TimeEntryApprovalDtoState>();
    const options = useMemo(
        (): IDropdownOption<Activity | null>[] => [
            ...activities.map(activity => {
                return {
                    key: activity.id,
                    text: activity.name,
                    data: activity,
                };
            }),
            {
                key: SET_NULL,
                text: 'Deselect',
                data: null,
            },
        ],
        [activities],
    );
    const [activity, setActivity] = useActivityState(row.id);
    const [showManualOverlay, setShowManualOverlay] = useState(false);
    const { mutate: mutateCustomRate } = useMutation((rate: number) => ApiCalls.setCustomRateForApproval({ rate, timeEntryId: row.timeEntry.id }));
    const { mutate: mutateActivity } = useMutation((selectedActivity: Activity) => ApiCalls.setActivity({ selectedActivity, timeEntryId: row.timeEntry.id }));

    const [rate, setRate] = useRateState(row.id);

    const onChangeDropdown = useCallback(
        (e: any, option: IDropdownOption<Activity>) => {
            if (option) {
                const newActivity = option.data;
                const oldActivity = activity;
                if (oldActivity && newActivity && newActivity.id === oldActivity.id && newActivity.id !== CUSTOM_RATE_ID) {
                    return; // User has chosen the same non-custom activity, do nothing
                }
                if (newActivity?.id === CUSTOM_RATE_ID) {
                    setShowManualOverlay(true);
                } else {
                    mutateActivity(newActivity);
                }
                setActivity(newActivity);
                if (option.key === SET_NULL) {
                    setRate(row.originalRate);
                }
            }
        },
        [activity, mutateActivity, row.originalRate, setActivity, setRate],
    );

    const [localRateState, setLocalRateState] = useState(rate);

    const onRenderTitle = useCallback<IRenderFunction<IDropdownOption<Activity>[]>>(
        ([option]: [IDropdownOption<Activity>], defaultRender) => {
            if ((option as unknown as IDropdownOption<Activity>).data.id === CUSTOM_RATE_ID) {
                return defaultRender?.([{ ...option, text: 'Manually entered: ' + localRateState }]);
            }
            return defaultRender?.([option]);
        },
        [localRateState],
    );

    const onBlur = useCallback(() => {
        setShowManualOverlay(false);
        mutateCustomRate(localRateState);
        setRate(localRateState);
    }, [localRateState, mutateCustomRate, setRate]);

    return (
        <Stack horizontal>
            {showManualOverlay ? (
                <TextField
                    autoFocus
                    styles={{ root: { width: 200 } }}
                    type="number"
                    onChange={(e, newValue) => {
                        const val = Number(newValue);
                        if (val >= 0) {
                            setLocalRateState(Number(newValue));
                        }
                    }}
                    value={String(localRateState)}
                    onBlur={onBlur}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            (e.target as HTMLDivElement).blur();
                        }
                    }}
                />
            ) : (
                <Dropdown
                    styles={{ root: { width: 200 } }}
                    options={options}
                    selectedKey={activity?.id || null}
                    onChange={onChangeDropdown}
                    onRenderTitle={onRenderTitle}
                    placeholder="Override rate"
                    disabled={row.approvalState === 'Approved'}
                />
            )}
        </Stack>
    );
};
