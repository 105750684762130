import { ResourceListColumns } from '../resourceHelpers';
import { Stack, DetailsList, SelectionMode, DetailsListLayoutMode, makeStyles } from '@fluentui/react';
import { TimeSpinner } from '../../../../../_components/Loader/TimeSpinner';
import { useOnRender } from '../hooks/useOnRender';
import { useSearchUsers } from '../hooks/useSearchUsers';
import { useStore } from '../../../../../store/useStore';

export const ResourceList = () => {
    const classes = useStyles();
    const { onRenderRow, onRenderItemColumn } = useOnRender({ classes });

    const { isFetching } = useSearchUsers();

    const currentUsers = useStore(state => state.currentUsers);

    return (
        <Stack>
            {isFetching ? (
                <TimeSpinner label="Searching for Users" />
            ) : (
                <DetailsList
                    items={currentUsers}
                    columns={ResourceListColumns()}
                    onRenderRow={(renderProps: any) => onRenderRow(renderProps)}
                    onRenderItemColumn={(item, index, column) => onRenderItemColumn(item, index, column)}
                    selectionMode={SelectionMode.none}
                    layoutMode={DetailsListLayoutMode.justified}
                    styles={{
                        root: {
                            position: 'static',
                            overflow: 'unset',
                            '.ms-DetailsRow-cell': { padding: 0, display: 'flex', alignItems: 'center' },
                            '.ms-DetailsHeader': {
                                paddingTop: 4,
                            },
                        },
                        headerWrapper: {
                            position: 'sticky',
                            top: 0,
                            zIndex: 10,
                        },
                    }}
                />
            )}
        </Stack>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%',
    },
    innerContainer: {
        position: 'relative',
        flexGrow: 1,
        paddingTop: 8,
    },
    selectedRow: {
        backgroundColor: theme.semanticColors.listItemBackgroundChecked,
        '&:hover': {
            backgroundColor: theme.semanticColors.listItemBackgroundCheckedHovered,
        },
    },
    disabledRow: {
        backgroundColor: 'rgba(0,0,0,0) !important',
        cursor: 'not-allowed',
        '& > *': {
            opacity: 0.5,
        },
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0) !important',
        },
    },
}));
