import React, { useRef, useState, useMemo } from 'react';
import { TimeSubmitIcon, TimeApprovedGreyedOutIcon, TimeSubmitGreyedOutIcon, TimeOpenIcon, TimeOpenGreyedOutIcon, TimeApprovedIcon } from '../Icons';
import { Stack, Label, TooltipHost, Text, FontIcon, IStackTokens, mergeStyleSets } from '@fluentui/react';
import '../Styles/TimeStatePicker.scss';
import { TimeState } from '../Model/State';
import { useUiContext } from './Contexts/UiContext';
import { TextField, PrimaryButton, DefaultButton, Callout, FontWeights, DirectionalHint } from '@fluentui/react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { makeStyles } from '@fluentui/react';
import TimePeriodRowM from '../Model/TimePeriodRowM';

interface propsTimeStatePicker {
    state: TimeState;
    stateChanged: Function;
    periodIsClosed: boolean;
    comment: string;
    mytimeprojects: TimePeriodRowM[];
}

function TimeStatePicker(props: propsTimeStatePicker) {
    const uiCtx = useUiContext();
    const styles = getStyles();

    const [showdetailsflyout, setShowdetailsflyout] = useState<boolean>(false);
    const [reopenDialogHidden, setReopenDialogHidden] = useState<boolean>(true);
    const [submitDialogHidden, setSubmitDialogHidden] = useState<boolean>(true);
    const [submitComment, setSubmitComment] = useState<string>('');

    const approveIconRef = useRef(null);

    const emptyTimeSheetNotAllowed = useMemo(() => uiCtx.enabledRules.find(_ => _.name === 'EmptyTimeSheetNotAllowed')?.enabled ?? false, [props]);
    const anyTimeEntries = useMemo(() => props.mytimeprojects.flatMap(_ => _.timeEntries).some(_ => _.hours > 0), [props.mytimeprojects]);

    const openclicked = (): void => {
        setReopenDialogHidden(false);
    };
    const reopenperiod = (): void => {
        props.stateChanged(TimeState.open);
    };

    const submitclicked = (): void => {
        setSubmitDialogHidden(false);
    };
    const executeSubmit = (): void => {
        props.stateChanged(TimeState.submitted, submitComment);
    };

    return (
        <>
            <Stack horizontal tokens={{ childrenGap: 16 }} verticalAlign="center">
                <Stack tokens={{ childrenGap: 2 }}>
                    {props.periodIsClosed ? (
                        props.state === TimeState.open || props.state === TimeState.rejected ? (
                            <div className="buttoncontaynerstyle timestatedisabled">
                                {/* <Timeopen /> */}
                                <div style={{ flexGrow: 1 }}></div>
                                <TimeOpenIcon />
                                <Text className="timestatelabel">Open</Text>
                            </div>
                        ) : (
                            <div className="buttoncontaynerstyle timestatedisabled">
                                {/* <Timeopengray /> */}
                                <div style={{ flexGrow: 1 }}>
                                    <TimeOpenGreyedOutIcon />
                                </div>
                                <Text className="timestatelabel">Open</Text>
                            </div>
                        )
                    ) : props.state === TimeState.open || props.state === TimeState.rejected ? (
                        <div className="buttoncontaynerstyle timestateenabled">
                            {/* <Timeopen /> */}
                            <div style={{ flexGrow: 1 }}>
                                <TimeOpenIcon />
                            </div>
                            <Text className="timestatelabel">Open</Text>
                        </div>
                    ) : (
                        <div className="buttoncontaynerstyle timestateenabled" onClick={openclicked}>
                            {/* <Timeopengray onClick={openclicked} /> */}
                            <div style={{ flexGrow: 1 }}>
                                <TimeOpenGreyedOutIcon />
                            </div>
                            <Text className="timestatelabel">Open</Text>
                        </div>
                    )}
                </Stack>
                <Stack>
                    {props.periodIsClosed ? (
                        props.state === TimeState.submitted ? (
                            <div id={`tft-submit`} className="buttoncontaynerstyle timestatedisabled">
                                {/* <Timesubmit /> */}
                                <div style={{ flexGrow: 1 }}>
                                    <TimeSubmitIcon />
                                </div>
                                <Text className="timestatelabel">Submitted</Text>
                            </div>
                        ) : (
                            <div id={`tft-submit`} className="buttoncontaynerstyle timestatedisabled">
                                <div style={{ flexGrow: 1 }}>
                                    <TimeSubmitIcon />
                                </div>
                                <Text className="timestatelabel">Submit</Text>
                            </div>
                        )
                    ) : props.state === TimeState.submitted ? (
                        <div id={`tft-submit`} className="buttoncontaynerstyle timestateenabled">
                            {/* <Timesubmit /> */}
                            <div style={{ flexGrow: 1 }}>
                                <TimeSubmitIcon />
                            </div>
                            <Text className="timestatelabel">Submitted</Text>
                        </div>
                    ) : props.state === TimeState.open || props.state === TimeState.rejected ? (
                        <div id={`tft-submit`} className="buttoncontaynerstyle timestateenabled" onClick={submitclicked}>
                            {/* <Timesubmitgray onClick={submitclicked} /> */}
                            <div style={{ flexGrow: 1 }}>
                                <TimeSubmitGreyedOutIcon />
                            </div>
                            <Text className="timestatelabel">Submit</Text>
                        </div>
                    ) : (
                        <div id={`tft-submit`} className="buttoncontaynerstyle">
                            <div style={{ flexGrow: 1 }}>
                                <TimeSubmitGreyedOutIcon />
                            </div>
                            <Text className="timestatelabel">Submit</Text>
                        </div>
                    )}
                </Stack>
                <Stack>
                    <div
                        ref={approveIconRef}
                        onClick={() => {
                            if (props.comment !== '') {
                                //setShowdetailsflyout(true)
                            }
                        }}
                    >
                        {props.periodIsClosed ? (
                            props.state === TimeState.approved ? (
                                <div id={`tft-approve`} className="buttoncontaynerstyle timestatedisabled">
                                    {/* <Timeapproved /> */}
                                    <div style={{ flexGrow: 1 }}>
                                        <TimeApprovedIcon />
                                    </div>
                                    <Text className="timestatelabel">Approved</Text>
                                </div>
                            ) : (
                                <div id={`tft-approve`} className="buttoncontaynerstyle timestatedisabled">
                                    <TooltipHost
                                        content={
                                            uiCtx.user.user.autoApprove
                                                ? 'When your time is submitted it will automatically be approved if all validation rules are successful'
                                                : 'When your time is submittet your manager can approve this period'
                                        }
                                        closeDelay={500}
                                    >
                                        {/* <Timeapprovedgray /> */}
                                        <div style={{ flexGrow: 1 }}>
                                            <TimeApprovedGreyedOutIcon />
                                        </div>
                                        <Text className="timestatelabel">Approve</Text>
                                    </TooltipHost>
                                </div>
                            )
                        ) : props.state === TimeState.approved ? (
                            <div id={`tft-approve`} className="buttoncontaynerstyle timestatedisabled">
                                {/* <Timeapproved /> */}
                                <div style={{ flexGrow: 1 }}>
                                    <TimeApprovedIcon />
                                </div>
                                <Text className="timestatelabel">Approved</Text>
                            </div>
                        ) : (
                            <div id={`tft-approve`} className="buttoncontaynerstyle timestatedisabled">
                                <TooltipHost
                                    content={
                                        uiCtx.user.user.autoApprove
                                            ? 'When your time is submitted it will automatically be approved if all validation rules are successful'
                                            : 'When your time is submittet your manager can approve this period'
                                    }
                                    closeDelay={500}
                                >
                                    {/* <Timeapprovedgray /> */}
                                    <div style={{ flexGrow: 1 }}>
                                        <TimeApprovedGreyedOutIcon />
                                    </div>
                                    <Text className="timestatelabel">Approve</Text>
                                </TooltipHost>
                            </div>
                        )}
                    </div>
                    {showdetailsflyout && (
                        <Callout
                            directionalHint={DirectionalHint.bottomAutoEdge}
                            directionalHintFixed={true}
                            className={styles.callout}
                            role="alertdialog"
                            gapSpace={0}
                            target={approveIconRef}
                            onDismiss={() => {
                                setShowdetailsflyout(false);
                            }}
                        >
                            <div className={styles.header}>
                                <Text className={styles.title}>Approver comment</Text>
                            </div>
                            <div className={styles.inner}>
                                <Text>{props.comment}</Text>
                            </div>
                        </Callout>
                    )}
                    <Dialog
                        hidden={reopenDialogHidden}
                        onDismiss={() => {
                            setReopenDialogHidden(true);
                        }}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Retract submit',
                            closeButtonAriaLabel: 'Close',
                            subText:
                                props.state === TimeState.approved
                                    ? 'You are about to retract a timesheet that is already approved. Do you want to continue?'
                                    : 'You are about to retract a timesheet. Do you want to continue?',
                        }}
                    >
                        <DialogFooter>
                            <PrimaryButton
                                onClick={() => {
                                    setReopenDialogHidden(true);
                                    reopenperiod();
                                }}
                                text="Retract"
                            />
                            <DefaultButton
                                onClick={() => {
                                    setReopenDialogHidden(true);
                                }}
                                text="Cancel"
                            />
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        hidden={submitDialogHidden}
                        onDismiss={() => {
                            setSubmitDialogHidden(true);
                        }}
                        minWidth={500}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Submit',
                            closeButtonAriaLabel: 'Close',
                        }}
                    >
                        <Label>
                            By submitting this timesheet, I confirm that the work recorded on this time sheet is correct and reflecting the actual worked hours
                            performed.
                        </Label>
                        {!anyTimeEntries && emptyTimeSheetNotAllowed && (
                            <Stack tokens={verticalGapStackTokens}>
                                <Stack horizontal verticalAlign="center" className={warningStyles.root}>
                                    <FontIcon aria-label="Warning" iconName="Warning" className={warningStyles.icon} />
                                    <Label className={warningStyles.label}>Warning!</Label>
                                </Stack>
                                <Label>Submitting a blank time sheet will report you for zero work this period.</Label>
                                <Label>Are you sure you want to proceed?</Label>
                            </Stack>
                        )}
                        {/* If time sheet comment is allowed, show text field */}
                        {uiCtx.allowTimeSheetComment && (
                            <TextField
                                value={submitComment}
                                onChange={(e, n) => {
                                    setSubmitComment(n);
                                }}
                                multiline
                                autoAdjustHeight
                            />
                        )}
                        <DialogFooter>
                            <PrimaryButton
                                onClick={() => {
                                    setSubmitDialogHidden(true);
                                    executeSubmit();
                                }}
                                text="Submit"
                            />
                            <DefaultButton
                                onClick={() => {
                                    setSubmitDialogHidden(true);
                                }}
                                text="Cancel"
                            />
                        </DialogFooter>
                    </Dialog>
                </Stack>
            </Stack>
        </>
    );
}

export default TimeStatePicker;

const warningStyles = mergeStyleSets({
    root: {
        fontSize: '20px',
    },
    icon: {
        color: 'red',
    },
    label: {
        marginLeft: '5px !important',
    },
});

const verticalGapStackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
};

const getStyles = makeStyles(theme => ({
    callout: {
        maxWidth: 800,
        minWidth: 300,
    },
    header: {
        padding: '18px 24px 12px',
    },
    title: [
        theme.fonts.mediumPlus,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
        },
    ],
    inner: {
        height: '100%',
        padding: '0 24px 20px',
    },
}));
