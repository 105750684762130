import React, { useEffect, useState } from 'react';
import { useUiContext } from '../components/Contexts/UiContext';
import { Stack, Dropdown } from '@fluentui/react';
import { PageContent, ContentSection } from '../components/LayoutElements';
import '../Styles/Charts.scss';
import '../Styles/PMCharts.scss';
import DelegateArea from '../components/DelegateArea';
import { QuotesLoader } from '../components/QuotesLoader';
import { getCookie, getParameterByName, setCookie } from '../components/Utils';
import PMChartHoursOverTime from './PMChartHoursOverTime';
import PMChartSunburst from './PMChartSunburst';
import { FontIcon } from '@fluentui/react/lib/Icon';
import PMApproveDetailslist from './PMApproveDetailslist';
import { mergeStyles } from '@fluentui/react';

var pmchartdata = null;
const itemStyles: React.CSSProperties = {
    alignItems: 'center',
    display: 'flex',
    height: 60,
    width: 354,
    justifyContent: 'center',
};
/*const nowMinusXMonths = (months?: number): Date => {
    let now = new Date();
    if (months == null || months == 0) return now;
    now.setMonth(now.getMonth() - months);
    return now;
}*/

function PMCharts() {
    const uiCtx = useUiContext();
    const [lineChartData, setLineChartData] = useState<any[]>([]);
    const [sunburstChartData, setSunburstChartData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [availableProjects, setAvailableProjects] = useState<any[]>([]);
    const [selectedProject, setSelectedProject] = useState<any>(null);
    const [tasksandresources, setTasksandresources] = useState<any[]>([]);
    const [projectidinframe, setProjectidinframe] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean>(true);
    const [groupiconrotation, setGroupiconrotation] = useState<string>('rotate(-180deg)');
    const [initialload, setInitialload] = useState<boolean>(true);

    let updateCharts = (selection): void => {
        let _linechartdata = [];

        if (pmchartdata != null) {
            pmchartdata.chartDataTasks.forEach(tsk => {
                tsk.taskresources.forEach(res => {
                    if (selection.length <= 0 || selection.find(sres => res.assignmentid === sres.key) !== undefined) {
                        res.actuals.forEach(act => {
                            let lcd = _linechartdata.find(lcd => lcd.date === act.date);
                            if (lcd === undefined || lcd == null) {
                                _linechartdata.push({ date: act.date, hours: act.hours, plannedhours: act.plannedHours });
                            } else {
                                lcd.hours = lcd.hours + act.hours;
                            }
                        });
                    }
                });
            });
            setLineChartData(_linechartdata);

            let _sunburstChartData = [];

            pmchartdata.chartDataTasks.forEach(tsk => {
                tsk.taskresources.forEach(res => {
                    if (selection.length <= 0 || selection.find(sres => res.resourcename === (sres as any).user) !== undefined) {
                        let sbcd = _sunburstChartData.find(sbcd => sbcd.resource === res.resourcename);
                        if (sbcd === undefined || sbcd == null) {
                            sbcd = { resource: res.resourcename, hours: 0 };
                            _sunburstChartData.push(sbcd);
                        }
                        res.actuals.forEach(act => {
                            sbcd.hours = sbcd.hours + act.hours;
                        });
                    }
                });
            });
            setSunburstChartData(_sunburstChartData);
        }
    };

    useEffect(() => {
        var urlprojectid = getParameterByName('id');

        if (urlprojectid !== undefined && urlprojectid != null && urlprojectid !== '') {
            setProjectidinframe(true);
            setSelectedProject(urlprojectid);
            setLoading(false);
            uiCtx.setLoading(false);
        } else {
            uiCtx.timeApi.getPMChartProjects().then(projects => {
                let sortedprojects = projects
                    .map(p => ({ key: p.id, text: p.name }))
                    .sort((a: any, b: any) => {
                        return a.text < b.text ? -1 : 1;
                    });
                setAvailableProjects(sortedprojects);

                setSelectedProject(sortedprojects[0].key);
                setLoading(false);
                uiCtx.setLoading(false);
            });
        }
    }, []);

    useEffect(() => {
        if (selectedProject != null) {
            setLoading(true);
            uiCtx.timeApi.getPMChartData(selectedProject).then(projectdata => {
                pmchartdata = projectdata;
                var _tasksandresources = [];
                projectdata.chartDataTasks.forEach(tsk => {
                    tsk.taskresources.forEach(res => {
                        _tasksandresources.push({
                            key: res.assignmentid,
                            task: tsk.name,
                            user: res.resourcename,
                            worktype: res.workType,
                            hours: res.totalHours,
                            plannedhours: res.totalPlannedHours,
                        });
                    });
                });

                setTasksandresources(_tasksandresources);

                setLoading(false);
            });
        }
    }, [selectedProject]);

    useEffect(() => {
        if (initialload) {
            setInitialload(false);
            let cexpanded = !(getCookie('pmchartgraphexpanded') === 'false') ?? true;
            // let cexpanded = (getCookie('pmchartgraphexpanded') == 'false')?false:true;
            setExpanded(cexpanded);
        }
    }, [tasksandresources, initialload]);

    return (
        <>
            <ContentSection>
                <Stack horizontal horizontalAlign="space-between" styles={{ root: { width: '100%' } }}>
                    {projectidinframe === true ? (
                        <></>
                    ) : (
                        <span style={itemStyles}>
                            <Dropdown
                                style={{ width: 300 }}
                                placeholder="Select project"
                                selectedKey={selectedProject}
                                //label="User"
                                options={availableProjects}
                                //selectedKey={selectedUsers.map(u => { return u.key })}
                                //multiSelect
                                styles={{ dropdown: { width: 150 } }}
                                onChanged={options => {
                                    setSelectedProject(options.key);
                                }}
                            />
                        </span>
                    )}
                </Stack>
            </ContentSection>

            <PageContent>
                {loading ? (
                    <QuotesLoader loadingtext="Getting data..." />
                ) : (
                    <ContentSection>
                        <Stack tokens={{ childrenGap: 0 }} verticalAlign="baseline" styles={{ root: { width: '100%' } }}>
                            <Stack.Item
                                className={mergeStyles({
                                    maxHeight: !expanded ? '0' : '30vh',
                                    transform: !expanded ? 'translateY(-130px) scaleY(0)' : 'translateY(0px) scaleY(1)',
                                    transition: 'all .5s ease 0s',
                                })}
                                // style={{
                                //     maxHeight: !expanded?'0':'30vh',
                                //     transform: !expanded?'translateY(-130px) scaleY(0)':'translateY(0px) scaleY(1)',
                                //     transition: 'all .5s ease 0s',
                                // }}
                            >
                                <Stack horizontal tokens={{ childrenGap: 50 }} verticalAlign="baseline" styles={{ root: { width: '100%', marginLeft: 50 } }}>
                                    <Stack.Item grow={3}>
                                        <div>
                                            <Stack horizontal tokens={{ childrenGap: 50 }} verticalAlign="baseline" styles={{ root: { width: '100%' } }}>
                                                <Stack.Item grow={3}>
                                                    <div style={{ height: '30vh', width: '100%' }}>
                                                        <PMChartHoursOverTime LineChartData={lineChartData} />
                                                    </div>
                                                </Stack.Item>
                                            </Stack>
                                        </div>
                                    </Stack.Item>
                                    <Stack.Item grow={1}>
                                        <div style={{ height: '30vh', width: '100%', cursor: 'pointer' }}>
                                            <PMChartSunburst SunburstChartData={sunburstChartData} />
                                        </div>
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item
                                // style={{marginTop:0, textAlign:'center'}}
                                className={mergeStyles({
                                    marginTop: 10,
                                    textAlign: 'center',
                                })}
                                align="center"
                            >
                                <div
                                    className="expander"
                                    onClick={(ev: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>): void => {
                                        if (expanded) {
                                            setGroupiconrotation('rotate(0deg)');
                                        } else {
                                            setGroupiconrotation('rotate(-180deg)');
                                        }
                                        setExpanded(e => !e);
                                        setCookie('pmchartgraphexpanded', expanded ? 'false' : 'true', 30);
                                    }}
                                >
                                    <div className="expanderInner">
                                        <FontIcon
                                            iconName="ChevronDown"
                                            style={{
                                                transform: groupiconrotation,
                                                transition: 'transform .2s ease 0s',
                                                userSelect: 'none',
                                            }}
                                        />
                                    </div>
                                </div>
                            </Stack.Item>
                            <Stack.Item
                                className={mergeStyles({ marginTop: 0 })}
                                // style={{marginTop:0}}
                            >
                                <PMApproveDetailslist Tasksandresources={tasksandresources} TaskSelectionChanged={updateCharts} />
                            </Stack.Item>
                        </Stack>
                    </ContentSection>
                )}
            </PageContent>
            <DelegateArea />
        </>
    );
}

export default PMCharts;
