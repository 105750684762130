import { useTheme } from '@fluentui/react';
import { mergeStyles } from '@fluentui/react';
import React from 'react';

interface IProps {
    grow?: true | number;
    noSideSpacing?: boolean;
    maxWidth?: 'medium' | 'large';
    isDetailslist?: boolean;
    noPadding?: boolean;
    noPaddingBottom?: boolean;
    noPaddingTop?: boolean;
    alignContent?: 'left' | 'center' | 'flex-start' | 'flex-end' | 'stretch';
    stickyTop?: number;
    detailslistStickyHeader?: boolean;
}

export const ApproverFilterSection: React.FC<IProps> = props => {
    const theme = useTheme();
    const growStyle = typeof props.grow === 'number' ? { flexGrow: props.grow } : {};
    const alignStyle = { alignItems: props.alignContent ? props.alignContent : 'left' };
    const stickyTopStyle: React.CSSProperties = props.stickyTop !== undefined ? { top: props.stickyTop, position: 'sticky', zIndex: 2 } : {};

    const detailslistStickyHeaderClass = mergeStyles({ '.ms-DetailsList-headerWrapper': { position: 'sticky', top: 0, zIndex: 2 } });

    return (
        <section
            className={`content_section${props.isDetailslist ? ' is_detailslist' : ' side_spacing'}${props.detailslistStickyHeader ? ` ${detailslistStickyHeaderClass}` : ''}${
                props.grow === true || props.isDetailslist ? ' grow1' : ''
            }${props.noPadding || props.noPaddingTop || props.isDetailslist ? '' : ' padding_top'}${props.noPadding || props.noPaddingBottom || props.isDetailslist ? '' : ' padding_bottom'}`}
            style={{ ...growStyle, ...stickyTopStyle, backgroundColor: theme.semanticColors.bodyBackground }}
        >
            <div className="flex_content" style={alignStyle}>
                {props.children}
            </div>
        </section>
    );
};
