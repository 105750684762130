import React, { Fragment } from 'react';
import { ValidateResponse } from '../Validator';
import { Text } from '@fluentui/react';
import { useUiContext } from '../../components/Contexts/UiContext';

interface ValidationErrorsProps {
    validationResponses: ValidateResponse[];
}

export const ValidationErrors = (props: ValidationErrorsProps) => {
    const uiCtx = useUiContext();
    return (
        <Fragment>
            {props.validationResponses.map(res => (
                <Text
                    styles={{
                        root: {
                            color: uiCtx.theme.semanticColors.warningBackground,
                        },
                    }}
                    variant="xLarge"
                    key={res.name}
                >
                    {res.reason}
                </Text>
            ))}
        </Fragment>
    );
};
