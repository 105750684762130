import Divider from "@material-ui/core/Divider";
import { Callout, ContextualMenu, DirectionalHint, IModalProps, Link } from "@fluentui/react";
import { DefaultButton, IconButton, PrimaryButton } from "@fluentui/react/lib/Button";
import Dialog, { DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { useId } from '@fluentui/react-hooks';
import { Stack } from "@fluentui/react/lib/Stack";
import { Text } from "@fluentui/react/lib/Text";
import React from "react";
import { useState } from "react";
import { TimeError } from "../Model/TimeError";
import HelpSupport from "../Help/HelpSupport";

const modelProps: IModalProps = {
    isBlocking: false,
    topOffsetFixed: true,
    // dragOptions: {
    //   moveMenuItemText: 'Move',
    //   closeMenuItemText: 'Close',
    //   menu: ContextualMenu,
    //   keepInBounds: true,
    // }
};

const tokens = {
    sectionStack: {
      childrenGap: 10,
    },
    headingStack: {
      childrenGap: 12,
    },
    buttonStack: {
      childrenGap: 10
    },
    contentStack: {
      childrenGap: 5
    }
};

interface IProps {
  error: TimeError | null
}

export const ErrorDialog: React.FC<IProps> = (props) => {
    const [showTechnicalDetails, setShowTechnicalDetails] = useState(false);
    const [showHelpCallout, setShowHelpCallout] = useState<boolean>(false);
    const targetId = useId();
    
    function toggleDetails(ev: React.MouseEvent<HTMLButtonElement>): void {
        setShowTechnicalDetails(!showTechnicalDetails);
    }

    const refreshPage = () => {
        window.location.reload();
    }
  
    function copyToClipboard() {
      navigator.clipboard.writeText(
        `${props.error.message}\n
        In plugin with ID: ${props.error.pluginId}\n
        StackTrace:\n
        ${props.error.stackTrace}`)
    }

  return (
    <>
      <Dialog containerClassName={'errorDialog'} /*dialogContentProps={{title: "We are very sorry, but an error occured"}}*/ hidden={props.error?.message ? false : true} modalProps={modelProps}>
        <Stack tokens={tokens.sectionStack}>
          <Stack horizontalAlign={"center"} tokens={tokens.headingStack}>
            <Text variant={"xLargePlus"}>We are very sorry, but an error occured</Text>
            <Text variant={"mediumPlus"}>If this error persists, please contact <Link as="span" id={targetId} style={{cursor:'pointer', fontWeight: 600}} onClick={()=>{setShowHelpCallout(true)}}>PMO Support</Link></Text>
            
            {showHelpCallout && 
              <Callout
                  calloutWidth={800}
                  ariaLabelledBy="{labelId}"
                  ariaDescribedBy="{descriptionId}"
                  role="alertdialog"
                  gapSpace={0}
                  target={`#${targetId}`}
                  directionalHint={DirectionalHint.bottomCenter}
                  onDismiss={()=>{setShowHelpCallout(false)}}
                  setInitialFocus
                  dismissOnTargetClick={true}>
                  <HelpSupport />
              </Callout>
            }
          </Stack>

          <Stack horizontal verticalAlign={"center"} horizontalAlign={"center"} tokens={tokens.buttonStack}>
              <IconButton iconProps={{iconName: "BugSolid"}} styles={{icon: {fontSize:26, marginTop:10, verticalAlign:'center', alignContent:'center', borderWidth:0}}} onClick={toggleDetails} />
          </Stack>

          {showTechnicalDetails && (
              <Stack tokens={tokens.contentStack}>
                  <h5>Message</h5>
                  <Text>{props.error?.message}</Text>
                  <Divider />
                  <h5>Plugin ID</h5>
                  <Text>{props.error?.pluginId}</Text>
                  <Divider />
                  <h5>StackTrace</h5>
                  <Text>{props.error?.stackTrace}</Text>
              </Stack>
          )}
          <DialogFooter>
            <Stack verticalAlign={"center"} horizontalAlign={"center"}>
              <Stack horizontal tokens={{childrenGap: 8}}>
                <DefaultButton onClick={copyToClipboard} text="Copy details" />
                <PrimaryButton onClick={refreshPage} text="Reload page" />
                
              </Stack>
            </Stack>
          </DialogFooter>
        </Stack>
      </Dialog>        
    </>
  )
}