export const ConnectionIcon = ({ connected, ...props }: { connected: boolean } & React.SVGProps<SVGSVGElement>) => {
    return (
        <svg focusable="false" version="1.1" viewBox="0 0 2048 2048" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                style={{ transform: connected ? 'translate(12.5%, -12.5%)' : 'translate(0%,0%)', transition: '1s' }}
                d="m1300 1201-271 271 90 90-226 227c-42 42-90.333 74.333-145 97s-112 34-172 34c-48.667 0-95.667-7.3333-141-22s-87.667-37-127-67l-199 198-90-90 198-199c-29.333-38.667-51.333-80.667-66-126s-22.333-92.667-23-142c0-59.333 11.333-116.33 34-171s55-103.33 97-146l227-226 90 90 271-271 91 90-272 272 272 272 272-272zm-724 591c42.667 0 83.333-8 122-24s73.333-39.333 104-70l136-136-452-452-136 136c-30 30-53 64.333-69 103s-24.333 79.667-25 123c0 44 8.3333 85.333 25 124s39.333 72.667 68 102 62.667 52.333 102 69 81 25 125 25z"
            />
            <g style={{ transform: connected ? 'translate(-12.5%, 12.5%)' : 'translate(0%,0%)', transition: '1s' }}>
                <path d="m1831 308c29.333 38.667 51.333 80.667 66 126s22.333 92.667 23 142c0 59.333-11.333 116.33-34 171s-55 103.33-97 146l-227 226-633-633 226-227c42-42 90.333-74.333 145-97 54.667-22.667 112-34 172-34 48.667 0 95.667 7.3333 141 22 45.333 14.667 87.667 37 127 67l199-198 90 90zm-133 494c30-30 53-64.333 69-103s24.333-79.667 25-123c0-44-8.3333-85.333-25-124s-39.667-72.333-69-101-63.333-51.667-102-69-80-26-124-26c-42.667 0-83.333 8-122 24s-73.333 39.333-104 70l-136 136 452 452z" />
                <path
                    d="m1336.5 711.39-224.67-224.72 81.572-81.068c85.323-84.796 98.199-95.545 138.67-115.76 24.229-12.103 60.69-23.713 89.17-28.394 35.376-5.8149 91.516-3.8023 122.86 4.4046 110.74 28.998 199.33 111.44 232.39 216.26 10.451 33.139 14.177 63.093 12.798 102.89-1.7547 50.646-11.756 91.098-32.921 133.15-20.107 39.953-29.521 51.201-114.16 136.4l-81.031 81.567z"
                    fill="#fff"
                    strokeWidth="2.3459"
                />
            </g>
        </svg>
    );
};
