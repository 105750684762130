import { useCallback, useEffect, useState } from 'react';
import useFetch from 'use-http';
// import { useMixedNetwork } from '../../../../../contexts/MixedNetworkProvider/MixedNetworkProvider'

export interface IRequestStatus {
    timeLeft: number;
    status: 'pending' | 'rebooting' | 'error' | 'success' | 'ready';
}

export function useSendChangeRequest(requestTime?: number) {
    // 1000 * 60 * 1.5 + 1000;
    const reqTime = requestTime ?? 90;
    const { post: changeStageRequest, error: changeStageRequestError, loading } = useFetch('/api/plugin/time/8c12555d-6d5a-4105-a816-9ac91969f727');
    
    const [requestStatus, setRequestStatus] = useState<IRequestStatus>({ timeLeft: 0, status: 'ready' });

    useEffect(() => {
        if (requestStatus.status === 'rebooting') {
            const { timeLeft } = requestStatus;
            const time = timeLeft - 1;
            if (time <= 0) {
                return setRequestStatus({ timeLeft: 0, status: 'success' });
            }
            const timeout = setTimeout(() => {
                setRequestStatus({ timeLeft: time, status: 'rebooting' });
            }, 1000);
            return () => clearTimeout(timeout);
        }
    }, [requestStatus]);

    useEffect(() => {
        if (changeStageRequestError) {
            setRequestStatus(lastState => ({ ...lastState, status: 'error' }));
        }
    }, [changeStageRequestError]);

    const sendChangeStageRequest = useCallback(
        (controllerPackagesToRequest: any[]) => {
            console.log('To API: ', controllerPackagesToRequest)
            setRequestStatus({timeLeft: reqTime, status: "pending"})
            return changeStageRequest(controllerPackagesToRequest).then(() => setRequestStatus({ timeLeft: reqTime, status: 'rebooting' }));
            // setRequestStatus({ timeLeft: reqTime, status: 'pending' });
        },
        [changeStageRequest, reqTime],
    );

    return { sendChangeStageRequest, requestStatus, requestTime: reqTime, error: changeStageRequestError, loading };
}
