import { useCallback, useRef, useState } from 'react';
import { Stack, TextField, Label, Dialog, DialogFooter, PrimaryButton } from '@fluentui/react';
import { IntegrationCard, IntegrationCardHandle } from './IntegrationCard';
import produce from 'immer';
import { disableAllSpellchecksAndAutoCompleteProp } from '../../../../helpers/killEvent';
import { DataverseIntegration } from '../../../../api/generated/data-contracts';

export const DataverseLayout = ({
    integration,
    setIntegration,
    isLoading,
}: {
    integration: DataverseIntegration;
    setIntegration: React.Dispatch<React.SetStateAction<DataverseIntegration>>;
    isLoading: boolean;
}) => {
    const { dataverseUrl, timeActualsSyncEnabled } = integration.configuration;

    const toggleEvent = useCallback(
        (state?: boolean) => {
            setIntegration(integration => {
                return {
                    ...integration,
                    configuration: {
                        ...integration.configuration,
                        timeActualsSyncEnabled: state ?? !timeActualsSyncEnabled,
                    },
                };
            });
        },
        [setIntegration, timeActualsSyncEnabled],
    );

    const [columnsError, setColumnsError] = useState<any>(null);
    const initiativeHandle = useRef<IntegrationCardHandle | null>(null);

    return (
        <>
            <Stack horizontal tokens={{ childrenGap: 10 }} {...disableAllSpellchecksAndAutoCompleteProp}>
                <Stack styles={{ root: { paddingLeft: 20, paddingBottom: 20, width: 400 } }}>
                    <TextField
                        disabled={isLoading}
                        value={dataverseUrl}
                        label="Url"
                        required
                        onChange={(e, val) =>
                            setIntegration(
                                produce(integration => {
                                    integration.configuration.dataverseUrl = val || '';
                                }),
                            )
                        }
                    />
                    {/* <EnvironmentDropdown
                        defaultSelectedKey={url}
                        onChange={(e, option) => {
                            setIntegration(
                                produce(integration => {
                                    integration.configuration.url = option.data.url || '';
                                    integration.configuration = {
                                        ...integration.configuration,
                                        ...defaultDataverseConfig,
                                        url: option.data.url || '',
                                    };
                                }),
                            );
                            expandInitiativeAndResouceConnection();
                        }}
                        disabled={isLoading}
                    /> */}
                    {/* <OriginDropdown
                        onChange={(e, option) =>
                            setIntegration(
                                produce(integration => {
                                    integration.originId = option.data.id || '';
                                }),
                            )
                        }
                        defaultSelectedKey={originId}
                        disabled={isLoading}
                    /> */}
                    {/* <Stack tokens={{ padding: '10px 0' }} horizontal horizontalAlign="start">
                        <DefaultButton
                            disabled={isLoading || !url}
                            onClick={() => {
                                expandInitiativeAndResouceConnection();
                                setIntegration(integration => {
                                    return {
                                        ...integration,
                                        configuration: {
                                            ...integration.configuration,
                                            ...defaultDataverseConfig,
                                        },
                                    };
                                });
                            }}
                        >
                            Restore default values
                        </DefaultButton>
                    </Stack> */}
                </Stack>

                <div style={{ width: '1px', backgroundColor: 'rgba(0,0,0,0.1)', flexGrow: 1 }} />

                <Stack styles={{ root: { width: 800, minHeight: 500 } }}>
                    <Label>Connections</Label>
                    <Stack styles={{ root: { gap: 10, flexWrap: 'wrap' } }} horizontal verticalAlign="start">
                        <IntegrationCard
                            // eventType={'Actuals'}
                            // enabled={subscribedEvents.includes('Actuals')}
                            // notReady={!configuration.initiativeEntity}
                            enabled={timeActualsSyncEnabled}
                            notReady={false}
                            toggleEvent={toggleEvent}
                            ref={initiativeHandle}
                        ></IntegrationCard>
                    </Stack>
                </Stack>
            </Stack>
            <Dialog hidden={!columnsError} dialogContentProps={{ title: 'An error occured' }}>
                Couldn't communicate with {dataverseUrl}. It's most likely an access issue with your account
                <DialogFooter>
                    <PrimaryButton onClick={() => setColumnsError(null)}>Okay</PrimaryButton>
                </DialogFooter>
            </Dialog>
        </>
    );
};

// const CustomFieldMappingDropdown = <T,>({
//     selectedFields,
//     onSelectFields,
//     ...props
// }: Omit<FilterDropdownProps<T>, 'onChange'> & { selectedFields?: string; onSelectFields: (fields: string) => void }) => {
//     const selectedKeys = useMemo(() => (selectedFields || '').split(','), [selectedFields]);
//     const onChange = useCallback(
//         (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<T>) => {
//             if (option) {
//                 if (option.key === DESELECT_KEY) {
//                     onSelectFields('');
//                 } else {
//                     onSelectFields(
//                         (option.selected ? [...selectedKeys, option.key as string] : selectedKeys.filter(key => key !== option.key)).filter(Boolean).join(','),
//                     );
//                 }
//             }
//         },
//         [onSelectFields, selectedKeys],
//     );
//     return <FilterDropdown {...props} selectedKeys={selectedKeys} multiSelect onChange={onChange} />;
// };

// const onRenderOption: IRenderFunction<ISelectableOption<ColumnDto>> = (props, defaultRender) => {
//     if (props && defaultRender) {
//         const { key, text } = props;
//         if (key === DESELECT_KEY) {
//             return <strong>{text}</strong>;
//         }
//         return (
//             <TooltipHost
//                 content={
//                     <div style={{ display: 'grid', gridTemplateColumns: 'auto auto', gap: 10 }}>
//                         <Text>
//                             <strong>Display name</strong>
//                         </Text>
//                         <Text>{`${props.data?.displayName}`}</Text>
//                         <Text>
//                             <strong>Logical name</strong>
//                         </Text>
//                         <Text>{`${props.data?.logicalName}`}</Text>
//                     </div>
//                 }
//                 directionalHint={DirectionalHint.topLeftEdge}
//             >
//                 {defaultRender(props)}
//             </TooltipHost>
//         );
//     }
//     return null;
// };
