import React from 'react';
import { TimeGrid } from './components/TimeGrid/TimeGrid';
import { PageContent, PageHeader, ContentSection, PageWrapper } from '../../components/Layout';
import { PeriodPicker } from '../../components/PeriodPicker';
import { IconButton, Stack, Text, makeStyles, useTheme } from '@fluentui/react';
import { useUiContext } from '../../../components/Contexts/UiContext';
import { TimeStateButton } from './components/TimeStateButton';
import { useMobileContext } from '../../MobileContext';
import { useNavigate } from 'react-router';

export const TimeOverview: React.FC = () => {
    // const history = useHistory();
    const navigate = useNavigate();
    const uiCtx = useUiContext();
    const mobCtx = useMobileContext();
    const theme = useTheme();
    const classes = getStyles();

    return (
        <PageWrapper>
            <PageHeader>
                {/* <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
                </Stack> */}
                <div className={classes.container}>
                    <Stack>
                        {mobCtx.selectedTimePeriod && (
                            <IconButton
                                iconProps={{ iconName: 'Search' }}
                                disabled={!mobCtx.selectedTimePeriod}
                                onClick={() => navigate('/projects')}
                                styles={{
                                    root: {
                                        i: {
                                            color: uiCtx.isTeams ? 'white' : theme.palette.black,
                                            fontSize: 18,
                                        },
                                    },
                                }}
                            />
                        )}
                    </Stack>
                    <Stack grow horizontal verticalAlign="center" horizontalAlign="center" styles={{ root: { paddingRight: 8, paddingLeft: 8 } }}>
                        <PeriodPicker />
                    </Stack>
                    <Stack horizontal horizontalAlign="end">
                        {mobCtx.selectedTimePeriod && <TimeStateButton />}
                    </Stack>
                </div>
            </PageHeader>

            <PageContent containerId="timeoverview">
                <ContentSection grow noPadding>
                    {!mobCtx.selectedTimePeriod ? (
                        <Stack horizontalAlign="center" styles={{ root: { padding: '20px 0' } }}>
                            <Text nowrap block variant="mediumPlus">
                                No period found
                            </Text>
                        </Stack>
                    ) : (
                        <TimeGrid />
                    )}
                </ContentSection>
            </PageContent>
        </PageWrapper>
    );
};

const getStyles = makeStyles(theme => ({
    container: {
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr',
    },
}));
