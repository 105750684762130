import React, { useEffect, useState, useMemo, ReactElement, useRef } from 'react';
import { GetApproverListDetailsListGroups } from '../components/Utils';
import ApprovalLine from './ApprovalLine';
import ApprovalCell from './ApprovalCell';
import ApproverCommentCell from './ApproverCommentCell';
import {
    IDetailsColumnStyles,
    DetailsList,
    SelectionMode,
    Stack,
    IColumn,
    Dropdown,
    DetailsListLayoutMode,
    Persona,
    PersonaSize,
    Text,
    DatePicker,
    IDatePicker,
    Icon,
    ScrollablePane,
    DayOfWeek,
} from '@fluentui/react';
import { TimeState } from '../Model/State';
import '../Styles/Approver.scss';
import User from '../Model/User';
import PeriodDay from '../Model/PeriodDay';
import EDays from '../Model/EDays';
import { useUiContext } from '../components/Contexts/UiContext';
import moment from 'moment';
import Period from '../Model/Period';
import { PageContent, ContentSection } from '../components/LayoutElements';
import { QuotesLoader } from '../components/QuotesLoader';
import AssignmentsByUserAndPeriod from '../Model/AssignmentsByUserAndPeriod';
import { IDropdownOption, TooltipHost } from '@fluentui/react';
import ApproverCustomFiltersContainer from './ApproverCustomFiltersContainer';
import { ApproverFilterSection } from '../components/LayoutElements/ApproverFilterSection';
import { ApproverCustomFilter } from '../Model/NewModels/ApproverCustomFilter';
import { ApiCalls } from '../api/api';
import { UserReportingPeriodState } from '../api/generated/data-contracts';

const headerStyle: Partial<IDetailsColumnStyles> = {
    cellTitle: { alignContent: 'center', justifyContent: 'center' },
};

const itemStyles: React.CSSProperties = {
    alignItems: 'center',
    display: 'flex',
    height: 60,
    width: 354,
    justifyContent: 'center',
};

const urpStateOptions = [
    { key: 'selectalloption', text: 'All' },
    { key: TimeState.open, text: 'Open' },
    { key: TimeState.submitted, text: 'Submitted' },
    { key: TimeState.rejected, text: 'Rejected' },
    { key: TimeState.approved, text: 'Approved' },
    { key: TimeState.notcreated, text: 'Not Created' },
];

const nowMinusXMonths = (months?: number): Date => {
    const now = new Date();
    if (months == null || months === 0) {
        return now;
    }
    now.setMonth(now.getMonth() - months);
    return now;
};

function Approver() {
    const [approvalItems, setApprovalItems] = useState<ApprovalLine[]>(null);
    const [filteredApprovalItems, setFilteredApprovalItems] = useState<ApprovalLine[]>([]);
    const [approvalgroups, setApprovalgroups] = useState<any[]>([]);
    // const [filterState, setFilterState] = useState<string>(TimeState.submitted);
    const [selectedUrpStates, setSelectedUrpStates] = useState<any[]>([]);
    const [availableUsers, setAvailableUsers] = useState<any[]>(null);
    const [userFilterOptions, setUserFilterOptions] = useState<any[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
    const [containerWidth, setContainerWidth] = useState(null);

    const [fromDateValue, setFromDateValue] = useState<Date>(nowMinusXMonths(1));
    const [toDateValue, setToDateValue] = useState<Date>(nowMinusXMonths());
    const [filteredPeriods, setFilteredPeriods] = useState<Period[]>([]);

    const [assignmentsByUserAndPeriod, setAssignmentsByUserAndPeriod] = useState<AssignmentsByUserAndPeriod[]>([]);

    const [appliedApproverCustomFilter, setAppliedApproverCustomFilter] = useState<ApproverCustomFilter>();
    const [customFilterChanged, setCustomFilterChanged] = useState<boolean>(false);

    const FromDatePickerRef = useRef<IDatePicker>(null);
    const ToDatePickerRef = useRef<IDatePicker>(null);

    const uiCtx = useUiContext();

    const getPeriodDays = (startDate: Date, endDate: Date) => {
        const periodDaysArr: PeriodDay[] = [];
        const currentday: Date = startDate;
        let index: number = 0;
        while (currentday <= endDate) {
            const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            const dayName = days[currentday.getDay()];
            periodDaysArr.push({ id: index, date: new Date(currentday), name: dayName, day: EDays[currentday.getDay()] });
            index++;
            currentday.setDate(currentday.getDate() + 1);
        }

        return periodDaysArr;
    };

    useEffect(() => {
        if (availableUsers === null || availableUsers === undefined) {
            return;
        }

        // Add select/deselect All option
        const allOptionObjForFilter = { key: 'selectalloption', text: 'All', approverId: null, approverName: null };
        const allOptionObjForSelected = { key: 'selectalloption', text: 'All' };
        setUserFilterOptions([allOptionObjForFilter, ...availableUsers]);

        if (availableUsers.length != selectedUsers.length) {
            setSelectedUsers(selectedUsers);
        } else {
            setSelectedUsers([allOptionObjForSelected, ...selectedUsers]);
        }
    }, [availableUsers]);

    useEffect(() => {
        uiCtx.timeApi.getUsersByManager().then(json => {
            let users: any = [];
            json.map((user: User, index: number) => {
                const approverid = user.approver != undefined && user.approver != null ? user.approver.id : user.id;
                // let approverprincipalName = user.approver != undefined && user.approver != null ? user.approver.principalName : user.principalName;
                const approverName = user.approver != undefined && user.approver != null ? user.approver.name : user.name;
                if (user.isNotActive != true) {
                    users.push({ key: user.id, text: user.name, approverId: approverid, approverName: approverName });
                }
            });
            users = users.sort((a: any, b: any) => {
                return a.text < b.text ? -1 : 1;
            });

            setSelectedUsers(users.filter(u => u.approverId == uiCtx.user.user.id));
            setAvailableUsers(users);

            uiCtx.setLoading(false);
        });

        setSelectedUrpStates(urpStateOptions);
    }, []);

    useEffect(() => {
        const start: any = fromDateValue.getISOStringMidnight();
        const end: any = toDateValue.getISOStringMidnight();
        uiCtx.timeApi.getPeriodsByDates(start, end).then(periodsJson => {
            setFilteredPeriods(periodsJson);
        });
    }, [fromDateValue, toDateValue, availableUsers]);

    useEffect(() => {
        if (availableUsers && availableUsers.length > 0) {
            const start: any = fromDateValue.getISOStringMidnight();
            const end: any = toDateValue.getISOStringMidnight();

            uiCtx.timeApi.getApprovalLinesByManagerCustomPeriod(start, end).then(json => {
                let approvalLines: ApprovalLine[] = [];
                json.map((approvalline: any, index: number) => {
                    const submitteddatetime = approvalline.submitted + 'Z';
                    approvalLines.push(
                        new ApprovalLine(
                            approvalline.periodId,
                            approvalline.reportPeriodId,
                            approvalline.userId,
                            approvalline.periodName,
                            approvalline.periodState,
                            approvalline.personName,
                            approvalline.hours,
                            approvalline.state,
                            new Date(approvalline.startDate),
                            new Date(approvalline.endDate),
                            approvalline.submitComment,
                            approvalline.comment,
                            approvalline.hasUserCommentsMonday,
                            approvalline.hasUserCommentsTuesday,
                            approvalline.hasUserCommentsWednesday,
                            approvalline.hasUserCommentsThursday,
                            approvalline.hasUserCommentsFriday,
                            approvalline.hasUserCommentsSaturday,
                            approvalline.hasUserCommentsSunday,
                            approvalline.dateTotalHours,
                            [],
                            approvalline.approverName,
                            approvalline.submitted,
                            approvalline.submittedBy,
                            approvalline.autoapproval,
                        ),
                    );
                });

                availableUsers.forEach(sUser => {
                    filteredPeriods.forEach(fPeriod => {
                        if (approvalLines.find(a => a.userId === sUser.key && a.periodId === fPeriod.id) === undefined) {
                            approvalLines.push(
                                new ApprovalLine(
                                    fPeriod.id,
                                    '',
                                    sUser.key,
                                    fPeriod.name,
                                    fPeriod.state == 2 ? 'Closed' : fPeriod.state == 1 ? 'Pending' : 'Open',
                                    sUser.text,
                                    0,
                                    TimeState.notcreated,
                                    new Date(fPeriod.startDate),
                                    new Date(fPeriod.endDate),
                                    '',
                                    '',
                                    false,
                                    false,
                                    false,
                                    false,
                                    false,
                                    false,
                                    false,
                                    [],
                                    [],
                                    sUser.approverName,
                                    undefined,
                                    sUser.text,
                                    false,
                                ),
                            );
                        }
                    });
                });

                approvalLines = approvalLines.sort((a: ApprovalLine, b: ApprovalLine) => {
                    if (a.startDate.getTime() - b.startDate.getTime() == 0) {
                        return a.personName < b.personName ? -1 : 1;
                    }
                    return b.startDate.getTime() - a.startDate.getTime();
                });
                setApprovalItems(approvalLines);
            });
        }
    }, [filteredPeriods]);

    useEffect(() => {
        //Order of setFilteredApprovalItems and setApprovalgroups is important for DetailList to render correctly
        setFilteredApprovalItems(filterItems());
    }, [approvalgroups]);

    useEffect(() => {
        setApprovalgroups(GetApproverListDetailsListGroups(filterItems()));
    }, [selectedUsers, selectedUrpStates, approvalItems]);

    useEffect(() => {
        if (selectedUrpStates.length === 0) {
            return;
        }
        if (selectedUsers.length === 0) {
            return;
        }

        const apGroups = GetApproverListDetailsListGroups(filterItems());
        if (apGroups.length != 0) {
            return;
        }

        setSelectedUrpStates(urpStateOptions);
    }, [approvalItems]);

    // DO NOT DELETE THIS, NEEDS TO BE FIXED BEFORE IMPLEMENTATION! - NIV
    // const getAssignmentsTest = (userId: string, periodId: string): GetAssignmentsByUserCustomPeriodDto => {
    //     let tAssignementsArray = assignmentsByUserAndPeriod.filter(_ => _.UserId == userId && _.PeriodId == periodId);
    //     if (tAssignementsArray.length > 0) return tAssignementsArray[0];

    //     // Not retrieved yet
    //     let apiInput = new GetAssignmentsByUserCustomPeriodDto(userId, periodId);
    //     let tAssignements = uiCtx.timeApi.GetAssignmentsByUserCustomPeriod(apiInput);
    //     let newAssignmentsByUserAndPeriod = new AssignmentsByUserAndPeriod(userId, periodId, tAssignements)
    //     setAssignmentsByUserAndPeriod(prev => [...prev, newAssignmentsByUserAndPeriod]);
    //     return tAssignements;
    // }

    // DO NOT DELETE THIS, NEEDS TO BE FIXED BEFORE IMPLEMENTATION! - NIV
    // const lazyLoadAssignments = async (userId: string, periodId: string) => {
    //     // If retrieved, do nothing
    //     if (assignmentsByUserAndPeriod.some(_ => _.UserId == userId && _.PeriodId == periodId)) return;

    //     // Not retrieved yet
    //     let apiInput = new GetAssignmentsByUserCustomPeriodDto(userId, periodId);
    //     let tAssignements = await uiCtx.timeApi.GetAssignmentsByUserCustomPeriod(apiInput);
    //     let newAssignmentsByUserAndPeriod = new AssignmentsByUserAndPeriod(userId, periodId, tAssignements)
    //     setAssignmentsByUserAndPeriod(prev => [...prev, newAssignmentsByUserAndPeriod]);
    // }

    const filterItems = (): ApprovalLine[] => {
        if (!approvalItems) {
            return [];
        }
        let filteredtimesheets = approvalItems.filter(
            i =>
                selectedUsers.some(function (el) {
                    return el.key === i.userId;
                }) === true,
        );

        // filteredtimesheets = filteredtimesheets.filter(i => (filterState === "all" || i.state.toLowerCase() === filterState));
        filteredtimesheets = filteredtimesheets.filter(
            i =>
                selectedUrpStates.some(function (el) {
                    return el.key === i.state.toLowerCase();
                }) === true,
        );
        return filteredtimesheets;
    };

    const applyApproverCustomFilter = (appliedFilter: ApproverCustomFilter) => {
        if (appliedFilter === null || appliedFilter === undefined) {
            setAppliedApproverCustomFilter(null);
            setSelectedUrpStates(urpStateOptions);
            const uFilterToApply = availableUsers.filter(u => u.approverId == uiCtx.user.user.id);
            if (uFilterToApply.length === userFilterOptions.length - 1) {
                setSelectedUsers([{ key: 'selectalloption', text: 'All' }, ...uFilterToApply]);
            } else {
                setSelectedUsers(uFilterToApply);
            }
            setCustomFilterChanged(false);
            return;
        }
        setAppliedApproverCustomFilter(appliedFilter);
        const sFilterToApply = urpStateOptions.filter(_ => appliedFilter.urpStateFilter.indexOf(_.key) > -1);
        if (sFilterToApply.length === urpStateOptions.length - 1) {
            setSelectedUrpStates([{ key: 'selectalloption', text: 'All' }, ...sFilterToApply]);
        } else {
            setSelectedUrpStates(sFilterToApply);
        }
        const uFilterToApply = userFilterOptions.filter(_ => appliedFilter.approverUsersFilter.indexOf(_.key) > -1);
        if (uFilterToApply.length === userFilterOptions.length - 1) {
            setSelectedUsers([{ key: 'selectalloption', text: 'All' }, ...uFilterToApply]);
        } else {
            setSelectedUsers(uFilterToApply);
        }
        setCustomFilterChanged(false);
    };

    const onRenderColumn = (item: ApprovalLine, index: number, column: IColumn): ReactElement => {
        const periodDays = getPeriodDays(new Date(item.startDate), new Date(item.endDate));

        const currentPeriodDay = periodDays.find(_ => _.name.toLowerCase() === column.key);

        const fieldContent = item[column.fieldName as keyof ApprovalLine] as string;
        let hasComments: boolean;
        let ishighlighted: boolean = false;
        switch (column.key) {
            case 'monday':
            case 'tuesday':
            case 'wednesday':
            case 'thursday':
            case 'friday':
            case 'saturday':
            case 'sunday':
                hasComments = item.dateTotalHours?.find(_ => new Date(_.date).getTime() === currentPeriodDay?.date?.getTime())?.hasComment;
                break;
            case 'hours':
                hasComments = false;
                break;
            default:
                hasComments = false;
                ishighlighted = true;
                break;
        }

        const missingDay = !periodDays.some(_ => _.name.toLowerCase() === column.key);

        switch (column.key) {
            case 'state':
                return (
                    <Stack>
                        <Stack.Item align="center">
                            <div>{item.autoapproval}</div>
                            <Dropdown
                                disabled={item.autoapproval === true || item.state.toLowerCase() === TimeState.notcreated}
                                placeholder="Update time state"
                                defaultSelectedKey={item.state.toLowerCase()}
                                onRenderCaretDown={(props, defaultRender) => {
                                    return item.autoapproval === true ? <></> : defaultRender(props);
                                }}
                                className={
                                    item.state.toLowerCase() === TimeState.notcreated
                                        ? 'dropdowngraystyle'
                                        : item.state.toLowerCase() === TimeState.open || item.state.toLowerCase() === TimeState.submitted
                                        ? item.autoapproval === true
                                            ? 'dropdownredstyledisabled'
                                            : 'dropdownredstyle'
                                        : 'dropdowngreenstyle'
                                }
                                options={[
                                    { key: TimeState.open, text: 'Open', disabled: true },
                                    { key: TimeState.submitted, text: 'Submitted', disabled: true },
                                    { key: TimeState.rejected, text: 'Rejected' },
                                    { key: TimeState.approved, text: 'Approved' },
                                    { key: TimeState.notcreated, text: 'Not Created', disabled: true },
                                ]}
                                styles={{ dropdown: { minWidth: 115 } }}
                                onChanged={option => {
                                    ApiCalls.setReportingPeriodState({
                                        userReportingPeriodId: item.reportPeriodId,
                                        state: option.key as UserReportingPeriodState,
                                        nextState: option.key as UserReportingPeriodState,
                                    });
                                    for (let i = 0; i < approvalItems.length; i++) {
                                        if (approvalItems[i].reportPeriodId === item.reportPeriodId) {
                                            approvalItems[i].state = option.text;
                                        }
                                    }
                                    setApprovalItems([...approvalItems]);
                                }}
                                id={item.reportPeriodId}
                            />
                        </Stack.Item>
                    </Stack>
                );

            case 'personname':
            case 'approverName':
            case 'submittedBy':
                return fieldContent ? (
                    <Persona
                        text={fieldContent}
                        size={PersonaSize.size24}
                        styles={{ root: { height: '100%' }, primaryText: { fontWeight: 600, fontSize: 13 } }}
                    />
                ) : null;
            // <Label><span style={{ textAlign: "start", display: 'block' }}>{fieldContent}</span></Label>
            case 'hours':
                return (
                    <ApprovalCell
                        disabled={item.state.toLowerCase() === TimeState.notcreated}
                        value={fieldContent}
                        userid={item.userId}
                        reportperiodid={item.reportPeriodId}
                        day={column.key}
                        hasUserComments={hasComments}
                    />
                );
            case 'monday':
            case 'tuesday':
            case 'wednesday':
            case 'thursday':
            case 'friday':
            case 'saturday':
            case 'sunday':
                const value =
                    (!missingDay && item.dateTotalHours?.find(_ => new Date(_.date).getTime() === currentPeriodDay.date?.getTime())?.hours.toString()) || '';
                return (
                    <ApprovalCell
                        date={currentPeriodDay?.date}
                        disabled={missingDay || item.state.toLowerCase() === TimeState.notcreated}
                        value={value}
                        userid={item.userId}
                        reportperiodid={item.reportPeriodId}
                        day={column.key}
                        hasUserComments={hasComments}
                    />
                );
            case 'period':
                return (
                    <ApprovalCell
                        periodDays={periodDays}
                        disabled={item.state.toLowerCase() === TimeState.notcreated}
                        value={`${item.hours}`}
                        userid={item.userId}
                        reportperiodid={item.reportPeriodId}
                        day={column.key}
                        hasUserComments={hasComments}
                    />
                );
            case 'submitComment':
                return (
                    <ApproverCommentCell
                        disabled={item.state.toLowerCase() === TimeState.notcreated}
                        commentDisabled={true}
                        labelText={'Comment from user'}
                        defaultValue={fieldContent}
                        reportPeriodId={item.reportPeriodId}
                        onUpdated={() => {}}
                    />
                );
            case 'comment':
                return (
                    <ApproverCommentCell
                        disabled={item.state.toLowerCase() === TimeState.notcreated}
                        commentDisabled={false}
                        labelText={'Reply to user'}
                        defaultValue={fieldContent}
                        reportPeriodId={item.reportPeriodId}
                        onUpdated={async v => {
                            const response = await uiCtx.timeApi.addCommentToUserReportingPeriod(item.reportPeriodId, v); // TODO: THIS SHOULD REALLY BE CHANGED! It calls the api for every single click on the keyboard in the text field, come on man...
                            console.log(response);
                        }}
                    />
                );
            case 'submitted':
                return (
                    <Text styles={{ root: { width: '100%', height: '100%', display: 'flex', alignItems: 'center', paddingRight: 6 } }}>
                        <span style={{ textAlign: 'center', display: 'block', fontSize: '0.8em', whiteSpace: 'normal' }}>
                            {fieldContent ? moment(new Date(fieldContent)).format(uiCtx.datetimeformat) : ''}
                        </span>
                    </Text>
                );
            default:
                return (
                    <Text styles={{ root: { width: '100%', height: '100%', display: 'flex', alignItems: 'center', paddingRight: 6 } }}>
                        <span style={{ textAlign: 'center', display: 'block', fontSize: '0.8em', whiteSpace: 'normal' }}>{fieldContent}</span>
                    </Text>
                );
        }
    };

    const userFilterDDChanged = (option: IDropdownOption, index?: number): void => {
        if (option === undefined || option === null) {
            return;
        }

        if (option.key === 'selectalloption' && option.selected) {
            setSelectedUsers(
                userFilterOptions.map(opt => {
                    return { key: opt.key, text: opt.text };
                }),
            );
        } else if (option.key === 'selectalloption') {
            setSelectedUsers([]);
        } else if (option.selected === true) {
            if (selectedUsers.length === userFilterOptions.length - 2) {
                setSelectedUsers([{ key: 'selectalloption', text: 'All' }, ...selectedUsers, { key: option.key, text: option.text }]);
            } else {
                setSelectedUsers([...selectedUsers, { key: option.key, text: option.text }]);
            }
        } else {
            setSelectedUsers(selectedUsers.filter(opt => opt.key !== option.key && opt.key !== 'selectalloption'));
        }

        setCustomFilterChanged(true);
    };

    const urpStatesFilterDDChanged = (option: IDropdownOption, index?: number): void => {
        if (option === undefined || option === null) {
            return;
        }

        if (option.key === 'selectalloption' && option.selected) {
            setSelectedUrpStates(
                urpStateOptions.map(opt => {
                    return { key: opt.key, text: opt.text };
                }),
            );
        } else if (option.key === 'selectalloption') {
            setSelectedUrpStates([]);
        } else if (option.selected === true) {
            if (selectedUrpStates.length === urpStateOptions.length - 2) {
                setSelectedUrpStates([{ key: 'selectalloption', text: 'All' }, ...selectedUrpStates, { key: option.key, text: option.text }]);
            } else {
                setSelectedUrpStates([...selectedUrpStates, { key: option.key, text: option.text }]);
            }
        } else {
            setSelectedUrpStates(selectedUrpStates.filter(opt => opt.key !== option.key && opt.key !== 'selectalloption'));
        }

        setCustomFilterChanged(true);
    };

    const columns = useMemo(() => {
        if (!containerWidth) {
            return [];
        }

        const minWidthSmall = (80 * containerWidth) / 2000;
        const minWidthMedium = (100 * containerWidth) / 2000;
        const statewidth = minWidthMedium < 100 ? 100 : minWidthMedium;

        const minWidthLarge = (120 * containerWidth) / 2000;
        // (x + 20) = 20 is the padding the element get & the 40 in the end is the expander icon
        const columnsWidth = 8 * (minWidthSmall + 20) + (statewidth + 20) + 2 * (minWidthMedium + 20) + 3 * (minWidthLarge + 20) + 40;

        let personColumnsWidth = minWidthLarge;
        let nameColumnsWidth = minWidthLarge;

        if (containerWidth > columnsWidth) {
            nameColumnsWidth = minWidthLarge + (containerWidth - columnsWidth) * 0.5;
            personColumnsWidth = minWidthLarge + ((containerWidth - columnsWidth) * 0.2) / 2;
        }
        if (containerWidth < columnsWidth) {
            nameColumnsWidth = minWidthLarge + (containerWidth - columnsWidth) / 3;
            personColumnsWidth = minWidthLarge + (containerWidth - columnsWidth) / 3;
        }

        return [
            { key: 'personname', name: 'Person', fieldName: 'personName', isRowHeader: true, minWidth: nameColumnsWidth, isResizable: true },
            ...(uiCtx.gridView === 'period'
                ? [
                      {
                          key: 'period',
                          name: uiCtx.gridInputType === 'percent' ? 'Total %' : 'Total',
                          fieldName: 'period',
                          minWidth: minWidthSmall,
                          isResizable: true,
                          styles: headerStyle,
                      },
                  ]
                : [
                      { key: 'monday', name: 'Monday', fieldName: 'monday', minWidth: minWidthSmall, isResizable: true, styles: headerStyle },
                      { key: 'tuesday', name: 'Tuesday', fieldName: 'tuesday', minWidth: minWidthSmall, isResizable: true, styles: headerStyle },
                      { key: 'wednesday', name: 'Wednesday', fieldName: 'wednesday', minWidth: minWidthSmall, isResizable: true, styles: headerStyle },
                      { key: 'thursday', name: 'Thursday', fieldName: 'fhursday', minWidth: minWidthSmall, isResizable: true, styles: headerStyle },
                      { key: 'friday', name: 'Friday', fieldName: 'friday', minWidth: minWidthSmall, isResizable: true, styles: headerStyle },
                      { key: 'saturday', name: 'Saturday', fieldName: 'saturday', minWidth: minWidthSmall, isResizable: true, styles: headerStyle },
                      { key: 'sunday', name: 'Sunday', fieldName: 'sunday', minWidth: minWidthSmall, isResizable: true, styles: headerStyle },
                      { key: 'hours', name: 'Total', fieldName: 'hours', minWidth: minWidthSmall, isResizable: true, styles: headerStyle },
                  ]),
            { key: 'submitted', name: 'Submitted', fieldName: 'submitted', minWidth: minWidthMedium, isResizable: true, styles: headerStyle },
            { key: 'submittedBy', name: 'Submitted By', fieldName: 'submittedBy', minWidth: personColumnsWidth, isResizable: true, styles: headerStyle },
            { key: 'submitComment', name: 'Comment', fieldName: 'submitComment', minWidth: minWidthMedium, isResizable: true, styles: headerStyle },
            { key: 'comment', name: 'Reply', fieldName: 'comment', minWidth: minWidthMedium, isResizable: true, styles: headerStyle },
            { key: 'approverName', name: 'Approver', fieldName: 'approverName', minWidth: personColumnsWidth, isResizable: true, styles: headerStyle },
            { key: 'state', name: 'State', fieldName: 'state', minWidth: statewidth, isResizable: true, styles: headerStyle },
        ];
    }, [containerWidth]);

    if (!availableUsers || !approvalgroups) {
        return <QuotesLoader loadingtext="Getting users" />;
    }

    if (availableUsers.length === 0) {
        return (
            <div className="centeredtext">
                <h2>Only managers can approve timesheets</h2>
            </div>
        );
    }

    return (
        <>
            {uiCtx.allConfig.find(_ => _.key.toLowerCase() === 'approvercustomfiltersenabled')?.value?.toLowerCase() === 'true' && (
                <ApproverFilterSection noPaddingBottom>
                    <ApproverCustomFiltersContainer
                        currentURPStateFilterArr={selectedUrpStates.map(_ => _.key)}
                        currentUserFilterArr={selectedUsers.map(_ => _.key)}
                        customFilterChanged={customFilterChanged}
                        filterSaved={() => setCustomFilterChanged(false)}
                        applyFilter={applyApproverCustomFilter}
                    />
                </ApproverFilterSection>
            )}
            <ContentSection>
                <Stack horizontal horizontalAlign="space-between" styles={{ root: { width: '100%' } }}>
                    {uiCtx.allConfig.find(_ => _.key.toLowerCase() === 'approvercustomfiltersenabled')?.value?.toLowerCase() === 'true' ? (
                        <TooltipHost content={<div>Dates are not included in the custom filters</div>} closeDelay={250}>
                            <span style={itemStyles}>
                                <DatePicker
                                    componentRef={FromDatePickerRef}
                                    allowTextInput
                                    //label="From date"
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    ariaLabel="Select from date" //Might not be needed as it should default to a date
                                    placeholder="Select from date" //Might not be needed as it should default to a date
                                    value={fromDateValue}
                                    onSelectDate={setFromDateValue as (date: Date | null | undefined) => void}
                                    styles={{ textField: { width: 150 } }}
                                />

                                <Icon
                                    iconName="ChromeMinimize"
                                    styles={{ root: { fontSize: '24px', height: '30px', width: '24px', margin: '0px 15px 0px 15px' } }}
                                />

                                <DatePicker
                                    componentRef={ToDatePickerRef}
                                    allowTextInput
                                    //label="To date"
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    ariaLabel="Select to date" //Might not be needed as it should default to a date
                                    placeholder="Select to date" //Might not be needed as it should default to a date
                                    value={toDateValue}
                                    onSelectDate={setToDateValue as (date: Date | null | undefined) => void}
                                    styles={{ textField: { width: 150 } }}
                                />
                            </span>
                        </TooltipHost>
                    ) : (
                        <span style={itemStyles}>
                            <DatePicker
                                componentRef={FromDatePickerRef}
                                allowTextInput
                                //label="From date"
                                firstDayOfWeek={DayOfWeek.Monday}
                                ariaLabel="Select from date" //Might not be needed as it should default to a date
                                placeholder="Select from date" //Might not be needed as it should default to a date
                                value={fromDateValue}
                                onSelectDate={setFromDateValue as (date: Date | null | undefined) => void}
                                styles={{ textField: { width: 150 } }}
                            />

                            <Icon
                                iconName="ChromeMinimize"
                                styles={{ root: { fontSize: '24px', height: '30px', width: '24px', margin: '0px 15px 0px 15px' } }}
                            />

                            <DatePicker
                                componentRef={ToDatePickerRef}
                                allowTextInput
                                //label="To date"
                                firstDayOfWeek={DayOfWeek.Monday}
                                ariaLabel="Select to date" //Might not be needed as it should default to a date
                                placeholder="Select to date" //Might not be needed as it should default to a date
                                value={toDateValue}
                                onSelectDate={setToDateValue as (date: Date | null | undefined) => void}
                                styles={{ textField: { width: 150 } }}
                            />
                        </span>
                    )}
                    <span style={itemStyles}>
                        <Dropdown
                            placeholder="Filter by user"
                            //label="User"
                            options={userFilterOptions}
                            selectedKeys={selectedUsers.map(u => {
                                return u.key;
                            })}
                            multiSelect
                            styles={{ dropdown: { width: 150 } }}
                            onChanged={userFilterDDChanged}
                            onRenderTitle={(defRenderProps, defRender) => {
                                return <>{selectedUsers.length === userFilterOptions.length ? <span>All</span> : defRender(defRenderProps)}</>;
                            }}
                        />
                    </span>
                    <span style={{ ...itemStyles, justifyContent: 'flex-end' }}>
                        <Dropdown
                            placeholder="Filter by state"
                            //label="State"
                            options={urpStateOptions}
                            selectedKeys={selectedUrpStates.map(u => {
                                return u.key;
                            })}
                            multiSelect
                            styles={{ dropdown: { width: 150 } }}
                            // onChanged={(option) => { setFilterState(option.key as string) }}
                            onChanged={urpStatesFilterDDChanged}
                            onRenderTitle={(defRenderProps, defRender) => {
                                return <>{selectedUrpStates.length === urpStateOptions.length ? <span>All</span> : defRender(defRenderProps)}</>;
                            }}
                        />
                    </span>
                </Stack>
            </ContentSection>

            <PageContent>
                <ContentSection isDetailslist detailslistStickyHeader>
                    <ScrollablePane>
                        <DetailsList
                            componentRef={(ref: any) => {
                                // dirty trick to get the containers width
                                if (ref && ref.props.viewport?.width && containerWidth !== ref.props.viewport?.width) {
                                    setContainerWidth(ref.props.viewport.width);
                                }
                            }}
                            items={filteredApprovalItems}
                            groups={approvalgroups}
                            columns={columns}
                            selectionMode={SelectionMode.none}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            ariaLabelForSelectionColumn="Toggle selection"
                            checkButtonAriaLabel="Row checkbox"
                            groupProps={{
                                showEmptyGroups: true,
                            }}
                            onRenderItemColumn={onRenderColumn}
                            compact={true}
                            styles={{
                                root: {
                                    // align submittedBy & approverName headers to the left
                                    [`div[data-item-key="submittedBy"] .ms-DetailsHeader-cellTitle, div[data-item-key="approverName"] .ms-DetailsHeader-cellTitle`]:
                                        {
                                            justifyContent: 'flex-start',
                                        },
                                },
                            }}
                        />
                    </ScrollablePane>
                </ContentSection>
            </PageContent>
        </>
    );
}

export default Approver;
