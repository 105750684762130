import React, { useEffect, useState } from 'react';
import './Styles/Admin.scss';
import UAParser from 'ua-parser-js';

function AppUserAgentTest() {
    const [agentInfo, setAgentInfo] = useState<UAParser>(new UAParser());
    const [uaResult, setUaResult] = useState<any>(null);

    useEffect(() => {
        if (navigator.userAgent !== null && navigator.userAgent !== undefined && navigator.userAgent !== '') {
            setAgentInfo(prevAgent => prevAgent.setUA(navigator.userAgent));
        }
    }, [navigator.userAgent]);

    useEffect(() => {
        setUaResult(agentInfo.getResult());
    }, [agentInfo]);

    return (
        <>
            <h4>
                UA is: {uaResult?.ua}
                <br />
                Browser Major is: {uaResult?.browser.major}
                <br />
                Browser Name is: {uaResult?.browser.name}
                <br />
                Browser Version: {uaResult?.browser.version}
                <br />
                Cpu Architecture is: {uaResult?.cpu.architecture}
                <br />
                Device Model is: {uaResult?.device.model}
                <br />
                Device Type is: {uaResult?.device.type}
                <br />
                Device Vendor is: {uaResult?.device.vendor}
                <br />
                Engine Name is: {uaResult?.engine.name}
                <br />
                Engine Version is: {uaResult?.engine.version}
                <br />
                OS Name is: {uaResult?.os.name}
                <br />
                OS Version is: {uaResult?.os.version}
            </h4>
        </>
    );
}

export default AppUserAgentTest;
