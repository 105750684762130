import React from 'react';
import { useState } from 'react';
import { Callout, DirectionalHint, Link, Text, useTheme } from '@fluentui/react';
import { useUiContext } from '../components/Contexts/UiContext';
import HelpSupport from '../Help/HelpSupport';
import DelegateArea from '../components/DelegateArea';

export const UserNotActive = () => {
    const uiCtx = useUiContext();
    const [showhelpcallout, setshowhelpcallout] = useState(false);

    return (
        <>
            <div className="centeredtext">
                <Text
                    styles={{
                        root: {
                            color: uiCtx.theme.semanticColors.bodyText,
                        },
                    }}
                    variant="xxLarge"
                >
                    Sorry, your account "{uiCtx.user.user.name}" is not active.
                </Text>
                <br />
                <br />
                <Text
                    styles={{
                        root: {
                            color: uiCtx.theme.semanticColors.bodyText,
                        },
                    }}
                    variant="xLarge"
                >
                    Please contact your{' '}
                    <Link id="showhelpbutton" onClick={() => setshowhelpcallout(s => !s)} styles={{ root: { fontStyle: 'inherit', fontWeight: 600 } }}>
                        PMO Support
                    </Link>{' '}
                    if you believe this is not correct.
                </Text>
                {showhelpcallout && (
                    <Callout
                        // style={{ minWidth: 800, minHeight: 300 }}
                        style={{ width: '100%', maxWidth: 800, minHeight: 200 }}
                        gapSpace={0}
                        target={'#showhelpbutton'}
                        directionalHint={DirectionalHint.rightCenter}
                        onDismiss={() => setshowhelpcallout(false)}
                        setInitialFocus
                    >
                        <HelpSupport />
                    </Callout>
                )}
            </div>
            <DelegateArea />
        </>
    );
};
