import { PageHeader, PageContent, ContentSection } from '../../../../components/LayoutElements';
import { Text, Stack, TooltipHost, IconButton, Dialog, DialogFooter, PrimaryButton } from '@fluentui/react';
import { ReleaseChanger } from '../../../../_components/ReleaseChanger/ReleaseChanger';
import { ApiCalls } from '../../../../api/api';
import { UpgradeCycle } from '../../../../api/generated/data-contracts';
import { ReleaseViewer } from '../../../../_components/ReleaseChanger/components/ReleaseViewer/ReleaseViewer';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { RELEASE_NOTES } from '../../../../constants/queryKeys';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const SystemInformation = () => {
    const [selectedVersion, setSelectedVersion] = useState<string | null>(null);
    const { data: releaseNote } = useQuery({
        queryKey: [RELEASE_NOTES, selectedVersion],
        queryFn: async () => {
            return ApiCalls.versioningReleasenotesDetail(selectedVersion, { format: 'text' }).then(res => res.data as unknown as string);
        },
        enabled: Boolean(selectedVersion),
        cacheTime: Infinity,
        staleTime: Infinity,
    });

    const onSelectedPackage = useCallback((version: string | null) => {
        if (version) {
            setSelectedVersion(version.slice(0, version.indexOf('-')));
        }
    }, []);
    const [showReleaseNotes, setShowReleaseNotes] = useState(false);
    return (
        <>
            <PageHeader title="System Information" icon="Info" />

            <PageContent>
                <ContentSection>
                    <Stack horizontalAlign="center" styles={{ root: { gap: 20 } }}>
                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <Stack styles={{ root: { minWidth: 300 } }}>
                                <ReleaseChanger
                                    onAfterRequest={() => {
                                        window.location.reload();
                                    }}
                                    fetchers={{
                                        getControllersByStage: [cycle => ApiCalls.upgradeGetPackages(cycle as UpgradeCycle).then(res => res.data)],
                                        sendUpgradeRequest: [controllers => ApiCalls.upgradePackages(controllers).then(res => res.data)],
                                    }}
                                    onSelectedPackage={onSelectedPackage}
                                />
                                {Boolean(releaseNote) && (
                                    <Stack tokens={{ padding: '10px 0' }} horizontal verticalAlign="center">
                                        <Text>
                                            <b>Release notes available:</b>
                                        </Text>
                                        <TooltipHost content={'Release notes'} styles={{ root: { display: 'inline-block' } }}>
                                            <IconButton
                                                disabled={!releaseNote}
                                                iconProps={{ iconName: 'DietPlanNotebook' }}
                                                onClick={() => setShowReleaseNotes(true)}
                                                text="Release notes available!"
                                            />
                                        </TooltipHost>
                                    </Stack>
                                )}
                            </Stack>
                            <Stack styles={{ root: { minWidth: 300 } }}>
                                <ReleaseViewer getCurrentControllers={[() => ApiCalls.upgradeGetCurrent().then(res => res.data)]} />
                            </Stack>
                        </Stack>
                    </Stack>
                </ContentSection>
            </PageContent>
            <Dialog hidden={!showReleaseNotes} onDismiss={() => setShowReleaseNotes(false)} dialogContentProps={{ title: 'Release notes' }} minWidth={'60%'}>
                <ReactMarkdown remarkPlugins={[remarkGfm as any]}>{releaseNote ?? ''}</ReactMarkdown>
                <DialogFooter>
                    <PrimaryButton onClick={() => setShowReleaseNotes(false)}>Close</PrimaryButton>
                </DialogFooter>
            </Dialog>
        </>
    );
};
