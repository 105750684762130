import React, { useEffect, useState } from 'react';
import HelpVideos from './HelpVideos';
import HelpFAQ from './HelpFAQ';
import HelpSupport from './HelpSupport';
import HelpGuide from './HelpGuide';
import { Stack, Pivot, PivotItem, ILabelStyles } from '@fluentui/react';
import { useUiContext } from '../components/Contexts/UiContext';

function HelpApp() {
    const uiCtx = useUiContext();
    const [guideEnabled, setGuideEnabled] = useState<boolean>(false);
    useEffect(() => {
        uiCtx.setLoading(false);
        uiCtx.timeApi.getGuide().then(json => {
            const anyGuidesfound: boolean = false;
            json.map((gui: any, index: number) => {
                setGuideEnabled(true);
            });
        });
    }, []);
    return (
        <>
            {/*<NavHeader />*/}
            <Stack horizontalAlign="center">
                <Pivot defaultSelectedKey="video" aria-label="Count and Icon Pivot Example" style={{ width: '100vw', textAlign: 'center' }}>
                    <PivotItem itemKey="video" headerText="Video" itemIcon="MSNVideosSolid">
                        <HelpVideos />
                    </PivotItem>
                    {/* {guideEnabled ? (
                        <PivotItem itemKey="guide" headerText="Guide" itemIcon="TextDocumentShared">
                            <HelpGuide />
                        </PivotItem>
                    ) : (
                        <></>
                    )} */}
                    {!uiCtx.isTeams && uiCtx.enableCoachmarks && (
                        <PivotItem itemKey="guide" headerText="Guide" itemIcon="SearchNearby">
                            <HelpGuide />
                        </PivotItem>
                    )}
                    <PivotItem itemKey="faq" headerText="FAQ" itemIcon="ClipboardList">
                        <HelpFAQ />
                    </PivotItem>
                    <PivotItem itemKey="support" headerText="Support" itemIcon="ContactHeart">
                        <HelpSupport />
                    </PivotItem>
                </Pivot>
            </Stack>
        </>
    );
}

export default HelpApp;
