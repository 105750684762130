import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
// import { UpgradePackagesPayload } from '../../../../../../../../../api/generated/data-contracts';
import { QueryPair } from '../types';
import { UpgradePackagesPayload } from '../../../api/generated/data-contracts';

export interface IRequestStatus {
    timeLeft: number;
    status: 'pending' | 'rebooting' | 'error' | 'success' | 'ready';
}

export function useSendChangeRequest(queryFetcher: QueryPair<(controllerPackagesToRequest: UpgradePackagesPayload) => Promise<any>>, requestTime: number, onUpgradeError?: (error: any) => void) {
    const [fetcher, key = ['upgrade-request']] = queryFetcher;
    const { mutateAsync: changeStageRequest, isLoading: loading } = useMutation(key, (controllerPackagesToRequest: UpgradePackagesPayload) => fetcher(controllerPackagesToRequest), {
        onError: err => {
            if (onUpgradeError) {
                onUpgradeError(err);
            }
            setRequestStatus(lastState => ({ ...lastState, status: 'error' }));
        },
    });
    const [requestStatus, setRequestStatus] = useState<IRequestStatus>({ timeLeft: 0, status: 'ready' });

    useEffect(() => {
        if (requestStatus.status === 'rebooting') {
            const { timeLeft } = requestStatus;
            const time = timeLeft - 1;
            if (time <= 0) {
                return setRequestStatus({ timeLeft: 0, status: 'success' });
            }
            const timeout = setTimeout(() => {
                setRequestStatus({ timeLeft: time, status: 'rebooting' });
            }, 1000);
            return () => clearTimeout(timeout);
        }
    }, [requestStatus]);

    const sendChangeStageRequest = useCallback(
        (controllerPackagesToRequest: any[]) => {
            setRequestStatus({ timeLeft: requestTime, status: 'pending' });
            return changeStageRequest(controllerPackagesToRequest).then(() => setRequestStatus({ timeLeft: requestTime, status: 'rebooting' }));
        },
        [changeStageRequest, requestTime],
    );

    return {
        //
        sendChangeStageRequest,
        requestStatus,
        requestTime: requestTime,
        // error: changeStageRequestError,
        loading,
    };
}
