import React, { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';
import AdminSupporterM from '../../../../../Model/AdminSupporterM';
import { TextField } from '@fluentui/react/lib/TextField';
import { IconButton } from '@fluentui/react';

interface propsAdminSupporter {
    Supporter: AdminSupporterM;
    ReorderSupporter: Function;
    RemoveSupporter: Function;
    SupporterUpdated: Function;
}
function AdminSupporter(props: propsAdminSupporter) {
    const [supporter, setSupporter] = useState<AdminSupporterM>(null);
    const [supporterHeader, setSupporterHeader] = useState<string>(props.Supporter.header);
    const [supporterDescription, setSupporterDescription] = useState<string>(props.Supporter.description);
    const [supporterWeb, setSupporterWeb] = useState<string>(props.Supporter.web);
    const [supporterEmail, setSupporterEmail] = useState<string>(props.Supporter.email);
    const [supporterPhone, setSupporterPhone] = useState<string>(props.Supporter.call);
    const [supporterOrder, setSupporterOrder] = useState<number>(props.Supporter.order);

    const [headerInFocus, setHeaderInFocus] = useState<boolean>(false);
    const headertxtfield = React.useRef(null);

    useEffect(() => {
        setSupporter(props.Supporter);
        setSupporterHeader(props.Supporter.header);
        setSupporterWeb(props.Supporter.web);
        setSupporterEmail(props.Supporter.email);
        setSupporterPhone(props.Supporter.call);
        setSupporterOrder(props.Supporter.order);
    }, [props.Supporter]);

    const moveUpClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement, MouseEvent>) => {
        props.ReorderSupporter(supporter, -1);
    };
    const moveDownClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement, MouseEvent>) => {
        props.ReorderSupporter(supporter, 1);
    };
    const removeClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement, MouseEvent>) => {
        props.RemoveSupporter(supporter, 1);
    };
    const newUpdateSupporter = () => {
        props.SupporterUpdated(supporter.id, supporterHeader, supporterDescription, supporterWeb, supporterEmail, supporterPhone);
    };
    const updateSupporterHeader = (event, value) => {
        setSupporterHeader(value);
        // props.SupporterUpdated(supporter.id, supporterHeader, supporterEmail, supporterPhone);
    };
    const supporterDescriptionUpdate = (event, value) => {
        setSupporterDescription(value);
        // props.SupporterUpdated(supporter.id, supporterHeader, supporterEmail, supporterPhone);
    };
    const supporterWebUpdate = (event, value) => {
        setSupporterWeb(value);
        // props.SupporterUpdated(supporter.id, supporterHeader, value, supporterPhone);
    };
    const supporterEmailUpdate = (event, value) => {
        setSupporterEmail(value);
        // props.SupporterUpdated(supporter.id, supporterHeader, value, supporterPhone);
    };
    const supporterPhoneUpdate = (event, value) => {
        setSupporterPhone(value);
        // props.SupporterUpdated(supporter.id, supporterHeader, supporterEmail, value);
    };
    useEffect(() => {
        if (headerInFocus === true) {
            headertxtfield.current.focus();
        }
    }, [headerInFocus]);
    return (
        <div style={{ width: 660 }}>
            <Stack tokens={{ childrenGap: 20 }} horizontal verticalAlign="baseline">
                <Stack.Item grow={1}>
                    {headerInFocus ? (
                        <TextField
                            componentRef={headertxtfield}
                            onBlur={() => {
                                setHeaderInFocus(false);
                                newUpdateSupporter();
                            }}
                            value={supporterHeader}
                            onChange={updateSupporterHeader}
                        />
                    ) : (
                        <Stack horizontal tokens={{ childrenGap: 0 }} verticalAlign="baseline">
                            <h4
                                onClick={() => {
                                    setHeaderInFocus(true);
                                }}
                                style={{ cursor: 'pointer', textDecoration: 'none' }}
                            >
                                {supporterHeader}
                            </h4>
                        </Stack>
                    )}
                </Stack.Item>
                <Stack.Item grow={5}>
                    <Separator></Separator>
                </Stack.Item>
                <Stack.Item grow={1} styles={{ root: { alignItems: 'center', display: 'flex', justifyContent: 'flex-end' } }}>
                    <Stack tokens={{ childrenGap: 0 }} horizontal>
                        <IconButton
                            iconProps={{ iconName: 'SkypeCircleArrow' }}
                            checked={false}
                            style={{ fontSize: 32, color: 'var(--palette-black)', transform: 'rotate(-90deg)' }}
                            onClick={moveDownClick}
                        />
                        <IconButton
                            iconProps={{ iconName: 'SkypeCircleArrow' }}
                            checked={false}
                            style={{ fontSize: 32, color: 'var(--palette-black)', transform: 'rotate(90deg)' }}
                            onClick={moveUpClick}
                        />
                        <IconButton iconProps={{ iconName: 'SkypeCircleMinus' }} checked={false} style={{ fontSize: 32, color: 'var(--palette-black)' }} onClick={removeClick} />
                    </Stack>
                </Stack.Item>
            </Stack>

            <div>
                <Stack tokens={{ childrenGap: 10 }} verticalAlign="start" style={{ margin: 10 }}>
                    <TextField width={300} placeholder={'Description'} value={supporterDescription} onChange={supporterDescriptionUpdate} onBlur={newUpdateSupporter} />
                    <TextField width={300} placeholder={'Web'} value={supporterWeb} onChange={supporterWebUpdate} onBlur={newUpdateSupporter} />
                    <TextField width={300} placeholder={'Email'} value={supporterEmail} onChange={supporterEmailUpdate} onBlur={newUpdateSupporter} />
                    <TextField width={300} placeholder={'Phone'} value={supporterPhone} onChange={supporterPhoneUpdate} onBlur={newUpdateSupporter} />

                    <br />
                </Stack>
            </div>
        </div>
    );
}

export default AdminSupporter;
