import { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import './AppLoader.scss';
import { TimeLoaderFullscreen } from '../TimeLoaderFullscreen';
import { Stack, Text } from '@fluentui/react';
import { useIsDarkmode } from '../../hooks/useIsDarkmode';
import { HubConnection } from '@microsoft/signalr';

type OperationStatus = {
    name: string | null;
    description: string | null;
    ready: boolean;
    timeStamp: string;
};

const CacheStatus = () => {
    const [logMessages, setLogMessages] = useState<OperationStatus[]>([]);
    const hubConnection = useRef<HubConnection | null>(null);
    const logContainerRef = useRef<HTMLDivElement | null>(null);

    const stopConnection = useCallback(() => {
        if (hubConnection.current && hubConnection.current.state === signalR.HubConnectionState.Connected) {
            hubConnection.current.stop().then(() => {
                console.debug('SignalR connection stopped.');
            });
        }
    }, []);

    useEffect(() => {
        if (!hubConnection.current) {
            hubConnection.current = new signalR.HubConnectionBuilder().withUrl('/hubs/hoststatus').build();

            hubConnection.current.start().then(() => {
                console.debug('SignalR connection established.');

                // Subscribe to the 'onMessage' event
                hubConnection.current.on('onMessage', message => {
                    setLogMessages(prevMessages => [...prevMessages, message]);
                    if (
                        logContainerRef.current &&
                        logContainerRef.current.scrollHeight - logContainerRef.current.scrollTop !== logContainerRef.current.clientHeight
                    ) {
                        logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
                    }
                });
            });
        }
    }, []);

    useEffect(() => {
        return () => {
            stopConnection();
        };
    }, [stopConnection]);

    const isDarkmode = useIsDarkmode();

    return (
        <TimeLoaderFullscreen>
            <Stack horizontalAlign="center">
                <Text styles={{ root: { color: isDarkmode && 'white' } }}>Preparing cache</Text>
            </Stack>
            <div style={{ marginTop: '32px', textAlign: 'left', maxHeight: '50vh', overflowY: 'auto', width: 400 }} ref={logContainerRef}>
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {logMessages.map((message, index) => (
                        <li
                            key={index}
                            style={{
                                backgroundColor: '#f0f0f0',
                                padding: '10px',
                                borderRadius: '4px',
                                margin: '8px 0',
                                animation: 'fade-in 0.5s ease-in-out forwards',
                                animationDelay: `${index * 0.1}s`,
                                textAlign: 'left',
                            }}
                        >
                            <div>
                                <strong>Name:</strong> {message.name}
                            </div>
                            <div>
                                <strong>Description:</strong> {message.description}
                            </div>
                            <div>
                                <strong>Timestamp:</strong> {message.timeStamp}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </TimeLoaderFullscreen>
    );
};

export const AppLoader = ({ children }: { children: React.ReactNode }) => {
    return <Suspense fallback={<CacheStatus />}>{children}</Suspense>;
};
