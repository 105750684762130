import React from 'react';
import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { makeStyles, Stack, Text, useTheme } from '@fluentui/react';
import { formatNumberToDisplay, formatNumberToDisplayPercent, getCleanValueDiff, roundToNearestValueStep } from '../../mobileUtils';
import { useMobile } from '../../useMobile';
import { useMobileContext } from '../../MobileContext';
import { useUiContext } from '../../../components/Contexts/UiContext';
import TimePeriodRowM from '../../../Model/TimePeriodRowM';
import { useGesture } from '@use-gesture/react';
import { convertHoursToProcent, convertProcentToHours, getCapacityPrDay } from '../../../components/WeekOverview/Cell.helpers';
import { periodFakeDay } from '../../mobileConstants';

interface IProps {
    hours: number;
    editTask: TimePeriodRowM;
    selectedDate: Date;
    isReadOnly: boolean;
}

// const pixelsDraggedToTrigger = 8;
const maxHoursInSlider = 12;
const maxHoursInInput = 24;
const pixelsDraggedToTriggerChange = 6;

export const TimeSlider: React.FC<IProps> = props => {
    const theme = useTheme();
    const uiCtx = useUiContext();
    const mobCtx = useMobileContext();
    const [state, setState] = useState<number>();
    const [isDragging, setIsDragging] = useState(false);
    const [isEditmode, setIsEditmode] = useState(false);
    // fix for double save when using input
    const [lastUsed, setLastUsed] = useState<'input' | 'drag' | undefined>();
    const classes = getStyles();
    const textfieldRef = useRef<any>();
    // const useGesture = createUseGesture([dragAction]);

    const maxValues = useMemo(() => {
        if (!mobCtx.isPeriodView) {
            return {
                slider: maxHoursInSlider,
                input: maxHoursInInput,
            };
        }
        const periodMaxHours = Math.round(mobCtx.selectedPeriodDays.length * getCapacityPrDay(mobCtx.selectedPeriodDays, uiCtx));
        if (mobCtx.showAsPercent) {
            return {
                slider: periodMaxHours,
                input: 100,
            };
        }
        return {
            slider: periodMaxHours,
            input: periodMaxHours,
        };
    }, [mobCtx.isPeriodView, mobCtx.showAsPercent, mobCtx.selectedPeriodDays]);

    const onTrigger = (direction: 'left' | 'right') => {
        let amount = 0.25;
        if (mobCtx.isPeriodView) {
            amount = mobCtx.showAsPercent || uiCtx.timeLowerLimitConfig === 0 ? 1 : 0.5;
        } else if (uiCtx.timeLowerLimitConfig === 0) {
            amount = 1;
        } else if (uiCtx.timeLowerLimitConfig === 0.5) {
            amount = 0.5;
        } else if (uiCtx.timeLowerLimitConfig === 0.1) {
            amount = 0.2;
        }

        if (direction === 'left') {
            setState(s => {
                if (mobCtx.showAsPercent) {
                    const percent = convertHoursToProcent(Number(s), mobCtx.selectedPeriodDays.length, getCapacityPrDay(mobCtx.selectedPeriodDays, uiCtx));
                    const newPercent = roundToNearestValueStep(percent - amount, uiCtx.timeLowerLimitConfig);
                    const newValue = convertProcentToHours(newPercent, mobCtx.selectedPeriodDays.length, getCapacityPrDay(mobCtx.selectedPeriodDays, uiCtx));
                    return newValue < 0 ? 0 : newValue;
                } else {
                    const newValue = roundToNearestValueStep(s - amount, uiCtx.timeLowerLimitConfig);
                    return newValue < 0 ? 0 : newValue;
                }
            });
        } else if (direction === 'right') {
            setState(s => {
                if (mobCtx.showAsPercent) {
                    const percent = convertHoursToProcent(Number(s), mobCtx.selectedPeriodDays.length, getCapacityPrDay(mobCtx.selectedPeriodDays, uiCtx));
                    const newPercent = roundToNearestValueStep(percent + amount, uiCtx.timeLowerLimitConfig);
                    const newValue = convertProcentToHours(newPercent, mobCtx.selectedPeriodDays.length, getCapacityPrDay(mobCtx.selectedPeriodDays, uiCtx));
                    return newValue > maxValues.slider ? maxValues.slider : newValue;
                } else {
                    const newValue = roundToNearestValueStep(s + amount, uiCtx.timeLowerLimitConfig);
                    return newValue > maxValues.slider ? maxValues.slider : newValue;
                }
            });
        }
    };

    const bind = useGesture(
        {
            onDrag: ({ memo, last, delta: [dx], active, tap }) => {
                if (tap && !active) {
                    return memo;
                }
                if (last) {
                    setIsDragging(false);
                } else if (!isDragging) {
                    setIsDragging(true);
                    setLastUsed('drag');
                }

                if (!memo) {
                    memo = {
                        direction: dx > 0 ? 'right' : 'left',
                        count: 0,
                    };
                } else {
                    const currentDirection = dx > 0 ? 'right' : 'left';
                    const currentMove = Math.abs(dx);
                    if (currentDirection === memo.direction) {
                        const currentCount = memo.count + currentMove;

                        if (currentCount >= pixelsDraggedToTriggerChange) {
                            onTrigger(currentDirection);
                            memo.count = currentCount - pixelsDraggedToTriggerChange;
                        } else {
                            memo.count = currentCount;
                        }
                    } else {
                        memo.direction = currentDirection;
                        memo.count = 0;
                    }
                }

                return memo;
            },
            // the empty onClick is needed because of some weird bug
            // the onClick 'area' on fluent's TextField is way bigger than the actual element
            // i have no idea why, but this empty onClick fixes it
            onClick: () => {},
        },
        {
            drag: {
                // filterTaps: true,
                preventScroll: true,
                axis: 'x',
            },
        },
    );

    useEffect(() => {
        if (!textfieldRef.current) {
            return;
        }
        textfieldRef.current.focus();
    }, [isEditmode]);

    useEffect(() => {
        setState(props.hours);
    }, [props.hours]);

    const values = useMemo(() => {
        if (mobCtx.showAsPercent) {
            return {
                hours: 0,
                minutes: 0,
                percent: `${formatNumberToDisplayPercent(state, mobCtx.selectedPeriodDays, uiCtx)}`,
            };
        }

        const formatted = formatNumberToDisplay(state, uiCtx);
        const newHour = Math.round(Math.trunc(formatted));
        const newMinute = Math.round((formatted - newHour) * 0.6 * 100);

        return {
            hours: newHour,
            minutes: newMinute,
            percent: '',
        };
    }, [state, mobCtx.showAsPercent]);

    const onSavePeriod = useCallback(
        (newHours: number) => {
            useMobile.getState().setIsloading({ active: true, overlay: false });
            const timeEntry = props.editTask.timeEntries?.[0];

            useMobile.getState().updateCellPeriod({
                taskId: props.editTask.taskId,
                totalHours: newHours,
                periodDays: mobCtx.selectedPeriodDays,
                comment: timeEntry?.comment ?? '',
                flagged: timeEntry?.flagged ?? false,
                selectedTimePeriod: mobCtx.selectedTimePeriod,
                workType: props.editTask.workType,
                uiCtx: uiCtx,
                onSuccess: () => {
                    useMobile.getState().setIsloading({ active: false, overlay: false });
                    mobCtx.onUpdateSuccessHighlightRow(props.editTask.taskId, periodFakeDay, null, props.editTask.workType?.id);
                },
                // updateTask: timeEntry ? false : true,
                updateTask: true,
            });
        },
        [props.editTask.timeEntries, props.selectedDate, props.hours, state, props.editTask, mobCtx.selectedTimePeriod, mobCtx.selectedPeriodDays],
    );

    const onSaveNormal = useCallback(
        (newHours: number) => {
            useMobile.getState().setIsloading({ active: true, overlay: false });
            const timeEntry = props.editTask.timeEntries?.[0];

            if (timeEntry) {
                useMobile.getState().updateTask({
                    newTask: { ...props.editTask, timeEntries: [{ ...timeEntry, hours: newHours }] },
                    updateEditTask: true,
                });
            }

            useMobile.getState().updateCell({
                taskId: props.editTask.taskId,
                date: props.selectedDate,
                hours: newHours,
                comment: timeEntry?.comment ?? '',
                flagged: timeEntry?.flagged ?? false,
                timePeriodId: mobCtx.selectedTimePeriod.reportPeriodId,
                workType: props.editTask.workType,
                uiCtx: uiCtx,
                onSuccess: newTimeEntry => {
                    console.debug('success', newTimeEntry);
                    useMobile.getState().setIsloading({ active: false, overlay: false });
                    mobCtx.onUpdateSuccessHighlightRow(props.editTask.taskId, null, newTimeEntry.date, props.editTask.workType?.id);
                },
                updateTask: timeEntry ? false : true,
            });
        },
        [props.editTask.timeEntries, props.selectedDate, props.hours, state],
    );

    const onSave = useCallback(
        (newHours: number) => {
            if (mobCtx.isPeriodView) {
                onSavePeriod(newHours);
            } else {
                onSaveNormal(newHours);
            }
        },
        [onSaveNormal, onSavePeriod, mobCtx.isPeriodView],
    );

    useEffect(() => {
        if (isDragging || state === undefined || lastUsed === undefined || lastUsed === 'input') {
            return;
        }

        const valueDiff = getCleanValueDiff(state, props.hours);

        if (valueDiff !== 0) {
            onSave(state);
        }
    }, [isDragging]);

    const onBlurEditmode = (newValue: number) => {
        const valueDiff = getCleanValueDiff(newValue, props.hours);

        setLastUsed('input');

        if (valueDiff !== 0) {
            onSave(newValue);
        }

        if (isDragging) {
            setIsDragging(false);
        }
    };

    const sliderProcessWidth = useMemo(() => {
        return (state / maxValues.slider) * 100 >= 100 ? 100 : (state / maxValues.slider) * 100;
    }, [state, maxValues.slider]);

    const getDefaultInputValue = () => {
        if (mobCtx.showAsPercent) {
            return `${formatNumberToDisplayPercent(state, mobCtx.selectedPeriodDays, uiCtx)}`;
        }
        return roundToNearestValueStep(state, uiCtx.timeLowerLimitConfig);
    };

    if (state === undefined) {
        return null;
    }

    return (
        <div className={classes.root}>
            {isEditmode ? (
                <div className={classes.numberInputContainer}>
                    <input
                        ref={textfieldRef}
                        type="number"
                        min={0}
                        max={maxValues.input}
                        autoFocus
                        autoComplete="off"
                        className={classes.numberInput}
                        defaultValue={`${getDefaultInputValue()}`}
                        onBlur={(e: any) => {
                            let newValue = Number(e.target.value);
                            if (Number.isNaN(newValue)) {
                                setIsEditmode(false);
                                return;
                            }

                            newValue = roundToNearestValueStep(newValue, uiCtx.timeLowerLimitConfig);

                            if (newValue < 0) {
                                newValue = 0;
                            } else if (newValue > maxValues.input) {
                                newValue = maxValues.input;
                            }
                            if (mobCtx.showAsPercent) {
                                newValue = convertProcentToHours(newValue, mobCtx.selectedPeriodDays.length, getCapacityPrDay(mobCtx.selectedPeriodDays, uiCtx));
                            }
                            setState(newValue);
                            onBlurEditmode(newValue);
                            setIsEditmode(false);
                        }}
                    />
                </div>
            ) : (
                <Stack
                    horizontal
                    styles={{ root: classes.textContainer }}
                    onClick={() => {
                        setIsEditmode(true);
                    }}
                >
                    {mobCtx.showAsPercent ? (
                        <Text styles={{ root: classes.hourMinute }} style={{ width: 70, left: 'calc(50% - 50px)' }}>
                            <b className={classes.percent}>{values.percent}</b> %
                        </Text>
                    ) : (
                        <>
                            <Text styles={{ root: classes.hourMinute }} style={{ width: 70, left: 'calc(50% - 70px)' }}>
                                <b className={classes.number}>{values.hours}</b> hour{values.hours > 2 ? 's' : ''}
                            </Text>
                            <Text styles={{ root: classes.hourMinute }} style={{ width: 100, right: 'calc(50% - 100px)' }}>
                                <b className={classes.number}>{values.minutes}</b> minute{values.minutes > 1 ? 's' : ''}
                            </Text>
                        </>
                    )}
                </Stack>
            )}
            <div className={classes.dragContainer}>
                <div className={classes.overlayContainer} style={{ transform: `scale(${isDragging ? '1.025, 1.2' : 1})` }}>
                    <div className={classes.overlay} style={{ width: `${sliderProcessWidth}%`, backgroundColor: isDragging ? theme.palette.themeDarkAlt : theme.palette.neutralLighter }} />
                </div>
                <Text className={classes.dragText}>drag to register time</Text>
                <div {...bind()} className={classes.drag} />
            </div>
        </div>
    );
};

const getStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
    },
    dragContainer: {
        marginTop: 8,
        height: 26,
        width: '100%',
        position: 'relative',
    },
    drag: {
        position: 'absolute',
        inset: 0,
        zIndex: 5,
    },
    dragText: {
        position: 'absolute',
        inset: 0,
        fontSize: 11,
        fontWeight: 300,
        color: theme.semanticColors.bodySubtext,
        textAlign: 'center',
        lineHeight: '26px',
        zIndex: 2,
        opacity: 0.2,
    },
    overlayContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: theme.semanticColors.bodyFrameBackground,
        borderRadius: 4,
        border: `1px solid ${theme.palette.neutralLighter}`,
        transition: 'transform 75ms',
        overflow: 'hidden',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        backgroundColor: theme.palette.neutralLighter,
        transition: 'background-color 75ms',
    },
    textContainer: {
        position: 'relative',
        height: 36,
        width: '100%',
    },
    numberInputContainer: {
        boxShadow: `inset 0 0 0 1px ${theme.palette.themePrimary}, 0 0 0 1px ${theme.palette.themePrimary}`,
        borderRadius: 4,
    },
    numberInput: {
        width: '100%',
        height: 36,
        padding: `0 ${theme.spacing.s1}`,
        fontFamily: 'inherit',
        fontSize: 14,
        fontWeight: '400',
        border: '0px solid rgba(0,0,0,0)',
        // boxShadow: `0 0 1px ${theme.palette.neutralLighter}`,
        borderRadius: 4,
        color: theme.semanticColors.bodyText,
        backgroundColor: 'rgba(0,0,0,0)',
        outline: 'none',
        // boxShadow: `inset 0 0 0 1px ${theme.palette.themePrimary}, 0 0 0 1px ${theme.palette.themePrimary}`,
        // "&:focus-visible, &:focused, &:active": {
        //     boxShadow: `inset 0 0 0 1px ${theme.palette.themePrimary}, 0 0 0 1px ${theme.palette.themePrimary}`,
        // },
    },
    number: {
        color: theme.semanticColors.bodyText,
        fontWeight: 400,
        width: 26,
        textAlign: 'right',
        paddingRight: 6,
        userSelect: 'none',
    },
    percent: {
        color: theme.semanticColors.bodyText,
        fontWeight: 400,
        flexGrow: 1,
        textAlign: 'right',
        paddingRight: 6,
        userSelect: 'none',
    },
    hourMinute: {
        color: theme.semanticColors.bodySubtext,
        fontWeight: 300,
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        width: 100,
        textAlign: 'left',
        position: 'absolute',
        top: 0,
        bottom: 0,
        userSelect: 'none',
    },
}));
