import { createContext as createCtx, useContext } from 'react';

export function createContext<ContextType>() {
    const ctx = createCtx<ContextType | undefined>(undefined);

    function useCtx() {
        const c = useContext(ctx);
        if (!c) throw new Error('useCtx must be inside a Provider with a value');

        return c;
    }

    return [useCtx, ctx.Provider] as const;
}
