// import { Calendar } from "@fluentui/react";
import { Console } from 'console';
import React from 'react';
// import { Holiday } from "../Admin/Calendar/AddHoliday";
import { useUiContext } from '../components/Contexts/UiContext';
import { ErrorDialog } from '../components/ErrorDialog';
import GetAssignmentsByUserCustomPeriodDto from '../Model/GetAssignmentsByUserCustomPeriodDto';
import { Calendar } from '../Model/NewModels/Calendar';
import { ApproverCustomFilter } from '../Model/NewModels/ApproverCustomFilter';
import WorkType from '../Model/NewModels/WorkType';
import WorkUserType from '../Model/NewModels/WorkUserType';
import Period from '../Model/Period';
import { TimeError } from '../Model/TimeError';
import { AxiosInstance } from 'axios';
import { TimeEntryError, TimeUser } from '../api/generated/data-contracts';
import User from '../Model/User';

// let baseUrl = window.location.href.includes('localhost:3000') ? 'https://localhost:44341' : '';
const baseUrl = '';

export const checkResponse = (response: any) => {
    if (response?.stackTrace || response?.message) {
        throw new TimeError(response.name, response.pluginId, response.message, response.stackTrace);
    }
    // if (typeof response === 'string') {
    //     throw new Error(response);
    // }
    return response;
};

async function get(url: string, setError: any, httpClient: AxiosInstance) {
    // const test = await httpClient.get(url);

    const response = await httpClient.get(url);
    return checkResponse(response.data);

    // return await httpClient.get(url)
    // .then(response => response.json())
    // .then(json => {
    //     return checkResponse(json);
    // }).catch((err) => {
    //     console.error("Error from API", err);
    //     setError && setError(err);
    //     return {error:"Error connecting to server", innerError:err};
    // });
}

async function post(url: string, payload: any, setError: any, httpClient: AxiosInstance) {
    const response = await httpClient.post(url, payload, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
    });
    return checkResponse(response.data);
    // return await httpClient.post(url, payload).then(response => response.json())
    // .then(json => {
    //     return checkResponse(json);
    // }).catch((err) => {
    //     console.error("Error from API", err);
    //     setError && setError(err);
    // })
}

export const timeApi = (setError, HttpClient) => ({
    getUser: async () => {
        return await get(baseUrl + '/api/plugin/time/11AE4FA2-D1D8-4A4A-9FC8-2261B2E6C7E8', setError, HttpClient);
    },
    getPeriodByDates: async (date: string) => {
        return await post(baseUrl + '/api/plugin/time/E1C1D3E4-3A7C-44B6-9446-E7DA9E660D12', JSON.stringify({ Date: date }), setError, HttpClient);
    },
    // getTimeByReportPeriod: async (reportingId: string) => {
    //     return await post(baseUrl + '/api/plugin/time/8C1F5E2B-891A-4924-A4A8-FCE2C1ACA572', JSON.stringify({ UserReportingPeriodId: reportingId }), setError, HttpClient);
    // },
    getTimeByReportPeriodCustomPeriod: async (reportingId: string) => {
        return await post(
            baseUrl + '/api/plugin/time/AB8BF533-DB05-4C14-A998-B993D88D7DEA',
            JSON.stringify({ UserReportingPeriodId: reportingId }),
            setError,
            HttpClient,
        );
    },
    // updateTimeByTask: async (userReportingPeriodId: string, taskId: string, day: string, hours: number, comment: string, userId: number) => {
    //     return await post(baseUrl + '/api/plugin/time/D459705C-44CF-4D1A-A002-269404C07582',
    //         JSON.stringify({ TaskId: taskId, UserReportingPeriodId: userReportingPeriodId, Day: day, Hours: hours, Comment: comment }), setError, HttpClient);
    // },
    updateTime: async (taskId: string, date: Date, hours: number, comment: string, flagged: boolean, userReportingPeriodId: string, workTypeId?: string) => {
        return await post(
            baseUrl + '/api/plugin/time/C6FED049-1AFE-40F8-A8FC-813816A2D0E0',
            JSON.stringify({
                TaskId: taskId,
                Date: date,
                Hours: hours,
                Comment: comment,
                Flagged: flagged,
                UserReportingPeriodId: userReportingPeriodId,
                workTypeId: workTypeId,
            }),
            setError,
            HttpClient,
        );
    },
    setPinned: async (taskId: string, pinned: boolean, workTypeId: string) => {
        return await post(
            baseUrl + '/api/plugin/time/7D7621D7-950D-4F3D-A9B5-D476AE262B96',
            JSON.stringify({ TaskId: taskId, Pinned: pinned, workTypeId }),
            setError,
            HttpClient,
        );
    },
    updateStatusByPeriod: async (userReportingPeriodId: string, state, nextstate = state, submitComment = '') => {
        return await post(
            baseUrl + '/api/plugin/time/8FE0378D-6AF2-4D86-957D-0C4F9A039541',
            JSON.stringify({ UserReportingPeriodId: userReportingPeriodId, State: state, NextState: nextstate, SubmitComment: submitComment }),
            setError,
            HttpClient,
        );
    },
    searchDefaultTasks: async () => {
        return await get(baseUrl + '/api/plugin/time/B0D700A2-B23A-4CD1-8D6B-9189BB59E8FD', setError, HttpClient);
    },
    searchByQuery: async (query: string) => {
        return await post(baseUrl + '/api/plugin/time/E3D3B934-67D6-4508-97C5-374655D6F70B', JSON.stringify({ query }), setError, HttpClient);
    },
    searchByQueryAssigned: async (query: string) => {
        return await post(baseUrl + '/api/plugin/time/CC902957-BCEE-44BC-89FE-3998B9C75829', JSON.stringify({ query }), setError, HttpClient);
    },
    // getApprovalLinesByManager: async (startDate: Date, endDate: Date) => {
    //     return await post(baseUrl + '/api/plugin/time/FC2B04B3-AE80-43D2-8BEF-0E638DC0CB72', JSON.stringify({ startDate, endDate }), setError, HttpClient);
    // },
    getApprovalLinesByManagerCustomPeriod: async (startDate: Date, endDate: Date) => {
        return await post(baseUrl + '/api/plugin/time/4171DDCA-47E9-4ACD-94A7-1AF59333F764', JSON.stringify({ startDate, endDate }), setError, HttpClient);
    },
    // getApprovalLinesByUser: async (userReportingPeriodId: string, day: string) => {
    //     return await post(baseUrl + '/api/plugin/time/EADFDE6D-4F03-4CA0-B71D-FE213DC38DE9', JSON.stringify({ userReportingPeriodId, day }), setError, HttpClient);
    // },
    getApprovalLinesByUserCustomPeriod: async (userReportingPeriodId: string, date: Date) => {
        return await post(
            baseUrl + '/api/plugin/time/821756CF-389B-42B6-B1A7-679027C7E47B',
            JSON.stringify({ userReportingPeriodId, date }),
            setError,
            HttpClient,
        );
    },
    addCommentToUserReportingPeriod: async (userReportingPeriodId: string, comment: string) => {
        return await post(
            baseUrl + '/api/plugin/time/43511ABB-C735-4E86-8D7B-DFFBCD9E089B',
            JSON.stringify({ userReportingPeriodId, comment }),
            setError,
            HttpClient,
        );
    },
    setApprover: async (userId: string, approverUserId: string) => {
        return await post(
            baseUrl + '/api/plugin/time/E62E6D59-CD7A-474A-A4C8-A846DB729EE2',
            JSON.stringify({ UserId: userId, ApproverUserId: approverUserId }),
            setError,
            HttpClient,
        );
    },
    // getProjects: async () => {
    //     return await get(baseUrl + '/api/plugin/time/1990739C-0891-417F-8BA6-7FD0D5335CCD', setError, HttpClient);
    // },
    getProjectSources: async () => {
        return await get(baseUrl + '/api/plugin/time/5236835F-E12D-4334-8362-6B7E31A279E7', setError, HttpClient);
    },
    // getTasksByProject: async (projectId: string) => {
    //     return await post(baseUrl + '/api/plugin/time/3DB7927D-484A-4E52-A7EB-94C188E8ADD1', JSON.stringify({ ProjectId: projectId }), setError, HttpClient);
    // },
    // getAssignmentsByProject: async (projectId: string) => {
    //     return await post(baseUrl + '/api/plugin/time/1AA73F49-953C-49A2-B4FD-627644F1FF81', JSON.stringify({ ProjectId: projectId }), setError, HttpClient);
    // },
    getVideos: async () => {
        return await get(baseUrl + '/api/plugin/time/7DD4272C-BE14-490A-BF77-60DC6D067988', setError, HttpClient);
    },
    getFAQ: async () => {
        return await get(baseUrl + '/api/plugin/time/2FDEF002-445A-47DB-8AF2-92C5D9442D11', setError, HttpClient);
    },
    getSupport: async () => {
        return await get(baseUrl + '/api/plugin/time/B406C030-6A52-444E-9389-6F4D16AD1B0E', setError, HttpClient);
    },
    getGuide: async () => {
        return await get(baseUrl + '/api/plugin/time/B75ECFE8-65D3-4F22-B821-698DB2C64590', setError, HttpClient);
    },
    setVideos: async videos => {
        return await post(baseUrl + '/api/plugin/time/40C92CDD-FB5C-4161-9342-F8F7F20109C2', JSON.stringify(videos), setError, HttpClient);
    },
    setFAQ: async faq => {
        return await post(baseUrl + '/api/plugin/time/566E2519-131D-408A-9978-F911F80256C0', JSON.stringify(faq), setError, HttpClient);
    },
    setSupporters: async supporters => {
        return await post(baseUrl + '/api/plugin/time/C7C7354D-4B10-41CB-AEE4-1143AB1C2093', JSON.stringify(supporters), setError, HttpClient);
    },
    setPeriodState: async (period: any, state: number) => {
        return await post(
            baseUrl + '/api/plugin/time/33B2136D-237F-4FAD-B4ED-C4D126E88655',
            JSON.stringify({ Period: period, SetState: state }),
            setError,
            HttpClient,
        );
    },
    getUsers: async () => {
        return await get(baseUrl + '/api/plugin/time/25713FC3-08F9-41E0-9F6B-83B43B22F52D', setError, HttpClient);
    },
    searchUsers: async (searchText: string) => {
        return await get(baseUrl + `/api/users/searchUsers?searchText=${searchText}`, setError, HttpClient);
    },
    getUsersWithApprovers: async () => {
        return await get(baseUrl + '/api/plugin/time/A4C1A4DA-66FE-4BC3-AE75-CA5C4E0A3E33', setError, HttpClient);
    },
    getUsersWithCalendars: async () => {
        return await get(baseUrl + '/api/plugin/time/6D9C2D7A-378E-4BFC-8980-4C6A4CD9BEDA', setError, HttpClient);
    },
    // getUsersForAdmin: async () => {
    //     return await get(baseUrl + '/api/plugin/time/C8C4BB65-0AD1-4A9E-8154-FD816B749E5F', setError, HttpClient);
    // },
    setUserAdmin: async (userid: string, start: Date, end: Date, calendar: Calendar, isNotActive: boolean) => {
        return await post(
            baseUrl + '/api/plugin/time/E19653F3-40BB-4FB5-A5DA-FAAFE49CB197',
            JSON.stringify({ UserId: userid, Start: start, End: end, Calendar: calendar, IsNotActive: isNotActive }),
            setError,
            HttpClient,
        );
    },
    deleteUser: async (userid: string) => {
        return await post(baseUrl + '/api/plugin/time/CBC6E25C-09E6-4C92-AC32-C1FAD1D8A2A5', JSON.stringify({ UserId: userid }), setError, HttpClient);
    },
    setUserAutoApprove: async (userId: string, autoApprove: boolean) => {
        return await post(baseUrl + '/api/plugin/time/292D780E-F341-43B7-8E90-FB50EE121400', JSON.stringify({ userId, autoApprove }), setError, HttpClient);
    },
    getUserByUserId: async (userId: string) => {
        return await post(baseUrl + '/api/plugin/time/0D513581-95EF-4BF1-B0E9-50CC54B3E66F', JSON.stringify({ UserId: userId }), setError, HttpClient);
    },
    getDelegates: async () => {
        return await get(baseUrl + '/api/plugin/time/3E81881C-9515-4082-91DF-1E6A74C1F9A3', setError, HttpClient);
    },
    getUsersWithDelegates: async () => {
        return await get(baseUrl + '/api/plugin/time/802D0056-21B6-43C6-81F4-ADFBF921DBD6', setError, HttpClient);
    },
    setIsAdminStatus: async (adminUserId: string, isAdmin: boolean) => {
        return await post(
            baseUrl + '/api/plugin/time/39EB55D8-4D35-48A0-9EF9-3B427DAFDA44',
            JSON.stringify({ AdminUserId: adminUserId, IsAdmin: isAdmin }),
            setError,
            HttpClient,
        );
    },
    setDelegate: async (userid: string, delegateids: string[]) => {
        return await post(
            baseUrl + '/api/plugin/time/A12C62E9-DF97-4F7C-8474-DDB330E7C42D',
            JSON.stringify({ UserId: userid, DelegateIds: delegateids }),
            setError,
            HttpClient,
        );
    },
    updateUser: async (user: TimeUser) => {
        return await post(baseUrl + '/api/users/updateUser', JSON.stringify(user), setError, HttpClient);
    },
    activateDelegate: async (delegateid: string) => {
        return await post(baseUrl + '/api/plugin/time/C6082518-5B06-4B20-82AB-566DB9EAAEC8', JSON.stringify({ DelegateId: delegateid }), setError, HttpClient);
    },
    getPeriods: async () => {
        return await get(baseUrl + '/api/plugin/time/1CEF1C80-010A-4CB5-859B-4DB4CB0CEE7E', setError, HttpClient);
    },
    GetAllPeriodsAsAdminPeriods: async () => {
        return await get(baseUrl + '/api/plugin/time/0EE38DF9-49DA-411B-AC5B-CD2D5DE55B60', setError, HttpClient);
    },
    getPeriodsByDates: async (fromDate: Date, toDate: Date) => {
        return await post(baseUrl + '/api/plugin/time/F0C1C757-C416-4B00-8752-AECF7947243B', JSON.stringify({ fromDate, toDate }), setError, HttpClient);
    },
    getUsersByManager: async () => {
        return await get(baseUrl + '/api/plugin/time/344F4894-E86A-4AE9-BDF1-5828526C33E7', setError, HttpClient);
    },
    // getUsersByDirectApprover: async () => {
    //     return await get(baseUrl + '/api/plugin/time/CAB47F02-8FF1-4503-8F6C-697FAE573AA8', setError, HttpClient);
    // },
    getTimeByDateMobile: async date => {
        return await post(baseUrl + '/api/plugin/time/3C943850-44C9-48A3-B5E6-A86009BEC3CA', JSON.stringify({ Date: date }), setError, HttpClient);
    },
    getGraphUser: async date => {
        return await get(baseUrl + '/api/plugin/time/94A4E9F6-0521-470B-B74E-245D0247AC85', setError, HttpClient);
    },
    // getUiUser: async () => {
    //     return await get(baseUrl + '/api/plugin/time/8AF4070E-AF19-4C94-B067-DA3FCF3A1878', setError, HttpClient);
    // },
    getAdminProjectsAndTasksForConfig: async () => {
        return await get(baseUrl + '/api/plugin/time/084B4CEE-D993-4360-B8E9-4502CA307693', setError, HttpClient);
    },
    setAdminProjectsAndTasksForConfig: async projects => {
        return await post(baseUrl + '/api/plugin/time/1C0DAE6B-FF82-4F12-8BAF-72B386F8E747', JSON.stringify(projects), setError, HttpClient);
    },
    setAdminProjectsSource: async projectSource => {
        return await post(baseUrl + '/api/plugin/time/622BD474-9FE8-483B-9F84-B59A9B826DBD', JSON.stringify(projectSource), setError, HttpClient);
    },
    getAllConfigs: async () => {
        return await get(baseUrl + '/api/plugin/time/1FB918CE-B94F-484F-8E9B-0DDFAA4EC9DF', setError, HttpClient);
    },
    getAllConfigsAdmin: async () => {
        return await get(baseUrl + '/api/plugin/time/8EC3B9D2-7FCE-4BDA-886B-8EC663127A3A', setError, HttpClient);
    },
    getConfigByKey: async (key: string) => {
        return await post(baseUrl + '/api/plugin/time/1B555676-4C73-4E95-8665-28B7E0B503E5', JSON.stringify({ Key: key }), setError, HttpClient);
    },
    setConfig: async (key: string, value: string) => {
        return await post(baseUrl + '/api/plugin/time/1AF6EAC6-F33B-4430-89D9-101D435B798B', JSON.stringify({ Key: key, Value: value }), setError, HttpClient);
    },
    editPeriod: async (period: Period) => {
        return await post(baseUrl + '/api/plugin/time/BAA3320E-E1C2-4406-9C69-C65993330AE9', JSON.stringify({ ...period }), setError, HttpClient);
    },
    createPeriod: async (period: Period) => {
        return await post(baseUrl + '/api/plugin/time/E3D28DBD-0908-45BA-8AF0-F3B9608C5A1F', JSON.stringify({ ...period }), setError, HttpClient);
    },
    deletePeriod: async (period: Period) => {
        return await post(baseUrl + '/api/plugin/time/576EBF12-7DA7-4D3F-A26C-5E5AE4B42AE4', JSON.stringify({ ...period }), setError, HttpClient);
    },
    getAllowedWorkTypes: async () => {
        return await get(baseUrl + '/api/plugin/time/2523FC6D-B677-469A-B6B2-7580DAE2EF88', setError, HttpClient);
    },
    // changeWorkType: async (assignmentId: string, workTypeId: string) => {
    //     return await post(baseUrl + '/api/plugin/time/3FBF6BC7-B7E8-4D87-835E-FF8EE2FA8244', JSON.stringify({ assignmentId, workTypeId }), setError, HttpClient);
    // },
    changeWorkTypeFromKey: async (taskId: string, oldWorkTypeId: string, workTypeId: string) => {
        return await post(
            baseUrl + '/api/plugin/time/E8207084-C830-46DD-8458-1B31478FD547',
            JSON.stringify({ taskId, workTypeId, oldWorkTypeId }),
            setError,
            HttpClient,
        );
    },
    // createWorkType: async (name: string, workUserTypeId: string) => {
    //     return await post(baseUrl + '/api/plugin/time/CDBE92B5-A3B5-4740-A203-6A099553D5CC', JSON.stringify({ name, workUserTypeId }), setError, HttpClient);
    // },
    // createWorkUserType: async (name: string) => {
    //     return await post(baseUrl + '/api/plugin/time/7E6F46F2-F588-4587-9F55-E8E179278EDF', JSON.stringify({ name }), setError, HttpClient);
    // },
    getAllProjectWorkTypes: async () => {
        return await get(baseUrl + '/api/plugin/time/192E2A52-B637-4897-B90F-AA5045A102DF', setError, HttpClient);
    },
    copyTimeSheet: async (fromReportingPeriodId: string, toReportingPeriodId: string) => {
        return await post(
            baseUrl + '/api/plugin/time/3CC06934-D3A6-4D06-A7F5-F2390ACFF7B1',
            JSON.stringify({ fromReportingPeriodId, toReportingPeriodId }),
            setError,
            HttpClient,
        );
    },
    // getOpenPeriodsForCurrentUser: async () => {
    //     return await get(baseUrl + '/api/plugin/time/4217D946-BAF0-462C-8E92-0FFBD06FF022', setError, HttpClient);
    // },
    // createCalendar: async (name: string, language: string, holidays: Holiday[]) => {
    //     return await post(baseUrl + '/api/plugin/time/E01B4D33-639F-4709-B5D5-8D3C6812A51F', JSON.stringify({ name, language, holidays }), setError, HttpClient);
    // },
    // editCalendar: async (id: string, name: string, holidays: Holiday[]) => {
    //     return await post(baseUrl + '/api/plugin/time/76B9DABD-51ED-400C-8F2D-F4DCD172C811', JSON.stringify({ id, name, holidays }), setError, HttpClient);
    // },
    setCalendars: async (calendars: Calendar[]) => {
        return await post(baseUrl + '/api/plugin/time/C9B10055-495F-409B-9B03-D312CFD92EA6', JSON.stringify(calendars), setError, HttpClient);
    },
    // deleteCalendar: async (id: string) => {
    //     return await post(baseUrl + '/api/plugin/time/7BF5159B-C539-4CB2-AEA5-C4C3A72B7DA2',  `"${id}"`, setError, HttpClient);
    // },
    getCalendars: async () => {
        return await get(baseUrl + '/api/plugin/time/F370E232-6979-4F18-A5ED-E0298858610B', setError, HttpClient);
    },
    // getCalendarDays: async (country: string) => {
    //     return await post(baseUrl + '/api/plugin/time/F1427728-5732-416B-8625-D58CC30B6EBE', `"${country}"`, setError, HttpClient);
    // },
    getCalendarForUser: async () => {
        return await get(baseUrl + '/api/plugin/time/28370C02-7801-4EC1-B0DB-00BB5188FFE8', setError, HttpClient);
    },
    // getCalendarForUser: async (userId: string) => {
    //     return await post(baseUrl + '/api/plugin/time/28370C02-7801-4EC1-B0DB-00BB5188FFE8', `"${userId}"`, setError, HttpClient);
    // },
    getHolidaysByApi: async (country: string, year: string) => {
        return await post(baseUrl + '/api/plugin/time/0B114B60-9D9C-4632-A50C-9C7410C73469', JSON.stringify({ country, year }), setError, HttpClient);
    },
    getUserChartDataByWeek: async (startDate: Date, endDate: Date) => {
        return await post(baseUrl + '/api/plugin/time/EA492B8F-33D2-4AAC-ABE9-B9C36FC3A6DA', JSON.stringify({ startDate, endDate }), setError, HttpClient);
    },
    getPMChartData: async (projectid: string) => {
        return await post(baseUrl + '/api/plugin/time/705DAF9E-7EF5-4483-9418-3716A6666598', JSON.stringify({ projectid }), setError, HttpClient);
    },
    getPMChartProjects: async () => {
        return await post(baseUrl + '/api/plugin/time/4D52E92F-C128-4B86-A592-B4FF8069EACF', JSON.stringify(''), setError, HttpClient);
    },
    // getUserProjectsPerWeek: async () => {
    //     return await get(baseUrl + '/api/plugin/time/A2E268AC-AC5B-4CA8-ADAE-9DA564B888F6', setError, HttpClient);
    // },
    getUserProjctHoursByWeekFluentUI: async () => {
        return await get(baseUrl + '/api/plugin/time/935F1D95-8A0D-4083-AD2C-6DDE329FF709', setError, HttpClient);
    },
    createDemoData: async () => {
        return await get(baseUrl + '/api/plugin/time/17165C8B-D431-4474-B47B-E94F35BCEF04', setError, HttpClient);
    },
    listEntities: async () => {
        return await get(baseUrl + '/api/plugin/time/78EE7226-6BD9-45FA-9617-96ECF1024D9B', setError, HttpClient);
    },
    entityMetadata: async (entityLogicalName: string) => {
        return await post(baseUrl + '/api/plugin/time/D6DAF20F-A4EA-4C28-8BD5-C63586A5442F', JSON.stringify({ entityLogicalName }), setError, HttpClient);
    },
    getClientId: async () => {
        return await get(baseUrl + '/api/plugin/time/138D7856-81F1-432F-BAB3-5B9A15954AD7', setError, HttpClient);
    },
    upsertSyncScheduleAndSync: async () => {
        return await get(baseUrl + '/api/plugin/time/48FB9AB7-35E7-4AB5-914B-68F9402BC728', setError, HttpClient);
    },
    getADGroups: async (query: string) => {
        return await post(baseUrl + '/api/plugin/time/416A41F8-102D-4818-BFED-BAC4CF7DDD15', JSON.stringify({ query }), setError, HttpClient);
    },
    // getAuthOptions: async () => {
    //     return await get(baseUrl + '/api/auth', setError, HttpClient);
    // },
    fullSyncUsers: async () => {
        return await get(baseUrl + '/api/plugin/time/DAEEC2A0-9002-4170-98BF-5AEAAD0BC653', setError, HttpClient);
    },
    importData: async () => {
        return await get(baseUrl + '/api/plugin/time/7DF4F728-5F59-4532-8727-BF31820DFF02', setError, HttpClient);
    },
    saveAsApproverCustomFilter: async (ApproverCustomFilter: ApproverCustomFilter) => {
        return await post(baseUrl + '/api/plugin/time/191F7BBA-C845-4BDD-85E0-33292CFABD9F', JSON.stringify(ApproverCustomFilter), setError, HttpClient);
    },
    saveApproverCustomFilter: async (ApproverCustomFilter: ApproverCustomFilter) => {
        return await post(baseUrl + '/api/plugin/time/7BB65BB4-0AB7-4F9E-84CB-C60A51B12C6F', JSON.stringify(ApproverCustomFilter), setError, HttpClient);
    },
    setApproverDefaultFilter: async (id: string) => {
        return await post(baseUrl + '/api/plugin/time/36982898-2CA9-4B0A-BA11-CBF1C70682E6', JSON.stringify(id), setError, HttpClient);
    },
    deleteApproverCustomFilter: async (id: string) => {
        return await post(baseUrl + '/api/plugin/time/72C1D919-7CF5-4288-9472-093EE0B62780', JSON.stringify(id), setError, HttpClient);
    },
    renameApproverCustomFilter: async (id: string, name: string) => {
        return await post(baseUrl + '/api/plugin/time/516AEBB0-38AB-463E-906F-465EE53C3429', JSON.stringify({ id: id, name: name }), setError, HttpClient);
    },
    getApproverCustomFilterByUser: async () => {
        return await get(baseUrl + '/api/plugin/time/DF74FA3A-CD36-4B83-A567-8097C1CE420D', setError, HttpClient);
    },
    upsertWorkDayTime: async (userReportingPeriodId: string, workDay: WorkDay) => {
        return await post(
            baseUrl + '/api/plugin/time/B5B49EE4-5E18-4A9B-B166-8B2320B6FCA9',
            JSON.stringify({ userReportingPeriodId: userReportingPeriodId, workDay: workDay }),
            setError,
            HttpClient,
        );
    },
    // saveCells: async ({ taskId, days, hours, comment, userReportingPeriodId, workTypeId }) => {
    //     return await post(baseUrl + '/api/plugin/time/C572D424-08DC-4D19-99C8-21B57D78DC87', { taskId, days, hours, comment, userReportingPeriodId, workTypeId }, setError, HttpClient);
    // },
    saveCellsPeriodOverview: async ({ taskId, days, hours, comment, flagged, userReportingPeriodId, workTypeId }) => {
        return await post(
            baseUrl + '/api/plugin/time/634943AA-6A30-4EC5-8134-3465A1D07A17',
            { taskId, days, hours, comment, flagged, userReportingPeriodId, workTypeId },
            setError,
            HttpClient,
        );
    },
    setDataverseURL: async (dataverseURL: string) => {
        return await post(baseUrl + `/api/integration/dataverse/setDataverseURL?dataverseURL=${dataverseURL}`, {}, setError, HttpClient);
    },
    removeDataverseURL: async () => {
        return await post(baseUrl + `/api/integration/dataverse/removeDataverseURL`, {}, setError, HttpClient);
    },
    dataverseIsSetup: async () => {
        return await get(baseUrl + '/api/integration/dataverse/dataverseIsSetup', setError, HttpClient);
    },
    setDataverseConfig: async (dataverseUrl: string) => {
        return await post(baseUrl + `/api/plugin/time/74B8C3EA-36CC-4ABE-8737-C73C2C162ACB`, JSON.stringify({ url: dataverseUrl }), setError, HttpClient);
    },
    addStep: async (logicalName: string) => {
        return await post(baseUrl + `/api/integration/dataverse/addStep?logicalName=${logicalName}`, {}, setError, HttpClient);
    },
    getStep: async (logicalName: string) => {
        return await post(baseUrl + '/api/integration/dataverse/getStep', logicalName, setError, HttpClient);
    },
    getSteps: async () => {
        return await get(baseUrl + '/api/integration/dataverse/getSteps', setError, HttpClient);
    },
    removeStep: async (logicalName: string) => {
        return await post(baseUrl + `/api/integration/dataverse/removeStep?logicalName=${logicalName}`, {}, setError, HttpClient);
    },
    removeSteps: async () => {
        return await get(baseUrl + '/api/integration/dataverse/removeSteps', setError, HttpClient);
    },
    setConfigList: async (configItems: []) => {
        return await post(baseUrl + '/api/plugin/time/6F38EE89-2601-48E6-9DF3-E656A573327A', JSON.stringify({ configItems }), setError, HttpClient);
    },
    getTimeForTeamsVersion: async () => {
        return await get(baseUrl + '/api/plugin/time/98BA0105-BB22-4679-B92D-AD431ED5CBA7', setError, HttpClient);
    },
    upsertPowerhubServicePrincipal: async clientId => {
        return await post(baseUrl + '/api/integration/powerhub/upsertPowerhubServicePrincipal', { clientId }, setError, HttpClient);
    },
    getPowerhubServicePrincipal: async () => {
        return await get(baseUrl + '/api/integration/powerhub/getPowerhubServicePrincipal', setError, HttpClient);
    },
    timeEntriesUpload: async (formData: File): Promise<TimeEntryError[]> => {
        return await post(baseUrl + '/api/import/upload/ImportTimeEntry', formData, setError, HttpClient);
    },
});

export interface WorkDay {
    date: Date;
    start: Date | undefined | null;
    end: Date | undefined | null;
}
