import { ContentSection, PageContent, PageHeader } from '../../../../components/LayoutElements';
import { Stack } from '@fluentui/react';
import { SearchFilter } from './components/SearchFilter';
import { ResourceList } from './components/ResourceList';
import { useEffect } from 'react';
import { useStore } from '../../../../store/useStore';

export const ResourceOverview = () => {
    useEffect(() => {
        return () => {
            useStore.getState().setSearchText('');
            useStore.getState().setCurrentUsers([]);
        };
    }, []);

    return (
        <>
            <PageHeader title="Resource Overview" icon="TemporaryUser" />

            <PageContent>
                <ContentSection>
                    <Stack horizontalAlign="center" tokens={{ childrenGap: 32 }} styles={{ root: { width: '100%', margin: '0 auto', maxWidth: 700 } }}>
                        <Stack>
                            <SearchFilter />
                        </Stack>
                        <Stack>
                            <ResourceList />
                        </Stack>
                    </Stack>
                </ContentSection>
            </PageContent>
        </>
    );
};
