import React from 'react';
import { useMemo } from 'react';
import { Text, Stack, IconButton } from '@fluentui/react';
import { ProjectRow } from './ProjectRow';
import { makeStyles, useTheme } from '@fluentui/react';
import { valueStyles } from '../../../../sharedStyles';
import { useMobile } from '../../../../useMobile';
import { mobileConstants } from '../../../../mobileConstants';
import { IMyTimeViewDay } from '../../../../mobileTypes';
import { useMobileContext } from '../../../../MobileContext';
import { useUiContext } from '../../../../../components/Contexts/UiContext';
import { formatNumberToDisplay, formatNumberToDisplayPercent } from '../../../../mobileUtils';

interface IProps extends IMyTimeViewDay {}

export const DayRow: React.FC<IProps> = props => {
    const uiCtx = useUiContext();
    const mobCtx = useMobileContext();
    const theme = useTheme();
    const classes = getStyles();

    const totalValueFromState = useMobile(state => state.totalValues?.[props.dateString]?.value);

    const totalValue = useMemo(() => {
        if (!totalValueFromState || Number.isNaN(Number(totalValueFromState))) {
            return '';
        }

        if (mobCtx.showAsPercent) {
            return formatNumberToDisplayPercent(totalValueFromState, mobCtx.selectedPeriodDays, uiCtx);
            // const procent = convertHoursToProcent(Number(totalValue), mobCtx.selectedPeriodDays.length, getCapacityPrDay(mobCtx.selectedPeriodDays, uiCtx));
            // return `${MathToFixed(parseNumber(procent.toString().replace(/\,/g, '.')), 1)}`;
        } else {
            return formatNumberToDisplay(totalValueFromState, uiCtx);
            // return MathToFixed(parseNumber(totalValue.toString().replace(/\,/g, '.')), 2)
        }
    }, [totalValueFromState, mobCtx.selectedPeriodDays, uiCtx.gridInputType]);

    const isToday = useMemo(() => {
        const today = new Date();
        return props.dayDate.getDate() === today.getDate() && props.dayDate.getMonth() === today.getMonth() && props.dayDate.getFullYear() === today.getFullYear();
    }, []);

    return (
        <div className={classes.root}>
            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: theme.spacing.s2 }} className={classes.dayRow}>
                <Stack
                    grow
                    horizontal
                    verticalAlign="center"
                    tokens={{ childrenGap: theme.spacing.s2 }}
                    onClick={() => useMobile.getState().setExpanded({ dateString: props.dateString, expandDay: !props.isExpanded })}
                >
                    <IconButton iconProps={{ iconName: 'ChevronRight' }} styles={{ root: classes.icon }} className={props.isExpanded ? classes.iconOpen : ''} />
                    <Stack grow horizontal verticalAlign="center" tokens={{ childrenGap: theme.spacing.s1 }}>
                        <Text className={`${classes.dayName} ${isToday ? classes.isTodayDayName : ''}`}>{props.dayName}</Text>

                        <Text className={`${classes.date} ${isToday ? classes.isTodayDate : ''}`}>{props.dateString}</Text>
                    </Stack>
                </Stack>

                <Text className={classes.value}>{totalValue || ''}</Text>
            </Stack>
            {props.isExpanded && props.projects.map(project => <ProjectRow key={`${project.projectId}_${project.dateString}`} {...project} />)}
        </div>
    );
};

const getStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
    },
    dayRow: [
        'dayRow',
        {
            height: mobileConstants.gridRowHeight,
            position: 'sticky',
            top: mobileConstants.gridRowHeight,
            backgroundColor: theme.semanticColors.bodyFrameBackground,
            zIndex: 40,
            paddingLeft: 4,
            paddingRight: 16,
        },
    ],
    dayName: {
        fontSize: 16,
        fontWeight: 700,
        color: theme.semanticColors.bodySubtext,
    },
    date: {
        fontSize: 14,
        fontWeight: 300,
        color: theme.semanticColors.bodySubtext,
        opacity: 0.9,
        textTransform: 'lowercase',
    },
    isTodayDayName: {
        color: theme.palette.themePrimary,
        '.isDarkmode &': {
            color: `${theme.palette.themeSecondary} !important`,
        },
    },
    isTodayDate: {
        color: theme.palette.themePrimary,
        '.isDarkmode &': {
            color: `${theme.palette.themeSecondary} !important`,
            fontWeight: 500,
        },
    },
    value: {
        ...valueStyles,
        fontWeight: 700,
        color: theme.semanticColors.bodySubtext,
    },
    icon: {
        width: 18,
        i: {
            fontSize: 12,
            color: theme.semanticColors.disabledBodySubtext,
            transform: 'rotate(0deg)',
            transition: '100ms',
        },
    },
    iconOpen: {
        i: {
            transform: 'rotate(90deg)',
        },
    },
}));
