import React, { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { Icon, IIconStyles } from '@fluentui/react/lib/Icon';
import { Separator } from '@fluentui/react/lib/Separator';
import { useUiContext } from '../../../../../components/Contexts/UiContext';
import AdminHelpVideo from './AdminHelpVideo';
import AdminVideoM from '../../../../../Model/AdminVideoM';
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { createNewGuid } from '../../../../../components/Utils';

interface propsAdminVideos {
    videos: AdminVideoM[];
}

function AdminVideos(props: propsAdminVideos) {
    const uiCtx = useUiContext();

    const [videos, setVideos] = useState<AdminVideoM[]>([]);

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setVideos(
            props.videos.sort((a, b) => {
                return a.order < b.order ? -1 : 1;
            }),
        );
        setLoading(false);
    }, [props.videos]);

    const ReorderVideo = (s: AdminVideoM, direction: number) => {
        //console.log("projects!", projects);
        let neworder: number = -1;

        if (direction > 0) {
            neworder = s.order < videos.length ? s.order + 1 : videos.length;
        } else {
            neworder = s.order > 1 ? s.order - 1 : 1;
        }

        let ordernumber: number = 1;
        for (let i = 0; i < videos.length; i++) {
            if (neworder === ordernumber) {
                ordernumber = ordernumber + 1;
            }
            if (videos[i].id === s.id) {
                videos[i].order = neworder;
            } else {
                videos[i].order = ordernumber;
                ordernumber = ordernumber + 1;
            }
        }

        setVideos(
            [...videos].sort((a, b) => {
                return a.order < b.order ? -1 : 1;
            }),
        );
    };
    const RemoveVideo = (s: AdminVideoM) => {
        setVideos(videos.filter(vid => vid.id !== s.id));
    };
    const VideoUpdated = (id: string, header: string, description: string, source: string, url: string) => {
        for (let i = 0; i < videos.length; i++) {
            if (videos[i].id === id) {
                videos[i].header = header;
                videos[i].description = description;
                videos[i].source = source;
                videos[i].url = url;
            }
        }
        setVideos(
            [...videos].sort((a, b) => {
                return a.order < b.order ? -1 : 1;
            }),
        );
    };
    const saveChanges = () => {
        setLoading(true);
        uiCtx.timeApi.setVideos(videos).then(videos => {
            console.log('saved');
            setLoading(false);
        });
    };
    const AddVideoClick = () => {
        const newVideo: AdminVideoM = { id: createNewGuid(), header: 'New Video', description: '', source: '', url: '', order: videos.length + 1 };
        videos.push(newVideo);
        setVideos([...videos]);
    };
    return (
        <div style={outerDivStyle}>
            <h3>Videos</h3>
            <Separator>
                <Icon iconName="ProjectCollection" styles={separatorIconStyles} />
            </Separator>
            <br />
            {loading ? (
                <Stack className="logindialog" horizontalAlign="center" verticalAlign="center">
                    <Spinner label="Loading..." />
                </Stack>
            ) : (
                <>{renderAdminVideosConfig()}</>
            )}
        </div>
    );

    function renderAdminVideosConfig(): JSX.Element {
        return (
            <div style={{ display: 'inline-block' }}>
                <Stack tokens={{ childrenGap: 10 }}>
                    {videos.map((video, index) => {
                        return <AdminHelpVideo key={index} ReorderVideo={ReorderVideo} RemoveVideo={RemoveVideo} VideoUpdated={VideoUpdated} Video={video} />;
                    })}
                    <Separator>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Stack.Item align="center">
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    <DefaultButton text="Add Video" onClick={AddVideoClick}></DefaultButton>
                                    <PrimaryButton onClick={saveChanges} text="Save Videos" />
                                </Stack>
                            </Stack.Item>
                        </Stack>
                    </Separator>
                </Stack>
                <br />
                <br />
                <br />
            </div>
        );
    }
}

export default AdminVideos;

const outerDivStyle = {
    margin: '20px 0px 0px 0px',
    display: 'block',
    justifyContent: 'center',
    textAlign: 'center',
    // backgroundColor: 'white',
} as React.CSSProperties;

const separatorIconStyles: IIconStyles = {
    root: {
        fontSize: '24px',
        height: '24px',
        width: '24px',
    },
};
