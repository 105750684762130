import React, { useRef } from 'react';
import { Text } from '@fluentui/react';
import { useQuery } from '@tanstack/react-query';
import { useUiContext } from '../../../../components/Contexts/UiContext';
import { SpinLoaderSlow } from '../../../../components/SpinLoader';
import { GET_CALENDARS, GET_USERS_WITH_CALENDARS } from '../../../../constants/queryKeys';
import { Calendar } from '../../../../Model/NewModels/Calendar';
import User from '../../../../Model/User';
import UsersAdministration from './UsersAdministration';

export const UsersConfig = () => {
    const uiCtx = useUiContext();

    const defaultData = useRef([]).current;

    const { data: allUsers = defaultData, isLoading: isLoadingUsers } = useQuery([GET_USERS_WITH_CALENDARS], (): Promise<User[]> => uiCtx.timeApi.getUsersWithCalendars(), {
        onSuccess: () => uiCtx.setLoading(false),
        placeholderData: defaultData,
    });

    const { data: allCalendars = defaultData, isLoading: isLoadingCalendars } = useQuery([GET_CALENDARS], (): Promise<Calendar[]> => uiCtx.timeApi.getCalendars(), {
        onSuccess: () => uiCtx.setLoading(false),
        placeholderData: defaultData,
    });

    if (isLoadingUsers || isLoadingCalendars) {
        return <SpinLoaderSlow />;
    }

    if (allUsers.length === 0) {
        return <Text>No users found</Text>;
    }

    return <UsersAdministration allUsers={allUsers} allCalendars={allCalendars} calendarsEnabled={allCalendars.length > 0} />;
};
