import React, { useEffect, useState } from 'react';
import { Checkbox, Stack } from '@fluentui/react';
import { useUiContext } from '../../components/Contexts/UiContext';
import { configComponent } from '../validationRules';
import { PageHeader, PageContent, ContentSection } from '../../components/LayoutElements';
import { AccessDenied } from '../../_pages/AccessDenied';

const ValidationConfig = () => {
    const uiCtx = useUiContext();
    useEffect(() => {
        uiCtx.setLoading(false);
    }, []);
    return (
        <>
            <PageHeader title="Validation configuration" icon="Admin" />

            <PageContent>
                <ContentSection>
                    {uiCtx.user.originalUser.isAdmin ? (
                        <Stack tokens={{ childrenGap: 16 }}>
                            <RulesCheckboxes />
                            <RulesConfiguration />
                        </Stack>
                    ) : (
                        <AccessDenied />
                    )}
                </ContentSection>
            </PageContent>
        </>
    );
};

const RulesCheckboxes = () => {
    const uiCtx = useUiContext();

    const toggleRule = (value, name) => {
        uiCtx.timeApi.setConfig(name, value);
        uiCtx.setEnabledRules(r =>
            r.map(rule => {
                if (rule.name !== name) {
                    return rule;
                }
                rule.enabled = value;
                return rule;
            }),
        );
    };

    return (
        <>
            {' '}
            {uiCtx.enabledRules.map(r => (
                <Checkbox key={r.name} styles={{ root: { marginTop: 25 } }} label={ruleNames(r.name)} onChange={(e, value) => toggleRule(value, r.name)} defaultChecked={r.enabled}></Checkbox>
            ))}{' '}
        </>
    );
};

const ruleNames = (name: string): string => {
    switch (name) {
        case 'NoCellErrors':
            return 'Timesheet must not contain cells with errors';
        case 'NoMoreThenXHours':
            return 'Limit the max number of hours a user is able to timesheet in a period';
        case 'NoMoreThanXHoursPerDay':
            return 'Limit the max number of hours a user is able to timesheet per day';
        case 'OnlyAllowWithinEmployment':
            return 'Only allow user to time register within earliest and latest available dates';
        case 'TimeEntriesMustHaveWorkType':
            return 'Ensure all time entries have a worktype (only relevent if worktypes are enabled)';
        case 'Flow':
            return 'Configure additional validation rules in Power Automate';
        case 'EmptyTimeSheetNotAllowed':
            return 'Timesheet must contain entries before being submitted';
        default:
            return name;
    }
};
const RulesConfiguration = () => {
    const [config, setConfig] = useState(null);

    const uiCtx = useUiContext();
    const enabledRules = uiCtx.enabledRules;

    const updateConfig = (name, value) => {
        uiCtx.timeApi.setConfig(name, value).then(res => console.log(res));

        setConfig(c =>
            c.map(cvalue => {
                if (cvalue.key != name) {
                    return cvalue;
                }
                cvalue.value = value;
                return cvalue;
            }),
        );
    };

    useEffect(() => {
        uiCtx.timeApi.getAllConfigs().then(c => setConfig(c));
    }, []);

    if (!config) {
        return <div>Loading config</div>;
    }

    return (
        <div style={{ marginTop: 25 }}>
            {enabledRules
                .filter(_ => _.enabled)
                .map(r => {
                    let Component = configComponent[r.name];
                    if (!Component) {
                        return <div key={r.name}></div>;
                    }
                    return <Component key={r.name} setConfig={updateConfig} allConfig={config} />;
                })}
        </div>
    );
};

export default ValidationConfig;
