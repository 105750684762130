import { ComboBox, Dropdown, IDropdownOption, Separator, Stack, TextField } from '@fluentui/react';
import React, { useState, useEffect, useMemo } from 'react';
import { useUiContext } from '../../../../components/Contexts/UiContext';
import utils, { configObj, IColumnProps, IConfigDictionaryProjectTeam, IExpandedComboBoxOption, ISectionProps, IValueData } from '../../utils/utils';

const taskAssignDropdownOptions: IDropdownOption[] = [
    { key: 'AllTasks', text: 'All Tasks' },
    { key: 'ProjectSummaryTask', text: 'Project Summary Task' },
];

export const ProjectTeam = (props: ISectionProps) => {
    const config: IConfigDictionaryProjectTeam = useMemo(() => props.values[props.configKey], [props.values, props.configKey]);
    const configMappingObj = useMemo(() => configObj[props.configKey], [props.configKey]);
    const [projectTeamStepAdded, setProjectTeamStepAdded] = useState<boolean>(!(config.tableName === null || !config.tableName));

    const [fields, setFields] = useState<IExpandedComboBoxOption[]>();
    const uiCtx = useUiContext();

    useEffect(() => {
        if (!config.tableName) {
            return;
        }
        utils.getFields(config.tableName.key as string, uiCtx.timeApi).then(setFields);
    }, [config.tableName]);

    return (
        <Stack>
            <h1>Project Team</h1>
            <>
                <ComboBox
                    label="Dataverse Project Team Table"
                    allowFreeform
                    autoComplete="on"
                    options={props.tables}
                    onChange={(e, opt, index, value) => {
                        if (opt) {
                            props.updateSteps(opt, props.configKey);
                            setProjectTeamStepAdded(true);
                            props.updateValue('enabled', true, props.configKey);
                        } else if (value === '') {
                            props.updateSteps(null, props.configKey);
                            setProjectTeamStepAdded(false);
                            props.updateValue('enabled', false, props.configKey);
                        }
                    }}
                    selectedKey={config.tableName ? config.tableName.key : ''}
                />
                {config.tableName && !fields && <div>Loading</div>}
                <>
                    <h5 style={{ marginTop: 5 }}>Columns:</h5>
                    <ProjectTeamInputs
                        configMappingObj={configMappingObj}
                        updateValue={props.updateValue}
                        config={config}
                        fields={fields ?? []}
                        configKey={props.configKey}
                        tables={props.tables}
                        stepAdded={projectTeamStepAdded}
                    />
                </>
            </>
        </Stack>
    );
};

interface IAssignmentColumnProps extends IColumnProps {
    config: IConfigDictionaryProjectTeam;
}

const ProjectTeamInputs = (props: IAssignmentColumnProps) => {
    //Alternate Fields
    const [alternateFields, setAlternateFields] = useState<IExpandedComboBoxOption[]>([]);
    const fields = useMemo(() => props.fields.filter(_ => !_.isLookUp), [props.fields]);
    const lookUpFields = useMemo(() => props.fields.filter(_ => _.isLookUp), [props.fields]);

    const uiCtx = useUiContext();

    useEffect(() => {
        if (!props.config.resourceAzureIdTable) {
            return;
        }
        utils.getFields(props.config.resourceAzureIdTable.key as string, uiCtx.timeApi).then(values => {
            // const fields = values.filter(_ => !_.isLookUp);
            setAlternateFields(values);
        });
    }, [props.config.resourceAzureIdTable]);

    const [taskAssignDropdownOptionSelected, setTaskAssignDropdownOptionSelected] = useState<string>(
        props.config.allTasksOrProjectSummaryTask ? props.config.allTasksOrProjectSummaryTask : 'AllTasks',
    );

    const onTaskAssignDropdownChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any>, index?: number) => {
        setTaskAssignDropdownOptionSelected(option.key as string);
        props.updateValue('allTasksOrProjectSummaryTask', option.key as string, props.configKey);
    };

    const getErrorMessage = (fieldValue: IValueData) => {
        if (!props.stepAdded) {
            return '';
        }
        if (!fieldValue || props.fields.filter(_ => _.key === fieldValue.key).length === 0) {
            return 'Field is required';
        }
        return '';
    };

    const getAlternativeErrorMessage = (fieldValue: IValueData) => {
        if (!props.stepAdded) {
            return '';
        }
        if (!fieldValue || alternateFields.filter(_ => _.key === fieldValue.key).length === 0) {
            return 'Field is required';
        }
        return '';
    };

    const getTableErrorMessage = (fieldValue: IValueData) => {
        if (!props.stepAdded) {
            return '';
        }
        if (!fieldValue || props.tables.filter(_ => _.key === fieldValue.key).length === 0) {
            return 'Field is required';
        }
        return '';
    };

    return (
        <>
            <ComboBox
                label="Project Team Id *"
                allowFreeform
                autoComplete="on"
                options={fields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('idColumn', opt, props.configKey);
                    } else if (!value) {
                        props.updateValue('idColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.config.idColumn ? props.config.idColumn.key : ''}
                errorMessage={getErrorMessage(props.config.idColumn)}
            />
            <ComboBox
                label="Project Id *"
                allowFreeform
                autoComplete="on"
                options={lookUpFields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('projectIdColumn', opt, props.configKey);
                    } else if (value === '') {
                        props.updateValue('projectIdColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.config.projectIdColumn ? props.config.projectIdColumn.key : ''}
                errorMessage={getErrorMessage(props.config.projectIdColumn)}
            />
            <ComboBox
                label="Project Team Resource Id *"
                allowFreeform
                autoComplete="on"
                options={props.fields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('resourceIdColumn', opt, props.configKey);
                        props.updateValue('resourceAzureIdAlternateColumnEnabled', true, props.configKey);
                    } else if (value === '') {
                        props.updateValue('resourceIdColumn', null, props.configKey);
                        props.updateValue('resourceAzureIdAlternateColumnEnabled', false, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.stepAdded && props.config.resourceIdColumn ? props.config.resourceIdColumn.key : ''}
                errorMessage={getErrorMessage(props.config.resourceIdColumn)}
            />
            <Dropdown
                label={'Assign All Project Tasks Or Project Summary Task'}
                options={taskAssignDropdownOptions}
                selectedKey={taskAssignDropdownOptionSelected}
                onChange={onTaskAssignDropdownChange}
                disabled={!props.stepAdded}
            />
            <ComboBox
                label="Project Team Closed column"
                allowFreeform
                autoComplete="on"
                options={fields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('closedColumn', opt, props.configKey);
                    } else if (value === '') {
                        props.updateValue('closedColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.stepAdded && props.config.closedColumn ? props.config.closedColumn.key : ''}
            />
            <TextField
                label={'Project Team Closed Compare Value'}
                onChange={(e, value) => props.updateValue('closedValue', value, props.configKey)}
                value={props.stepAdded && props.config.closedValue}
                disabled={!props.stepAdded}
            />
            <Separator />
            <ComboBox
                label="Resource Azure Id Alternate Table *"
                allowFreeform
                autoComplete="on"
                options={props.tables}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('resourceAzureIdTable', opt, props.configKey);
                    } else if (value === '') {
                        props.updateValue('resourceAzureIdTable', null, props.configKey);
                    }
                    if (props.config.resourceAzureIdTable.key !== opt.key || value === '') {
                        props.updateValue('resourceAzureIdColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded || !props.config.resourceAzureIdAlternateColumnEnabled}
                selectedKey={props.stepAdded && props.config.resourceAzureIdTable ? props.config.resourceAzureIdTable.key : ''}
                errorMessage={getTableErrorMessage(props.config.resourceAzureIdTable)}
            />
            <ComboBox
                label="Resource Azure Id Alternate Column *"
                allowFreeform
                autoComplete="on"
                options={alternateFields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('resourceAzureIdColumn', opt, props.configKey);
                    } else if (value === '') {
                        props.updateValue('resourceAzureIdColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded || !props.config.resourceAzureIdAlternateColumnEnabled}
                selectedKey={props.stepAdded && props.config.resourceAzureIdColumn ? props.config.resourceAzureIdColumn.key : ''}
                errorMessage={getAlternativeErrorMessage(props.config.resourceAzureIdColumn)}
            />
        </>
    );
};
