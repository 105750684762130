import React, { useEffect } from 'react';
import { useUiContext } from '../components/Contexts/UiContext';
import AdminSync from '../Admin/AdminSync/AdminSync';

function AppAdminSyncConfig() {
    const uiCtx = useUiContext();

    useEffect(() => {
        uiCtx.setLoading(false);
    }, []);

    return <AdminSync />;
}

export default AppAdminSyncConfig;
