import React from 'react';
import { useState } from 'react';
// import { makeStyles } from "@fluentui/react-theme-provider";
import { IconButton, Stack, DefaultButton, Callout, Calendar, DayOfWeek, DateRangeType, DirectionalHint, AnimationStyles, makeStyles } from '@fluentui/react';
import { useMobileContext } from '../MobileContext';
import { DayPickerStrings } from '../../components/PeriodPicker';
import { useUiContext } from '../../components/Contexts/UiContext';
import { useMobile } from '../useMobile';

export const PeriodPicker: React.FC = () => {
    const uiCtx = useUiContext();
    const mobCtx = useMobileContext();
    const classes = getStyles();
    const selectedDate = useMobile(state => state.selectedDate);
    const [showCalendar, setShowCalendar] = useState(false);

    const nextPeriodClick = (): void => {
        const newPeriodStartDate = new Date(mobCtx.selectedTimePeriod.finish);
        newPeriodStartDate.setDate(newPeriodStartDate.getDate() + 1);
        useMobile.getState().setSelectedDate({ newSelectedDate: newPeriodStartDate });
        // const selectedPeriod = mobCtx.allTimePeriods.find(timePeriod => newPeriodStartDate.getTime() == new Date(timePeriod.startDate).getTime());
        // if (selectedPeriod) {
        //     mobCtx.setSelectedTimePeriodByDate(new Date(selectedPeriod.startDate));
        // };
    };

    const prevPeriodClick = (): void => {
        const newPeriodStartDate = new Date(mobCtx.selectedTimePeriod.start);
        newPeriodStartDate.setDate(newPeriodStartDate.getDate() - 1);
        useMobile.getState().setSelectedDate({ newSelectedDate: newPeriodStartDate });
        // const selectedPeriod = mobCtx.allTimePeriods.find(timePeriod => new Date(newPeriodStartDate).getTime() == new Date(timePeriod.endDate).getTime());
        // if (selectedPeriod) {
        //     mobCtx.setSelectedTimePeriodByDate(new Date(selectedPeriod.startDate));
        // };
    };

    const onSelectDate = (date: Date, dateRangeArray: Date[]): void => {
        useMobile.getState().setSelectedDate({ newSelectedDate: date });
        // useMobile.getState().setIsloading(true);
        // const selectedPeriod = mobCtx.allTimePeriods.find(timePeriod => date.getTime() >= new Date(timePeriod.startDate).getTime() && date.getTime() <= new Date(timePeriod.endDate).getTime())
        // console.log("selectedPeriod: ", selectedPeriod);
        // if (selectedPeriod) {
        //     useMobile.getState().setSelectedDate({ newSelectedDate: date, mobCtx });
        // };

        setShowCalendar(false);
    };

    return (
        <>
            <Stack grow horizontal horizontalAlign="center" className={classes.root}>
                <IconButton
                    className={uiCtx.isTeams ? classes.iconBackFowardTeams : classes.iconBackFoward}
                    iconProps={{ iconName: 'ChevronLeft' }}
                    onClick={prevPeriodClick}
                    disabled={mobCtx.selectedTimePeriod === null}
                />
                <DefaultButton
                    id="calendarButtonMobile"
                    text={!mobCtx.selectedTimePeriod ? 'Select date' : mobCtx.selectedTimePeriod.name}
                    onClick={() => setShowCalendar(s => !s)}
                    styles={{ root: classes.button, label: uiCtx.isTeams ? classes.buttonLabelTeams : classes.buttonLabel }}
                />
                <IconButton
                    className={uiCtx.isTeams ? classes.iconBackFowardTeams : classes.iconBackFoward}
                    iconProps={{ iconName: 'ChevronRight' }}
                    onClick={nextPeriodClick}
                    disabled={mobCtx.selectedTimePeriod === null}
                />
            </Stack>
            {showCalendar && (
                <Callout
                    gapSpace={0}
                    target="#calendarButtonMobile"
                    onDismiss={() => setShowCalendar(false)}
                    setInitialFocus
                    directionalHint={DirectionalHint.bottomCenter}
                    layerProps={{
                        styles: {
                            content: {
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0,0,0,0.3)',
                                ...AnimationStyles.fadeIn100,
                            },
                        },
                    }}
                >
                    <Calendar
                        onSelectDate={onSelectDate}
                        // componentRef={calref}
                        value={selectedDate}
                        strings={DayPickerStrings}
                        isDayPickerVisible={true}
                        highlightSelectedMonth={true}
                        showMonthPickerAsOverlay={false}
                        showGoToToday={false}
                        dateRangeType={DateRangeType.Day}
                        firstDayOfWeek={DayOfWeek.Monday}
                        // minDate={mindate}
                        // maxDate={maxdate}
                        // onMouseMove={(e:any) =>{highlightSelectedPeriod(e);}}
                        // onMouseOver={(e: any) => {highlightSelectedPeriod(e);}}
                    />
                </Callout>
            )}
        </>
    );
};
const getStyles = makeStyles(theme => ({
    root: {},
    button: {
        paddingRight: 4,
        paddingLeft: 4,
        borderColor: 'rgba(0,0,0,0)',
        backgroundColor: 'rgba(0,0,0,0)',
    },
    buttonLabel: {
        fontSize: 16,
        fontWeight: 600,
        color: theme.palette.neutralPrimary,
    },
    buttonLabelTeams: {
        fontSize: 16,
        fontWeight: 600,
        color: 'rgba(255, 255, 255, 0.9)',
    },
    iconBackFoward: {
        i: {
            fontSize: 16,
            color: theme.palette.neutralDark,
        },
        '&.is-disabled': {
            opacity: 0.1,
        },
    },
    iconBackFowardTeams: {
        i: {
            fontSize: 16,
            color: 'rgba(255, 255, 255, 0.75)',
        },
        '&.is-disabled': {
            opacity: 0.1,
        },
    },
}));
