import * as React from 'react';
import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import { Text } from '@fluentui/react';
import { useUiContext } from './Contexts/UiContext';
import { useEffect } from 'react';
import { useState } from 'react';
import TimeRow from '../Model/TimePeriodRowM';
import WorkType from '../Model/NewModels/WorkType';
import { getEmptyGuid } from './Utils';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { Stack } from '@fluentui/react/lib/Stack';


const dropdownStyles: Partial<IDropdownStyles> = {
    root: { 
        height: '100%', 
        width: '100%',
        "&:hover": {
            backgroundColor: "var(--semanticColors-inputBackground)",
        },
    },
    dropdown: { height: '100%', width: '100%' },
    title: { 
        maxWidth: '100%', 
        borderWidth: 0, 
        borderStyle: 'none',
        backgroundColor: "none",

    },
};

export const titleStyles: any = { 
    fontSize: 13, 
    textOverflow: "ellipsis", 
    whiteSpace: "nowrap", 
    overflow: "hidden", 
    overflowWrap: "break-word", 
    textAlign: "center",
};

const onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
    const option = options[0];
    return (
        <div className="tag_dropdown_item">
            <Text styles={{ root: titleStyles }}>
                { option.text }
            </Text>
        </div>
    );
};


interface workTypePickerProps {
    row: TimeRow;
    existingTaskTimeRows: TimeRow[];
    readOnly: boolean;
    onChange: (row: TimeRow, option: WorkType, changeCompleted: Function) => void
}

export const WorkTypePicker = (props: workTypePickerProps) => {
    const uiContext = useUiContext();
    const [dropdownOptions, setDropdownOptions] = useState<IDropdownOption[]>();
    const [hidden, setHidden] = useState<boolean>(false);
    const [changing, setChanging] = useState<boolean>(false);

    useEffect(() => {

        const userWorkTypes = [...uiContext.allowedWorkTypes];   
        const projectWorkTypes =  uiContext.projectWorkTypes.find(_ => _.id === props.row.projectWorkType?.id)?.allowedWorkTypes || [];

        //Hide if projectworktype is only empty guid
        if (projectWorkTypes.length === 1 && projectWorkTypes[0].id === getEmptyGuid()) {
            setDropdownOptions([]);
            setHidden(true);
            return;
        }
        
        const userWorkTypesInProject = userWorkTypes.filter(uw => projectWorkTypes.some(pw => pw.id === uw.id));
        const projectWorkTypesInUser = projectWorkTypes.filter(uw => userWorkTypes.some(pw => pw.id === uw.id));                  
        
        //Take worktypes that are in both lists
        let avaiableWorkWtype = [...projectWorkTypesInUser, ...userWorkTypesInProject].filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
        //If no project work types, take all avaiable from user
        if (projectWorkTypes.length === 0) {
            avaiableWorkWtype = userWorkTypes;
        }
        
        const rowWorkType = props.row.workType;

        let existingTaskTimeRows = props.existingTaskTimeRows.filter(_ => _.taskId === props.row.taskId);          
        let allowedWorkTypes = avaiableWorkWtype?.filter(workType => !existingTaskTimeRows.filter(task => task.key !== props.row.key).some(t => t.workType?.id === workType.id));     
        let allowedWorkTypesDisabledCurrent = allowedWorkTypes?.map(aw => ({...aw, disabled: aw.id === rowWorkType?.id}));
        
        //If assignment is closed, make worktype readonly
        if (props.row.taskClosed === true) {    
            let options = [{id: rowWorkType.id, name: `(${rowWorkType.name})`, disabled: true}]; 
            setDropdownOptions(options?.map(workType => ({key: workType.id, text: workType.name, disabled: workType.disabled})) || []);
            return;
        }

        let nullWorkType = rowWorkType == null;    
        let workTypeAllowed = avaiableWorkWtype?.find(wt => wt.id === rowWorkType?.id); 

        //This should never happen after empty guid worktype?
        if (nullWorkType) {           
            //If the row has null in worktype, we create a option with emptyguid
            allowedWorkTypesDisabledCurrent.push({id: getEmptyGuid(), name: "No Worktype selected", disabled: true}) 
        }            
        else if (!workTypeAllowed) {
                //If work type is not in userworktype, we will show the value, but disabed
            allowedWorkTypesDisabledCurrent.push({id: rowWorkType.id, name: `(${rowWorkType.name})`, disabled: true}) 
        }
        
        setDropdownOptions(allowedWorkTypesDisabledCurrent?.map(workType => ({key: workType.id, text: workType.name, disabled: workType.disabled})) || []);
            
    }, [props.existingTaskTimeRows]);

    const changeCompleted = () =>{
        setChanging(false);
    }

    if (!dropdownOptions || !dropdownOptions?.length) return <></>

    return (
        <>
            {changing ? (
                <Stack horizontalAlign="center" style={{width:'100%'}}>
                    <Spinner />
                </Stack>
            ) : (
                <Dropdown
                    onRenderTitle={onRenderTitle}
                    hidden={hidden}
                    selectedKey={props.row.workType?.id || getEmptyGuid()}
                    placeholder="Select worktype"       
                    options={dropdownOptions}
                    styles={dropdownStyles}
                    disabled={(changing || props.readOnly || (dropdownOptions && dropdownOptions.length <= 1))}            
                    onChange={(e,option: any,i) => {setChanging(true); props.onChange(props.row, {id: option.key, name: option.text, workUserType: null}, changeCompleted)}}
                />
            )}
        </>
    );
};