import { FlowValidation } from './Rules/FlowValidation/FlowValidation';
import { FlowValidationConfig, FlowValidationConfigValues } from './Rules/FlowValidation/FlowValidationConfg';
import { NoMoreThenXHours } from './Rules/NoMoreThenXHours/NoMoreThenXHours';
import { NoMoreThanXHoursPerDay } from './Rules/NoMoreThanXHoursPerDay';
import { NoMoreThenXHoursConfig, NoMoreThenXHoursConfigValues } from './Rules/NoMoreThenXHours/NoMoreThenXHoursConfig';
import { OnlyAllowWithinEmployment } from './Rules/OnlyAllowWithinEmployment';
import { TimeEntriesMustHaveWorkType } from './Rules/TimeEntriesMustHaveWorkType';
import { NoCellErrors } from './Rules/NoCellErrors';
import { MinPercentPerDay } from './Rules/MinPercentPerDay/MinPercentPerDay';
import { MinPercentPerDayConfig, MinPercentPerDayConfigValues } from './Rules/MinPercentPerDay/MinPercentPerDayConfig';
import { MaxPercentOfCapacityConfig, MaxPercentOfCapacityConfigValues } from './Rules/MaxPercentOfCapacity/MaxPercentOfCapacityConfig';
import { MaxPercentOfCapacity } from './Rules/MaxPercentOfCapacity/MaxPercentOfCapacity';
import { EmptyTimeSheetNotAllowed } from './Rules/EmptyTimeSheetNotAllowed';

//Validation Rules

export enum validationRules {
    NoCellErrors = 'NoCellErrors',
    NoMoreThenXHours = 'NoMoreThenXHours',
    NoMoreThanXHoursPerDay = 'NoMoreThanXHoursPerDay',
    OnlyAllowWithinEmployment = 'OnlyAllowWithinEmployment',
    TimeEntriesMustHaveWorkType = 'TimeEntriesMustHaveWorkType',
    Flow = 'Flow',
    MinPercentPerDay = 'MinPercentPerDay',
    MaxPercentOfCapacity = 'MaxPercentOfCapacity',
    EmptyTimeSheetNotAllowed = 'EmptyTimeSheetNotAllowed',
}

//Validation function parameters (needed to fetch config from db)
export const validationRulesParameters = {
    [validationRules.NoCellErrors]: [],
    [validationRules.NoMoreThenXHours]: [NoMoreThenXHoursConfigValues.MaxHours],
    [validationRules.NoMoreThanXHoursPerDay]: [],
    [validationRules.OnlyAllowWithinEmployment]: [],
    [validationRules.TimeEntriesMustHaveWorkType]: [],
    [validationRules.EmptyTimeSheetNotAllowed]: [],
    [validationRules.Flow]: [FlowValidationConfigValues.FlowUrl],
    [validationRules.MinPercentPerDay]: [MinPercentPerDayConfigValues.MinPercent],
    [validationRules.MaxPercentOfCapacity]: [MaxPercentOfCapacityConfigValues.MaxPercent],
};

//Validation functions
export const validationRulesFunction = {
    [validationRules.TimeEntriesMustHaveWorkType]: TimeEntriesMustHaveWorkType,
    [validationRules.OnlyAllowWithinEmployment]: OnlyAllowWithinEmployment,
    [validationRules.NoCellErrors]: NoCellErrors,
    [validationRules.NoMoreThenXHours]: NoMoreThenXHours,
    [validationRules.NoMoreThanXHoursPerDay]: NoMoreThanXHoursPerDay,
    [validationRules.EmptyTimeSheetNotAllowed]: EmptyTimeSheetNotAllowed,
    [validationRules.Flow]: FlowValidation,
    [validationRules.MinPercentPerDay]: MinPercentPerDay,
    [validationRules.MaxPercentOfCapacity]: MaxPercentOfCapacity,
};

//Configuration conmponents
export const configComponent = {
    [validationRules.NoMoreThenXHours]: NoMoreThenXHoursConfig,
    [validationRules.Flow]: FlowValidationConfig,
    [validationRules.MinPercentPerDay]: MinPercentPerDayConfig,
    [validationRules.MaxPercentOfCapacity]: MaxPercentOfCapacityConfig,
};
