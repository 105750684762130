import Axios from 'axios';

export const createHttpClientTeams = getAccessToken => {
    const HttpClient = Axios.create();

    HttpClient.interceptors.request.use(
        async config => {
            const token = await getAccessToken();

            config.headers = {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
            } as any;
            return config;
        },
        error => {
            return Promise.reject(error);
        },
    );

    return HttpClient;
};

const createHttpClient = (getMsalAccessToken: () => Promise<string | void>) => {
    const HttpClient = Axios.create();

    HttpClient.interceptors.request.use(
        async config => {
            const response = await getMsalAccessToken();
            config.headers.set('Authorization', `Bearer ${response}`);
            config.headers.set('Accept', 'application/json');
            return config;
        },
        error => {
            return Promise.reject(error);
        },
    );

    return HttpClient;
};

export default createHttpClient;
