export interface RegisterTimeByPeriodDayResponseDto{
    timeEntryIds: string[], // Not Used Right Now
    responseCode: RegisterTimeByPeriodDayResponseCode
}

export enum RegisterTimeByPeriodDayResponseCode{
    Success = 0,
    PeriodIsClosed = 96,
    InsufficientPermissions = 97,
    DbError = 98,
    UnknownError = 99
}