import { IUiContext } from '../../../components/Contexts/UiContext';
import { TimeEntry } from '../../../api/generated/data-contracts';
import User from '../../../Model/User';
import { validationRules } from '../../validationRules';
import { ValidateResponse } from '../../Validator';
import { MinPercentPerDayConfigValues } from './MinPercentPerDayConfig';

interface MinPercentPerDayProps {
    timeEntries: TimeEntry[];
    uiContext: IUiContext;
    [key: string]: any;
}

export const MinPercentPerDay = (props: MinPercentPerDayProps): ValidateResponse => {
    const minPercent = props[MinPercentPerDayConfigValues.MinPercent] || 5;

    const availability = props.uiContext.user.user.availability;

    const minHoursAsPercent = (availability / 100) * minPercent;
    const entries = props.timeEntries.filter(te => te.hours !== 0).filter(te => te.hours < minHoursAsPercent);

    const valid = entries.length === 0;

    const response: ValidateResponse = {
        reason: !valid ? `You have to report more than ${minPercent}% per period` : null,
        valid: valid,
        name: validationRules.MinPercentPerDay,
        rejected: !valid,
    };

    return response;
};
