import { ProjectSource } from './ProjectSource';
import TimeRow from './TimePeriodRowM';

export default class TimePeriodGroupM{
    
    constructor (
        public GroupId:string, 
        public GroupName:string, 
        public ProjectSource:ProjectSource,
        public TimeRows:TimeRow[],
        public Expanded: boolean) 
        {
    }
}