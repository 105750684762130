import { makeStyles } from '@fluentui/react';
import * as React from 'react';
// import { useGetStyles, makeStyles } from '../../hooks/useGetStyles';

interface IProps {
    noSideSpacing?: boolean;
    noScroll?: boolean;
    // maxWidth?: string | number;
    maxWidth?: 'medium' | 'large';
}

export const PageContent: React.FC<IProps> = props => {
    return (
        // <div className={`page_content${props.noSideSpacing ? " inner_side_spacing" : " side_spacing"}${props.maxWidth == "medium" ? " max_width_medium" : ""}${props.maxWidth == "large" ? " max_width_large" : ""}`}>
        <div className={`page_content${props.maxWidth == 'medium' ? ' max_width_medium' : ''}${props.maxWidth == 'large' ? ' max_width_large' : ''}`}>
            <div className="flex_content">
                {/* <div className={`page_content_inner${props.noSideSpacing ? "" : " side_spacing"}`} data-is-scrollable> */}
                <div className={`page_content_inner${props.noSideSpacing ? '' : ''}`} data-is-scrollable>
                    {/* <div style={{ flexGrow: 1 }}> */}
                    {props.children}
                    {/* </div> */}
                </div>
            </div>
        </div>
    );
};
