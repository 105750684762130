import { WorkDay } from "../Workaround/TimeApiWorkaround";

export default class ReportPeriodRowM{
    constructor(
        public periodId:string, 
        public name:string, 
        public state:Number, 
        public reportPeriodId:string,
        public start:Date,
        public finish:Date,
        public isClosed:boolean,
        public workDays: WorkDay[]) {

    }
    
}