import { useMemo } from 'react';
import { UpgradePackage } from '../../../api/generated/data-contracts';
// import { UpgradePackage } from '../../../../../../../../../api/generated/data-contracts';

export function useValidateStrictVersions(controllerPackagesToRequest: UpgradePackage[], strictMajorVersions?: boolean, strictMinorVersions?: boolean, strictPatchVersions?: boolean) {
    return useMemo(() => {
        if (controllerPackagesToRequest.some(controller => !controller.package) || controllerPackagesToRequest.length === 0) {
            // The request is being validated elsewhere so it
            // won't get sent even though we return an all green validation.
            // Wait for the user to be done choosing versions before complaining.
            return { majorValid: true, minorValid: true, patchValid: true };
        }

        const { major, minor, patch } = controllerPackagesToRequest.reduce(
            (validation, controller) => {
                const { major, minor, patch } = validation;
                // Cut off the file extension
                let name = controller.package!.name!.slice(0, controller.package!.name!.lastIndexOf('.'));
                if (name.includes('-')) {
                    // Cut off the build number
                    name = name.slice(0, name.indexOf('-'));
                }
                const [curMajor, curMinor, curPatch] = name.split('.');
                major.add(curMajor);
                minor.add(curMinor);
                patch.add(curPatch);
                return validation;
            },
            // Set() can only hold unique values.
            { major: new Set(), minor: new Set(), patch: new Set() },
        );

        return {
            // If we got any more than 1 it means different versions
            // has been chosen. Return results according to strictness
            majorValid: strictMajorVersions ? major.size === 1 : true,
            minorValid: strictMinorVersions ? minor.size === 1 : true,
            patchValid: strictPatchVersions ? patch.size === 1 : true,
        };
    }, [controllerPackagesToRequest, strictMajorVersions, strictMinorVersions, strictPatchVersions]);
}
