import { useState, useEffect, useMemo } from 'react';
import { useUiContext } from '../../../../components/Contexts/UiContext';
import utils, { IColumnProps, IConfigDictionaryTask, IExpandedComboBoxOption, ISectionProps, IValueData, configObj } from '../../utils/utils';
import { ComboBox, Spinner, Stack, TextField } from '@fluentui/react';

export const Tasks = (props: ISectionProps) => {
    const config: IConfigDictionaryTask = useMemo(() => props.values[props.configKey], [props.values, props.configKey]);
    const configMappingObj = useMemo(() => configObj[props.configKey], [props.configKey]);
    const [taskStepAdded, setTaskStepAdded] = useState<boolean>(!(config.tableName === null || !config.tableName));

    const [fields, setFields] = useState<IExpandedComboBoxOption[]>();
    const uiCtx = useUiContext();

    useEffect(() => {
        if (!config.tableName) {
            return;
        }
        utils.getFields(config.tableName.key as string, uiCtx.timeApi).then(setFields);
    }, [config.tableName]);

    return (
        <Stack>
            <h1>Tasks</h1>
            <>
                <ComboBox
                    label="Dataverse Task Table"
                    allowFreeform
                    autoComplete="on"
                    options={props.tables}
                    onChange={(e, opt, index, value) => {
                        if (opt) {
                            props.updateSteps(opt, props.configKey);
                            setTaskStepAdded(true);
                            props.updateValue('enabled', true, props.configKey);
                        } else if (!value) {
                            props.updateSteps(null, props.configKey);
                            setTaskStepAdded(false);
                            props.updateValue('enabled', false, props.configKey);
                        }
                    }}
                    selectedKey={config.tableName ? config.tableName.key : ''}
                />
                {config.tableName && !fields && <Spinner />}
                <>
                    <h5 style={{ marginTop: 5 }}>Columns:</h5>
                    <ProjectInputs
                        configMappingObj={configMappingObj}
                        updateValue={props.updateValue}
                        config={config}
                        fields={fields ?? []}
                        configKey={props.configKey}
                        stepAdded={taskStepAdded}
                    />
                </>
            </>
        </Stack>
    );
};

interface ITaskColumnProps extends IColumnProps {
    config: IConfigDictionaryTask;
}

const ProjectInputs = (props: ITaskColumnProps) => {
    const fields = useMemo(() => props.fields.filter(_ => !_.isLookUp), [props.fields]);
    const lookUpFields = useMemo(() => props.fields.filter(_ => _.isLookUp), [props.fields]);

    const getErrorMessage = (fieldValue: IValueData) => {
        if (!props.stepAdded) {
            return '';
        }
        if (!fieldValue || props.fields.filter(_ => _.key === fieldValue.key).length === 0) {
            return 'Field is required';
        }
        return '';
    };

    return (
        <>
            <ComboBox
                label="Task Id *"
                allowFreeform
                autoComplete="on"
                options={fields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('idColumn', opt, props.configKey);
                    } else if (value === '') {
                        props.updateValue('idColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.stepAdded && props.config.idColumn ? props.config.idColumn.key : ''}
                errorMessage={getErrorMessage(props.config.idColumn)}
            />
            <ComboBox
                label="Task Name *"
                allowFreeform
                autoComplete="on"
                options={fields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('nameColumn', opt, props.configKey);
                    } else if (value === '') {
                        props.updateValue('nameColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.stepAdded && props.config.nameColumn ? props.config.nameColumn.key : ''}
                errorMessage={getErrorMessage(props.config.nameColumn)}
            />
            <ComboBox
                label="Task Project Id *"
                allowFreeform
                autoComplete="on"
                options={lookUpFields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('projectIdColumn', opt, props.configKey);
                    } else if (value === '') {
                        props.updateValue('projectIdColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.stepAdded && props.config.projectIdColumn ? props.config.projectIdColumn.key : ''}
                errorMessage={getErrorMessage(props.config.projectIdColumn)}
            />
            {/* 08/06/2023 SOS: Removed for now, until further investegation on how is must work */}
            {/* <ComboBox
                label="Task Type Name *"
                allowFreeform
                autoComplete="on"
                options={fields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue("taskTypeNameColumn", opt, props.configKey)
                    }
                    else if (value === '') {
                        props.updateValue("taskTypeNameColumn", null, props.configKey)
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.stepAdded && props.config.taskTypeNameColumn ? props.config.taskTypeNameColumn.key : ''}
                errorMessage={getErrorMessage(props.config.taskTypeNameColumn)}

            /> */}
            <ComboBox
                label="Task Start"
                allowFreeform
                autoComplete="on"
                options={fields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('startColumn', opt, props.configKey);
                    } else if (value === '') {
                        props.updateValue('startColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.stepAdded && props.config.startColumn ? props.config.startColumn.key : ''}
            />
            <ComboBox
                label="Task End"
                allowFreeform
                autoComplete="on"
                options={fields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('endColumn', opt, props.configKey);
                    } else if (value === '') {
                        props.updateValue('endColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.stepAdded && props.config.endColumn ? props.config.endColumn.key : ''}
            />
            <ComboBox
                label="Task Closed"
                allowFreeform
                autoComplete="on"
                options={fields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('closedColumn', opt, props.configKey);
                    } else if (value === '') {
                        props.updateValue('closedColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.stepAdded && props.config.closedColumn ? props.config.closedColumn.key : ''}
            />
            <TextField
                label={'Task Closed Compare Value'}
                onChange={(e, value) => props.updateValue('closedValue', value, props.configKey)}
                value={props.stepAdded ? props.config.closedValue : ''}
                disabled={!props.stepAdded}
            />
        </>
    );
};
