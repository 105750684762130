import React, { useEffect, useState } from 'react';
import TimeRow from '../../Model/TimePeriodRowM';
import TimeRowGroup from '../../Model/TimePeriodGroupM';
import PeriodDay from '../../Model/PeriodDay';
import MyTimeAdd from '../MyTimeAdd';
import { TimeGridMode } from '../../Model/TimeGridMode';
import { DirectionalHint, FontIcon, Stack, Text, TooltipHost } from '@fluentui/react';
// import '../../Styles/TimePeriod.scss';
import { GetTotalFromTimeRowArray, FormatValue, getEmptyGuid, DateAsStartOfDay } from '../Utils';
import WorkType from '../../Model/NewModels/WorkType';
import { useUiContext } from '../Contexts/UiContext';
import MyTimeDelete from '../MyTimeDelete';
import { useCellCtx } from '../Contexts/CellContext';
import ProjectSourceIcon from '../../Icons/ProjectSourceIcon';
import CellWeek from './CellWeek';
import { DisplayCell } from './DisplayCell';
import { WorkTypePicker } from '../WorkTypePicker';
import { TimeEntry } from '../../api/generated/data-contracts';
import { TimeConfigKeys } from '../../constants/TimeConfigKeys';

interface propsTimePeriodGroup {
    group: TimeRowGroup;

    expanded: boolean;
    onCellWeekUpdated: Function;
    mode: TimeGridMode;
    pinMyTime: Function;
    deleteMyTime: Function;
    today: Date;
    submitted: boolean;
    periodIsClosed: boolean;
    timeLowerLimitConfig: string;
    startDate: Date;
    endDate: Date;
    deadlineDate: Date;
    perioddays: PeriodDay[];
    reportPeriodId: string;
    updateWorkType: (row: TimeRow, workType: WorkType, changeCompleted: Function) => void;
}

function TimePeriodGroupWeek(props: propsTimePeriodGroup) {
    const [times, setTimes] = useState<TimeRow[]>([]);
    // const [deletedTimeRows, setDeletedTimeRows] = useState<TimeRow[]>([]);
    const [expanded, setExpanded] = useState<boolean>(true);
    const [expandMiddleman, setExpandMiddleman] = useState<boolean>(true);
    const [groupiconrotation, setGroupiconrotation] = useState<string>('rotate(-90deg)');
    const submittedOrPeriodClosed = props.submitted ? true : props.periodIsClosed ? true : false;
    const [cellDataNeedsSave, setCellDataNeedsSave] = useState<boolean>(false);
    const [onlyTimesheetWithinStartEnd, setOnlyTimesheetWithinStartEnd] = useState<boolean>(true);

    //TODO FIX:
    const [updateTotal, setUpdateTotal] = useState<boolean>(true);

    const uiCtx = useUiContext();
    const cellCtx = useCellCtx();

    const workTypeAllowed = (timeRow: TimeRow) => {
        if (timeRow === undefined) {
            return true;
        }

        if (!uiCtx.workTypeEnabled) {
            return true;
        }

        const TimeEntriesMustHaveWorkType = uiCtx.enabledRules.find(_ => _.name === 'TimeEntriesMustHaveWorkType');

        if (uiCtx.workTypeEnabled && !TimeEntriesMustHaveWorkType?.enabled) {
            return true;
        }

        if (TimeEntriesMustHaveWorkType?.enabled && (!timeRow.workType || timeRow.workType.id === getEmptyGuid())) {
            return false;
        }

        if (timeRow.timeEntries.some(_ => _.assignment.timeTask?.isDefault === true)) {
            return true;
        }
        const userWorkTypes = [...uiCtx.allowedWorkTypes];
        if (userWorkTypes.length === 0) {
            return true;
        }

        const projectWorkTypes = uiCtx.projectWorkTypes.find(_ => _.id === timeRow.projectWorkType?.id)?.allowedWorkTypes || [];

        const userWorkTypesInProject = userWorkTypes.filter(uw => projectWorkTypes.some(pw => pw.id === uw.id));
        const projectWorkTypesInUser = projectWorkTypes.filter(uw => userWorkTypes.some(pw => pw.id === uw.id));
        const avaiableWorkWtype = [...projectWorkTypesInUser, ...userWorkTypesInProject].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);

        const rowWorkType = timeRow.workType;
        const workTypeAllowedvar = avaiableWorkWtype?.find(wt => wt.id === rowWorkType?.id);
        if (!workTypeAllowedvar) {
            return false;
        } else {
            return true;
        }
    };
    useEffect(() => {
        const tsfconfig = uiCtx.onlyallowtimesheetwithintaskstartend;
        setOnlyTimesheetWithinStartEnd(tsfconfig);
    }, []);

    useEffect(() => {
        setTimes([...props.group.TimeRows]);
    }, [props.group, updateTotal]);

    useEffect(() => {
        // setExpanded(props.expanded);
        setExpandMiddleman(props.expanded);
    }, [props.expanded]);

    useEffect(() => {
        if (expanded) {
            setGroupiconrotation('rotate(0deg)');
        } else {
            setGroupiconrotation('rotate(-90deg)');
        }
    }, [expanded]);

    useEffect(() => {
        if (!cellDataNeedsSave) {
            setExpanded(expandMiddleman);
        }
    }, [expandMiddleman, cellDataNeedsSave]);

    const groupGollapsedExpanded = (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>): void => {
        setExpandMiddleman(!expandMiddleman);
    };

    const onCellUpdated = (
        time: TimeRow,
        day: PeriodDay,
        te: TimeEntry,
        cellValue: number,
        allCellsValue: number,
        comment: string,
        flagged: boolean,
        previousValue: number,
        saveSuccess: Function,
    ): void => {
        props.onCellWeekUpdated(time, day, te, cellValue, allCellsValue, comment, flagged, previousValue, saveSuccess);
        setUpdateTotal(t => !t);
    };

    const PinMyTime = (time: TimeRow, pinned: boolean) => {
        props.pinMyTime(time, pinned);
    };

    const DeleteMyTime = (time: TimeRow) => {
        // setTimes(currentArray => [...currentArray.filter(c => c != time)])
        // setDeletedTimeRows(currentArray => [...currentArray, time]);
        props.deleteMyTime(time);
    };

    const setCellDataNeedsSaveFunc = (val: boolean) => {
        setCellDataNeedsSave(val);
    };

    return (
        <>
            <tr className={`groupHeadRow`} onClick={groupGollapsedExpanded}>
                {/* <ReactCSSTransitionGroup
                onClick={groupGollapsedExpanded}
                transitionName="fade"
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}
                transitionAppearTimeout={500}
                transitionAppear={true}
                component="tr"
                className={`groupHeadRow`}
            > */}
                <td className="rowHeader noselect">
                    <Stack horizontal verticalAlign="center" className="rowHeaderContainer">
                        <Stack.Item className="expander">
                            <div className="expanderInner">
                                <FontIcon
                                    iconName="ChevronDown"
                                    style={{
                                        transform: groupiconrotation,
                                        transition: 'transform .2s ease 0s',
                                    }}
                                />
                            </div>
                        </Stack.Item>
                        <Text className="groupName textOverflow">{props.group.GroupName}</Text>
                        {props.group?.ProjectSource?.iconUrl && (
                            <Stack.Item>
                                <ProjectSourceIcon
                                    id={`${props.group.GroupName}_${props.group.ProjectSource.name}`}
                                    projectSource={props.group.ProjectSource}
                                />
                            </Stack.Item>
                        )}
                    </Stack>
                </td>

                <td className="cellContainer worktypeContainer noselect" hidden={!uiCtx.workTypeEnabled}>
                    <Stack horizontal className="cell day">
                        <Text className="groupName textOverflow"> {/* White space for work type (Strange layout if empty...) */} </Text>
                    </Stack>
                </td>

                {uiCtx.plannedActive && uiCtx.showPlanned && uiCtx.gridInputType !== 'percent' && (
                    <td className={`rowTotalContainer noselect`}>
                        <div className="rowTotal totalCell">
                            <Text className="totalCellInner" as="div">
                                <DisplayCell value={times?.reduce((prev, cur) => prev + (cur.plannedWork || 0), 0)} periodDays={props.perioddays} />
                            </Text>
                        </div>
                    </td>
                )}
                {uiCtx.actualActive && uiCtx.showPlanned && uiCtx.gridInputType !== 'percent' && (
                    <td className={`rowTotalContainer noselect`}>
                        <div className="rowTotal totalCell">
                            <Text className="totalCellInner" as="div">
                                <DisplayCell value={times?.reduce((prev, cur) => prev + (cur.actualWork || 0), 0)} periodDays={props.perioddays} />
                            </Text>
                        </div>
                    </td>
                )}
                {uiCtx.remainingActive && uiCtx.showPlanned && uiCtx.gridInputType !== 'percent' && (
                    <td className={`rowTotalContainer noselect`}>
                        <div className="rowTotal totalCell">
                            <Text className="totalCellInner" as="div">
                                <DisplayCell value={times?.reduce((prev, cur) => prev + (cur.remainingWork || 0), 0)} periodDays={props.perioddays} />
                            </Text>
                        </div>
                    </td>
                )}
                <td className="rowTotalContainer noselect">
                    <div className="rowTotal totalCell">
                        <Text as="div" className="totalCellInner">
                            <DisplayCell
                                value={GetTotalFromTimeRowArray(times) != null ? FormatValue(GetTotalFromTimeRowArray(times)) : ''}
                                periodDays={props.perioddays}
                            />
                        </Text>
                    </div>
                </td>
            </tr>
            {/* </ReactCSSTransitionGroup> */}
            {times?.map((time: TimeRow, index) => {
                if (!expanded) {
                    return <React.Fragment key={time.key}></React.Fragment>;
                }

                return (
                    <tr key={time.key} className={`subRow${index === times?.length - 1 ? ' lastSubRow' : ''}`}>
                        {/* <ReactCSSTransitionGroup
                        key={time.key}
                        transitionName="fade"
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={300}
                        transitionAppearTimeout={500}
                        transitionAppear={true}
                        component="tr"
                        className={`subRow${index === times?.length - 1 ? " lastSubRow" : ""}`}
                    // className={`subRow${props.today === props.startDate ? " isMonday" : ""}${index === times.length - 1 ? " lastSubRow" : ""}${deletedTimeRows.indexOf(time) > -1 ? " deletedRow" : ""}`}
                    > */}

                        <td className="rowHeader noselect">
                            <Stack horizontal className="rowHeaderContainer">
                                <div className="expander" />
                                <Stack.Item grow>
                                    <TooltipHost directionalHint={DirectionalHint.topLeftEdge} content={time.taskDescription} closeDelay={250}>
                                        <Text className="subGroupName textOverflow">
                                            {time.taskName.length < 70 ? (
                                                time.taskName
                                            ) : (
                                                <TooltipHost content={time.taskName}>{time.taskName.substr(0, 68) + '...'}</TooltipHost>
                                            )}
                                        </Text>
                                    </TooltipHost>
                                </Stack.Item>

                                <Stack.Item className="noselect rowDelete">
                                    <MyTimeDelete Time={time} deleteMyTime={DeleteMyTime} enabled={!submittedOrPeriodClosed} />
                                </Stack.Item>

                                <Stack.Item className="noselect pin">
                                    <MyTimeAdd key={index} timeId={time.timeId} pinned={time.pinned} Time={time} pinMyTime={PinMyTime} isMyTime={true} />
                                </Stack.Item>
                            </Stack>
                        </td>

                        {uiCtx.workTypeEnabled && (
                            // <td className="cellContainer noselect weekend" hidden={uiCtx.workTypesDisabled}>
                            <td className="cellContainer worktypeContainer noselect">
                                <div className="cell day">
                                    <WorkTypePicker
                                        readOnly={submittedOrPeriodClosed || time.projectClosed || time.taskClosed}
                                        row={time}
                                        existingTaskTimeRows={times}
                                        onChange={props.updateWorkType}
                                    />
                                </div>
                            </td>
                        )}
                        {uiCtx.plannedActive && uiCtx.showPlanned && uiCtx.gridInputType !== 'percent' && (
                            <td className={`cellContainer noselect`}>
                                <div className="cell day">
                                    <DisplayCell value={time.plannedWork} periodDays={props.perioddays} />
                                </div>
                            </td>
                        )}
                        {uiCtx.actualActive && uiCtx.showPlanned && uiCtx.gridInputType !== 'percent' && (
                            <td className={`cellContainer noselect`}>
                                <div className="cell day">
                                    <DisplayCell value={time.actualWork} periodDays={props.perioddays} />
                                </div>
                            </td>
                        )}
                        {uiCtx.remainingActive && uiCtx.showPlanned && uiCtx.gridInputType !== 'percent' && (
                            <td className={`cellContainer noselect`}>
                                <div className="cell day">
                                    <DisplayCell value={time.remainingWork} periodDays={props.perioddays} />
                                </div>
                            </td>
                        )}
                        <td className="cellContainer noselect">
                            <div className="cell day">
                                <CellWeek
                                    time={time}
                                    timeentries={time.timeEntries}
                                    perioddays={props.perioddays}
                                    updated={onCellUpdated}
                                    propertyUpdated={time.changetoken}
                                    reportPeriodId={props.reportPeriodId}
                                    readonly={
                                        uiCtx.user.user.start === null || uiCtx.user.user.end === null
                                            ? false
                                            : onlyTimesheetWithinStartEnd && !time.taskIsDefault && (time.taskStart === undefined || time.taskEnd === undefined)
                                            ? true
                                            : (props.perioddays[0].date < new Date(uiCtx.user.user.start) &&
                                                  new Date(uiCtx.user.user.start).getFullYear() > 1900) ||
                                              (props.perioddays[props.perioddays.length - 1].date > new Date(uiCtx.user.user.end) &&
                                                  new Date(uiCtx.user.user.end).getFullYear() > 1900) ||
                                              submittedOrPeriodClosed ||
                                              time.projectClosed ||
                                              time.taskClosed ||
                                              (onlyTimesheetWithinStartEnd &&
                                                  (time.taskStart === undefined
                                                      ? false
                                                      : props.perioddays[0].date < DateAsStartOfDay(new Date(time.taskStart)))) ||
                                              (onlyTimesheetWithinStartEnd &&
                                                  (time.taskEnd === undefined
                                                      ? false
                                                      : props.perioddays[props.perioddays.length - 1].date > DateAsStartOfDay(new Date(time.taskEnd)))) ||
                                              !workTypeAllowed(time)
                                    }
                                    readonlyreason={
                                        props.perioddays[0].date < new Date(uiCtx.user.user.start) && new Date(uiCtx.user.user?.start).getFullYear() > 1900
                                            ? 'You are not working at this time'
                                            : props.perioddays[props.perioddays.length - 1].date > new Date(uiCtx.user.user.end) &&
                                              new Date(uiCtx.user.user.end).getFullYear() > 1900
                                            ? 'You are not working at this time'
                                            : submittedOrPeriodClosed
                                            ? 'time period is submitted, approved or closed'
                                            : time.projectClosed
                                            ? 'Project is closed or deleted'
                                            : time.taskClosed
                                            ? 'Task is closed, deleted or you are no longer assigned the task'
                                            : onlyTimesheetWithinStartEnd &&
                                              time.taskStart === undefined &&
                                              onlyTimesheetWithinStartEnd &&
                                              time.taskEnd === undefined
                                            ? 'Task has no start or end date'
                                            : onlyTimesheetWithinStartEnd && time.taskStart === undefined
                                            ? 'Task has no start date'
                                            : onlyTimesheetWithinStartEnd && time.taskEnd === undefined
                                            ? 'Task has no end date'
                                            : onlyTimesheetWithinStartEnd && time.taskStart !== undefined && props.perioddays[0].date < new Date(time.taskStart)
                                            ? 'Task is not yet started'
                                            : onlyTimesheetWithinStartEnd &&
                                              time.taskEnd !== undefined &&
                                              props.perioddays[props.perioddays.length - 1].date > new Date(time.taskEnd)
                                            ? 'Task has ended'
                                            : !workTypeAllowed(time)
                                            ? 'Worktype is not allowed'
                                            : ''
                                    }
                                    // readonlyreason={
                                    //     (day.date < uiCtx.user.start && new Date(uiCtx.user.start).getFullYear() > 1900) ? 'You are not working at this time' :
                                    //         (day.date > uiCtx.user.end && new Date(uiCtx.user.end).getFullYear() > 1900) ? 'You are not working at this time' :
                                    //             submittedOrPeriodClosed ? 'time period is submitted, approved or closed' :
                                    //                 time.projectClosed ? 'Project is closed or deleted' :
                                    //                     time.taskClosed ? 'Task is closed, deleted or you are no longer assigned the task' :
                                    //                         (onlyTimesheetWithinStartEnd && (time.taskStart !== undefined && day.date < new Date(time.taskStart))) ? 'Task is not yet started' :
                                    //                             (onlyTimesheetWithinStartEnd && (time.taskEnd !== undefined && day.date > new Date(time.taskEnd))) ? 'Task has ended' :
                                    //                                 !workTypeAllowed(time) ? 'Worktype is not allowed' :
                                    //                                     ''
                                    // }
                                    timeLowerLimitConfig={props.timeLowerLimitConfig}
                                    // ref={(n) => cellCtx.addInputNode(time.taskId, time.workType?.id, index, n)}
                                    ref={n => cellCtx.addInputNode(time.taskId, time.workType?.id, 0, n)}
                                    cellDataNeedsSave={setCellDataNeedsSaveFunc}
                                />
                            </div>
                        </td>

                        {/* </ReactCSSTransitionGroup> */}
                    </tr>
                );
            })}
        </>
    );
}

export default TimePeriodGroupWeek;
