export type ConfigDictionary =
    | 'ADSync_Enabled'
    | 'DataverseSync_Enabled'
    | 'ADSyncO365_GroupName'
    | 'DataverseResourceEntity'
    | 'DataverseColumnResourceId'
    | 'DataverseColumnResourceName'
    | 'DataverseResourceColumnPrincipalname'
    | 'DataverseResourceColumnEmail'
    | 'DataverseResourceColumnAzureId';

export const ConfigDictionaryAsString = {
    ADSYNC_ENABLED: 'ADSync_Enabled',
    DATAVERSYNC_ENABLED: 'DataverseSync_Enabled',
    ADSYNC0365_GROUPNAME: 'ADSyncO365_GroupName',
    RESOURCEENTITY: 'DataverseResourceEntity',
    RESOURCEID: 'DataverseColumnResourceId',
    RESOURCENAME: 'DataverseColumnResourceName',
    PRINCIPALNAME: 'DataverseResourceColumnPrincipalname',
    RESOURCEEMAIL: 'DataverseResourceColumnEmail',
    RESOURCEAZUREID: 'DataverseResourceColumnAzureId',
};

export const validateForm = (configs: Record<ConfigDictionary, string>): boolean => {
    let validForm = true;
    if (configs.ADSync_Enabled === 'true') {
        validForm = configs.ADSyncO365_GroupName !== '';
    }
    if (configs.DataverseSync_Enabled === 'true') {
        if (
            configs.DataverseResourceEntity === '' ||
            configs.DataverseColumnResourceId === '' ||
            configs.DataverseColumnResourceName === '' ||
            configs.DataverseResourceColumnPrincipalname === '' ||
            configs.DataverseResourceColumnEmail === '' ||
            configs.DataverseResourceColumnAzureId === ''
        ) {
            validForm = false;
        }
    }
    return validForm;
};
