import React, { useMemo, useRef } from 'react';
import { IPersonaProps, PersonaPresence, PersonaSize } from '@fluentui/react';
import { useUiContext } from '../../../../components/Contexts/UiContext';
import User from '../../../../Model/User';
import { SpinLoaderSlow } from '../../../../components/SpinLoader';
import { ApproverConfigOverview } from './ApproverConfigOverview';
import { useQuery } from '@tanstack/react-query';
import { GET_USERS_WITH_APPROVERS } from '../../../../constants/queryKeys';

export const ApproverConfig = () => {
    const uiCtx = useUiContext();

    const defaultData = useRef([]).current;

    const { data: allUsers = defaultData, isLoading } = useQuery([GET_USERS_WITH_APPROVERS], (): Promise<User[]> => uiCtx.timeApi.getUsersWithApprovers(), {
        onSuccess: () => uiCtx.setLoading(false),
        placeholderData: defaultData,
    });

    const allUsersPersonas = useMemo((): IPersonaProps[] => {
        return allUsers.map(userPicker => {
            return {
                //imageUrl: 'https://outlook.office.com/owa/service.svc/s/GetPersonaPhoto?email=' + userPicker.email + '&UA=0&size=HR48x48',
                imageInitials: userPicker.name?.substring(0, 1) + (userPicker.surname ? userPicker.surname.substring(0, 1) : ''),
                text: userPicker.name,
                secondaryText: userPicker.userNumber ?? userPicker.jobTitle ?? '',
                showSecondaryText: true,
                size: PersonaSize.size48,
                presence: PersonaPresence.none,
                imageAlt: userPicker.name + ', ' + userPicker.jobTitle,
                itemID: userPicker.id,
            };
        });
    }, [allUsers]);

    if (isLoading || (allUsers.length === 0 && allUsersPersonas.length === 0)) {
        return <SpinLoaderSlow />;
    }

    return <ApproverConfigOverview allUsers={allUsers} allUsersPersonas={allUsersPersonas} />;
};
