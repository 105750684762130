import { Spinner, Stack } from '@fluentui/react';
import { Environment } from '../../PowerPPMSync/components/Environment';
import { useUiContext } from '../../../components/Contexts/UiContext';
import { useEffect, useState } from 'react';
import React from 'react';
import { DataverseUsers } from './components/DataverseUsers';
import { ConfigDictionary } from '../utils';
import { ContentSection } from '../../../components/LayoutElements';

export type DataverseProps = {
    config: Record<ConfigDictionary, string>;
    setConfig: React.Dispatch<React.SetStateAction<Record<ConfigDictionary, string>>>;
};

export const DataverseUserSync = ({ config, setConfig }: DataverseProps) => {
    const uiCtx = useUiContext();

    const [isDataverse, setIsDataverse] = useState<boolean>(false);
    const [checkIsLoading, setCheckIsLoading] = useState<boolean>(true);
    const [dataverseURL, setDataverseURL] = useState<string>('');

    useEffect(() => {
        if (checkIsLoading) {
            uiCtx.timeApi.dataverseIsSetup().then(ep => {
                setDataverseURL(ep);
                setIsDataverse(ep !== '');
                setCheckIsLoading(false);
            });
        }
    }, [checkIsLoading]);

    if (checkIsLoading) {
        return (
            <Stack horizontalAlign="center" verticalAlign="center">
                <Spinner label="Loading..." />
            </Stack>
        );
    }

    return (
        <Stack>
            <Environment config={uiCtx} isDataverse={isDataverse} setDataverseUrl={setDataverseURL} dataverseUrl={dataverseURL} />
            <ContentSection>
                {isDataverse && (
                    <Stack tokens={{ childrenGap: 10 }}>
                        <DataverseUsers config={config} setConfig={setConfig} />
                    </Stack>
                )}
            </ContentSection>
        </Stack>
    );
};
