import React, { useEffect } from 'react';
import { Icon, IIconStyles } from '@fluentui/react/lib/Icon';
import { Separator } from '@fluentui/react/lib/Separator';
import { Stack } from '@fluentui/react/lib/Stack';
import { useUiContext } from '../components/Contexts/UiContext';
import { Link as RouterLink } from 'react-router-dom';
import { Link as FluentLink } from '@fluentui/react';
import { PageHeader, PageContent, ContentSection } from '../components/LayoutElements';
import { CopyAppId } from './CopyAppId/CopyAppId';
import { routePaths } from '../Router/Router';
import '../Styles/Admin.scss';

const Link = ({
    to,
    seperator = 'below',
    children,
}: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & { to: string; seperator?: 'above' | 'below' }) => {
    return (
        <>
            {seperator === 'above' && <LinkSeperator />}
            <RouterLink to={to} style={{ textDecoration: 'none' }}>
                <FluentLink>{children}</FluentLink>
            </RouterLink>
            {seperator === 'below' && <LinkSeperator />}
        </>
    );
};

const LinkSeperator = () => {
    return <Separator styles={{ root: { width: '100%' } }} />;
};

function Admin() {
    const uiCtx = useUiContext();

    useEffect(() => {
        uiCtx.setLoading(false);
    }, []);

    return (
        <>
            <PageHeader title="Admin Center" icon="Settings" />
            <PageContent>
                <ContentSection alignContent="stretch">
                    <h4>
                        <Stack horizontalAlign="center">
                            <Link to={routePaths.admin.users}>
                                <Icon iconName="TemporaryUser" styles={linkIconStyles} />
                                Users
                            </Link>
                            <Link to={routePaths.admin.adminUsers}>
                                <Icon iconName="Admin" styles={linkIconStyles} />
                                Admin Users
                            </Link>
                            {uiCtx.user?.originalUser?.isAdmin && (
                                <>
                                    <Link to={routePaths.admin.users} seperator="below">
                                        <Icon iconName="TemporaryUser" styles={linkIconStyles} />
                                        Users
                                    </Link>
                                    <Link to={routePaths.admin.adminUsers} seperator="below">
                                        <Icon iconName="Admin" styles={linkIconStyles} />
                                        Admin Users
                                    </Link>
                                    <Link to={routePaths.admin.resourceoverview} seperator="below">
                                        <Icon iconName="TemporaryUser" styles={linkIconStyles} />
                                        Resource Overview
                                    </Link>
                                </>
                            )}
                            {(uiCtx.user?.delegatedUsers.length > 0 || uiCtx.user?.originalUser.isAdmin) && (
                                <Link to={routePaths.admin.delegates}>
                                    <Icon iconName="PeopleRepeat" styles={linkIconStyles} />
                                    Manage Delegates
                                </Link>
                            )}
                            <Link to={routePaths.admin.configuration.approver}>
                                <Icon iconName="ManagerSelfService" styles={linkIconStyles} />
                                Approvers
                            </Link>
                            <Link to={routePaths.admin.configuration.period}>
                                <Icon iconName="Calendar" styles={linkIconStyles} />
                                Periods
                            </Link>

                            <Link to={routePaths.admin.projects}>
                                <Icon iconName="ProjectCollection" styles={linkIconStyles} />
                                Administrative Projects
                            </Link>
                            <Link to={routePaths.admin.configuration.calendar}>
                                <Icon iconName="Calendar" styles={linkIconStyles} />
                                Calendar Configuration
                            </Link>
                            <Link to={routePaths.admin.configuration.time}>
                                <Icon iconName="CalendarSettings" styles={linkIconStyles} />
                                Time Configuration
                            </Link>
                            <Link to={routePaths.admin.configuration.validation}>
                                <Icon iconName="Admin" styles={linkIconStyles} />
                                Time Validation Configuration
                            </Link>
                            <Link to={routePaths.admin.sync}>
                                <Icon iconName="PlugConnected" styles={linkIconStyles} />
                                Connect Resources
                            </Link>
                            <Link to={routePaths.admin.powerppm}>
                                <Icon iconName="PlugConnected" styles={linkIconStyles} />
                                Connect Data from Dataverse
                            </Link>
                            <Link to={routePaths.admin.powerhub}>
                                <Icon iconName="PlugConnected" styles={linkIconStyles} />
                                PowerHub Settings
                            </Link>
                            {uiCtx.enableTimeEntriesImport && (
                                <Link to={routePaths.admin.jiraupload}>
                                    <Icon iconName="Upload" styles={linkIconStyles} />
                                    Time Entries Import
                                </Link>
                            )}
                            <Link to={routePaths.admin.configuration.help}>
                                <Icon iconName="HandsFree" styles={linkIconStyles} />
                                Help Configuration
                            </Link>
                            <Link to={routePaths.admin.systemInformation}>
                                <Icon iconName="Info" styles={linkIconStyles} />
                                System Information
                            </Link>
                            <Link to={routePaths.admin.settings}>
                                <Icon iconName="AllApps" styles={linkIconStyles} />
                                Modern settings (preview)
                            </Link>
                            <CopyAppId />
                        </Stack>
                    </h4>
                </ContentSection>
            </PageContent>
        </>
    );
}

export default Admin;

const linkIconStyles: IIconStyles = {
    root: {
        fontSize: '18px',
        height: '18px',
        width: '32px',
    },
};
