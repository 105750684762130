// import { makeStyles } from '../../../../hooks/useGetStyles';

import { makeStyles } from '../../useGetStyles';

// const EditableText: React.FC<{
//     source: ISource;
//     editMode: boolean;
//     sourcePropName: keyof ISource;
//     className?: string;
//     setSource: React.Dispatch<React.SetStateAction<ISource>>;
//     textComponent?: any;
//     // children?: React.ReactNode;
// }> = ({ sourcePropName, source, setSource, editMode, className, textComponent = Text, children }) => {
//     const Text = textComponent;
//     return editMode ? (
//         <TextField
//             underlined
//             value={String(source[sourcePropName])}
//             onChange={(e, newValue) => {
//                 if (newValue !== undefined) {
//                     setSource((s) => ({ ...s, [sourcePropName]: newValue }));
//                 }
//             }}
//             styles={{root: {input: {padding: 0}}}}
//         />
//     ) : children ? (
//         <>{children}</>
//     ) : (
//         <Text className={className}>{source[sourcePropName]}</Text>
//     );
// };
export const styles = makeStyles(theme => ({
    root: [
        'cards-grid',
        {
            position: 'relative',
            // height: '100%',
            padding: theme.spacing.l1,
            paddingBottom: theme.spacing.m,
            borderRadius: theme.effects.roundedCorner2,
            boxShadow: `0 0 0 1px ${theme.palette.neutralLight}`,
            // backgroundColor: '#fdfdfd',
            backgroundColor: theme.semanticColors.disabledBackground,
            transition: 'box-shadow 150ms',
            ':hover': {
                transition: 'box-shadow 75ms',
                boxShadow: theme.effects.elevation16,
            },
        },
    ],
    card: [
        'card',
        {
            height: '100%',
        },
    ],
    title: {
        fontSize: 20,
        fontWeight: 600,
        width: '100%',
        overflow: 'hidden',
    },
    divider: {
        width: '100%',
        height: 1,
        backgroundColor: theme.semanticColors.variantBorder,
        margin: `${theme.spacing.m} 0px`,
    },
    description: {
        flexGrow: 1,
        fontSize: 14,
        fontWeight: 600,
        color: theme.semanticColors.bodySubtext,
        paddingBottom: theme.spacing.m,
    },
    url: {
        flexGrow: 1,
        fontSize: 14,
        color: theme.semanticColors.bodySubtext,
        paddingBottom: theme.spacing.m,
        overflow: 'hidden',
        width: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'no-wrap',
        // overflowWrap: "break-word",
    },
    toolTip: {
        overflow: 'hidden',
        width: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'no-wrap',
        // width: "100%",
        display: 'block',
    },
    status: {
        fontSize: 13,
        width: 'fit-content',
        alignSelf: 'flex-end',
        padding: `${theme.spacing.s2} ${theme.spacing.s1}`,
        borderRadius: theme.effects.roundedCorner2,
        color: theme.semanticColors.successText,
        backgroundColor: theme.semanticColors.successBackground,
    },
    schemaIcon: {
        // height: '1.2rem !important',
        // marginBottom: theme.spacing.m,
        // marginRight: theme.spacing.m,
        height: '2rem',
        width: '2rem',
        display: 'flex',
        alignItems: 'center',
        '.icon-container': {
            height: '100%',
            width: '100%',
        },
    },
    showMore: {
        fontSize: 11,
        color: theme.palette.themeSecondary,
    },
}));
