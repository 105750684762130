export class LocalStorage {
	public static Set = (key: string, value: any): void => {
		localStorage.setItem(key, JSON.stringify(value));
	}

	public static Get = <T>(key: string): T => {
		var value = localStorage.getItem(key);
		if (value == null || value === "undefined") return null;
		return JSON.parse(value);
	}

	public static SetProperty = (key: string, propertyKey: string, value: any) : void => {
		var cache = LocalStorage.Get<Date>(key) || {};
		cache[propertyKey] = value;
		LocalStorage.Set(key, cache);
	}

	public static GetProperty = <T>(key: string, propertyKey: string) : T => {
		var cache = LocalStorage.Get<any>(key) || {};
		return cache[propertyKey];
	}
}