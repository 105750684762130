import React, { useState, useEffect } from 'react';
import { useUiContext } from '../../../../components/Contexts/UiContext';
import { ComboBox } from '@fluentui/react';
import { DataverseProps } from '../DataverseUserSync';
import utils, { IExpandedComboBoxOption } from '../../../PowerPPMSync/utils/utils';
import { ConfigDictionary } from '../../utils';
import { Stack } from '@fluentui/react';

export const DataverseUsers = ({ config, setConfig }: DataverseProps) => {
    const [tables, setTables] = useState<IExpandedComboBoxOption[]>([]);
    const [fields, setFields] = useState<IExpandedComboBoxOption[]>([]);
    const uiCtx = useUiContext();

    useEffect(() => {
        utils.getTables(uiCtx.timeApi).then(setTables);
    }, []);

    useEffect(() => {
        if (!config.DataverseResourceEntity) {
            return;
        }
        utils.getFields(config.DataverseResourceEntity, uiCtx.timeApi).then(setFields);
    }, [config.DataverseResourceEntity]);

    return (
        <Stack>
            <>
                <ComboBox
                    label="Dataverse Resource entity Table"
                    allowFreeform
                    autoComplete="on"
                    options={tables}
                    onChange={(e, opt, index, value) => {
                        setConfig(prevState => ({
                            ...prevState,
                            DataverseResourceEntity: value,
                        }));
                    }}
                    selectedKey={config.DataverseResourceEntity}
                />
                {config.DataverseResourceEntity && !fields && <div>Loading</div>}
                <>
                    <h5 style={{ marginTop: 5 }}>Columns:</h5>
                    <UserInputs config={config} setConfig={setConfig} fields={fields ?? []} />
                </>
            </>
        </Stack>
    );
};

type UserColumnProps = {
    config: Record<ConfigDictionary, string>;
    setConfig: React.Dispatch<React.SetStateAction<Record<ConfigDictionary, string>>>;
    fields: IExpandedComboBoxOption[];
};

const UserInputs = ({ config, setConfig, fields }: UserColumnProps) => {
    const getErrorMessage = (fieldValue: string) => {
        return fieldValue ? '' : 'Field is required';
    };

    return (
        <>
            <ComboBox
                label="Resource id *"
                allowFreeform
                autoComplete="on"
                options={fields}
                onChange={(e, opt, index, value) => {
                    setConfig(prevState => ({
                        ...prevState,
                        DataverseColumnResourceId: value,
                    }));
                }}
                selectedKey={config.DataverseColumnResourceId}
                errorMessage={getErrorMessage(config.DataverseColumnResourceId)}
            />
            <ComboBox
                label="Resource name *"
                allowFreeform
                autoComplete="on"
                options={fields}
                onChange={(e, opt, index, value) => {
                    setConfig(prevState => ({
                        ...prevState,
                        DataverseColumnResourceName: value,
                    }));
                }}
                selectedKey={config.DataverseColumnResourceName}
                errorMessage={getErrorMessage(config.DataverseColumnResourceName)}
            />
            <ComboBox
                label="Resource principal name *"
                allowFreeform
                autoComplete="on"
                options={fields}
                onChange={(e, opt, index, value) => {
                    setConfig(prevState => ({
                        ...prevState,
                        DataverseResourceColumnPrincipalname: value,
                    }));
                }}
                selectedKey={config.DataverseResourceColumnPrincipalname}
                errorMessage={getErrorMessage(config.DataverseResourceColumnPrincipalname)}
            />
            <ComboBox
                label="Resource email *"
                allowFreeform
                autoComplete="on"
                options={fields}
                onChange={(e, opt, index, value) => {
                    setConfig(prevState => ({
                        ...prevState,
                        DataverseResourceColumnEmail: value,
                    }));
                }}
                selectedKey={config.DataverseResourceColumnEmail}
                errorMessage={getErrorMessage(config.DataverseResourceColumnEmail)}
            />
            <ComboBox
                label="Resource azure id *"
                allowFreeform
                autoComplete="on"
                options={fields}
                onChange={(e, opt, index, value) => {
                    setConfig(prevState => ({
                        ...prevState,
                        DataverseResourceColumnAzureId: value,
                    }));
                }}
                selectedKey={config.DataverseResourceColumnAzureId}
                errorMessage={getErrorMessage(config.DataverseResourceColumnAzureId)}
            />
        </>
    );
};
