import React, { useEffect } from 'react';
import { ThemeProvider } from '@fluentui/react';
import { ITheme, loadTheme } from '@fluentui/react';
import { useUiContext } from './components/Contexts/UiContext';
import { AppMobileWrapper } from './Mobile/AppMobile';
import { Router } from './Router/Router';

export const App: React.FC = () => {
    const uiCtx = useUiContext();

    useEffect(() => {
        loadTheme(uiCtx.theme);
        // hotfix/polyfil for theme as css variables
        const applyThemeCssVariables = (theme: ITheme) => {
            const themeprovider = document.getElementById('themeprovider');
            const apply = (theme: ITheme, keyName: keyof ITheme) => {
                Object.entries(theme[keyName]).forEach(([key, value]) => {
                    themeprovider.style.setProperty(`--${keyName}-${key}`, value);
                });
            };
            apply(theme, 'palette');
            apply(theme, 'semanticColors');
            apply(theme, 'effects');
            apply(theme, 'spacing');
        };
        applyThemeCssVariables(uiCtx.theme);
    }, [uiCtx.theme]);

    return (
        <ThemeProvider id="themeprovider" theme={uiCtx.theme} className={`root${uiCtx.isDarkmode ? ' isDarkmode' : ''}`}>
            {uiCtx.userAgent === 'mobile' ? <AppMobileWrapper /> : <Router />}
        </ThemeProvider>
    );
};
