import { createContext } from '../../_contexts/createContext';

export type FilterDropdownContext = {
    onFilterInput: (str: string) => void;
};

const [useCtx, Provider] = createContext<FilterDropdownContext>();

export const useFilterDropdown = useCtx;

export const FilterDropdownContextProvider = Provider;
