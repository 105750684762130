import { TextField } from "@fluentui/react"
import React from "react"

export interface ValidationConfigProps {
    setConfig: (name, value) => void
    allConfig: any[]
}


export const NoMoreThenXHoursConfigValues = {
    MaxHours: "NoMoreThenXHours_MaxHours"
}

export const NoMoreThenXHoursConfig = (props: ValidationConfigProps) => {

    
    const configKey = NoMoreThenXHoursConfigValues.MaxHours;

    const onChangeMaxHours = (value) => {        
        props.setConfig(configKey, value);
    }

    if (!props.allConfig) return;


    return <div>
        <TextField onBlur={(e) => onChangeMaxHours(e.target.value)} label="Max hours per period" defaultValue={props.allConfig.find(_ => _.key == configKey)?.value || `38`}></TextField>
    </div>
}

