import { forwardRef, useMemo } from 'react';
import { Text, Stack, Link, useTheme } from '@fluentui/react';
import { styles } from './CardItem.styles';
import { useGetStyles } from '../../useGetStyles';
import { DataverseLogo } from '../Logos/DataverseLogo';
import './CardItem.scss';
import { GridContainer } from './components/GridContainer';
import { GridRow } from './components/GridRow';
import { IntegrationType } from '../../../../api/generated/data-contracts';
import { IntegrationTypeGeneric, IsDataverseIntegration, IsMicrosoftEntraIdIntegration } from '../../IntegrationTypeGeneric';
import { MicrosoftEntraIdLogo } from '../Logos/MicrosoftEntraIdLogo';

export const CardItem = forwardRef<
    HTMLDivElement,
    { integration: IntegrationTypeGeneric<any, any>; setEditableIntegration: (integration: IntegrationTypeGeneric<any, any>) => void }
>(function CardItem({ integration, setEditableIntegration }, ref) {
    const theme = useTheme();
    const classes = useGetStyles(styles);

    const configRender = useMemo(() => {
        if (IsDataverseIntegration(integration)) {
            return (
                <>
                    <GridRow
                        left="Url"
                        right={
                            <Text nowrap title={integration.configuration.dataverseUrl}>
                                <Link
                                    target="_blank"
                                    rel="noreferrer"
                                    href={integration.configuration.dataverseUrl}
                                    onClick={e => {
                                        e.stopPropagation();
                                    }}
                                >
                                    {integration.configuration.dataverseUrl}
                                </Link>
                            </Text>
                        }
                    />
                    <GridRow left={'Enabled'} right={integration.configuration.timeActualsSyncEnabled ? 'Yes' : 'No'} />
                </>
            );
        }
        if (IsMicrosoftEntraIdIntegration(integration)) {
            return (
                <>
                    <GridRow left={'Enabled'} right={integration.configuration.enabled ? 'Yes' : 'No'} />
                    <GridRow left={'Group Name'} right={integration.configuration.groupName} />
                    {/* <GridRow left={'Include Manager'} right={integration.configuration.includeManager ? 'Yes' : 'No'} />
                    <GridRow left={'Sync Users To Dataverse'} right={integration.configuration.syncUsersToDataverse ? 'Yes' : 'No'} /> */}
                </>
            );
        }

        return <div>Unknown integration type</div>;
    }, [integration]);

    return (
        <div className={classes.root} ref={ref} onClick={() => setEditableIntegration(integration)} style={{ cursor: 'pointer' }}>
            <Stack verticalAlign="start">
                <Stack
                    horizontal
                    tokens={{ childrenGap: theme.spacing.m }}
                    verticalAlign="center"
                    styles={{ root: { paddingBottom: theme.spacing.m, width: '100%' } }}
                >
                    <Stack>
                        <CardLogo type={integration.type} />
                    </Stack>
                    {/* <Stack styles={{root: {display: "inline-grid", flexGrow: 1}}}> */}
                    <Stack styles={{ root: { display: 'inline-grid', flexGrow: 1 } }}>
                        <Text nowrap={false} className={classes.title}>
                            {integration.name ?? integration.configuration.dataverseUrl}
                        </Text>
                    </Stack>
                </Stack>
                <div className={classes.divider} />
                <GridContainer>
                    <GridRow left={'Type'} right={getIntegrationTypeName(integration.type)} />
                    {configRender}
                </GridContainer>
            </Stack>
        </div>
    );
});

const getIntegrationTypeName = (type: IntegrationType) => {
    switch (type) {
        // case IntegrationType.Dataverse:
        case 'Dataverse':
            return 'Dataverse';
        case 'MicrosoftEntraId':
            return 'MicrosoftEntraId';
        default:
            return 'Unknown type';
    }
};

const CardLogo = ({ type }: { type: IntegrationType }) => {
    if (type === 'Dataverse') {
        return <DataverseLogo height={'35px'} width={'35px'} />;
    }

    if (type === 'MicrosoftEntraId') {
        return <MicrosoftEntraIdLogo height={'35px'} width={'35px'} />;
    }

    return <div>NO LOGO</div>;
};

export type DataverseConfiguration = {
    url: string;
    enabled: boolean;
};

export type MicrosoftEntraIdConfiguration = {
    enabled: boolean;
    groupName: string;
    includeManager: boolean;
    syncUsersToDataverse: boolean;
};
