
import * as React from "react"
import { constants } from '../Styles/constants';

export const TimeSubmitIcon: React.FC = (props) => {
  return (
    <svg
    width={constants.menuIconSize}
    height={constants.menuIconSize}
      viewBox="0 0 80 80"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <path
        fill="#4d8cc2"
        d="M23.799 60.24l47.345-47.344 6.859 6.859L30.658 67.1z"
      />
      <path
        fill="#0f447b"
        d="M30.6 67.007L1.996 38.4l7.454-7.455 28.606 28.607z"
      />
    </svg>
  )
}


// <svg width="40px" height="40px" viewBox="0 0 80 80" version="1.1"  style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
//     <g transform="matrix(0.848279,-0.848279,0.542436,0.542436,-3201.47,3050.38)">
//         <rect x="3663.54" y="216.735" width="55.813" height="12.645" style="fill:rgb(77,140,194);"/>
//     </g>
//     <g transform="matrix(-0.512536,-0.512536,0.589508,-0.589508,1780.53,2072.47)">
//         <rect x="3663.54" y="216.735" width="55.813" height="12.645" style="fill:rgb(15,68,123);"/>
//     </g>
// </svg>
