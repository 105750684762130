import React, { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { Icon, IIconStyles } from '@fluentui/react/lib/Icon';
import { Separator } from '@fluentui/react/lib/Separator';
import { useUiContext } from '../../../../../components/Contexts/UiContext';
import AdminHelpFAQ from './AdminHelpFAQ';
import AdminFAQM from '../../../../../Model/AdminFAQM';
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { createNewGuid } from '../../../../../components/Utils';

interface propsAdminFAQs {
    fAQs: AdminFAQM[];
}

function AdminFAQs(props: propsAdminFAQs) {
    const uiCtx = useUiContext();

    const [fAQs, setFAQs] = useState<AdminFAQM[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setFAQs(
            props.fAQs.sort((a, b) => {
                return a.order < b.order ? -1 : 1;
            }),
        );
        setLoading(false);
    }, [props.fAQs]);

    const ReorderFAQ = (s: AdminFAQM, direction: number) => {
        //console.log("projects!", projects);
        let neworder: number = -1;

        if (direction > 0) {
            neworder = s.order < fAQs.length ? s.order + 1 : fAQs.length;
        } else {
            neworder = s.order > 1 ? s.order - 1 : 1;
        }

        let ordernumber: number = 1;
        for (let i = 0; i < fAQs.length; i++) {
            if (neworder === ordernumber) {
                ordernumber = ordernumber + 1;
            }
            if (fAQs[i].id === s.id) {
                fAQs[i].order = neworder;
            } else {
                fAQs[i].order = ordernumber;
                ordernumber = ordernumber + 1;
            }
        }

        setFAQs(
            [...fAQs].sort((a, b) => {
                return a.order < b.order ? -1 : 1;
            }),
        );
    };
    const RemoveFAQ = (s: AdminFAQM) => {
        setFAQs(fAQs.filter(vid => vid.id !== s.id));
    };
    const FAQUpdated = (id: string, header: string, description: string, source: string, url: string) => {
        for (let i = 0; i < fAQs.length; i++) {
            if (fAQs[i].id === id) {
                fAQs[i].question = header;
                fAQs[i].response = description;
            }
        }
        setFAQs(
            [...fAQs].sort((a, b) => {
                return a.order < b.order ? -1 : 1;
            }),
        );
    };
    const saveChanges = () => {
        setLoading(true);
        uiCtx.timeApi.setFAQ(fAQs).then(fAQs => {
            console.log('saved');
            setLoading(false);
        });
    };
    const AddFAQClick = () => {
        const newFAQ: AdminFAQM = { id: createNewGuid(), question: '', response: '', order: fAQs.length + 1 };
        fAQs.push(newFAQ);
        setFAQs([...fAQs]);
    };
    return (
        <div style={outerDivStyle}>
            <h3>FAQs</h3>
            <Separator>
                <Icon iconName="ProjectCollection" styles={separatorIconStyles} />
            </Separator>
            <br />
            {loading ? (
                <Stack className="logindialog" horizontalAlign="center" verticalAlign="center">
                    <Spinner label="Loading..." />
                </Stack>
            ) : (
                <>{renderAdminFAQsConfig(uiCtx.user.originalUser.isAdmin)}</>
            )}
        </div>
    );

    function renderAdminFAQsConfig(isAdmin: Boolean): JSX.Element {
        return (
            <div style={{ display: 'inline-block' }}>
                <Stack tokens={{ childrenGap: 10 }}>
                    {fAQs.map((fAQ, index) => {
                        return <AdminHelpFAQ key={index} ReorderFAQ={ReorderFAQ} RemoveFAQ={RemoveFAQ} FAQUpdated={FAQUpdated} FAQ={fAQ} />;
                    })}
                    <Separator>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Stack.Item align="center">
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    {/* <DefaultButton onClick={()=>{window.location.href = /admin'}}
                                        text='Cancel' /> */}
                                    <DefaultButton text="Add Question" onClick={AddFAQClick}></DefaultButton>
                                    <PrimaryButton onClick={saveChanges} text="Save Questions" />
                                </Stack>
                            </Stack.Item>
                        </Stack>
                    </Separator>
                </Stack>
                <br />
                <br />
                <br />
            </div>
        );
    }
}

export default AdminFAQs;

const outerDivStyle = {
    margin: '20px 0px 0px 0px',
    display: 'block',
    justifyContent: 'center',
    textAlign: 'center',
    // backgroundColor: 'white',
} as React.CSSProperties;

const personaAprroverConfigContainerDivStyle = {
    display: 'inline-flex',
};

const separatorIconStyles: IIconStyles = {
    root: {
        fontSize: '24px',
        height: '24px',
        width: '24px',
    },
};
