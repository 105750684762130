// import { TimeRow } from '../../../../../_grid/grid';
import { TimeRow } from '../../../../../_grid/grid2';
import { createCtx } from '../../../../../components/Contexts/createCtx';

export type RowMetaContext<T extends TimeRow = TimeRow> = {
    subrows: TimeRow[];
    subrowFamily: TimeRow[];
    nestingLevel: number;
    parentRows: TimeRow[];
    row: T;
    siblingRows: TimeRow[];
};
const [useRowMetaContext__, Provider] = createCtx<RowMetaContext>();
export const useRowMetaContext = <T extends TimeRow>() => useRowMetaContext__() as RowMetaContext<T>;
export const RowMetaContextProvider = Provider;
