import { Text } from '@fluentui/react';
import { useLocation } from 'react-router';
import { TSettingsCategory } from './Dashboard';
import { TransitionGroup } from 'react-transition-group';
import { forwardRef, useMemo } from 'react';
import { CSSTransitionWithRefRenderProps } from '../../_components/CSSTransitionWithRefRenderProps';

export const Headliner = ({ category }: { category: TSettingsCategory }) => {
    const { pathname } = useLocation();
    const description = useMemo(() => {
        if (!category.description) {
            return <Text variant="large">{category.shortDescription}</Text>;
        }
        if (typeof category.description === 'function') {
            const Description = category.description;
            return <Description />;
        }
        return category.description;
    }, [category.description, category.shortDescription]);
    return (
        <TransitionGroup key={pathname} appear>
            <CSSTransitionWithRefRenderProps mountOnEnter unmountOnExit timeout={{ enter: 100, exit: 0 }} classNames={'setttings-header'}>
                {ref => (
                    <HeadlineContainer ref={ref}>
                        <Text variant="xxLarge">{category.title}</Text>
                        {description}
                    </HeadlineContainer>
                )}
            </CSSTransitionWithRefRenderProps>
        </TransitionGroup>
    );
};

type DivStyle = React.CSSProperties;

const HeadlineContainer = forwardRef<HTMLDivElement, { style?: DivStyle; children: React.ReactNode }>(({ children, style }, ref) => {
    const styles = useMemo(() => {
        const containerStyles: DivStyle = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 10,
            gap: 10,
        };
        return {
            ...containerStyles,
            ...style,
        };
    }, [style]);
    return (
        <div style={styles} ref={ref}>
            {children}
        </div>
    );
});
