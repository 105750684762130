import * as React from "react"
import { constants } from '../Styles/constants';

export const TimeApprovedGreyedOutIcon: React.FC = (props) => {
  return (
    <svg
    width={constants.menuIconSize}
    height={constants.menuIconSize}
      viewBox="0 0 80 80"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <path fill="#535353" d="M1.76 9.407h57.72v8.942H1.76z" />
      <path fill="#fff" d="M1.76 18.35h57.72v46.177H1.76z" />
      <path
        d="M37.671 51.699H26.127a2.565 2.565 0 110-5.13H37.67a2.565 2.565 0 110 5.13zm10.262-11.544a2.565 2.565 0 00-2.566-2.566h-19.24a2.565 2.565 0 100 5.131h19.24a2.565 2.565 0 002.566-2.565zm0-8.98a2.565 2.565 0 00-2.566-2.565h-19.24a2.565 2.565 0 100 5.131h19.24a2.565 2.565 0 002.566-2.565zm-28.22 17.958a2.565 2.565 0 00-2.565-2.565h-1.283a2.565 2.565 0 100 5.13h1.283a2.565 2.565 0 002.565-2.565zm0-8.978a2.565 2.565 0 00-2.565-2.566h-1.283a2.565 2.565 0 100 5.131h1.283a2.565 2.565 0 002.565-2.565zm0-8.98a2.565 2.565 0 00-2.565-2.565h-1.283a2.565 2.565 0 100 5.131h1.283a2.565 2.565 0 002.565-2.565z"
        fill="#535353"
        fillRule="nonzero"
      />
      <path
        fill="gray"
        d="M47.506 65.6l25.737-25.736 4.995 4.996-25.736 25.736z"
      />
      <path
        fill="#535353"
        d="M52.44 70.576L36.869 55.005l4.96-4.961 15.572 15.57z"
      />
    </svg>
  )
}


{/* <svg width="40px" height="40px" viewBox="0 0 80 80" version="1.1" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
     <g transform="matrix(0.543187,0,0,0.506601,-1933.79,-51.3054)">
        <rect x="3563.32" y="119.842" width="106.263" height="17.652" style="fill:rgb(83,83,83);"/>
    </g>
    <g transform="matrix(0.543187,0,0,2.616,-1933.79,-295.157)">
        <rect x="3563.32" y="119.842" width="106.263" height="17.652" style="fill:white;"/>
    </g>
    <g transform="matrix(1.28269,0,0,1.28269,-11.0711,-7.30487)">
        <path d="M38,46L29,46C27.895,46 27,45.105 27,44C27,42.895 27.895,42 29,42L38,42C39.105,42 40,42.895 40,44C40,45.105 39.105,46 38,46ZM46,37C46,35.895 45.105,35 44,35L29,35C27.895,35 27,35.895 27,37C27,38.105 27.895,39 29,39L44,39C45.105,39 46,38.105 46,37ZM46,30C46,28.895 45.105,28 44,28L29,28C27.895,28 27,28.895 27,30C27,31.105 27.895,32 29,32L44,32C45.105,32 46,31.105 46,30ZM24,44C24,42.895 23.105,42 22,42L21,42C19.895,42 19,42.895 19,44C19,45.105 19.895,46 21,46L22,46C23.105,46 24,45.105 24,44ZM24,37C24,35.895 23.105,35 22,35L21,35C19.895,35 19,35.895 19,37C19,38.105 19.895,39 21,39L22,39C23.105,39 24,38.105 24,37ZM24,30C24,28.895 23.105,28 22,28L21,28C19.895,28 19,28.895 19,30C19,31.105 19.895,32 21,32L22,32C23.105,32 24,31.105 24,30Z" style="fill:rgb(83,83,83);fill-rule:nonzero;"/>
    </g>
    <g transform="matrix(0.461119,-0.461119,0.395083,0.395083,-1727.45,1669.3)">
        <rect x="3663.54" y="216.735" width="55.813" height="12.645" style="fill:rgb(128,128,128);"/>
    </g>
    <g transform="matrix(-0.27899,-0.27899,0.392336,-0.392336,989.498,1177.7)">
        <rect x="3663.54" y="216.735" width="55.813" height="12.645" style="fill:rgb(83,83,83);"/>
    </g>
</svg>  */}
