import { ComboBox, Separator, Spinner, Stack } from '@fluentui/react';
import { useState, useEffect, useMemo } from 'react';
import { useUiContext } from '../../../../components/Contexts/UiContext';
import utils, { configObj, IColumnProps, IConfigDictionaryAssignment, IExpandedComboBoxOption, ISectionProps, IValueData } from '../../utils/utils';

export const Assignments = (props: ISectionProps) => {
    const config: IConfigDictionaryAssignment = useMemo(() => props.values[props.configKey], [props.values, props.configKey]);
    const configMappingObj = useMemo(() => configObj[props.configKey], [props.configKey]);
    const [assignmentStepAdded, setAssignmentStepAdded] = useState<boolean>(!(config.tableName === null || !config.tableName));

    const [fields, setFields] = useState<IExpandedComboBoxOption[]>();
    const uiCtx = useUiContext();

    useEffect(() => {
        if (!config.tableName) {
            return;
        }
        utils.getFields(config.tableName.key as string, uiCtx.timeApi).then(setFields);
    }, [config.tableName]);

    return (
        <Stack>
            <h1>Assignments</h1>
            <>
                <ComboBox
                    label="Dataverse Assignment Table"
                    allowFreeform
                    autoComplete="on"
                    options={props.tables}
                    onChange={(e, opt, index, value) => {
                        if (opt) {
                            props.updateSteps(opt, props.configKey);
                            setAssignmentStepAdded(true);
                            props.updateValue('enabled', true, props.configKey);
                        } else if (!value) {
                            props.updateSteps(null, props.configKey);
                            setAssignmentStepAdded(false);
                            props.updateValue('enabled', false, props.configKey);
                        }
                    }}
                    selectedKey={config.tableName ? config.tableName.key : ''}
                />
                {config.tableName && !fields && <Spinner />}
                <>
                    <h5 style={{ marginTop: 5 }}>Columns:</h5>
                    <AssignmentInputs
                        configMappingObj={configMappingObj}
                        updateValue={props.updateValue}
                        config={config}
                        fields={fields ?? []}
                        configKey={props.configKey}
                        tables={props.tables}
                        stepAdded={assignmentStepAdded}
                    />
                </>
            </>
        </Stack>
    );
};

interface IAssignmentColumnProps extends IColumnProps {
    config: IConfigDictionaryAssignment;
}

const AssignmentInputs = (props: IAssignmentColumnProps) => {
    //Alternate Fields
    const [alternateFields, setAlternateFields] = useState<IExpandedComboBoxOption[]>([]);
    const fields = useMemo(() => props.fields.filter(_ => !_.isLookUp), [props.fields]);
    const lookUpFields = useMemo(() => props.fields.filter(_ => _.isLookUp), [props.fields]);

    const uiCtx = useUiContext();

    useEffect(() => {
        if (!props.config.resourceAzureIdTable) {
            return;
        }
        utils.getFields(props.config.resourceAzureIdTable.key as string, uiCtx.timeApi).then(values => {
            // const fields = values.filter(_ => !_.isLookUp);
            setAlternateFields(values);
        });
    }, [props.config.resourceAzureIdTable]);

    const getErrorMessage = (fieldValue: IValueData) => {
        if (!props.stepAdded) {
            return '';
        }
        if (!fieldValue || props.fields.filter(_ => _.key === fieldValue.key).length === 0) {
            return 'Field is required';
        }
        return '';
    };

    const getAlternativeErrorMessage = (fieldValue: IValueData) => {
        if (!props.stepAdded) {
            return '';
        }
        if (!fieldValue || alternateFields.filter(_ => _.key === fieldValue.key).length === 0) {
            return 'Field is required';
        }
        return '';
    };

    const getTableErrorMessage = (fieldValue: IValueData) => {
        if (!props.stepAdded) {
            return '';
        }
        if (!fieldValue || props.tables.filter(_ => _.key === fieldValue.key).length === 0) {
            return 'Field is required';
        }
        return '';
    };

    return (
        <>
            <ComboBox
                label="Assignment Id *"
                allowFreeform
                autoComplete="on"
                options={fields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('idColumn', opt, props.configKey);
                    } else if (value === '') {
                        props.updateValue('idColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.stepAdded && props.config.idColumn ? props.config.idColumn.key : ''}
                errorMessage={getErrorMessage(props.config.idColumn)}
            />
            <ComboBox
                label="Assignment Task Id *"
                allowFreeform
                autoComplete="on"
                options={lookUpFields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('taskIdColumn', opt, props.configKey);
                    } else if (value === '') {
                        props.updateValue('taskIdColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.stepAdded && props.config.taskIdColumn ? props.config.taskIdColumn.key : ''}
                errorMessage={getErrorMessage(props.config.taskIdColumn)}
            />
            <ComboBox
                label="Assignment Resource Id *"
                allowFreeform
                autoComplete="on"
                options={props.fields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('resourceIdColumn', opt, props.configKey);
                        props.updateValue('resourceAzureIdAlternateColumnEnabled', true, props.configKey);
                    } else if (value === '') {
                        props.updateValue('resourceIdColumn', null, props.configKey);
                        props.updateValue('resourceAzureIdAlternateColumnEnabled', false, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.stepAdded && props.config.resourceIdColumn ? props.config.resourceIdColumn.key : ''}
                errorMessage={getErrorMessage(props.config.resourceIdColumn)}
            />
            <ComboBox
                label="Assignment Planned Work"
                allowFreeform
                autoComplete="on"
                options={fields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('plannedWorkColumn', opt, props.configKey);
                    } else if (value === '') {
                        props.updateValue('plannedWorkColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.stepAdded && props.config.plannedWorkColumn ? props.config.plannedWorkColumn.key : ''}
            />
            <ComboBox
                label="Assignment Remaining Work"
                allowFreeform
                autoComplete="on"
                options={fields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('remainingWorkColumn', opt, props.configKey);
                    } else if (value === '') {
                        props.updateValue('remainingWorkColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.stepAdded && props.config.remainingWorkColumn ? props.config.remainingWorkColumn.key : ''}
            />
            {/* Add rate column */}
            <ComboBox
                label="Assignment Rate"
                allowFreeform
                autoComplete="on"
                options={fields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('rateColumn', opt, props.configKey);
                    } else if (value === '') {
                        props.updateValue('rateColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.stepAdded && props.config.rateColumn ? props.config.rateColumn.key : ''}
            />
            <Separator />
            <>
                <ComboBox
                    label="Resource Azure Id Alternate Table *"
                    allowFreeform
                    autoComplete="on"
                    options={props.tables}
                    onChange={(e, opt, index, value) => {
                        if (opt) {
                            props.updateValue('resourceAzureIdTable', opt, props.configKey);
                        } else if (value === '') {
                            props.updateValue('resourceAzureIdTable', null, props.configKey);
                        }
                        if (props.config.resourceAzureIdTable.key !== opt.key || value === '') {
                            props.updateValue('resourceAzureIdColumn', null, props.configKey);
                        }
                    }}
                    disabled={!props.stepAdded || !props.config.resourceAzureIdAlternateColumnEnabled}
                    selectedKey={props.stepAdded && props.config.resourceAzureIdTable ? props.config.resourceAzureIdTable.key : ''}
                    errorMessage={getTableErrorMessage(props.config.resourceAzureIdTable)}
                />
                <ComboBox
                    label="Resource Azure Id Alternate Column *"
                    allowFreeform
                    autoComplete="on"
                    options={alternateFields}
                    onChange={(e, opt, index, value) => {
                        if (opt) {
                            props.updateValue('resourceAzureIdColumn', opt, props.configKey);
                        } else if (value === '') {
                            props.updateValue('resourceAzureIdColumn', null, props.configKey);
                        }
                    }}
                    disabled={!props.stepAdded || !props.config.resourceAzureIdAlternateColumnEnabled}
                    selectedKey={props.stepAdded && props.config.resourceAzureIdColumn ? props.config.resourceAzureIdColumn.key : ''}
                    errorMessage={getAlternativeErrorMessage(props.config.resourceAzureIdColumn)}
                />
            </>
        </>
    );
};
