import React, { useEffect, useState, useRef } from 'react';
import { IPersonaProps } from '@fluentui/react/lib/Persona';
import { CompactPeoplePicker, IBasePickerSuggestionsProps, ValidationState } from '@fluentui/react/lib/Pickers';
import User from '../../Model/User';
import { useUiContext } from '../../components/Contexts/UiContext';

interface propsDelegatePicker {
    concernedUser: User;
    allUsersPersonas: IPersonaProps[];
    currentDelegatePersona: IPersonaProps[];
    onDelegateChanged: (approverAsArray: IPersonaProps[], concernedUser: User) => void;
}

function DelegatePicker(props: propsDelegatePicker) {
    const uiCtx = useUiContext();

    const [usersPersonas, setUsersPersonas] = useState<IPersonaProps[]>(props.allUsersPersonas);
    const [selectedPersonas, setSelectedPersonas] = useState<IPersonaProps[]>([]);
    const [mostRecentlyUsed, setMostRecentlyUsed] = useState<IPersonaProps[]>([]); //props.allUsersPersonas.slice(0,5)
    const picker = useRef(null);

    const resultsMaxNumber: number = 10;
    const resultsSuperMaxNumber: number = 50;
    const suggestionProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested People',
        mostRecentlyUsedHeaderText: 'Suggested Contacts',
        noResultsFoundText: 'No results found',
        loadingText: 'Loading',
        showRemoveButtons: false,
        suggestionsAvailableAlertText: 'People Picker Suggestions available',
        suggestionsContainerAriaLabel: 'Suggested contacts',
        resultsMaximumNumber: resultsSuperMaxNumber,
        searchForMoreText: 'Get all results',
        searchingText: 'Fetching all results...',
        resultsFooterFull: () => {
            return (
                // TODO: fix color
                <div style={{ textAlign: 'center', color: uiCtx.isDarkmode ? 'rgb(208, 208, 208)' : 'rgb(96, 94, 92)', fontSize: '12px', lineHeight: '30ox' }}>
                    Max results reached, please specify search
                </div>
            );
        },
        resultsFooter: () => {
            // TODO: fix color
            return (
                <div style={{ textAlign: 'center', color: uiCtx.isDarkmode ? 'rgb(208, 208, 208)' : 'rgb(96, 94, 92)', fontSize: '12px', lineHeight: '30ox' }}>
                    All results shown
                </div>
            );
        },
    };

    useEffect(() => {
        setSelectedPersonas(props.currentDelegatePersona);
    }, [props.concernedUser]);

    const onResolveSuggestions = (filterText: string, currentPersonas: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
        return onFilterChanged(filterText, currentPersonas, resultsMaxNumber);
    };

    const onFilterChanged = (filterText: string, currentPersonas: IPersonaProps[], limitResults?: number): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (filterText) {
            let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText);

            filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
            filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas.slice(0, resultsSuperMaxNumber);
            return filterPromise(filteredPersonas);
        } else {
            return [];
        }
    };

    const filterPersonasByText = (filterText: string): IPersonaProps[] => {
        return usersPersonas.filter(item => doesTextContain(item.text as string, filterText) || doesTextContain(item.secondaryText as string, filterText));
    };

    const filterPromise = (personasToReturn: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
        return personasToReturn;
    };

    const returnMostRecentlyUsed = (currentPersonas: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
        return filterPromise(removeDuplicates(mostRecentlyUsed, currentPersonas));
    };

    const onRemoveSuggestion = (item: IPersonaProps): void => {
        const indexPeopleList: number = usersPersonas.indexOf(item);
        const indexMostRecentlyUsed: number = mostRecentlyUsed.indexOf(item);

        if (indexPeopleList >= 0) {
            const newPeople: IPersonaProps[] = usersPersonas.slice(0, indexPeopleList).concat(usersPersonas.slice(indexPeopleList + 1));
            setUsersPersonas(newPeople);
        }

        if (indexMostRecentlyUsed >= 0) {
            const newSuggestedPeople: IPersonaProps[] = mostRecentlyUsed
                .slice(0, indexMostRecentlyUsed)
                .concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1));
            setMostRecentlyUsed(newSuggestedPeople);
        }
    };

    const onDelegateChangedPreProp = (approverAsArray: IPersonaProps[]): void => {
        setSelectedPersonas(approverAsArray);
        props.onDelegateChanged(approverAsArray, props.concernedUser);
    };

    function doesTextContain(text: string, filterText: string): boolean {
        return text.toLowerCase().indexOf(filterText.toLowerCase()) !== -1;
    }

    function removeDuplicates(personas: IPersonaProps[], possibleDupes: IPersonaProps[]) {
        return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
    }

    function listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
        if (!personas || !personas.length || personas.length === 0) {
            return false;
        }
        return personas.filter(item => item.text === persona.text).length > 0;
    }

    function convertResultsToPromise(results: IPersonaProps[]): Promise<IPersonaProps[]> {
        return new Promise<IPersonaProps[]>((resolve, reject) => setTimeout(() => resolve(results), 2000));
    }

    function getTextFromItem(persona: IPersonaProps): string {
        return persona.text as string;
    }

    function validateInput(input: string): ValidationState {
        if (input.indexOf('@') !== -1) {
            return ValidationState.valid;
        } else if (input.length > 1) {
            return ValidationState.warning;
        } else {
            return ValidationState.invalid;
        }
    }

    return (
        <CompactPeoplePicker
            styles={{ root: { width: 600 } }}
            onChange={onDelegateChangedPreProp}
            selectedItems={selectedPersonas}
            onResolveSuggestions={onResolveSuggestions}
            onGetMoreResults={onFilterChanged}
            itemLimit={90}
            onEmptyInputFocus={returnMostRecentlyUsed}
            getTextFromItem={getTextFromItem}
            pickerSuggestionsProps={suggestionProps}
            className={'ms-PeoplePicker'}
            onRemoveSuggestion={onRemoveSuggestion}
            onValidateInput={validateInput}
            inputProps={{
                'aria-label': 'People Picker',
                placeholder: 'Search here to select delegate...',
            }}
            componentRef={picker}
            resolveDelay={500}
            disabled={false}
        />
    );
}

export default DelegatePicker;
