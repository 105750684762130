import { validationRules } from '../validationRules';
import { ValidateResponse } from '../Validator';
import { TimeEntry } from '../../api/generated/data-contracts';
import User from '../../Model/User';
import { IUiContext } from '../../components/Contexts/UiContext';
import moment from 'moment';

interface OnlyAllowWithinEmploymentValdationProps {
    user: User;
    timeEntries: TimeEntry[];
    uiContext: IUiContext;
}

export const OnlyAllowWithinEmployment = (props: OnlyAllowWithinEmploymentValdationProps): ValidateResponse => {
    //TODO: Find better way
    let minDate = new Date('1900,1,1');
    let user = (props.user as any).user;

    let isStarted = true;
    let startdatestring = 'N/A';
    let enddatestring = 'N/A';
    console.debug('OnlyAllowWithinEmployment', user.start, user.end);
    let userstartdate = new Date(1900, 0, 1, 0, 0, 0);
    if (user.start != undefined && user.start != null && new Date(user.start).getFullYear() > 1900) {
        userstartdate = user.start;
        startdatestring = moment(new Date(userstartdate)).format(props.uiContext.dateformat);
    }
    let userenddate = new Date(2049, 12, 1, 0, 0, 0);

    if (user.end != undefined && user.end != null && new Date(user.end).getFullYear() > 1900 && new Date(user.end).getFullYear() < 2049) {
        userenddate = user.end;
        enddatestring = moment(new Date(userenddate)).format(props.uiContext.dateformat);
    }

    let valid = true;
    let timeentreies = props.timeEntries;
    let timeentriesoutsideemployment = timeentreies.find(
        te => te.hours != 0 && (new Date(te.date).getTime() < new Date(userstartdate).getTime() || new Date(te.date).getTime() > new Date(userenddate).getTime()),
    );
    if (timeentriesoutsideemployment != undefined && timeentriesoutsideemployment != null) {
        valid = false;
    }

    let response: ValidateResponse = {
        reason: valid ? null : `It is only allowed to report hours within your first and last working day (${startdatestring} - ${enddatestring}) `,
        valid: valid,
        name: validationRules.OnlyAllowWithinEmployment,
        rejected: !valid,
    };

    return response;
};
