//import { Stack, Text } from "@fluentui/react";
import React from "react";
// import { makeStyles } from "@fluentui/react-theme-provider";
import { Spinner, SpinnerSize, AnimationClassNames, makeStyles } from "@fluentui/react";
import { useMobile } from "../useMobile";

export const LoadingSpinner: React.FC = () => {
    const isLoading = useMobile(state => state.isLoading);
    const classes = getStyles();

    if (!isLoading.active) return null;

    return (
        <div className={`${classes.root} ${AnimationClassNames.fadeIn100} ${isLoading.overlay ? classes.overlay : ""}`}>
            <Spinner size={SpinnerSize.large} />
        </div>
    );
};

const getStyles = makeStyles(theme => ({
    root: {
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 150,
    },
    overlay: {
        backgroundColor: "rgba(0,0,0,0.25)",
    },
}));

