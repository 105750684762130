import React, { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { useUiContext } from '../../components/Contexts/UiContext';
import { ComboBox, DatePicker, DayOfWeek, DetailsList, Dialog, DialogType, IColumn, IDialogContentProps, PrimaryButton, SelectionMode, Selection, DialogFooter, DefaultButton } from '@fluentui/react';
import { Holiday } from '../../Model/NewModels/Holiday';
import { _CountryOptions, _YearOptions } from './CountryAndYeartLists';

interface propsAddFromAPIDialog {
    hidden: boolean;
    onDismiss: (ev?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
    onAddHolidaysFromDialog: (newHolidays: Holiday[]) => any;
}
function AddFromAPIDialog(props: propsAddFromAPIDialog) {
    const uiCtx = useUiContext();

    const [newHolidays, setNewHolidays] = useState<Holiday[]>([]);
    const [holidaysSelectionModel, setHolidaysSelectionModel] = useState<Selection>(new Selection({ onSelectionChanged: () => setSelectedNewHolidays(getSelectedNewHolidays()) }));
    // const [newHolidaysSelectionDetails, setNewHolidaysSelectionDetails] = useState<Holiday[]>
    const [selectedNewHolidays, setSelectedNewHolidays] = useState<Holiday[]>([]);

    const [countryBoxSelection, setCountryBoxSelection] = useState<string>(null);
    const [yearBoxSelection, setYearBoxSelection] = useState<string>(null);

    const [hideDialog, setHideDialog] = useState<boolean>(props.hidden);

    const _listColumns = [
        { key: 'name', name: 'Name', fieldName: 'name', minWidth: 150, maxWidth: 150, isResizable: true },
        { key: 'date', name: 'Date', fieldName: 'date', minWidth: 150, maxWidth: 150, isResizable: false },
    ];

    useEffect(() => {
        if (countryBoxSelection == null || countryBoxSelection == '' || yearBoxSelection == null || yearBoxSelection == '') {
            return;
        }

        uiCtx.timeApi.getHolidaysByApi(countryBoxSelection, yearBoxSelection).then((holidaysList: Holiday[]) => {
            setNewHolidays(holidaysList);
        });
    }, [countryBoxSelection, yearBoxSelection]);

    useEffect(() => {
        if (newHolidays == null || newHolidays.length < 1 || holidaysSelectionModel == null) {
            return;
        }
        holidaysSelectionModel.setAllSelected(true);
    }, [newHolidays]);

    useEffect(() => {
        setHideDialog(props.hidden);
    }, [props.hidden]);

    const getSelectedNewHolidays = (): Holiday[] => {
        return holidaysSelectionModel.getSelection() as Holiday[];
    };

    const renderItemColumn = (item: any, index: number, column: IColumn) => {
        switch (column.key) {
            case 'date':
                let fieldContDate = new Date(item.date);
                return <DatePicker disabled firstDayOfWeek={DayOfWeek.Monday} value={fieldContDate} styles={{ textField: { width: 150 } }} />;
            default:
                let fieldContDefaultString = item[column.fieldName as keyof any] as string;
                return <span>{fieldContDefaultString}</span>;
        }
    };

    return (
        <Dialog
            hidden={hideDialog}
            onDismiss={props.onDismiss}
            minWidth={300}
            maxWidth={1000}
            dialogContentProps={
                {
                    type: DialogType.largeHeader,
                    title: 'Select country and year',
                    subText: 'Then check the holidays to add to the calendar, and click "Add"',
                } as IDialogContentProps
            }
        >
            <Stack tokens={{ childrenGap: 20 }} horizontal verticalAlign="baseline">
                <Stack.Item grow={1}>
                    <ComboBox
                        options={_CountryOptions}
                        autoComplete={'on'}
                        selectedKey={countryBoxSelection}
                        onChange={(e, o, i, v) => {
                            setCountryBoxSelection(o.key as string);
                        }}
                        styles={{ optionsContainer: { width: 191 } }}
                    />
                </Stack.Item>
                <Stack.Item grow={1}>
                    <ComboBox
                        options={_YearOptions}
                        autoComplete={'on'}
                        selectedKey={yearBoxSelection}
                        onChange={(e, o, i, v) => {
                            setYearBoxSelection(o.key as string);
                        }}
                        styles={{ optionsContainer: { width: 191 } }}
                    />
                </Stack.Item>
            </Stack>

            {newHolidays.length > 1 && (
                <Stack tokens={{ childrenGap: 10 }}>
                    <DetailsList
                        compact
                        columns={_listColumns}
                        onRenderItemColumn={renderItemColumn}
                        items={newHolidays}
                        selectionMode={SelectionMode.multiple}
                        selectionPreservedOnEmptyClick={true}
                        selection={holidaysSelectionModel}
                    />
                </Stack>
            )}

            <DialogFooter>
                <PrimaryButton onClick={() => props.onAddHolidaysFromDialog(selectedNewHolidays)} text="Add" />
                <DefaultButton onClick={props.onDismiss} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
}

export default AddFromAPIDialog;
