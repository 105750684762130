import { useTheme } from '@fluentui/react';
import { mergeStyles } from '@fluentui/react';
import React from 'react';
// import { useGetStyles, makeStyles } from '../../hooks/useGetStyles';

interface IProps {
    grow?: true | number;
    noSideSpacing?: boolean;
    maxWidth?: 'medium' | 'large';
    isDetailslist?: boolean;
    noPadding?: boolean;
    noPaddingBottom?: boolean;
    noPaddingTop?: boolean;
    alignContent?: 'center' | 'flex-start' | 'flex-end' | 'stretch';
    stickyTop?: number;
    detailslistStickyHeader?: boolean;
}

export const ContentSection: React.FC<IProps> = props => {
    // export const ContentSection: React.FC<IProps> = React.forwardRef((props, ref: any) => {

    const theme = useTheme();
    // const classes = useGetStyles(styles, props);
    const growStyle = typeof props.grow === 'number' ? { flexGrow: props.grow } : {};
    const alignStyle = { alignItems: props.alignContent ? props.alignContent : 'center' };
    const stickyTopStyle: React.CSSProperties = props.stickyTop !== undefined ? { top: props.stickyTop, position: 'sticky', zIndex: 2 } : {};

    const detailslistStickyHeaderClass = mergeStyles({ '.ms-DetailsList-headerWrapper': { position: 'sticky', top: 0, zIndex: 2 } });

    return (
        <section
            className={`content_section${props.isDetailslist ? ' is_detailslist' : ' side_spacing'}${props.detailslistStickyHeader ? ` ${detailslistStickyHeaderClass}` : ''}${
                props.grow === true || props.isDetailslist ? ' grow1' : ''
            }${props.noPadding || props.noPaddingTop || props.isDetailslist ? '' : ' padding_top'}${props.noPadding || props.noPaddingBottom || props.isDetailslist ? '' : ' padding_bottom'}`}
            style={{ ...growStyle, ...stickyTopStyle, backgroundColor: theme.semanticColors.bodyBackground }}
        >
            <div className="flex_content" style={alignStyle}>
                {props.children}
            </div>
        </section>
    );
};

// const styles = makeStyles((theme, props) => ({
//     root: [
//         'content-section',
//         {
//             flexGrow: 1,
//             // flexGrow: String(props.grow && props.grow),
//             // padding: theme.spacing.m,
//             display: "flex",
//             flexDirection: "column",
//             width: '100%',
//             position: 'relative',
//             margin: "0 auto",
//             padding: props.noPadding ? 0 : props.padding ? `${props.padding}` : `${theme.spacing.m} ${theme.spacing.l2}`,
//             maxWidth: props.maxWidth && props.maxWidth,
//             maxHeight: props.maxHeight && props.maxHeight,
//         }
//     ],
// }));
