import { IBreadcrumbItem } from '@fluentui/react';
import { useCallback, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router';
import { TSettingsCategory } from '../components/Dashboard';

export const useCurrentCategory = ({ config }: { config: TSettingsCategory }) => {
    const goTo = useNavigate();
    const { pathname } = useLocation();
    const subPath = useParams();
    const allParam = subPath['*'];
    const rootPath = useMemo(() => pathname.replace(allParam, '').slice(1), [allParam, pathname]);

    const onBreadcrumbItemClicked = useCallback(
        (ev: React.MouseEvent<HTMLElement>, item: IBreadcrumbItem) => {
            ev.preventDefault();
            goTo(item.href);
        },
        [goTo],
    );
    const { breadCrumbs, category } = useMemo(() => {
        const pathParts = allParam.split('/').filter(Boolean);
        const breadCrumbs: IBreadcrumbItem[] = [
            {
                //
                key: 'Settings',
                text: 'Settings',
                href: '/' + rootPath,
                onClick: onBreadcrumbItemClicked,
            },
        ];
        const currentPathParts = ['/' + rootPath];
        let currentCategory = config;
        let found = false;

        for (let i = 0; i < pathParts.length; i++) {
            const part = pathParts[i];
            if (Array.isArray(currentCategory.children)) {
                const newConfig = currentCategory.children.find(item => item.link === part);
                if (newConfig) {
                    currentPathParts.push(newConfig.link);
                    const absoluteLink = currentPathParts.join('/').replace('//', '/');
                    breadCrumbs.push({
                        key: newConfig.link,
                        text: newConfig.title,
                        href: absoluteLink,
                        onClick: onBreadcrumbItemClicked,
                    });
                    currentCategory = newConfig;
                    if (i === pathParts.length - 1) {
                        found = true;
                    }
                }
            }
        }

        if (!found && allParam !== '') {
            currentCategory = {
                shortDescription: 'Not found',
                iconName: 'Fail',
                link: '',
                title: 'Not found',
                children: { Component: () => <div>Not found!</div> },
            };
        }

        return {
            breadCrumbs,
            category: currentCategory,
        };
    }, [allParam, config, onBreadcrumbItemClicked, rootPath]);

    return {
        breadCrumbs,
        category,
    };
};
