import * as React from 'react';
import { makeStyles } from '@fluentui/react';

interface IProps {
    children: any;
}

export const TableHeaderRow: React.FC<IProps> = props => {
    const classes = getHeaderRowClasses();

    return <div className={classes.root}>{props.children}</div>;
};

const getHeaderRowClasses = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        position: 'sticky',
        top: 0,
        zIndex: 2,
        height: 44,
        backgroundColor: theme.semanticColors.bodyBackground,
        borderBottom: `1px solid ${theme.semanticColors.variantBorder}`,
    },
}));
