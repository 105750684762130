import React, { useEffect, useState } from 'react';

import '../Styles/Delegate.scss';
import {
    Stack,
    PrimaryButton,
    Dialog,
    DialogType,
    DialogFooter,
    DefaultButton,
    DetailsListLayoutMode,
    DetailsList,
    SelectionMode,
    Selection,
    Spinner,
} from '@fluentui/react';
import Org from '../Model/Org';
import { getEmptyGuid } from './Utils';
import { useUiContext } from './Contexts/UiContext';
import { timeApi } from '../Workaround/TimeApiWorkaround';
import User from '../Model/User';
import { TimeError } from '../Model/TimeError';
import { TimeWarning } from '../Model/TimeWarning';
import { TimeUser } from '../api/generated/data-contracts';

function DelegateArea() {
    // const { TimeAPI } = useAADAuth();
    const [delegatePossible, setdelegatePossible] = useState<boolean>(false);
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);
    const [delegateUsers, setDelegateUsers] = useState<TimeUser[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<Selection>(
        new Selection({
            onSelectionChanged: () => {
                selectedPersonChange();
            },
        }),
    );
    const [originaluser, setOriginaluser] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [signinbtntext, setSigninbtntext] = useState<string>('Sign in');

    const uiCtx = useUiContext();

    useEffect(() => {
        let delegationUsers = [];

        const originalUser = uiCtx.user.originalUser;

        delegationUsers = [...uiCtx.user.delegatedUsers, originalUser].map((u: any) => {
            if (u.id === originalUser.id) {
                setOriginaluser(u);
                setSigninbtntext('Sign in as Me (' + u.name + ')');
            }
            return { ...u, activated: false };
        });
        console.debug('delgusers', delegationUsers);
        setdelegatePossible(true);
        if (delegationUsers.length > 1) {
            setdelegatePossible(true);
        } else {
            setdelegatePossible(false);
        }
        setDelegateUsers(
            delegationUsers.sort((u1, u2) => {
                if (u1.originaluser == true) {
                    return -1;
                }
                return u1.name >= u2.name ? 1 : -1;
            }),
        );
    }, []);
    const delegateChangeClick = () => {
        setDialogVisible(true);
    };
    const delegateChangeCancekClick = () => {
        setDialogVisible(false);
    };
    const delegateAsPersonClick = () => {
        const selection = selectedUsers.getSelection();

        if (selection.length <= 0) {
            setLoading(true);
            uiCtx.timeApi.activateDelegate('00000000-0000-0000-0000-000000000000').then(() => window.location.reload());
            // TimeAPI.activateDelegate(props.org.Id, originaluser.id, getEmptyGuid()).then(result => {
            //     console.debug("Delegate complete ", result);
            //     window.location.reload();
            // });
        } else {
            const selectedUser = selection[0] as any;
            if (selectedUser.principalName == undefined) {
                uiCtx.setTimeError(new TimeWarning('Not an actual user', 'This person does not exists as a user in your orginization and cannot be delegated'));
                return;
            }
            if (originaluser?.id == selectedUser.id) {
                setLoading(true);
                uiCtx.timeApi.activateDelegate('00000000-0000-0000-0000-000000000000').then(() => window.location.reload());
                // TimeAPI.activateDelegate(props.org.Id, originaluser.id, getEmptyGuid()).then(result => {
                //     console.debug("Delegate complete ", result);
                //     window.location.reload();
                // });
            } else {
                setLoading(true);
                uiCtx.timeApi.activateDelegate(selectedUser.id).then(() => {
                    window.location.reload();
                });
                // TimeAPI.activateDelegate(props.org.Id, props.user.userid, selectedUser.id).then(result => {
                //     console.debug("Delegate complete ", result);
                //     window.location.reload();
                // });
            }
        }
    };
    const selectedPersonChange = (): void => {
        const selection = selectedUsers.getSelection();
        console.log('selection', selection, uiCtx.user.originalUser);
        if (selection.length >= 1) {
            const selectedUser = selection[0] as any;
            setSigninbtntext('Sign in as ' + (selectedUser.originaluser ? 'Me (' + selectedUser.name + ')' : selectedUser.name));
        } else {
            setSigninbtntext('Sign in as Me (' + uiCtx.user.originalUser.name + ')');
        }
    };
    useEffect(() => {
        console.debug('useEffect selectedUsers', selectedUsers);
    }, [selectedUsers]);
    return (
        <>
            {delegatePossible ? (
                <div className="DelegateArea">
                    <PrimaryButton text={'' + uiCtx.user.user.name + ' (Change user)'} onClick={delegateChangeClick} />
                </div>
            ) : (
                <></>
            )}
            <Dialog
                styles={{
                    main: [
                        {
                            selectors: {
                                '@media (min-width: 480px)': {
                                    minWidth: '800px',
                                },
                            },
                        },
                    ],
                }}
                hidden={!dialogVisible}
                onDismiss={delegateChangeCancekClick}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Sign in as a delegated person',
                    closeButtonAriaLabel: 'Cancel',
                    subText: 'Use this dialog to sign in as another person. The can delegate as the following persons.',
                }}
            >
                {loading ? (
                    <Stack className="logindialog" horizontalAlign="center" verticalAlign="center">
                        <Spinner label="changing user..." />
                    </Stack>
                ) : (
                    <>
                        <DetailsList
                            items={delegateUsers}
                            columns={[{ key: 'name', name: 'Name', fieldName: 'name', minWidth: 300, maxWidth: 3000, isResizable: true }]}
                            selectionMode={SelectionMode.single}
                            setKey="set"
                            layoutMode={DetailsListLayoutMode.justified}
                            selection={selectedUsers}
                            selectionPreservedOnEmptyClick={true}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="Row checkbox"
                            onActiveItemChanged={selectedPersonChange}
                        />
                        <DialogFooter>
                            <DefaultButton onClick={delegateChangeCancekClick} text="Cancel" />
                            <PrimaryButton onClick={delegateAsPersonClick} text={signinbtntext} />
                        </DialogFooter>
                    </>
                )}
            </Dialog>
        </>
    );
}

export default DelegateArea;
