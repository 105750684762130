import { Stack, Text, DefaultButton, Modal, Icon } from '@fluentui/react';

export const ErrorModal = ({ reset, error }: { reset: () => void; error?: any }) => {
    return (
        <Modal isOpen={Boolean(error)}>
            <Stack verticalAlign="space-between" styles={{ root: { minHeight: 200, padding: 10 } }}>
                <Text variant="xLarge" styles={{ root: { marginBottom: 10 } }}>
                    <Icon iconName="Error" styles={{ root: { color: 'red' } }} /> {error?.title ?? error?.massage}
                </Text>
                <Text as="p" styles={{ root: { whiteSpace: 'pre-wrap' } }}>
                    {error?.detail}
                </Text>
                <Text>Correlation id: {error?.correlationId}</Text>
                <a href={getMailtoUrl('support@projectum.com', error)}>Report error</a>
                <Stack horizontal horizontalAlign="end" tokens={{ padding: '50px 0 0 0', childrenGap: 10 }}>
                    <DefaultButton onClick={reset}>OK</DefaultButton>
                </Stack>
            </Stack>
        </Modal>
    );
};

const getMailtoUrl = (email: string, error?: any) => {
    const body = encodeURIComponent(`Title: ${error?.title}

Correlation id: ${error?.correlationId}

Details: ${error?.detail}

Stack trace: 
${error?.stackTrace}`);
    return `mailto:${email}?subject=Teamplanner crashed&body=${body}`;
};
