import React, { FunctionComponent, useEffect, useState } from 'react';
import { DirectionalHint } from '@fluentui/react';
import { PFCoachmark } from './PFCoachmark';
import { CoachmarkInfo } from './interfaces/CoachmarkInfo';
import { Coachmark } from './enumerations/Coachmark';
import { LocalStorage } from './utils/LocalStorage';

export const CoachmarkVisibilityKey: string = 'tft-guide-seen';

export const CoachmarkGridSeenKey: string = 'tft-grid-coachmarks-seen;';

export const TFTMainCoachmarks: FunctionComponent<any> = (_) => {
    const [currentCoachmark, setCurrentCoachmark] = useState<number | null>(0); //DEFINE INITIAL COACHMARK HERE
    // const coachmarkSeen = useMemo(() => LocalStorage.Get(CoachmarkVisibilityKey) || false, []);
    const [coachmarkSeen, setCoachmarkSeen] = useState<Boolean>(() => LocalStorage.Get(CoachmarkVisibilityKey));

    let seen: Boolean = LocalStorage.Get(CoachmarkVisibilityKey);
    let gridSeen: Boolean = LocalStorage.Get(CoachmarkGridSeenKey);
    let btnPresent: boolean = document.querySelector('.cellInner:not(.hidden)') ? true : false;

    useEffect(() => {
        if (seen && (gridSeen === false || gridSeen === null) && btnPresent) {
            // LocalStorage.Set(CoachmarkGridSeenKey, true);
            setCoachmarkSeen(true);
            setCurrentCoachmark(0);
        }
    }, [seen, gridSeen, btnPresent]);

    return (
        // <>
        //     {!coachmarkSeen || currentCoachmark === null ? (
        //         <PFCoachmark SetSelectedCoachmark={setCurrentCoachmark} CoachmarkInfo={coachmarkArray[currentCoachmark]} currentCoachmark={currentCoachmark} />
        //     ) : null}
        // </>
        <>
            {!coachmarkSeen || currentCoachmark === null ? (
                <PFCoachmark
                    SetSelectedCoachmark={setCurrentCoachmark}
                    CoachmarkInfo={coachmarkArray[currentCoachmark]}
                    currentCoachmark={currentCoachmark}
                    coachmarks={coachmarkArray}
                />
            ) : seen && !gridSeen && btnPresent ? (
                <PFCoachmark
                    SetSelectedCoachmark={setCurrentCoachmark}
                    CoachmarkInfo={coachmarkGridArray[currentCoachmark]}
                    currentCoachmark={currentCoachmark}
                    coachmarks={coachmarkGridArray}
                />
            ) : null}
        </>
    );
};

export const coachmarkGridArray: CoachmarkInfo[] = [
    //PARTIAL COACHMARKARRAY USED FOR WHEN THERE'S NO ROW IN THE GRID
    {
        Type: Coachmark.GridCell,
        Target: '.cellInner:not(.hidden)',
        Item: { Headline: 'Hours', Text: 'Insert the amount of work you have done on a daily basis' },
        DirectionalHint: DirectionalHint.leftTopEdge,
    },
    {
        Type: Coachmark.GridRowTotal,
        Target: '.subRow .rowTotal',
        Item: {
            Headline: 'Period Total',
            Text: 'Enter your weekly capacity and Time will distribute the hours evenly across your workweek',
        },
        DirectionalHint: DirectionalHint.leftTopEdge,
    },
    {
        Type: Coachmark.GroupPin,
        Target: '.pinContainer',
        Item: {
            Headline: 'Pin',
            Text: 'Pin tasks that are not added automatically',
        },
        DirectionalHint: DirectionalHint.rightTopEdge,
    },
];

export const coachmarkArray: CoachmarkInfo[] = [
    //TO ADD NEW COACHMARKS, CREATE A NEW INSTANCE OF 'CoachmarkInfo' AND ADD IT TO THE SWITCH-CASE DOWN BELOW.
    //ALSO TO ADD IT TO THE 'Coachmark' ENUM AT Coachmark.tsx
    {
        Type: Coachmark.Search,
        Target: '#tft-search',
        Item: {
            Headline: 'Search',
            Text: `Click 'Search' to find- and add Projects and Tasks to the grid.`,
        },
        DirectionalHint: DirectionalHint.rightTopEdge,
    },
    ...coachmarkGridArray,
    {
        Type: Coachmark.PeriodPicker,
        Target: '#tft-periodPicker',
        Item: {
            Headline: 'Period Picker',
            Text: 'Click to change which period you want to register. Click the dates to search and select from calendar.',
        },
        DirectionalHint: DirectionalHint.bottomCenter,
    },

    {
        Type: Coachmark.Submit,
        Target: '#tft-submit',
        Item: {
            Headline: 'Submit- or Retract',
            Text: `Submit- or retract your timesheet based on your organisations validation rules.`,
        },
        DirectionalHint: DirectionalHint.bottomAutoEdge,
    },
    {
        Type: Coachmark.Approve,
        Target: '#tft-approve',
        Item: {
            Headline: 'Status',
            Text: ` The status of your timesheet will always be displayed here.`,
        },
        DirectionalHint: DirectionalHint.bottomAutoEdge,
    },
    {
        Type: Coachmark.GridUtils,
        Target: '#tft-utils',
        Item: {
            Headline: 'Utilities',
            Text: `Click to add planned, actual and remaining work. You can copy entries from the previous period.`,
        },
        DirectionalHint: DirectionalHint.rightTopEdge,
    },
    {
        Type: Coachmark.SiteNavigation,
        Target: '#tft-charts',
        Item: {
            Headline: 'Site Navigation',
            Text: 'This is where you go to find your timesheet, dashboard and additional help.',
        },
        DirectionalHint: DirectionalHint.bottomAutoEdge,
    },
];
