import { Link, Stack, Text } from '@fluentui/react';
import { TSettingsCategory } from '../components/Dashboard';
import { useState } from 'react';
import { IntegrationsV2 } from '../../_components/integrationsv2/IntegrationsV2';
import { ApprovalGridConfig } from './components/ApprovalGridConfig';

export const configurationConfig: TSettingsCategory = {
    iconName: 'Settings',
    link: '/',
    title: 'Modern Settings',
    shortDescription: '',
    description: (
        <Stack horizontalAlign="center" tokens={{ childrenGap: 10, maxWidth: 1000 }}>
            <Text variant="mediumPlus" styles={{ root: { textAlign: 'center' } }}>
                Welcome to Modern Settings, this page will over time be the new ways of workings with Settings in Time for Teams. For now this settings page
                include Integrations
            </Text>
        </Stack>
    ),
    children: [
        {
            iconName: 'PlugDisconnected',
            link: 'integrations',
            title: 'Integrations (preview)',
            shortDescription:
                'The Integration section allows for seamless connections between our platform and external applications, enhancing collaboration.',
            description: function Description() {
                const [expanded, setExpanded] = useState(false);
                return (
                    <Stack horizontalAlign="center">
                        {expanded}
                        <Text variant="mediumPlus" styles={{ root: { textAlign: 'center' } }}>
                            The Integration section serves as a vital component of our settings module, designed to create seamless connections between our
                            platform and external applications.
                        </Text>
                        <Text variant="mediumPlus" styles={{ root: { textAlign: 'center' } }}>
                            This feature enables users to effortlessly exchange data, and collaborate across multiple platforms, making it easier than ever to
                            manage resources effectively. {!expanded && <Link onClick={() => setExpanded(e => !e)}>See {expanded ? 'less..' : 'more..'}</Link>}
                        </Text>
                        {expanded && (
                            <>
                                <br />
                                <Text variant="mediumPlus" styles={{ root: { textAlign: 'center' } }}>
                                    By integrating with other applications, our platform can harness its unique functionalities and data to provide a more
                                    comprehensive and tailored resource management experience.
                                </Text>
                                <Text variant="mediumPlus" styles={{ root: { textAlign: 'center' } }}>
                                    This not only improves overall efficiency but also eliminates the need to manually transfer data or switch between multiple
                                    systems. <Link onClick={() => setExpanded(e => !e)}>See {expanded ? 'less..' : 'more..'}</Link>
                                </Text>
                            </>
                        )}
                    </Stack>
                );
            },
            children: <IntegrationsV2 />,
            // children: <div>hello</div>,
        },
        {
            title: 'Project Approval (preview)',
            link: 'project-approvals',
            shortDescription:
                'Approvals allows to setup approval settings whether is Line- or Project Manager approval. This ensure a smooth process flow from time entry to approval.',
            description: (
                <Stack horizontalAlign="center" tokens={{ childrenGap: 10, maxWidth: 1000 }}>
                    <Text variant="mediumPlus" styles={{ root: { textAlign: 'center' } }}>
                        The Approvals section is a key component of our settings module, designed to streamline the validation and approval of hours entered.
                        This feature ensures that all recorded time is accurate and accountable, involving various stakeholders like managers, project leads
                    </Text>
                </Stack>
            ),
            iconName: 'InboxCheck',
            children: <ApprovalGridConfig />,
        } as TSettingsCategory,
    ],
};
