import React, { useEffect, useState } from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { PeriodListPicker } from './PeriodListPicker';
import { PrimaryButton, Stack } from '@fluentui/react';
import moment from 'moment';
import Period from '../../../../Model/Period';
import AdminPeriodsDto from '../../../../Model/AdminPeriodsDto';
import { useUiContext } from '../../../../components/Contexts/UiContext';
import { GetMonthNameShort } from '../../../../components/Utils';
import { ContentSection, PageContent, PageHeader } from '../../../../components/LayoutElements';

const dropdownControlledExampleOptions = [
    { key: 0, text: 'Open' },
    { key: 1, text: 'Pending' },
    { key: 2, text: 'Closed' },
    { key: 3, text: 'All' },
];

interface IProps {
    allPeriods: Period[];
    allPeriodsAsAdminPeriods: AdminPeriodsDto[];
}

export const PeriodConfigOverview: React.FC<IProps> = props => {
    const uiCtx = useUiContext();

    const [selectedItemStateFilter, setSelectedItemStateFilter] = useState<IDropdownOption>();
    const [filteredPeriods, setFilteredPeriods] = useState<Period[]>([]);
    const [errors, setErrors] = useState('');
    const [allAdminPeriods, setAllAdminPeriods] = useState<AdminPeriodsDto[]>(props.allPeriodsAsAdminPeriods);

    const onChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setSelectedItemStateFilter(item);
    };

    const defaultPeriodSpan = 6;

    const addPeriod = () => {
        let newStartDate = new Date();

        if (filteredPeriods.length > 0) {
            const newestPeriod = filteredPeriods.reduce((a, b) => (new Date(a.endDate) > new Date(b.endDate) ? a : b));
            newStartDate = new Date(newestPeriod.endDate);
            newStartDate.setDate(newStartDate.getDate() + 1);
        } else {
            newStartDate.setUTCDate(newStartDate.getDate() - ((newStartDate.getDay() + 6) % 7));
        }

        const newEndDate = new Date(newStartDate);
        newEndDate.setDate(newEndDate.getDate() + defaultPeriodSpan);

        const startDateWeek = moment(newStartDate).format('W');
        const endDateWeek = moment(newEndDate).format('W');
        const isSameWeek = startDateWeek === endDateWeek;

        let name = `${newStartDate.getDate()} ${GetMonthNameShort(newStartDate.getMonth())} - ${newEndDate.getDate()} ${GetMonthNameShort(
            newEndDate.getMonth(),
        )} (W ${isSameWeek ? startDateWeek : `${startDateWeek} - ${endDateWeek}`})`;
        // const nameOld = `${newStartDate.getDate()}-${newEndDate.getDate()} ${newEndDate.toLocaleDateString('default', { month: 'short' })}`;

        //Maybe we should refactor and create the period instant? then have a activation flag or something
        const existingName = filteredPeriods.find(_ => _.name === name);
        if (existingName) {
            name += '_2';
        }

        const newPeriod = new Period('', name, newStartDate, newEndDate, 0, null);

        setFilteredPeriods(fp => [...fp, newPeriod]);
    };

    const handleChangeName = (period: Period, name: string) => {
        uiCtx.timeApi.editPeriod({ ...period, name: name }).then(v => {
            setFilteredPeriods(fp => {
                return fp.map(p => {
                    if (period.name !== p.name) {
                        return p;
                    }
                    p.name = name;
                    return p;
                });
            });
        });
    };

    const handleStartDateChange = (period: Period, date: Date) => {
        let newEndDate: Date = null;
        if (date.getTime() > period.endDate.getTime()) {
            newEndDate = new Date(date);
            newEndDate.setDate(date.getDate() + defaultPeriodSpan);
        }

        const startDateWeek = moment(date).format('W');
        const endDateWeek = moment(newEndDate || period.endDate).format('W');
        const isSameWeek = startDateWeek === endDateWeek;

        const newName = `${date.getDate()} ${GetMonthNameShort(date.getMonth())} - ${(newEndDate || period.endDate).getDate()} ${GetMonthNameShort(
            (newEndDate || period.endDate).getMonth(),
        )} (W ${isSameWeek ? startDateWeek : `${startDateWeek} - ${endDateWeek}`})`;

        setFilteredPeriods(fp => {
            return fp.map(p => {
                if (period.name != p.name) {
                    return p;
                }
                p.name = newName;
                p.startDate = date;
                if (newEndDate) {
                    p.endDate = newEndDate;
                }
                return p;
            });
        });
    };

    const handleEndDateChange = (period: Period, date: Date) => {
        let newStartDate: Date = null;
        if (period.startDate.getTime() > date.getTime()) {
            newStartDate = new Date(date);
            newStartDate.setDate(date.getDate() - defaultPeriodSpan);
        }

        const startDateWeek = moment(newStartDate || period.startDate).format('W');
        const endDateWeek = moment(date).format('W');
        const isSameWeek = startDateWeek === endDateWeek;

        const newName = `${(newStartDate || period.startDate).getDate()} ${GetMonthNameShort(
            (newStartDate || period.startDate).getMonth(),
        )} - ${date.getDate()} ${GetMonthNameShort(date.getMonth())} (W ${isSameWeek ? startDateWeek : `${startDateWeek} - ${endDateWeek}`})`;

        setFilteredPeriods(fp => {
            return fp.map(p => {
                if (period.name !== p.name) {
                    return p;
                }

                p.name = newName;
                p.endDate = date;
                if (newStartDate) {
                    p.startDate = newStartDate;
                }
                return p;
            });
        });
    };

    const handlePeriodChange = (period: Period, optionKey: number) => {
        uiCtx.timeApi.setPeriodState(period, +optionKey);
        setFilteredPeriods(fp => {
            return fp.map(p => {
                if (period.id !== p.id) {
                    return p;
                }

                p.state = optionKey;
                return p;
            });
        });
    };

    const deletePeriod = async (period: Period) => {
        let resp = { deleteState: 0 };
        if (period.id) {
            resp = await uiCtx.timeApi.deletePeriod(period);
        }
        if (resp.deleteState > 1) {
            setAllAdminPeriods(ap => {
                return ap.map(p => {
                    if (period.id !== p.id) {
                        return p;
                    }

                    p.canBeDeleted = false;
                    return p;
                });
            });
            console.debug('Could not delete period', period);
            return;
        }
        if (!period.id) {
            setFilteredPeriods(prev => [...prev.filter(p => !(p.id === period.id && p.name === period.name))]);
            console.debug('Period deleted', period);
            return;
        }

        setFilteredPeriods(prev => [...prev.filter(p => p.id !== period.id)]);
        console.debug('Period deleted', period);
    };

    const savePeriod = (period: Period) => {
        const utcStart: any = period.startDate.getISOStringMidnight();
        const utcEnd: any = period.endDate.getISOStringMidnight();
        uiCtx.timeApi.createPeriod({ ...period, startDate: utcStart, endDate: utcEnd }).then(createdPeriod => {
            if (!createdPeriod) {
                setErrors(e => e + `${period.name} Period is overlapping!`);
                return;
            }
            setFilteredPeriods(fp => {
                return fp.map(p => {
                    if (
                        new Date(p.startDate).getTime() !== new Date(period.startDate).getTime() &&
                        new Date(p.endDate).getTime() !== new Date(period.endDate).getTime()
                    ) {
                        return p;
                    }
                    p.id = createdPeriod.id;
                    return p;
                });
            });
        });
    };

    useEffect(() => {
        if (selectedItemStateFilter === undefined) {
            setSelectedItemStateFilter(dropdownControlledExampleOptions[0]);
        }
    }, []);

    useEffect(() => {
        setAllAdminPeriods(props.allPeriodsAsAdminPeriods);
        if (selectedItemStateFilter !== undefined && props.allPeriods !== undefined) {
            if (selectedItemStateFilter.key !== 3) {
                setFilteredPeriods(
                    props.allPeriods.sort((p1, p2) => (p1.startDate < p2.startDate ? 1 : -1)).filter(p => p.state === selectedItemStateFilter.key),
                );
            } else {
                setFilteredPeriods(props.allPeriods.sort((p1, p2) => (p1.startDate < p2.startDate ? 1 : -1)));
            }
        }
    }, [selectedItemStateFilter, props.allPeriods, props.allPeriodsAsAdminPeriods]);

    return (
        <>
            <PageHeader icon="Calendar" title="Period configuration" />

            {errors && <h1>Errors: {errors}</h1>}

            <PageContent>
                <ContentSection>
                    <Stack
                        horizontal
                        horizontalAlign="space-between"
                        wrap
                        tokens={{ childrenGap: 16 }}
                        styles={{ root: { width: '100%', maxWidth: 885, margin: '0 auto' } }}
                    >
                        <Stack verticalAlign="center" horizontal>
                            <PrimaryButton text="Add Period" onClick={addPeriod} />
                        </Stack>
                        <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 16 }} wrap>
                            {/* <Dropdown
                                    placeholder="Filter by date"
                                    options={[
                                        { key: TimeDate.months3, text: '3 months ago' },
                                        { key: TimeDate.months6, text: '6 months ago' },
                                        { key: TimeDate.everything, text: 'Everything' },
                                    ]}
                                    defaultSelectedKey={TimeDate.months3}
                                    styles={{ dropdown: { width: 150 } }}
                                /> */}
                            <Dropdown
                                selectedKey={selectedItemStateFilter ? selectedItemStateFilter.key : undefined}
                                onChange={onChange}
                                placeholder="Select an option"
                                options={dropdownControlledExampleOptions}
                                styles={{ dropdown: { width: 150 } }}
                            />
                        </Stack>
                    </Stack>
                </ContentSection>

                <ContentSection isDetailslist detailslistStickyHeader>
                    <PeriodListPicker
                        allPeriods={filteredPeriods}
                        allPeriodsAsAdminPeriods={allAdminPeriods}
                        savePeriod={savePeriod}
                        changePeriodState={handlePeriodChange}
                        changeStartDate={handleStartDateChange}
                        changeEndDate={handleEndDateChange}
                        changeName={handleChangeName}
                        deletePeriod={deletePeriod}
                    />
                </ContentSection>
            </PageContent>
        </>
    );
};
