import { useQuery } from '@tanstack/react-query';
import { CONFIGURATION_BASIC } from '../constants/queryKeys';
import { ApiCalls } from '../api/api';

export const useTimeUIConfig = () => {
    const { data, ...rest } = useQuery({
        queryKey: [CONFIGURATION_BASIC],
        queryFn: () => ApiCalls.configurationBasicList().then(res => res.data),
        staleTime: Infinity,
        cacheTime: Infinity,
        suspense: true,
    });
    return {
        config: data,
        ...rest,
    };
};
