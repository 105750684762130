import { HubConnectionBuilder, HubConnection } from "@microsoft/signalr";


export class DisplayLogging {
    public static onMessage: (dto: any) => void;
    private static Connection: HubConnection | null;
    
	public static Init = async (messageFn: (dtos: any) => void): Promise<void> => {
        
        if(DisplayLogging.Connection) {
            await DisplayLogging.Destroy()
        }
        var full = `${window.location.protocol}//${window.location.hostname}${(window.location.port ? ':' + window.location.port: '')}`;
        DisplayLogging.onMessage = messageFn;

        DisplayLogging.Connection = new HubConnectionBuilder()
										.withAutomaticReconnect()
										.withUrl(`${full}/signalr/debugs`)
										.build();
        
        //Ensures that the connection is held alive
        DisplayLogging.Connection.serverTimeoutInMilliseconds = 120000;
        
        DisplayLogging.Connection.on('onMessage', (dto: any) => {
            if (DisplayLogging.onMessage !== undefined) 
                {
                    //console.log("displaynot", dto);
                    DisplayLogging.onMessage(dto);
                }
        });
        
		DisplayLogging.Connection.start().then(() => {
			console.info("[DEBUG]: Listening for notifications from time api..");
		});
	}

	public static Stop = async () : Promise<void> => await DisplayLogging.Connection?.stop();
	public static Start = async () : Promise<void> => await DisplayLogging.Connection?.start();
	public static Destroy = async () : Promise<void> => {
        // await debugsHub.Connection.stop();
        // debugsHub
        await DisplayLogging.Stop();
        DisplayLogging.Connection = null;
    };
	
}