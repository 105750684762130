import React, { useEffect, useState } from 'react';
import User from '../../Model/User';
import { IPersonaProps, Persona, PersonaSize, PersonaPresence } from '@fluentui/react/lib/Persona';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import { Stack } from '@fluentui/react/lib/Stack';
import DelegatePicker from './DelegatePicker';
import { useUiContext } from '../../components/Contexts/UiContext';
import { ContentSection, PageHeader, TableHeaderRow, PageContent } from '../../components/LayoutElements';
import { DefaultButton } from '@fluentui/react';
import { GetMinimalUsersWithDelegatesDto } from '../../api/generated/data-contracts';

const personaContainerDivStyle = {
    width: '400px',
};

interface DelegatesProps {
    allUsers: GetMinimalUsersWithDelegatesDto[];
    allUsersPersonas: IPersonaProps[];
    onDelegateChanged: (approverAsArray: IPersonaProps[], concernedUser: User) => void;
}

function Delegates(props: DelegatesProps) {
    const uiCtx = useUiContext();

    const [filteredUsers, setFilteredUsers] = useState<GetMinimalUsersWithDelegatesDto[]>(props.allUsers);
    const [filterInputValue, setFilterInputValue] = useState<string>('');
    const [delegatesChanged, setDelegatesChanged] = useState<boolean>(true);
    const [filterChangedSinceDelegatesChanged, setFilterChangedSinceDelegatesChanged] = useState<boolean>(true);
    const [showCount, setShowCount] = useState(50);

    const [typingTimeout, setTypingTimeout] = useState<any>(0);
    const [searchIsError, setSearchIsError] = useState<string>('');

    useEffect(() => {
        if (!filterChangedSinceDelegatesChanged) {
            return;
        }

        const selfUser = props.allUsers.filter(_ => _.id === uiCtx.user.user.id)[0];
        const latestModifiedUsers = props.allUsers
            .filter(_ => uiCtx.latestModifiedDelegateUserIds.some(lmd => lmd === _.id && lmd !== uiCtx.user.user.id))
            .sort((a, b) => uiCtx.latestModifiedDelegateUserIds.indexOf(b.id) - uiCtx.latestModifiedDelegateUserIds.indexOf(a.id));
        let nFilteredUsers = props.allUsers.filter(_ => !latestModifiedUsers.some(lmu => lmu.id === _.id)); // Removes last modified
        latestModifiedUsers.forEach(lmu => {
            nFilteredUsers.unshift(lmu);
        });
        nFilteredUsers = nFilteredUsers.filter(_ => _.id !== uiCtx.user.user.id); // Removes self
        nFilteredUsers.unshift(selfUser);
        if (filterInputValue !== '') {
            setFilteredUsers(
                nFilteredUsers.filter(
                    (au: User) =>
                        au.name.toLowerCase().indexOf(filterInputValue.toLowerCase()) > -1 ||
                        au.userNumber?.toLowerCase().indexOf(filterInputValue.toLowerCase()) > -1,
                ),
            );
        } else {
            setFilteredUsers(nFilteredUsers);
        }

        setDelegatesChanged(false);
        setFilterChangedSinceDelegatesChanged(false);
    }, [props.allUsers]);

    const onFilterBoxChanged = (event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
        setFilterInputValue(value);
        if (delegatesChanged) {
            setFilterChangedSinceDelegatesChanged(true);
        }

        if (props.allUsers.length > 1000) {
            if (value.length < 3 && value.length > 0) {
                setSearchIsError('Type at least 3 characters');
                return;
            } else {
                setSearchIsError('');
            }
        }

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setTypingTimeout(
            setTimeout(() => {
                const selfUser = props.allUsers.filter(_ => _.id === uiCtx.user.user.id)[0];
                const latestModifiedUsers = props.allUsers
                    .filter(_ => uiCtx.latestModifiedDelegateUserIds.some(lmd => lmd === _.id && lmd !== uiCtx.user.user.id))
                    .sort((a, b) => uiCtx.latestModifiedDelegateUserIds.indexOf(b.id) - uiCtx.latestModifiedDelegateUserIds.indexOf(a.id));
                let nFilteredUsers = props.allUsers.filter(_ => !latestModifiedUsers.some(lmu => lmu.id === _.id)); // Removes last modified
                latestModifiedUsers.forEach(lmu => {
                    nFilteredUsers.unshift(lmu);
                });

                if (value === '') {
                    nFilteredUsers = nFilteredUsers.filter(_ => _.id !== uiCtx.user.user.id); // Removes self
                    nFilteredUsers.unshift(selfUser);

                    setFilteredUsers(nFilteredUsers);
                    return;
                }
                setFilteredUsers(
                    nFilteredUsers.filter(
                        (au: User) => au.name.toLowerCase().indexOf(value.toLowerCase()) > -1 || au.userNumber?.toLowerCase().indexOf(value.toLowerCase()) > -1,
                    ),
                );
            }, 750),
        );
    };

    const onDelegateChangedPreProp = (approverAsArray: IPersonaProps[], concernedUser: User): void => {
        if (filterInputValue !== '') {
            setDelegatesChanged(true);
        }
        props.onDelegateChanged(approverAsArray, concernedUser);
    };

    function createDelegatePickers(): JSX.Element[] {
        return filteredUsers.map((user: User, index: any) => {
            if (index > showCount) {
                return null;
            }

            return (
                <div style={{ display: 'inline-flex' }} key={index}>
                    <div style={personaContainerDivStyle}>
                        <Persona
                            //imageUrl={'https://outlook.office.com/owa/service.svc/s/GetPersonaPhoto?email=' + user.email + '&UA=0&size=HR48x48'}
                            imageInitials={user.name?.substring(0, 1) + (user.surname ? user.surname.substring(0, 1) : '')}
                            text={user.name}
                            secondaryText={user.userNumber ?? user.jobTitle ?? ''}
                            showSecondaryText={true}
                            size={PersonaSize.size48}
                            presence={PersonaPresence.none}
                            imageAlt={user.name + ', ' + user.jobTitle}
                            itemID={user.id}
                        />
                    </div>
                    <DelegatePicker
                        concernedUser={user}
                        // allUsers={props.allUsers}
                        allUsersPersonas={props.allUsersPersonas}
                        currentDelegatePersona={
                            !(user.delegates && user.delegates.length >= 1)
                                ? []
                                : user.delegates.map((delegateU: User) => {
                                      return {
                                          imageInitials: delegateU.name?.substring(0, 1) + delegateU.surname?.substring(0, 1),
                                          text: delegateU.name,
                                          secondaryText: delegateU.userNumber ?? delegateU.jobTitle ?? '',
                                          showSecondaryText: true,
                                          size: PersonaSize.size24,
                                          presence: PersonaPresence.none,
                                          imageAlt: delegateU.name + ', ' + delegateU.jobTitle,
                                          itemID: delegateU.id,
                                      };
                                  })
                        }
                        onDelegateChanged={onDelegateChangedPreProp}
                    />
                </div>
            );
        });
    }

    return (
        <>
            <PageHeader title="Delegate configuration" icon="PeopleRepeat" />

            <ContentSection>
                <div style={{ width: '100%', maxWidth: 700 }}>
                    <SearchBox
                        placeholder="Filter users"
                        iconProps={{ iconName: 'Filter' }}
                        onChange={onFilterBoxChanged}
                        value={filterInputValue}
                        styles={{
                            root: {
                                // TODO: fix color
                                borderColor: `${searchIsError !== '' ? 'rgba(155,42,49,0.75)' : 'rgba(208,208,208,1)'}`,
                                borderWidth: '1px',
                                '&:hover': {
                                    // TODO: fix color
                                    borderColor: `${searchIsError !== '' ? 'rgba(155,42,49,1)' : 'rgba(73,74,130,0.75)'}`,
                                },
                                '&::after': {
                                    // TODO: fix color
                                    borderColor: `${searchIsError !== '' ? 'rgba(155,42,49,1)' : 'rgba(96,97,170,1)'}`,
                                },
                            },
                        }}
                    />
                </div>
            </ContentSection>

            <PageContent>
                <ContentSection noPaddingTop>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <TableHeaderRow>
                            <div style={{ width: '400px', textAlign: 'left', paddingLeft: 8 }}>
                                <h4>User</h4>
                            </div>
                            <h5>Can work as</h5>
                        </TableHeaderRow>
                        {createDelegatePickers()}
                        {showCount < filteredUsers?.length && (
                            <DefaultButton
                                text="Show more"
                                onClick={() => setShowCount(s => s + 50)}
                                styles={{ root: { alignSelf: 'center', border: 'none' } }}
                            />
                        )}
                    </Stack>
                </ContentSection>
            </PageContent>
        </>
    );
}

export default Delegates;
