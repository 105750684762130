import { Stack, TextField, PrimaryButton } from '@fluentui/react';
import { useState } from 'react';
import { useStore } from '../../../../../store/useStore';

export const SearchFilter = () => {
    const [currentSearchText, setCurrentSearchText] = useState<string>('');

    return (
        <Stack horizontal horizontalAlign="space-between" styles={{ root: { width: '100%', margin: '0 auto' } }} tokens={{ childrenGap: 20 }}>
            <Stack.Item align="center">
                <TextField
                    label="Search Users"
                    placeholder="Search on Name, E-Mail, User Principal Name or User Number. Use minimum 3 characters"
                    onChange={(e, v) => setCurrentSearchText(v)}
                    style={{ width: 600 }}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            useStore.getState().setSearchText(currentSearchText);
                        }
                    }}
                />
            </Stack.Item>
            <Stack.Item align="end">
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <PrimaryButton
                        onClick={() => {
                            useStore.getState().setSearchText(currentSearchText);
                        }}
                        text="Search"
                        style={{ width: 50, cursor: currentSearchText.length < 3 ? 'no-drop' : 'context-menu', color: 'lightgray' }}
                        disabled={currentSearchText.length < 3}
                    />
                </Stack>
            </Stack.Item>
        </Stack>
    );
};
