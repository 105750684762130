import { PrimaryButton } from '@fluentui/react';
import { useEnvironment } from '../../Authentication/hooks/useEnvironment';

export const CopyAppId = () => {
    const {
        env: { clientId },
    } = useEnvironment();

    return (
        <PrimaryButton
            text="Copy ClientId"
            title={clientId}
            onClick={() => {
                navigator.clipboard.writeText(clientId);
            }}
        />
    );
};
