import React from "react";
import { useState, useLayoutEffect } from "react";
import { PageContent, PageHeader, ContentSection, PageWrapper } from "../../components/Layout";
import { ProjectsGrid } from "./components/ProjectsGrid/ProjectsGrid";
import { Search } from "./components/Search";
import { useMobileContext } from "../../MobileContext";
import { IProjects } from "../../mobileTypes";
import { IconButton, Stack, Text, useTheme } from "@fluentui/react";
import { Link } from "react-router-dom";
// import { useTheme } from "@fluentui/react-theme-provider";
import { useUiContext } from "../../../components/Contexts/UiContext";

export const ProjectsOverview: React.FC = () => {
    const uiCtx = useUiContext();
    const theme = useTheme();
    const mobCtx = useMobileContext();

    const [shownProjects, setShownProjects] = useState<IProjects[]>();
    const [isDefaultProjects, setIsDefaultProjects] = useState(true);

    useLayoutEffect(() => {
        if (!mobCtx.allProjects?.length) return;
        
        setShownProjects(mobCtx.allProjects);
    }, [mobCtx.allProjects]);

    return (
        <PageWrapper>
            <PageHeader>
                <Stack 
                    horizontal 
                    verticalAlign="center"
                    tokens={{ childrenGap: theme.spacing.m }}
                >
                    <Link to="/">
                        <IconButton
                            iconProps={{ iconName: 'ChevronLeft' }}
                            styles={{ 
                                root: {
                                    "i": {
                                        color: uiCtx.isTeams ? "white" : theme.palette.black,
                                        fontSize: 18,
                                    },
                                }
                            }}
                        />
                    </Link>
                    <Stack.Item grow>
                        <Search
                            shownProjects={shownProjects} 
                            setShownProjects={setShownProjects}
                            isDefaultProjects={isDefaultProjects}
                            setIsDefaultProjects={setIsDefaultProjects}
                        />
                    </Stack.Item>
                </Stack>
            </PageHeader>
            
            <PageContent>
                <ContentSection grow noPadding>
                    {!shownProjects?.length ? (
                        <Text 
                            nowrap 
                            block
                            variant="mediumPlus"
                            styles={{ root: { margin: `${theme.spacing.m} auto 0 auto` }}}
                        >
                            No items found
                        </Text>
                    ) : (
                        <ProjectsGrid projects={shownProjects} /> 
                    )}
                </ContentSection>
            </PageContent>
        </PageWrapper>
    );
};