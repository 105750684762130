import React from 'react';
import { ADLookup } from './ADLookup';
import { ConfigDictionary } from './utils';
import { Checkbox, Stack, TextField, TooltipHost } from '@fluentui/react';
interface AdminSyncADGroupProps {
    config: Record<ConfigDictionary, string>;
    setConfig: React.Dispatch<React.SetStateAction<Record<ConfigDictionary, string>>>;
}

export const AdminSyncADGroup = ({ config, setConfig }: AdminSyncADGroupProps) => {
    return (
        <>
            <Checkbox
                onChange={(e, value) =>
                    setConfig(prevState => ({
                        ...prevState,
                        ADSync_Enabled: value ? 'true' : 'false',
                        DataverseSync_Enabled: value ? prevState.DataverseSync_Enabled : 'false',
                    }))
                }
                checked={config.ADSync_Enabled === 'true'}
                label="Sync Users from azure active directory with Time"
            />
            <TooltipHost
                content={
                    <div style={{ textAlign: 'left' }}>
                        The exact AAD group name. You can find this in the name column in azure: https://portal.azure.com/#blade/Microsoft_AAD_IAM/GroupsManagementMenuBlade/AllGroups
                    </div>
                }
            >
                <Stack horizontal>
                    <Stack.Item grow={99}>
                        <TextField
                            label="Azure active directory group name"
                            placeholder="Domain Users"
                            onChange={(e, v) =>
                                setConfig(prevState => ({
                                    ...prevState,
                                    ADSyncO365_GroupName: v,
                                }))
                            }
                            value={config.ADSync_Enabled !== 'true' ? '' : config.ADSyncO365_GroupName}
                            disabled={config.ADSync_Enabled !== 'true'}
                        />
                    </Stack.Item>
                    <Stack.Item grow={1} align="end">
                        <ADLookup setConfig={setConfig} />
                    </Stack.Item>
                </Stack>
            </TooltipHost>
        </>
    );
};
