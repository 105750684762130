import React, { useState, useCallback, useRef } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';
import { useTheme } from '@fluentui/react';
import { useUiContext } from '../../components/Contexts/UiContext';
import AdminProject from './AdminProject';
import AdminProjectM from '../../Model/AdminProjectM';
import Task from '../../Model/Task';
import { DefaultButton, PrimaryButton, IDropdownOption, Spinner } from '@fluentui/react';
import { createNewGuid } from '../../components/Utils';
import AdminProjectSourcesDropdown from './AdminProjectSourcesDropdown';
import { PageHeader, PageContent, ContentSection } from '../../components/LayoutElements';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { GET_ADMIN_PROJECT_AND_TASKS_FOR_CONFIG } from '../../constants/queryKeys';

export function AdminProjects() {
    const theme = useTheme();
    const uiCtx = useUiContext();
    const [selectedProjectSource, setSelectedProjectSource] = React.useState<IDropdownOption>();
    const [loading, setLoading] = useState<boolean>(true);

    const defaultData = useRef([]).current;
    const { data: projects } = useQuery(
        [GET_ADMIN_PROJECT_AND_TASKS_FOR_CONFIG],
        async () => {
            uiCtx.setLoading(true);
            return uiCtx.timeApi.getAdminProjectsAndTasksForConfig().then((projects: AdminProjectM[]) =>
                projects.sort((a, b) => {
                    return a.order < b.order ? -1 : 1;
                }),
            );
        },
        {
            onSuccess: projects => {
                uiCtx.setLoading(false);
                setLoading(false);
                // setProjects(projects);
            },
            placeholderData: defaultData,
        },
    );

    const queryClient = useQueryClient();

    const setProjects = useCallback(
        (updater: (projects: AdminProjectM[]) => AdminProjectM[]) => {
            queryClient.setQueryData<AdminProjectM[]>([GET_ADMIN_PROJECT_AND_TASKS_FOR_CONFIG], projects => updater(projects!));
        },
        [queryClient],
    );

    const ReorderProject = useCallback(
        (p: AdminProjectM, direction: number) => {
            setProjects(projects => {
                let neworder: number = -1;

                if (direction > 0) {
                    neworder = p.order < projects.length ? p.order + 1 : projects.length;
                } else {
                    neworder = p.order > 1 ? p.order - 1 : 1;
                }

                let ordernumber: number = 1;
                for (let i = 0; i < projects.length; i++) {
                    const currentProject = projects[i];
                    if (neworder === ordernumber) {
                        ordernumber = ordernumber + 1;
                    }

                    if (currentProject.id === p.id) {
                        projects[i] = {
                            ...currentProject,
                            order: neworder,
                        };
                    } else {
                        projects[i] = {
                            ...currentProject,
                            order: ordernumber,
                        };
                        ordernumber = ordernumber + 1;
                    }
                }
                return [...projects].sort((a, b) => {
                    return a.order < b.order ? -1 : 1;
                });
            });
        },
        [setProjects],
    );

    const ProjectUpdated = useCallback(
        (id: string, name: string, closed: boolean) => {
            setProjects(projects =>
                projects
                    .map(project => {
                        if (project.id === id) {
                            return {
                                ...project,
                                name,
                                closed,
                            };
                        }
                        return project;
                    })
                    .sort((a, b) => {
                        return a.order < b.order ? -1 : 1;
                    }),
            );
        },
        [setProjects],
    );

    let TaskUpdated = useCallback(
        (tsk: Task, prj: AdminProjectM) => {
            setProjects(projects => {
                console.log('TaskUpdated', tsk);
                for (let i = 0; i < projects.length; i++) {
                    if (projects[i].id === prj.id) {
                        let newtask = projects[i].tasks.filter(t => t.id === tsk.id)[0];
                        if (newtask !== undefined || newtask !== null) {
                            newtask.name = tsk.name;
                            newtask.closed = tsk.closed;
                            newtask.order = tsk.order;
                            console.log('Task Updated', projects);
                        } else {
                            newtask = tsk;
                            projects[i].tasks.push(newtask);
                            console.log('Task created', projects);
                        }
                    }
                }
                return [...projects].sort((a, b) => {
                    return a.order < b.order ? -1 : 1;
                });
            });
        },
        [setProjects],
    );

    const saveChanges = useCallback(async () => {
        setLoading(true);
        uiCtx.setLoading(true);

        await uiCtx.timeApi.setAdminProjectsAndTasksForConfig(projects);

        if (selectedProjectSource?.key) {
            await uiCtx.timeApi.setAdminProjectsSource({ projectSourceId: selectedProjectSource.key });
        }

        console.log('saved');
        setLoading(false);
        uiCtx.setLoading(false);
    }, [projects, selectedProjectSource?.key, uiCtx]);

    const AddProjectClick = useCallback(() => {
        setProjects(projects => {
            const newproject: AdminProjectM = {
                id: createNewGuid(),
                name: 'New Project',
                closed: false,
                order: projects.length + 1,
                key: projects.length + 1,
                metaData: '',
                priority: projects.length + 1,
                tasks: [],
            };
            return [...projects, newproject];
        });
    }, [setProjects]);

    const onChange = useCallback((event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setSelectedProjectSource(item);
    }, []);

    if (loading) {
        return (
            <Stack className="logindialog" horizontalAlign="center" verticalAlign="center">
                <Spinner label="Loading..." />
            </Stack>
        );
    }

    return (
        <>
            <PageHeader title="Administrative Projects" icon="ProjectCollection" />

            <ContentSection noPaddingBottom>
                <Stack
                    horizontal
                    horizontalAlign="space-between"
                    verticalAlign="end"
                    tokens={{ childrenGap: 20 }}
                    styles={{ root: { width: '100%', maxWidth: 692, margin: '0 auto', borderBottom: `1px solid ${theme.semanticColors.variantBorder}`, padding: '0 16px 16px' } }}
                >
                    <Stack.Item align="center">
                        <AdminProjectSourcesDropdown selectedProjectSource={selectedProjectSource} callback={onChange} />
                    </Stack.Item>
                    <Stack.Item align="end">
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <PrimaryButton onClick={saveChanges} text="Save Changes" />
                            <DefaultButton
                                onClick={() => {
                                    // TODO: fix link
                                    window.location.href = window.location.href.substring(0, window.location.href.toLowerCase().indexOf('/admin') + 6);
                                }}
                                text="Cancel"
                            />
                        </Stack>
                    </Stack.Item>
                </Stack>
            </ContentSection>

            <PageContent>
                <ContentSection>
                    <Stack tokens={{ childrenGap: 10 }}>
                        {projects.map((proj, index) => (
                            <AdminProject key={index} ReorderProject={ReorderProject} Tasks={proj.tasks} ProjectUpdated={ProjectUpdated} TaskUpdated={TaskUpdated} Project={proj} />
                        ))}
                        <Separator>
                            <Stack tokens={{ childrenGap: 20 }} horizontal>
                                <h4>
                                    <DefaultButton text="Add Project" onClick={AddProjectClick}></DefaultButton>
                                </h4>
                            </Stack>
                        </Separator>
                    </Stack>
                </ContentSection>
            </PageContent>
        </>
    );
}
