import { validationRulesFunction } from "./validationRules";

export interface ValidateResponse {
    valid: boolean,
    reason: string,
    name: string,
    rejected: boolean
}

export interface RuleParams {
    [ruleName: string]: any;
}

export class Validator {

    private rules: {(...params: any): ValidateResponse}[] = [];

    validate = async (params: RuleParams) => {
        let responses: ValidateResponse[] = [];
        for (let name in this.rules) {
            
            let func = this.rules[name];
            let funcParam = params[name];
            if (funcParam === undefined) continue;
            let response = await func({...funcParam});
            responses.push(response);
        }
        return responses;
    }

    addRule = (name) => {
        this.rules[name] = validationRulesFunction[name];
    }

    clearRules = () => {
        this.rules = [];
    }

}