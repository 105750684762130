import React, { useEffect, useState } from 'react';
import { useUiContext } from '../../../components/Contexts/UiContext';
import utils, { configObj, configTypes, IConfigDictionary, IExpandedComboBoxOption, IValueData, validateForm } from '../utils/utils';
import { Assignments } from './newSections/Assignments';
import { Projects } from './newSections/Projects';
import { ProjectTeam } from './newSections/ProjectTeam';
import { Tasks } from './newSections/Tasks';
import { PrimaryButton, Spinner, Stack, Text } from '@fluentui/react';

interface IProps {
    values: IConfigDictionary;
    setValues: React.Dispatch<React.SetStateAction<IConfigDictionary>>;
    environment: string;
}

export const Sections: React.FC<IProps> = props => {
    const [tables, setTables] = useState<IExpandedComboBoxOption[]>();
    const [saving, setSaving] = useState<any>(false);
    const [errors, setErrors] = useState<any[]>([]);

    const uiCtx = useUiContext();

    //Refactor to use one state onstead of 3
    useEffect(() => {
        setTables(null);
        setErrors([]);
    }, [props.environment]);

    useEffect(() => {
        uiCtx.setLoading(false);
        utils
            .getTables(uiCtx.timeApi)
            .then(setTables)
            .catch(reason => {
                setErrors(e => [...e, reason]);
            });
    }, [props.environment]);

    useEffect(() => {
        if (!tables) {
            return;
        }
    }, [tables]);

    const addStepFunc = (key: string, table: IValueData) => {
        uiCtx.timeApi.addStep(table.key).then(() => {
            const object = configObj[key];
            uiCtx.timeApi.setConfig(object.tableName, JSON.stringify(table));
            uiCtx.timeApi.setConfig(object.enabled, 'true');
        });
    };

    const removeStepFunc = (key: string, table: IValueData) => {
        uiCtx.timeApi.removeStep(table.key).then(() => {
            const object = configObj[key];
            uiCtx.timeApi.setConfig(object.tableName, null);
            uiCtx.timeApi.setConfig(object.enabled, 'false');
        });
    };

    const saveNewValues = () => {
        setSaving(true);

        const configValues: any = [];
        Object.keys(props.values).forEach(key => {
            for (const property in props.values[key]) {
                const configKey = configObj[key][property];
                const configValue =
                    props.values[key][property] !== null
                        ? typeof props.values[key][property] === 'object'
                            ? JSON.stringify(props.values[key][property])
                            : props.values[key][property]
                        : null;
                configValues.push({ key: configKey, value: configValue });
            }
        });

        uiCtx.timeApi.setConfigList(configValues).then(() => {
            setSaving(false);
        });
    };

    const updateValue = (key: string, opt: IExpandedComboBoxOption | boolean | string, configKey: string) => {
        if (typeof opt === 'boolean' || typeof opt === 'string') {
            props.setValues(v => ({
                ...v,
                [configKey]: {
                    ...v[configKey],
                    [key]: opt,
                },
            }));
        } else {
            const value = opt === null ? null : ({ key: opt.key, name: opt.name, logicalCollectionName: opt.logicalCollectionName } as IValueData);
            props.setValues(v => ({
                ...v,
                [configKey]: {
                    ...v[configKey],
                    [key]: value,
                },
            }));
        }
    };

    const updateSteps = (opt: IExpandedComboBoxOption, configKey: string) => {
        const value = opt === null ? null : ({ key: opt.key, name: opt.name, logicalCollectionName: opt.logicalCollectionName } as IValueData);
        if (value === null) {
            removeStepFunc(configKey, props.values[configKey].tableName as IValueData);
        } else {
            addStepFunc(configKey, value);
        }
        updateValue('tableName', opt, configKey);
    };

    const isFormValid = () => {
        return validateForm(props.values);
    };

    const [formIsValid, setFormIsValid] = useState<boolean>(isFormValid);

    useEffect(() => {
        setFormIsValid(isFormValid);
    }, [props.values]);

    if (!tables && errors.length > 0) {
        return (
            <>
                {errors.map(error => (
                    <Text variant="xLarge" id={error.message}>
                        {error.message}
                    </Text>
                ))}
            </>
        );
    }

    if (!tables) {
        return (
            <>
                <Text variant="xLarge">Fetching Tables From PowerPPM</Text>
                <Spinner label="Loading..." />
            </>
        );
    }

    return (
        <Stack tokens={{ childrenGap: 20 }}>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Projects
                    setValues={props.setValues}
                    configKey={configTypes.Project}
                    tables={tables}
                    values={props.values}
                    addStepFunc={addStepFunc}
                    removeStepFunc={removeStepFunc}
                    updateSteps={updateSteps}
                    updateValue={updateValue}
                />
                <Tasks
                    setValues={props.setValues}
                    configKey={configTypes.Task}
                    tables={tables}
                    values={props.values}
                    addStepFunc={addStepFunc}
                    removeStepFunc={removeStepFunc}
                    updateSteps={updateSteps}
                    updateValue={updateValue}
                />
                <Assignments
                    setValues={props.setValues}
                    configKey={configTypes.Assignment}
                    tables={tables}
                    values={props.values}
                    addStepFunc={addStepFunc}
                    removeStepFunc={removeStepFunc}
                    updateSteps={updateSteps}
                    updateValue={updateValue}
                />
                {/* <Resources setValues={setValues} configKey={configTypes.Resource} tables={tables} values={values} enabled={true} /> */}
                <ProjectTeam
                    setValues={props.setValues}
                    configKey={configTypes.ProjectTeam}
                    tables={tables}
                    values={props.values}
                    addStepFunc={addStepFunc}
                    removeStepFunc={removeStepFunc}
                    updateSteps={updateSteps}
                    updateValue={updateValue}
                />
            </Stack>
            <PrimaryButton
                disabled={saving || !formIsValid}
                styles={{ root: { width: 200, alignSelf: 'center' } }}
                onClick={() => {
                    saveNewValues();
                }}
                text={saving ? 'Saving' : 'Save'}
            />
        </Stack>
    );
};
