/* eslint-disable react-refresh/only-export-components */
import { useMemo } from 'react';
import { createCtx } from '../../../../../components/Contexts/createCtx';
import { useTimeUIConfig } from '../../../../../hooks/useTimeUIConfig';

const GRID_COLUMN_GAP = 10;

export type GridLayoutContext = {
    gap: number;
    columnWidths: number[];
    totalWidth: number;
    gridColumnTemplate: string;
    gridRowStyles: React.CSSProperties;
};

const [useCtx, Provider] = createCtx<GridLayoutContext>();

export const useGridLayoutContext = useCtx;

export const GridLayoutContextProvider = ({ children }: { children: React.ReactNode }) => {
    const { config } = useTimeUIConfig();

    const columnWidths = useMemo(() => {
        const { useRates } = config?.approval?.projectManagerApproval || {};
        return [
            400, //
            100,
            200,
            useRates && 100,
            100,
            useRates && 200,
            200,
        ].filter(Boolean);
    }, [config?.approval?.projectManagerApproval]);

    const totalWidth = useMemo(() => columnWidths.reduce((acc, width) => acc + width + GRID_COLUMN_GAP, 0), [columnWidths]);

    const gridColumnTemplate = useMemo(() => columnWidths.reduce((acc, width) => acc + `${width}px `, ''), [columnWidths]);

    const gridRowStyles = useMemo(
        (): React.CSSProperties => ({
            display: 'grid',
            gridTemplateColumns: gridColumnTemplate,
            gap: GRID_COLUMN_GAP,
            alignItems: 'center',
        }),
        [gridColumnTemplate],
    );

    const context = useMemo((): GridLayoutContext => {
        return {
            columnWidths,
            gap: GRID_COLUMN_GAP,
            gridColumnTemplate,
            gridRowStyles,
            totalWidth,
        };
    }, [columnWidths, gridColumnTemplate, gridRowStyles, totalWidth]);

    return <Provider value={context}>{children}</Provider>;
};
