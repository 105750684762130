import { Stack, Text } from '@fluentui/react';
import { TimeForTeamsLogo } from '@pum-spark/projectum-logos-react';
import { useIsDarkmode } from '../hooks/useIsDarkmode';

export const TimeLoaderFullscreen = ({ children, text = 'Time for Teams is loading' }: { text?: React.ReactNode; children?: React.ReactNode }) => {
    const isDarkmode = useIsDarkmode();
    // const txt: React.ReactNode = useMemo(() => {
    //     if (typeof text === 'string') {
    //         if (isDarkmode) {
    //             return (
    //                 <Text styles={{ root: { color: isDarkmode && 'white' } }} variant="large">
    //                     {text}
    //                 </Text>
    //             );
    //         }
    //         return <Text variant="large">{text}</Text>;
    //     }
    //     return text;
    // }, [isDarkmode, text]);
    return (
        <Stack styles={{ root: { position: 'absolute', inset: 0, alignItems: 'center' } }}>
            <Stack>
                <TimeForTeamsLogo height={300} />
                {/* <Stack horizontalAlign="center">{txt}</Stack> */}
                <Stack horizontalAlign="center" tokens={{ childrenGap: 10 }}>
                    <Text styles={{ root: { color: isDarkmode && 'white' } }} variant="large">
                        {/* {text} */}
                        Time For Teams is loading.
                    </Text>
                    <Text styles={{ root: { color: isDarkmode && 'white' } }} variant="large">
                        {/* {text} */}
                        It will be ready any moment now.
                    </Text>
                </Stack>
                {children}
            </Stack>
        </Stack>
    );
};
