import * as React from "react";
// import { makeStyles } from "@fluentui/react-theme-provider";
import { TimeStateMessage } from '../TimeStateMessage';
import { makeStyles } from "@fluentui/react";
// import { useGetStyles, makeStyles } from '../../hooks/useGetStyles';

interface IProps {
    containerId?: string;
    // children: React.ReactElement;
};

// export const PageContent = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
export const PageContent: React.FC<IProps> = (props) => {
    const classes = getStyles();

    return (
        // <div className={`page_content${props.noSideSpacing ? " inner_side_spacing" : " side_spacing"}${props.maxWidth == "medium" ? " max_width_medium" : ""}${props.maxWidth == "large" ? " max_width_large" : ""}`}>
        <div id={props.containerId ? props.containerId : ""} className={classes.root} data-is-scrollable>
            <TimeStateMessage />
            <div className="flex_content">
                <div /*ref={ref}*/ className={classes.inner}>
                    { props.children }
                </div>
            </div>
        </div>
    )
};

// const getStyles = makeStyles(theme => ({
//     root: {
//         position: "relative",
//         flexGrow: 1,
//         display: "flex",
//         flexDirection: "column",
//         overflow: "auto",
//     },
// }));
const getStyles = makeStyles(theme => ({
    root: ["mobile-page", {
        position: "relative",
        display: "flex",
        flexGrow: "1",
        flexDirection: "column",
        overflowX: "hidden",
        // overflowY: "auto",
        width: "100%",
        height: "100%",
        overflowY: "scroll",
        
    }],
    inner: {
        // display: "flex",
        // flexDirection: "column",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: theme.semanticColors.bodyFrameBackground,
    },
    // &_inner {
    //     display: flex;
    //     flex-direction: column;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    // }
}));

// return (
//     // <div className={`page_content${props.noSideSpacing ? " inner_side_spacing" : " side_spacing"}${props.maxWidth == "medium" ? " max_width_medium" : ""}${props.maxWidth == "large" ? " max_width_large" : ""}`}>
//     <div className={`page_content${props.maxWidth == "medium" ? " max_width_medium" : ""}${props.maxWidth == "large" ? " max_width_large" : ""}`}>
//         <div className="flex_content">
//             {/* <div className={`page_content_inner${props.noSideSpacing ? "" : " side_spacing"}`} data-is-scrollable> */}
//             <div className={`page_content_inner${props.noSideSpacing ? "" : ""}`} data-is-scrollable>
//                 {/* <div style={{ flexGrow: 1 }}> */}
//                     { props.children }
//                 {/* </div> */}
//             </div>
//         </div>
//     </div>
// )