import * as React from 'react';

export function CornIcon(props) {
    return (
        <svg viewBox="0 0 50 50" width={50} height={50} {...props}>
        <g fill="#FFF">
            <circle cx={19} cy={19} r={1.6} />
            <circle cx={25} cy={19} r={1.6} />
            <circle cx={31} cy={19} r={1.6} />
            <circle cx={19} cy={25} r={1.6} />
            <circle cx={25} cy={25} r={1.6} />
            <circle cx={31} cy={25} r={1.6} />
            <circle cx={19} cy={31} r={1.6} />
            <circle cx={25} cy={31} r={1.6} />
            <circle cx={31} cy={31} r={1.6} />
        </g>
    </svg>
        // <svg width={48} height={48} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
        //     <title>{'Rectangle 5'}</title>
        //     <path d="M0 0h48v1H0z" fill="#063855" fillRule="evenodd" />
        // </svg>
    );
}

