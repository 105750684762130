import React, { useEffect, useState } from 'react';
import { useUiContext } from '../components/Contexts/UiContext';
import { DetailsList, SelectionMode, DirectionalHint, Icon, IIconStyles, Separator, Stack, Text, DatePicker, IDatePicker, DayOfWeek, Dropdown, Selection, IColumn, TextField } from '@fluentui/react';
import { CheckboxVisibility, GroupHeader, IDetailsGroupRenderProps } from '@fluentui/react';
import { DefaultButton, PrimaryButton, IconButton } from '@fluentui/react';
import { isNumber } from 'util';

interface propsPMApproveDetailslist {
    Tasksandresources: any[];
    TaskSelectionChanged: Function;
}

function PMApproveDetailslist(props: propsPMApproveDetailslist) {
    const [tasksandresources, setTasksandresources] = useState<any[]>([]);
    const [tastandresourcesGroups, setTastandresourcesGroups] = useState<any[]>([]);
    const [selectedTasks, setSelectedTasks] = useState<Selection>(
        new Selection({
            onSelectionChanged: () => {
                selectedTaskChange();
            },
        }),
    );
    let selectedTaskChange = (): void => {
        let selection = selectedTasks.getSelection();
        props.TaskSelectionChanged(selection);
    }
    const uiCtx = useUiContext();


    useEffect(() => 
    {
        debugger;
        let sortedTaskAndRes = [...props.Tasksandresources]
                .sort((a: any, b: any) => {
                    return a.task < b.task ? -1 : 1;
                });
        for (let i=0; i<sortedTaskAndRes.length; i++)
        {
            sortedTaskAndRes[i].approvehours = sortedTaskAndRes[i].hours;
            sortedTaskAndRes[i].approvedhours = 0;
            sortedTaskAndRes[i].rejectedhours = 0;
        }
        setTasksandresources(sortedTaskAndRes);
        
        
    }, [props.Tasksandresources]);
    useEffect(() => 
    {
        setTastandresourcesGroups(generateDetailListGroups(tasksandresources));
        selectedTaskChange();
    }, [tasksandresources]);
    let generateDetailListGroups = (lines: any[]): any[] => {
        debugger;
        let expandedrows = [];
        for (let i=0;i<tastandresourcesGroups.length;i++)
        {
            expandedrows.push(tastandresourcesGroups[i].isCollapsed);
                    
        }
        let grouped = [];
        if (lines.length > 0) 
        {
            let lastTaskName = lines[0].task;
            let lastTaskId = lines[0].key;
            let groupStartIndex = 0, groupCount = 0;
            let groupTotalHours = 0;
            let groupTotalPlannedHours = 0;
            let groupTotalapprovedhours = 0;
            let groupTotalapprovehours = 0;
            let groupTotalrejectedhours = 0;
            let worktypes = '';
            
            for (let i = 0; i < lines.length; i++) 
            {
                if (lastTaskName !== lines[i].task) 
                {
                    let isCollapsed =(expandedrows[grouped.length]!=undefined?expandedrows[grouped.length]:true);
                    grouped.push({ key: lastTaskId, name: lastTaskName, startIndex: groupStartIndex, count: groupCount, level: 0, isCollapsed: isCollapsed, 
                        totalhours:groupTotalHours, totalapprovehours:groupTotalapprovehours, totalplannedhours:groupTotalPlannedHours, totalapprovedhours:groupTotalapprovedhours, 
                        totalrejectedhours:groupTotalrejectedhours, worktypes:worktypes.substring(0, worktypes.length - 2) });
                    groupTotalPlannedHours = 0;
                    groupTotalHours = 0;
                    groupTotalapprovedhours = 0;
                    groupTotalapprovehours = 0;
                    groupTotalrejectedhours = 0;
                    worktypes = '';
                    lastTaskName = lines[i].task;
                    lastTaskId = lines[i].key;
                    groupCount = 0;
                    groupStartIndex = i;
                }
                groupTotalPlannedHours += lines[i].plannedhours;
                groupTotalHours += lines[i].hours;
                groupTotalapprovedhours += lines[i].approvedhours;
                debugger;
                groupTotalapprovehours += isANumber(lines[i].approvehours)?parseFloat(lines[i].approvehours):0;
                groupTotalrejectedhours += lines[i].rejectedhours;
                worktypes = worktypes  + lines[i].worktype + ", ";
                groupCount = groupCount + 1;
            }
            let isCollapsed =(expandedrows[grouped.length]!=undefined?expandedrows[grouped.length]:true);
            grouped.push({ key: lastTaskId, name: lastTaskName, startIndex: groupStartIndex, count: groupCount, level: 0, isCollapsed: isCollapsed, 
                totalhours:groupTotalHours, totalapprovehours:groupTotalapprovehours, totalplannedhours:groupTotalPlannedHours, totalapprovedhours:groupTotalapprovedhours, 
                totalrejectedhours:groupTotalrejectedhours,worktypes:worktypes.substring(0, worktypes.length - 2)  });
        }
        return grouped;
    }
    let isANumber = (number:any):any =>
    {
        if (typeof number === 'number' && isFinite(number))
        {
            return true;
        } 
        try
        {
            if (typeof parseFloat(number) === 'number')
            {
                return true;
            } else
            {
                return false;
            }
        } catch (ex)
        {
            return false;
        }
    }
    let numberToFixed = (number:any):any =>
    {
        
        let num = 0;
        if (typeof number === 'number' && isFinite(number))
        {
            num = number;
            
        } else
        {
            try
            {
                num = parseFloat(number);
            } catch (ex)
            {
                return number;
            }
        } 
        if (num != 0)
        {
            return num.toFixed(2);
        }
        return '-';
    }
    return (

        <div id='detialslistTasks' style={{cursor:'pointer' }}>
            <DetailsList
                selectionPreservedOnEmptyClick={true}
                checkboxVisibility={CheckboxVisibility.always}
                items={tasksandresources}
                groups={tastandresourcesGroups}
                selection={selectedTasks}
                columns={[
                    //{ key: 'task', name: 'Task', fieldName: 'task', minWidth: 100, maxWidth: 400, isResizable: true },
                    { key: 'user', name: 'Task->User', fieldName: 'user', minWidth: 200, maxWidth: 300, isResizable: true  },
                    { key: 'plannedhours', name: 'Planned Hours', fieldName: 'plannedhours', minWidth: 50, maxWidth: 100 , isResizable: true },
                    { key: 'worktype', name: 'Worktype(s)', fieldName: 'worktype', minWidth: 300, maxWidth: 1500, isResizable: true  },
                    { key: 'hours', name: 'New Hours', fieldName: 'hours', minWidth: 100, maxWidth: 200 , isResizable: true },
                    { key: 'approvehours', name: 'Approve/Reject', fieldName: 'approvehours', minWidth: 100, maxWidth: 100 , isResizable: true },
                    { key: 'approvebtn', name: '', fieldName: 'approvebtn', minWidth: 100, maxWidth: 100 , isResizable: true },
                    { key: 'approvedhours', name: 'Approved Hours', fieldName: 'approvedhours', minWidth: 100, maxWidth: 100 , isResizable: true },
                    { key: 'rejectedhours', name: 'Balance', fieldName: 'rejectedhours', minWidth: 100, maxWidth: 100 , isResizable: true },
                ]}
                selectionMode={SelectionMode.multiple}
                //layoutMode={DetailsListLayoutMode.fixedColumns}
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                ariaLabelForSelectionColumn="Toggle selection"
                checkButtonAriaLabel="Row checkbox"
                onRenderItemColumn={(item: any, index: number, column: IColumn)=>{
                    
                    switch (column.key) {
                        case 'approvebtn':
                            return (
                                <IconButton 
                                iconProps={{ iconName: "Accept" }}
                                title="Approve Hours"
                                ariaLabel="Approve Hours"
                                onClick={()=>{
                                    
                                    console.log("item", item);
                                    let assign = tasksandresources.find(e => e.key == item.key);
                                    let approvehours = parseFloat(assign.approvehours);
                                    assign.rejectedhours = assign.rejectedhours + approvehours - assign.hours;
                                    assign.approvedhours = assign.approvedhours + approvehours;
                                    assign.approvehours = 0;
                                    assign.hours = 0;
                                    
                                    setTasksandresources([...tasksandresources]);
                                    
                                }} />
                            );
                        //case 'rejecthours':
                        case 'approvehours':
                                return (
                                    <TextField style={{maxWidth: '100px'}} underlined value={item['approvehours']} data-attribute-id={item.key} key={item.key} 
                                    onChange={(e, v)=>
                                        {
                                            
                                            let rowid = (e as any).target.getAttribute('data-attribute-id');
                                            console.log("e,v", e, v, (e as any).target.getAttribute('data-attribute-id'));
                                            let taskres = tasksandresources.find(e => e.key == rowid);
                                            taskres.approvehours = (v);
                                            setTasksandresources([...tasksandresources]);
                                        }}
                                   >

                                        </TextField>
                                );
                        case 'rejectedhours':
                        case 'approvedhours':
                        case 'hours':
                            return <div>{numberToFixed(item[column.fieldName])}</div>;
                        default:
                            return <div>{item[column.fieldName]}</div>;
                    }
                }}
                groupProps={{
                    showEmptyGroups: true,
                    onRenderHeader: (props, defaultRender) => {
                    
                        let g = props.group as any;
                        return <GroupHeader onRenderTitle={(e,v)=>{
                            
                            return <>
                                    <div style={{width:props.columns[0].calculatedWidth, marginLeft:12, marginRight:8, fontWeight:500}}>{g.name}</div>
                                    <div style={{width:props.columns[1].calculatedWidth, marginLeft:12, marginRight:8}}>{numberToFixed(g.totalplannedhours)}</div>
                                    <div style={{width:props.columns[2].calculatedWidth, marginLeft:12, marginRight:8}}>{g.worktypes}</div>
                                    <div style={{width:props.columns[3].calculatedWidth, marginLeft:12, marginRight:8}}>{numberToFixed(g.totalhours)}</div>
                                    <div style={{width:props.columns[4].calculatedWidth, marginLeft:12, marginRight:8}}>{numberToFixed(g.totalapprovehours)}</div>
                                    <div style={{width:props.columns[5].calculatedWidth, marginLeft:12, marginRight:8}}>
                                    <IconButton 
                                        iconProps={{ iconName: "Accept" }}
                                        title="Approve Hours"
                                        ariaLabel="Approve Hours"
                                        onClick={()=>{}} />
                                    </div>
                                    <div style={{width:props.columns[4].calculatedWidth, marginLeft:12, marginRight:8}}>{numberToFixed(g.totalapprovedhours)}</div>
                                    <div style={{width:props.columns[4].calculatedWidth, marginLeft:12, marginRight:8}}>{numberToFixed(g.totalrejectedhours)}</div>
                                    
                                </>;
                        }} {...props} />;

                        }
                }}
                //onRenderItemColumn={onRenderColumn}
                compact={true}
                styles={{
                    root: {
                        //maxHeight : '40vh',
                        overflowX: 'hidden'
                    }
                }}
                
            />
        </div>
    );
}

export default PMApproveDetailslist;
