import { FC } from 'react';
import { IImportErrorProps } from '../types/timeEntryTypes';
import { DefaultButton, Label, Stack } from '@fluentui/react';
import { PageHeader, PageContent, ContentSection } from '../../../../components/LayoutElements';

export const ImportError: FC<IImportErrorProps> = ({ problemDetails, setProblemDetails, setFileData }) => {
    return (
        <>
            <PageHeader title="Time Entries Import" icon="Upload" />

            <PageContent>
                <ContentSection>
                    <Stack tokens={{ childrenGap: 32 }}>
                        <Stack>
                            <Label>Title</Label>
                            {problemDetails.title}
                        </Stack>
                        <Stack>
                            <Label>Status</Label>
                            {problemDetails.status}
                        </Stack>
                        <Stack>
                            <Label>Detail</Label>
                            {problemDetails.detail}
                        </Stack>
                        <Stack>
                            <Label>Stacktrace</Label>
                            {problemDetails.stackTrace}
                        </Stack>

                        <Stack>
                            <Label>Correlation Id</Label>
                            {problemDetails.correlationId}
                        </Stack>
                        <Stack>
                            <DefaultButton
                                onClick={() => {
                                    setFileData(undefined);
                                    setProblemDetails(undefined);
                                }}
                            >
                                Clear
                            </DefaultButton>
                        </Stack>
                    </Stack>
                </ContentSection>
            </PageContent>
        </>
    );
};
