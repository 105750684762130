// import { PublicClientApplication } from "@azure/msal-browser";
// import { queryClient } from "../_contexts/QueryProvider";
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { Api } from './generated/Api';
// import { EnvironmentOptions } from "./generated/data-contracts";

/**
 * This instance contains methods to make all calls to the time-for-teams backend.
 */
export const ApiCalls = new Api<() => Promise<string>>({
    securityWorker: async (getAccessTokenFunction?: () => Promise<string>) => {
        if (getAccessTokenFunction) {
            const response = await getAccessTokenFunction();

            const headers = {
                Authorization: `Bearer ${response}`,
                Accept: 'application/json',
            };

            return { headers };
        }
        return {};
    },
    // securityWorker: async (msalInstance?) => {
    //     if (msalInstance) {
    //         const account = msalInstance?.getActiveAccount();
    //         if (!account) {
    //             throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
    //         }
    //         const loginRequest = {
    //             // ...loginRequest,
    //             scopes: [msalInstance.getConfiguration().auth.clientId + '/.default'],
    //             account: account,
    //         };
    //         const response = await msalInstance.acquireTokenSilent(loginRequest).catch(async (error: any) => {
    //             return await msalInstance.acquireTokenPopup(loginRequest);
    //         });

    //         const headers = {
    //             Authorization: `Bearer ${response.accessToken}`,
    //             Accept: 'application/json',
    //         };

    //         return { headers };
    //     }
    //     return {};
    // },
});
// export const ApiCalls = new Api()

// async function customFetch(input: RequestInfo | URL, init?: RequestInit) {
//     // console.log("🚀 ~ customFetch ~ input:", input)
//     // if (input === `/auth/options`) {
//     //     return fetch(input, init);
//     // }

//     const msalInstance = queryClient.getQueryData<PublicClientApplication>([MSAL_INSTANCE]);
//     const account = msalInstance?.getActiveAccount();
//     if (!account) {
//         throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
//     }
//     const loginRequest = {
//         // ...loginRequest,
//         scopes: [msalInstance!.getConfiguration().auth.clientId + '/.default'],
//         account: account,
//     };
//     const response = await msalInstance!.acquireTokenSilent(loginRequest).catch(async (error: any) => {
//         return await msalInstance!.acquireTokenPopup(loginRequest);
//     });

//     const headers = {
//         Authorization: `Bearer ${response.accessToken}`,
//         Accept: 'application/json',
//     };

//     return fetch(input as any, { ...init, headers: { ...init?.headers, ...headers } });
// }
