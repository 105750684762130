import { useEffect, useState } from 'react';
import { useUiContext } from '../../components/Contexts/UiContext';
import { Environment } from './components/Environment';
import { Sections } from './components/Sections';
import utils, { IConfigDictionary } from './utils/utils';
import { FontWeights, Stack, mergeStyleSets } from '@fluentui/react';
import { PageHeader, PageContent, ContentSection } from '../../components/LayoutElements';
import { Spinner } from '@fluentui/react';
import { ApiCalls } from '../../api/api';

export const PowerPPMSync = () => {
    const [configDic, setConfigDic] = useState<IConfigDictionary>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [dataverseURL, setDataverseUrl] = useState<string>('');

    const uiCtx = useUiContext();

    useEffect(() => {
        if (configDic == null) {
            uiCtx.timeApi.getAllConfigsAdmin().then(data => {
                const dictionary = utils.GetConfigDic(data);
                setConfigDic(dictionary);
            });
        }

        if (dataverseURL === '') {
            ApiCalls.integrationDataverseDataverseIsSetupList().then(ep => {
                setDataverseUrl(ep.data);
                setLoading(false);
            });
        }

        // DO NOT SET uiCtx.timeApi as a dependency, it will cause an infinite loop
    }, [configDic]);

    if (loading || configDic === null) {
        return (
            <Stack horizontalAlign="center" verticalAlign="center">
                <Spinner label="Loading..." />
            </Stack>
        );
    }

    return (
        <>
            <PageHeader title="Power PPM Sync" icon="Calendar" />
            <PageContent>
                <Environment config={uiCtx} isDataverse={dataverseURL !== ''} setDataverseUrl={setDataverseUrl} dataverseUrl={dataverseURL} />
                <ContentSection>
                    {dataverseURL && (
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Sections environment={dataverseURL} setValues={setConfigDic} values={configDic} />
                        </Stack>
                    )}
                </ContentSection>
            </PageContent>
        </>
    );
};
