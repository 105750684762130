/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    ActiveDirectoryGroup,
    Activity,
    AddEntityMetadataParams,
    AddPowerhubServicePrincipalDTO,
    AddSecretParams,
    AdminProject,
    AggregatedUser,
    AppPackageGroup,
    ApprovalProjectapprovalGetProjectManagerApprovalsForMonthListParams,
    ApproverCellDetailLine,
    ApproverCustomFiltersRequest,
    ApproverLinesCustomPeriod,
    Calendar,
    CalendarHolidays,
    ChartsProjectsListParams,
    Config,
    CopyTime,
    CopyTimeEntriesFromPreviousUserPeriodParams,
    CreateNewConfigurationParams,
    CreateTrialDatabaseDto,
    DataverseEntityMetadata,
    DataverseIntegration,
    DataverseUrlDto,
    Delegate,
    DelegationPayload,
    DeleteParams,
    DeleteUserDto,
    DisableScheduleWebJob2Params,
    EnableScheduleWebJob2Params,
    EntityMetadataDto,
    EnvironmentOptions,
    FrequentQuestion,
    GetAdminProjectsParams,
    GetAllowedWorkTypesParams,
    GetAllPeriodsAsAdminPeriodsParams,
    GetAllProjectTypesParams,
    GetAllUsersWithApproversParams,
    GetAllUsersWithCalendarsParams,
    GetApproverLineByCustomPeriodParams,
    GetApproverLinesByManagerCustomPeriodParams,
    GetChartsParams,
    GetDelegatesParams,
    GetHolidaysByApiDTO,
    GetMinimalUsersWithDelegatesDto,
    GetPeriodsByDatesDto,
    GetProjectSourcesParams,
    GetScheduleJobPayload,
    GetSupportersParams,
    GetTimeRowsMobileParams,
    GetUserPeriodByDateParams,
    GetVideosParams,
    GuidDto,
    Guide,
    HelpFrequentQuestionsCreatePayload,
    ImportActualsCreateParams,
    ImportTimeEntryPayload,
    InitListParams,
    IntegrationCatchAllDto,
    IntegrationCreateRequest,
    IntegrationDataverseAddStepCreateParams,
    IntegrationDataverseGetStepCreateParams,
    IntegrationDataverseRemoveStepCreateParams,
    IntegrationDataverseSetDataverseUrlCreateParams,
    IntegrationDto,
    IntegrationEventUpsertRequest,
    IntegrationPowerhubDataListParams,
    IScheduleJob,
    ListEntitiesFromEnvironmentParams,
    LoginDatabaseDto,
    MakeDefaultParams,
    MetadataTable,
    MicrosoftEntraIdIntegration,
    OkObjectResult,
    OrganizationsDTO,
    OrgIdContainer,
    Period,
    PeriodCreation,
    PeriodDto,
    PFServiceBusConfiguration,
    PFServiceBusTopicOptions,
    PMChartDataProject,
    PowerAutomateFlowValidationRequest,
    PowerAutomateValidationResponse,
    PowerPPMSyncConfig,
    ProblemDetails,
    Project,
    ProjectApprovalData,
    ProjectumProblemDetails,
    QueryDataDTO,
    RegisterTimeByPeriodDayDto,
    RegisterTimeDto,
    RenameApproverCustomFilterDto,
    SearchAssignedParams,
    SearchTasksParams,
    SearchUsersParams,
    ServicebusClientTopicUpdateParams,
    ServicebusClientUpdatePayload,
    ServiceBusConnection,
    ServiceEndpointStepDTO,
    SetAdminProjectsPayload,
    SetAdminProjectsSourceDto,
    SetApproverDto,
    SetAutoApproveDto,
    SetCalendarsPayload,
    SetConfigDto,
    SetConfigListDto,
    SetCustomRateForApprovalDto,
    SetDelegatesDto,
    SetIsAdminDto,
    SetMultipleTimeEntryApprovalActivityDto,
    SetMultipleTimeEntryApprovalStateDto,
    SetPeriodStateDto,
    SetPinnedDto,
    SetSupportersPayload,
    SetTimeEntryApprovalActivityDto,
    SetTimeEntryApprovalStateDto,
    SetUserAdministrationDto,
    SetUserPeriodStateDto,
    SetVideosPayload,
    SetWorkDayStartEndDto,
    SubscriptionRuleCreation,
    SubscriptionRuleDeletion,
    Supporter,
    SyncPlannerParams,
    TenantNotFoundProblem,
    TimeConfiguration,
    TimeEntryError,
    TimeRegistrationResult,
    TimeRowMobile,
    TimeSearchRow,
    TimeUser,
    TimeUserFeatures,
    TimeVersion,
    TopicRuleContainer,
    UpgradeController,
    UpgradePackageGroup,
    UpgradePackagesPayload,
    UserRegistrationPeriod,
    UserReportingPeriod,
    UserReportingPeriodComment,
    UsersCalendarListParams,
    Video,
    WorkProjectType,
    WorkType,
    WorkTypeChangeDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags {Configurationkey}
     * @name ServicebusClientStartCreate
     * @request POST:/api/servicebus/client/{configurationKey}/start
     * @secure
     * @response `200` `void` Success
     * @response `404` `ProblemDetails` Not Found
     */
    servicebusClientStartCreate = (configurationKey: string, params: RequestParams = {}) =>
        this.request<void, ProblemDetails>({
            path: `/api/servicebus/client/${configurationKey}/start`,
            method: 'POST',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags {Configurationkey}
     * @name ServicebusClientTopicUpdate
     * @request PUT:/api/servicebus/client/{configurationKey}/topic
     * @secure
     * @response `200` `void` Success
     * @response `404` `ProblemDetails` Not Found
     */
    servicebusClientTopicUpdate = (
        { configurationKey, ...query }: ServicebusClientTopicUpdateParams,
        body: PFServiceBusTopicOptions,
        params: RequestParams = {},
    ) =>
        this.request<void, ProblemDetails>({
            path: `/api/servicebus/client/${configurationKey}/topic`,
            method: 'PUT',
            query: query,
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags {Configurationkey}
     * @name ServicebusClientEnableCreate
     * @request POST:/api/servicebus/client/{configurationKey}/enable
     * @secure
     * @response `200` `void` Success
     */
    servicebusClientEnableCreate = (configurationKey: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/servicebus/client/${configurationKey}/enable`,
            method: 'POST',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags {Configurationkey}
     * @name ServicebusClientDisableCreate
     * @request POST:/api/servicebus/client/{configurationKey}/disable
     * @secure
     * @response `200` `void` Success
     */
    servicebusClientDisableCreate = (configurationKey: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/servicebus/client/${configurationKey}/disable`,
            method: 'POST',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags {Configurationkey}
     * @name ServicebusClientRulesDetail
     * @request GET:/api/servicebus/client/{configurationKey}/rules
     * @secure
     * @response `200` `(TopicRuleContainer)[]` Success
     * @response `404` `ProblemDetails` Not Found
     */
    servicebusClientRulesDetail = (configurationKey: string, params: RequestParams = {}) =>
        this.request<TopicRuleContainer[], ProblemDetails>({
            path: `/api/servicebus/client/${configurationKey}/rules`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags {Configurationkey}
     * @name ServicebusClientRulesUpdate
     * @request PUT:/api/servicebus/client/{configurationKey}/rules
     * @secure
     * @response `200` `void` Success
     */
    servicebusClientRulesUpdate = (configurationKey: string, body: SubscriptionRuleCreation, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/servicebus/client/${configurationKey}/rules`,
            method: 'PUT',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags {Configurationkey}
     * @name ServicebusClientRulesDelete
     * @request DELETE:/api/servicebus/client/{configurationKey}/rules
     * @secure
     * @response `200` `(TopicRuleContainer)[]` Success
     */
    servicebusClientRulesDelete = (configurationKey: string, body: SubscriptionRuleDeletion, params: RequestParams = {}) =>
        this.request<TopicRuleContainer[], any>({
            path: `/api/servicebus/client/${configurationKey}/rules`,
            method: 'DELETE',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Aad
     * @name IntegrationAadEnablescheduleCreate
     * @request POST:/api/integration/aad/enableschedule
     * @secure
     * @response `200` `void` Success
     */
    integrationAadEnablescheduleCreate = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integration/aad/enableschedule`,
            method: 'POST',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags Aad
     * @name IntegrationAadSyncusersCreate
     * @request POST:/api/integration/aad/syncusers
     * @secure
     * @response `200` `void` Success
     */
    integrationAadSyncusersCreate = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integration/aad/syncusers`,
            method: 'POST',
            secure: true,
            ...params,
        });
    /**
     * @description 5881EFAE-FE4F-4C88-B66B-2B8D31D88192
     *
     * @tags Aad
     * @name IntegrationAadSyncusersscheduledCreate
     * @request POST:/api/integration/aad/syncusersscheduled
     * @secure
     * @response `200` `void` Success
     */
    integrationAadSyncusersscheduledCreate = (body: OrgIdContainer, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integration/aad/syncusersscheduled`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description 97A314D1-3600-4F6C-8BD8-C32CBFF5A46C
     *
     * @tags Aad
     * @name IntegrationAadSyncusersdeltaCreate
     * @request POST:/api/integration/aad/syncusersdelta
     * @secure
     * @response `200` `void` Success
     * @response `500` `void` Server Error
     */
    integrationAadSyncusersdeltaCreate = (body: OrgIdContainer, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/api/integration/aad/syncusersdelta`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description 0EE38DF9-49DA-411B-AC5B-CD2D5DE55B60
     *
     * @tags Admin
     * @name GetAllPeriodsAsAdminPeriods
     * @request GET:/api/admin/getAllPeriodsAsAdminPeriods
     * @secure
     * @response `200` `(Period)[]` Success
     */
    getAllPeriodsAsAdminPeriods = (query: GetAllPeriodsAsAdminPeriodsParams, params: RequestParams = {}) =>
        this.request<Period[], any>({
            path: `/api/admin/getAllPeriodsAsAdminPeriods`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description 33B2136D-237F-4FAD-B4ED-C4D126E88655
     *
     * @tags Admin
     * @name SetPeriodState
     * @request POST:/api/admin/setPeriodState
     * @secure
     * @response `200` `SetPeriodStateDto` Success
     * @response `401` `ProblemDetails` Unauthorized
     */
    setPeriodState = (body: SetPeriodStateDto, params: RequestParams = {}) =>
        this.request<SetPeriodStateDto, ProblemDetails>({
            path: `/api/admin/setPeriodState`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description 622BD474-9FE8-483B-9F84-B59A9B826DBD
     *
     * @tags Admin
     * @name SetAdminProjectsSource
     * @request POST:/api/admin/setAdminProjectsSource
     * @secure
     * @response `200` `void` Success
     */
    setAdminProjectsSource = (body: SetAdminProjectsSourceDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/admin/setAdminProjectsSource`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description 8EC3B9D2-7FCE-4BDA-886B-8EC663127A3A
     *
     * @tags Api
     * @name ConfigurationList
     * @request GET:/api/configuration
     * @secure
     * @response `200` `(Config)[]` Success
     */
    configurationList = (params: RequestParams = {}) =>
        this.request<Config[], any>({
            path: `/api/configuration`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description 11AE4FA2-D1D8-4A4A-9FC8-2261B2E6C7E8
     *
     * @tags Api
     * @name User
     * @request GET:/api/user
     * @secure
     * @response `200` `AggregatedUser` Success
     * @response `404` `ProblemDetails` Not Found
     */
    user = (params: RequestParams = {}) =>
        this.request<AggregatedUser, ProblemDetails>({
            path: `/api/user`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description 5e43b73d-ae9c-4504-af79-d0da56249097
     *
     * @tags Api
     * @name InitList
     * @request GET:/api/init
     * @secure
     * @response `200` `void` Success
     */
    initList = (query: InitListParams, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/init`,
            method: 'GET',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags Api
     * @name IntegrationEventList
     * @request GET:/api/IntegrationEvent
     * @secure
     * @response `200` `(IntegrationDto)[]` Success
     */
    integrationEventList = (params: RequestParams = {}) =>
        this.request<IntegrationDto[], any>({
            path: `/api/IntegrationEvent`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Api
     * @name IntegrationEventCreate
     * @request POST:/api/IntegrationEvent
     * @secure
     * @response `201` `IntegrationDto` Created
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    integrationEventCreate = (body: IntegrationCreateRequest, params: RequestParams = {}) =>
        this.request<IntegrationDto, ProjectumProblemDetails>({
            path: `/api/IntegrationEvent`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Api
     * @name IntegrationEventUpdate
     * @request PUT:/api/IntegrationEvent
     * @secure
     * @response `200` `IntegrationDto` Success
     * @response `404` `ProblemDetails` Not Found
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    integrationEventUpdate = (body: IntegrationEventUpsertRequest, params: RequestParams = {}) =>
        this.request<IntegrationDto, ProblemDetails | ProjectumProblemDetails>({
            path: `/api/IntegrationEvent`,
            method: 'PUT',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Api
     * @name EnvironmentList
     * @request GET:/api/environment
     * @secure
     * @response `200` `EnvironmentOptions` Success
     */
    environmentList = (params: RequestParams = {}) =>
        this.request<EnvironmentOptions, any>({
            path: `/api/environment`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Approver
     * @name GetApproverLineByCustomPeriod
     * @request GET:/api/approver/getApproverLineByCustomPeriod
     * @secure
     * @response `200` `(ApproverCellDetailLine)[]` Success
     */
    getApproverLineByCustomPeriod = (query: GetApproverLineByCustomPeriodParams, params: RequestParams = {}) =>
        this.request<ApproverCellDetailLine[], any>({
            path: `/api/approver/getApproverLineByCustomPeriod`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Approver
     * @name GetApproverLinesByManagerCustomPeriod
     * @request GET:/api/approver/getApproverLinesByManagerCustomPeriod
     * @secure
     * @response `200` `(ApproverLinesCustomPeriod)[]` Success
     */
    getApproverLinesByManagerCustomPeriod = (query: GetApproverLinesByManagerCustomPeriodParams, params: RequestParams = {}) =>
        this.request<ApproverLinesCustomPeriod[], any>({
            path: `/api/approver/getApproverLinesByManagerCustomPeriod`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description DF74FA3A-CD36-4B83-A567-8097C1CE420D
     *
     * @tags Approverfilter
     * @name GetByUser
     * @request GET:/api/approverfilter/getByUser
     * @secure
     * @response `200` `(ApproverCustomFiltersRequest)[]` Success
     * @response `400` `ProblemDetails` Bad Request
     */
    getByUser = (params: RequestParams = {}) =>
        this.request<ApproverCustomFiltersRequest[], ProblemDetails>({
            path: `/api/approverfilter/getByUser`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description 191F7BBA-C845-4BDD-85E0-33292CFABD9F
     *
     * @tags Approverfilter
     * @name SaveApprover
     * @request POST:/api/approverfilter/saveApprover
     * @secure
     * @response `200` `void` Success
     * @response `500` `void` Server Error
     */
    saveApprover = (body: ApproverCustomFiltersRequest, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/api/approverfilter/saveApprover`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description 36982898-2CA9-4B0A-BA11-CBF1C70682E6
     *
     * @tags Approverfilter
     * @name MakeDefault
     * @request POST:/api/approverfilter/setdefault
     * @secure
     * @response `200` `void` Success
     */
    makeDefault = (query: MakeDefaultParams, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/approverfilter/setdefault`,
            method: 'POST',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * @description 7BB65BB4-0AB7-4F9E-84CB-C60A51B12C6F
     *
     * @tags Approverfilter
     * @name Save
     * @request POST:/api/approverfilter/save
     * @secure
     * @response `200` `void` Success
     * @response `400` `ProblemDetails` Bad Request
     */
    save = (body: ApproverCustomFiltersRequest, params: RequestParams = {}) =>
        this.request<void, ProblemDetails>({
            path: `/api/approverfilter/save`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description 72C1D919-7CF5-4288-9472-093EE0B62780
     *
     * @tags Approverfilters
     * @name Delete
     * @request POST:/api/approverfilters/delete
     * @secure
     * @response `200` `void` Success
     * @response `404` `ProblemDetails` Not Found
     */
    delete = (query: DeleteParams, params: RequestParams = {}) =>
        this.request<void, ProblemDetails>({
            path: `/api/approverfilters/delete`,
            method: 'POST',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * @description 516AEBB0-38AB-463E-906F-465EE53C3429
     *
     * @tags Approverfilters
     * @name RenameApproverCustomFilter
     * @request POST:/api/approverfilters/rename
     * @secure
     * @response `200` `void` Success
     * @response `400` `ProblemDetails` Bad Request
     */
    renameApproverCustomFilter = (body: RenameApproverCustomFilterDto, params: RequestParams = {}) =>
        this.request<void, ProblemDetails>({
            path: `/api/approverfilters/rename`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description E8207084-C830-46DD-8458-1B31478FD547
     *
     * @tags Assignments
     * @name ChangeWorkTypeFromKey
     * @request POST:/api/users/assignments/changeWorkTypeFromKey
     * @secure
     * @response `200` `boolean` Success
     */
    changeWorkTypeFromKey = (body: WorkTypeChangeDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/users/assignments/changeWorkTypeFromKey`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description 0B114B60-9D9C-4632-A50C-9C7410C73469
     *
     * @tags Calendars
     * @name ConfigurationCalendarsHolidaysList
     * @request GET:/api/configuration/calendars/holidays
     * @secure
     * @response `200` `(CalendarHolidays)[]` Success
     */
    configurationCalendarsHolidaysList = (body: GetHolidaysByApiDTO, params: RequestParams = {}) =>
        this.request<CalendarHolidays[], any>({
            path: `/api/configuration/calendars/holidays`,
            method: 'GET',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description C9B10055-495F-409B-9B03-D312CFD92EA6
     *
     * @tags Calendars
     * @name SetCalendars
     * @request POST:/api/configuration/calendars/set
     * @secure
     * @response `200` `void` Success
     */
    setCalendars = (body: SetCalendarsPayload, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/configuration/calendars/set`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Charts
     * @name GetCharts
     * @request GET:/api/charts/getCharts
     * @secure
     * @response `200` `PMChartDataProject` Success
     */
    getCharts = (query: GetChartsParams, params: RequestParams = {}) =>
        this.request<PMChartDataProject, any>({
            path: `/api/charts/getCharts`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Charts
     * @name ChartsProjectsList
     * @request GET:/api/charts/projects
     * @secure
     * @response `200` `(Project)[]` Success
     */
    chartsProjectsList = (query: ChartsProjectsListParams, params: RequestParams = {}) =>
        this.request<Project[], any>({
            path: `/api/charts/projects`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Client
     * @name ServicebusClientUpdate
     * @request PUT:/api/servicebus/client/{configurationName}
     * @secure
     * @response `200` `(PFServiceBusConfiguration)[]` Success
     */
    servicebusClientUpdate = (configurationName: string, body: ServicebusClientUpdatePayload, params: RequestParams = {}) =>
        this.request<PFServiceBusConfiguration[], any>({
            path: `/api/servicebus/client/${configurationName}`,
            method: 'PUT',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Client
     * @name ServicebusClientDelete
     * @request DELETE:/api/servicebus/client/{configurationKey}
     * @secure
     * @response `200` `void` Success
     */
    servicebusClientDelete = (configurationKey: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/servicebus/client/${configurationKey}`,
            method: 'DELETE',
            secure: true,
            ...params,
        });
    /**
     * @description Creates a KeyVault secret containing the Service Bus configuration, based on an already configured Service Bus, using the Service Bus name
     *
     * @tags Configuration
     * @name AddSecret
     * @summary Create secret from Service Bus
     * @request POST:/api/servicebus/configuration/addSecret
     * @secure
     * @response `200` `void` Success
     * @response `500` `void` Server Error
     */
    addSecret = (query: AddSecretParams, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/api/servicebus/configuration/addSecret`,
            method: 'POST',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags Configuration
     * @name CreateNewConfiguration
     * @request POST:/api/servicebus/configuration/createConfig
     * @secure
     * @response `200` `void` Success
     */
    createNewConfiguration = (query: CreateNewConfigurationParams, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/servicebus/configuration/createConfig`,
            method: 'POST',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * @description D7BB8E69-9912-4E2F-A52E-48A6C908D7E4
     *
     * @tags Configuration
     * @name EnsureApplicationAppKey
     * @request GET:/api/configuration/ensureappinsightskey
     * @secure
     * @response `200` `void` Success
     * @response `503` `void` Server Error
     */
    ensureApplicationAppKey = (params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/api/configuration/ensureappinsightskey`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags Configuration
     * @name ConfigurationBasicList
     * @request GET:/api/configuration/basic
     * @secure
     * @response `200` `TimeUserFeatures` Success
     */
    configurationBasicList = (params: RequestParams = {}) =>
        this.request<TimeUserFeatures, any>({
            path: `/api/configuration/basic`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Configuration
     * @name ConfigurationFullList
     * @request GET:/api/configuration/full
     * @secure
     * @response `200` `TimeConfiguration` Success
     */
    configurationFullList = (params: RequestParams = {}) =>
        this.request<TimeConfiguration, any>({
            path: `/api/configuration/full`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Configuration
     * @name ConfigurationFullCreate
     * @request POST:/api/configuration/full
     * @secure
     * @response `200` `void` Success
     */
    configurationFullCreate = (body: TimeConfiguration, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/configuration/full`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description 1B555676-4C73-4E95-8665-28B7E0B503E5
     *
     * @tags Configuration
     * @name ConfigurationDetail
     * @request GET:/api/configuration/{key}
     * @secure
     * @response `200` `Config` Success
     */
    configurationDetail = (key: string, params: RequestParams = {}) =>
        this.request<Config, any>({
            path: `/api/configuration/${key}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description 1FB918CE-B94F-484F-8E9B-0DDFAA4EC9DF
     *
     * @tags Configuration
     * @name ConfigurationGetcachedList
     * @request GET:/api/configuration/getcached
     * @secure
     * @response `200` `(Config)[]` Success
     * @response `404` `TenantNotFoundProblem` Not Found
     */
    configurationGetcachedList = (params: RequestParams = {}) =>
        this.request<Config[], TenantNotFoundProblem>({
            path: `/api/configuration/getcached`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description 1C923F3F-CA48-48D7-A190-2B67C5455C0A
     *
     * @tags Configuration
     * @name ConfigurationGetPowerPpmSyncConfigList
     * @request GET:/api/configuration/getPowerPPMSyncConfig
     * @secure
     * @response `200` `PowerPPMSyncConfig` Success
     */
    configurationGetPowerPpmSyncConfigList = (params: RequestParams = {}) =>
        this.request<PowerPPMSyncConfig, any>({
            path: `/api/configuration/getPowerPPMSyncConfig`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description 98BA0105-BB22-4679-B92D-AD431ED5CBA7
     *
     * @tags Configuration
     * @name GetTimeForTeamsVersion
     * @request GET:/api/configuration/version
     * @secure
     * @response `200` `TimeVersion` Success
     */
    getTimeForTeamsVersion = (params: RequestParams = {}) =>
        this.request<TimeVersion, any>({
            path: `/api/configuration/version`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description 6F38EE89-2601-48E6-9DF3-E656A573327A
     *
     * @tags Configuration
     * @name ConfigurationUpsertListCreate
     * @request POST:/api/configuration/upsertList
     * @secure
     * @response `200` `(Config)[]` Success
     * @response `401` `ProblemDetails` Unauthorized
     */
    configurationUpsertListCreate = (body: SetConfigListDto, params: RequestParams = {}) =>
        this.request<Config[], ProblemDetails>({
            path: `/api/configuration/upsertList`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description 1AF6EAC6-F33B-4430-89D9-101D435B798B
     *
     * @tags Configuration
     * @name ConfigurationUpsertCreate
     * @request POST:/api/configuration/upsert
     * @secure
     * @response `200` `void` Success
     */
    configurationUpsertCreate = (body: SetConfigDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/configuration/upsert`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description 74B8C3EA-36CC-4ABE-8737-C73C2C162ACB
     *
     * @tags Configuration
     * @name ConfigurationUpsertDataverseConfigCreate
     * @request POST:/api/configuration/upsertDataverseConfig
     * @secure
     * @response `200` `void` Success
     */
    configurationUpsertDataverseConfigCreate = (body: DataverseUrlDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/configuration/upsertDataverseConfig`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description F370E232-6979-4F18-A5ED-E0298858610B
     *
     * @tags Configuration
     * @name ConfigurationCalendarsList
     * @request GET:/api/configuration/calendars
     * @secure
     * @response `200` `(Calendar)[]` Success
     */
    configurationCalendarsList = (params: RequestParams = {}) =>
        this.request<Calendar[], any>({
            path: `/api/configuration/calendars`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Controller
     * @name UpgradeGetController
     * @request GET:/api/framework/upgrade/controller/{upgradeController}
     * @secure
     * @response `200` `(UpgradeController)[]` Success
     */
    upgradeGetController = (upgradeController: string, params: RequestParams = {}) =>
        this.request<UpgradeController[], any>({
            path: `/api/framework/upgrade/controller/${upgradeController}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dataverse
     * @name IntegrationDataverseAddStepCreate
     * @request POST:/api/integration/dataverse/addStep
     * @secure
     * @response `200` `void` Success
     */
    integrationDataverseAddStepCreate = (query: IntegrationDataverseAddStepCreateParams, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integration/dataverse/addStep`,
            method: 'POST',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags Dataverse
     * @name IntegrationDataverseGetStepsList
     * @request GET:/api/integration/dataverse/getSteps
     * @secure
     * @response `200` `(ServiceEndpointStepDTO)[]` Success
     */
    integrationDataverseGetStepsList = (params: RequestParams = {}) =>
        this.request<ServiceEndpointStepDTO[], any>({
            path: `/api/integration/dataverse/getSteps`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dataverse
     * @name IntegrationDataverseGetStepCreate
     * @request POST:/api/integration/dataverse/getStep
     * @secure
     * @response `200` `(ServiceEndpointStepDTO)[]` Success
     */
    integrationDataverseGetStepCreate = (query: IntegrationDataverseGetStepCreateParams, params: RequestParams = {}) =>
        this.request<ServiceEndpointStepDTO[], any>({
            path: `/api/integration/dataverse/getStep`,
            method: 'POST',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dataverse
     * @name IntegrationDataverseRemoveStepCreate
     * @request POST:/api/integration/dataverse/removeStep
     * @secure
     * @response `200` `void` Success
     */
    integrationDataverseRemoveStepCreate = (query: IntegrationDataverseRemoveStepCreateParams, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integration/dataverse/removeStep`,
            method: 'POST',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags Dataverse
     * @name IntegrationDataverseRemoveStepsList
     * @request GET:/api/integration/dataverse/removeSteps
     * @secure
     * @response `200` `void` Success
     */
    integrationDataverseRemoveStepsList = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integration/dataverse/removeSteps`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags Dataverse
     * @name IntegrationDataverseDataverseIsSetupList
     * @request GET:/api/integration/dataverse/dataverseIsSetup
     * @secure
     * @response `200` `string` Success
     */
    integrationDataverseDataverseIsSetupList = (params: RequestParams = {}) =>
        this.request<string, any>({
            path: `/api/integration/dataverse/dataverseIsSetup`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dataverse
     * @name IntegrationDataverseSetDataverseUrlCreate
     * @request POST:/api/integration/dataverse/setDataverseURL
     * @secure
     * @response `200` `boolean` Success
     */
    integrationDataverseSetDataverseUrlCreate = (query: IntegrationDataverseSetDataverseUrlCreateParams, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/integration/dataverse/setDataverseURL`,
            method: 'POST',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Dataverse
     * @name IntegrationDataverseRemoveDataverseUrlCreate
     * @request POST:/api/integration/dataverse/removeDataverseURL
     * @secure
     * @response `200` `boolean` Success
     */
    integrationDataverseRemoveDataverseUrlCreate = (params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/integration/dataverse/removeDataverseURL`,
            method: 'POST',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description 802D0056-21B6-43C6-81F4-ADFBF921DBD6
     *
     * @tags Delegation
     * @name GetUsersWithDelegates
     * @request GET:/api/users/delegation/getUsersWithDelegates
     * @secure
     * @response `200` `(GetMinimalUsersWithDelegatesDto)[]` Success
     */
    getUsersWithDelegates = (params: RequestParams = {}) =>
        this.request<GetMinimalUsersWithDelegatesDto[], any>({
            path: `/api/users/delegation/getUsersWithDelegates`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description C6082518-5B06-4B20-82AB-566DB9EAAEC8
     *
     * @tags Delegation
     * @name Activate
     * @request POST:/api/users/delegation/activate
     * @secure
     * @response `200` `void` Success
     * @response `500` `void` Server Error
     */
    activate = (body: DelegationPayload, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/api/users/delegation/activate`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description 3E81881C-9515-4082-91DF-1E6A74C1F9A3
     *
     * @tags Delegation
     * @name GetDelegates
     * @request GET:/api/users/delegation/get
     * @secure
     * @response `200` `(Delegate)[]` Success
     */
    getDelegates = (query: GetDelegatesParams, params: RequestParams = {}) =>
        this.request<Delegate[], any>({
            path: `/api/users/delegation/get`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description A12C62E9-DF97-4F7C-8474-DDB330E7C42D
     *
     * @tags Delegation
     * @name SetDelegates
     * @request POST:/api/users/delegation/set
     * @secure
     * @response `200` `void` Success
     * @response `401` `ProblemDetails` Unauthorized
     */
    setDelegates = (body: SetDelegatesDto, params: RequestParams = {}) =>
        this.request<void, ProblemDetails>({
            path: `/api/users/delegation/set`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Download
     * @name DownloadMsteamsappList
     * @request GET:/api/download/msteamsapp
     * @secure
     * @response `200` `File` Success
     * @response `500` `OkObjectResult` Server Error
     */
    downloadMsteamsappList = (params: RequestParams = {}) =>
        this.request<File, OkObjectResult>({
            path: `/api/download/msteamsapp`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags Environment
     * @name GetClientId
     * @request POST:/api/environment/getclientid
     * @secure
     * @response `200` `string` Success
     */
    getClientId = (params: RequestParams = {}) =>
        this.request<string, any>({
            path: `/api/environment/getclientid`,
            method: 'POST',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Framework
     * @name UpgradeGetCurrent
     * @request GET:/api/framework/upgrade
     * @secure
     * @response `200` `(AppPackageGroup)[]` Success
     */
    upgradeGetCurrent = (params: RequestParams = {}) =>
        this.request<AppPackageGroup[], any>({
            path: `/api/framework/upgrade`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Framework
     * @name UpgradePackages
     * @request POST:/api/framework/upgrade
     * @secure
     * @response `200` `void` Success
     */
    upgradePackages = (body: UpgradePackagesPayload, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/framework/upgrade`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description 2FDEF002-445A-47DB-8AF2-92C5D9442D11
     *
     * @tags Help
     * @name FrequentQuestions
     * @request GET:/api/help/frequentQuestions
     * @secure
     * @response `200` `(FrequentQuestion)[]` Success
     */
    frequentQuestions = (params: RequestParams = {}) =>
        this.request<FrequentQuestion[], any>({
            path: `/api/help/frequentQuestions`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description 566E2519-131D-408A-9978-F911F80256C0
     *
     * @tags Help
     * @name HelpFrequentQuestionsCreate
     * @request POST:/api/help/frequentQuestions
     * @secure
     * @response `200` `void` Success
     * @response `401` `ProblemDetails` Unauthorized
     */
    helpFrequentQuestionsCreate = (body: HelpFrequentQuestionsCreatePayload, params: RequestParams = {}) =>
        this.request<void, ProblemDetails>({
            path: `/api/help/frequentQuestions`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description B75ECFE8-65D3-4F22-B821-698DB2C64590
     *
     * @tags Help
     * @name Guides
     * @request GET:/api/help/getGuides
     * @secure
     * @response `200` `(Guide)[]` Success
     */
    guides = (params: RequestParams = {}) =>
        this.request<Guide[], any>({
            path: `/api/help/getGuides`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description B406C030-6A52-444E-9389-6F4D16AD1B0E
     *
     * @tags Help
     * @name GetSupporters
     * @request GET:/api/help/getSupporters
     * @secure
     * @response `200` `(Supporter)[]` Success
     */
    getSupporters = (query: GetSupportersParams, params: RequestParams = {}) =>
        this.request<Supporter[], any>({
            path: `/api/help/getSupporters`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description 7DD4272C-BE14-490A-BF77-60DC6D067988
     *
     * @tags Help
     * @name GetVideos
     * @request GET:/api/help/getVideos
     * @secure
     * @response `200` `(Video)[]` Success
     */
    getVideos = (query: GetVideosParams, params: RequestParams = {}) =>
        this.request<Video[], any>({
            path: `/api/help/getVideos`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description C7C7354D-4B10-41CB-AEE4-1143AB1C2093
     *
     * @tags Help
     * @name SetSupporters
     * @request POST:/api/help/setSupporters
     * @secure
     * @response `200` `void` Success
     * @response `401` `ProblemDetails` Unauthorized
     */
    setSupporters = (body: SetSupportersPayload, params: RequestParams = {}) =>
        this.request<void, ProblemDetails>({
            path: `/api/help/setSupporters`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description 40C92CDD-FB5C-4161-9342-F8F7F20109C2
     *
     * @tags Help
     * @name SetVideos
     * @request POST:/api/help/setVideos
     * @secure
     * @response `200` `void` Success
     * @response `401` `ProblemDetails` Unauthorized
     */
    setVideos = (body: SetVideosPayload, params: RequestParams = {}) =>
        this.request<void, ProblemDetails>({
            path: `/api/help/setVideos`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description 7DF4F728-5F59-4532-8727-BF31820DFF02
     *
     * @tags Import
     * @name ImportActualsCreate
     * @request POST:/api/import/actuals
     * @secure
     * @response `200` `void` Success
     * @response `401` `ProblemDetails` Unauthorized
     */
    importActualsCreate = (query: ImportActualsCreateParams, params: RequestParams = {}) =>
        this.request<void, ProblemDetails>({
            path: `/api/import/actuals`,
            method: 'POST',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags Import
     * @name ImportTimeEntry
     * @request POST:/api/import/upload
     * @secure
     * @response `200` `(TimeEntryError)[]` Success
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    importTimeEntry = (data: ImportTimeEntryPayload, params: RequestParams = {}) =>
        this.request<TimeEntryError[], ProjectumProblemDetails>({
            path: `/api/import/upload`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.FormData,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Initialize
     * @name ServicebusClientInitializeCreate
     * @request POST:/api/servicebus/client/{configurationKey}/initialize/{configurationName}
     * @secure
     * @response `200` `void` Success
     */
    servicebusClientInitializeCreate = (configurationKey: string, configurationName: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/servicebus/client/${configurationKey}/initialize/${configurationName}`,
            method: 'POST',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags Integrationevent
     * @name IntegrationEventDetail
     * @request GET:/api/IntegrationEvent/{id}
     * @secure
     * @response `200` `IntegrationDto` Success
     * @response `404` `ProblemDetails` Not Found
     */
    integrationEventDetail = (id: string, params: RequestParams = {}) =>
        this.request<IntegrationDto, ProblemDetails>({
            path: `/api/IntegrationEvent/${id}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Integrationevent
     * @name IntegrationEventDelete
     * @request DELETE:/api/IntegrationEvent/{id}
     * @secure
     * @response `204` `void` No Content
     * @response `404` `ProblemDetails` Not Found
     * @response `500` `ProjectumProblemDetails` Server Error
     */
    integrationEventDelete = (id: string, params: RequestParams = {}) =>
        this.request<void, ProblemDetails | ProjectumProblemDetails>({
            path: `/api/IntegrationEvent/${id}`,
            method: 'DELETE',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags Integrationevent
     * @name IntegrationEventDataverseIntegrationTypeList
     * @request GET:/api/IntegrationEvent/dataverseIntegrationType
     * @secure
     * @response `200` `DataverseIntegration` Success
     */
    integrationEventDataverseIntegrationTypeList = (params: RequestParams = {}) =>
        this.request<DataverseIntegration, any>({
            path: `/api/IntegrationEvent/dataverseIntegrationType`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Integrationevent
     * @name IntegrationEventMicrosoftEntraIdIntegrationTypeList
     * @request GET:/api/IntegrationEvent/microsoftEntraIdIntegrationType
     * @secure
     * @response `200` `MicrosoftEntraIdIntegration` Success
     */
    integrationEventMicrosoftEntraIdIntegrationTypeList = (params: RequestParams = {}) =>
        this.request<MicrosoftEntraIdIntegration, any>({
            path: `/api/IntegrationEvent/microsoftEntraIdIntegrationType`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Integrationeventtype
     * @name SynchronizationIntegrationeventtypeGetCreate
     * @request POST:/api/synchronization/integrationeventtype/get
     * @secure
     * @response `200` `IntegrationCatchAllDto` Success
     * @response `401` `ProblemDetails` Unauthorized
     */
    synchronizationIntegrationeventtypeGetCreate = (params: RequestParams = {}) =>
        this.request<IntegrationCatchAllDto, ProblemDetails>({
            path: `/api/synchronization/integrationeventtype/get`,
            method: 'POST',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Mobile
     * @name GetTimeRowsMobile
     * @request GET:/api/mobile/rows
     * @secure
     * @response `200` `(TimeRowMobile)[]` Success
     */
    getTimeRowsMobile = (query: GetTimeRowsMobileParams, params: RequestParams = {}) =>
        this.request<TimeRowMobile[], any>({
            path: `/api/mobile/rows`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Packages
     * @name UpgradeGetPackages
     * @request GET:/api/framework/upgrade/packages/{releaseCycle}
     * @secure
     * @response `200` `(UpgradePackageGroup)[]` Success
     */
    upgradeGetPackages = (releaseCycle: any, params: RequestParams = {}) =>
        this.request<UpgradePackageGroup[], any>({
            path: `/api/framework/upgrade/packages/${releaseCycle}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description E3D28DBD-0908-45BA-8AF0-F3B9608C5A1F
     *
     * @tags Periods
     * @name CreatePeriod
     * @request POST:/api/periods/create
     * @secure
     * @response `200` `Period` Success
     * @response `401` `ProblemDetails` Unauthorized
     */
    createPeriod = (body: PeriodCreation, params: RequestParams = {}) =>
        this.request<Period, ProblemDetails>({
            path: `/api/periods/create`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description 576EBF12-7DA7-4D3F-A26C-5E5AE4B42AE4
     *
     * @tags Periods
     * @name DeletePeriod
     * @request POST:/api/periods/deletePeriod
     * @secure
     * @response `200` `void` Success
     */
    deletePeriod = (body: PeriodDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/periods/deletePeriod`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description BAA3320E-E1C2-4406-9C69-C65993330AE9
     *
     * @tags Periods
     * @name EditPeriod
     * @request POST:/api/periods/edit
     * @secure
     * @response `200` `Period` Success
     * @response `401` `ProblemDetails` Unauthorized
     */
    editPeriod = (body: Period, params: RequestParams = {}) =>
        this.request<Period, ProblemDetails>({
            path: `/api/periods/edit`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description 1CEF1C80-010A-4CB5-859B-4DB4CB0CEE7E
     *
     * @tags Periods
     * @name GetAllPeriods
     * @request GET:/api/periods/getAll
     * @secure
     * @response `200` `(Period)[]` Success
     */
    getAllPeriods = (params: RequestParams = {}) =>
        this.request<Period[], any>({
            path: `/api/periods/getAll`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description F0C1C757-C416-4B00-8752-AECF7947243B
     *
     * @tags Periods
     * @name GetPeriodsByDates
     * @request GET:/api/periods/getByDates
     * @secure
     * @response `200` `(Period)[]` Success
     */
    getPeriodsByDates = (body: GetPeriodsByDatesDto, params: RequestParams = {}) =>
        this.request<Period[], any>({
            path: `/api/periods/getByDates`,
            method: 'GET',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Plugin
     * @name PluginGlobalCreate
     * @request POST:/api/plugin/global
     * @secure
     * @response `200` `void` Success
     */
    pluginGlobalCreate = (body: CreateTrialDatabaseDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/plugin/global`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Powerapps
     * @name AddEntityMetadata
     * @request POST:/api/powerapps/entitymetadata
     * @secure
     * @response `200` `(EntityMetadataDto)[]` Success
     */
    addEntityMetadata = (query: AddEntityMetadataParams, params: RequestParams = {}) =>
        this.request<EntityMetadataDto[], any>({
            path: `/api/powerapps/entitymetadata`,
            method: 'POST',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Powerapps
     * @name ListEntitiesFromEnvironment
     * @request GET:/api/powerapps/listentities
     * @secure
     * @response `200` `(DataverseEntityMetadata)[]` Success
     */
    listEntitiesFromEnvironment = (query: ListEntitiesFromEnvironmentParams, params: RequestParams = {}) =>
        this.request<DataverseEntityMetadata[], any>({
            path: `/api/powerapps/listentities`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Powerhub
     * @name IntegrationPowerhubDataList
     * @request GET:/api/integration/powerhub/data
     * @secure
     * @response `200` `(any)[]` Success
     */
    integrationPowerhubDataList = (query: IntegrationPowerhubDataListParams, params: RequestParams = {}) =>
        this.request<any[], any>({
            path: `/api/integration/powerhub/data`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Powerhub
     * @name IntegrationPowerhubDataCreate
     * @request POST:/api/integration/powerhub/data
     * @secure
     * @response `200` `(any)[]` Success
     */
    integrationPowerhubDataCreate = (body: QueryDataDTO, params: RequestParams = {}) =>
        this.request<any[], any>({
            path: `/api/integration/powerhub/data`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Powerhub
     * @name IntegrationPowerhubUpsertPowerhubServicePrincipalCreate
     * @request POST:/api/integration/powerhub/upsertPowerhubServicePrincipal
     * @secure
     * @response `200` `void` Success
     */
    integrationPowerhubUpsertPowerhubServicePrincipalCreate = (body: AddPowerhubServicePrincipalDTO, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integration/powerhub/upsertPowerhubServicePrincipal`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Powerhub
     * @name IntegrationPowerhubGetPowerhubServicePrincipalList
     * @request GET:/api/integration/powerhub/getPowerhubServicePrincipal
     * @secure
     * @response `200` `TimeUser` Success
     */
    integrationPowerhubGetPowerhubServicePrincipalList = (params: RequestParams = {}) =>
        this.request<TimeUser, any>({
            path: `/api/integration/powerhub/getPowerhubServicePrincipal`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Powerhub
     * @name IntegrationPowerhubRemovePowerhubServicePrincipalList
     * @request GET:/api/integration/powerhub/removePowerhubServicePrincipal
     * @secure
     * @response `200` `void` Success
     */
    integrationPowerhubRemovePowerhubServicePrincipalList = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integration/powerhub/removePowerhubServicePrincipal`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags Powerhub
     * @name IntegrationPowerhubEnableCreate
     * @request POST:/api/integration/powerhub/enable
     * @secure
     * @response `200` `void` Success
     */
    integrationPowerhubEnableCreate = (body: ServiceBusConnection, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integration/powerhub/enable`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Powerhub
     * @name IntegrationPowerhubDisableList
     * @request GET:/api/integration/powerhub/disable
     * @secure
     * @response `200` `void` Success
     */
    integrationPowerhubDisableList = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integration/powerhub/disable`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags Powerhub
     * @name IntegrationPowerhubDisableCreate
     * @request POST:/api/integration/powerhub/disable
     * @secure
     * @response `200` `void` Success
     */
    integrationPowerhubDisableCreate = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/integration/powerhub/disable`,
            method: 'POST',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags Powerhub
     * @name IntegrationPowerhubMetadataList
     * @request GET:/api/integration/powerhub/metadata
     * @secure
     * @response `200` `(MetadataTable)[]` Success
     */
    integrationPowerhubMetadataList = (params: RequestParams = {}) =>
        this.request<MetadataTable[], any>({
            path: `/api/integration/powerhub/metadata`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Powerhub
     * @name IntegrationPowerhubEntitiesList
     * @request GET:/api/integration/powerhub/entities
     * @secure
     * @response `200` `(string)[]` Success
     */
    integrationPowerhubEntitiesList = (params: RequestParams = {}) =>
        this.request<string[], any>({
            path: `/api/integration/powerhub/entities`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Powerhub
     * @name IntegrationPowerhubOrganizationsList
     * @request GET:/api/integration/powerhub/organizations
     * @secure
     * @response `200` `(OrganizationsDTO)[]` Success
     */
    integrationPowerhubOrganizationsList = (params: RequestParams = {}) =>
        this.request<OrganizationsDTO[], any>({
            path: `/api/integration/powerhub/organizations`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Projectapproval
     * @name ApprovalProjectapprovalGetActivitiesList
     * @request GET:/api/approval/projectapproval/getActivities
     * @secure
     * @response `200` `(Activity)[]` Success
     */
    approvalProjectapprovalGetActivitiesList = (params: RequestParams = {}) =>
        this.request<Activity[], any>({
            path: `/api/approval/projectapproval/getActivities`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Projectapproval
     * @name ApprovalProjectapprovalGetProjectManagerApprovalsForMonthList
     * @request GET:/api/approval/projectapproval/getProjectManagerApprovalsForMonth
     * @secure
     * @response `200` `ProjectApprovalData` Success
     */
    approvalProjectapprovalGetProjectManagerApprovalsForMonthList = (
        query: ApprovalProjectapprovalGetProjectManagerApprovalsForMonthListParams,
        params: RequestParams = {},
    ) =>
        this.request<ProjectApprovalData, any>({
            path: `/api/approval/projectapproval/getProjectManagerApprovalsForMonth`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Projectapproval
     * @name SetCustomRateForApproval
     * @request POST:/api/approval/projectapproval/setCustomRate
     * @secure
     * @response `200` `void` Success
     * @response `401` `ProblemDetails` Unauthorized
     * @response `404` `ProblemDetails` Not Found
     */
    setCustomRateForApproval = (body: SetCustomRateForApprovalDto, params: RequestParams = {}) =>
        this.request<void, ProblemDetails>({
            path: `/api/approval/projectapproval/setCustomRate`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Projectapproval
     * @name SetActivity
     * @request POST:/api/approval/projectapproval/setActivity
     * @secure
     * @response `200` `void` Success
     * @response `401` `ProblemDetails` Unauthorized
     */
    setActivity = (body: SetTimeEntryApprovalActivityDto, params: RequestParams = {}) =>
        this.request<void, ProblemDetails>({
            path: `/api/approval/projectapproval/setActivity`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Projectapproval
     * @name SetMultipleActivity
     * @request POST:/api/approval/projectapproval/setMultipleActivity
     * @secure
     * @response `200` `void` Success
     * @response `401` `ProblemDetails` Unauthorized
     */
    setMultipleActivity = (body: SetMultipleTimeEntryApprovalActivityDto, params: RequestParams = {}) =>
        this.request<void, ProblemDetails>({
            path: `/api/approval/projectapproval/setMultipleActivity`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Projectapproval
     * @name SetApprovalState
     * @request POST:/api/approval/projectapproval/setState
     * @secure
     * @response `200` `void` Success
     * @response `401` `ProblemDetails` Unauthorized
     * @response `404` `ProblemDetails` Not Found
     */
    setApprovalState = (body: SetTimeEntryApprovalStateDto, params: RequestParams = {}) =>
        this.request<void, ProblemDetails>({
            path: `/api/approval/projectapproval/setState`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Projectapproval
     * @name SetMultipleApprovalState
     * @request POST:/api/approval/projectapproval/setMultipleState
     * @secure
     * @response `200` `void` Success
     * @response `401` `ProblemDetails` Unauthorized
     * @response `404` `ProblemDetails` Not Found
     */
    setMultipleApprovalState = (body: SetMultipleTimeEntryApprovalStateDto, params: RequestParams = {}) =>
        this.request<void, ProblemDetails>({
            path: `/api/approval/projectapproval/setMultipleState`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description 084B4CEE-D993-4360-B8E9-4502CA307693
     *
     * @tags Projects
     * @name GetAdminProjects
     * @request GET:/api/projects/getAdminProjects
     * @secure
     * @response `200` `(AdminProject)[]` Success
     */
    getAdminProjects = (query: GetAdminProjectsParams, params: RequestParams = {}) =>
        this.request<AdminProject[], any>({
            path: `/api/projects/getAdminProjects`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description 192E2A52-B637-4897-B90F-AA5045A102DF
     *
     * @tags Projects
     * @name GetAllProjectTypes
     * @request GET:/api/projects/types
     * @secure
     * @response `200` `(WorkProjectType)[]` Success
     */
    getAllProjectTypes = (query: GetAllProjectTypesParams, params: RequestParams = {}) =>
        this.request<WorkProjectType[], any>({
            path: `/api/projects/types`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description 5236835F-E12D-4334-8362-6B7E31A279E7
     *
     * @tags Projects
     * @name GetProjectSources
     * @request GET:/api/projects/getProjectSources
     * @secure
     * @response `200` `void` Success
     */
    getProjectSources = (query: GetProjectSourcesParams, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/projects/getProjectSources`,
            method: 'GET',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * @description C6FED049-1AFE-40F8-A8FC-813816A2D0E0
     *
     * @tags Registrations
     * @name RegisterTimeEntry
     * @request POST:/api/registrations/register
     * @secure
     * @response `200` `TimeRegistrationResult` Success
     */
    registerTimeEntry = (body: RegisterTimeDto, params: RequestParams = {}) =>
        this.request<TimeRegistrationResult, any>({
            path: `/api/registrations/register`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description 634943AA-6A30-4EC5-8134-3465A1D07A17
     *
     * @tags Registrations
     * @name RegisterTimeByPeriodDays
     * @request POST:/api/registrations/registertimebyperioddays
     * @secure
     * @response `200` `(string)[]` Success
     */
    registerTimeByPeriodDays = (body: RegisterTimeByPeriodDayDto, params: RequestParams = {}) =>
        this.request<string[], any>({
            path: `/api/registrations/registertimebyperioddays`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description AB8BF533-DB05-4C14-A998-B993D88D7DEA
     *
     * @tags Registrations
     * @name GetRowsByUserReportingPeriodId
     * @request POST:/api/registrations/rows
     * @secure
     * @response `200` `(UserRegistrationPeriod)[]` Success
     */
    getRowsByUserReportingPeriodId = (body: GuidDto, params: RequestParams = {}) =>
        this.request<UserRegistrationPeriod[], any>({
            path: `/api/registrations/rows`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Releasenotes
     * @name VersioningReleasenotesDetail
     * @request GET:/api/versioning/releasenotes/{version}
     * @secure
     * @response `200` `void` Success
     */
    versioningReleasenotesDetail = (version: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/versioning/releasenotes/${version}`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags Schedules
     * @name CronValidator
     * @request POST:/api/schedules/cronvalidator
     * @secure
     * @response `200` `void` Success
     */
    cronValidator = (body: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/schedules/cronvalidator`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Schedules
     * @name DeleteScheduleJob
     * @request POST:/api/schedules/deleteschedulejob
     * @secure
     * @response `200` `void` Success
     */
    deleteScheduleJob = (body: IScheduleJob, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/schedules/deleteschedulejob`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Schedules
     * @name DisableScheduleWebJob
     * @request GET:/api/schedules/disableschedulewebjob
     * @secure
     * @response `200` `void` Success
     */
    disableScheduleWebJob = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/schedules/disableschedulewebjob`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags Schedules
     * @name EnableScheduleWebJob
     * @request POST:/api/schedules/enableschedulewebjob
     * @secure
     * @response `200` `void` Success
     */
    enableScheduleWebJob = (body: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/schedules/enableschedulewebjob`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Schedules
     * @name GetScheduleJob
     * @request POST:/api/schedules/getschedulejob
     * @secure
     * @response `200` `void` Success
     */
    getScheduleJob = (body: GetScheduleJobPayload, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/schedules/getschedulejob`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Schedules
     * @name ScheduleJobExecutor
     * @request GET:/api/schedules/schedulejobexecutor
     * @secure
     * @response `200` `void` Success
     */
    scheduleJobExecutor = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/schedules/schedulejobexecutor`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags Schedules
     * @name UpsertScheduleJob
     * @request POST:/api/schedules/upsertschedulejob
     * @secure
     * @response `200` `void` Success
     */
    upsertScheduleJob = (body: IScheduleJob, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/schedules/upsertschedulejob`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Search
     * @name GetDefault
     * @request GET:/api/search/getDefault
     * @secure
     * @response `200` `(TimeSearchRow)[]` Success
     */
    getDefault = (params: RequestParams = {}) =>
        this.request<TimeSearchRow[], any>({
            path: `/api/search/getDefault`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Search
     * @name SearchTasks
     * @request GET:/api/search/tasks
     * @secure
     * @response `200` `(TimeSearchRow)[]` Success
     */
    searchTasks = (query: SearchTasksParams, params: RequestParams = {}) =>
        this.request<TimeSearchRow[], any>({
            path: `/api/search/tasks`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Search
     * @name SearchAssigned
     * @request GET:/api/search/assigned
     * @secure
     * @response `200` `(TimeSearchRow)[]` Success
     */
    searchAssigned = (query: SearchAssignedParams, params: RequestParams = {}) =>
        this.request<TimeSearchRow[], any>({
            path: `/api/search/assigned`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Servicebus
     * @name ServicebusClientList
     * @request GET:/api/servicebus/client
     * @secure
     * @response `200` `(PFServiceBusConfiguration)[]` Success
     */
    servicebusClientList = (params: RequestParams = {}) =>
        this.request<PFServiceBusConfiguration[], any>({
            path: `/api/servicebus/client`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Servicebus
     * @name ServicebusInitializeCreate
     * @request POST:/api/servicebus/initialize
     * @secure
     * @response `200` `void` Success
     */
    servicebusInitializeCreate = (body: string, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/servicebus/initialize`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Servicebus
     * @name ServicebusStartCreate
     * @request POST:/api/servicebus/start
     * @secure
     * @response `200` `void` Success
     */
    servicebusStartCreate = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/servicebus/start`,
            method: 'POST',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags Sync
     * @name DisableScheduleWebJob2
     * @request POST:/api/sync/disableschedulewebjob
     * @originalName disableScheduleWebJob
     * @duplicate
     * @secure
     * @response `200` `boolean` Success
     */
    disableScheduleWebJob2 = (query: DisableScheduleWebJob2Params, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/sync/disableschedulewebjob`,
            method: 'POST',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Sync
     * @name EnableScheduleWebJob2
     * @request POST:/api/sync/enableschedulewebjob
     * @originalName enableScheduleWebJob
     * @duplicate
     * @secure
     * @response `200` `boolean` Success
     */
    enableScheduleWebJob2 = (query: EnableScheduleWebJob2Params, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/sync/enableschedulewebjob`,
            method: 'POST',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Sync
     * @name SyncPlanner
     * @request GET:/api/sync/planner
     * @secure
     * @response `200` `(string)[]` Success
     */
    syncPlanner = (query: SyncPlannerParams, params: RequestParams = {}) =>
        this.request<string[], any>({
            path: `/api/sync/planner`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Sync
     * @name GetAdGroups
     * @request GET:/api/sync/getadgroups
     * @secure
     * @response `200` `(ActiveDirectoryGroup)[]` Success
     */
    getAdGroups = (params: RequestParams = {}) =>
        this.request<ActiveDirectoryGroup[], any>({
            path: `/api/sync/getadgroups`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description 7D7621D7-950D-4F3D-A9B5-D476AE262B96
     *
     * @tags Tasks
     * @name PinForUser
     * @request POST:/api/tasks/setpinned
     * @secure
     * @response `200` `void` Success
     */
    pinForUser = (body: SetPinnedDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/tasks/setpinned`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Telemetry
     * @name LogApplicationUsage
     * @request POST:/api/telemetry/logapplicationusage
     * @secure
     * @response `200` `void` Success
     */
    logApplicationUsage = (body: OrgIdContainer, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/telemetry/logapplicationusage`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Telemetry
     * @name LogLicenseUsage
     * @request POST:/api/telemetry/loglicenseusage
     * @secure
     * @response `200` `void` Success
     */
    logLicenseUsage = (params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/telemetry/loglicenseusage`,
            method: 'POST',
            secure: true,
            ...params,
        });
    /**
     * @description 17165C8B-D431-4474-B47B-E94F35BCEF04
     *
     * @tags Trial
     * @name CreateDemoDataForTrial
     * @request POST:/api/trial/loaddemodata
     * @secure
     * @response `200` `void` Success
     */
    createDemoDataForTrial = (body: LoginDatabaseDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/trial/loaddemodata`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Upgrade
     * @name UpgradeControllers
     * @request GET:/api/framework/upgrade/controller
     * @secure
     * @response `200` `(UpgradeController)[]` Success
     */
    upgradeControllers = (params: RequestParams = {}) =>
        this.request<UpgradeController[], any>({
            path: `/api/framework/upgrade/controller`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description E1C1D3E4-3A7C-44B6-9446-E7DA9E660D12
     *
     * @tags Userperiod
     * @name GetUserPeriodByDate
     * @request POST:/api/users/userperiod/getByDate
     * @secure
     * @response `200` `UserReportingPeriod` Success
     */
    getUserPeriodByDate = (query: GetUserPeriodByDateParams, params: RequestParams = {}) =>
        this.request<UserReportingPeriod, any>({
            path: `/api/users/userperiod/getByDate`,
            method: 'POST',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description 43511ABB-C735-4E86-8D7B-DFFBCD9E089B
     *
     * @tags Userperiods
     * @name AddComment
     * @request POST:/api/users/userperiods/addComment
     * @secure
     * @response `200` `void` Success
     */
    addComment = (body: UserReportingPeriodComment, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/users/userperiods/addComment`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Userperiods
     * @name CopyTimeEntriesFromExistingUserPeriod
     * @request POST:/api/users/userperiods/copyfromuserperiod
     * @secure
     * @response `200` `(UserRegistrationPeriod)[]` Success
     */
    copyTimeEntriesFromExistingUserPeriod = (body: CopyTime, params: RequestParams = {}) =>
        this.request<UserRegistrationPeriod[], any>({
            path: `/api/users/userperiods/copyfromuserperiod`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Userperiods
     * @name CopyTimeEntriesFromPreviousUserPeriod
     * @request POST:/api/users/userperiods/copyfromprevious
     * @secure
     * @response `200` `(UserRegistrationPeriod)[]` Success
     */
    copyTimeEntriesFromPreviousUserPeriod = (query: CopyTimeEntriesFromPreviousUserPeriodParams, params: RequestParams = {}) =>
        this.request<UserRegistrationPeriod[], any>({
            path: `/api/users/userperiods/copyfromprevious`,
            method: 'POST',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description 8FE0378D-6AF2-4D86-957D-0C4F9A039541
     *
     * @tags Userperiods
     * @name SetReportingPeriodState
     * @request POST:/api/users/userperiods/setState
     * @secure
     * @response `200` `void` Success
     * @response `401` `ProblemDetails` Unauthorized
     */
    setReportingPeriodState = (body: SetUserPeriodStateDto, params: RequestParams = {}) =>
        this.request<void, ProblemDetails>({
            path: `/api/users/userperiods/setState`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description B5B49EE4-5E18-4A9B-B166-8B2320B6FCA9
     *
     * @tags Userperiods
     * @name SetWorkday
     * @request POST:/api/users/userperiods/setworkday
     * @secure
     * @response `200` `void` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `401` `ProblemDetails` Unauthorized
     */
    setWorkday = (body: SetWorkDayStartEndDto, params: RequestParams = {}) =>
        this.request<void, ProblemDetails>({
            path: `/api/users/userperiods/setworkday`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description 1C0DAE6B-FF82-4F12-8BAF-72B386F8E747
     *
     * @tags Users
     * @name SetAdminProjects
     * @request POST:/api/users/setAdminProjects
     * @secure
     * @response `200` `void` Success
     */
    setAdminProjects = (body: SetAdminProjectsPayload, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/users/setAdminProjects`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description CBC6E25C-09E6-4C92-AC32-C1FAD1D8A2A5
     *
     * @tags Users
     * @name DeleteUser
     * @request POST:/api/users/delete
     * @secure
     * @response `200` `boolean` Success
     */
    deleteUser = (body: DeleteUserDto, params: RequestParams = {}) =>
        this.request<boolean, any>({
            path: `/api/users/delete`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description 25713FC3-08F9-41E0-9F6B-83B43B22F52D
     *
     * @tags Users
     * @name GetAllUsers
     * @request GET:/api/users/getall
     * @secure
     * @response `200` `(TimeUser)[]` Success
     */
    getAllUsers = (params: RequestParams = {}) =>
        this.request<TimeUser[], any>({
            path: `/api/users/getall`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description 344F4894-E86A-4AE9-BDF1-5828526C33E7
     *
     * @tags Users
     * @name GetUsersByApprover
     * @request GET:/api/users/byapprover
     * @secure
     * @response `200` `(TimeUser)[]` Success
     * @response `500` `void` Server Error
     */
    getUsersByApprover = (params: RequestParams = {}) =>
        this.request<TimeUser[], void>({
            path: `/api/users/byapprover`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name SearchUsers
     * @request GET:/api/users/searchUsers
     * @secure
     * @response `200` `(TimeUser)[]` Success
     * @response `401` `ProblemDetails` Unauthorized
     */
    searchUsers = (query: SearchUsersParams, params: RequestParams = {}) =>
        this.request<TimeUser[], ProblemDetails>({
            path: `/api/users/searchUsers`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description E62E6D59-CD7A-474A-A4C8-A846DB729EE2
     *
     * @tags Users
     * @name SetApprover
     * @request POST:/api/users/setApprover
     * @secure
     * @response `200` `void` Success
     */
    setApprover = (body: SetApproverDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/users/setApprover`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description 292D780E-F341-43B7-8E90-FB50EE121400
     *
     * @tags Users
     * @name SetAutoApprovalForUser
     * @request POST:/api/users/autoapprove
     * @secure
     * @response `200` `void` Success
     * @response `500` `void` Server Error
     */
    setAutoApprovalForUser = (body: SetAutoApproveDto, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/api/users/autoapprove`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name SetIsAdmin
     * @request POST:/api/users/setIsAdmin
     * @secure
     * @response `200` `void` Success
     * @response `401` `ProblemDetails` Unauthorized
     */
    setIsAdmin = (body: SetIsAdminDto, params: RequestParams = {}) =>
        this.request<void, ProblemDetails>({
            path: `/api/users/setIsAdmin`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * @description E19653F3-40BB-4FB5-A5DA-FAAFE49CB197
     *
     * @tags Users
     * @name SetBasicAdministrativeUserProperties
     * @request POST:/api/users/setBasics
     * @secure
     * @response `200` `void` Success
     */
    setBasicAdministrativeUserProperties = (body: SetUserAdministrationDto, params: RequestParams = {}) =>
        this.request<void, any>({
            path: `/api/users/setBasics`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name UpdateChangedFieldsOnUser
     * @request POST:/api/users/updateTimeUser
     * @secure
     * @response `200` `TimeUser` Success
     * @response `401` `ProblemDetails` Unauthorized
     */
    updateChangedFieldsOnUser = (body: TimeUser, params: RequestParams = {}) =>
        this.request<TimeUser, ProblemDetails>({
            path: `/api/users/updateTimeUser`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name GetAllUsersWithApprovers
     * @request GET:/api/users/withapprovers
     * @secure
     * @response `200` `(TimeUser)[]` Success
     */
    getAllUsersWithApprovers = (query: GetAllUsersWithApproversParams, params: RequestParams = {}) =>
        this.request<TimeUser[], any>({
            path: `/api/users/withapprovers`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Users
     * @name GetAllUsersWithCalendars
     * @request GET:/api/users/withcalendars
     * @secure
     * @response `200` `(TimeUser)[]` Success
     */
    getAllUsersWithCalendars = (query: GetAllUsersWithCalendarsParams, params: RequestParams = {}) =>
        this.request<TimeUser[], any>({
            path: `/api/users/withcalendars`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * @description 28370C02-7801-4EC1-B0DB-00BB5188FFE8
     *
     * @tags Users
     * @name UsersCalendarList
     * @request GET:/api/users/calendar
     * @secure
     * @response `200` `Calendar` Success
     */
    usersCalendarList = (query: UsersCalendarListParams, params: RequestParams = {}) =>
        this.request<Calendar, any>({
            path: `/api/users/calendar`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
    /**
     * No description
     *
     * @tags Validation
     * @name ValidationPowerautomateCreate
     * @request POST:/api/validation/powerautomate
     * @secure
     * @response `200` `PowerAutomateValidationResponse` Success
     * @response `400` `ProblemDetails` Bad Request
     */
    validationPowerautomateCreate = (body: PowerAutomateFlowValidationRequest, params: RequestParams = {}) =>
        this.request<PowerAutomateValidationResponse, ProblemDetails>({
            path: `/api/validation/powerautomate`,
            method: 'POST',
            body: body,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        });
    /**
     * @description 2523FC6D-B677-469A-B6B2-7580DAE2EF88
     *
     * @tags Worktypes
     * @name GetAllowedWorkTypes
     * @request GET:/api/worktypes/allowed
     * @secure
     * @response `200` `(WorkType)[]` Success
     * @response `500` `void` Server Error
     */
    getAllowedWorkTypes = (query: GetAllowedWorkTypesParams, params: RequestParams = {}) =>
        this.request<WorkType[], void>({
            path: `/api/worktypes/allowed`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        });
}
