import { useState, useEffect } from 'react';
import { Stack, Text, DefaultButton, PrimaryButton, Spinner, SpinnerSize, Modal, Icon, AnimationClassNames, IconButton } from '@fluentui/react';
import { ConnectionIcon } from '../../components/Connection';
import { useMutation } from '@tanstack/react-query';
import { ApiCalls } from '../../../../api/api';
import { IntegrationCreateRequest, IntegrationDto, MicrosoftEntraIdIntegration } from '../../../../api/generated/data-contracts';
import { MicrosoftEntraIdLayout } from './MicrosoftEntraIdLayout';
import { ErrorModal } from '../ErrorModal';
import { MicrosoftEntraIdLogo } from '../../components/Logos/MicrosoftEntraIdLogo';

export const NewMicrosoftEntraIdIntegration = ({
    close,
    addIntegration,
}: {
    close: () => void;
    addIntegration: (updater: (data: MicrosoftEntraIdIntegration[]) => MicrosoftEntraIdIntegration[]) => void;
}) => {
    const [integration, setIntegration] = useState<MicrosoftEntraIdIntegration>(() => ({
        configuration: {
            enabled: false,
            groupName: '',
            includeManager: false,
            syncUsersToDataverse: false,
        },
    }));
    const [addedIntegration, setAddedIntegration] = useState(false);

    const {
        mutate: createIntegration,
        isLoading,
        error,
        reset,
    } = useMutation<MicrosoftEntraIdIntegration>({
        mutationFn: async () => {
            const request: IntegrationCreateRequest = {
                type: 'MicrosoftEntraId',
                configuration: integration.configuration,
                name: 'Microsoft Entra ID',
            };

            return ApiCalls.integrationEventCreate(request).then(res => res.data as IntegrationDto);
        },
        onSuccess: integration => {
            addIntegration(integrations => [integration, ...integrations]);
            setAddedIntegration(true);
        },
    });

    return (
        <>
            <Stack horizontal horizontalAlign="center" verticalAlign="center" styles={{ root: { gap: 10, paddingTop: 20, paddingBottom: 20 } }}>
                {isLoading && <Spinner size={SpinnerSize.large} />}
                <MicrosoftEntraIdLogo height={'30px'} width={'30px'} />
                <Text variant="xxLarge">
                    {isLoading && 'Creating: '} {'Microsoft Entra ID integration'}*
                </Text>
                <IconButton iconProps={{ iconName: 'Cancel' }} styles={{ root: { position: 'absolute', right: 0, top: 0 } }} onClick={close} />
            </Stack>
            <MicrosoftEntraIdLayout integration={integration} isLoading={isLoading} setIntegration={setIntegration} />
            <Stack horizontal horizontalAlign="end" tokens={{ padding: '0 20px 20px', childrenGap: 10 }}>
                <DefaultButton disabled={isLoading} onClick={close}>
                    Cancel
                </DefaultButton>
                <PrimaryButton disabled={isLoading} onClick={() => createIntegration()}>
                    Save
                </PrimaryButton>
            </Stack>
            <SuccessModal addedIntegration={addedIntegration} close={close} name={'Microsoft Entra ID'} />
            <ErrorModal reset={reset} error={error} />
        </>
    );
};

const SuccessModal = ({ addedIntegration, name, close }: { addedIntegration: boolean; name: string; close: () => void }) => {
    const [connected, setConnected] = useState(false);

    useEffect(() => {
        if (addedIntegration) {
            const timer = setTimeout(() => {
                setConnected(true);
            }, 800);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [addedIntegration]);

    useEffect(() => {
        if (connected) {
            const timer = setTimeout(() => {
                close();
            }, 2000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [close, connected]);

    return (
        <Modal isOpen={addedIntegration} className={AnimationClassNames.fadeIn400}>
            <Stack horizontalAlign="center" verticalAlign="center" styles={{ root: { minWidth: 500, padding: 20, gap: 20 } }}>
                <Text variant="xxLarge">
                    <Icon iconName="Accept" /> Successfully created {name}
                </Text>
                <ConnectionIcon connected={connected} height={400} width={400} />
            </Stack>
        </Modal>
    );
};
