import { DetailsRow, IColumn, IDetailsListProps, Persona, PersonaPresence, PersonaSize, TextField, mergeStyles } from '@fluentui/react';
import { TimeUser } from '../../../../../api/generated/data-contracts';
import { UpdateUser } from '../components/UpdateUser';
import { UpdateUserNumber } from '../components/UpdateUserNumber';

interface IProps {
    classes: any;
}

export const useOnRender = ({ classes }: IProps) => {
    const onRenderRow: IDetailsListProps['onRenderRow'] = renderProps => {
        if (renderProps) {
            if (renderProps.item.originalProjectRemoved && !renderProps.item.isSelected) {
                return <DetailsRow {...renderProps} styles={{ root: classes.disabledRow }} />;
            } else if (renderProps.item.isSelected) {
                return <DetailsRow {...renderProps} styles={{ root: classes.selectedRow }} />;
            }
            return <DetailsRow {...renderProps} styles={{}} />;
        }
        return null;
    };

    const onRenderItemColumn = (item?: TimeUser, index?: number | undefined, column?: IColumn | undefined) => {
        if (!item || !column) return;

        switch (column.key) {
            case 'isSelectColumn':
                return (
                    <div className="personarowpersona">
                        <Persona
                            imageInitials={item.givenName.substring(0, 1) + item.surname.substring(0, 1)}
                            text={item.name}
                            secondaryText={item.userNumber ?? item.jobTitle ?? ''}
                            showSecondaryText={true}
                            size={PersonaSize.size24}
                            presence={PersonaPresence.none}
                            imageAlt={item.name + ', ' + item.jobTitle}
                            itemID={item.id}
                        />
                    </div>
                );

            case 'name':
                return <div className={columnContainerStyle}>{item.name}</div>;

            case 'email':
                return <div className={columnContainerStyle}>{item.email}</div>;

            case 'principalName':
                return <div className={columnContainerStyle}>{item.principalName}</div>;

            case 'userNumber':
                return <UpdateUserNumber user={item}></UpdateUserNumber>;

            case 'update':
                return <UpdateUser user={item}></UpdateUser>;

            default:
                return <div className={columnContainerStyle}>Not Found</div>;
        }
    };

    return { onRenderRow, onRenderItemColumn };
};

export const columnContainerStyle = mergeStyles({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%',
    minHeight: 42,
    padding: '0px 12px',
    // cursor: 'pointer',
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '.is-row-header &': {
        fontWeight: 600,
    },
});
