import React, { useEffect } from 'react';
import { Stack } from '@fluentui/react';
import { useUiContext } from '../../components/Contexts/UiContext';
import { ReleaseViewer } from './ReleaseChanger/components/ReleaseViewer/ReleaseViewer';
import { ReleaseChanger } from './ReleaseChanger/ReleaseChanger';

function VersionConfig() {
    const uiCtx = useUiContext();

    useEffect(() => {
        uiCtx.setLoading(false);
    }, []);

    return (
        <Stack
            styles={{
                root: {
                    width: 400,
                },
            }}
        >
            <ReleaseViewer />
            <ReleaseChanger
                dateFormatter={date => `(${date.slice(0, date.indexOf('+')).replace('T', ' ')})`}
                onMapControllers={controllers => {
                    console.log(controllers);

                    return controllers.map(controller => ({
                        ...controller,
                        // Filter out latest
                        packages: controller.packages.filter((pkg: any) => !pkg.name.includes('latest')),
                    }));
                }}
            />
        </Stack>
    );
}
export default VersionConfig;
