import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Calendar, DayOfWeek, DateRangeType } from '@fluentui/react';
import { DefaultButton, IconButton } from '@fluentui/react/lib/Button';
import { GetButtonColorClass, GetMonthNameShort } from './Utils';
import { Callout, DirectionalHint, FontWeights, Text } from '@fluentui/react';
import Period from '../Model/Period';
import { makeStyles, useTheme } from '@fluentui/react';
import { TimeState } from '../Model/State';
import { useId } from '@fluentui/react-hooks';
import '../Styles/PeriodPicker.scss';
import { useUiContext } from './Contexts/UiContext';

// const theme = getTheme();

// const styles = mergeStyleSets({
const getStyles = makeStyles(theme => ({
    callout: {
        maxWidth: 800,
        minWidth: 300,
    },
    backBtnCallout: {
        width: 330,
        padding: '6px 8px',
    },
    header: {
        padding: '18px 24px 12px',
        textAlign: 'center',
    },
    title: [
        theme.fonts.mediumPlus,
        {
            margin: 'auto',
            fontWeight: FontWeights.semilight,
        },
    ],
    inner: {
        height: '100%',
        padding: '0 24px 20px',
    },
}));

export const DayPickerStrings = {
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    goToToday: 'Go to today',
    weekNumberFormatString: 'Week number {0}',
    prevMonthAriaLabel: 'Previous month',
    nextMonthAriaLabel: 'Next month',
    prevYearAriaLabel: 'Previous year',
    nextYearAriaLabel: 'Next year',
    prevYearRangeAriaLabel: 'Previous year range',
    nextYearRangeAriaLabel: 'Next year range',
    closeButtonAriaLabel: 'Close',
};

let calendardaysformattetarray = [];
let calendarlastdayhovered;

interface propsPeriodPicker {
    periodPicked: Function;
    defaultStartDate: Date;
    defaultEndDate: Date;
    allPeriods: Period[];
    currentTimeState: TimeState;
    showRememberSubmitCallout: boolean;
}

function PeriodPicker(props: propsPeriodPicker) {
    const uiCtx = useUiContext();
    const theme = useTheme();
    const styles = getStyles();
    const backButtonId = useId('backButton-callout');
    const [showRememberSubmitCallout, setShowRememberSubmitCallout] = useState<boolean>(props.showRememberSubmitCallout);
    const [rememberSubmitCalloutDismissed, setRememberSubmitCalloutDismissed] = useState<boolean>(!props.showRememberSubmitCallout);
    const [showFlyout, setShowFlyout] = useState<boolean>(false);
    const buttonFieldRef = useRef(null);
    const [selectedDate, setSelectedDate] = React.useState<Date>();
    const [mindate, setMindate] = React.useState<Date>(new Date());
    const [maxdate, setMaxdate] = React.useState<Date>(new Date());

    const [currentPeriodRealStart, setCurrentPeriodRealStart] = useState<Date>(new Date());
    const [currentPeriodRealEnd, setCurrentPeriodRealEnd] = useState<Date>(new Date());
    const [currentPeriod, setCurrentPeriod] = useState<any>();
    const [currentTimeState, setCurrentTimeState] = useState<TimeState>(props.currentTimeState);
    // const [mouseHoverLastPeriod, setMouseHoverLastPeriod] = useState<boolean>(props.showRememberSubmitCallout);

    const calref = useRef(null);

    useEffect(() => {
        let today = new Date();
        today = new Date(today.getFullYear(), today.getMonth(), today.getDate());

        let mincalendardate = new Date(today);
        mincalendardate.setDate(mincalendardate.getDate() - 365 * 4);
        setMindate(mincalendardate);

        let maxcalendardate = new Date(today);
        maxcalendardate.setDate(maxcalendardate.getDate() + 365 * 4);
        setMaxdate(maxcalendardate);

        setSelectedDate(props.defaultStartDate);
    }, []);

    useEffect(() => {
        setSelectedDate(props.defaultStartDate);
    }, [props.defaultStartDate]);

    useEffect(() => {
        if (selectedDate != null && selectedDate !== undefined) {
            let currentPeriod = props.allPeriods.find(p => selectedDate.getTime() >= new Date(p.startDate).getTime() && selectedDate.getTime() <= new Date(p.endDate).getTime()); // Find current period by current selected date
            if (!currentPeriod) {
                return; // Do nothing
            } else {
                setCurrentPeriod(currentPeriod);
                setCurrentPeriodRealStart(new Date(currentPeriod.startDate));
                setCurrentPeriodRealEnd(new Date(currentPeriod.endDate));
                let notificationEnabled = !(uiCtx.allConfig.find(_ => _.key === 'showRememberSubmitCallout')?.value == 'false');
                console.log('notificationEnabled', notificationEnabled);
                if (notificationEnabled) {
                    setRememberSubmitCalloutDismissed(!props.showRememberSubmitCallout); // To show again when switching back and forth
                } else {
                    setRememberSubmitCalloutDismissed(true);
                }
            }
        }
    }, [selectedDate]);

    useEffect(() => {
        setCurrentTimeState(props.currentTimeState);
    }, [props.currentTimeState]);

    useEffect(() => {
        setShowRememberSubmitCallout(props.showRememberSubmitCallout);
        setRememberSubmitCalloutDismissed(!props.showRememberSubmitCallout);
        // setMouseHoverLastPeriod(props.showRememberSubmitCallout)
    }, [props.showRememberSubmitCallout]);

    let calendarbuttonClicked = () => {
        setShowFlyout(!showFlyout);
    };

    const onSelectDate = (date: Date, dateRangeArray: Date[]): void => {
        let selectedPeriod = props.allPeriods.find(p => date.getTime() >= new Date(p.startDate).getTime() && date.getTime() <= new Date(p.endDate).getTime());

        if (!selectedPeriod) {
            props.periodPicked(date, dateRangeArray[dateRangeArray.length - 1]);
            return;
        }

        setSelectedDate(date);
        console.debug(
            'selected period ',
            new Date(selectedPeriod.startDate).getDate() +
                ' ' +
                GetMonthNameShort(new Date(selectedPeriod.startDate).getMonth()) +
                ' - ' +
                new Date(selectedPeriod.endDate).getDate() +
                ' ' +
                GetMonthNameShort(new Date(selectedPeriod.endDate).getMonth()),
        );
        props.periodPicked(date, dateRangeArray[dateRangeArray.length - 1]);
        // props.periodPicked(dateRangeArray[0], dateRangeArray[dateRangeArray.length-1]);
        setShowFlyout(false);
        setRememberSubmitCalloutDismissed(true); // To make it blink with the rest of the UI
    };

    const nextPeriodClick = (): void => {
        let newPeriodStartDate = new Date(currentPeriodRealEnd); // Start by setting newPeriodStartDate var to old period end date
        newPeriodStartDate.setDate(newPeriodStartDate.getDate() + 1); // Add exact one day to old period end date
        let newPeriodStartDateArray = [newPeriodStartDate]; // Create array for onSelectDate
        onSelectDate(newPeriodStartDate, newPeriodStartDateArray); // Use onSelectDate to switch to the period for next day after end of old period
        // setRememberSubmitCalloutDismissed(false); // To show again when switching back and forth
    };

    const prevPeriodClick = (): void => {
        let newPeriodStartDate = new Date(currentPeriodRealStart); // Start by setting newPeriodStartDate var to old period start date
        newPeriodStartDate.setDate(newPeriodStartDate.getDate() - 1); // Subtract exact one day from old period start date
        let newPeriodStartDateArray = [newPeriodStartDate]; // Create array for onSelectDate
        onSelectDate(newPeriodStartDate, newPeriodStartDateArray); // Use onSelectDate to switch to the period for the day before start of old period
        // setRememberSubmitCalloutDismissed(false); // To show again when switching back and forth
    };

    let flyoutDismissed = (ev: any): void => {
        setShowFlyout(false);
    };

    const highlightSelectedPeriod = (e: any) => {
        try {
            if (calref != null && calref.current != null) {
                if (e.relatedTarget != null && (e.relatedTarget.getAttribute('role') === 'gridcell' || e.relatedTarget.parentNode.getAttribute('role') === 'gridcell')) {
                    let caltd = null;
                    if (e.relatedTarget.nodeName === 'TD') {
                        caltd = e.relatedTarget;
                    } else if (e.relatedTarget.nodeName === 'BUTTON') {
                        caltd = e.relatedTarget.parentNode;
                    }
                    let button = caltd.children[0];
                    let buttonAreaLabel = button.getAttribute('aria-label');

                    if (button.classList === undefined || button.classList.contains('ms-DatePicker-day-button') === false) {
                        return;
                    }

                    let hovereddate = new Date(buttonAreaLabel);
                    let mouseoverdate = new Date(buttonAreaLabel).toString();

                    if (calendarlastdayhovered !== mouseoverdate) {
                        calendarlastdayhovered = mouseoverdate;
                        try {
                            for (let i = 0; i < calendardaysformattetarray.length; i++) {
                                calendardaysformattetarray[i].style.backgroundColor = '';
                            }
                        } catch (err) {
                            console.log('clean cal err', err);
                        }

                        let currentDayElement = calref.current._dayPicker.current.days[mouseoverdate];

                        currentDayElement.style.backgroundColor = theme.palette.neutralLight;
                        // currentDayElement.style.backgroundColor = "#edebe9";
                        calendardaysformattetarray.push(currentDayElement);
                        let hoveredperiods = props.allPeriods.filter(p => new Date(p.startDate) <= hovereddate && new Date(p.endDate) >= hovereddate);
                        if (hoveredperiods.length >= 1) {
                            let hoveredperiod = hoveredperiods[0];

                            let diffTime = Math.abs(new Date(hoveredperiod.endDate).getTime() - hovereddate.getTime());
                            let daystillend = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                            let previuosDaytd = currentDayElement;
                            for (let i = 0; i < daystillend; i++) {
                                if (previuosDaytd.nextElementSibling != null) {
                                    previuosDaytd = previuosDaytd.nextElementSibling;
                                    previuosDaytd.style.backgroundColor = theme.palette.neutralLight;
                                    //   previuosDaytd.style.backgroundColor = "#edebe9";
                                    calendardaysformattetarray.push(previuosDaytd);
                                } else {
                                    if (previuosDaytd.parentNode.nextElementSibling != null && previuosDaytd.parentNode.nextElementSibling.nodeName === 'TR') {
                                        previuosDaytd = previuosDaytd.parentNode.nextElementSibling.firstChild;
                                        previuosDaytd.style.backgroundColor = theme.palette.neutralLight;
                                        // previuosDaytd.style.backgroundColor = "#edebe9";
                                        calendardaysformattetarray.push(previuosDaytd);
                                    }
                                }
                            }
                            let nextDaytd = currentDayElement;
                            diffTime = Math.abs(new Date(hoveredperiod.startDate).getTime() - hovereddate.getTime());
                            let daysfromstart = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                            for (let i = 0; i < daysfromstart; i++) {
                                if (nextDaytd.previousElementSibling != null) {
                                    nextDaytd = nextDaytd.previousElementSibling;
                                    nextDaytd.style.backgroundColor = theme.palette.neutralLight;
                                    //   nextDaytd.style.backgroundColor = "#edebe9";
                                    calendardaysformattetarray.push(nextDaytd);
                                } else {
                                    if (nextDaytd.parentNode.previousElementSibling != null && nextDaytd.parentNode.previousElementSibling.nodeName === 'TR') {
                                        nextDaytd = nextDaytd.parentNode.previousElementSibling.lastChild;
                                        nextDaytd.style.backgroundColor = theme.palette.neutralLight;
                                        // nextDaytd.style.backgroundColor = "#edebe9";
                                        calendardaysformattetarray.push(nextDaytd);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const periodName = useMemo(() => {
        if (currentPeriod?.name) {
            return currentPeriod.name;
        }
        if (currentPeriodRealStart && currentPeriodRealEnd) {
            return `${currentPeriodRealStart.getDate()} ${GetMonthNameShort(currentPeriodRealStart.getMonth())} - ${currentPeriodRealEnd.getDate()} ${GetMonthNameShort(
                currentPeriodRealEnd.getMonth(),
            )}`;
        }
        return '';
    }, [currentPeriod?.name, currentPeriodRealStart, currentPeriodRealEnd]);

    return (
        <>
            <div id="tft-periodPicker" ref={buttonFieldRef} style={{ display: 'flex' }}>
                <IconButton
                    id={backButtonId}
                    iconProps={{ iconName: 'ChevronLeftSmall' }}
                    onClick={prevPeriodClick}
                    style={{ color: theme.semanticColors.bodyTextChecked }}
                    // onMouseOver={()=>{setMouseHoverLastPeriod(true)}}
                    // onMouseLeave={()=>{setMouseHoverLastPeriod(false)}}
                />
                <DefaultButton
                    style={{ minWidth: 160 }}
                    text={periodName}
                    //   text={`${currentPeriodRealStart.getDate()} ${GetMonthNameShort(currentPeriodRealStart.getMonth())} - ${currentPeriodRealEnd.getDate()} ${GetMonthNameShort(currentPeriodRealEnd.getMonth())}`}
                    onClick={calendarbuttonClicked}
                    className={
                        'periodPickerButton ' +
                        GetButtonColorClass(new Date(currentPeriodRealStart).setUTCTime(), new Date(currentPeriodRealEnd).setUTCTime(), new Date().setUTCTime(), currentTimeState)
                    }
                    styles={{ root: { marginRight: theme.spacing.s1, marginLeft: theme.spacing.s1 } }}
                />
                <IconButton
                    iconProps={{ iconName: 'ChevronRightSmall' }}
                    onClick={nextPeriodClick}
                    // style={{marginLeft: '2%', width: 40, color: theme.semanticColors.bodyText, animation: 'fadein 1s'}}
                    style={{ color: theme.semanticColors.bodyTextChecked }}
                />
            </div>
            {!rememberSubmitCalloutDismissed && showRememberSubmitCallout && (
                <Callout
                    className={styles.backBtnCallout + ' rememberSubmit'}
                    target={`#${backButtonId}`}
                    directionalHint={DirectionalHint.leftCenter}
                    styles={{
                        beakCurtain: { backgroundColor: '#f39494 !important' },
                        calloutMain: { backgroundColor: '#f39494 !important' },
                        beak: { backgroundColor: '#f39494 !important' },
                    }}
                    // onDismiss={()=>{setMouseHoverLastPeriod(false)}}
                >
                    <IconButton onClick={() => setRememberSubmitCalloutDismissed(true)} iconProps={{ iconName: 'ChromeClose' }} styles={{ root: { height: 18, width: 18 }, icon: { fontSize: 10 } }} />
                    <span style={{ paddingLeft: 6, color: 'black' }}>Remember to submit your previous timesheet</span>
                </Callout>
            )}
            {showFlyout && (
                <Callout className={styles.callout} role="alertdialog" gapSpace={0} target={buttonFieldRef} onDismiss={flyoutDismissed} setInitialFocus>
                    <div
                        className={styles.inner}
                        onMouseLeave={() => {
                            calendarlastdayhovered = '';
                            try {
                                for (let i = 0; i < calendardaysformattetarray.length; i++) {
                                    calendardaysformattetarray[i].style.backgroundColor = '';
                                }
                            } catch (err) {
                                console.log('clean cal err', err);
                            }
                        }}
                    >
                        <Calendar
                            onSelectDate={onSelectDate}
                            componentRef={calref}
                            value={selectedDate!}
                            strings={DayPickerStrings}
                            isDayPickerVisible={true}
                            highlightSelectedMonth={true}
                            showMonthPickerAsOverlay={false}
                            showGoToToday={false}
                            dateRangeType={DateRangeType.Day}
                            firstDayOfWeek={DayOfWeek.Monday}
                            minDate={mindate}
                            maxDate={maxdate}
                            // onMouseMove={(e: any) => { highlightSelectedPeriod(e); }}
                            // onMouseOver={(e: any) => { highlightSelectedPeriod(e); }}
                            {...{
                                onMouseMove: (e: any) => {
                                    highlightSelectedPeriod(e);
                                },
                                onMouseOver: (e: any) => {
                                    highlightSelectedPeriod(e);
                                },
                            }}
                        />
                    </div>
                </Callout>
            )}
        </>
    );
}

export default PeriodPicker;
