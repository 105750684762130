import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useRef } from 'react';
import { useUiContext } from '../../../../components/Contexts/UiContext';
import { GET_ALL_PERIODS_AS_ADMIN_PERIODS } from '../../../../constants/queryKeys';
import AdminPeriodsDto from '../../../../Model/AdminPeriodsDto';
import Period from '../../../../Model/Period';
import { PeriodConfigOverview } from './PeriodConfigOverview';

export const PeriodConfig = () => {
    const uiCtx = useUiContext();

    const defaultData = useRef([]).current;

    const { data = defaultData } = useQuery([GET_ALL_PERIODS_AS_ADMIN_PERIODS], (): Promise<AdminPeriodsDto[]> => uiCtx.timeApi.GetAllPeriodsAsAdminPeriods(), {
        onSuccess: () => uiCtx.setLoading(false),
        placeholderData: defaultData,
    });

    const { allAdminPeriods, allPeriods } = useMemo((): {
        allAdminPeriods: AdminPeriodsDto[];
        allPeriods: Period[];
    } => {
        return {
            allAdminPeriods: data,
            allPeriods: data.map(ap => {
                return new Period(ap.id, ap.name, ap.startDate, ap.endDate, ap.state, ap.deadlineDate);
            }),
        };
    }, [data]);

    return <PeriodConfigOverview allPeriods={allPeriods} allPeriodsAsAdminPeriods={allAdminPeriods} />;
};
