import * as React from "react"
import { constants } from '../Styles/constants';

export const TimeIconWhite: React.FC = (props) => {
  return (
    <svg
    width={24}
    height={24}
      viewBox="0 0 80 80"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <path
        d="M40 7.171c19.65 0 35.603 15.953 35.603 35.603 0 19.65-15.953 35.603-35.603 35.603-19.65 0-35.603-15.953-35.603-35.603C4.397 23.124 20.35 7.171 40 7.171zm0 7.756c15.37 0 27.847 12.478 27.847 27.847 0 15.37-12.478 27.847-27.847 27.847-15.369 0-27.847-12.478-27.847-27.847 0-15.369 12.478-27.847 27.847-27.847z"
        fill="#ffffff"
      />
      <path
        d="M40.764 39.848c-.38 0-.739.066-1.09.157L25.38 25.552a2.136 2.136 0 00-3.014-.021v.002a2.1 2.1 0 00-.022 2.98l14.32 14.503a4.293 4.293 0 00-.158 1.09 4.257 4.257 0 004.258 4.257 4.257 4.257 0 004.258-4.257 4.257 4.257 0 00-4.258-4.258z"
        fill="#bfbebf"
        fillRule="nonzero"
      />
      <path
        d="M59.086 9.404a1.496 1.496 0 00-.719-1.99c-1.099-.516-2.64-1.24-3.74-1.755a1.496 1.496 0 00-1.99.719v.001a1.496 1.496 0 00.718 1.99l3.74 1.755c.748.351 1.64.03 1.99-.718l.001-.002zM44.97 3.723a2.1 2.1 0 00-2.1-2.1h-5.74a2.1 2.1 0 100 4.2h5.74a2.1 2.1 0 002.1-2.1z"
        fill="#ffffff"
      />
    </svg>
  )
};


{/* <svg width="40px" height="40px" viewBox="0 0 80 80" version="1.1" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(0.0565591,0,0,0.0565591,-4.10784,-15.324)">
        <path d="M779.854,397.725C1127.28,397.725 1409.34,679.789 1409.34,1027.21C1409.34,1374.64 1127.28,1656.7 779.854,1656.7C432.431,1656.7 150.367,1374.64 150.367,1027.21C150.367,679.789 432.431,397.725 779.854,397.725ZM779.854,534.864C1051.59,534.864 1272.2,755.478 1272.2,1027.21C1272.2,1298.95 1051.59,1519.56 779.854,1519.56C508.12,1519.56 287.506,1298.95 287.506,1027.21C287.506,755.478 508.12,534.864 779.854,534.864Z" style="fill:rgb(15,68,123);"/>
    </g>
    <g transform="matrix(2.12891,0,0,2.12891,-69.9396,-66.5978)">
        <path d="M52,50C51.822,50 51.653,50.031 51.488,50.074C51.488,50.074 46.518,45.049 44.774,43.285C44.385,42.892 43.752,42.888 43.358,43.275C43.358,43.276 43.358,43.276 43.358,43.276C43.358,43.276 43.358,43.276 43.358,43.276C43.17,43.46 43.064,43.711 43.062,43.974C43.06,44.237 43.163,44.489 43.348,44.676C45.087,46.437 50.074,51.488 50.074,51.488C50.031,51.653 50,51.822 50,52C50,53.105 50.895,54 52,54C53.105,54 54,53.105 54,52C54,50.895 53.105,50 52,50Z" style="fill:rgb(191,190,191);fill-rule:nonzero;"/>
    </g>
    <g transform="matrix(0.077457,0.0363515,-0.0354963,0.0756348,21.4971,-15.389)">
        <path d="M520.801,77.493C520.801,67.604 512.973,59.588 503.317,59.588C489.127,59.588 469.218,59.588 455.028,59.588C445.372,59.588 437.544,67.604 437.544,77.493C437.544,77.501 437.544,77.508 437.544,77.516C437.544,87.405 445.372,95.421 455.028,95.421C469.218,95.421 489.127,95.421 503.317,95.421C512.973,95.421 520.801,87.405 520.801,77.516C520.801,77.508 520.801,77.501 520.801,77.493Z" style="fill:rgb(15,68,123);"/>
    </g>
    <g transform="matrix(0.119386,-6.85492e-18,-7.79656e-18,0.11721,-17.2065,-5.36169)">
        <path d="M520.801,77.504C520.801,67.609 512.926,59.588 503.211,59.588C489.064,59.588 469.281,59.588 455.134,59.588C445.419,59.588 437.544,67.609 437.544,77.504C437.544,77.504 437.544,77.505 437.544,77.505C437.544,87.4 445.419,95.421 455.134,95.421C469.281,95.421 489.064,95.421 503.211,95.421C512.926,95.421 520.801,87.4 520.801,77.505C520.801,77.505 520.801,77.504 520.801,77.504Z" style="fill:rgb(15,68,123);"/>
    </g>
</svg> */}
