import * as React from 'react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { SpinButton } from '@fluentui/react/lib/SpinButton';
import { ValidateResponse } from '../Validator';



const dialogContentProps = {
  type: DialogType.normal,
  title: 'Timesheet warning',
  subText: 'Do you want to submit this timesheet?',
};

interface ValidationWarningDialogProps {
    hidden: boolean,
    handleDialog: (submit: boolean) => void,
    warnings: ValidateResponse[]
}


export const ValidationWarningDialog: React.FunctionComponent<ValidationWarningDialogProps> = (props: ValidationWarningDialogProps) => {



  return (
    <>
      <Dialog
        hidden={props.hidden}
        onDismiss={() => console.log("hidden")}
        dialogContentProps={{...dialogContentProps}}
      >  
        <ul>
        {props.warnings.map((warning, index) => {return <li key={warning.name}>{warning.reason}</li>})}     
        </ul> 
        <DialogFooter>         
          <PrimaryButton onClick={() => props.handleDialog(true)} text="Submit" />
          <DefaultButton onClick={() => props.handleDialog(false)} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </>
  );
};
