import { ISpinnerProps, Spinner, SpinnerSize } from '@fluentui/react';
import React from 'react';

export const TimeSpinner = (props: ISpinnerProps) => {
    const spinnerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    };

    return (
        <div style={spinnerStyle}>
            <Spinner size={SpinnerSize.large} {...props} />
        </div>
    );
};
