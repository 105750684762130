
import * as React from "react"
// import { useTheme } from "@fluentui/react-theme-provider";
import { mobileConstants } from "./mobileConstants";
import { useUiContext } from "../components/Contexts/UiContext";
import { useTheme } from "@fluentui/react";

export const TimeSubmitIconMobile: React.FC = (props) => {
    const uiCtx = useUiContext();
    const theme = useTheme();

    return (
        <svg
            width={mobileConstants.topbarSvgSize}
            height={mobileConstants.topbarSvgSize}
            viewBox="0 0 80 80"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit={2}
            {...props}
        >
            <path
                // fill="#4d8cc2"
                // fill={theme.semanticColors.bodySubtext}
                fill={uiCtx.isTeams ? "rgba(255, 255, 255, 0.8)" : theme.semanticColors.bodySubtext}
                d="M23.799 60.24l47.345-47.344 6.859 6.859L30.658 67.1z"
            />
            <path
                // fill="#0f447b"
                // fill={theme.semanticColors.bodyText}
                fill={uiCtx.isTeams ? "rgba(255, 255, 255, 0.9)" : theme.semanticColors.bodyText}
                d="M30.6 67.007L1.996 38.4l7.454-7.455 28.606 28.607z"
            />
        </svg>
    );
};

