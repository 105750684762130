import { RouteObject } from 'react-router';

type RouteMeta = {
    relativePath: string;
    caseSensitive: boolean;
    childrenIndex: number;
    route: RouteObjectWithName;
    name?: string;
};

type RouteBranch = {
    path: string;
    score: number;
    routesMeta: RouteMeta[];
};

export type RouteObjectWithName = Omit<RouteObject, 'children'> & { name?: string; children?: RouteObjectWithName[] };

export function flattenRoutes(routes: RouteObjectWithName[], branches: RouteBranch[] = [], parentsMeta: RouteMeta[] = [], parentPath = ''): RouteBranch[] {
    routes.forEach((route, index) => {
        const foundName = parentsMeta.find(route => route.name !== undefined);
        const meta: RouteMeta = {
            relativePath: route.path || '',
            caseSensitive: route.caseSensitive === true,
            childrenIndex: index,
            route,
            name: foundName ? foundName.name : route.name,
        };

        if (meta.relativePath.startsWith('/')) {
            // invariant(
            //   meta.relativePath.startsWith(parentPath),
            //   `Absolute route path "${meta.relativePath}" nested under path ` +
            // 	`"${parentPath}" is not valid. An absolute child route path ` +
            // 	`must start with the combined path of all its parent routes.`
            // );

            meta.relativePath = meta.relativePath.slice(parentPath.length);
        }

        const path = joinPaths([parentPath, meta.relativePath]);
        const routesMeta = parentsMeta.concat(meta);

        // Add the children before adding this route to the array so we traverse the
        // route tree depth-first and child routes appear before their parents in
        // the "flattened" version.
        if (route.children && route.children.length > 0) {
            // invariant(route.index !== true, `Index routes must not have child routes. Please remove ` + `all child routes from route path "${path}".`);

            flattenRoutes(route.children, branches, routesMeta, path);
        }

        // Routes without a path shouldn't ever match by themselves unless they are
        // index routes, so don't add them to the list of possible branches.
        if (route.path == null && !route.index) {
            return;
        }

        branches.push({ path, score: computeScore(path, route.index), routesMeta });
    });

    return branches;
}

const joinPaths = (paths: string[]): string => paths.join('/').replace(/\/\/+/g, '/');

const isSplat = (s: string) => s === '*';
const paramRe = /^:\w+$/;
const dynamicSegmentValue = 3;
const indexRouteValue = 2;
const emptySegmentValue = 1;
const staticSegmentValue = 10;
const splatPenalty = -2;

function computeScore(path: string, index: boolean | undefined): number {
    const segments = path.split('/');
    let initialScore = segments.length;
    if (segments.some(isSplat)) {
        initialScore += splatPenalty;
    }

    if (index) {
        initialScore += indexRouteValue;
    }

    return segments
        .filter(s => !isSplat(s))
        .reduce(
            (score, segment) => score + (paramRe.test(segment) ? dynamicSegmentValue : segment === '' ? emptySegmentValue : staticSegmentValue),
            initialScore,
        );
}

export const buildRoute = (...args: (string | number | null | undefined)[]) =>
    (
        args.reduce((route, part) => {
            if (part !== undefined && part !== null && part !== '') {
                return route + '/' + part;
            }
            return route;
        }, '') as string
    ).replace(/\/\/+/g, '/');
