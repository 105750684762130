export enum UpgradeCycle {
    Production = 'Production',
    ReleaseCandidate = 'ReleaseCandidate',
    Beta = 'Beta',
    Alpha = 'Alpha',
}

export enum UpgradeCycleNumeric {
    Production = 0,
    ReleaseCandidate = 1,
    Beta = 2,
    Alpha = 3,
}

// export type QueryKey<T extends readonly any[] = any[]> = T;
export type QueryKey<T extends readonly any[] = any[]> = T;
export type QueryFetcher<Params extends any[] = any[], Returns = any> = (...args: Params) => Promise<Returns>;
// export type QueryPair<Params extends any[] = any[], Returns = any, Key extends readonly any[] = any[]> = readonly [QueryFetcher<Params, Returns>, QueryKey<Key>?];
export type QueryPair<Fetcher extends (...args: any[]) => Promise<any>, Key extends readonly any[] = any[]> = readonly [Fetcher, QueryKey<Key>?];
