import * as React from 'react';
import { memo } from 'react';
import { Spinner, Stack, Text } from '@fluentui/react';
import { useMemo } from 'react';
import useFetch from 'use-http';
import { UpgradeCycleNumeric } from '../../types/types';
import { useTheme } from '@fluentui/react';

export const ReleaseViewer: React.FC<any> = memo(props => {
    const theme = useTheme();
    // GetCurrentAppPackages
    const { data: currentVersions, loading } = useFetch('/api/plugin/time/8298ccf2-0819-4464-9457-9046e0a5888f', { data: [] }, []);
    const formattedData = useMemo(() => {
        return currentVersions.map((controller: any) => {
            return {
                stage: UpgradeCycleNumeric[controller.package.cycle],
                controllerName: controller.controllerName,
                ...controller.package,
            };
        });
    }, [currentVersions]);

    return (
        <Stack>
            <Stack styles={{ root: { height: '50px' } }}>
                {loading ? (
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <Text variant="large">Loading versions..</Text>
                        <Spinner />
                    </Stack>
                ) : (
                    <Text variant="large">{currentVersions.length} controllers in use:</Text>
                )}
            </Stack>
            <Stack tokens={{ childrenGap: theme.spacing.m }}>
                {formattedData.map((data: any) => {
                    return (
                        <Stack
                            key={data.path}
                            horizontal
                            tokens={{ childrenGap: theme.spacing.m }}
                            styles={{ root: { padding: theme.spacing.m, boxShadow: 'rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px, rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px' } }}
                        >
                            <Stack>
                                <Text>Controller name:</Text>
                                <Text>Version stage:</Text>
                                <Text>Filename:</Text>
                                <Text>Last updated:</Text>
                            </Stack>
                            <Stack>
                                <Text>{data.controllerName}</Text>
                                <Text>{data.stage}</Text>
                                <Text>{data.name}</Text>
                                <Text>{data.lastModified}</Text>
                            </Stack>
                        </Stack>
                    );
                })}
            </Stack>
        </Stack>
    );
});
