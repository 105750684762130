import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import './Styles/index.scss';
import './Styles/App.css';
// import * as serviceWorker from './serviceWorker';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { QueryProvider } from './_contexts/QueryProvider';
import { AppLoader } from './_components/AppLoader/AppLoader';
import { ErrorBoundary } from './_components/ErrorBoundary';
import { Suspense } from 'react';
import { TimeLoaderFullscreen } from './_components/TimeLoaderFullscreen';
import { AuthContextProvider } from './Authentication/AuthContext';
import { UiContextProvider } from './components/Contexts/UiContext';

initializeIcons();

const rootElement = document.getElementById('root');

const CommonSuspenseBoundary = ({ children }: { children: React.ReactNode }) => {
    return <Suspense fallback={<TimeLoaderFullscreen />}>{children}</Suspense>;
};

ReactDOM.render(
    // <Loader />,
    <ErrorBoundary>
        <QueryProvider>
            <HashRouter>
                <AppLoader>
                    {/* This suspense boundary keeps the suspension from reaching the <AppLoader /> */}
                    <CommonSuspenseBoundary>
                        <AuthContextProvider>
                            <UiContextProvider />
                        </AuthContextProvider>
                    </CommonSuspenseBoundary>
                </AppLoader>
            </HashRouter>
        </QueryProvider>
    </ErrorBoundary>,
    rootElement,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
