import DelegateArea from '../../components/DelegateArea';
import Time from '../../components/Time';

export const Overview = () => {
    return (
        <div className="playGround">
            <Time />
            <DelegateArea />
        </div>
    );
};
