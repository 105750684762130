import React, { useEffect, useState, ReactElement } from 'react';
import Period from '../../../../Model/Period';
import { TextField } from '@fluentui/react/lib/TextField';
import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { DatePicker, DayOfWeek, IconButton, ScrollablePane, Text, TooltipHost } from '@fluentui/react';
import { DayPickerStrings } from '../../../../components/PeriodPicker';
import moment from 'moment';
import { useUiContext } from '../../../../components/Contexts/UiContext';
import AdminPeriodsDto from '../../../../Model/AdminPeriodsDto';

interface IProps {
    allPeriods: Period[];
    allPeriodsAsAdminPeriods: AdminPeriodsDto[];
    savePeriod: (period: Period) => void;
    changeStartDate: (period: Period, date: Date) => void;
    changeEndDate: (period: Period, date: Date) => void;
    changeName: (period: Period, name: string) => void;
    changePeriodState(period: Period, optionKey: number);
    deletePeriod: (period: Period) => void;
}

export const PeriodListPicker: React.FC<IProps> = props => {
    const uiCtx = useUiContext();
    const [allAdminPeriods, setAllAdminPeriods] = useState<AdminPeriodsDto[]>(props.allPeriodsAsAdminPeriods);
    const [periodListItems, setPeriodListItems] = useState<any[]>(props.allPeriods.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()));

    const _columns: IColumn[] = [
        { key: 'name', name: 'Name', fieldName: 'name', isRowHeader: true, minWidth: 260, maxWidth: 260, isResizable: true },
        { key: 'startDate', name: 'Start Date', fieldName: 'startDate', minWidth: 160, maxWidth: 160, isResizable: true },
        { key: 'endDate', name: 'End Date', fieldName: 'endDate', minWidth: 160, maxWidth: 160, isResizable: true },
        { key: 'state', name: 'State', fieldName: 'state', minWidth: 140, maxWidth: 140, isResizable: true },
        { key: 'saveRemove', name: '', fieldName: 'saveRemove', minWidth: 80, maxWidth: 80, isResizable: true },
        // { key: 'remove', name: '', fieldName: 'remove', minWidth: 40, maxWidth: 40, isResizable: true },
    ];

    useEffect(() => {
        setAllAdminPeriods(props.allPeriodsAsAdminPeriods);
        setPeriodListItems(
            props.allPeriods
                .map((period: Period) => {
                    let pStateText = 'Open';
                    switch (period.state) {
                        case 0:
                            pStateText = 'Open';
                            break;
                        case 1:
                            pStateText = 'Pending';
                            break;
                        case 2:
                            pStateText = 'Closed';
                            break;
                        default:
                            pStateText = 'Open';
                            break;
                    }
                    return { ...period, stateText: pStateText };
                })
                .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()),
        );
    }, [props.allPeriods, props.allPeriodsAsAdminPeriods]);

    let defaultDatePickerProps = {
        firstDayOfWeek: DayOfWeek.Monday,
        strings: DayPickerStrings,
        placeholder: 'Select a date...',
        ariaLabel: 'Select a date',
    };

    const onRenderColumn = (item: any, index: number, column: IColumn): ReactElement => {
        switch (column.key) {
            case 'name':
                return <TextField key={item.name} defaultValue={item.name} onBlur={e => props.changeName(item, e.target.value)} styles={{ root: { marginRight: 32 } }} disabled={!item.id} />;
            case 'state':
                return (
                    <Dropdown
                        //placeholder="Update period state"
                        defaultSelectedKey={item.state as number}
                        options={[
                            { key: 0, text: 'Open' },
                            { key: 1, text: 'Pending' },
                            { key: 2, text: 'Closed' },
                        ]}
                        onChanged={option => props.changePeriodState(item, +option.key)}
                        styles={{ dropdown: { width: 100, marginRight: 10 } }}
                    />
                );
            case 'startDate':
                return item.id ? (
                    <Text styles={{ root: { display: 'flex', height: '100%', alignItems: 'center' } }}>{moment(new Date(item.startDate)).format(uiCtx.dateformat)}</Text>
                ) : (
                    <DatePicker onSelectDate={date => props.changeStartDate(item, date)} {...defaultDatePickerProps} value={new Date(item.startDate)} />
                );
            case 'endDate':
                return item.id ? (
                    <Text styles={{ root: { display: 'flex', height: '100%', alignItems: 'center' } }}>{moment(new Date(item.endDate)).format(uiCtx.dateformat)}</Text>
                ) : (
                    <DatePicker onSelectDate={date => props.changeEndDate(item, date)} {...defaultDatePickerProps} value={new Date(item.endDate)} />
                );

            // case 'save':
            //     return item.id ? <></> : <PrimaryButton onClick={() => props.savePeriod(item)} text={'Save'} />;

            case 'saveRemove':
                return !item.id ? (
                    <>
                        <IconButton
                            iconProps={{ iconName: 'Save' }}
                            onClick={() => {
                                props.savePeriod(item);
                            }}
                        />
                        <IconButton
                            iconProps={{ iconName: 'Delete' }}
                            onClick={() => {
                                props.deletePeriod(item);
                            }}
                        />
                    </>
                ) : allAdminPeriods.find(_ => _.id == item.id)?.canBeDeleted ? (
                    <>
                        <IconButton iconProps={{ iconName: 'Save' }} styles={{ root: { background: 'none', visibility: 'hidden' } }} disabled={true} />
                        <IconButton
                            iconProps={{ iconName: 'Delete' }}
                            onClick={() => {
                                props.deletePeriod(item);
                            }}
                        />
                    </>
                ) : (
                    <>
                        <IconButton iconProps={{ iconName: 'Save' }} styles={{ root: { background: 'none', visibility: 'hidden' } }} disabled={true} />
                        <TooltipHost
                            content={
                                <div>
                                    Period can't be removed.
                                    <br />
                                    Timeentries already exists.
                                </div>
                            }
                            closeDelay={250}
                        >
                            <IconButton iconProps={{ iconName: 'Delete' }} styles={{ root: { background: 'none' } }} disabled={true} />
                        </TooltipHost>
                    </>
                );

            default:
                return <span>ERROR</span>;
        }
    };

    return (
        <ScrollablePane>
            <DetailsList
                items={periodListItems}
                columns={_columns}
                selectionMode={SelectionMode.none}
                //selection={selectedTasks}
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                ariaLabelForSelectionColumn="Toggle selection"
                checkButtonAriaLabel="Row checkbox"
                groupProps={{
                    showEmptyGroups: true,
                }}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                onRenderItemColumn={onRenderColumn}
                compact={true}
                styles={{
                    root: {
                        maxWidth: 885,
                        margin: '0 auto',
                    },
                    headerWrapper: {
                        '.ms-DetailsHeader-cellName': {
                            flex: '1 1 auto',
                        },
                    },
                }}
            />
        </ScrollablePane>
    );
};
