import { TextField } from "@fluentui/react"
import React from "react"

export interface ValidationConfigProps {
    setConfig: (name, value) => void
    allConfig: any[]
}


export const FlowValidationConfigValues = {
    FlowUrl: "Flow_Url"
}

export const FlowValidationConfig = (props: ValidationConfigProps) => {

    
    const flowUrl = FlowValidationConfigValues.FlowUrl;

    const onChangeMaxHours = (value) => {        
        props.setConfig(flowUrl, value);
    }

    if (!props.allConfig) return;


    return <div>
        <TextField onBlur={(e) => onChangeMaxHours(e.target.value)} label="Power Automate URL" defaultValue={props.allConfig.find(_ => _.key == flowUrl)?.value || ``}></TextField>
    </div>
}

