import React, { useEffect, useState } from 'react';
import { Persona, PersonaSize, PersonaPresence } from '@fluentui/react/lib/Persona';
import { DatePicker, DayOfWeek, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, IconButton, IDropdownOption, PrimaryButton, Spinner, Toggle } from '@fluentui/react';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import { Stack } from '@fluentui/react/lib/Stack';
import { Calendar } from '../../../../Model/NewModels/Calendar';
import { useUiContext } from '../../../../components/Contexts/UiContext';
import User from '../../../../Model/User';
import { ContentSection, PageContent, PageHeader, TableHeaderRow } from '../../../../components/LayoutElements';
import FilterFlyout from '../../../../components/LayoutElements/FilterFlyout';

interface propsUsersAdministration {
    allUsers: any[];
    allCalendars: Calendar[];
    calendarsEnabled: boolean;
}

function UsersAdministration(props: propsUsersAdministration) {
    const uiCtx = useUiContext();

    const [allUsers, setAllUsers] = useState<User[]>([]);
    const [allCalendars, setAllCalendars] = useState<Calendar[]>(props.allCalendars);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const [filterInputValue, setFilterInputValue] = useState<string>('');
    const [filterActiveValue, setfilterActiveValue] = useState<string>('All');
    const [filterCalendarValue, setfilterCalendarValue] = useState<string>('All Calendars');
    const [showCount, setShowCount] = useState(50);
    const [typingTimeout, setTypingTimeout] = useState<any>(0);
    const [searchIsError, setSearchIsError] = useState<string>('');

    useEffect(() => {
        setfilterActiveValue(filterActiveValue);
        setfilterCalendarValue(filterCalendarValue);
    }, []);

    useEffect(() => {
        setAllUsers(
            props.allUsers.sort((a: any, b: any) => {
                return a.name < b.name ? -1 : 1;
            }),
        );
        setFilteredUsers(
            props.allUsers.sort((a: any, b: any) => {
                return a.name < b.name ? -1 : 1;
            }),
        );
    }, [props.allUsers]);

    useEffect(() => {
        if (props.allCalendars != null && props.allCalendars != undefined) {
            setAllCalendars(props.allCalendars);
        }
    }, [props.allCalendars]);

    const saveUser = (userid: string, start: Date, end: Date, calendar: Calendar, active: boolean): void => {
        console.log('Saving', userid, start, end, active);
        const utcStart: any = start === null ? null : start.getISOStringMidnight();
        const utcEnd: any = end === null ? null : end.getISOStringMidnight();
        uiCtx.timeApi.setUserAdmin(userid, utcStart, utcEnd, calendar, !active).then(response => {
            console.log('Saved!');
            let changedUser = allUsers.find(u => u.id == userid);
            if (changedUser != undefined) {
                changedUser.start = start != null ? new Date(start).removeTimeZone() : null;
                changedUser.end = end != null ? new Date(end).removeTimeZone() : null;
                changedUser.calendar = calendar;
                changedUser.isNotActive = !active;
                setAllUsers(prev => [...prev.filter(p => p.id != userid), changedUser]);
            }
            //Do nothing
        });
    };

    const deleteUser = async (userid: string) => {
        const rslt = await uiCtx.timeApi.deleteUser(userid);
        const filteruseridx = filteredUsers.indexOf(filteredUsers.find(fu => fu.id == userid));
        if (filteruseridx >= 0) {
            filteredUsers.splice(filteruseridx, 1);
            setFilteredUsers([...filteredUsers]);
        }

        const alluseridx = allUsers.indexOf(allUsers.find(au => au.id == userid));
        if (alluseridx >= 0) {
            allUsers.splice(alluseridx, 1);
            setAllUsers([...allUsers]);
        }

        return rslt;
    };

    const onFilterBoxChanged = (value: string): void => {
        setFilterInputValue(value);
        filterUsers(value, filterActiveValue, filterCalendarValue);
    };

    const onActiveFilterChanged = (value: string): void => {
        setfilterActiveValue(value);

        filterUsers(filterInputValue, value, filterCalendarValue, 50);
    };

    const onCalendarFilterChanged = (value: string): void => {
        setfilterCalendarValue(value);

        filterUsers(filterInputValue, filterActiveValue, value, 50);
    };

    const filterUsers = (value: string, activevalue: string, chosencalendar: string, timeout: number = 750): void => {
        if (props.allUsers.length > 1000) {
            if (value.length < 3 && value.length > 0) {
                setSearchIsError('Type at least 3 characters');
                return;
            } else {
                setSearchIsError('');
            }
        }

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setTypingTimeout(
            setTimeout(() => {
                if (value == '') {
                    setFilteredUsers(
                        allUsers
                            .filter(
                                (au: User) =>
                                    (activevalue == 'All' || (au.isNotActive && activevalue == 'Inactive') || (!au.isNotActive && activevalue == 'Active')) &&
                                    (chosencalendar == 'All Calendars' ||
                                        (au.calendar == undefined && 'Default' == chosencalendar) ||
                                        (au.calendar != undefined && au.calendar.name == chosencalendar)),
                            )
                            .sort((a: any, b: any) => {
                                return a.name < b.name ? -1 : 1;
                            }),
                    );
                    return;
                }
                setFilteredUsers(
                    allUsers
                        .filter(
                            (au: User) =>
                                (activevalue == 'All' || (au.isNotActive && activevalue == 'Inactive') || (!au.isNotActive && activevalue == 'Active')) &&
                                (chosencalendar == 'All Calendars' || (au.calendar == undefined && 'Default' == chosencalendar) || (au.calendar != undefined && au.calendar.name == chosencalendar)) &&
                                (au.name.toLowerCase().indexOf(value.toLowerCase()) > -1 || au.userNumber?.toLowerCase().indexOf(value.toLowerCase()) > -1),
                        )
                        .sort((a: any, b: any) => {
                            return a.name < b.name ? -1 : 1;
                        }),
                );
            }, timeout),
        );
    };

    return (
        <>
            <PageHeader title="Users Configuration" icon="TemporaryUser" />

            <ContentSection alignContent="center">
                <div style={{ width: '100%', maxWidth: 770 }}>
                    <SearchBox
                        placeholder="Filter users"
                        iconProps={{ iconName: 'Filter' }}
                        onChange={(e, v) => onFilterBoxChanged(v)}
                        value={filterInputValue}
                        styles={{
                            root: {
                                // TODO: fix color
                                borderColor: `${searchIsError != '' ? 'rgba(155,42,49,0.75)' : 'rgba(208,208,208,1)'}`,
                                borderWidth: '1px',
                                '&:hover': {
                                    // TODO: fix color
                                    borderColor: `${searchIsError != '' ? 'rgba(155,42,49,1)' : 'rgba(73,74,130,0.75)'}`,
                                },
                                '&::after': {
                                    // TODO: fix color
                                    borderColor: `${searchIsError != '' ? 'rgba(155,42,49,1)' : 'rgba(96,97,170,1)'}`,
                                },
                            },
                        }}
                    />
                </div>
            </ContentSection>

            <PageContent>
                <ContentSection alignContent="center" noPaddingTop>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <TableHeaderRow>
                            <Stack verticalAlign="center" styles={{ root: { width: '350px', textAlign: 'left', paddingLeft: 8 } }}>
                                <h4>User</h4>
                            </Stack>
                            <Stack verticalAlign="center" className="datepickercolumn">
                                <h5>First workday</h5>
                            </Stack>

                            <Stack verticalAlign="center" className="datepickercolumn">
                                <h5>Last workday</h5>
                            </Stack>
                            {props.calendarsEnabled && (
                                <Stack verticalAlign="center" className="calendarpickerheadercolumn">
                                    <h5>
                                        <FilterFlyout choices={['All Calendars', 'Default', ...allCalendars.map(v => v.name)]} defaultchoice={filterCalendarValue} callback={onCalendarFilterChanged}>
                                            Calendar
                                        </FilterFlyout>
                                    </h5>
                                </Stack>
                            )}
                            <Stack verticalAlign="center" className="activecolumn">
                                <h5>
                                    <FilterFlyout choices={['All', 'Active', 'Inactive']} defaultchoice={filterActiveValue} callback={onActiveFilterChanged}>
                                        Active
                                    </FilterFlyout>
                                </h5>
                            </Stack>
                        </TableHeaderRow>

                        {filteredUsers.map((user: User, index: any) => {
                            if (index > showCount) {
                                return null;
                            }

                            return (
                                <React.Fragment key={user.id}>
                                    <div className="personarow" style={{ display: 'flex' }}>
                                        <CreateUserAdminRow
                                            active={!user.isNotActive}
                                            username={user.name}
                                            usernumber={user.userNumber}
                                            start={user.start != null ? new Date(user.start).setUTCTime() : null}
                                            end={user.end != null ? new Date(user.end).setUTCTime() : null}
                                            jobtitle={user.jobTitle}
                                            usersurname={user.surname}
                                            userid={user.id}
                                            userCalendar={user.calendar}
                                            onUserUpdated={saveUser}
                                            onUserDelete={deleteUser}
                                            calendarsEnabled={props.calendarsEnabled}
                                            allCalendars={allCalendars}
                                        />
                                    </div>
                                    {/* {index == 30 &&
                                                <div><i>please use the filter in the top...</i></div>
                                            } */}
                                </React.Fragment>
                            );
                        })}
                        {showCount < filteredUsers?.length && <DefaultButton text="Show more" onClick={() => setShowCount(s => s + 25)} styles={{ root: { alignSelf: 'center', border: 'none' } }} />}
                    </Stack>
                </ContentSection>
            </PageContent>
        </>
    );
}

interface propsUserAdministrationRow {
    active: boolean;
    username: string;
    usernumber: string;
    jobtitle: string;
    start: Date;
    end: Date;
    usersurname: string;
    userid: string;
    userCalendar: Calendar;
    onUserUpdated: Function;
    onUserDelete: Function;
    calendarsEnabled: boolean;
    allCalendars: Calendar[];
}
function CreateUserAdminRow(props: propsUserAdministrationRow) {
    const [userid, setUserid] = useState<string>(null);
    const [userCalendar, setUserCalendar] = useState<Calendar>(props.userCalendar);
    const [active, setActive] = useState<boolean>(null);
    const [username, setUserName] = useState<string>(null);
    const [usernumber, setUserNumber] = useState<string>(null);
    const [usersurname, setUsersurname] = useState<string>(null);
    const [start, setStart] = useState<Date>(null);
    const [end, setEnd] = useState<Date>(null);
    const [jobtitle, setJobtitle] = useState<string>(null);
    const [showDeleteUserDlg, setShowDeleteUserDlg] = useState<boolean>(false);
    const [deleteUserObject, setDeleteUserObject] = useState<any>(null);
    const [deleting, setDeleting] = useState<boolean>(false);

    const [allCalendars, setAllCalendars] = useState<Calendar[]>(props.allCalendars);

    const [calendarOptions, setCalendarOptions] = useState<IDropdownOption[]>([
        { key: 'NULL', text: 'Default' },
        ...props.allCalendars.map(cal => {
            return { key: cal.id, text: cal.name };
        }),
    ]);

    const submenuRef = React.useRef(null);

    useEffect(() => {
        if (props.userid != null) {
            setActive(props.active ?? true);
            setUserName(props.username || '');
            setUserNumber(props.usernumber || null);
            setJobtitle(props.jobtitle || null);
            setUserid(props.userid);
            setUsersurname(props.usersurname || '');
            setUserCalendar(props.userCalendar || null);
        }
        if (props.start != undefined && props.start != null && new Date(props.start) >= new Date(1900, 0, 1, 0, 0, 0)) {
            setStart(new Date(props.start));
        } else {
            setStart(null);
        }
        if (props.end != undefined && props.end != null && new Date(props.end) >= new Date(1900, 0, 1, 0, 0, 0)) {
            setEnd(new Date(props.end));
        } else {
            setEnd(null);
        }
        if (props.allCalendars != undefined && props.allCalendars != null) {
            setCalendarOptions([
                { key: 'NULL', text: 'Default' },
                ...props.allCalendars.map(cal => {
                    return { key: cal.id, text: cal.name };
                }),
            ]);
        }
    }, []);

    useEffect(() => {
        if (props.allCalendars != undefined && props.allCalendars != null) {
            setAllCalendars(props.allCalendars);
            setCalendarOptions([
                { key: 'NULL', text: 'Default' },
                ...props.allCalendars.map(cal => {
                    return { key: cal.id, text: cal.name };
                }),
            ]);
        }
    }, [props.allCalendars]);

    return (
        <>
            <div style={{ width: '350px', textAlign: 'left' }}>
                <div className="personarowpersona">
                    <Persona
                        //imageUrl={'https://outlook.office.com/owa/service.svc/s/GetPersonaPhoto?email=' + user.email + '&UA=0&size=HR48x48'}
                        imageInitials={username?.substring(0, 1) + usersurname?.substring(0, 1)}
                        text={username}
                        secondaryText={usernumber ?? jobtitle ?? ''}
                        showSecondaryText={true}
                        size={PersonaSize.size24}
                        presence={PersonaPresence.none}
                        imageAlt={username + ', ' + jobtitle}
                        itemID={userid}
                    />
                </div>
            </div>
            <div className="datepickercolumn">
                {!active ? (
                    <></>
                ) : (
                    <DatePicker
                        value={start!}
                        firstDayOfWeek={DayOfWeek.Monday}
                        onSelectDate={d => {
                            let newstart = start != undefined && start != null && d != undefined && d != null && start.getDate() == d.getDate() ? undefined : d;
                            setStart(newstart);
                            props.onUserUpdated(userid, newstart, end, userCalendar, active);
                        }}
                        placeholder="Select a date..."
                        ariaLabel="Select a date"
                    />
                )}
            </div>

            <div className="datepickercolumn">
                <div className="datepicker">
                    {!active ? (
                        <></>
                    ) : (
                        <DatePicker
                            value={end!}
                            firstDayOfWeek={DayOfWeek.Monday}
                            onSelectDate={d => {
                                let newend = end != undefined && end != null && d != undefined && d != null && end.getDate() == d.getDate() ? undefined : d;
                                setEnd(newend);
                                props.onUserUpdated(userid, start, newend, userCalendar, active);
                            }}
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                        />
                    )}
                </div>
            </div>

            {props.calendarsEnabled && (
                <div className="calendarpickercolumn">
                    <div className="calendarpicker">
                        {!active ? (
                            <></>
                        ) : (
                            <Dropdown
                                options={calendarOptions}
                                selectedKey={userCalendar?.id == null ? 'NULL' : userCalendar.id}
                                onChange={(e, v) => {
                                    let newUserCalendar = allCalendars.find(c => c.id == v.key);
                                    if (newUserCalendar != userCalendar) {
                                        console.log('newUserCalendar', newUserCalendar);
                                        setUserCalendar(newUserCalendar);
                                        props.onUserUpdated(userid, start, end, newUserCalendar, active);
                                    }
                                }}
                                // defaultSelectedKey={userCalendar?.id == null ? 'null' : userCalendar.id}
                                styles={{ root: { minWidth: 150 } }}
                            />
                        )}
                    </div>
                </div>
            )}

            <div className="activecolumn">
                <div>
                    <Toggle
                        checked={active}
                        onText="Active"
                        offText="Inactive"
                        role="checkbox"
                        onChange={(e, v) => {
                            setActive(v);
                            props.onUserUpdated(userid, start, end, userCalendar, v);
                        }}
                        styles={{ root: { marginBottom: 0, marginLeft: 20 } }}
                    />
                </div>
            </div>
            <div>
                <IconButton
                    ref={submenuRef}
                    menuIconProps={{ iconName: 'More' }}
                    menuProps={{
                        items: [
                            {
                                key: 'delete',
                                text: 'Delete',
                                iconProps: { iconName: 'Delete' },
                                onClick: () => {
                                    setShowDeleteUserDlg(true);
                                    setDeleteUserObject({ username, userid });
                                },
                            },
                        ],
                    }}
                ></IconButton>
                <Dialog
                    hidden={!showDeleteUserDlg}
                    onDismiss={() => {
                        setShowDeleteUserDlg(false);
                    }}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Delete User',
                        closeButtonAriaLabel: 'Cancel',
                        subText:
                            'Deleting the "' +
                            (deleteUserObject != null ? deleteUserObject.username : '') +
                            '" user will delete all time entries, assignments, delegates and other data related to the user. \nDo you want to continue?',
                    }}
                    //modalProps={modalProps}
                >
                    {deleting ? (
                        <Spinner label="Deleting, please wait..." />
                    ) : (
                        <DialogFooter>
                            <PrimaryButton
                                onClick={() => {
                                    setDeleting(true);
                                    props.onUserDelete(deleteUserObject.userid).then(e => {
                                        console.log('deleted user', e);
                                        if (e == false || e == 'false') {
                                            alert('ERROR: Exact user could not be found. Please contact Administrator, user Id was: ' + deleteUserObject.userid);
                                        }
                                        setShowDeleteUserDlg(false);
                                        setDeleting(false);
                                        setDeleteUserObject(null);
                                    });
                                }}
                                text="Delete"
                            />
                            <DefaultButton onClick={() => setShowDeleteUserDlg(false)} text="Cancel" />
                        </DialogFooter>
                    )}
                </Dialog>
            </div>
        </>
    );
}

export default UsersAdministration;
