import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useUiContext } from '../../components/Contexts/UiContext';
import { ProjectSource } from '../../Model/ProjectSource';


const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 225 } };

interface AdminProjectSourcesDropdownProps {
    callback: any;
    selectedProjectSource: any;
}

const AdminProjectSourcesDropdown: React.FC<AdminProjectSourcesDropdownProps> = (props) => {

    const uiCtx = useUiContext();

    const [projectSources, setProjectSources] = useState<ProjectSource[]>([]);

    useEffect(() => {
        uiCtx.timeApi.getProjectSources().then((projectSources: ProjectSource[]) => {
            setProjectSources(projectSources);
        });
    }, [])

    const projectSourcesDropdownOptions: IDropdownOption[] = 
        projectSources.map(ps => 
            ({ key: ps.id, text: ps.name })
        ) as IDropdownOption[];
    
    return (
        <Dropdown 
            label="Icon for Administrative Projects" 
            options={projectSourcesDropdownOptions} 
            selectedKey={props.selectedProjectSource ? props.selectedProjectSource.key : undefined} 
            onChange={props.callback} 
            placeholder="Select project source (icon)" 
            styles={dropdownStyles}
        />
    )
}

export default AdminProjectSourcesDropdown;