import { validationRules } from '../validationRules';
import { ValidateResponse } from '../Validator';

export const EmptyTimeSheetNotAllowed = (): ValidateResponse => {
    let valid = true;

    let response: ValidateResponse = {
        reason: !valid ? `Timesheet must not contain cells with errors. Please review the cells with red borders below.` : null,
        valid: valid,
        name: validationRules.NoCellErrors,
        rejected: !valid,
    };

    return response;
};
