import EDays from '../../Model/EDays';
import PeriodDay from '../../Model/PeriodDay';
import { IUiContext } from '../Contexts/UiContext';

export const convertHoursToProcent = (hours: number, days: number, hoursPrDay: number) => {
    if (Number.isNaN(hours)) {
        return 0;
    }
    const totalHours = days * hoursPrDay;
    return (hours / totalHours) * 100;
};

export const convertProcentToHours = (procent: number, days: number, hoursPrDay: number) => {
    if (Number.isNaN(procent)) {
        return 0;
    }
    const totalHours = days * hoursPrDay;
    return (procent * totalHours) / 100;
};

export const getPeriodHours = timeentries => {
    let hours = 0;

    timeentries.forEach(entry => {
        hours += entry?.hours || 0;
    });

    return hours;
};

// export const getCapacityPrDay = (perioddays: PeriodDay[], uiCtx: IUiContext) => {
export const getCapacityPrDay = (perioddays: any[], uiCtx: IUiContext) => {
    let capacity = 0;
    perioddays.forEach(day => {
        // if (day.name.toLocaleLowerCase() !== EDays.Saturday && day.name.toLocaleLowerCase() !== EDays.Sunday) {
        capacity += uiCtx.user.user.availability;
        // };
    });
    return capacity / perioddays.length;
};

export const isValidNumber = (num: string) => {
    if (num === '') {
        return true;
    }
    if (isNaN(parseFloat(num))) {
        return false;
    }
    return true;
};

export const parseNumber = (num: string) => {
    if (num === '') {
        return 0;
    }
    if (isNaN(parseFloat(num))) {
        return 0;
    }
    return parseFloat(num);
};
