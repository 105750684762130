import React, { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar'
import { useUiContext } from '../components/Contexts/UiContext';
import { MathToFixed } from '../components/Utils';

interface propsUserProjectHoursByWeekBarChart {
    BarChartData: any[];
    NewProjectSelected: Function,
    ProjectSelected: string
}

function UserProjectHoursByWeekBarChart(props: propsUserProjectHoursByWeekBarChart) {

    const [barChartData, setBarChartData] = useState<any[]>();
    const [barChartKeys, setBarChartKeys] = useState<string[]>();
    const uiCtx = useUiContext();


    useEffect(() => {
        if (props.BarChartData != null && props.BarChartData != undefined) {
            //Keys & Data
            console.debug("Raw Bar Chart Data", props.BarChartData);
            var foundBarChartKeys = [];
            var chartData = [];
            let rawdata = [...props.BarChartData];
            rawdata?.forEach(perioddata => {
                let newprojdata = {};
                newprojdata['period'] = "" + perioddata.periodname;
                newprojdata['sort'] = (perioddata.year * 100) + perioddata.weekno;
                perioddata?.chartDataProjects?.forEach(projectdata => {
                    if (foundBarChartKeys.indexOf(projectdata.projectname) < 0) {
                        foundBarChartKeys.push(projectdata.projectname);
                    }
                    if (props.ProjectSelected == null || props.ProjectSelected == projectdata.projectname) {
                        let actualhours = 0;
                        projectdata?.chartDataTasks?.forEach(taskdata => {
                            taskdata?.chartDataTimes?.forEach(timedata => {
                                actualhours = actualhours + timedata.actual;
                            });
                        });

                        newprojdata[projectdata.projectname] = MathToFixed(actualhours, 2);;
                    }

                });
                chartData.push(newprojdata);
            });
            console.debug("Bar Chart Data", chartData.sort((a, b) => { return (a.sort < b.sort) ? -1 : 1 }));
            console.debug("Bar Chart Keys", foundBarChartKeys.sort((a, b) => { return (a > b) ? -1 : 1 }));
            setBarChartData(chartData.sort((a, b) => { return (a.sort < b.sort) ? -1 : 1 }));
            setBarChartKeys(foundBarChartKeys.sort((a, b) => { return (a > b) ? -1 : 1 }));
        }
    }, [props.BarChartData, props.ProjectSelected]);


    const barCustomTooltip = (id, value, color, data, indexValue) => {
        console.log(data);
        return (
            <div
                style={{
                    padding: 12,
                    color,
                    background: '#222222',

                }}
            >
                < span > Look, I'm custom :)</span>
                < br />
                <strong>
                    {id}: {value}
                </strong >
            </div >
        )
    }


    return (
        <ResponsiveBar
            onClick={(data, e) => {
                console.debug(`Project Selected: ${data["id"]}, selected data: ${data["value"]}`, e, data);
                e.stopPropagation();
                props.NewProjectSelected(data["id"]);

            }}
            data={barChartData}
            keys={barChartKeys}
            indexBy="period"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            //colors={{ scheme: 'pink_yellowGreen' }} 
            colors={["#31B6FD", "#4584D3", "#5BD078", "#A5D028", "#F5C040", "#05E0DB", "#3153FD", "#4C45D3", "#5BD0B0", "#54D028", "#D0F540", "#057BE0"]}
            theme={uiCtx.isDarkmode ? {
                textColor: '#ffffff',
                tooltip: { basic: { color: '#000000' } }
            } : {}}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Period',
                legendPosition: 'middle',
                legendOffset: 32
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Hours',
                legendPosition: 'middle',
                legendOffset: -40
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            animate={true}
            // tooltip={({ id, value, color, data, indexValue, }) => barCustomTooltip(id, value, color, data, indexValue)}

        />
    );
}

export default UserProjectHoursByWeekBarChart;
