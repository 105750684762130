import { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { ICommandBarItemProps, Stack, mergeStyles, AnimationClassNames, CommandBar, Modal, IContextualMenuItem, useTheme } from '@fluentui/react';
import { CardItem } from './components/CardItem/CardItem';
import { EditDataverseIntegration } from './integrations/Dataverse/EditDataverseIntegration';
import { DataverseLogo } from './components/Logos/DataverseLogo';
import { NewDataverseIntegration } from './integrations/Dataverse/NewDataverseIntegration';
import { CardItemShimmer } from './components/CardItem/CardItemShimmer';
import { CardItemNoIntegrations } from './components/CardItem/CardItemNoIntegrations';
import { IntegrationType } from '../../api/generated/data-contracts';
import { INTEGRATIONS_SETTINGS } from '../../constants/queryKeys';
import { useMemoQueryKey } from '../../hooks/useMemoQueryKey';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { CSSTransitionWithRefRenderProps } from '../CSSTransitionWithRefRenderProps';
import { ApiCalls } from '../../api/api';
import { MicrosoftEntraIdLogo } from './components/Logos/MicrosoftEntraIdLogo';
import { IntegrationTypeGeneric, IsDataverseIntegration } from './IntegrationTypeGeneric';
import { NewMicrosoftEntraIdIntegration } from './integrations/MicrosoftEntraId/NewMicrosoftEntraIdIntegration';
import { EditMicrosoftEntraIdIntegration } from './integrations/MicrosoftEntraId/EditMicrosoftEntraIdIntegration';

export const IntegrationsV2 = () => {
    const theme = useTheme();
    const [showAddIntegration, setShowAddIntegration] = useState<IntegrationType | null>(null);
    const [editableIntegration, setEditableIntegration] = useState<IntegrationTypeGeneric<any, any> | null>(null);
    const defaultData = useRef([] as IntegrationTypeGeneric<any, any>[]).current;
    const qKey = useMemoQueryKey(INTEGRATIONS_SETTINGS);
    const { data: integrations = defaultData, isLoading } = useQuery({
        queryKey: qKey,
        queryFn: async () => ApiCalls.integrationEventList().then(res => res.data),
    });
    const location = useLocation();
    const queryClient = useQueryClient();
    const updateIntegrations = useCallback(
        (updater: IntegrationTypeGeneric<any, any>[] | ((data: IntegrationTypeGeneric<any, any>[]) => IntegrationTypeGeneric<any, any>[])) => {
            queryClient.setQueryData<IntegrationTypeGeneric<any, any>[]>(qKey, typeof updater === 'function' ? updater : () => updater);
        },
        [qKey, queryClient],
    );

    const toolbarItems = useMemo(
        (): ICommandBarItemProps[] => [
            {
                key: 'add-integration',
                text: 'Add Integration',
                iconProps: { iconName: 'Add' },
                // disabled: false,
                subMenuProps: {
                    items: [
                        {
                            key: 'Dataverse',
                            text: 'Dataverse',
                            onClick: () => setShowAddIntegration('Dataverse'),
                            disabled: integrations.some((integration: IntegrationTypeGeneric<any, any>) => integration.type === 'Dataverse'),
                            onRenderIcon: () => {
                                return (
                                    <div style={{ height: 30, width: 30, alignItems: 'center', display: 'flex', padding: '4px' }}>
                                        <DataverseLogo />
                                    </div>
                                );
                            },
                            // Fluent 8.30.0 bug: The custom icon render hook above won't get called if no iconProps is provided, an empty object is enough
                            iconProps: {},
                        },
                        {
                            key: 'MicrosoftEntraId',
                            text: 'Microsoft Entra ID',
                            onClick: () => setShowAddIntegration('MicrosoftEntraId'),
                            disabled: integrations.some((integration: IntegrationTypeGeneric<any, any>) => integration.type === 'MicrosoftEntraId'),
                            onRenderIcon: () => {
                                return (
                                    <div style={{ height: 30, width: 30, alignItems: 'center', display: 'flex', padding: '4px' }}>
                                        <MicrosoftEntraIdLogo />
                                    </div>
                                );
                            },
                            // Fluent 8.30.0 bug: The custom icon render hook above won't get called if no iconProps is provided, an empty object is enough
                            iconProps: {},
                        },
                    ] as IContextualMenuItem[],
                    calloutProps: {
                        dismissOnTargetClick: true,
                    },
                },
            },
        ],
        [],
    );

    const cardContainer = mergeStyles({
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fit, minmax(${'300px'}, ${'400px'}))`,
        gridGap: `${theme.spacing.l1}`,
        alignItems: 'flex-start',
    });

    const closeEditIntegration = useCallback(() => setEditableIntegration(null), []);

    const closeNewIntegration = useCallback(() => setShowAddIntegration(null), []);

    const render = useMemo(() => {
        if (isLoading) {
            return <CardItemShimmer />;
        }
        if (integrations.length === 0) {
            return <CardItemNoIntegrations />;
        }
        return (
            <TransitionGroup appear className={cardContainer} inlist={integrations} key={location.pathname}>
                {integrations.map((integration, i) => {
                    return (
                        <CSSTransitionWithRefRenderProps
                            key={integration.id ?? i}
                            mountOnEnter
                            unmountOnExit
                            timeout={{ enter: i * 100, exit: 0 }}
                            classNames={'card'}
                        >
                            {ref => <CardItem integration={integration} ref={ref} setEditableIntegration={setEditableIntegration} />}
                        </CSSTransitionWithRefRenderProps>
                    );
                })}
            </TransitionGroup>
        );
    }, [cardContainer, integrations, isLoading, location.pathname]);

    return (
        <Stack tokens={{ padding: '10px 20px 20px' }}>
            <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
                className={AnimationClassNames.fadeIn200}
                tokens={{ padding: '0 0 10px 0' }}
            >
                <CommandBar
                    //
                    items={toolbarItems}
                    styles={{ root: { padding: 0 } }}
                />
            </Stack>
            {render}

            <EditIntegration
                close={closeEditIntegration}
                integration={editableIntegration}
                editIntegrations={updateIntegrations}
                setEditableIntegration={setEditableIntegration}
            />
            <NewIntegration close={closeNewIntegration} integrationType={showAddIntegration} addIntegration={updateIntegrations} />
        </Stack>
    );
};

const EditIntegration = ({
    integration,
    close,
    editIntegrations,
    setEditableIntegration,
}: {
    integration: IntegrationTypeGeneric<any, any>;
    close: () => void;
    editIntegrations: (updater: (data: IntegrationTypeGeneric<any, any>[]) => IntegrationTypeGeneric<any, any>[]) => void;
    setEditableIntegration: React.Dispatch<React.SetStateAction<IntegrationTypeGeneric<any, any>>>;
}) => {
    const render = useMemo(() => {
        console.log('integration', integration);

        if (integration === null) {
            return;
        }
        if (integration.type === 'Dataverse') {
            return (
                <EditDataverseIntegration
                    integration={integration}
                    close={close}
                    editIntegrations={editIntegrations}
                    setEditableIntegration={setEditableIntegration}
                />
            );
        }

        if (integration.type === 'MicrosoftEntraId') {
            return (
                <EditMicrosoftEntraIdIntegration
                    integration={integration}
                    close={close}
                    editIntegrations={editIntegrations}
                    setEditableIntegration={setEditableIntegration}
                />
            );
        }
    }, [close, editIntegrations, integration, setEditableIntegration]);

    useEffect(() => {
        const handler = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                close();
            }
        };
        window.addEventListener('keyup', handler, { capture: true });
        return () => window.removeEventListener('keyup', handler, { capture: true });
    }, [close]);

    if (!integration) {
        return null;
    }

    return <Modal isOpen>{render}</Modal>;
};

const NewIntegration = ({
    integrationType,
    close,
    addIntegration,
}: {
    integrationType: IntegrationType | null;
    close: () => void;
    addIntegration: (updater: (data: IntegrationTypeGeneric<any, any>[]) => IntegrationTypeGeneric<any, any>[]) => void;
}) => {
    const render = useMemo(() => {
        switch (integrationType) {
            case 'Dataverse':
                return <NewDataverseIntegration close={close} addIntegration={addIntegration} />;
            case 'MicrosoftEntraId':
                return <NewMicrosoftEntraIdIntegration close={close} addIntegration={addIntegration} />;
            default:
                return <div>Unknown integration type</div>;
        }
    }, [addIntegration, close, integrationType]);

    useEffect(() => {
        const handler = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                close();
            }
        };
        window.addEventListener('keyup', handler, { capture: true });
        return () => window.removeEventListener('keyup', handler, { capture: true });
    }, [close]);

    if (integrationType === null) {
        return null;
    }

    return <Modal isOpen>{render}</Modal>;
};
