import { FC } from 'react';
import { IImportResultProps } from '../types/timeEntryTypes';
import { DetailsList, IColumn, PrimaryButton, SelectionMode, Stack } from '@fluentui/react';
import { TimeEntryError, TimeEntryErrorType } from '../../../../api/generated/data-contracts';

export const ImportResult: FC<IImportResultProps> = ({ importErrors, setFileData }) => {
    const onConfirm = () => {
        setFileData(undefined);
    };

    return (
        <Stack tokens={{ childrenGap: 32 }} horizontalAlign="center">
            {importErrors.length === 0 ? (
                <Stack tokens={{ childrenGap: 32 }} horizontalAlign="center">
                    <h1>All time entries where imported to Time!</h1>
                </Stack>
            ) : (
                <Stack tokens={{ childrenGap: 32 }} horizontalAlign="center">
                    <h1>Some time entries failed to be imported!</h1>
                    <DetailsList
                        setKey="items"
                        items={importErrors}
                        columns={setColumns()}
                        selectionMode={SelectionMode.none}
                        compact={true}
                        onRenderItemColumn={renderColumn}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                    />
                </Stack>
            )}
            <PrimaryButton onClick={onConfirm} text="Ok"></PrimaryButton>
        </Stack>
    );
};

const setColumns = (): IColumn[] => {
    return [
        { key: 'upn', name: 'User Principal Name', fieldName: 'upn', minWidth: 200, maxWidth: 300 },
        { key: 'date', name: 'Date', fieldName: 'date', minWidth: 100, maxWidth: 200 },
        { key: 'project', name: 'Project Name', fieldName: 'projectName', minWidth: 100, maxWidth: 200 },
        { key: 'task', name: 'Task Name', fieldName: 'taskName', minWidth: 100, maxWidth: 200 },
        { key: 'hours', name: 'Hours', fieldName: 'hours', minWidth: 100, maxWidth: 200 },
        { key: 'comment', name: 'Comment', fieldName: 'comment', minWidth: 100, maxWidth: 200 },
        { key: 'error', name: 'Error', fieldName: 'timeEntryErrorType', minWidth: 300, maxWidth: 400 },
    ];
};

const renderColumn = (item: TimeEntryError, index: number, column: IColumn) => {
    const fieldContent = item[column.fieldName as keyof TimeEntryError] as string;

    switch (column.key) {
        case 'error':
            return <span>{errorText(item.timeEntryErrorType)}</span>;
        case 'date':
            const parsedDate = new Date(item.date);
            return (
                <span>{`${parsedDate.getDate() < 10 ? '0' : ''}${parsedDate.getDate()}/${parsedDate.getMonth() + 1 < 10 ? '0' : ''}${
                    parsedDate.getMonth() + 1
                }/${parsedDate.getFullYear()}`}</span>
            );

        default:
            return <span>{fieldContent}</span>;
    }
};

const errorText = (error: TimeEntryErrorType) => {
    switch (error) {
        case 'UPN':
            return 'Failed to find user with user principal name';
        case 'Project':
            return 'Failed to find project with project name';
        case 'Task':
            return 'Failed to find task with task name';
        case 'Period':
            return 'Failed to find period for the date';
        default:
            return 'No import errors';
    }
};
