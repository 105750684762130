import PeriodDay from '../Model/PeriodDay';

export default class ApprovalLine {
    constructor(
        public periodId: string,
        public reportPeriodId: string,
        public userId: string,
        public periodName: string,
        public periodState: string,
        public personName: string,
        public hours: Number,
        public state: string,
        public startDate: Date,
        public endDate: Date,
        public submitComment: string,
        public comment: string,
        public hasUserCommentsMonday: boolean,
        public hasUserCommentsTuesday: boolean,
        public hasUserCommentsWednesday: boolean,
        public hasUserCommentsThursday: boolean,
        public hasUserCommentsFriday: boolean,
        public hasUserCommentsSaturday: boolean,
        public hasUserCommentsSunday: boolean,
        public dateTotalHours: DateTotalHours[],
        public periodDays: PeriodDay[],
        public approverName: string,
        public submitted: Date,
        public submittedBy: string,
        public autoapproval: boolean,
    ) {}
}

interface DateTotalHours {
    date: Date;
    hours: number;
    hasComment: boolean;
    hasFlag: boolean;
}
