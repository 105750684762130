import React, { useState } from 'react';
import { useUiContext } from '../Contexts/UiContext';
import PeriodDay from '../../Model/PeriodDay';
import { makeStyles } from '@fluentui/react';
import { constants } from '../../Styles/constants';
import { WorkDay } from '../../Workaround/TimeApiWorkaround';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export interface WorkDayStartEndCellProps {
    reportingPeriodId: string;
    periodDay: PeriodDay;
    startTime: Date;
    setStartTime: React.Dispatch<React.SetStateAction<Date>>;
    endTime: Date;
    setEndTime: React.Dispatch<React.SetStateAction<Date>>;
    type: 'start' | 'end';
}

export const WorkDayStartEndCell: React.FC<WorkDayStartEndCellProps> = props => {
    const styles = getStyles();

    const uiCtx = useUiContext();

    const [hasFocus, setHasFocus] = useState(false);

    const [cellText, setCellText] = useState(props.type === 'start' ? props.startTime : props.endTime);

    const onBlur = (date: Date): void => {
        setHasFocus(false);
        if (date != null && !isNaN(date.getTime())) {
            if (props.type === 'start') {
                props.setStartTime(date.removeTimeZone());
            } else {
                props.setEndTime(date.removeTimeZone());
            }

            const upsertContent = {
                date: props.periodDay.date.removeTimeZone(),
                start: props.type === 'start' ? date.removeTimeZone() : props.startTime?.removeTimeZone?.() || null,
                end: props.type === 'end' ? date.removeTimeZone() : props.endTime?.removeTimeZone?.() || null,
            } as WorkDay;

            uiCtx.timeApi.upsertWorkDayTime(props.reportingPeriodId, upsertContent);
        } else {
            if (props.type === 'start') {
                props.setStartTime(null);
            } else {
                props.setEndTime(null);
            }

            const upsertContent = {
                date: props.periodDay.date.removeTimeZone(),
                start: props.type === 'start' ? null : props.startTime?.removeTimeZone?.() || null,
                end: props.type === 'end' ? null : props.endTime?.removeTimeZone?.() || null,
            } as WorkDay;

            uiCtx.timeApi.upsertWorkDayTime(props.reportingPeriodId, upsertContent);
        }
    };

    const onChange = (date: Date) => {
        if (date) {
            setCellText(date);
        } else {
            setCellText(null);
        }
    };

    const deleteTime = () => {
        setCellText(null);
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        switch (event.key) {
            case 'Delete':
                deleteTime();
                break;
            case 'Backspace':
                deleteTime();
                break;
        }
    };

    return (
        <div className={`${styles.root} ${hasFocus ? styles.hasFocus : ''}`}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                    ampm={uiCtx.workDayAMPM}
                    ampmInClock={uiCtx.workDayAMPM}
                    minutesStep={Number(uiCtx.workDayMinutesInterval)}
                    value={cellText === undefined ? null : cellText}
                    onChange={newValue => {
                        onChange(newValue);
                    }}
                    onAccept={value => {
                        const dateString: string = value.toString();
                        const date: Date = new Date(dateString);
                        onBlur(date);
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                placeholder: 'hh:mm',
                            }}
                            size="small"
                            onBlur={() => onBlur(cellText)}
                            onKeyDown={e => onKeyDown(e)}
                        />
                    )}
                />
            </LocalizationProvider>
        </div>
    );
};

const getStyles = makeStyles(theme => ({
    root: [
        'workTimeCellInner',
        {
            position: 'relative',
            height: '100%',
            width: '100%',
            maxWidth: constants.cellInputMaxWidth,
            margin: '0 auto',
            button: {
                padding: 2,
            },
            input: {
                height: 30,
                width: '100%',
                fontSize: 13,
                fontFamily: 'inherit',
                borderWidth: 0,
                backgroundColor: 'rgba(0,0,0,0)',
                borderRadius: 2,
                outline: 'none',
                textAlign: 'center',
                color: theme.semanticColors.bodySubtext,
                cursor: 'text',
                padding: 0,
                '.isDarkmode &': {
                    colorScheme: 'dark',
                },
                '&::-webkit-calendar-picker-indicator': {
                    opacity: 0,
                    cursor: 'pointer',
                },
            },
            '.MuiInputAdornment-root': {
                marginLeft: 0,
            },
            svg: {
                padding: 0,
                height: 12,
                width: 12,
                color: theme.semanticColors.bodyText,
            },
        },
    ],
    hasFocus: {
        backgroundColor: theme.semanticColors.inputBackground,
        '& input': {
            backgroundColor: theme.semanticColors.inputBackground,
            color: theme.semanticColors.bodyText,
        },
        'input::-webkit-calendar-picker-indicator': {
            opacity: 0.95,
        },
        '&:after': {
            pointerEvents: 'none',
            content: "''",
            position: 'absolute',
            inset: '-1px',
            border: `2px solid ${theme.palette.accent}`,
            borderRadius: 2,
        },
    },
    input: {
        height: 30,
        width: '100%',
        fontFamily: 'inherit',
        borderWidth: 0,
        backgroundColor: 'rgba(0,0,0,0)',
        borderRadius: 2,
        outline: 'none',
        textAlign: 'center',
        color: theme.semanticColors.bodySubtext,
        cursor: 'text',
        '.isDarkmode &': {
            colorScheme: 'dark',
        },
        '&::-webkit-calendar-picker-indicator': {
            opacity: 0,
            cursor: 'pointer',
        },
    },
}));
