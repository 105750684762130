import React from 'react';
import { mergeStyles, getTheme, Stack } from '@fluentui/react';
import { EndPointSolidIcon } from '@fluentui/react-icons-mdl2';
const theme = getTheme();

interface IProps {
    flagged: boolean;
    alignLeft?: true | never;
}

export const ApproverFlaggedColumn: React.FC<IProps> = ({ flagged, alignLeft }) => {
    if (!flagged) {
        return null;
    }

    return (
        <Stack verticalAlign="center" horizontalAlign={alignLeft ? 'start' : 'center'} styles={{ root: { height: '100%' } }}>
            <EndPointSolidIcon className={mergeStyles({ color: theme.palette.red, fontSize: 14 })} />
        </Stack>
    );
};
