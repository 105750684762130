import produce from 'immer';
import { useCallback, useMemo, useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import create from 'zustand';
import shallow from 'zustand/shallow';
import { AnyObject } from 'immer/dist/internal.js';
import { TimeConfiguration, TimeUserFeatures } from '../../api/generated/data-contracts';
import { CONFIGURATION_BASIC, CONFIGURATION_FULL } from '../../constants/queryKeys';
import { ApiCalls } from '../../api/api';

type Mutable<T> = {
    -readonly [K in keyof T]: T[K];
};

type MutableProps<T extends AnyObject, J extends keyof T> = Omit<T, J> & Mutable<Pick<T, J>>;

export type SettingsContext = {
    editableSettings: TimeConfiguration | null;
    readonly settings: TimeConfiguration | null;
    readonly setSettings: (updater: (newSettings: TimeConfiguration | null) => void) => void;
    readonly setSettingsContext: (updater: (newSettings: MutableProps<SettingsContext, 'settings'>) => void) => void;
};

const useSettingsContext = create<SettingsContext>((set, get, api) => {
    return {
        settings: null,
        editableSettings: null,
        // loadingSettings: true,
        setSettings: newSettings =>
            set(
                produce<SettingsContext>(state => {
                    newSettings(state.editableSettings);
                }),
            ),
        setSettingsContext: updater => {
            set(produce(updater));
        },
    };
});

export const useSetSettings = () => {
    return useSettingsContext(ctx => ctx.setSettings);
};

export type SettingState<T> = <Key extends keyof T>(key: Key) => readonly [T[Key], (newValue: T[Key]) => void];

export const useSettingState = <
    Read extends (settings: TimeConfiguration) => any,
    Write extends (settings: TimeConfiguration, newValue: ReadReturn) => void,
    ReadReturn = ReturnType<Read>,
>(
    read: Read,
    write: Write,
) => {
    return useSettingsContext(ctx => {
        const writeWrapper = (newValue: ReadReturn) => {
            ctx.setSettings(ctx => write(ctx, newValue));
        };
        return [read(ctx.editableSettings) as ReadReturn, writeWrapper] as const;
    }, shallow);
};

export const usePersistSettings = () => {
    const { settings, editableSettings, setSettingsContext } = useSettingsContext(ctx => {
        return {
            settings: ctx.settings,
            editableSettings: ctx.editableSettings,
            setSettingsContext: ctx.setSettingsContext,
        };
    }, shallow);
    const settingsBackup = useRef<TimeConfiguration | null>(null);
    const settingsHasChanged = useMemo(() => settings !== editableSettings, [editableSettings, settings]);
    const queryClient = useQueryClient();

    const { mutateAsync, isLoading, error, isSuccess } = useMutation<TimeConfiguration, any>(
        async () => {
            // if (isLoading || error || !settingsHasChanged) {
            if (isLoading || !settingsHasChanged) {
                return;
            }
            settingsBackup.current = settings;
            setSettingsContext(ctx => {
                ctx.settings = ctx.editableSettings;
            });
            return ApiCalls.configurationFullCreate(editableSettings).then(() => editableSettings);
        },
        {
            onSuccess: updatedSettings => {
                queryClient.invalidateQueries([CONFIGURATION_FULL]);
                queryClient.setQueryData<TimeUserFeatures>([CONFIGURATION_BASIC], currentState => {
                    return {
                        ...currentState,
                        approval: {
                            ...currentState,
                            projectManagerApproval: {
                                ...currentState.approval.projectManagerApproval,
                                ...updatedSettings.userFeatures.approval.projectManagerApproval,
                            },
                        },
                    };
                });
                settingsBackup.current = null;
            },
        },
    );

    const persistSettings = useCallback(() => {
        return mutateAsync();
    }, [mutateAsync]);

    const revertChanges = useCallback(() => {
        const currentSettingsBackup = settingsBackup.current;
        setSettingsContext(ctx => {
            ctx.editableSettings = currentSettingsBackup || ctx.settings;
            if (currentSettingsBackup) {
                ctx.settings = currentSettingsBackup;
            }
        });
        settingsBackup.current = null;
    }, [setSettingsContext]);

    return {
        persistSettings,
        revertChanges,
        isLoading,
        error,
        isSuccess,
        settingsHasChanged,
    };
};

export const useSetInitialSettings = () => {
    const setSettingsContext = useSettingsContext(settings => {
        return settings.setSettingsContext;
    });

    return useCallback(
        (settings: TimeConfiguration | null) => {
            setSettingsContext(ctx => {
                ctx.editableSettings = settings;
                ctx.settings = settings;
            });
        },
        [setSettingsContext],
    );
};

export const useGetSettings = () => {
    return useSettingsContext(ctx => {
        return {
            settings: ctx.settings,
            editableSettings: ctx.editableSettings,
        };
    }, shallow);
};
