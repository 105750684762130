import React from 'react';
import { Stack, Spinner, SpinnerSize } from '@fluentui/react'; 
import { QuotesLoader } from './QuotesLoader';


export const SpinLoader = () => {

    return (
        <Stack className="logindialog" horizontalAlign="center" verticalAlign="center" style={{ height: "70vh" }}>
            <Spinner label="just a sec..." />
        </Stack> 
    )
}

export const SpinLoaderSlow = () => {

    return (
        /*<Stack className="logindialog" horizontalAlign="center" verticalAlign="center" style={{ height: "70vh" }}>
            <Spinner size={SpinnerSize.large} label="Hang in, we are fetching a lot of data for you..." />
        </Stack> */
        <QuotesLoader loadingtext="Hang in, we are fetching a lot of data for you..." />
    )
}