import { useMemo } from 'react';
import { Text } from '@fluentui/react';

export const GridRow = ({ left, right }: { left: React.ReactNode; right: React.ReactNode }) => {
    const titleText = useMemo(() => {
        if (left && typeof left === 'object') {
            return left;
        }
        return <Text styles={{ root: { fontWeight: 600 } }}>{left}</Text>;
    }, [left]);
    const dataText = useMemo(() => {
        if (right && typeof right === 'object') {
            return right;
        }
        return (
            <Text nowrap block>
                {right}
            </Text>
        );
    }, [right]);
    return (
        <>
            {titleText} {dataText}
        </>
    );
};
