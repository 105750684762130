import { useCallback, useMemo, useState } from 'react';
import { APPROVAL_STATES } from '../../../../CONSTANTS';
import { ApprovalState } from '../../../../../../../../api/generated/data-contracts';
// import { TimeEntryApprovalDtoState, isApprovalRow, useMissingApprovalCount, useApprovalState } from '../../../../../../../../_grid/grid';
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    Dropdown,
    IDropdownOption,
    IDropdownProps,
    IRenderFunction,
    Icon,
    PrimaryButton,
    Stack,
    Text,
    useTheme,
} from '@fluentui/react';
import { ApiCalls } from '../../../../../../../../api/api';
import { useMutation } from '@tanstack/react-query';
import { useRowMetaContext } from '../../../../context/RowMetaContext';
import { TimeEntryApprovalDtoState, useApprovalState, useMissingApprovalCount } from '../../../../../../../../_grid/grid2';

const stateOptions = APPROVAL_STATES.map((state): IDropdownOption<ApprovalState> => {
    return {
        key: state,
        text: state,
        data: state,
    };
});

export const StateDropdown = () => {
    const { row, parentRows } = useRowMetaContext<TimeEntryApprovalDtoState>();
    const [approval, setApproval] = useApprovalState(row.id);
    const { mutate: mutateApproval } = useMutation((selectedApprovalState: ApprovalState) =>
        ApiCalls.setApprovalState({ selectedApprovalState, timeEntryId: row.timeEntry.id }),
    );
    const missingApprovalCount = useMissingApprovalCount(row.id);
    const [showProjectClosureWarning, setShowProjectClosureWarning] = useState(false);

    const onChange = useCallback(
        (event: any, option?: IDropdownOption<ApprovalState>) => {
            if (option?.data && option.data !== approval) {
                if (option.data === 'Approved' && missingApprovalCount === 1) {
                    // Warn about project closure
                    setShowProjectClosureWarning(true);
                } else {
                    setApproval(option.data);
                    mutateApproval(option.data);
                }
            }
        },
        [approval, missingApprovalCount, mutateApproval, setApproval],
    );

    const theme = useTheme();

    const approvalBackground = useMemo((): IDropdownProps['styles'] => {
        let backgroundColor: string;
        if (approval === 'Approved') {
            backgroundColor = theme.palette.green;
        }
        if (approval === 'Pending') {
            backgroundColor = theme.palette.yellow;
        }
        if (approval === 'Rejected') {
            backgroundColor = theme.palette.red;
        }
        return {
            title: {
                backgroundColor,
            },
        };
    }, [approval, theme.palette.green, theme.palette.red, theme.palette.yellow]);
    const onRenderCaretDown: IRenderFunction<IDropdownProps> = useCallback(
        (props, defaultRender) => {
            if (missingApprovalCount === 0) {
                return null;
            }
            return defaultRender(props);
        },
        [missingApprovalCount],
    );
    return (
        <>
            <Dropdown
                //
                disabled={missingApprovalCount === 0}
                options={stateOptions}
                onChange={onChange}
                selectedKey={approval}
                styles={approvalBackground}
                onRenderCaretDown={onRenderCaretDown}
            />
            <Dialog hidden={!showProjectClosureWarning} dialogContentProps={{ title: 'Approve and close time registration?' }}>
                {/* The project "{parentRows[parentRows.length - 1].name}" will close after this approval */}
                After approving all hours, time registration will be closed for the given time period and restricted from further changes on the project: "
                {parentRows[parentRows.length - 1].name}"
                <DialogFooter>
                    <DefaultButton
                        onClick={() => {
                            setShowProjectClosureWarning(false);
                        }}
                    >
                        Cancel
                    </DefaultButton>
                    <PrimaryButton
                        styles={{ root: { backgroundColor: theme.semanticColors.successIcon } }}
                        onClick={() => {
                            setShowProjectClosureWarning(false);
                            setApproval('Approved');
                            mutateApproval('Approved');
                        }}
                    >
                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
                            <Text>Approve</Text>
                            <Icon iconName="CheckMark" />
                        </Stack>
                    </PrimaryButton>
                </DialogFooter>
            </Dialog>
        </>
    );
};
