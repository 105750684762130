import { DataverseConfiguration, IntegrationEventType, IntegrationDto, MicrosoftEntraIdConfiguration } from '../../api/generated/data-contracts';

export type IntegrationTypeGeneric<Config extends Record<string, any>, Events extends IntegrationEventType[]> = Omit<
    IntegrationDto,
    'configuration' | 'subscribedEvents'
> & {
    configuration: Config;
    subscribedEvents: Events;
};

export const IsDataverseIntegration = (integration: IntegrationTypeGeneric<any, any>): integration is IntegrationTypeGeneric<DataverseConfiguration, any> => {
    return integration.type === 'Dataverse';
};

export const IsMicrosoftEntraIdIntegration = (
    integration: IntegrationTypeGeneric<any, any>,
): integration is IntegrationTypeGeneric<MicrosoftEntraIdConfiguration, any> => {
    return integration.type === 'MicrosoftEntraId';
};
