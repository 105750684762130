

export const mobileConstants = {
    topbarHeight: 52,
    gridRowHeight: 42,
    sideSpacing: 20,
    topbarSvgSize: 24,
};

export const daysArr = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const periodFakeDay = "periodFakeDay";