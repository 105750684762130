export enum TimeConfigKeys {
    GridInputType = 'GridInputType',
    ShowPlanned = 'ShowPlanned',
    GridView = 'GridView',
    WorkDayBoundaries = 'WorkDayBoundaries',
    WorkDayMinutesInterval = 'WorkDayMinutesInterval',
    WorkDays = 'WorkDays',
    TimesheetRAGsEnabled = 'TimesheetRAGsEnabled',
    NativeChartsEnabled = 'NativeChartsEnabled',
    TimeEntriesImport = 'enableJiraUpload',
    SubmitPeriodOnImport = 'submitPeriodOnImport',
    InheritApproverRights = 'InheritApproverRights',
    AllowCellComment = 'AllowCellComment',
    AllowSubmitComment = 'AllowSubmitComment',
    AllowTimeSheetComment = 'AllowTimeSheetComment',
    AllowCellFlag = 'AllowCellFlag',
    WorkDayAMPM = 'WorkDayAMPM',
    MobileAccess = 'MobileAccess',
    AutoCreateNewUsers = 'autocreatenewusers',
    AutoCreationDisabled = 'autocreationDisabled',
    EnableWorkType = 'disableWorkTypes',
    EnableCoachmarks = 'enableCoachmarks',
    AllowTelemitry = 'allowTelemitry',
    OnlyAssignedSearch = 'onlyAssignedSearch',
    Onlyallowtimesheetwithintaskstartend = 'onlyallowtimesheetwithintaskstartend',
}
