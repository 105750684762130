import React, { useEffect, useRef } from 'react';
import { useUiContext } from '../../../../components/Contexts/UiContext';
import AdminHelpSupporters from './SupportersConfigComponents/AdminHelpSupporters';
import AdminSupporterM from '../../../../Model/AdminSupporterM';
import AdminHelpVideos from './VideosConfigComponents/AdminHelpVideos';
import AdminVideoM from '../../../../Model/AdminVideoM';
import AdminHelpFAQs from './FAQConfigComponents/AdminHelpFAQs';
import AdminFAQM from '../../../../Model/AdminFAQM';
import { PageHeader, PageContent, ContentSection } from '../../../../components/LayoutElements';
import { useQuery } from '@tanstack/react-query';
import { GET_CONFIG, GET_HELP_FAQ, GET_HELP_SUPPORT, GET_HELP_VIDEOS } from '../../../../constants/queryKeys';

export const HelpConfig = () => {
    const uiCtx = useUiContext();

    const defaultData = useRef([]).current;

    const { data: config = null, isLoading: isLoadingConfig } = useQuery([GET_CONFIG], (): Promise<any> => uiCtx.timeApi.getAllConfigs(), {
        onSuccess: () => uiCtx.setLoading(false),
        placeholderData: null,
    });

    const { data: supporters = defaultData, isLoading: isLoadingSupport } = useQuery([GET_HELP_SUPPORT], (): Promise<AdminSupporterM[]> => uiCtx.timeApi.getSupport(), {
        onSuccess: () => uiCtx.setLoading(false),
        placeholderData: defaultData,
    });

    const { data: videos = defaultData, isLoading: isLoadingVideos } = useQuery([GET_HELP_VIDEOS], (): Promise<AdminVideoM[]> => uiCtx.timeApi.getVideos(), {
        onSuccess: () => uiCtx.setLoading(false),
        placeholderData: defaultData,
    });

    const { data: fAQs = defaultData, isLoading: isLoadingFAQ } = useQuery([GET_HELP_FAQ], (): Promise<AdminFAQM[]> => uiCtx.timeApi.getFAQ(), {
        onSuccess: () => uiCtx.setLoading(false),
        placeholderData: defaultData,
    });

    useEffect(() => {
        if (isLoadingFAQ || isLoadingVideos || isLoadingSupport || isLoadingConfig) {
            return;
        }
        uiCtx.setLoading(false);
    }, [isLoadingFAQ, isLoadingVideos, isLoadingSupport, isLoadingConfig]);

    if (!config) {
        return (
            <div className="centeredtext">
                <h2>Loading</h2>
            </div>
        );
    }

    return (
        <>
            <PageHeader title="Help configuration" icon="HandsFree" />

            <PageContent>
                <ContentSection>
                    <AdminHelpSupporters supporters={supporters} />
                    <AdminHelpVideos videos={videos} />
                    <AdminHelpFAQs fAQs={fAQs} />
                </ContentSection>
            </PageContent>
        </>
    );
};
