import React, { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';
import { TextField } from '@fluentui/react/lib/TextField';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { CommandBar, DatePicker, DayOfWeek, DetailsList, IColumn, IconButton, IContextualMenuItem, SelectionMode } from '@fluentui/react';
import { Calendar } from '../../Model/NewModels/Calendar';
import { Holiday } from '../../Model/NewModels/Holiday';
import { createNewGuid } from '../../components/Utils';
import { useBoolean } from '@fluentui/react-hooks';
import AddFromAPIDialog from './AddFromAPIDialog';

interface propsAdminCalendar {
    Calendar: Calendar;
    IsDefault: boolean;
    RemoveCalendar: Function;
    CalendarUpdated: Function;
}
function AdminCalendar(props: propsAdminCalendar) {
    const [calendar, setCalendar] = useState<Calendar>(props.Calendar);
    const [calendarId, setCalendarId] = useState<string>(props.Calendar.id);
    const [calendarName, setCalendarName] = useState<string>(props.Calendar.name);
    const [calendarLanguage, setCalendarLanguage] = useState<string>(props.Calendar.language);
    const [calendarIsDefault, setCalendarIsDefault] = useState<boolean>(props.IsDefault);
    const [calendarHolidays, setCalendarHolidays] = useState<Holiday[]>(props.Calendar?.holidays ?? []);

    const [calendarCollapsed, setCalendarCollapsed] = useState<boolean>(false);

    const [headerInFocus, setHeaderInFocus] = useState<boolean>(false);
    const headertxtfield = React.useRef(null);

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const _columns = [
        // { key: 'id', name: 'Id', fieldName: 'id', minWidth: 20, maxWidth: 20, isResizable: true },
        { key: 'name', name: 'Name', fieldName: 'name', minWidth: 100, maxWidth: 320, isResizable: true },
        { key: 'date', name: 'Date', fieldName: 'date', minWidth: 200, maxWidth: 200, isResizable: false },
        { key: 'delete', name: '', fieldName: 'delete', minWidth: 20, maxWidth: 20, isResizable: false },
    ];

    useEffect(() => {
        setCalendar(props.Calendar);
        setCalendarId(props.Calendar.id);
        setCalendarName(props.Calendar.name);
        setCalendarLanguage(props.Calendar.language);
        setCalendarHolidays(props.Calendar.holidays);
    }, [props.Calendar]);

    useEffect(() => {
        setCalendarIsDefault(props.Calendar.isDefault);
    }, [props.IsDefault]);

    useEffect(() => {
        if (headerInFocus == true) {
            headertxtfield.current.focus();
        }
    }, [headerInFocus]);

    useEffect(() => {
        newUpdateCalendar();
    }, [calendarIsDefault, calendarHolidays]); // All besides names and ids

    // let moveUpClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement, MouseEvent>) => {
    //     props.ReorderSupporter(supporter, -1);
    // }
    // let moveDownClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement, MouseEvent>) => {
    //     props.ReorderSupporter(supporter, 1);
    // }

    // Calendar updates
    // let removeClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement, MouseEvent>) => {
    //     props.RemoveCalendar(calendar, 1);
    // }
    // Calendar updates
    const removeClick = (event?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => {
        props.RemoveCalendar(calendar, 1);
    };
    const newUpdateCalendar = () => {
        props.CalendarUpdated(calendarId, calendarName, calendarLanguage, calendarIsDefault, calendarHolidays);
    };
    const CalendarIdUpdate = (event, value) => {
        setCalendarId(value);
        // props.SupporterUpdated(supporter.id, supporterHeader, supporterEmail, supporterPhone);
    };
    const CalendarNameUpdate = (event, value) => {
        setCalendarName(value);
        // props.SupporterUpdated(supporter.id, supporterHeader, supporterEmail, supporterPhone);
    };
    const CalendarLanguageUpdate = (event, value) => {
        setCalendarLanguage(value);
        // props.SupporterUpdated(supporter.id, supporterHeader, value, supporterPhone);
    };
    const CalendarIsDefaultUpdate = (event, value) => {
        setCalendarIsDefault(value);
        // props.SupporterUpdated(supporter.id, supporterHeader, value, supporterPhone);
    };
    const CalendarHolidaysUpdate = (event, value) => {
        setCalendarHolidays(value);
        // props.SupporterUpdated(supporter.id, supporterHeader, supporterEmail, value);
    };

    // Holiday updates
    const HolidayNameUpdate = (event, value, item: Holiday) => {
        // let updatedItem = item;
        // updatedItem.name = value;
        const updatedArrray = calendarHolidays;
        updatedArrray[updatedArrray.findIndex(ua => ua.id == item.id)].name = value;
        setCalendarHolidays(updatedArrray);

        // let updatedItem = item;
        // updatedItem.name = value;
        // let updatedArrray = calendarHolidays;
        // updatedArrray[updatedArrray.findIndex(ua => ua.id == updatedItem.id)] = updatedItem;
        // setCalendarHolidays(updatedArrray);
        // setCalendarHolidays(prev => [...prev.filter(p => p.id != item.id), updatedItem]);
        // props.SupporterUpdated(supporter.id, supporterHeader, supporterEmail, value);
    };
    const HolidayDateUpdate = (date: Date, item: Holiday) => {
        const updatedItem = item;
        updatedItem.date = date.setUTCTime();
        const updatedArrray = calendarHolidays;
        updatedArrray[updatedArrray.findIndex(ua => ua.id == updatedItem.id)] = updatedItem;
        setCalendarHolidays(updatedArrray);
    };
    const RemoveHoliday = (item: Holiday) => {
        setCalendarHolidays(prev => [...prev.filter(p => p.id != item.id)]);
    };
    let AddHoliday = () => {
        let newHolidayDate = new Date();
        newHolidayDate.setHours(0);
        newHolidayDate.setMinutes(0);
        newHolidayDate.setSeconds(0);
        newHolidayDate = newHolidayDate.setUTCTime();

        let newHoliday = { id: createNewGuid(), name: 'New Holiday', date: newHolidayDate, type: '', country: '' } as Holiday;
        setCalendarHolidays(prev => [...prev, newHoliday]);
    };
    const AddHolidaysFromDialog = (newHolidaysArray: Holiday[]) => {
        setCalendarHolidays(prev => [...prev, ...newHolidaysArray]);
        toggleHideDialog();
    };

    const collapseClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement, MouseEvent>) => {
        setCalendarCollapsed(!calendarCollapsed);
    };

    const renderItemColumn = (item: any, index: number, column: IColumn) => {
        switch (column.key) {
            case 'name':
                // TODO: Fix this with seperate control, we need state for this...
                const fieldContString = item[column.fieldName as keyof any] as string;
                return (
                    // Switched to use defaultValue, as it actually works, but is a bad solution
                    <TextField
                        borderless
                        defaultValue={fieldContString}
                        onChange={(e, v) => {
                            HolidayNameUpdate(e, v, item);
                            newUpdateCalendar();
                        }}
                    /> // onBlur={newUpdateCalendar} />
                );
            case 'date':
                const fieldContDate = new Date(item.date);
                return (
                    <DatePicker
                        allowTextInput
                        firstDayOfWeek={DayOfWeek.Monday}
                        ariaLabel="Select date" //Might not be needed as it should default to a date
                        placeholder="Select date" //Might not be needed as it should default to a date
                        value={fieldContDate}
                        onSelectDate={(date: Date | null | undefined) => {
                            HolidayDateUpdate(date, item);
                        }}
                        styles={{ textField: { width: 150 } }}
                    />
                );
            case 'delete':
                return (
                    <IconButton
                        iconProps={{ iconName: 'Delete' }}
                        onClick={() => {
                            RemoveHoliday(item);
                        }}
                    />
                );
            default:
                const fieldContDefaultString = item[column.fieldName as keyof any] as string;
                return <span>{fieldContDefaultString}</span>;
        }
    };

    return (
        <div style={{ width: 660 }}>
            <Stack tokens={{ childrenGap: 20 }} horizontal verticalAlign="baseline">
                <Stack.Item styles={{ root: { alignItems: 'center', display: 'flex', justifyContent: 'flex-start' } }}>
                    <Toggle
                        checked={calendarIsDefault}
                        onText="Default"
                        offText=""
                        onChange={(e, v) => {
                            CalendarIsDefaultUpdate(e, v);
                        }}
                        role="checkbox"
                    />
                </Stack.Item>
                <Stack.Item grow={1}>
                    {headerInFocus ? (
                        <TextField
                            componentRef={headertxtfield}
                            onBlur={() => {
                                setHeaderInFocus(false);
                                newUpdateCalendar();
                            }}
                            value={calendarName}
                            onChange={CalendarNameUpdate}
                        />
                    ) : (
                        <Stack horizontal tokens={{ childrenGap: 0 }} verticalAlign="baseline">
                            <h4
                                onClick={() => {
                                    setHeaderInFocus(true);
                                }}
                                style={{ cursor: 'pointer', textDecoration: 'none' }}
                            >
                                {calendarName}
                            </h4>
                        </Stack>
                    )}
                </Stack.Item>
                <Stack.Item grow={5}>
                    <Separator></Separator>
                </Stack.Item>
                <Stack.Item grow={1} styles={{ root: { alignItems: 'center', display: 'flex', justifyContent: 'flex-end' } }}>
                    <Stack tokens={{ childrenGap: 0 }} horizontal>
                        <CommandBar
                            items={[]}
                            overflowItems={[
                                { key: 'addfromapi', text: 'Add from API', onClick: () => toggleHideDialog(), iconProps: { iconName: 'Add' } },
                                {
                                    key: 'removecalendar',
                                    text: 'Remove Calendar',
                                    onClick: (e, i) => {
                                        removeClick(e, i);
                                    },
                                    iconProps: { iconName: 'Delete' },
                                    disabled: calendarIsDefault,
                                },
                            ]}
                        />
                        <IconButton iconProps={{ iconName: 'SkypeCircleMinus' }} checked={false} styles={{ root: { height: 40, width: 40 } }} onClick={collapseClick} />
                    </Stack>
                </Stack.Item>
            </Stack>
            {!calendarCollapsed && (
                <div>
                    {' '}
                    {/* style={{display: calendarCollapsed ? 'none' : ''}}> */}
                    <Stack tokens={{ childrenGap: 10 }} verticalAlign="start" style={{ margin: 10 }}>
                        <DetailsList setKey="items" items={calendarHolidays ?? []} columns={_columns} selectionMode={SelectionMode.none} compact={true} onRenderItemColumn={renderItemColumn} />
                        <Stack>
                            <Stack.Item align="center">
                                <IconButton iconProps={{ iconName: 'CircleAdditionSolid' }} onClick={AddHoliday}></IconButton>
                                {/* <PrimaryButton text="Import" /> */}
                            </Stack.Item>
                        </Stack>

                        <br />
                    </Stack>
                </div>
            )}

            <AddFromAPIDialog hidden={hideDialog} onDismiss={toggleHideDialog} onAddHolidaysFromDialog={AddHolidaysFromDialog} />
        </div>
    );
}

export default AdminCalendar;
