import React from 'react';
import { TooltipHost } from '@fluentui/react';
import { ProjectSource } from '../Model/ProjectSource';

interface ProjectSourceIconProps {
    id: string;
    width?: number;
    projectSource: ProjectSource;
    style?: React.CSSProperties
}

const ProjectSourceIcon: React.FC<ProjectSourceIconProps> = (props) => {
    return (
        <TooltipHost
            id={props.id} 
            content={props.projectSource.name}
            styles={{ root: { display: 'flex', alignItems: "center" }}}
            calloutProps={{ gapSpace: -4 }}
        >
            <img style={props.style} width={props.width ? `${props.width}px` : ""} height={props.width ? `${props.width}px` : ""} aria-describedby={props.id} src={props.projectSource.iconUrl} />
        </TooltipHost>
    )
}

export default ProjectSourceIcon;