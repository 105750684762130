import { IComboBoxOption } from "@fluentui/react";

export const _CountryOptions = [
    { key: "AF", text: "Afghanistan" },
    { key: "AL", text: "Albania" },
    { key: "DZ", text: "Algeria" },
    { key: "AS", text: "American Samoa" },
    { key: "AD", text: "Andorra" },
    { key: "AO", text: "Angola" },
    { key: "AI", text: "Anguilla" },
    { key: "AG", text: "Antigua and Barbuda" },
    { key: "AR", text: "Argentina" },
    { key: "AM", text: "Armenia" },
    { key: "AW", text: "Aruba" },
    { key: "AU", text: "Australia" },
    { key: "AT", text: "Austria" },
    { key: "AZ", text: "Azerbaijan" },
    { key: "BH", text: "Bahrain" },
    { key: "BD", text: "Bangladesh" },
    { key: "BB", text: "Barbados" },
    { key: "BY", text: "Belarus" },
    { key: "BE", text: "Belgium" },
    { key: "BZ", text: "Belize" },
    { key: "BJ", text: "Benin" },
    { key: "BM", text: "Bermuda" },
    { key: "BT", text: "Bhutan" },
    { key: "BO", text: "Bolivia" },
    { key: "BA", text: "Bosnia and Herzegovina" },
    { key: "BW", text: "Botswana" },
    { key: "BR", text: "Brazil" },
    { key: "VG", text: "British Virgin Islands" },
    { key: "BN", text: "Brunei" },
    { key: "BG", text: "Bulgaria" },
    { key: "BF", text: "Burkina Faso" },
    { key: "BI", text: "Burundi" },
    { key: "CV", text: "Cabo Verde" },
    { key: "KH", text: "Cambodia" },
    { key: "CM", text: "Cameroon" },
    { key: "CA", text: "Canada" },
    { key: "KY", text: "Cayman Islands" },
    { key: "CF", text: "Central African Republic" },
    { key: "TD", text: "Chad" },
    { key: "CL", text: "Chile" },
    { key: "CN", text: "China" },
    { key: "CO", text: "Colombia" },
    { key: "KM", text: "Comoros" },
    { key: "CG", text: "Congo" },
    { key: "CD", text: "Congo Democratic Republic" },
    { key: "CK", text: "Cook Islands" },
    { key: "CR", text: "Costa Rica" },
    { key: "CI", text: "Cote d'Ivoire" },
    { key: "HR", text: "Croatia" },
    { key: "CU", text: "Cuba" },
    { key: "CW", text: "Curaçao" },
    { key: "CY", text: "Cyprus" },
    { key: "CZ", text: "Czechia" },
    { key: "DK", text: "Denmark" },
    { key: "DJ", text: "Djibouti" },
    { key: "DM", text: "Dominica" },
    { key: "DO", text: "Dominican Republic" },
    { key: "TL", text: "East Timor" },
    { key: "EC", text: "Ecuador" },
    { key: "EG", text: "Egypt" },
    { key: "SV", text: "El Salvador" },
    { key: "GQ", text: "Equatorial Guinea" },
    { key: "ER", text: "Eritrea" },
    { key: "EE", text: "Estonia" },
    { key: "SZ", text: "eSwatini" },
    { key: "ET", text: "Ethiopia" },
    { key: "FK", text: "Falkland Islands" },
    { key: "FO", text: "Faroe Islands" },
    { key: "FJ", text: "Fiji" },
    { key: "FI", text: "Finland" },
    { key: "FR", text: "France" },
    { key: "GF", text: "French Guiana" },
    { key: "PF", text: "French Polynesia" },
    { key: "GA", text: "Gabon" },
    { key: "GM", text: "Gambia" },
    { key: "GE", text: "Georgia" },
    { key: "DE", text: "Germany" },
    { key: "GH", text: "Ghana" },
    { key: "GI", text: "Gibraltar" },
    { key: "GR", text: "Greece" },
    { key: "GL", text: "Greenland" },
    { key: "GD", text: "Grenada" },
    { key: "GP", text: "Guadeloupe" },
    { key: "GU", text: "Guam" },
    { key: "GT", text: "Guatemala" },
    { key: "GG", text: "Guernsey" },
    { key: "GN", text: "Guinea" },
    { key: "GW", text: "Guinea-Bissau" },
    { key: "GY", text: "Guyana" },
    { key: "HT", text: "Haiti" },
    { key: "HN", text: "Honduras" },
    { key: "HK", text: "Hong Kong" },
    { key: "HU", text: "Hungary" },
    { key: "IS", text: "Iceland" },
    { key: "IN", text: "India" },
    { key: "ID", text: "Indonesia" },
    { key: "IR", text: "Iran" },
    { key: "IQ", text: "Iraq" },
    { key: "IE", text: "Ireland" },
    { key: "IM", text: "Isle of Man" },
    { key: "IL", text: "Israel" },
    { key: "IT", text: "Italy" },
    { key: "JM", text: "Jamaica" },
    { key: "JP", text: "Japan" },
    { key: "JE", text: "Jersey" },
    { key: "JO", text: "Jordan" },
    { key: "KZ", text: "Kazakhstan" },
    { key: "KE", text: "Kenya" },
    { key: "KI", text: "Kiribati" },
    { key: "XK", text: "Kosovo" },
    { key: "KW", text: "Kuwait" },
    { key: "KG", text: "Kyrgyzstan" },
    { key: "LA", text: "Laos" },
    { key: "LV", text: "Latvia" },
    { key: "LB", text: "Lebanon" },
    { key: "LS", text: "Lesotho" },
    { key: "LR", text: "Liberia" },
    { key: "LY", text: "Libya" },
    { key: "LI", text: "Liechtenstein" },
    { key: "LT", text: "Lithuania" },
    { key: "LU", text: "Luxembourg" },
    { key: "MO", text: "Macau" },
    { key: "MG", text: "Madagascar" },
    { key: "MW", text: "Malawi" },
    { key: "MY", text: "Malaysia" },
    { key: "MV", text: "Maldives" },
    { key: "ML", text: "Mali" },
    { key: "MT", text: "Malta" },
    { key: "MH", text: "Marshall Islands" },
    { key: "MQ", text: "Martinique" },
    { key: "MR", text: "Mauritania" },
    { key: "MU", text: "Mauritius" },
    { key: "YT", text: "Mayotte" },
    { key: "MX", text: "Mexico" },
    { key: "FM", text: "Micronesia" },
    { key: "MD", text: "Moldova" },
    { key: "MC", text: "Monaco" },
    { key: "MN", text: "Mongolia" },
    { key: "ME", text: "Montenegro" },
    { key: "MS", text: "Montserrat" },
    { key: "MA", text: "Morocco" },
    { key: "MZ", text: "Mozambique" },
    { key: "MM", text: "Myanmar" },
    { key: "NA", text: "Namibia" },
    { key: "NR", text: "Nauru" },
    { key: "NP", text: "Nepal" },
    { key: "NL", text: "Netherlands" },
    { key: "NC", text: "New Caledonia" },
    { key: "NZ", text: "New Zealand" },
    { key: "NI", text: "Nicaragua" },
    { key: "NE", text: "Niger" },
    { key: "NG", text: "Nigeria" },
    { key: "KP", text: "North Korea" },
    { key: "MK", text: "North Macedonia" },
    { key: "MP", text: "Northern Mariana Islands" },
    { key: "NO", text: "Norway" },
    { key: "OM", text: "Oman" },
    { key: "PK", text: "Pakistan" },
    { key: "PW", text: "Palau" },
    { key: "PA", text: "Panama" },
    { key: "PG", text: "Papua New Guinea" },
    { key: "PY", text: "Paraguay" },
    { key: "PE", text: "Peru" },
    { key: "PH", text: "Philippines" },
    { key: "PL", text: "Poland" },
    { key: "PT", text: "Portugal" },
    { key: "PR", text: "Puerto Rico" },
    { key: "QA", text: "Qatar" },
    { key: "RE", text: "Reunion" },
    { key: "RO", text: "Romania" },
    { key: "RE", text: "Russia" },
    { key: "RW", text: "Rwanda" },
    { key: "SH", text: "Saint Helena" },
    { key: "KN", text: "Saint Kitts and Nevis" },
    { key: "LC", text: "Saint Lucia" },
    { key: "MF", text: "Saint Martin" },
    { key: "PM", text: "Saint Pierre and Miquelon" },
    { key: "VC", text: "Saint Vincent and the Grenadines" },
    { key: "WS", text: "Samoa" },
    { key: "SM", text: "San Marino" },
    { key: "ST", text: "Sao Tome and Principe" },
    { key: "SA", text: "Saudi Arabia" },
    { key: "SN", text: "Senegal" },
    { key: "RS", text: "Serbia" },
    { key: "SC", text: "Seychelles" },
    { key: "SL", text: "Sierra Leone" },
    { key: "SG", text: "Singapore" },
    { key: "SX", text: "Sint Maarten" },
    { key: "SK", text: "Slovakia" },
    { key: "SI", text: "Slovenia" },
    { key: "SB", text: "Solomon Islands" },
    { key: "SO", text: "Somalia" },
    { key: "ZA", text: "South Africa" },
    { key: "KR", text: "South Korea" },
    { key: "SS", text: "South Sudan" },
    { key: "ES", text: "Spain" },
    { key: "LK", text: "Sri Lanka" },
    { key: "BL", text: "St. Barts" },
    { key: "SD", text: "Sudan" },
    { key: "SR", text: "Suriname" },
    { key: "SE", text: "Sweden" },
    { key: "CH", text: "Switzerland" },
    { key: "SY", text: "Syria" },
    { key: "TW", text: "Taiwan" },
    { key: "TJ", text: "Tajikistan" },
    { key: "TZ", text: "Tanzania" },
    { key: "TH", text: "Thailand" },
    { key: "BH", text: "The Bahamas" },
    { key: "TG", text: "Togo" },
    { key: "TO", text: "Tonga" },
    { key: "TT", text: "Trinidad and Tobago" },
    { key: "TN", text: "Tunisia" },
    { key: "TR", text: "Turkey" },
    { key: "TM", text: "Turkmenistan" },
    { key: "TC", text: "Turks and Caicos Islands" },
    { key: "TV", text: "Tuvalu" },
    { key: "UG", text: "Uganda" },
    { key: "UA", text: "Ukraine" },
    { key: "AE", text: "United Arab Emirates" },
    { key: "GB", text: "United Kingdom" },
    { key: "US", text: "United States" },
    { key: "UY", text: "Uruguay" },
    { key: "VI", text: "US Virgin Islands" },
    { key: "UZ", text: "Uzbekistan" },
    { key: "VU", text: "Vanuatu" },
    { key: "VA", text: "Vatican City (Holy See)" },
    { key: "VE", text: "Venezuela" },
    { key: "VN", text: "Vietnam" },
    { key: "WF", text: "Wallis and Futuna" },
    { key: "YE", text: "Yemen" },
    { key: "ZM", text: "Zambia" },
    { key: "ZW", text: "Zimbabwe" }
] as IComboBoxOption[];

export const _YearOptions = [
    { key: "2020", text: "2020" },
    { key: "2021", text: "2021" },
    { key: "2022", text: "2022" },
    { key: "2023", text: "2023" },
    { key: "2024", text: "2024" },
    { key: "2025", text: "2025" },
    { key: "2026", text: "2026" },
    { key: "2027", text: "2027" },
    { key: "2028", text: "2028" },
    { key: "2029", text: "2029" },
    { key: "2030", text: "2030" },
    { key: "2031", text: "2031" },
    { key: "2032", text: "2032" },
    { key: "2033", text: "2033" },
    { key: "2034", text: "2034" },
    { key: "2035", text: "2035" },
    { key: "2036", text: "2036" },
    { key: "2037", text: "2037" },
    { key: "2038", text: "2038" },
    { key: "2039", text: "2039" },
    { key: "2040", text: "2040" },
] as IComboBoxOption[];