import React, { useEffect, useState, useRef, ReactElement } from 'react';
import { FontIcon } from '@fluentui/react/lib/Icon';
import TimeRow from '../../Model/TimePeriodRowM';
import TimeSearchRowM from '../../Model/TimeSearchRowM';
import '../../Styles/TimePeriod.scss';
import '../../Styles/Search.scss';
import { SortTimeSearchRows, GetDetailsListGroups, getEmptyGuid } from '../Utils';
import { Spinner } from '@fluentui/react/lib/Spinner';

import {
    IconButton,
    SearchBox,
    ISearchBox,
    ISearchBoxStyles,
    Callout,
    mergeStyleSets,
    getTheme,
    FontWeights,
    Text,
    DetailsList,
    SelectionMode,
    PrimaryButton,
    Stack,
    BaseButton,
    Button,
    Selection,
    TooltipHost,
    IColumn,
    IIconProps,
    IDetailsGroupRenderProps,
    GroupHeader,
    ColumnActionsMode,
    ContextualMenu,
    DirectionalHint,
    IContextualMenuItem,
    IDetailsListProps,
    IDetailsRowStyles,
    DetailsRow,
    Tooltip,
    ITooltipHostStyles,
} from '@fluentui/react';
// import { isNullOrUndefined } from 'util';
import { timeApi } from '../../Workaround/TimeApiWorkaround';
import { SearchIcon } from '../../Icons';
import { SearchIconInactive } from '../../Icons/SearchIconInactive';
import { useUiContext } from '../Contexts/UiContext';
import { ProjectSource } from '../../Model/ProjectSource';
import ProjectSourceIcon from '../../Icons/ProjectSourceIcon';
import { useTheme } from '@fluentui/react';
import { isNullOrUndefined } from '../../helpers/isNullOrUndefined';
import { ApiCalls } from '../../api/api';
import { TimeSearchRow } from '../../api/generated/data-contracts';
// const winTheme = getTheme()

let searchInProgress = false;
let searchTimeout;
let parkedSearch: Function = null;
let lastsearchquery: string = '';
let globalsearchitems = [];
enum SearchViewEnum {
    nosearch = 0,
    defaulttasks = 1,
    searching = 2,
}

const theme = getTheme();

const GROUP_HEADER_AND_FOOTER_SPACING: number = 8;
const GROUP_HEADER_AND_FOOTER_BORDER_WIDTH: number = 1;

const classNames = mergeStyleSets({
    headerAndFooter: {
        borderTop: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
        borderBottom: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
        padding: GROUP_HEADER_AND_FOOTER_SPACING,
        margin: `${GROUP_HEADER_AND_FOOTER_SPACING}px 0`,
        background: theme.palette.neutralLighterAlt,
        // Overlay the sizer bars
        position: 'relative',
        zIndex: 100,
    },
    headerTitle: [
        theme.fonts.medium,
        {
            padding: '4px 0',
            marginLeft: '12px',
            minWidth: '360px',
            maxWidth: '360px',
            width: '360px',
            fontWeight: FontWeights.semibold,
            display: 'inline-block',
        },
    ],
    headerAdditionalTitles: [
        theme.fonts.medium,
        {
            padding: '4px 0',
            marginLeft: '12px',
            fontWeight: FontWeights.semibold,
            display: 'inline-block',
            color: theme.palette.neutralSecondaryAlt,
        },
    ],
    headerSourceTitles: [
        theme.fonts.medium,
        {
            padding: '4px 0',
            marginLeft: '8px',
            fontWeight: FontWeights.semibold,
            display: 'inline-block',
            minWidth: '30px',
            maxWidth: '30px',
        },
    ],
    headerLinkSet: {
        margin: '4px -8px',
    },
    headerLink: {
        margin: '0 8px',
    },
});
const filterIcon: IIconProps = { iconName: 'noicon' };
const styles = mergeStyleSets({
    buttonArea: {
        verticalAlign: 'top',
        display: 'inline-block',
        textAlign: 'center',
        margin: '0 100px',
        minWidth: 130,
        height: 32,
    },
    callout: {
        maxWidth: 800,
        minWidth: 300,
    },
    header: {
        position: 'relative',
        padding: '20px 24px',
    },
    message: {
        position: 'absolute',
        bottom: 2,
        left: 32,
    },
    title: [
        theme.fonts.mediumPlus,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
        },
    ],
    inner: {
        height: '100%',
        // padding: '0 24px',
        padding: '0 24px 20px',
    },
    actions: {
        position: 'relative',
        marginTop: 20,
        width: '100%',
        whiteSpace: 'nowrap',
    },
    subtext: [
        theme.fonts.small,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
        },
    ],
    link: [
        theme.fonts.medium,
        {
            color: theme.palette.neutralPrimary,
        },
    ],
});
const searchBoxStyles: Partial<ISearchBoxStyles> = {
    root: {
        // width: 270,
        width: '100%',
        backgroundColor: 'transparent',
        // marginLeft: 10,
        // color: "rgb(50, 49, 48)",
        borderColor: theme.semanticColors.variantBorder,
        // borderColor: "transparent",
        // fontFamily: "'Segoe UI', 'SegoeUI-SemiBold-final', 'Segoe UI Semibold', 'SegoeUI-Regular-final', 'Segoe UI', 'Segoe UI Web (West European)', Segoe, -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', Tahoma, Helvetica, Arial, sans-serif",
        fontSize: 16,
    },
};

interface propsSearch {
    timeAdd: Function;
    alreadyAddedProjects: TimeRow[];
    active: boolean;
}
function Search(props: propsSearch) {
    const themeCtx = useTheme();
    const [searchView, setSearchView] = useState<SearchViewEnum>(SearchViewEnum.nosearch);
    const [searchdefaulttasks, setSearchdefaulttasks] = useState<TimeSearchRow[]>([]);
    const [searchitems, setSearchitems] = useState<TimeSearchRow[]>([]);
    const [searchgroups, setSearchgroups] = useState<any[]>([]);
    const [searchitemsfiltered, setSearchitemsfiltered] = useState<TimeSearchRow[]>([]);
    const [searchgroupsfiltered, setSearchgroupsfiltered] = useState<any[]>([]);
    const [searchdefaulttasksloaded, setsearchdefaulttasksloaded] = useState<boolean>(false);
    const [columns, setColumns] = useState<any[]>([]);

    const [showdetailsflyout, setShowdetailsflyout] = useState<boolean>(false);
    const [searchState, setSearchState] = useState<number>(0);
    const [selectedTasks, setSelectedTasks] = useState<Selection>(
        new Selection({
            canSelectItem: (item: any, index?: number): boolean => {
                if (item.taskId) {
                    const alreadyAdded = props.alreadyAddedProjects.find(addedProject => addedProject.taskId === item.taskId);
                    if (alreadyAdded) {
                        return false;
                    }
                }
                return true;
            },
        }),
    );

    const [filterAssign, setFilterAssign] = useState<boolean>(false);
    const [filterTasks, setFilterTasks] = useState<boolean>(false);
    const [filterProjects, setFilterProjects] = useState<boolean>(true);
    const [metadata1ContextualMenu, setMetadata1ContextualMenu] = useState<any>(undefined);
    const [metadata1Values, setMetadata1Values] = useState<string[]>([]);
    const [detailslistItemSelected, setDetailslistItemSelected] = useState<boolean>(false);

    // const searchInputValue = useRef(null);
    const [searchInputValue, setSearchInputValue] = useState('');

    const searchFieldRef = useRef<ISearchBox>(null);
    const searchAreaRef = useRef(null);
    const searchIconRef = useRef<any>();

    const uiCtx = useUiContext();
    const onlyAssignedSearch = uiCtx.onlyAssignedSearch;

    useEffect(() => {}, []);
    useEffect(() => {
        //The following is needed as props.alreadyAddedProjects will be copied into the selction scope (bug when changing periods and searching)
        setSelectedTasks(
            new Selection({
                canSelectItem: (item: any, index?: number): boolean => {
                    if (item.taskId) {
                        const alreadyAdded = props.alreadyAddedProjects.find(addedProject => addedProject.taskId === item.taskId);
                        if (alreadyAdded) {
                            return false;
                        }
                    }
                    return true;
                },
            }),
        );
    }, [props.alreadyAddedProjects]);

    useEffect(() => {
        if (searchView === SearchViewEnum.searching || searchView === SearchViewEnum.defaulttasks) {
            if (searchFieldRef !== undefined && searchFieldRef != null && searchFieldRef.current !== undefined && searchFieldRef.current != null) {
                searchFieldRef.current.focus();
            }
        }
    }, [searchView]);

    useEffect(() => {
        if (!showdetailsflyout) {
            return;
        }
        if (!searchInputValue || searchInputValue === '') {
            return;
        }

        search(searchInputValue);
    }, [showdetailsflyout]);

    const searchSvgSelected = (): void => {
        if (props.active) {
            showDefaultTasks();
        }
    };

    const addButtonClicked = (
        event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement, MouseEvent>,
    ): void => {
        addTimeRow();
        setSearchInputValue('');
    };

    const addTimeRow = (): void => {
        const selection = selectedTasks.getSelection();
        console.log('Task to be added', selection);
        if (selection.length <= 0) {
            return;
        }

        selection.forEach((row: TimeSearchRowM) => {
            let selectedTimeRow: TimeRow = null;

            selectedTimeRow = new TimeRow(
                row.key,
                getEmptyGuid(),
                row.projectId,
                row.projectName,
                row.projectNumber,
                row.projectSource,
                row.projectClosed,
                row.taskId,
                row.taskName,
                row.taskStart,
                row.taskEnd,
                row.taskClosed,
                row.taskIsDefault,
                row.description,
                false,
                '',
                row.projectOrder,
                row.taskOrder,
                row.plannedWork,
                row.remainingWork,
                row.actualWork,
                row.timeEntries, //[], //@kra, not sure if this is going to cause problems in TimePeriodGroup when timeentries are parsed down to cells
                row.defaultWorkType, //TODO: KRA - refactor
                row.projectWorkType,
            );

            props.timeAdd(selectedTimeRow, false);
        });

        setShowdetailsflyout(false);
    };
    const assignSelected = (ev: React.MouseEvent<HTMLElement, MouseEvent>): void => {
        setFilterAssign(true);
        setFilterTasks(false);
        setFilterProjects(false);
        filtertasks(true, false, false);
    };
    const tasksSelected = (ev: React.MouseEvent<HTMLElement, MouseEvent>): void => {
        setFilterAssign(false);
        setFilterTasks(true);
        setFilterProjects(false);
        filtertasks(false, true, false);
    };
    const projectSelected = (ev: React.MouseEvent<HTMLElement, MouseEvent>): void => {
        setFilterAssign(false);
        setFilterTasks(false);
        setFilterProjects(true);
        filtertasks(false, false, true);
    };
    const filtertasks = (filterassignments: boolean, filtertasks: boolean, nofilter: boolean, metadata1Filter?: string): void => {
        let filteredTasks = [...globalsearchitems];

        if (nofilter) {
        } else if (filterassignments) {
            filteredTasks = filteredTasks.filter(t => t.isAssignedToTask === true);
        } else if (filtertasks) {
            filteredTasks = filteredTasks.filter(t => t.isAssignedToProject === true);
        } else {
        }
        if (!isNullOrUndefined(metadata1Filter)) {
            filteredTasks = filteredTasks.filter(t => t.metadata1 === metadata1Filter);
        }
        setSearchitemsfiltered(filteredTasks);
        setSearchgroupsfiltered(GetDetailsListGroups(filteredTasks, searchgroupsfiltered));
    };
    const searchForDismissed = (ev: any): void => {
        setShowdetailsflyout(false);
        selectedTasks.setItems([], true);
        setSearchState(0);
        setSearchView(SearchViewEnum.nosearch);
    };

    const searchFor = (event: React.SyntheticEvent<HTMLInputElement, Event>): void => {};
    const searchFocus = (event: React.FocusEvent<HTMLInputElement>): void => {};

    const onsearch = (newValue?: string): void => {
        if (newValue !== undefined && newValue != null /*&& newValue != ''*/) {
            search(newValue);
        }
    };

    const charsTyped = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string): void => {
        setSearchInputValue(newValue);

        if (newValue !== undefined && newValue !== null) {
            if (newValue.length >= 3) {
                if (searchTimeout !== undefined && searchTimeout != null) {
                    clearTimeout(searchTimeout);
                }
                searchTimeout = setTimeout(() => {
                    search(newValue);
                }, 500);
            } else if (newValue.length === 0) {
                showDefaultTasks();
            } else {
            }
        }
    };
    const initColumns = (search: boolean, metadata1values?: string[]) => {
        if (search) {
            setColumns([
                { key: 'name', name: 'Project/Task', fieldName: 'taskName', minWidth: 350, maxWidth: 350, isResizable: false },
                {
                    key: 'assigned',
                    name: '',
                    fieldName: 'isAssignedToTask',
                    minWidth: 30,
                    maxWidth: 30,
                    isResizable: false,
                },
                {
                    key: 'source',
                    name: '',
                    fieldName: '',
                    minWidth: 30,
                    maxWidth: 30,
                    isResizable: false,
                    columnActionsMode: ColumnActionsMode.disabled,
                },
                {
                    key: 'metadata1',
                    name: 'Filter',
                    fieldName: '',
                    minWidth: 100,
                    maxWidth: 100,
                    isResizable: false,
                    columnActionsMode: ColumnActionsMode.hasDropdown,
                    onColumnContextMenu: (column, ev) => {
                        onColumnContextMenu(column, ev, metadata1values);
                    },
                    onColumnClick: (ev, column) => {
                        onColumnContextMenu(column, ev, metadata1values);
                    },
                },
            ]);
        } else {
            setColumns([
                { key: 'name', name: 'Project/Task', fieldName: 'taskName', minWidth: 350, maxWidth: 350, isResizable: false },
                {
                    key: 'assigned',
                    name: '',
                    fieldName: 'isAssignedToTask',
                    minWidth: 30,
                    maxWidth: 30,
                    isResizable: false,
                },
            ]);
        }
    };
    const showDefaultTasks = (): void => {
        if (!searchInProgress) {
            setShowdetailsflyout(true);
            //   setSearchState(1);
            setSearchView(SearchViewEnum.defaulttasks);
            //if (searchdefaulttasks.length >= 1) {
            if (searchdefaulttasksloaded) {
                setShowdetailsflyout(true);
                initColumns(false);
                setSearchitems(searchdefaulttasks);
                globalsearchitems = searchdefaulttasks;
                setSearchitemsfiltered(searchdefaulttasks);
                let grouped: any = [];
                grouped = GetDetailsListGroups(searchdefaulttasks);
                setSearchgroups(grouped);
                setSearchgroupsfiltered(grouped);
                setSearchState(2);
            } else {
                const sortedTasks = SortTimeSearchRows([]);
                initColumns(false);
                setSearchitems(sortedTasks);
                setSearchgroupsfiltered([]);
                globalsearchitems = sortedTasks;
                setSearchitemsfiltered(sortedTasks);
                setSearchdefaulttasks(sortedTasks);
                if (sortedTasks.length > 0) {
                    let grouped: any = [];
                    grouped = GetDetailsListGroups(sortedTasks);
                    setSearchgroups(grouped);
                    setSearchgroupsfiltered(grouped);
                    setSearchState(2);
                }
                setShowdetailsflyout(true);
                searchInProgress = false;

                ApiCalls.getDefault().then(json => {
                    const searchResultItems = json.data;

                    const sortedTasks = SortTimeSearchRows(searchResultItems);
                    initColumns(false);
                    setSearchitems(sortedTasks);
                    globalsearchitems = sortedTasks;
                    setSearchitemsfiltered(sortedTasks);
                    setSearchdefaulttasks(sortedTasks);
                    if (sortedTasks.length > 0) {
                        let grouped: any = [];
                        grouped = GetDetailsListGroups(sortedTasks);
                        setSearchgroups(grouped);
                        setSearchgroupsfiltered(grouped);
                        setSearchState(2);
                    }
                    setShowdetailsflyout(true);
                    setsearchdefaulttasksloaded(true);
                    searchInProgress = false;
                });
            }
        }
    };

    const search = async (query: string) => {
        if (!searchInProgress) {
            searchInProgress = true;
            setShowdetailsflyout(true);
            setSearchState(1);
            setSearchView(SearchViewEnum.searching);
            setSearchitems([]);
            globalsearchitems = [];
            setSearchitemsfiltered([]);
            setSearchgroups([]);
            setSearchgroupsfiltered([]);
            setDetailslistItemSelected(false);

            console.debug('SEARCH: ' + query);
            lastsearchquery = query;

            let searchResultItems: TimeSearchRow[] = [];
            if (onlyAssignedSearch) {
                await ApiCalls.searchAssigned({ query }).then(json => {
                    searchResultItems = json.data;
                });
            } else {
                await ApiCalls.searchTasks({ query }).then(json => {
                    searchResultItems = json.data;
                });
            }

            const localmetadata1values: string[] = [];

            searchResultItems.forEach(result => {
                if (!localmetadata1values.includes(result.metadata1)) {
                    localmetadata1values.push(result.metadata1);
                }
            });
            localmetadata1values.sort();
            setMetadata1Values(localmetadata1values);
            console.log('searchResultItems', searchResultItems);

            const sortedTasks = SortTimeSearchRows(searchResultItems);
            initColumns(true, localmetadata1values);
            setSearchitems(sortedTasks);
            globalsearchitems = sortedTasks;
            setSearchitemsfiltered(sortedTasks);
            if (sortedTasks.length > 0) {
                let grouped: any = [];
                grouped = GetDetailsListGroups(sortedTasks);
                setSearchgroups(grouped);
                setSearchgroupsfiltered(grouped);
                setSearchState(2);
            } else {
                setSearchState(0);
            }
            setSearchView(SearchViewEnum.searching);
            setShowdetailsflyout(true);
            setFilterAssign(false);
            setFilterTasks(false);
            setFilterProjects(true);
            searchInProgress = false;
            if (parkedSearch != null) {
                parkedSearch();
                parkedSearch = null;
            }
        } else {
            if (lastsearchquery !== query) {
                parkedSearch = () => {
                    search(query);
                };
            }
        }
    };

    const detilaslistItemChange = (item?: any, index?: number, ev?: React.FocusEvent<HTMLElement>): void => {
        setDetailslistItemSelected(true);

        //TODO: Why not use this? /kra
        // let selection = selectedTasks.getSelection();

        // if (selection.length >= 1) {
        //     setDetailslistItemSelected(true);
        // } else {
        //     setDetailslistItemSelected(false);
        // }
    };
    const onRenderColumn = (item: TimeSearchRowM, index: number, column: IColumn): ReactElement => {
        const fieldContent = item[column.fieldName as keyof TimeSearchRowM] as string;

        switch (column.key) {
            case 'assigned':
                if (item.isAssignedToTask) {
                    return (
                        <TooltipHost
                            content="You are assigned to this task"
                            closeDelay={500}
                            styles={{ root: { height: '100%', display: 'flex', alignItems: 'center' } }}
                        >
                            <FontIcon iconName="ReminderPerson" />
                        </TooltipHost>
                    );
                } else if (item.isAssignedToProject) {
                    return (
                        <TooltipHost
                            content="You are assigned to the project, but not this particular task"
                            closeDelay={500}
                            styles={{ root: { height: '100%', display: 'flex', alignItems: 'center' } }}
                        >
                            <FontIcon iconName="ContactList" />
                        </TooltipHost>
                    );
                } else {
                    return (
                        <TooltipHost
                            content="You are not assigned to this project or task"
                            closeDelay={500}
                            styles={{ root: { height: '100%', display: 'flex', alignItems: 'center' } }}
                        >
                            <FontIcon iconName="Search" />
                        </TooltipHost>
                    );
                }
            case 'name':
                if (searchView === SearchViewEnum.defaulttasks) {
                    return (
                        <TooltipHost content={item.description} closeDelay={500}>
                            <Text styles={{ root: { fontSize: 12 } }}>{fieldContent}</Text>
                        </TooltipHost>
                    );
                } else {
                    return <Text styles={{ root: { fontSize: 12 } }}>{fieldContent}</Text>;
                }
            default:
                return <Text styles={{ root: { fontSize: 12 } }}>{fieldContent}</Text>;
        }
    };
    const onRenderGroupHeader: IDetailsGroupRenderProps['onRenderHeader'] = (props, defaultrender) => {
        if (props) {
            const metadata1: string = (props.group as any).metadata1;
            const projectSource: ProjectSource = (props.group as any).projectSource;

            return (
                <GroupHeader
                    {...props}
                    onRenderTitle={props => {
                        return (
                            <div>
                                <div className={classNames.headerTitle}>{props.group?.name}</div>
                                <div className={classNames.headerSourceTitles}>
                                    {projectSource?.iconUrl != null && (
                                        <ProjectSourceIcon id={`${props.group.name}_${projectSource.name}`} width={24} projectSource={projectSource} />
                                    )}
                                </div>
                                <div className={classNames.headerAdditionalTitles}>{metadata1}</div>
                            </div>
                        );
                    }}
                />
            );
        }

        return null;
    };
    const onColumnContextMenu = (column: IColumn, ev: React.MouseEvent<HTMLElement>, localmetadata1Values: string[]): void => {
        if (column.columnActionsMode !== ColumnActionsMode.disabled) {
            const items: IContextualMenuItem[] = [];
            items.push({
                key: 'all',
                name: 'All',
                onClick: (ev, item) => {
                    filtertasks(filterAssign, filterTasks, filterProjects);
                },
            });
            for (let i = 0; i < localmetadata1Values.length; i++) {
                if (!isNullOrUndefined(localmetadata1Values[i]) && localmetadata1Values[i] !== '') {
                    items.push({
                        key: i.toString(),
                        name: localmetadata1Values[i],
                        onClick: (ev, item) => {
                            filtertasks(filterAssign, filterTasks, filterProjects, item.name);
                        },
                    });
                }
            }
            setMetadata1ContextualMenu({
                items: items,
                target: ev.currentTarget as HTMLElement,
                directionalHint: DirectionalHint.bottomLeftEdge,
                gapSpace: 10,
                isBeakVisible: true,
                onDismiss: onContextualMenuDismissed,
            });
        }
    };
    const onContextualMenuDismissed = (): void => {
        setMetadata1ContextualMenu(undefined);
    };

    const onRenderRow: IDetailsListProps['onRenderRow'] = rowProps => {
        const customStyles: Partial<IDetailsRowStyles> = {};

        if (rowProps) {
            if (rowProps.item.taskId) {
                const alreadyAdded = props.alreadyAddedProjects.find(addedProject => addedProject.taskId === rowProps.item.taskId);
                if (alreadyAdded) {
                    customStyles.root = {
                        backgroundColor: uiCtx.isDarkmode ? themeCtx.palette.neutralLight : themeCtx.palette.neutralLighter,
                        '.ms-DetailsRow-fields': { opacity: 0.55 },
                        // backgroundColor: uiCtx.isDarkmode ? theme.palette.neutralQuaternary : theme.palette.neutralLighter, ".ms-DetailsRow-fields": { opacity: 0.55 }
                        // backgroundColor: uiCtx.isDarkmode ? "blue" : "red", ".ms-DetailsRow-fields": { opacity: 0.55 }
                    };
                }
            }

            return <DetailsRow {...rowProps} styles={customStyles} />;
        }
        return null;
    };

    return (
        <div>
            <div ref={searchAreaRef} className="searchmainarea">
                <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                    <div ref={searchIconRef} id={'tft-search'} className="buttoncontaynerstyle timestateenabled" onClick={searchSvgSelected}>
                        <Stack horizontalAlign="center">
                            {props.active ? (
                                <div style={{ flexGrow: 1 }}>
                                    <SearchIcon />
                                </div>
                            ) : (
                                <TooltipHost
                                    content={'The timesheet is closed and you cannot insert tasks. Recall the timesheet to insert tasks.'}
                                    closeDelay={500}
                                >
                                    <div style={{ flexGrow: 1 }}>
                                        <SearchIconInactive />
                                    </div>
                                </TooltipHost>
                            )}
                            <Text className="timestatelabel">Search</Text>
                        </Stack>
                    </div>
                </Stack>
            </div>
            {showdetailsflyout && (
                <Callout
                    directionalHint={DirectionalHint.rightTopEdge}
                    directionalHintFixed={true}
                    className={styles.callout}
                    role="alertdialog"
                    gapSpace={0}
                    target={searchIconRef}
                    onDismiss={searchForDismissed}
                    onLayerMounted={() => searchFieldRef.current && searchFieldRef.current.focus()}
                >
                    <div className={styles.header}>
                        <SearchBox
                            placeholder="Search..."
                            value={searchInputValue}
                            underlined={true}
                            styles={searchBoxStyles}
                            onSelect={searchFor}
                            onChange={charsTyped}
                            onSearch={onsearch}
                            onFocus={searchFocus}
                            componentRef={searchFieldRef}
                            iconProps={filterIcon}
                        />
                        <div className={styles.message}>
                            {searchInputValue.length > 0 && searchInputValue.length < 3 && (
                                <Text className="delayedFadeIn" styles={{ root: { fontSize: 10, color: theme.semanticColors.bodySubtext } }}>
                                    Type at least 3 characters or enter to search
                                </Text>
                            )}
                        </div>
                    </div>
                    <div>
                        {searchState === 0 ? (
                            <div></div>
                        ) : searchState === 1 ? (
                            <Stack styles={{ root: { paddingBottom: 20 } }}>
                                <Spinner label="Searching..." />
                            </Stack>
                        ) : (
                            <Stack className={styles.inner}>
                                <Stack horizontal horizontalAlign="space-between" verticalAlign="center" styles={{ root: { paddingBottom: 10 } }}>
                                    {!onlyAssignedSearch && (
                                        <Stack horizontal horizontalAlign="space-between" verticalAlign="center" tokens={{ childrenGap: 4 }}>
                                            <TooltipHost content="Search all tasks where I am assigned" closeDelay={500}>
                                                <IconButton
                                                    className={'filterIcon' + (filterAssign ? ' filterSelected' : '')}
                                                    // className='filterIcon'
                                                    iconProps={{ iconName: 'ReminderPerson' }}
                                                    checked={filterAssign}
                                                    onClick={assignSelected}
                                                    disabled={false}
                                                    // styles={{icon: { fontSize: 22 }}}
                                                />
                                            </TooltipHost>
                                            <TooltipHost content="Search all tasks where I am working on the project" closeDelay={500}>
                                                <IconButton
                                                    className={'filterIcon' + (filterTasks ? ' filterSelected' : '')}
                                                    // className='filterIcon'
                                                    iconProps={{ iconName: 'ContactList' }}
                                                    checked={filterTasks}
                                                    onClick={tasksSelected}
                                                    disabled={false}
                                                    // styles={{icon: { fontSize: 22 }}}
                                                />
                                            </TooltipHost>
                                            <TooltipHost content="Search all projects and tasks" closeDelay={500}>
                                                <IconButton
                                                    className={'filterIcon' + (filterProjects ? ' filterSelected' : '')}
                                                    // className='filterIcon'
                                                    iconProps={{ iconName: 'Search' }}
                                                    checked={filterProjects}
                                                    onClick={projectSelected}
                                                    disabled={false}
                                                    // styles={{icon: { fontSize: 22 }}}
                                                />
                                            </TooltipHost>
                                        </Stack>
                                    )}

                                    {searchitemsfiltered.length < 1 ? (
                                        <></>
                                    ) : (
                                        <PrimaryButton text="Add" onClick={addButtonClicked} /*disabled={!detailslistItemSelected} - does not work properly*/ />
                                    )}
                                </Stack>
                                <div style={{ overflow: 'auto', maxHeight: '70vh' }}>
                                    {searchitemsfiltered.length < 1 ? (
                                        <></>
                                    ) : (
                                        <>
                                            <DetailsList
                                                onItemInvoked={(a, b, c) => addTimeRow()}
                                                items={searchitemsfiltered}
                                                groups={searchgroupsfiltered}
                                                onActiveItemChanged={detilaslistItemChange}
                                                columns={columns}
                                                selectionMode={SelectionMode.multiple}
                                                selection={selectedTasks}
                                                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                                ariaLabelForSelectionColumn="Toggle selection"
                                                checkButtonAriaLabel="Row checkbox"
                                                groupProps={{
                                                    showEmptyGroups: true,
                                                    onRenderHeader: onRenderGroupHeader,
                                                }}
                                                onRenderItemColumn={onRenderColumn}
                                                onRenderRow={onRenderRow}
                                                // column.columnActionsMode = ColumnActionsMode.disabled;
                                                compact={true}
                                                styles={{
                                                    root: {
                                                        '.ms-DetailsHeader .ms-DetailsHeader-cellIsCheck .ms-Icon': { display: 'none' },
                                                        '.ms-DetailsHeader': { paddingTop: 0, backgroundColor: 'rgba(0, 0, 0, 0)' },
                                                        '.ms-DetailsHeader-cellIsCheck': { width: '0px !important' },
                                                        '.ms-DetailsHeader-cell .ms-DetailsRow-check': { width: '0px !important' },
                                                        '.ms-GroupHeader .ms-GroupSpacer': { width: '0px !important' },
                                                        '.ms-DetailsRow .ms-GroupSpacer': { width: '0px !important' },
                                                        '.ms-DetailsRow-cell': { display: 'flex', alignItems: 'center', paddingLeft: 26 },
                                                    },
                                                }}
                                            />
                                            {metadata1ContextualMenu && <ContextualMenu {...metadata1ContextualMenu} />}
                                        </>
                                    )}
                                </div>
                            </Stack>
                        )}
                    </div>
                </Callout>
            )}
        </div>
    );
}
export default Search;
