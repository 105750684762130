import { Stack } from '@fluentui/react';

export const GridContainer = ({ children }: { children: React.ReactNode }) => {
    return (
        <Stack
            styles={{
                root: {
                    //
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr',
                    gridGap: '10px',
                    // ...style,
                },
            }}
        >
            {children}
        </Stack>
    );
};
