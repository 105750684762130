import { Spinner, SpinnerSize, Stack, useTheme } from '@fluentui/react';
import { forwardRef, memo, useMemo } from 'react';
import { useLocation } from 'react-router';
import { TransitionGroup } from 'react-transition-group';
import { SettingsCard } from './SettingsCard';
import { CSSTransitionWithRefRenderProps } from '../../_components/CSSTransitionWithRefRenderProps';

export type TSettingsCategory = {
    title: string;
    shortDescription: string;
    description?: React.ReactNode | React.FC<any>;
    iconName: string;
    link: string;
    children: TSettingsCategory[] | React.ReactNode;
};

type TDashBoardCategory = {
    category: TSettingsCategory;
    loading: boolean;
};

export const Category = memo(({ category, loading }: TDashBoardCategory) => {
    const { pathname } = useLocation();
    const { render, grid } = useMemo(() => {
        const currentItem = category.children;
        if (Array.isArray(currentItem)) {
            return {
                render: (currentItem as TSettingsCategory[]).map((item, i) => {
                    return (
                        <CSSTransitionWithRefRenderProps
                            //
                            key={item.link}
                            mountOnEnter
                            unmountOnExit
                            timeout={{ enter: i * 100, exit: 0 }}
                            classNames={'card'}
                        >
                            {ref => <SettingsCard ref={ref} {...item} />}
                        </CSSTransitionWithRefRenderProps>
                    );
                }),
                grid: true,
            };
        }
        return {
            render: loading ? (
                <Spinner size={SpinnerSize.large} label="Loading settings.." />
            ) : (
                <CSSTransitionWithRefRenderProps
                    //
                    mountOnEnter
                    unmountOnExit
                    timeout={{ enter: 100, exit: 0 }}
                    classNames={'form'}
                >
                    {ref => <FormContainer ref={ref}>{currentItem}</FormContainer>}
                </CSSTransitionWithRefRenderProps>
            ),
            grid: false,
        };
    }, [category.children, loading]);

    return (
        <Stack>
            <TransitionGroup key={pathname} className={grid ? 'fadein-transition-container grid' : 'fadein-transition-container'} appear>
                {render}
            </TransitionGroup>
        </Stack>
    );
});

const FormContainer = forwardRef<HTMLDivElement, { children?: React.ReactNode }>(({ children }, ref) => {
    const theme = useTheme();
    return (
        <div style={{ height: '100%', width: '100%', backgroundColor: theme.semanticColors.bodyBackground }} ref={ref}>
            {children}
        </div>
    );
});
