import React, { useEffect, useState } from 'react';
import { Persona, PersonaSize, PersonaPresence, IPersonaProps } from '@fluentui/react/lib/Persona';
import { SearchBox } from '@fluentui/react/lib/SearchBox';
import { Stack } from '@fluentui/react/lib/Stack';
import { Checkbox, DefaultButton } from '@fluentui/react';
import { useUiContext } from '../../../../components/Contexts/UiContext';
import User from '../../../../Model/User';
import { ContentSection, PageContent, PageHeader, TableHeaderRow } from '../../../../components/LayoutElements';
import FilterFlyout from '../../../../components/LayoutElements/FilterFlyout';
import { ApproverPicker } from './ApproverPicker';

interface IProps {
    allUsers: User[];
    allUsersPersonas: IPersonaProps[];
}

export const ApproverConfigOverview: React.FC<IProps> = props => {
    const [filteredUsers, setFilteredUsers] = useState<User[]>(props.allUsers);
    const [filterInputValue, setFilterInputValue] = useState<string>('');
    const [filterApproveValue, setfilterApproveValue] = useState<string>('All');
    const [showCount, setShowCount] = useState(50);

    const [typingTimeout, setTypingTimeout] = useState<any>(0);
    const [searchIsError, setSearchIsError] = useState<string>('');

    const uiCtx = useUiContext();

    const personaContainerDivStyle = {
        width: '325px',
    };

    useEffect(() => {
        setFilteredUsers(props.allUsers);
    }, [props.allUsers]);

    const onFilterBoxChanged = (event: React.ChangeEvent<HTMLInputElement>, value: string): void => {
        setFilterInputValue(value);
        filterUsers(value, filterApproveValue);
    };
    const onActiveFilterChanged = (value: string): void => {
        setfilterApproveValue(value);
        filterUsers(filterInputValue, value, 50);
    };
    const filterUsers = (value: string, approvefilter: string, timeout: number = 750): void => {
        if (props.allUsers.length > 1000) {
            if (value.length < 3 && value.length > 0) {
                setSearchIsError('Type at least 3 characters');
                return;
            } else {
                setSearchIsError('');
            }
        }

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setTypingTimeout(
            setTimeout(() => {
                if (value == '') {
                    setFilteredUsers(
                        props.allUsers.filter(
                            (au: User) => approvefilter == 'All' || (au.autoApprove == true && approvefilter == 'Auto Approve') || (au.autoApprove == false && approvefilter == 'Manual Approve'),
                        ),
                    );
                    return;
                }
                setFilteredUsers(
                    props.allUsers.filter(
                        (au: User) =>
                            (au.name.toLowerCase().indexOf(value.toLowerCase()) > -1 || au.userNumber?.toLowerCase().indexOf(value.toLowerCase()) > -1) &&
                            (approvefilter == 'All' || (au.autoApprove == true && approvefilter == 'Auto Approve') || (au.autoApprove == false && approvefilter == 'Manual Approve')),
                    ),
                );
            }, timeout),
        );
    };

    function createApproverPickers(): JSX.Element[] {
        // return filteredUsers.slice(0,100).map((user: User, index: any) => {
        return filteredUsers.map((user: User, index: any) => {
            if (index > showCount) {
                return null;
            }
            //if (user.autoApprove) console.log(user)

            return (
                <div style={{ display: 'inline-flex' }} key={index}>
                    <div style={{ ...personaContainerDivStyle, paddingLeft: 8 }}>
                        <Persona
                            //imageUrl={'https://outlook.office.com/owa/service.svc/s/GetPersonaPhoto?email=' + user.email + '&UA=0&size=HR48x48'}
                            imageInitials={user.name?.substring(0, 1) + (user.surname ? user.surname.substring(0, 1) : '')}
                            text={user.name}
                            secondaryText={user.userNumber ?? user.jobTitle ?? ''}
                            showSecondaryText={true}
                            size={PersonaSize.size48}
                            presence={PersonaPresence.none}
                            imageAlt={user.name + ', ' + user.jobTitle}
                            itemID={user.id}
                        />
                    </div>
                    <ApproverPicker
                        concernedUser={user}
                        // allUsers={props.allUsers}
                        allUsersPersonas={props.allUsersPersonas}
                        currentApproverPersona={
                            user.approver && [
                                {
                                    imageInitials: user.approver.name?.substring(0, 1) + (user.approver.surname ? user.approver.surname.substring(0, 1) : ''),
                                    text: user.approver.name,
                                    secondaryText: user.approver.userNumber ?? user.approver.jobTitle ?? '',
                                    showSecondaryText: true,
                                    size: PersonaSize.size24,
                                    presence: PersonaPresence.none,
                                    imageAlt: user.approver.name + ', ' + user.approver.jobTitle,
                                    itemID: user.approver.id,
                                },
                            ]
                        }
                    />
                    <div style={{ paddingLeft: 8 }}>
                        <Checkbox
                            key={user.id}
                            defaultChecked={user.autoApprove}
                            onChange={(e, c) => {
                                uiCtx.timeApi.setUserAutoApprove(user.id, c);
                                filteredUsers.map(fu => {
                                    if (fu.id != user.id) {
                                        return fu;
                                    }
                                    fu.autoApprove = c;
                                    return fu;
                                });
                            }}
                        />
                    </div>
                </div>
            );
        });
    }

    return (
        <>
            <PageHeader title="Approver configuration" icon="ManagerSelfService" />
            <ContentSection>
                <div style={{ width: '100%', maxWidth: 770, paddingBottom: 8 }}>
                    <SearchBox
                        placeholder="Filter users"
                        iconProps={{ iconName: 'Filter' }}
                        onChange={onFilterBoxChanged}
                        value={filterInputValue}
                        styles={{
                            root: {
                                // TODO: fix color
                                borderColor: `${searchIsError != '' ? 'rgba(155,42,49,0.75)' : 'rgba(208,208,208,1)'}`,
                                borderWidth: '1px',
                                '&:hover': {
                                    // TODO: fix color
                                    borderColor: `${searchIsError != '' ? 'rgba(155,42,49,1)' : 'rgba(73,74,130,0.75)'}`,
                                },
                                '&::after': {
                                    // TODO: fix color
                                    borderColor: `${searchIsError != '' ? 'rgba(155,42,49,1)' : 'rgba(96,97,170,1)'}`,
                                },
                            },
                        }}
                    />
                </div>
            </ContentSection>

            <PageContent>
                <ContentSection noPaddingTop>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <TableHeaderRow>
                            <div style={{ width: personaContainerDivStyle.width, textAlign: 'left', paddingLeft: 8 }}>
                                <h4>User</h4>
                            </div>
                            <h5 style={{ width: 292, marginRight: 32 }}>Approver</h5>
                            <FilterFlyout choices={['All', 'Auto Approve', 'Manual Approve']} defaultchoice={filterApproveValue} callback={onActiveFilterChanged}>
                                <h5 style={{ padding: '0 8px' }}>Auto Approve</h5>
                            </FilterFlyout>
                        </TableHeaderRow>
                        {createApproverPickers()}
                        {showCount < filteredUsers?.length && <DefaultButton text="Show more" onClick={() => setShowCount(s => s + 50)} styles={{ root: { alignSelf: 'center', border: 'none' } }} />}
                    </Stack>
                </ContentSection>
            </PageContent>
        </>
    );
};
