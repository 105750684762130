import { memo, useMemo } from 'react';
import { ROW_HEIGHT } from '../../../CONSTANTS';
// import { TimeRow, getFamilyOfSubrows, getImmediateSubrows, getParentRows, isPseudoRow, useGridExpandedRowState } from '../../../../../../../_grid/grid';
import { RowMetaContext, RowMetaContextProvider } from '../../../context/RowMetaContext';
import { RowExpandContextProvider } from '../../../context/RowExpandContext';
import { ApprovalRow } from '../ApprovalRow/ApprovalRow';
import { GroupingRow } from '../GroupingRow/GroupingRow';
import { IStackProps, Stack, useTheme } from '@fluentui/react';
import { TimeRow, getFamilyOfSubrows, getImmediateSubrows, getParentRows, isPseudoRow, useGridExpandedRowState } from '../../../../../../../_grid/grid2';

export const RowWrapper = memo(function RowWrapper({ row, start, rows }: { row: TimeRow; rows: TimeRow[]; start: number }) {
    // const [expanded, setExpanded] = useGridExpandedRowState({ id: row.id, rows });
    const [expanded, setExpanded] = useGridExpandedRowState(row.id);
    const style = useMemo(() => {
        return {
            position: 'absolute',
            transform: `translateY(${start}px)`,
            top: 0,
            left: 0,
            height: `${ROW_HEIGHT}px`,
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
        } as React.CSSProperties;
    }, [start]);

    const theme = useTheme();

    const styles = useMemo((): IStackProps['styles'] => {
        return {
            root: {
                '&:hover': {
                    backgroundColor: theme.semanticColors.bodyBackgroundHovered,
                },
            },
        };
    }, [theme.semanticColors.bodyBackgroundHovered]);

    const render = useMemo(() => {
        if (isPseudoRow(row)) {
            return <GroupingRow row={row} />;
        }
        return <ApprovalRow row={row} />;
    }, [row]);

    const rowMetaContext = useMemo((): RowMetaContext => {
        const parentRows = getParentRows(row.id, rows);
        const siblingRows = (parentRows.length && getFamilyOfSubrows(parentRows[parentRows.length - 1].id, rows)) || [];
        return {
            row,
            parentRows,
            subrows: getImmediateSubrows(row.id, rows),
            subrowFamily: getFamilyOfSubrows(row.id, rows),
            nestingLevel: parentRows.length,
            siblingRows,
        };
    }, [row, rows]);

    return (
        <Stack style={style} styles={styles}>
            <RowMetaContextProvider value={rowMetaContext}>
                <RowExpandContextProvider value={{ expanded, setExpanded }}>{render}</RowExpandContextProvider>
            </RowMetaContextProvider>
        </Stack>
    );
});
