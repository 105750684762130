import { Icon, Separator, Text } from '@fluentui/react';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

interface IProps {
    title?: string;
    icon?: string;
    noUnderline?: boolean;
    children?: any;
    noPaddingBottom?: boolean;
}

export const PageHeader: React.FC<IProps> = props => {
    return (
        <div className={`page_header${props.icon || props.noUnderline ? ' no_border' : ''}${props.noPaddingBottom ? ' noPaddingBottom' : ''}`}>
            <div className="flex_content">
                {props.title && (
                    <Text variant="xLargePlus" styles={{ root: { display: 'block', textAlign: 'center', paddingBottom: 8 } }}>
                        {props.title}
                    </Text>
                )}

                {props.children && props.children}

                {props.icon && (
                    <Separator styles={{ root: { marginLeft: -16, marginRight: -16 } }}>
                        <Icon iconName={props.icon} styles={{ root: { fontSize: '24px', height: '24px', width: '24px' } }} />
                    </Separator>
                )}
            </div>
        </div>
    );
};

{
    /* <Stack horizontal wrap horizontalAlign="space-between" className="filterSection">
            <Stack verticalAlign="center" horizontal>
                <Search alreadyAddedProjects={mytimeprojects} timeAdd={addTaskFromSearch} active={(timestate == TimeState.open || timestate == TimeState.rejected ? true : false)} />
            </Stack>
            <Stack verticalAlign="center">
                {allPeriods && <PeriodPicker periodPicked={timePeriodSelected} defaultStartDate={periodStart} defaultEndDate={periodEnd} allPeriods={allPeriods} />}


            </Stack>
            <TimeStatePicker
                state={timestate}
                stateChanged={timestatechanged}
                periodIsClosed={periodIsClosed}
                comment={comment}

            />
        </Stack> */
}
{
    /* <Separator>
                <Icon iconName="Calendar" styles={separatorIconStyles} />
            </Separator> */
}
