import { useCallback, useRef, useState } from 'react';
import { Stack, IDropdownOption, Dialog, DialogFooter, PrimaryButton, Toggle } from '@fluentui/react';
import { MicrosoftEntraIdIntegration } from '../../../../api/generated/data-contracts';
import { useQuery } from '@tanstack/react-query';
import { disableAllSpellchecksAndAutoCompleteProp } from '../../../../helpers/killEvent';
import produce from 'immer';
import { ApiCalls } from '../../../../api/api';
import { FilterDropdown } from '../../../FilterDropdown/FilterDropdown';
import { INTEGRATIONS_MICROSOFT_ENTRAID_GROUPS } from '../../../../constants/queryKeys';

export const MicrosoftEntraIdLayout = ({
    integration,
    setIntegration,
    isLoading,
}: {
    integration: MicrosoftEntraIdIntegration;
    setIntegration: React.Dispatch<React.SetStateAction<MicrosoftEntraIdIntegration>>;
    isLoading: boolean;
}) => {
    const { enabled, includeManager, syncUsersToDataverse, groupName } = integration.configuration;

    const toggleEnableEvent = useCallback(
        (event: any, checked?: boolean) => {
            setIntegration(integration => {
                return {
                    ...integration,
                    configuration: {
                        ...integration.configuration,
                        enabled: checked ?? !enabled,
                    },
                };
            });
        },
        [enabled, setIntegration],
    );

    const [columnsError, setColumnsError] = useState<any>(null);

    const defaultEntraIdGroups: IDropdownOption[] = useRef([]).current;

    const { data: entraIdGroups = defaultEntraIdGroups } = useQuery({
        queryKey: [INTEGRATIONS_MICROSOFT_ENTRAID_GROUPS],
        queryFn: () => ApiCalls.getAdGroups().then(groups => groups.data.map(group => ({ key: group.id, text: group.name } as IDropdownOption))),
    });

    return (
        <>
            <Stack horizontal tokens={{ childrenGap: 10 }} {...disableAllSpellchecksAndAutoCompleteProp}>
                <Stack styles={{ root: { paddingLeft: 20, paddingBottom: 20, width: 400 } }}>
                    <Toggle disabled={isLoading} checked={enabled} onChange={toggleEnableEvent} label="Enabled" />
                    <FilterDropdown
                        options={entraIdGroups}
                        selectedKey={entraIdGroups?.find(group => group.text === groupName)?.key || undefined}
                        onChange={(e, option) => {
                            setIntegration(
                                produce(integration => {
                                    integration.configuration.groupName = option?.text || '';
                                }),
                            );
                        }}
                        disabled={entraIdGroups?.length < 1}
                        placeholder={entraIdGroups?.length < 1 ? 'Loading...' : 'Select Entra ID Group'}
                    />
                    <Toggle
                        disabled={isLoading}
                        checked={includeManager}
                        onChange={(e, val) =>
                            setIntegration(
                                produce(integration => {
                                    integration.configuration.includeManager = val;
                                }),
                            )
                        }
                        label="Include manager"
                    />
                    <Toggle
                        disabled={isLoading}
                        checked={syncUsersToDataverse}
                        onChange={(e, val) =>
                            setIntegration(
                                produce(integration => {
                                    integration.configuration.syncUsersToDataverse = val;
                                }),
                            )
                        }
                        label="Sync users to Dataverse"
                    />
                </Stack>
                <div style={{ width: '1px', backgroundColor: 'rgba(0,0,0,0.1)', flexGrow: 1 }} />
            </Stack>
            <Dialog hidden={!columnsError} dialogContentProps={{ title: 'An error occured' }}>
                Couldn't communicate with Microsoft Entra ID. It's most likely an access issue with your account.
                <DialogFooter>
                    <PrimaryButton onClick={() => setColumnsError(null)}>Okay</PrimaryButton>
                </DialogFooter>
            </Dialog>
        </>
    );
};
