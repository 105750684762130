import { Stack, Shimmer, useTheme, ShimmerElementType } from '@fluentui/react';
import { styles } from './CardItem.styles';
import { useGetStyles } from '../../useGetStyles';

export const CardItemShimmer = () => {
    const theme = useTheme();
    const classes = useGetStyles(styles);
    return (
        <Stack className={classes.root} styles={{ root: { width: 400 } }}>
            <Stack horizontal tokens={{ childrenGap: theme.spacing.m }} verticalAlign="center" styles={{ root: { paddingBottom: theme.spacing.m } }}>
                <ShimmerHeader />
            </Stack>
            <div className={classes.divider} />
            <Stack tokens={{ childrenGap: theme.spacing.m }}>
                <ShimmerLine />
                <ShimmerLine />
                <ShimmerLine />
            </Stack>
        </Stack>
    );
};

const shimmerLineElements = [
    {
        type: ShimmerElementType.line,
        width: 80,
    },
    {
        type: ShimmerElementType.gap,
        width: 10,
    },
    {
        type: ShimmerElementType.line,
        width: 100,
    },
    // {
    // 	type: ShimmerElementType.gap,

    // }
];

const ShimmerLine = () => {
    return (
        <Stack>
            <Shimmer shimmerElements={shimmerLineElements} />
        </Stack>
    );
};

const shimmerHeaderElements = [
    {
        type: ShimmerElementType.circle,
        height: 32,
        width: 32,
    },
    {
        type: ShimmerElementType.gap,
        width: 18,
    },
    {
        type: ShimmerElementType.line,
        width: 150,
        height: 20,
    },
];

const ShimmerHeader = () => {
    return <Shimmer shimmerElements={shimmerHeaderElements} />;
};
