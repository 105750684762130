import {
    IPublicClientApplication,
    PublicClientApplication,
    PopupRequest,
    EventType,
    RedirectRequest,
    InteractionRequiredAuthError,
    Configuration,
} from '@azure/msal-browser';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AuthConfig } from '../Model/AuthConfig';
import { TenantNotFoundProblem } from '../api/generated/data-contracts';
import { ApiCalls } from '../api/api';
import { DefaultButton, Label, Link, Stack, Text } from '@fluentui/react';
import { useEnvironment } from './hooks/useEnvironment';
import * as microsoftTeams from '@microsoft/teams-js';
import { MsalProvider } from '@azure/msal-react';
import { Suspender } from '../_components/Suspender';
import { useMutation } from '@tanstack/react-query';
import { useEvent } from '../hooks/useEvent';
import { createCtx } from '../components/Contexts/createCtx';

export interface IAuthContext {
    authConfig: AuthConfig;
    setTenantError: (error: TenantNotFoundProblem) => void;
    // msalInstance: IPublicClientApplication | undefined;
    getMsalAccessToken: () => Promise<string | void>;
    getTeamsAccessToken: () => any;
}

const [useAuthCtx, AuthCtxProvider] = createCtx<IAuthContext>();

export const useAuthContext = useAuthCtx;

const isTeams =
    window.location.ancestorOrigins !== undefined &&
    window.location.ancestorOrigins.length >= 1 &&
    window.location.ancestorOrigins[0] === 'https://teams.microsoft.com';

export const AuthContextProvider: React.FC<any> = memo(props => {
    const [tenantError, setTenantError] = useState<TenantNotFoundProblem | null>(null);
    const [msalInstance, setMsalInstance] = useState<IPublicClientApplication>(null);
    const [isTeamsInitialized, setIsTeamsInitialized] = useState<'pending' | 'success' | 'failed'>(() =>
        microsoftTeams.app.isInitialized() ? 'success' : 'pending',
    );

    const { env: authConfig } = useEnvironment();

    const loginRequest: PopupRequest = useMemo(() => {
        return {
            scopes: [`${authConfig.clientId}/.default`],
        };
    }, [authConfig.clientId]);

    const { mutateAsync: getTeamsToken, error: teamsTokenAcquisitionFailed } = useMutation(
        () => {
            // NOTE: It is crucial that microsoftTeams.app.initialize() is called before this function is called
            return microsoftTeams.authentication.getAuthToken({
                resources: [`api://${authConfig.hostFQDN}/${authConfig.clientId}/access_as_user`],
                silent: true,
            });
        },
        {
            onError: error => {
                console.log(`Failed to get teams AccessToken ${error}`);
            },
        },
    );

    const getTeamsAccessToken: () => Promise<string> = useEvent(() => {
        return getTeamsToken();
    });

    const { mutateAsync: getMsalToken, error: msalTokenAcquisitionFailed } = useMutation(() => {
        return msalInstance
            .acquireTokenSilent(loginRequest)
            .then(response => {
                return response.accessToken;
            })
            .catch((error: any) => {
                console.log('Silent token acquisition failed, falling back to interactive', error);
                return msalInstance.acquireTokenPopup(loginRequest).then(response => {
                    return response.accessToken;
                });
            });
    });

    const getMsalAccessToken: () => Promise<string> = useEvent(() => {
        return getMsalToken();
    });

    const initMsalClient = useEvent(async () => {
        if (msalInstance === null) {
            const msalConfig: Configuration = {
                auth: {
                    clientId: authConfig.clientId,
                    authority: `https://login.microsoftonline.com/${authConfig.tenantId}`,
                    navigateToLoginRequestUrl: false,
                },
                cache: {
                    cacheLocation: 'sessionStorage',
                    storeAuthStateInCookie: true,
                    secureCookies: true,
                },
            };

            const newClientApp = new PublicClientApplication(msalConfig);

            newClientApp.addEventCallback(event => {
                if (event.eventType === EventType.LOGIN_SUCCESS && (event.payload as any).account) {
                    const account = (event.payload as any).account;
                    newClientApp.setActiveAccount(account);
                }
                if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
                    newClientApp.loginRedirect(loginRequest);
                }
            });

            // cached account
            await newClientApp.handleRedirectPromise();
            const accounts = newClientApp.getAllAccounts();

            if (accounts.length > 0) {
                newClientApp.setActiveAccount(accounts[0]);
            } else {
                try {
                    await newClientApp.loginRedirect(loginRequest);
                } catch (e) {
                    if (e.errorCode == 'redirect_in_iframe') {
                        await newClientApp.ssoSilent(loginRequest);
                        return window.location.reload();
                    }
                }
            }

            setMsalInstance(newClientApp);
        }
    });

    const authLibraryInitialized = useRef(false);

    useEffect(() => {
        if (authLibraryInitialized.current) {
            return;
        }

        if (isTeams) {
            microsoftTeams.app
                .initialize()
                .then(() => {
                    setIsTeamsInitialized('success');
                })
                .catch(() => {
                    setIsTeamsInitialized('failed');
                });
            ApiCalls.setSecurityData(getTeamsAccessToken);
        } else {
            initMsalClient();
            ApiCalls.setSecurityData(getMsalAccessToken);
        }

        authLibraryInitialized.current = true;
    }, [getMsalAccessToken, getTeamsAccessToken, initMsalClient]);

    if (tenantError) {
        return (
            <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { height: '100vh' } }}>
                <Text variant="xLarge">
                    The Tenant ID is unknown, please <Link href="https://getourproducts.projectum.com">click here</Link> to get started
                    {tenantError.detail}
                </Text>
            </Stack>
        );
    }

    // Show loader until the auth context is ready
    if ((!isTeams && msalInstance === null) || (isTeams && isTeamsInitialized === 'pending')) {
        return <Suspender />;
    }

    if (isTeams && isTeamsInitialized === 'success') {
        return (
            <AuthCtxProvider
                value={{
                    authConfig,
                    setTenantError,
                    getMsalAccessToken,
                    getTeamsAccessToken,
                }}
            >
                {props.children}
            </AuthCtxProvider>
        );
    }

    if (msalInstance !== null) {
        return (
            <MsalProvider instance={msalInstance}>
                <AuthCtxProvider
                    value={{
                        authConfig,
                        setTenantError,
                        // msalInstance,
                        getMsalAccessToken,
                        getTeamsAccessToken,
                    }}
                >
                    {props.children}
                </AuthCtxProvider>
            </MsalProvider>
        );
    }

    if (teamsTokenAcquisitionFailed || msalTokenAcquisitionFailed || isTeamsInitialized === 'failed') {
        return (
            <Stack className="logindialog" horizontalAlign="center" verticalAlign="center" style={{ height: '70vh' }}>
                <Label>There was an error signing you in.</Label>
                {/* <DefaultButton text="Try again" primary={true} onClick={() => manualSignIn()} /> */}
            </Stack>
        );
    }

    throw new Error('AuthContextProvider: This should never happen.');
});
