import React, { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';
import AdminVideoM from '../../../../../Model/AdminVideoM';
import { TextField } from '@fluentui/react/lib/TextField';
import { Dropdown, IconButton, IDropdownOption } from '@fluentui/react';

interface propsAdminVideo {
    Video: AdminVideoM;
    ReorderVideo: Function;
    RemoveVideo: Function;
    VideoUpdated: Function;
}

const options: IDropdownOption[] = [
    { key: '', text: 'Select a source' },
    { key: 'youtube', text: 'Youtube' },
    { key: 'msstream', text: 'MS Stream' },
];

function AdminVideo(props: propsAdminVideo) {
    const [video, setVideo] = useState<AdminVideoM>(null);
    const [videoHeader, setVideoHeader] = useState<string>(props.Video.header);
    const [videoDescription, setVideoDescription] = useState<string>(props.Video.description);
    const [videoSource, setVideoSource] = useState<string>(props.Video.source);
    const [videoUrl, setVideoUrl] = useState<string>(props.Video.url);
    const [videoOrder, setVideoOrder] = useState<number>(props.Video.order);

    const [headerInFocus, setHeaderInFocus] = useState<boolean>(false);
    const headertxtfield = React.useRef(null);

    useEffect(() => {
        setVideo(props.Video);
        setVideoHeader(props.Video.header);
        setVideoSource(props.Video.source);
        setVideoUrl(props.Video.url);
        setVideoOrder(props.Video.order);
    }, [props.Video]);

    const moveUpClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement, MouseEvent>) => {
        props.ReorderVideo(video, -1);
    };
    const moveDownClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement, MouseEvent>) => {
        props.ReorderVideo(video, 1);
    };
    const removeClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement, MouseEvent>) => {
        props.RemoveVideo(video, 1);
    };
    const newUpdateVideo = () => {
        props.VideoUpdated(video.id, videoHeader, videoDescription, videoSource, videoUrl);
    };
    const updatevideoHeader = (event, value) => {
        setVideoHeader(value);
        // props.videoUpdated(video.id, videoHeader, videoEmail, videoPhone);
    };
    const videoDescriptionUpdate = (event, value) => {
        setVideoDescription(value);
        // props.videoUpdated(video.id, videoHeader, videoEmail, videoPhone);
    };
    const videoSourceUpdate = (event, option: IDropdownOption, index) => {
        setVideoSource(option.key.toString());
        // To account for difference in state for dropdown, Can't use newUpdateVideo function
        props.VideoUpdated(video.id, videoHeader, videoDescription, option.key.toString(), videoUrl);
        // setVideoSource(value);
        // props.videoUpdated(video.id, videoHeader, value, videoPhone);
    };
    const videoUrlUpdate = (event, value) => {
        setVideoUrl(value);
        // props.videoUpdated(video.id, videoHeader, videoEmail, value);
    };
    useEffect(() => {
        if (headerInFocus === true) {
            headertxtfield.current.focus();
        }
    }, [headerInFocus]);
    return (
        <div style={{ width: 660 }}>
            <Stack tokens={{ childrenGap: 20 }} horizontal verticalAlign="baseline">
                <Stack.Item grow={1}>
                    {headerInFocus ? (
                        <TextField
                            componentRef={headertxtfield}
                            onBlur={() => {
                                setHeaderInFocus(false);
                                newUpdateVideo();
                            }}
                            value={videoHeader}
                            onChange={updatevideoHeader}
                        />
                    ) : (
                        <Stack horizontal tokens={{ childrenGap: 0 }} verticalAlign="baseline">
                            <h4
                                onClick={() => {
                                    setHeaderInFocus(true);
                                }}
                                style={{ cursor: 'pointer', textDecoration: 'none' }}
                            >
                                {videoHeader}
                            </h4>
                        </Stack>
                    )}
                </Stack.Item>
                <Stack.Item grow={5}>
                    <Separator></Separator>
                </Stack.Item>
                <Stack.Item grow={1} styles={{ root: { alignItems: 'center', display: 'flex', justifyContent: 'flex-end' } }}>
                    <Stack tokens={{ childrenGap: 0 }} horizontal>
                        <IconButton
                            iconProps={{ iconName: 'SkypeCircleArrow' }}
                            checked={false}
                            style={{ fontSize: 32, color: 'var(--palette-black)', transform: 'rotate(-90deg)' }}
                            onClick={moveDownClick}
                        />
                        <IconButton
                            iconProps={{ iconName: 'SkypeCircleArrow' }}
                            checked={false}
                            style={{ fontSize: 32, color: 'var(--palette-black)', transform: 'rotate(90deg)' }}
                            onClick={moveUpClick}
                        />
                        <IconButton iconProps={{ iconName: 'SkypeCircleMinus' }} checked={false} style={{ fontSize: 32, color: 'var(--palette-black)' }} onClick={removeClick} />
                    </Stack>
                </Stack.Item>
            </Stack>

            <div>
                <Stack tokens={{ childrenGap: 10 }} verticalAlign="start" style={{ margin: 10 }}>
                    <TextField width={300} placeholder={'Description'} value={videoDescription} onChange={videoDescriptionUpdate} onBlur={newUpdateVideo} />
                    <Dropdown options={options} multiSelect={false} selectedKey={videoSource} onChange={videoSourceUpdate} styles={{ title: { textAlign: 'left' } }} />
                    <TextField width={300} placeholder={'Url'} value={videoUrl} onChange={videoUrlUpdate} onBlur={newUpdateVideo} />

                    <br />
                </Stack>
            </div>
        </div>
    );
}

export default AdminVideo;
