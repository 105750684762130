import { Icon, TooltipHost } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CornIcon, TimeApprovedIcon } from '../Icons';
import { TimeIconWhite } from '../Icons/TimeIconWhite';
import { useUiContext } from './Contexts/UiContext';
import { useTimeUIConfig } from '../hooks/useTimeUIConfig';

function NavHeader() {
    const uiCtx = useUiContext();
    const location = useLocation();
    const { config } = useTimeUIConfig();

    const [hashPath, setHashPath] = useState(window.location.hash);

    useEffect(() => {
        setHashPath(window.location.hash);
    }, [location]);

    if (uiCtx.inIframe) {
        return null;
    }

    return (
        <div className="topbar-container">
            <div className="corn">
                <a href="https://www.office.com/">
                    <CornIcon />
                </a>
            </div>
            <div className="head" style={{ display: 'flex' }}>
                <span className="headTitle">Time</span>

                {hashPath !== '#/' && (
                    <Link to="/" style={{ marginLeft: 'auto', marginRight: '20px', color: 'white' }}>
                        <TooltipHost content="Go to time registration" id="TimeIconWhite">
                            <TimeIconWhite />{' '}
                        </TooltipHost>
                    </Link>
                )}
                {hashPath === '#/' && (
                    <Link to="/approve" style={{ marginLeft: 'auto', marginRight: '20px', color: 'white' }}>
                        <TooltipHost content="Go to time registration approvals" id="TimeIconWhite">
                            <TimeApprovedIcon />
                        </TooltipHost>
                    </Link>
                )}
                {uiCtx.nativeChartsEnabled && (
                    <Link to="/charts" id={'tft-charts'} style={{ marginRight: '20px', marginTop: -2, color: 'white' }}>
                        <TooltipHost content="Go to charts" id="TimeIconWhite">
                            <Icon style={{ fontSize: 20 }} iconName="BarChartVerticalFill" />
                        </TooltipHost>
                    </Link>
                )}
                {uiCtx.user.user.isProjectManager && config?.approval?.projectManagerApproval?.enabled && (
                    <Link to="/project-approval" style={{ marginRight: '20px', marginTop: -2, color: 'white' }}>
                        <TooltipHost content="Go to project approvals" id="TimeIconWhite">
                            <Icon style={{ fontSize: 20 }} iconName="InboxCheck" />
                        </TooltipHost>
                    </Link>
                )}
                {(uiCtx.user.user.isAdmin || uiCtx.user.delegatedUsers.length > 0) && (
                    <Link to="/admin" style={{ marginRight: '20px', marginTop: -2, color: 'white' }}>
                        <TooltipHost content="Go to settings" id="TimeIconWhite">
                            <Icon style={{ fontSize: 20 }} iconName="Settings" />
                        </TooltipHost>
                    </Link>
                )}
                <Link to="/help" id={'tft-help'} style={{ marginRight: '20px', marginTop: -2, color: 'white' }}>
                    <TooltipHost content="Go to help" id="TimeIconWhite">
                        <Icon style={{ fontSize: 20 }} iconName="Help" />
                    </TooltipHost>
                </Link>
            </div>
        </div>
    );
}

export default NavHeader;
