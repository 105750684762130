import { useLayoutEffect, useState } from 'react';

/**
 *
 * Useful when syncing state to e.g props changes
 *
 * @param controlledState A state that automatically gets updated when dependencies changes
 * @param dependencies All dependencies that should trigger a state update
 */
export function useControlledState<S>(controlledState: S | ((lastState?: S) => S), dependencies: any[]) {
    const [state, setState] = useState<S>(controlledState);
    useLayoutEffect(() => {
        setState(controlledState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
    return [state, setState] as const;
}
