import React from "react";
import { Stack } from "@fluentui/react";
import { ProjectRow } from "./ProjectRow";
import { IProjects } from "../../../../mobileTypes";

interface IProps {
    projects: IProjects[];
}

export const ProjectsGrid: React.FC<IProps> = (props) => {

    const CollapseProjectsByDefault = () : boolean => {
        return props.projects.map(p => {
            return p.tasks.length;
        }).reduce((pV, cV) => {
            return pV + cV;
        }) > 12; // If more than n tasks is to be shown, collapse all projects in overview.
    };

    return (
        <Stack>
            {props.projects.map(project => (
                <ProjectRow 
                    key={project.projectId} 
                    collapseProjectsByDefault={CollapseProjectsByDefault()} 
                    {...project} 
                />
            ))}
        </Stack>
    );
};

