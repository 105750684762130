import { ApiCalls } from '../../../api/api';
import { TimeEntry } from '../../../api/generated/data-contracts';
import User from '../../../Model/User';
import { validationRules } from '../../validationRules';
import { ValidateResponse } from '../../Validator';
import { FlowValidationConfigValues } from './FlowValidationConfg';

interface FlowValdationProps {
    userPeriodId: string;
    user: User;
    [key: string]: any;
}

export const FlowValidation = async (props: FlowValdationProps): Promise<ValidateResponse> => {
    const flowUrl = props[FlowValidationConfigValues.FlowUrl];

    if (!flowUrl?.startsWith('http')) {
        console.error(`FLOW_URL IS NOT CONFIGURED CORRECTLY (${flowUrl})`);
        const response: ValidateResponse = {
            reason: '',
            valid: true,
            name: validationRules.Flow,
            rejected: false,
        };

        return response;
    } else {
        const response = await ApiCalls.validationPowerautomateCreate({ userPeriodId: props.userPeriodId });

        const result: ValidateResponse = {
            reason: response.data.reason,
            valid: response.data.valid,
            name: validationRules.Flow,
            rejected: response.data.rejected !== undefined ? response.data.rejected : !response.data.valid,
        };

        return result;
    }
};
