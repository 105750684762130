import React from "react";
import { useState } from "react";
import { makeStyles, useTheme, IconButton, Stack, Persona, PersonaSize } from "@fluentui/react";
// import { makeStyles, useTheme } from "@fluentui/react-theme-provider";
import { TaskRow } from "./TaskRow";
import TimePeriodRowM from "../../../../../Model/TimePeriodRowM";
import { mobileConstants } from "../../../../mobileConstants";

interface IProps {
    projectId: string;
    projectName: string;
    tasks: TimePeriodRowM[];
    collapseProjectsByDefault: boolean;
}

export const ProjectRow: React.FC<IProps> = (props) => {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(!props.collapseProjectsByDefault);
    
    const classes = getStyles();

    return (
        <>
            <Stack
                className={classes.root} 
                horizontal 
                verticalAlign="center"
                tokens={{ childrenGap: theme.spacing.s2 }}
                onClick={() => setIsOpen(s => !s)} 
            >
                <IconButton 
                    iconProps={{ iconName: "ChevronRight" }}
                    styles={{ root: classes.icon }}
                    className={isOpen ? classes.iconOpen : ""}
                />
                <Persona
                    text={props.projectName}
                    size={PersonaSize.size32}
                    styles={{ 
                        root: { ".ms-Persona-initials": { borderRadius: 4 }},
                        details: { ".ms-TooltipHost": { fontWeight: 600 }},
                    }}
                />
            </Stack>

            {isOpen && props.tasks.map(task => (
                <TaskRow
                    key={`${task.taskId}_${task.workType?.id}`}
                    {...task}
                    keyId={task.key}
                />
            ))}
        </>
    );
};

const getStyles = makeStyles(theme => ({
    root: ["projectRow", {
        height: mobileConstants.gridRowHeight,
        position: "sticky",
        top: 0,
        backgroundColor: theme.semanticColors.bodyFrameBackground,
        zIndex: 40,
        paddingLeft: 4,
        paddingRight: 16,
    }],
    container: {
        overflowX: "hidden",
    },
    icon: {        
        width: 18,
        "i": {
            fontSize: 12,
            color: theme.semanticColors.disabledBodySubtext,
            transform: "rotate(0deg)",
            transition: '100ms',
        },
    },
    iconOpen: {
        "i": {
            transform: "rotate(90deg)",
        },
    },
}));