import { IntegrationEventType } from '../../api/generated/data-contracts';

export const getEventTextFromEnum = (eventType: IntegrationEventType) => {
    switch (eventType) {
        case 'Project':
            return 'Initiative';
        case 'Actuals':
            return 'Actuals';
        default:
            return 'Unknown event';
    }
};
