import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line'
import { useUiContext } from '../components/Contexts/UiContext';
import { CollectionsOutlined } from '@material-ui/icons';
import { MathToFixed } from '../components/Utils';

interface propsUserProjectsPerWeekLineChart {
    LineChartData: any[];
    ProjectSelected: string;
}

function UserProjectsPerWeekLineChart(props: propsUserProjectsPerWeekLineChart) {
    const [lineChartData, setLineChartData] = useState<any[]>([]);

    const uiCtx = useUiContext();

    useEffect(() => {
        if (props.LineChartData != null && props.LineChartData != undefined) 
        {
            console.debug("Raw Line Chart Data", props.LineChartData, props.ProjectSelected);
            
            var chartData = [{data:[], id:"Planned"}, {data:[], id:"Actuals"}];
            let rawdata = [...props.LineChartData];
            rawdata?.forEach(perioddata => {
                let newprojactualsdata = {};
                let newprojplanneddata = {};
                newprojactualsdata['x'] = "" + perioddata.periodname;
                newprojplanneddata['x'] = "" + perioddata.periodname;
                newprojactualsdata['sort'] =  (perioddata.year*100)+perioddata.weekno;
                newprojplanneddata['sort'] = (perioddata.year*100)+perioddata.weekno;
                let actualhours = 0;
                let plannedhours = 0;
                perioddata?.chartDataProjects?.forEach(projectdata => 
                {
                    
                    
                    if (props.ProjectSelected == null || props.ProjectSelected == projectdata.projectname)
                    {
                        
                        projectdata?.chartDataTasks?.forEach(taskdata => 
                        {    
                            taskdata?.chartDataTimes?.forEach(timedata => 
                            {
                                actualhours = actualhours + timedata.actual;
                                plannedhours = plannedhours + timedata.planned;
                            });
                        });
                        
                        
                    }
                    newprojactualsdata["y"] = MathToFixed(actualhours, 2);;
                    newprojplanneddata["y"] = MathToFixed(plannedhours, 2);;
                    
                });
                chartData[1].data.push(newprojactualsdata);
                chartData[0].data.push(newprojplanneddata);
            });
            console.debug("Line Chart Data", chartData[0].data.sort((a, b) => {return (a.sort<b.sort)?-1:1}));
            
            setLineChartData(chartData);
            
        }
    }, [props.LineChartData, props.ProjectSelected]);
    return (
        
        <ResponsiveLine
            data={lineChartData}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 0, max: 'auto', stacked: false, reverse: false }}
            
            colors={["hsl(202, 26%, 73%)", "hsl(169, 58%, 64%)"]}
            theme={uiCtx.isDarkmode ? {
                textColor: '#ffffff',
                tooltip: {basic: {color: '#000000'}}
            } : {}}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Period',
                legendOffset: 36,
                legendPosition: 'middle'
            }}
            axisLeft={{
                
                tickPadding: 5,
                tickRotation: 0,
                
                legend: 'Hours',
                legendOffset: -40,
                legendPosition: 'middle'
            }}
            tooltip={point => {
                
                return  <div className="linecharttooltip">
                            
                                <div>
                                    <span className="boxlinecharttooltip" style={{background:point.point.serieColor}}></span>
                                    <span style={uiCtx.isDarkmode ? {color: '#000000'} : {}}>Period: <strong>{point.point.data.xFormatted}</strong>, {point.point.serieId}: <strong>{point.point.data.yFormatted}</strong></span>
                                </div>
                            
                        </div>;
              }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            animate={true}
            isInteractive={true}
            
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    );
}

export default UserProjectsPerWeekLineChart;
