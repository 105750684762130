import React from 'react';
import { useRef } from 'react';
import { TimeState } from '../../../../Model/State';
import { GetValidatorParameters } from '../../../../Validation/helpers';
import { useUiContext } from '../../../../components/Contexts/UiContext';
import { useMobileContext } from '../../../MobileContext';
import { useMobile } from '../../../useMobile';
import { Validator } from '../../../../Validation/Validator';
import { TimeSubmitIconMobile } from '../../../TimeSubmitIconMobile';
import { TimeOpenIconMobile } from '../../../TimeOpenIconMobile';
import { makeStyles } from '@fluentui/react';
import { ApiCalls } from '../../../../api/api';
import { UserReportingPeriodState } from '../../../../api/generated/data-contracts';

export const TimeStateButton: React.FC = () => {
    const uiCtx = useUiContext();
    const mobCtx = useMobileContext();
    const classes = getStyles();

    const validator = useRef(new Validator()).current;

    // const [showValidationWarningDialog, setShowValidationWarningDialog] = useState(false);

    const setTimeStateChange = async (newTimeState: UserReportingPeriodState): Promise<void> => {
        let nextState = newTimeState;
        if (newTimeState === 'Submitted') {
            const myTimeProjects = [];
            useMobile.getState().allTasks.forEach(task => myTimeProjects.push(task));

            const allEntries = myTimeProjects.flatMap(_ => _.timeEntries);
            const validatorParams = GetValidatorParameters(allEntries, uiCtx, myTimeProjects, uiCtx.selectedTimePeriod.reportPeriodId);
            const validationResponses = await validator.validate(validatorParams);
            const valErrors = validationResponses.filter(_ => !_.valid);
            const valRejected = validationResponses.filter(_ => _.rejected);

            mobCtx.setValidationErrors(valErrors);

            if (valRejected.length > 0) {
                mobCtx.setTimeState('Rejected' as TimeState);
                return;
            }

            // if (valErrors.length > 0) {
            //     setShowValidationWarningDialog(true);
            //     return;
            // };

            if (!uiCtx.user.user.approver || uiCtx.user.user.autoApprove) {
                nextState = 'Approved';
                setTimeout(() => {
                    mobCtx.setTimeState('Approved' as TimeState);
                }, 1000);
            }
        }

        if (nextState !== 'Approved') {
            mobCtx.setTimeState(newTimeState as TimeState);
        }
        ApiCalls.setReportingPeriodState({
            userReportingPeriodId: mobCtx.selectedTimePeriod.reportPeriodId,
            state: newTimeState,
            nextState: nextState,
            submitComment: mobCtx.periodComment,
        });
    };

    return (
        <div
            className={classes.root}
            onClick={() => setTimeStateChange(mobCtx.timeState === TimeState.open || mobCtx.timeState === TimeState.rejected ? 'Submitted' : 'Open')}
        >
            {mobCtx.timeState === undefined ? null : mobCtx.timeState === TimeState.open || mobCtx.timeState === TimeState.rejected ? (
                <TimeSubmitIconMobile />
            ) : (
                <TimeOpenIconMobile />
            )}
        </div>
    );
    // return (
    //     <IconButton
    //         iconProps={{ iconName: 'CheckMark' }}
    //         onClick={() => {
    //             if (mobCtx.timeState === TimeState.open || mobCtx.timeState === TimeState.rejected) {
    //                 timeStateChanged(TimeState.submitted);
    //             } else {
    //                 timeStateChanged(TimeState.open);
    //             };
    //         }}
    //         styles={{
    //             root: {
    //                 "i": {
    //                     color: uiCtx.isTeams ? "white" : theme.palette.black,
    //                     fontSize: 18,
    //                     // fontWeight: uiCtx.isTeams ? 600 : "",
    //                 },
    //             }
    //         }}
    //     />
    // );
};
const getStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 32,
        height: 32,
        cursor: 'pointer',
    },
}));
