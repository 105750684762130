import { IUiContext } from '../components/Contexts/UiContext';
import { MathToFixed } from '../components/Utils';
import { convertProcentToHours, getCapacityPrDay } from '../components/WeekOverview/Cell.helpers';
import { convertHoursToProcent } from '../components/WeekOverview/Cell.helpers';
import { parseNumber } from '../components/WeekOverview/Cell.helpers';
import { IPeriodDay } from './mobileTypes';

export const addValueToTotalValue1 = (valueToAdd: number, totalValue: number) => {
    // this is needed to fix numbers like: 2.9000000000000004
    const valueDiff = valueToAdd + totalValue;
    const fixedValue = parseFloat(valueDiff.toFixed(3));
    const value = Math.round(fixedValue * 10);
    return value / 10;
};

export const getCleanValueDiff1 = (newValue: number, oldValue: number) => {
    // this is needed to fix numbers like: 2.9000000000000004
    const valueDiff = newValue - oldValue;
    const fixedValue = parseFloat(valueDiff.toFixed(3));
    const value = Math.round(fixedValue * 10);
    return value / 10;
};

export const getCleanValueDiff = (newValue: number, oldValue: number) => {
    return newValue - oldValue;
};

export const addValueToTotalValue = (valueToAdd: number, totalValue: number) => {
    return totalValue + valueToAdd;
};

export const round2Number = (number: number): number => {
    if (isNaN(number)) {
        return 0;
    }
    return number;
};

export const formatNumberToDisplay = (number: number | string, uiCtx: IUiContext): number => {
    if (Number.isNaN(Number(number))) {
        return 0;
    }
    return roundToNearestValueStep(Number(number), uiCtx.timeLowerLimitConfig);
};

export const formatNumberToDisplayPercent = (number: number | string, selectedPeriodDays: IPeriodDay[], uiCtx: IUiContext): number => {
    const percent = convertHoursToProcent(Number(number), selectedPeriodDays.length, getCapacityPrDay(selectedPeriodDays, uiCtx));
    return roundToNearestValueStep(percent, uiCtx.timeLowerLimitConfig);
};

export const formatPercentToDisplayNumber = (number: number | string, selectedPeriodDays: IPeriodDay[], uiCtx: IUiContext): number => {
    const newValue = convertProcentToHours(Number(number), selectedPeriodDays.length, getCapacityPrDay(selectedPeriodDays, uiCtx));
    return MathToFixed(parseNumber(`${newValue}`), 1);
};

export const roundToNearestValueStep = (value: number, step: number): number => {
    // console.log("roundToNearestValueStep", value)
    // If step is 0, return the nearest integer to the input value
    if (step === 1) {
        return Math.round(value);
    }
    if (step === 0) {
        return MathToFixed(parseNumber(`${value}`), 2);
    }
    return round(value, step);
};

const round = (value: number, step: number) => {
    const [whole, decimal] = value.toFixed(2).split('.').map(Number);
    const steps = getStepRange(step);
    const closest = steps.reduce((prev, curr) => {
        return Math.abs(curr - decimal) <= Math.abs(prev - decimal) ? curr : prev;
    });
    if (closest === 100) {
        return whole + 1;
    }
    return Number(`${whole}.${closest}`);
};

const getStepRange = (step: number) => {
    let currentStep = step;
    const range = [0];
    let index = 1;
    while (currentStep < 1) {
        currentStep = step * index;
        if (currentStep < 1) {
            range.push(currentStep);
        }
        index++;
    }
    range.push(1);
    return range.map(n => Math.floor(n * 100));
};
