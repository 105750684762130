import produce from 'immer';
import { TPartialStore, IUseStore } from '../useStore.types';
import { TimeUser } from '../../api/generated/data-contracts';

export type TResourceStore = {
    searchText: string;
    setSearchText: (searchText: string) => void;
    currentUsers: TimeUser[];
    setCurrentUsers: (currentUsers: TimeUser[]) => void;
    originalUsers: TimeUser[];
    setOriginalUsers: (searoriginalUserschText: TimeUser[]) => void;
};

export const resourceStore: TPartialStore<TResourceStore> = (set, get, api) => {
    return {
        searchText: '',
        setSearchText: (searchText: string) =>
            set(
                produce((state: IUseStore) => {
                    state.searchText = searchText;
                }),
            ),
        currentUsers: [],
        setCurrentUsers: (currentUsers: TimeUser[]) =>
            set(
                produce((state: IUseStore) => {
                    state.currentUsers = currentUsers;
                }),
            ),
        originalUsers: [],
        setOriginalUsers: (originalUsers: TimeUser[]) =>
            set(
                produce((state: IUseStore) => {
                    state.originalUsers = originalUsers;
                }),
            ),
    };
};
