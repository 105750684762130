import { Icon, Stack, Breadcrumb, Text, SearchBox, Callout, DirectionalHint, ScrollablePane, useTheme } from '@fluentui/react';
import { useCallback, useMemo, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useNavigate } from 'react-router';
import { Category } from './components/Dashboard';
import { useSetInitialSettings } from '../_contexts/settings/settingsContext';
import { useCurrentCategory } from './hooks/useCurrentCategory';
import { SaveSettingsMessageBar } from './components/SaveSettingsMessageBar';
import { configurationConfig } from './config/config';
import { flattenCategories } from './helpers/flattenCategories';
import { Headliner } from './components/Headliner';
import '../Styles/SettingsSearch.scss';
import { useQuery } from '@tanstack/react-query';
import { CONFIGURATION_FULL } from '../constants/queryKeys';
import { ApiCalls } from '../api/api';

const Configuration = () => {
    const setInitialSettings = useSetInitialSettings();

    const { isLoading } = useQuery([CONFIGURATION_FULL], async () => ApiCalls.configurationFullList().then(res => res.data), {
        onSuccess: settings => {
            setInitialSettings(settings);
        },
    });

    const goTo = useNavigate();
    const { breadCrumbs, category } = useCurrentCategory({ config: configurationConfig });
    const flatConfigRoutes = useMemo(() => flattenCategories(configurationConfig), []);
    const [showSearchCallout, setShowSearchCallout] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const onSearchChange = useCallback((notUsedEvent: never, newValue: string | undefined) => {
        setSearchInput(newValue?.trim() || '');
    }, []);
    const dismissSearchCallout = useCallback(() => setShowSearchCallout(false), []);
    const openSearchCallout = useCallback(() => setShowSearchCallout(true), []);
    const searchResults = useMemo(() => {
        const searchVal = searchInput.toLowerCase();
        if (!searchVal) {
            return null;
        }
        return (
            flatConfigRoutes
                // search on shortDesription and title (maybe route also???)
                .filter(({ shortDescription, title }) => shortDescription.toLowerCase().includes(searchVal) || title.toLowerCase().includes(searchVal))
                // inject an onClick handler on results
                .map(meta => {
                    return {
                        ...meta,
                        onClick: () => {
                            goTo(meta.route);
                            dismissSearchCallout();
                        },
                    };
                })
        );
    }, [dismissSearchCallout, flatConfigRoutes, goTo, searchInput]);

    const theme = useTheme();

    return (
        <>
            <Stack
                styles={{
                    root: {
                        width: '100%',
                        height: '100%',
                        backgroundColor: theme.semanticColors.bodyBackgroundHovered,
                        paddingTop: '1rem',
                        position: 'relative',
                    },
                }}
            >
                <SaveSettingsMessageBar />
                <Headliner category={category} />

                <Stack
                    styles={{
                        root: {
                            paddingTop: 10,
                            paddingLeft: 10,
                        },
                    }}
                >
                    <Stack
                        horizontal
                        verticalAlign="center"
                        styles={{
                            root: {
                                '& > div:nth-last-child(1)': {
                                    width: '100%',
                                },
                                '& > div:nth-last-child(1) > div > div': {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                },
                                '.ms-Breadcrumb': {
                                    margin: 0,
                                },
                            },
                        }}
                    >
                        <div>
                            <Icon iconName={category.iconName} styles={{ root: { fontSize: '2rem' } }} />
                        </div>
                        <Breadcrumb items={breadCrumbs} />
                    </Stack>
                    <SearchBox onChange={onSearchChange} autoComplete="off" placeholder={`Search..`} onFocus={openSearchCallout} />
                </Stack>
                <Stack styles={{ root: { position: 'relative', flexGrow: 1 } }}>
                    <ScrollablePane>
                        <Category category={category} loading={isLoading} />
                    </ScrollablePane>
                </Stack>
            </Stack>
            {showSearchCallout && (
                <Callout
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    isBeakVisible={false}
                    className={'search-callout-container'}
                    target=".ms-SearchBox"
                    onDismiss={dismissSearchCallout}
                >
                    {/* <Text className={'search-result-header'}>{language.Common.SearchResults}</Text> */}
                    <Text className={'search-result-header'}>SEARCH RESULTS</Text>
                    <div className={'search-result-container'}>
                        {searchResults?.length > 0 ? (
                            searchResults.map(({ route, iconName, title, breadCrumb, onClick }) => {
                                return (
                                    <div className={'search-result-item2'} key={route} onClick={onClick}>
                                        <div className={'search-result-parent2'}>
                                            <Icon iconName={iconName} />
                                            &nbsp; &nbsp;
                                            <Highlighter searchWords={[searchInput]} highlightStyle={highlightStyle} textToHighlight={title} />
                                        </div>
                                        <div className={'search-result-item-content2'}>
                                            <Text>
                                                <Highlighter searchWords={[searchInput]} highlightStyle={highlightStyle} textToHighlight={breadCrumb} />
                                            </Text>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className={'search-no-result'}>No results</div>
                        )}
                    </div>
                </Callout>
            )}
        </>
    );
};

const highlightStyle = { background: 'yellow', padding: 0 };

export default Configuration;
