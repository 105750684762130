import { mobileConstants } from "./mobileConstants";

export const valueStyles = {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    // fontSize: 17,
    // fontWeight: "600",
    width: 50,
    height: "100%",
    // textAlign: "right",
    // fontSize: 25,
    // color: "#6264a7",
};

export const topBar = {
    width: 100,
    textAlign: "center",
};

export const sharedRowStyling = {
    height: mobileConstants.gridRowHeight,
};