import React, { useEffect, useState } from 'react';
import Delegates from '../Admin/Delegates/Delegates';
import User from '../Model/User';
import { useUiContext } from '../components/Contexts/UiContext';
import { IPersonaProps, PersonaPresence, PersonaSize } from '@fluentui/react';
import { SpinLoaderSlow } from '../components/SpinLoader';
import { ApiCalls } from '../api/api';
import { GetMinimalUsersWithDelegatesDto } from '../api/generated/data-contracts';

function AppAdminDelegatesConfig() {
    const uiCtx = useUiContext();

    const [allUsers, setAllUsers] = useState<GetMinimalUsersWithDelegatesDto[]>([]);
    const [allUsersPersonas, setAllUsersPersonas] = useState<IPersonaProps[]>([]);
    const [loadingcomplete, setLoadingcomplete] = useState<boolean>(false);

    useEffect(() => {
        ApiCalls.getUsersWithDelegates().then(delegates => {
            setAllUsers(delegates.data);
            setAllUsersPersonas(
                delegates.data.map((userPicker, index: any) => {
                    return {
                        //imageUrl: 'https://outlook.office.com/owa/service.svc/s/GetPersonaPhoto?email=' + userPicker.email + '&UA=0&size=HR48x48',
                        imageInitials: userPicker.name?.substring(0, 1) + (userPicker.surname ? userPicker.surname.substring(0, 1) : ''),
                        text: userPicker.name,
                        secondaryText: userPicker.userNumber ?? userPicker.jobTitle ?? '',
                        showSecondaryText: true,
                        size: PersonaSize.size48,
                        presence: PersonaPresence.none,
                        imageAlt: userPicker.name + ', ' + userPicker.jobTitle,
                        itemID: userPicker.id,
                    };
                }),
            );
            setLoadingcomplete(true);

            uiCtx.setLoading(false);
        });
    }, []);

    const onDelegateChanged = async (approverAsArray: IPersonaProps[], concernedUser: GetMinimalUsersWithDelegatesDto) => {
        let ids = approverAsArray.map(person => {
            return person.itemID;
        });

        if (approverAsArray.length >= 1) {
            ApiCalls.setDelegates({ delegateIds: ids, userId: concernedUser.id });
        } else {
            ApiCalls.setDelegates({ delegateIds: [], userId: concernedUser.id });
        }
        uiCtx.setLatestModifiedDelegateUserIds(prev => [concernedUser.id, ...prev.filter(_ => _ != concernedUser.id).slice(0, 8)]);

        let filteredDelegateUsers = allUsers.filter(_ => ids.some(id => id == _.id)); // Get users to add as delegates on concerened user
        let updatedConcernedUser = concernedUser;
        updatedConcernedUser.delegates = filteredDelegateUsers;
        let usersSelfRemoved = allUsers.filter(_ => _.id != concernedUser.id); // Remove concerned user
        usersSelfRemoved.unshift(updatedConcernedUser);
        setAllUsers(usersSelfRemoved);
    };

    if (!loadingcomplete) {
        return <SpinLoaderSlow />;
    }

    return <>{allUsers.length > 0 && allUsersPersonas.length > 0 && <Delegates allUsers={allUsers} allUsersPersonas={allUsersPersonas} onDelegateChanged={onDelegateChanged} />}</>;
}

export default AppAdminDelegatesConfig;
