import React, { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Icon } from '@fluentui/react/lib/Icon';
import { Separator } from '@fluentui/react/lib/Separator';
import { DetailsList, IDragDropEvents, IDragDropContext, Selection, SelectionMode, IColumn } from '@fluentui/react/lib/DetailsList';
import { getTheme, mergeStyles } from '@fluentui/react/lib/Styling';
import Task from '../../Model/Task';
import AdminProjectM from '../../Model/AdminProjectM';
import { TextField } from '@fluentui/react/lib/TextField';
import { IconButton, TooltipHost } from '@fluentui/react';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { createNewGuid } from '../../components/Utils';

const theme = getTheme();
const dragEnterClass = mergeStyles({
    backgroundColor: theme.palette.neutralLight,
});

interface propsAdminProject {
    Tasks: Task[];
    Project: AdminProjectM;
    ReorderProject: Function;
    ProjectUpdated: Function;
    TaskUpdated: Function;
}
function AdminProject(props: propsAdminProject) {
    const [selectedTasks, setSelectedTasks] = useState<Selection>(new Selection());

    const [draggedItem, setDraggedItem] = useState<any | undefined>(undefined);
    const [draggedIndex, setDraggedIndex] = useState<any | undefined>(undefined);

    const [tasks, setTasks] = useState<Task[]>([]);
    const [columns, setColumns] = useState<any>(undefined);

    const [project, setProject] = useState<AdminProjectM>(null);
    const [projectName, setProjectName] = useState<string>(props.Project.name);
    const [projectOrder, setProjectOrder] = useState<number>(props.Project.order);
    const [projectClosed, setProjectClosed] = useState<boolean>(props.Project.closed);

    const [headerInFocus, setHeaderInFocus] = useState<boolean>(false);
    const headertxtfield = React.useRef(null);

    useEffect(() => {
        const newtasklist = props.Tasks.sort((a, b) => {
            return a.order < b.order ? -1 : 1;
        });

        setTasks(newtasklist);
        const newColumns = [
            { key: 'order', name: '', fieldName: 'order', minWidth: 20, maxWidth: 20, isResizable: true },
            { key: 'name', name: 'Task', fieldName: 'name', minWidth: 480, maxWidth: 600, isResizable: true },
            { key: 'closed', name: 'Active', fieldName: 'closed', minWidth: 100, maxWidth: 200, isResizable: true },
            //{ key: 'delete', name: '', fieldName: 'delete', minWidth: 20, maxWidth: 20, isResizable: true },
        ];
        setColumns(newColumns);
    }, [props.Tasks]);

    useEffect(() => {
        setProject(props.Project);
        setProjectName(props.Project.name);
        setProjectOrder(props.Project.order);
        setProjectClosed(props.Project.closed);
    }, [props.Project]);

    const getDragDropEvents = (): IDragDropEvents => {
        return {
            canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) => {
                return true;
            },
            canDrag: (item?: any) => {
                return true;
            },
            onDragEnter: (item?: any, event?: DragEvent) => {
                // return string is the css classes that will be added to the entering element.
                return dragEnterClass;
            },
            onDragLeave: (item?: any, event?: DragEvent) => {
                return;
            },
            onDrop: (item?: any, event?: DragEvent) => {
                if (draggedItem) {
                    insertBeforeItem(item);
                }
            },
            onDragStart: (item?: any, itemIndex?: number, selectedItems?: any[], event?: MouseEvent) => {
                setDraggedItem(item);
                setDraggedIndex(itemIndex!);
            },
            onDragEnd: (item?: any, event?: DragEvent) => {
                setDraggedItem(undefined);
                setDraggedIndex(-1);
            },
        };
    };
    const insertBeforeItem = (item: Task): void => {
        const draggedItems = selectedTasks.isIndexSelected(draggedIndex) ? (selectedTasks.getSelection() as any[]) : [draggedItem!];

        const insertIndex = tasks.indexOf(item);

        const items = tasks.filter(itm => draggedItems.indexOf(itm) === -1);

        items.splice(insertIndex, 0, ...draggedItems);
        items.forEach((t, index) => {
            t.order = index + 1;
        });
        const newtask = items.filter(t => t.id == item.id)[0];
        props.TaskUpdated(newtask, project);
        setTasks(items);
    };
    const moveUpClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement, MouseEvent>) => {
        props.ReorderProject(project, -1);
    };
    const moveDownClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement, MouseEvent>) => {
        props.ReorderProject(project, 1);
    };
    const updateProjectName = (event, value) => {
        setProjectName(value);
        props.ProjectUpdated(project.id, value, projectClosed);
    };
    const updateProjectClosed = (event, value) => {
        setProjectClosed(!value);
        props.ProjectUpdated(project.id, projectName, !value);
    };
    const renderItemColumn = (item: any, index: number, column: IColumn) => {
        switch (column.key) {
            case 'name':
                const fieldContString = item[column.fieldName as keyof any] as string;
                return (
                    <TextField
                        borderless
                        value={fieldContString}
                        onChange={(e, v) => {
                            taskNameUpdated(item, v);
                        }}
                    />
                );
            case 'closed':
                const fieldContBoolean = item[column.fieldName as keyof any] as boolean;
                return (
                    <Toggle
                        checked={!fieldContBoolean}
                        onChange={(e, v) => {
                            taskClosedUpdated(item, v);
                        }}
                        onText="Active"
                        offText="Inactive"
                        role="checkbox"
                    />
                );
            case 'order':
                const fieldContOrderString = item[column.fieldName as keyof any] as string;
                return (
                    <TooltipHost content={'This task is placed in order ' + fieldContOrderString} closeDelay={500}>
                        <span style={{ cursor: 'pointer', marginTop: 20 }}>
                            <Icon iconName="Sort" />
                        </span>
                    </TooltipHost>
                );
            case 'delete':
                return <IconButton iconProps={{ iconName: 'Delete' }}></IconButton>;
            default:
                const fieldContDefaultString = item[column.fieldName as keyof any] as string;
                return <span>{fieldContDefaultString}</span>;
        }
    };
    const taskNameUpdated = (item, newvalue) => {
        console.log(item);
        const newtask = tasks.filter(t => t.id == item.id)[0];
        newtask.name = newvalue;

        setTasks([...tasks]);
        props.TaskUpdated(newtask, project);
    };
    const taskClosedUpdated = (item, newvalue) => {
        const newtask = tasks.filter(t => t.id == item.id)[0];
        newtask.closed = !newvalue;

        setTasks([...tasks]);
        props.TaskUpdated(newtask, project);
    };
    const addTaskClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement, MouseEvent>) => {
        const emptyTask: any = { id: createNewGuid(), name: '', order: tasks.length + 1, closed: false, daysSinceLastUsed: 0, isDefault: true };
        tasks.push(emptyTask);
        setTasks([...tasks]);
    };
    useEffect(() => {
        if (headerInFocus == true) {
            headertxtfield.current.focus();
        }
    }, [headerInFocus]);
    return (
        <div style={{ width: 660 }}>
            <Stack tokens={{ childrenGap: 20 }} horizontal verticalAlign="baseline">
                <Stack.Item styles={{ root: { alignItems: 'center', display: 'flex', justifyContent: 'flex-start' } }}>
                    <Toggle checked={!projectClosed} onChange={updateProjectClosed} role="checkbox" />
                </Stack.Item>
                <Stack.Item grow={1}>
                    {headerInFocus ? (
                        <TextField
                            componentRef={headertxtfield}
                            onBlur={() => {
                                setHeaderInFocus(false);
                            }}
                            value={projectName}
                            onChange={updateProjectName}
                        />
                    ) : (
                        <Stack horizontal tokens={{ childrenGap: 0 }} verticalAlign="baseline">
                            <h4
                                onClick={() => {
                                    setHeaderInFocus(true);
                                }}
                                style={{ cursor: 'pointer', textDecoration: projectClosed ? 'line-through' : 'none' }}
                            >
                                {projectName}
                            </h4>
                        </Stack>
                    )}
                </Stack.Item>
                <Stack.Item grow={5}>
                    <Separator></Separator>
                </Stack.Item>
                <Stack.Item grow={1} styles={{ root: { alignItems: 'center', display: 'flex', justifyContent: 'flex-end' } }}>
                    <Stack tokens={{ childrenGap: 0 }} horizontal>
                        <IconButton
                            iconProps={{ iconName: 'SkypeCircleArrow' }}
                            checked={false}
                            // TODO: fix color
                            style={{ fontSize: 32, color: 'rgb(50, 49, 48)', transform: 'rotate(-90deg)' }}
                            onClick={moveDownClick}
                        />
                        <IconButton
                            iconProps={{ iconName: 'SkypeCircleArrow' }}
                            checked={false}
                            style={{ fontSize: 32, color: 'rgb(50, 49, 48)', transform: 'rotate(90deg)' }}
                            onClick={moveUpClick}
                        />
                    </Stack>
                </Stack.Item>
            </Stack>

            {!projectClosed ? renderAdminTasksConfig() : <></>}
        </div>
    );

    function renderAdminTasksConfig(): JSX.Element {
        return (
            <div style={{ display: 'inline-block' }}>
                <Stack tokens={{ childrenGap: 0 }}>
                    <DetailsList
                        setKey="items"
                        items={tasks}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        compact={true}
                        onRenderItemColumn={renderItemColumn}
                        dragDropEvents={getDragDropEvents()}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="Row checkbox"
                    />
                    <Stack>
                        <Stack.Item align="center">
                            <IconButton iconProps={{ iconName: 'CircleAdditionSolid' }} onClick={addTaskClick}></IconButton>
                        </Stack.Item>
                    </Stack>

                    <br />
                </Stack>
            </div>
        );
    }
}

export default AdminProject;
