import * as React from 'react';
import { useUiContext } from '../../../components/Contexts/UiContext';
import { makeStyles } from '@fluentui/react';

export const PageHeader: React.FC = ({children}) => {
    const uiCtx = useUiContext();
    const classes = getStyles();

    return (
        <div className={`${classes.root} ${uiCtx.isTeams ? classes.bgTeams : classes.bg}`}>
            { children }
        </div>
    );
};

const getStyles = makeStyles(theme => ({
    root: {
        margin: "0 auto 0",
        width: "100%",
        padding: `8px 12px`,
        // borderBottom: `1px solid ${theme.semanticColors.menuDivider}`,
        borderBottom: `1px solid ${theme.palette.neutralQuaternaryAlt}`,
    },
    bg: {
        backgroundColor: theme.semanticColors.bodyStandoutBackground,
    },
    bgTeams: {
        backgroundColor: theme.palette.themePrimary,
    },
}));
