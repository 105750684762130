import { makeStyles } from '@fluentui/react';
import { Height } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { constants } from '../Styles/constants';
import "../Styles/Loading.scss";

 const quotes: any[] = [{ quote: 'If we do not ever take time, how can we ever have time', source: 'Merovingian' },
  {quote:'Time is the most valuable thing a man can spend', source:'Theophrastus'},
  {quote:'Lost Time is never found again', source:'Benjamin Franklin'},
  {quote:'The key is in not spending time, but in investing it', source:'Stephen R. Covey'},
  {quote:'Time is the longest distance between two places', source:'Tennessee Williams'},
  {quote:'They always say time changes things, but you actually have to change them yourself', source:'Andy Warhol'},
  {quote:'Time is free, but it’s priceless. You can’t own it, but you can use it. You can’t keep it, but you can spend it', source:'Harvey Mackay'},
  {quote:'The trouble is, you think you have time', source:'Buddha'},
  {quote:'The two most powerful warriors are patience and time', source:'Leo Tolstoy'},
  {quote:'Either you run the day, or the day runs you', source:'Jim Rohn'},
  {quote:'Men talk of killing time, while time quietly kills them', source:'Dion Boucicault'}];

const useStyles = makeStyles(theme => ({root:{position:'fixed', left:0, top:52, width:'100%',  height:'100%',  backgroundColor:theme.semanticColors.bodyBackground, zIndex:998}}));


interface propsLoader {
    loadingtext:string
  }
 
export const QuotesLoader = (props:propsLoader) => {
    const [loadingquote, setLoadingquote]: any = useState({ quote: '', source: '' });
    const classes = useStyles();
    const setNewQuote = () => {
        setLoadingquote(quotes[Math.floor(Math.random() * ((quotes.length - 1) - 0 + 1)) + 0]);
    }

    useEffect(() => {
        setNewQuote();
        let rotationInterval = setInterval(() => {
            setNewQuote();
        }, 10000);

        return () => clearInterval(rotationInterval);
    }, []);

 
    return (
        <div className={classes.root}>
            <div className="loadingscreen">
                <svg width="20%" height="20%" viewBox="0 0 500 300" >
                    <rect width="82" height="82" rx="50" ry="50"    fill='transparent' />
                    <g className="ci1" transform="matrix(1,0,0,1,0,0)">
                        <circle cx="250" cy="150" r="105" fill='rgb(100,101,164)' />
                        <circle cx="250" cy="150" r="85" fill='white' />
                    </g>
                    <g className="ci2" transform="matrix(1,0,0,1,0,0)">
                        <circle cx="250" cy="150" r="60" fill='rgb(100,101,164)' />
                        <circle cx="250" cy="150" r="40" fill='white' />
                    </g>
                    <g className="ci4" transform="matrix(1,0,0,1,0,0)">
                        <circle cx="250" cy="150" r="20" fill='rgb(100,101,164)' />
                        <circle cx="250" cy="150" r="0" fill='white' />
                    </g>   
                    
                </svg>
            <div className="loadingdesctxt">{props.loadingtext}</div>    
        </div>
    </div>
    )
}