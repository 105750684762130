import React from 'react';
import { Stack, useTheme, Text, Icon } from '@fluentui/react';
import { styles } from './CardItem.styles';
import { useGetStyles } from '../../useGetStyles';

export const CardItemNoIntegrations = () => {
    const theme = useTheme();
    const classes = useGetStyles(styles);
    return (
        <Stack className={classes.root} styles={{ root: { width: 400 } }}>
            <Stack horizontal tokens={{ childrenGap: theme.spacing.m }} verticalAlign="center" styles={{ root: { paddingBottom: theme.spacing.m } }}>
                <Icon iconName="Add" />
                <Text className={classes.title}>No integrations</Text>
            </Stack>
            <div className={classes.divider} />
            <Stack tokens={{ childrenGap: theme.spacing.m }}>
                <Text>Click the "Add integration" button above to add your first integration</Text>
            </Stack>
        </Stack>
    );
};
