import React from 'react';

export const DataverseLogo = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg fill="none" version="1.1" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" {...props}>
            <defs>
                <filter id="filter0_f">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur" stdDeviation="0.4" />
                </filter>
                <filter id="filter1_f">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur result="effect1_foregroundBlur" stdDeviation="4" />
                </filter>
                <linearGradient id="paint0_linear" x1="35.198601" x2="43.518501" y1="10.2397" y2="85.759499" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#096738" offset="0" />
                    <stop stopColor="#0A4C2D" offset="1" />
                </linearGradient>
                <linearGradient id="paint1_linear" x1="69.438103" x2="85.118103" y1="9.2801304" y2="84.160103" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4ED062" offset="0" />
                    <stop stopColor="#209B4E" offset="1" />
                </linearGradient>
                <linearGradient id="paint2_linear" x1="69.1642" x2="61.2239" y1="86.648598" y2="86.648598" gradientUnits="userSpaceOnUse">
                    <stop offset="0" />
                    <stop stopOpacity=".560661" offset=".271932" />
                    <stop stopOpacity=".264518" offset=".620896" />
                    <stop stopOpacity="0" offset="1" />
                </linearGradient>
                <linearGradient id="paint3_linear" x1="64.829201" x2="52.6105" y1="64.889801" y2="27.012699" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#66EB6E" offset="0" />
                    <stop stopColor="#9FE0A2" offset="1" />
                </linearGradient>
                <clipPath id="clip0">
                    <rect width="95.999802" height="96" fill="#ffffff" />
                </clipPath>
                <clipPath id="clip1">
                    <rect width="95.999802" height="95.999802" fill="#ffffff" />
                </clipPath>
            </defs>
            <g transform="matrix(.21394214 0 0 .21394214 .23526584 .23027715)" clipPath="url(#clip0)">
                <g clipPath="url(#clip1)">
                    <mask id="mask0" x="-1" y="11" width="97" height="74" mask-type="alpha" maskUnits="userSpaceOnUse">
                        <path
                            d="m91.9541 21.5445-0.0031-1e-4c-1.2807-2.975-3.4966-5.5777-6.5173-7.3217-7.4525-4.30273-16.9819-1.7496-21.2852 5.7025l-0.0041-3e-4c-0.3857-0.3441-0.7826-0.6813-1.1902-1.0124-13.2049-10.7274-33.8516-10.77547-49.0774 2.9111-13.676869 12.2943-17.95564 33.9559-9.91616 52.7267l0.00498 3e-4c1.28658 2.9368 3.48789 5.5041 6.47758 7.2302 7.4502 4.3013 16.9758 1.7513 21.281-5.6952l0.0042 2e-4 0.0038-0.0067c0.3857 0.3442 0.7823 0.682 1.1898 1.0131 13.2049 10.7274 33.8516 10.7754 49.0774-2.9111 13.655-12.2746 17.942-33.8866 9.9547-52.6366z"
                            fill="#c4c4c4"
                        />
                    </mask>
                    <g mask="url(#mask0)">
                        <path
                            d="m39.5009 62.6252c13.0266 7.5209 24.7312 4.6196 31.35-6.8446 6.6189-11.4642 4.1304-27.0967-7.8963-36.8669-13.2049-10.72745-33.8516-10.77552-49.0774 2.911-13.676885 12.2943-17.95566 33.9559-9.91617 52.7267l0.00476 3e-4c1.28658 2.9368 3.48788 5.5041 6.47761 7.2301 7.4501 4.3014 16.9757 1.7513 21.2809-5.6951l0.0045 2e-4z"
                            clipRule="evenodd"
                            fill="url(#paint0_linear)"
                            fillRule="evenodd"
                        />
                        <path
                            d="m91.95 21.5443 0.0035 3e-4c7.9872 18.7499 3.7003 40.3619-9.9547 52.6365-15.2258 13.6866-35.8725 13.6385-49.0774 2.9111-12.0267-9.7702-14.5152-25.4027-7.8963-36.8669 6.6188-11.4642 18.3234-14.3656 31.35-6.8447l7.7686-13.4557 0.0043 3e-4c4.3034-7.4517 13.8325-10.00457 21.2849-5.7019 3.0205 1.7438 5.2363 4.3463 6.5171 7.321z"
                            clipRule="evenodd"
                            fill="url(#paint1_linear)"
                            fillRule="evenodd"
                        />
                        <path
                            d="m56.3789 33.3814c-13.0266-7.5209-24.7311-4.6195-31.35 6.8447-6.3947 11.0759-4.2885 26.0425 6.7071 35.8543l7.7679-13.4544c13.0266 7.5209 24.7311 4.6196 31.35-6.8446 6.3947-11.076 4.2885-26.0425-6.7071-35.8544z"
                            clipRule="evenodd"
                            fill="#088142"
                            fillRule="evenodd"
                        />
                        <rect
                            transform="rotate(30,61.2239,9.11133)"
                            x="61.2239"
                            y="9.11133"
                            width="7.94029"
                            height="97.446098"
                            fill="url(#paint2_linear)"
                            opacity=".25"
                        />
                        <g filter="url(#filter0_f)">
                            <path
                                d="m62.5645 56.8453c-4.6622 8.0752-14.9879 10.8419-23.0631 6.1797s-10.842-14.9879-6.1798-23.0631c4.6623-8.0751 14.9879-10.8419 23.0631-6.1797s10.842 14.9879 6.1798 23.0631z"
                                fill="#000000"
                                fillOpacity=".24"
                            />
                        </g>
                        <g filter="url(#filter1_f)">
                            <path
                                d="m62.5645 58.4453c-4.6622 8.0751-14.9879 10.8419-23.0631 6.1797s-10.842-14.9879-6.1798-23.0631c4.6623-8.0752 14.9879-10.8419 23.0631-6.1797s10.842 14.9879 6.1798 23.0631z"
                                fill="#000000"
                                fillOpacity=".32"
                            />
                        </g>
                        <path
                            d="m62.5645 56.4453c-4.6623 8.0751-14.9879 10.8419-23.0631 6.1797s-10.842-14.9879-6.1798-23.0631c4.6623-8.0752 14.9879-10.8419 23.0631-6.1797s10.842 14.9879 6.1798 23.0631z"
                            fill="url(#paint3_linear)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
