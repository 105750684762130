import Divider from "@material-ui/core/Divider";
import { Callout, ContextualMenu, DirectionalHint, IModalProps, Link } from "@fluentui/react";
import { DefaultButton, IconButton, PrimaryButton } from "@fluentui/react/lib/Button";
import Dialog, { DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { useId } from '@fluentui/react-hooks';
import { Stack } from "@fluentui/react/lib/Stack";
import { Text } from "@fluentui/react/lib/Text";
import React from "react";
import { useState } from "react";
import { TimeError } from "../Model/TimeError";
import HelpSupport from "../Help/HelpSupport";
import { TimeInformation } from "../Model/TimeInformation";

const modelProps: IModalProps = {
  isBlocking: false,
  topOffsetFixed: true,
  // dragOptions: {
  //   moveMenuItemText: 'Move',
  //   closeMenuItemText: 'Close',
  //   menu: ContextualMenu,
  //   keepInBounds: true,
  // }
};

const tokens = {
  sectionStack: {
    childrenGap: 10,
  },
  headingStack: {
    childrenGap: 12,
  },
  buttonStack: {
    childrenGap: 10
  },
  contentStack: {
    childrenGap: 5
  }
};

interface IProps {
  information: TimeInformation | null,
  show: boolean,
  closeDialog: Function
}

export const InformationDialog: React.FC<IProps> = (props) => {
  const [showTechnicalDetails, setShowTechnicalDetails] = useState(false);
  const [showHelpCallout, setShowHelpCallout] = useState<boolean>(false);
  const targetId = useId();

  function toggleDetails(ev: React.MouseEvent<HTMLButtonElement>): void {
    setShowTechnicalDetails(!showTechnicalDetails);
  }

  // const refreshPage = () => {
  //   window.location.reload();
  // }

  const closeDialog = () => {
    props.closeDialog();
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(`${props.information.header}\n${props.information.description}`)
  }

  return (
    <>
      {props.show &&
        <Dialog containerClassName={'errorDialog'} /*dialogContentProps={{title: "We are very sorry, but an error occured"}}*/ hidden={props.information?.description ? false : true} modalProps={modelProps}>
          <Stack tokens={tokens.sectionStack}>
            <Stack horizontalAlign={"center"} tokens={tokens.headingStack}>
              <Text variant={"xLargePlus"}>{props.information.header}</Text>
              <Text variant={"mediumPlus"}>{props.information.description}</Text>
            </Stack>
            <DialogFooter>
              <Stack verticalAlign={"center"} horizontalAlign={"center"}>
                <Stack horizontal tokens={{ childrenGap: 8 }}>
                  {/* <DefaultButton onClick={copyToClipboard} text="Copy details" /> */}
                  <PrimaryButton onClick={closeDialog} text="Ok" />

                </Stack>
              </Stack>
              <Stack>
                <Text>{new Date().toLocaleString()}</Text>
              </Stack>
            </DialogFooter>
          </Stack>
        </Dialog>
      }
    </>
  )
}