import { memo, useState, useEffect, useMemo, useRef } from 'react';

import Org from '../../Model/Org';
import UAParser from 'ua-parser-js';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { getParameterByName } from '../Utils';
import { TeamsTheme } from '../../Theme/TeamsTheme';
import { TeamsDarkTheme } from '../../Theme/TeamsDarkTheme';
import { DefaultTheme } from '../../Theme/DefaultTheme';
import { validationRules } from '../../Validation/validationRules';
import { TimeError } from '../../Model/TimeError';
import { timeApi } from '../../Workaround/TimeApiWorkaround';
import { TimeWarning } from '../../Model/TimeWarning';
import { Calendar } from '../../Model/NewModels/Calendar';
import { useNavigate, useLocation } from 'react-router';
import createHttpClient, { createHttpClientTeams } from '../../Workaround/HttpClient';
import { useMsal } from '@azure/msal-react';
import { useAuthContext } from '../../Authentication/AuthContext';
//import { TimeApiWorkaround } from ""
import { TimeConfigKeys } from '../../constants/TimeConfigKeys';
import { getBoolean } from '../../api/apiUtils';
import ReportPeriodRowM from '../../Model/ReportPeriodRowM';
import { WorkDay } from '../../Admin/TimeConfig/TimeConfigModules/WorkdaysSelector';
import { UserNotActive } from '../../_pages/UserNotActive';
import { App } from '../../App';
import { ApiCalls } from '../../api/api';
import { AggregatedUser, ProblemDetails, TenantNotFoundProblem, TimeUser } from '../../api/generated/data-contracts';
import { HttpResponse } from '../../api/generated/http-client';
import { Suspender } from '../../_components/Suspender';
import { createCtx } from './createCtx';

export interface IUiContext {
    org: Org;
    setOrg: React.Dispatch<React.SetStateAction<Org>>;
    user: AggregatedUser;
    setUser: React.Dispatch<React.SetStateAction<any>>;
    userAgent: any;
    inIframe: boolean;
    isTeams: boolean;
    setIsTeams: any;
    isDarkmode: boolean;
    splitBarPos: any;
    setSplitBarPos: any;
    plannedActive: any;
    setPlannedActive: any;
    actualActive: any;
    setActualActive: any;
    remainingActive: any;
    setRemainingActive: any;
    latestModifiedDelegateUserIds: any;
    setLatestModifiedDelegateUserIds: any;
    onlyAssignedSearch: boolean;
    setOnlyAssignedSearch: React.Dispatch<React.SetStateAction<boolean>>;
    theme: any;
    cellsWithError: any[];
    setCellsWithError: any;
    allowedWorkTypes: any[];
    projectWorkTypes: any[];
    timeLowerLimitConfig: number;
    enabledRules: any[];
    setEnabledRules: React.Dispatch<any>;
    allConfig: any[];
    timeError: TimeError | TimeWarning | null;
    setTimeError: React.Dispatch<React.SetStateAction<TimeError | TimeWarning | null>>;
    timeApi: ReturnType<typeof timeApi>;
    dateshortformat: string;
    dateformat: string;
    datetimeformat: string;
    gridInputType: string;
    setGridInputType: React.Dispatch<React.SetStateAction<string>>;
    showPlanned: boolean;
    setShowPlanned: React.Dispatch<React.SetStateAction<boolean>>;
    gridView: string;
    setGridView: React.Dispatch<React.SetStateAction<string>>;
    workDayBoundaries: boolean;
    setWorkDayBoundaries: React.Dispatch<React.SetStateAction<boolean>>;
    workDayMinutesInterval: number;
    setWorkDayMinutesInterval: React.Dispatch<React.SetStateAction<number>>;
    allowCellComment: boolean;
    setAllowCellComment: React.Dispatch<React.SetStateAction<boolean>>;
    allowTimeSheetComment: boolean;
    setAllowTimeSheetComment: React.Dispatch<React.SetStateAction<boolean>>;
    allowCellFlag: boolean;
    setAllowCellFlag: React.Dispatch<React.SetStateAction<boolean>>;
    timesheetRAGsEnabled: boolean;
    setTimesheetRAGsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
    nativeChartsEnabled: boolean;
    setNativeChartsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    selectedTimePeriod: ReportPeriodRowM;
    setSelectedTimePeriod: React.Dispatch<React.SetStateAction<ReportPeriodRowM>>;
    workdays: WorkDay[];
    setWorkdays: React.Dispatch<React.SetStateAction<WorkDay[]>>;
    enableTimeEntriesImport: boolean;
    setEnableTimeEntriesImport: React.Dispatch<React.SetStateAction<boolean>>;
    enableSubmitPeriodOnImport: boolean;
    setEnableSubmitPeriodOnImport: React.Dispatch<React.SetStateAction<boolean>>;
    workDayAMPM: boolean;
    setWorkDayAMPM: React.Dispatch<React.SetStateAction<boolean>>;
    inheritApproverRights: boolean;
    setInheritApproverRights: React.Dispatch<React.SetStateAction<boolean>>;
    autoCreateNewUsers: boolean;
    setAutoCreateNewUsers: React.Dispatch<React.SetStateAction<boolean>>;
    autoCreationDisabled: boolean;
    setAutoCreationDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    workTypeEnabled: boolean;
    setWorkTypeEnabled: React.Dispatch<React.SetStateAction<boolean>>;
    enableCoachmarks: boolean;
    setEnableCoachmarks: React.Dispatch<React.SetStateAction<boolean>>;
    allowTelemitry: boolean;
    setAllowTelemitry: React.Dispatch<React.SetStateAction<boolean>>;
    onlyallowtimesheetwithintaskstartend: boolean;
    setOnlyallowtimesheetwithintaskstartend: React.Dispatch<React.SetStateAction<boolean>>;
}

const [useUiCtx, UiCtxProvider, ctx] = createCtx<IUiContext>();

export const useUiContext = useUiCtx;

interface IProps {}

export const UiContextProvider: React.FC<IProps> = memo(props => {
    const mylocation = useLocation();
    // const myparams = useParams();
    // const myhistory = useHistory();
    const navigate = useNavigate();
    const [splitBarPos, setSplitBarPos] = useLocalStorage<number>('time_rowHeaderWidth', 400);
    const [plannedActive, setPlannedActive] = useLocalStorage<boolean>('time_plannedActive', false);
    const [actualActive, setActualActive] = useLocalStorage<boolean>('time_actualActive', false);
    const [remainingActive, setRemainingActive] = useLocalStorage<boolean>('time_remainingActive', false);
    const [latestModifiedDelegateUserIds, setLatestModifiedDelegateUserIds] = useLocalStorage<string[]>('time_latestModifiedDelegateUserIds', []);
    const [org, setOrg] = useState<Org>();
    const [user, setUser] = useState<AggregatedUser>();
    const [userAgent, setUserAgnet] = useState<any>(null);
    // const [userAgnet, setUserAgnet] = useState<any>("mobile");
    const [inIframe, setInIframe] = useState<any>();
    const [isTeams, setIsTeams] = useState<boolean>();
    const [isDarkmode, setIsDarkmode] = useState<boolean>();
    const [rejected, setRejected] = useState(false);
    const [timeError, setTimeError] = useState<null | TimeError | TimeWarning>(null);

    const [cellsWithError, setCellsWithError] = useState<any[]>([]);

    const [enableCoachmarks, setEnableCoachmarks] = useState<boolean>(false);

    const [allowedWorkTypes, setAllowedWorkTypes] = useState<any[] | null>(null);

    const [projectWorkTypes, setProjectWorkTypes] = useState<any[] | null>(null);

    const [calendar, setCalendar] = useState<Calendar>();

    const [timeLowerLimitConfig, setTimeLowerLimitConfig] = useState<number>(null);

    // Allow Comment on entries
    const [allowCellComment, setAllowCellComment] = useState<boolean>(false);

    // Allow Comment on submit
    const [allowTimeSheetComment, setAllowTimeSheetComment] = useState<boolean>(false);

    // Allow Flagging on entries
    const [allowCellFlag, setAllowCellFlag] = useState<boolean>(false);

    // 'percent' or 'hours'
    const [gridInputType, setGridInputType] = useState<string>('hours');

    // Show/hide planned/remaining/Actual work
    const [showPlanned, setShowPlanned] = useState<boolean>(true);

    // Can be 'days' or 'period' (default days)
    const [gridView, setGridView] = useState<string>('days');

    // autoCreateNewUsers
    const [autoCreateNewUsers, setAutoCreateNewUsers] = useState<boolean>(false);

    const [autoCreationDisabled, setAutoCreationDisabled] = useState<boolean>(false);
    const [workTypeEnabled, setWorkTypeEnabled] = useState<boolean>(false);
    const [allowTelemitry, setAllowTelemitry] = useState<boolean>(false);

    // Toggle start/end of WorkDay
    const [workDayBoundaries, setWorkDayBoundaries] = useState<boolean>(false);
    const [workDayAMPM, setWorkDayAMPM] = useState<boolean>(false);
    const [workDayMinutesInterval, setWorkDayMinutesInterval] = useState<number>(1);
    const [workdays, setWorkdays] = useState<WorkDay[]>([]);
    const [onlyallowtimesheetwithintaskstartend, setOnlyallowtimesheetwithintaskstartend] = useState<boolean>(true);

    const [timesheetRAGsEnabled, setTimesheetRAGsEnabled] = useState<boolean>(true);
    const [nativeChartsEnabled, setNativeChartsEnabled] = useState<boolean>(true);

    const [enableTimeEntriesImport, setEnableTimeEntriesImport] = useState<boolean>(false);
    const [enableSubmitPeriodOnImport, setEnableSubmitPeriodOnImport] = useState<boolean>(false);

    const [onlyAssignedSearch, setOnlyAssignedSearch] = useState<boolean>(true); // SET FALSE FOR DEFAULT SETTING - TRUE FOR NORDEA SETTING
    const [theme, setTheme] = useState<any>(null);
    const [shortdateformat, setshortdateformat] = useState<string>();
    const [dateformat, setdateformat] = useState<string>();
    const [datetimeformat, setdatetimeformat] = useState<string>();
    const [loading, setLoading] = useState<boolean>(true);
    const [firstloadcompleted, setFirstloadcompleted] = useState<boolean>(false);
    const [initialerror, setinitialerror] = useState<any>(null);
    const [httpapiready, sethttpapiready] = useState<boolean>(false);

    const [enabledRules, setEnabledRules] = useState<any>();

    const [allConfig, setAllConfig] = useState<any[]>();

    const [selectedTimePeriod, setSelectedTimePeriod] = useState<ReportPeriodRowM>();

    // const { instance, accounts } = useMsal();
    const authCtx = useAuthContext();
    // const teamsCtx = useTeamsContext();

    //httpclient is bugged in a state
    const httpClient = useRef<any>();

    const [initError, setInitError] = useState<any>(null);

    useEffect(() => {
        if (initError) {
            throw new Error('Init error', { cause: initError });
        }
    }, [initError]);

    useEffect(() => {
        // This is done to setup authentication for the legacy API
        if (isTeams) {
            httpClient.current = createHttpClientTeams(authCtx.getTeamsAccessToken);
        } else {
            httpClient.current = createHttpClient(authCtx.getMsalAccessToken);
        }

        sethttpapiready(true);
    }, [authCtx.authConfig.clientId, authCtx.getMsalAccessToken, authCtx.getTeamsAccessToken, isTeams]);

    const getEnabledRules = (config: any[]) => {
        return Object.keys(validationRules)?.map(vr => ({ name: vr, enabled: config.find(_ => _.key == vr)?.value == 'true' }));
    };

    useEffect(() => {
        setUserAgnet(new UAParser(navigator.userAgent).getResult()?.device?.type?.toLowerCase());
        setInIframe(window.location !== window.parent.location);
        const newIsTeams =
            window.location.ancestorOrigins !== undefined &&
            window.location.ancestorOrigins.length >= 1 &&
            window.location.ancestorOrigins[0] === 'https://teams.microsoft.com';
        setIsTeams(newIsTeams);
        const newIsDarkmode = newIsTeams ? getParameterByName('theme').toLowerCase() === 'dark' : window.matchMedia('(prefers-color-scheme: dark)').matches;
        setIsDarkmode(newIsDarkmode);

        // setUserAgnet("mobile");
        // setIsDarkmode(false);
        // setIsTeams(false);
        // console.log('location', window.location.search, mylocation);
        // console.log('mylocation', mylocation);
        // console.log('params', myparams);
        const page = getParameterByName('page');
        if (page !== undefined && page != null && page !== '') {
            debugger;
            // myhistory.replace('/' + getParameterByName('page') + myhistory.location.search.replace(/[\?&]page=[^&]+/, '').replace(/^&/, '?'));
            // cju - dont know??
            navigate('/' + getParameterByName('page') + mylocation.search.replace(/[\?&]page=[^&]+/, '').replace(/^&/, '?'));

            //history.replaceState && history.replaceState(
            //  null, '', location.pathname + location.search.replace(/[\?&]page=[^&]+/, '').replace(/^&/, '?') + location.hash
            //  );
            //+ location.search.replace(/[\?&]page=[^&]+/, '').replace(/^&/, '?') + location.hash
            //window.location.search = window.location.search.replace(/[\?&]page=[^&]+/, '').replace(/^&/, '?') + location.hash;
        }
    }, []);

    useEffect(() => {
        if (!httpapiready) {
            return;
        }

        const getUser = () =>
            ApiCalls.user()
                .then(res => res.data)
                .catch((err: ProblemDetails) => {
                    if (err.status === 404) {
                        setinitialerror({ error: 'User not found', msg: '' });
                        return;
                    }

                    console.error(err);
                });

        const getInitialSettings = async () => {
            const api = timeApi(setTimeError, httpClient.current);

            const promises: Promise<any>[] = [
                api.getAllowedWorkTypes(),
                api.getAllProjectWorkTypes(),
                ApiCalls.configurationGetcachedList().then(res => res.data),
            ];

            return await Promise.all(promises);
        };

        const getCalendarSettings = async userId => {
            const api = timeApi(setTimeError, httpClient.current);
            const calPromise: Promise<any>[] = [];
            // calPromise.push(timeApi(setTimeError, httpClient.current).getCalendarForUser(userId));
            calPromise.push(timeApi(setTimeError, httpClient.current).getCalendarForUser());
            return await Promise.all(calPromise);
        };

        // ApiCalls.configurationGetcachedList();
        getUser().then(user => {
            if (!user) {
                return;
            }
            getInitialSettings()
                .then(([workTypes, projectWorkTypes, allConfigs]) => {
                    setAllConfig(allConfigs);
                    // const calendar = await timeApi(setTimeError).getCalendarForUser(user.id);
                    if (user.user.isNotActive === true) {
                        // setUser({
                        //     approver: user.user.approver,
                        //     approverid: user.user.approver?.id,
                        //     delegateusers: user.delegatedUsers,
                        //     status: 'FOUND',
                        //     userid: user.user.id,
                        //     azureid: user.user.azureId,
                        //     username: user.user.name,
                        //     originalUser: user.originalUser,
                        //     workingUser: user,
                        //     isAdmin: user.user.isAdmin,
                        //     // permissions: user.originalUser.permissions,
                        //     isNotActive: user.user.isNotActive,
                        //     availability: user.user.availability || 8,
                        //     autoApprove: user.user.autoApprove || !user.user.approver,
                        //     start: new Date(user.user.start),
                        //     end: new Date(user.user.end),
                        //     calendar: user.user.calendar,
                        // });
                        setUser(user);
                        setEnabledRules([
                            { name: 'NoCellErrors', enabled: false },
                            { name: 'NoMoreThenXHours', enabled: false },
                            { name: 'NoMoreThanXHoursPerDay', enabled: false },
                            { name: 'OnlyAllowWithinEmployment', enabled: false },
                            { name: 'TimeEntriesMustHaveWorkType', enabled: false },
                            { name: 'Flow', enabled: false },
                            { name: 'EmptyTimeSheetAllowed', enabled: true },
                        ]);

                        setshortdateformat('D MMM');
                        setdateformat('DD/MM/YYYY');
                        setdatetimeformat('DD/MM/YYYY HH:mm');
                        setOnlyAssignedSearch(false);
                        setAllowedWorkTypes([]);

                        setProjectWorkTypes([]);
                        setTimeLowerLimitConfig(0);
                        try {
                            (window as any).tftloadingscreen(false);
                        } catch (ex) {}
                        return;
                    }
                    getCalendarSettings(user.user.id).then(([calendar]) => {
                        // set calendar and look it up, instead of on the user object
                        if (calendar) {
                            setCalendar(calendar);
                        }
                        // console.log('allConfigs', allConfigs);
                        const timeLowerLimitConfig = allConfigs.find(_ => (_.key as string).toLowerCase() === 'timelowerlimitconfig');
                        setEnabledRules(getEnabledRules(allConfigs));

                        const sdformat = allConfigs.find(_ => _.key.toLowerCase() === 'shortdateformat');
                        // console.log('Shortdateformat', sdformat);
                        if (sdformat !== undefined && sdformat != null && sdformat.value.length >= 2) {
                            setshortdateformat(sdformat.value);
                        } else {
                            setshortdateformat('D MMM');
                        }
                        const dformat = allConfigs.find(_ => _.key.toLowerCase() === 'dateformat');
                        // console.log('Dateformat', dformat);
                        if (dformat !== undefined && dformat != null && dformat.value.length >= 2) {
                            setdateformat(dformat.value);
                        } else {
                            setdateformat('DD/MM/YYYY');
                        }
                        const dtformat = allConfigs.find(_ => _.key.toLowerCase() === 'datetimeformat');
                        // console.log('DateTimeformat', dtformat);
                        if (dtformat !== undefined && dtformat != null && dtformat.value.length >= 2) {
                            setdatetimeformat(dtformat.value);
                        } else {
                            setdatetimeformat('DD/MM/YYYY HH:mm');
                        }

                        const gridInputTypeCfg = allConfigs.find(_ => _.key === TimeConfigKeys.GridInputType);
                        if (gridInputTypeCfg) {
                            setGridInputType(gridInputTypeCfg.value);
                        }

                        const showPlannedCfg = allConfigs.find(_ => _.key === TimeConfigKeys.ShowPlanned);
                        if (showPlannedCfg) {
                            setShowPlanned(getBoolean(showPlannedCfg.value));
                        }

                        const gridViewCfg = allConfigs.find(_ => _.key === TimeConfigKeys.GridView);
                        if (gridViewCfg) {
                            setGridView(gridViewCfg.value);
                        }

                        const workDayBoundariesCfg = allConfigs.find(_ => _.key === TimeConfigKeys.WorkDayBoundaries);
                        // console.log(workDayBoundariesCfg.value)
                        if (workDayBoundariesCfg) {
                            setWorkDayBoundaries(getBoolean(workDayBoundariesCfg.value));
                        }

                        const workDayAMPMCfg = allConfigs.find(_ => _.key === TimeConfigKeys.WorkDayAMPM);
                        // console.log(workDayBoundariesCfg.value)
                        if (workDayAMPMCfg) {
                            setWorkDayAMPM(getBoolean(workDayAMPMCfg.value));
                        }

                        const workDayMinutesIntervalCfg = allConfigs.find(_ => _.key === TimeConfigKeys.WorkDayMinutesInterval);
                        // console.log(workDayBoundariesCfg.value)
                        if (workDayMinutesIntervalCfg) {
                            setWorkDayMinutesInterval(Number(workDayMinutesIntervalCfg.value));
                        }

                        const allowCellCommentEnabledCfg = allConfigs.find(_ => _.key === TimeConfigKeys.AllowCellComment);
                        // console.log(workDayBoundariesCfg.value)
                        if (allowCellCommentEnabledCfg) {
                            setAllowCellComment(getBoolean(allowCellCommentEnabledCfg.value));
                        } else {
                            setAllowCellComment(true);
                        }

                        const allowTimeSheetCommentEnabledCfg = allConfigs.find(_ => _.key === TimeConfigKeys.AllowTimeSheetComment);
                        // console.log(workDayBoundariesCfg.value)
                        if (allowTimeSheetCommentEnabledCfg) {
                            setAllowTimeSheetComment(getBoolean(allowTimeSheetCommentEnabledCfg.value));
                        } else {
                            setAllowTimeSheetComment(true);
                        }

                        const allowCellFlagEnabledCfg = allConfigs.find(_ => _.key === TimeConfigKeys.AllowCellFlag);
                        // console.log(workDayBoundariesCfg.value)
                        if (allowCellFlagEnabledCfg) {
                            setAllowCellFlag(getBoolean(allowCellFlagEnabledCfg.value));
                        }

                        const onlyallowtimesheetwithintaskstartendCfg = allConfigs.find(_ => _.key === TimeConfigKeys.Onlyallowtimesheetwithintaskstartend);
                        // console.log('onlyallowtimesheetwithintaskstartendCfg', onlyallowtimesheetwithintaskstartendCfg)
                        if (onlyallowtimesheetwithintaskstartendCfg) {
                            setOnlyallowtimesheetwithintaskstartend(getBoolean(onlyallowtimesheetwithintaskstartendCfg.value));
                        } else {
                            setOnlyallowtimesheetwithintaskstartend(false);
                        }

                        const autoCreateNewUsersCfg = allConfigs.find(_ => _.key === TimeConfigKeys.AutoCreateNewUsers);
                        if (autoCreateNewUsersCfg) {
                            setAutoCreateNewUsers(getBoolean(autoCreateNewUsersCfg.value));
                        }

                        const autoCreationDisabledCfg = allConfigs.find(_ => _.key === TimeConfigKeys.AutoCreationDisabled);
                        if (autoCreationDisabledCfg) {
                            setAutoCreationDisabled(!getBoolean(autoCreationDisabledCfg.value));
                        }

                        const disableWorkTypesCfg = allConfigs.find(_ => _.key === TimeConfigKeys.EnableWorkType);
                        if (disableWorkTypesCfg) {
                            if (getBoolean(disableWorkTypesCfg.value) === false) {
                                setWorkTypeEnabled(false);
                            } else {
                                setWorkTypeEnabled(true);
                            }
                        }

                        const timesheetRAGsEnabledCfg = allConfigs.find(_ => _.key === TimeConfigKeys.TimesheetRAGsEnabled);
                        // console.log(workDayBoundariesCfg.value)
                        if (timesheetRAGsEnabledCfg) {
                            setTimesheetRAGsEnabled(getBoolean(timesheetRAGsEnabledCfg.value));
                        }

                        const nativeChartsEnabledCfg = allConfigs.find(_ => _.key === TimeConfigKeys.NativeChartsEnabled);
                        // console.log(workDayBoundariesCfg.value)
                        if (nativeChartsEnabledCfg) {
                            setNativeChartsEnabled(getBoolean(nativeChartsEnabledCfg.value));
                        }

                        const enableJiraEnabledCfg = allConfigs.find(_ => _.key === TimeConfigKeys.TimeEntriesImport);
                        if (enableJiraEnabledCfg) {
                            setEnableTimeEntriesImport(getBoolean(enableJiraEnabledCfg.value));
                        }

                        const enableSubmitPeriodOnImport = allConfigs.find(_ => _.key === TimeConfigKeys.SubmitPeriodOnImport);
                        if (enableSubmitPeriodOnImport) {
                            setEnableSubmitPeriodOnImport(getBoolean(enableSubmitPeriodOnImport.value));
                        }

                        const enableCoachmarksCfg = allConfigs.find(_ => _.key === TimeConfigKeys.EnableCoachmarks);
                        if (enableCoachmarksCfg) {
                            setEnableCoachmarks(getBoolean(enableCoachmarksCfg.value));
                        }

                        const onlyAssignedCfg = allConfigs.find(_ => _.key === TimeConfigKeys.OnlyAssignedSearch);
                        if (onlyAssignedCfg) {
                            setOnlyAssignedSearch(getBoolean(onlyAssignedCfg.value));
                        }

                        const workDaysCfg = allConfigs.find(_ => _.key === TimeConfigKeys.WorkDays);
                        // console.log(workDaysCfg?.value);
                        if (workDaysCfg) {
                            setWorkdays(JSON.parse(workDaysCfg.value));
                        } else {
                            // Setting default config if not saved
                            setWorkdays([
                                { name: 'monday', enabled: true },
                                { name: 'tuesday', enabled: true },
                                { name: 'wednesday', enabled: true },
                                { name: 'thursday', enabled: true },
                                { name: 'friday', enabled: true },
                                { name: 'saturday', enabled: false },
                                { name: 'sunday', enabled: false },
                            ]);
                        }

                        // if (user.stackTrace) {
                        //     fetch('/.auth/refresh').then(() => {
                        //         setRejected(true);
                        //         return;
                        //     });
                        // }
                        // const userToSet: AggregatedUser = {
                        //     approver: user.user.approver,
                        //     approverid: user.user.approver?.id,
                        //     delegateusers: user.delegatedUsers,
                        //     status: 'FOUND',
                        //     userid: user.user.id,
                        //     azureid: user.user.azureId,
                        //     username: user.user.name,
                        //     originalUser: user.originalUser,
                        //     workingUser: user,
                        //     isAdmin: user.user.isAdmin,
                        //     // permissions: user.originalUser.permissions,
                        //     isNotActive: user.user.isNotActive,
                        //     availability: user.user.availability || 8,
                        //     autoApprove: user.user.autoApprove || !user.user.approver,
                        //     start: user.user.start,
                        //     end: user.user.end,
                        //     calendar: user.user.calendar,
                        // };

                        setUser(user);

                        setAllowedWorkTypes(workTypes || []);

                        const inheritApproverRights = allConfigs.find(_ => _.key === TimeConfigKeys.InheritApproverRights);
                        setInheritApproverRights(serializeConfigBoolean(inheritApproverRights?.value) ?? false);

                        setProjectWorkTypes(projectWorkTypes || []);

                        setTimeLowerLimitConfig(timeLowerLimitConfig ? parseFloat(timeLowerLimitConfig.value) : 0);
                    });
                })
                .catch((errorResponse?: HttpResponse<TenantNotFoundProblem>) => {
                    if (errorResponse?.status === 404) {
                        authCtx.setTenantError(errorResponse.error);
                    } else {
                        setInitError(errorResponse);
                    }
                    // console.log('🚀 ~ useEffect ~ error:', error);
                });
        });
    }, [httpapiready]);

    useEffect(() => {
        if (isTeams === undefined || isDarkmode === undefined) {
            return;
        }

        const mqList = window.matchMedia('(prefers-color-scheme: dark)');
        mqList.addEventListener('change', ev => {
            setTheme(ev.matches ? TeamsDarkTheme : isTeams ? TeamsTheme : DefaultTheme);
        });
        const theme = isDarkmode ? TeamsDarkTheme : isTeams ? TeamsTheme : DefaultTheme;
        setTheme(theme);
    }, [isTeams, isDarkmode]);

    useEffect(() => {
        // needed to write css that only apply to darkmode & worked for the Layer/Callout's too.
        if (isDarkmode === undefined) {
            return;
        }

        const bodyClasslist = document.body.classList;
        if (!bodyClasslist) {
            return;
        }

        if (isDarkmode) {
            if (!bodyClasslist.contains('isDarkmode')) {
                bodyClasslist.add('isDarkmode');
            }
        } else {
            if (bodyClasslist.contains('isDarkmode')) {
                bodyClasslist.remove('isDarkmode');
            }
        }
    }, [isDarkmode]);
    useEffect(() => {
        if (loading === false && firstloadcompleted === false) {
            setFirstloadcompleted(true);
            try {
                //@Kristoffer, why is the wrong app.html
                (window as any).tftloadingscreen(false);
            } catch (ex) {}
        }
    }, [loading]);

    const renderApp = useMemo(() => {
        if (!(allConfig && enabledRules && allowedWorkTypes && user && userAgent !== null && theme)) {
            return <Suspender />;
        }
        if (user.user.isNotActive) {
            return <UserNotActive />;
        }
        return <App />;
    }, [allConfig, allowedWorkTypes, enabledRules, theme, user, userAgent]);

    const [inheritApproverRights, setInheritApproverRights] = useState(false);
    return (
        <UiCtxProvider
            value={{
                org,
                setOrg,
                user,
                setUser,
                userAgent,
                inIframe,
                isTeams,
                setIsTeams,
                isDarkmode,
                theme,
                splitBarPos,
                setSplitBarPos,
                plannedActive,
                setPlannedActive,
                actualActive,
                setActualActive,
                remainingActive,
                setRemainingActive,
                latestModifiedDelegateUserIds,
                setLatestModifiedDelegateUserIds,
                onlyAssignedSearch,
                setOnlyAssignedSearch,
                cellsWithError,
                setCellsWithError,
                allowedWorkTypes,
                enableCoachmarks,
                projectWorkTypes,
                timeLowerLimitConfig,
                enabledRules,
                setEnabledRules,
                allConfig,
                timeError,
                setTimeError,
                timeApi: timeApi(setTimeError, httpClient.current),
                dateshortformat: shortdateformat,
                dateformat: dateformat,
                datetimeformat: datetimeformat,
                gridInputType,
                setGridInputType,
                showPlanned,
                setShowPlanned,
                gridView,
                setGridView,
                workDayBoundaries,
                setWorkDayBoundaries,
                workDayMinutesInterval,
                setWorkDayMinutesInterval,
                allowCellComment,
                setAllowCellComment,
                allowTimeSheetComment,
                setAllowTimeSheetComment,
                allowCellFlag,
                setAllowCellFlag,
                timesheetRAGsEnabled,
                setTimesheetRAGsEnabled,
                nativeChartsEnabled,
                setNativeChartsEnabled,
                loading: loading,
                setLoading,
                selectedTimePeriod,
                setSelectedTimePeriod,
                workdays,
                setWorkdays,
                enableTimeEntriesImport,
                setEnableTimeEntriesImport,
                enableSubmitPeriodOnImport,
                setEnableSubmitPeriodOnImport,
                inheritApproverRights,
                setInheritApproverRights,
                workDayAMPM,
                setWorkDayAMPM,
                autoCreateNewUsers,
                setAutoCreateNewUsers,
                autoCreationDisabled,
                setAutoCreationDisabled,
                workTypeEnabled,
                setWorkTypeEnabled,
                allowTelemitry,
                setAllowTelemitry,
                setEnableCoachmarks,
                setOnlyallowtimesheetwithintaskstartend,
                onlyallowtimesheetwithintaskstartend,
            }}
        >
            {/* {allConfig && enabledRules && allowedWorkTypes && user && userAgnet !== null && theme && <Router routing={userAgnet === "mobile" ? mobileRouting : routing} />} */}

            {/* {allConfig && enabledRules && allowedWorkTypes && user && userAgnet !== null && theme && (user.isNotActive ? <UserNotActive /> : <App />)} */}
            {/* {allConfig && enabledRules && allowedWorkTypes && user && userAgnet !== null && theme && <Suspender />} */}
            {/* {user.isNotActive ? <UserNotActive /> : <App />} */}
            {renderApp}
            {initialerror ? (
                <div style={{ margin: 100, textAlign: 'center', color: 'white' }}>
                    <h3>{initialerror.error}</h3>
                    <h5>{initialerror.msg}</h5>
                    <div style={{ color: 'transparent', fontSize: 8 }}>{initialerror.innerError ? initialerror.innerError : ''}</div>
                </div>
            ) : (
                <></>
            )}
        </UiCtxProvider>
    );
});

export default ctx;

const serializeConfigBoolean = (dirtyBool?: any): boolean | undefined => {
    if (dirtyBool === null || dirtyBool === undefined) {
        return dirtyBool;
    }
    const type = typeof dirtyBool;
    if (type === 'string') {
        const stringBool = dirtyBool.toLowerCase();
        if (stringBool === 'false') {
            return false;
        }
        if (stringBool === 'true') {
            return true;
        }
    }
    // if (type === "number") {
    //     return Boolean(dirtyBool)
    // }
};
