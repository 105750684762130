import create from 'zustand';
import shallow from 'zustand/shallow';
import { Activity, ApprovalState, ProjectDto, TaskDto, TimeEntryApprovalDto, TimeEntryDto, TimeUserDto } from '../api/generated/data-contracts';
import { buildGridRows } from './helpers/buildGridRows';
import { useEffect, useMemo } from 'react';

type NonFunctionPropertyNames<T> = {
    [K in keyof T]: T[K] extends (...args: any[]) => any ? never : K;
}[keyof T];

type NonFunctionProperties<T> = Pick<T, NonFunctionPropertyNames<T>>;

export type PseudoRowType = 'project' | 'user' | 'group';

export type PseudoRow = {
    id: string;
    name: string;
    timeEntry: BigHours<TimeEntryDto>;
    parentId?: string;
    pseudoRow: true;
    type: PseudoRowType;
    project: ProjectDto;
    task: TaskDto;
};

type BigHours<T extends { hours: number }> = Omit<T, 'hours'> & { hours: Big };

export type TimeEntryApprovalDtoState = Omit<TimeEntryApprovalDto, 'timeEntry'> & { timeEntry: BigHours<TimeEntryDto>; name: string; type: 'approval' };
export type TimeRow = TimeEntryApprovalDtoState | PseudoRow;

export type ExpandedRows = {
    [key: string]: boolean;
};

type FunctionOr<T> = T | ((v: T) => T);

export type StateSetter<T> = (valueOrUpdate: FunctionOr<T>) => void;

type ApprovalStoreSetter<T extends keyof ApprovalStore> = StateSetter<ApprovalStore[T]>;

type ApprovalStore = {
    // filteredRows: TimeRow[];
    // setFilteredRows: StateSetter<ApprovalStore['filteredRows']>;
    // setFilteredRows: ApprovalStoreSetter<'filteredRows'>;
    projects: ProjectDto[];
    users: TimeUserDto[];
    selectedProjectIds: string[];
    setSelectedProjectIds: ApprovalStoreSetter<'selectedProjectIds'>;
    selectedUserIds: string[];
    setSelectedUserIds: ApprovalStoreSetter<'selectedUserIds'>;
    // selectedStates: ApprovalState[];
    // setSelectedStates: ApprovalStoreSetter<'selectedStates'>;

    rows: TimeRow[];
    setRows: ApprovalStoreSetter<'rows'>;
    originalApprovals: TimeEntryApprovalDto[];
    initState: (rawApprovals: TimeEntryApprovalDto[]) => void;
    /**
     *  Nukes the state
     */
    cleanup: () => void;
    expandedRows: ExpandedRows;
    setExpandedRows: ApprovalStoreSetter<'expandedRows'>;
    setGridExpandedRow: (rowId: string, expand: boolean) => void;
    selectedRows: Set<string>;
    setSelectedRows: ApprovalStoreSetter<'selectedRows'>;
};

type ApprovalStoreValues = NonFunctionProperties<ApprovalStore>;

export const useApprovalStore = create<ApprovalStore>((set, get, api) => {
    return {
        projects: [],
        users: [],
        rows: [],
        setRows: valueOrUpdater => {
            set(store => ({ ...store, rows: unwrap(valueOrUpdater, store.rows) }));
        },
        originalApprovals: [],

        selectedProjectIds: [],
        setSelectedProjectIds: valueOrUpdater => {
            set(store => {
                const nextState = unwrap(valueOrUpdater, store.selectedProjectIds);
                const filteredApprovals = store.originalApprovals.filter(
                    row => nextState.includes(row.project.id) && store.selectedUserIds.includes(row.user.id),
                );
                return {
                    ...store,
                    selectedProjectIds: nextState,
                    rows: buildGridRows(filteredApprovals),
                    selectedRows: new Set(),
                };
            });
        },
        selectedUserIds: [],
        setSelectedUserIds: valueOrUpdater => {
            set(store => {
                const nextState = unwrap(valueOrUpdater, store.selectedUserIds);
                const filteredApprovals = store.originalApprovals.filter(
                    row => nextState.includes(row.user.id) && store.selectedProjectIds.includes(row.project.id),
                );
                return {
                    ...store,
                    selectedUserIds: nextState,
                    rows: buildGridRows(filteredApprovals),
                    selectedRows: new Set(),
                };
            });
        },

        initState: approvals => {
            const rows = buildGridRows(approvals);
            const projects = [...new Map(approvals.map(approval => [approval.project.id, approval.project])).values()];
            const users = [...new Map(approvals.map(approval => [approval.user.id, approval.user])).values()];
            const state: ApprovalStoreValues = {
                originalApprovals: approvals,
                rows,
                expandedRows: rows.reduce((acc, row) => {
                    acc[row.id] = true;
                    return acc;
                }, {} as ExpandedRows),
                selectedRows: new Set(),
                selectedProjectIds: projects.map(project => project.id),
                selectedUserIds: users.map(user => user.id),
                // selectedUserIds: [],
                users,
                projects,
            };
            set(state);
        },
        cleanup: () => {
            const state: ApprovalStoreValues = {
                originalApprovals: [],
                rows: [],
                expandedRows: {},
                selectedRows: new Set(),
                selectedProjectIds: [],
                selectedUserIds: [],
                projects: [],
                users: [],
            };
            set(state);
        },
        expandedRows: {},
        setExpandedRows: valueOrUpdater =>
            set(store => ({
                ...store,
                expandedRows: unwrap(valueOrUpdater, store.expandedRows),
            })),
        setGridExpandedRow: (id, expand) => {
            set(store => {
                if (expand) {
                    const parentRows = getParentRows(id, store.rows);
                    const subrowFamily = getFamilyOfSubrows(id, store.rows);
                    return {
                        ...store,
                        expandedRows: [...parentRows, ...subrowFamily].reduce(
                            (newState, row) => {
                                newState[row.id] = true;
                                return newState;
                            },
                            { ...store.expandedRows, [id]: true } as ExpandedRows,
                        ),
                    };
                }
                const subrowFamily = getFamilyOfSubrows(id, store.rows);
                return {
                    ...store,
                    expandedRows: subrowFamily.reduce(
                        (newState, row) => {
                            newState[row.id] = false;
                            return newState;
                        },
                        { ...store.expandedRows, [id]: false } as ExpandedRows,
                    ),
                };
            });
        },
        selectedRows: new Set(),
        setSelectedRows: valueOrUpdate => {
            set(store => {
                return {
                    ...store,
                    selectedRows: unwrap(valueOrUpdate, store.selectedRows),
                };
            });
        },
    };
});

// (window as any).aStore = useApprovalStore

const unwrap = <T>(valueOrUpdater: FunctionOr<T>, currentState: T) => (valueOrUpdater instanceof Function ? valueOrUpdater(currentState) : valueOrUpdater);

export const getParentRows = (rowId: string, rows: TimeRow[]) => {
    const tree: TimeRow[] = [];
    let currentAncestor = rows.find(row => row.id === rowId);
    while (currentAncestor) {
        currentAncestor = rows.find(row => row.id === currentAncestor.parentId);
        if (currentAncestor) {
            tree.push(currentAncestor);
        }
    }
    return tree;
};

export const getFamilyOfSubrows = (rowId: string, rows: TimeRow[]) => {
    const subrowFamily: TimeRow[] = [];
    const recurse = (rowId: string) => {
        const subrows = getImmediateSubrows(rowId, rows);
        subrows.forEach(subrow => {
            subrowFamily.push(subrow);
            recurse(subrow.id);
        });
    };
    recurse(rowId);
    return subrowFamily;
};

const isRootRow = (row: TimeRow) => {
    return !row.parentId;
};

export const getImmediateSubrows = (rowId: string, rows: TimeRow[]) => {
    return rows.filter(row => row.parentId === rowId);
};

export const isPseudoRow = (row: TimeRow): row is PseudoRow => Boolean((row as any).pseudoRow);
export const isApprovalRow = (row: TimeRow): row is TimeEntryApprovalDtoState => !(row as any).pseudoRow;

export const useGridRenderableRows = (rows: (TimeEntryApprovalDtoState | PseudoRow)[]) => {
    const expandedRows = useApprovalStore(store => store.expandedRows, shallow);
    return useMemo(() => {
        // The row is renderable if it is a root row or the parent is expanded
        return rows.filter(row => {
            return isRootRow(row) || expandedRows[row.parentId];
        });
    }, [expandedRows, rows]);
};

export const useInitializeApprovalStore = (appr?: TimeEntryApprovalDto[]) => {
    const { initState, cleanup, rows } = useApprovalStore(
        store => ({
            initState: store.initState,
            cleanup: store.cleanup,
            rows: store.rows,
        }),
        shallow,
    );

    useEffect(() => {
        if (appr?.length) {
            initState(appr);
        }
    }, [appr, initState]);

    useEffect(() => {
        return () => {
            cleanup();
        };
    }, [cleanup]);

    return rows;
};

export const useGridExpandedRowState = (id: string) =>
    useApprovalStore(
        store =>
            [
                store.expandedRows[id],
                (valueOrUpdater: FunctionOr<boolean>) => {
                    const nextState = unwrap(valueOrUpdater, store.expandedRows[id]);
                    const subrows = getFamilyOfSubrows(id, store.rows);
                    store.setExpandedRows(lastState => {
                        return subrows.reduce(
                            (next, row) => {
                                next[row.id] = nextState;
                                return next;
                            },
                            { ...lastState, [id]: nextState } as ExpandedRows,
                        );
                    });
                },
            ] as const,
        shallow,
    );

export const useSelectedRowIdState = (id: string) =>
    useApprovalStore(
        store =>
            [
                store.selectedRows.has(id),
                (valueOrUpdater: FunctionOr<boolean>) => {
                    const nextState = unwrap(valueOrUpdater, store.selectedRows.has(id));
                    const subrowIds = getFamilyOfSubrows(id, store.rows).map(row => row.id);
                    store.setSelectedRows(rows => {
                        if (nextState) {
                            return new Set([...rows, id, ...subrowIds]);
                        }
                        return new Set([...rows].filter(rowId => rowId !== id && !subrowIds.includes(rowId)));
                    });
                },
            ] as const,
        shallow,
    );

export const useApprovalState = (rowId: string) =>
    useApprovalStore(
        store =>
            [
                (store.rows.find(row => row.id === rowId) as TimeEntryApprovalDtoState).approvalState,
                (valueOrUpdater: FunctionOr<ApprovalState>) => {
                    store.setRows(rows =>
                        rows.map(row => {
                            if (row.id === rowId) {
                                return {
                                    ...row,
                                    approvalState: unwrap(valueOrUpdater, (row as TimeEntryApprovalDtoState).approvalState),
                                };
                            }
                            return row;
                        }),
                    );
                },
            ] as const,
        shallow,
    );

export const useActivityState = (rowId: string) =>
    useApprovalStore(
        store =>
            [
                (store.rows.find(row => row.id === rowId) as TimeEntryApprovalDtoState).activity,
                (valueOrUpdater: FunctionOr<Activity>) => {
                    store.setRows(rows =>
                        rows.map(row => {
                            if (row.id === rowId) {
                                const nextActivityState = unwrap(valueOrUpdater, (row as TimeEntryApprovalDtoState).activity);
                                return {
                                    ...row,
                                    activity: nextActivityState,
                                    rate: nextActivityState?.rate,
                                };
                            }
                            return row;
                        }),
                    );
                },
            ] as const,
        shallow,
    );

export const useRateState = (rowId: string) =>
    useApprovalStore(store => {
        const row = store.rows.find(row => row.id === rowId) as TimeEntryApprovalDtoState;
        return [
            row.activity?.rate ?? row.rate ?? row.originalRate,
            (valueOrUpdater: FunctionOr<number | null | undefined>) => {
                store.setRows(rows =>
                    rows.map(row => {
                        if (row.id === rowId) {
                            return {
                                ...row,
                                rate: unwrap(valueOrUpdater, (row as TimeEntryApprovalDtoState).rate),
                            };
                        }
                        return row;
                    }),
                );
            },
        ] as const;
    }, shallow);

export const useMissingApprovalCount = (rowId: string) =>
    useApprovalStore(store => {
        const row = store.rows.find(row => row.id === rowId);
        const parentRows = getParentRows(row.id, store.rows);
        const siblingRows = (parentRows.length && getFamilyOfSubrows(parentRows[parentRows.length - 1].id, store.rows)) || [];
        const siblingApprovalRows = siblingRows.filter(isApprovalRow);
        return siblingApprovalRows.filter(row => row.approvalState !== 'Approved').length;
    }, shallow);
