import React, { FunctionComponent } from 'react';
import { Stack, Separator, Link } from '@fluentui/react';
import { Icon, ILabelStyles, Label } from '@fluentui/react';
import { LocalStorage } from '../components/Guide/Coachmarks/utils/LocalStorage';
import { CoachmarkGridSeenKey, CoachmarkVisibilityKey } from '../components/Guide/Coachmarks/TFTMainCoachmarks';
import { useNavigate } from 'react-router';

export const HelpGuide: FunctionComponent<any> = _ => {
    const navigate = useNavigate();

    const _guideResetDescription: string = 'If you missed, or wish to redo the Guided Tour of Time for Teams, press the button below.';
    const labelStyles: ILabelStyles = {
        root: { marginTop: 10 },
    };

    const _handleIconClick = () => {
        LocalStorage.Set(CoachmarkVisibilityKey, false);
        LocalStorage.Set(CoachmarkGridSeenKey, false);
        navigate('/');
    };

    return (
        <div style={{ width: '100vw' }}>
            <br />
            <h2>Guide</h2>
            <Separator styles={{ root: { width: '100%' } }} />
            <Label styles={labelStyles}>{_guideResetDescription}</Label>
            <Stack horizontalAlign="center">
                <Link onClick={_handleIconClick} to="/" style={{ marginRight: '20px', marginTop: -2 }}>
                    <Icon style={{ fontSize: 60 }} iconName="PlaybackRate1x" onClick={_handleIconClick} />
                </Link>
            </Stack>
        </div>
    );
};

export default HelpGuide;
