import React, { useEffect, useState } from 'react';
import { useUiContext } from '../../components/Contexts/UiContext';
import { PrimaryButton, Stack } from '@fluentui/react';
import { PageHeader, PageContent, ContentSection } from '../../components/LayoutElements';
import { TextField } from '@fluentui/react';

function PowerHubSettings() {
    //FIX
    const [loading, setLoading] = useState<boolean>(true);
    const [updating, setUpdating] = useState<boolean>(false);

    const uiCtx = useUiContext();
    const [clientId, setClientId] = useState<string>(null);

    useEffect(() => {
        uiCtx.timeApi.getPowerhubServicePrincipal().then(powerHubPrincipal => {
            setClientId(powerHubPrincipal?.principalName);
            uiCtx.setLoading(false);
            setLoading(false);
        });
    }, []);

    const updateServicePrincipal = () => {
        setUpdating(true);
        uiCtx.timeApi.upsertPowerhubServicePrincipal(clientId).then(() => {
            setUpdating(false);
        });
    }

    if (uiCtx.loading || loading) {
        return null;
    }

    return (
        <>
            <PageHeader title="PowerHub Settings" icon="Info" />

            <PageContent>
                <ContentSection>
                    <Stack tokens={{ childrenGap: 32 }}>
                        <TextField defaultValue={clientId} onChange={(e, v) => setClientId(v)} styles={{ root: { alignSelf: 'center', minWidth: 300 } }} label="PowerHub ClientID"></TextField>
                        <PrimaryButton
                            styles={{ root: { alignSelf: 'center' }, flexContainer: { minWidth: 250 } }}
                            onClick={() => {
                                updateServicePrincipal();
                            }}
                            text={updating ? "updating PowerHub ClientID" : "Save PowerHub ClientID"}
                            disabled={updating}
                        />
                    </Stack>
                </ContentSection>
            </PageContent>
        </>
    );
}

export default PowerHubSettings;
