/**
 * COLUMN SIZES
 */
import { ApprovalState } from '../../../../api/generated/data-contracts';

// /**
//  * ROW SIZES
//  */
// export const ROW_HEADER_WIDTH = 400;
export const ROW_HEIGHT = 50;
export const ROW_EXPANDED_HEIGHT = 75;

// const GRID_COLUMN_GAP = 10;
// const GRID_COLUMN_WIDTHS = [400, 100, 200, 100, 100, 200, 200];
// export const GRID_TOTAL_WIDTH = GRID_COLUMN_WIDTHS.reduce((acc, width) => acc + width + GRID_COLUMN_GAP, 0);
// export const GRID_COLUMN_TEMPLATE = GRID_COLUMN_WIDTHS.reduce((acc, width) => acc + `${width}px `, '');
// export const GRID_ROW_STYLES = {
//     display: 'grid',
//     gridTemplateColumns: GRID_COLUMN_TEMPLATE,
//     gap: 10,
//     alignItems: 'center',
// };
export const ROW_STICKY_COUNT = 1;
export const TOP_HEADERS_HEIGHT = 50;

// /**
//  * CELL SIZES
//  */
// export const CELL_WIDTH = 80;
// export const CELL_HEIGHT = 30;

// /**
//  * COLORS
//  */
// export const ROW_BG_HOVER = "var(--rowHoverBackgroundColor)";
// export const ROOT_ROW_BG = "var(--tableBackground)";
// export const ROW_BG = "var(--rowGroupBackground)";

// /**
//  * BORDERS
//  */
// export const BORDER = "1.5px solid var(--tableBorderColorVertical)";

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

type LastOf<T> = UnionToIntersection<T extends any ? (x: T) => void : never> extends (x: infer L) => void ? L : never;

// TS4.0+
type Push<T extends any[], V> = [...T, V];

type TuplifyUnion<T, L = LastOf<T>, N = [T] extends [never] ? true : false> = true extends N ? [] : Push<TuplifyUnion<Exclude<T, L>>, L>;

type ApprovalStateArray = TuplifyUnion<ApprovalState>;

export const APPROVAL_STATES: ApprovalStateArray = ['Pending', 'Approved', 'Rejected']; // This will be OK

export const CUSTOM_RATE_ID = 'ca1d89fb-1bfa-4b5b-9cc3-10428fe0d4d6';
