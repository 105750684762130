import { useRef } from 'react';
import { Spinner, Stack, Text, useTheme } from '@fluentui/react';
import { useMemo } from 'react';
import { QueryPair } from '../../types';
import { useQuery } from '@tanstack/react-query';
import { AppPackageGroup } from '../../../../api/generated/data-contracts';

export const ReleaseViewer = ({ getCurrentControllers: [fetcher, key = ['current-controlllers']] }: { getCurrentControllers: QueryPair<() => Promise<AppPackageGroup[]>> }) => {
    const theme = useTheme();
    const defaultCurrentVersions = useRef([] as AppPackageGroup[]).current;
    const { data: currentVersions = defaultCurrentVersions, isLoading: loading } = useQuery(key, fetcher);

    const formattedData = useMemo(() => {
        return currentVersions.map(controller => {
            return {
                // stage: UpgradeCycleNumeric[controller.package!.cycle!],
                stage: controller.package!.cycle!,
                controllerName: controller.controllerName,
                ...controller.package,
            };
        });
    }, [currentVersions]);

    return (
        <Stack>
            <Stack styles={{ root: { height: '50px' } }}>
                {loading ? (
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <Text variant="large">Loading versions..</Text>
                        <Spinner />
                    </Stack>
                ) : (
                    <Text variant="large">Installed:</Text>
                )}
            </Stack>
            <Stack tokens={{ childrenGap: theme.spacing.m }}>
                {formattedData.map(data => {
                    return (
                        <Stack
                            key={data.path}
                            horizontal
                            tokens={{ childrenGap: theme.spacing.m }}
                            styles={{
                                root: { padding: theme.spacing.m, boxShadow: 'rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px, rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px' },
                            }}
                        >
                            <Stack>
                                <Text>Package:</Text>
                                <Text>Cycle:</Text>
                                <Text>Filename:</Text>
                                <Text>Release date:</Text>
                            </Stack>
                            <Stack>
                                <Text>{data.controllerName}</Text>
                                <Text>{data.stage}</Text>
                                <Text>{data.name}</Text>
                                <Text>{new Date(data.lastModified!)?.toDateString()}</Text>
                            </Stack>
                        </Stack>
                    );
                })}
            </Stack>
        </Stack>
    );
};
