import { TextField } from "@fluentui/react"
import React from "react"

export interface ValidationConfigProps {
    setConfig: (name, value) => void
    allConfig: any[]
}

export const MaxPercentOfCapacityConfigValues = {
    MaxPercent: "MaxPercentOfCapacity_MaxPercent"
}

export const MaxPercentOfCapacityConfig = (props: ValidationConfigProps) => {
    
    const configKey = MaxPercentOfCapacityConfigValues.MaxPercent;

    const onChange = (value) => {
        props.setConfig(configKey, value);
    }

    if (!props.allConfig) return;


    return <div>
        <TextField onBlur={(e) => onChange(e.target.value)} label="Max. % of period capacity" defaultValue={props.allConfig.find(_ => _.key == configKey)?.value || `5`}></TextField>
    </div>
}

