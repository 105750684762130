import React, { useMemo } from "react";
import { Text, Stack, makeStyles } from "@fluentui/react";
import { useMobileContext } from "../../../../MobileContext";
import { useMobile } from "../../../../useMobile";
import { mobileConstants } from "../../../../mobileConstants";
import { useUiContext } from "../../../../../components/Contexts/UiContext";
import { formatNumberToDisplay, formatNumberToDisplayPercent } from "../../../../mobileUtils";

interface IProps {};

export const TotalRow: React.FC<IProps> = (props) => {
    const uiCtx = useUiContext();
    const mobCtx = useMobileContext();
    const classes = getStyles();

    const totalValue = useMobile(state => state.totalValues?.all);

    const value = useMemo(() => {
        if (!totalValue || Number.isNaN(Number(totalValue))) return "";

        if (mobCtx.showAsPercent) {
            return formatNumberToDisplayPercent(totalValue, mobCtx.selectedPeriodDays, uiCtx)
            // const procent = convertHoursToProcent(Number(totalValue), mobCtx.selectedPeriodDays.length, getCapacityPrDay(mobCtx.selectedPeriodDays, uiCtx));
            // return `${MathToFixed(parseNumber(procent.toString().replace(/\,/g, '.')), 1)}`;
        } else {
            return formatNumberToDisplay(totalValue, uiCtx);
            // return MathToFixed(parseNumber(totalValue.toString().replace(/\,/g, '.')), 2)
        }
    }, [totalValue, mobCtx.selectedPeriodDays, uiCtx.gridInputType]);
    
    return (
        <Stack 
            horizontal 
            verticalAlign="center" 
            className={classes.root}
        >
            <Stack grow verticalAlign="center">
                <Text className={classes.txt}>
                    Total {`${(mobCtx.showAsPercent && value) ? "%" : "hours"}`}
                </Text>
            </Stack>
            <Text className={classes.value}>
                {`${value}${(mobCtx.showAsPercent && value) ? " %" : ""}`}
                {/* { totalValue || "" } */}
            </Text>
        </Stack>
    );
};

const getStyles = makeStyles(theme => ({
    root: ["totalRow", {
        height: mobileConstants.gridRowHeight,
        position: "sticky",
        top: 0,
        backgroundColor: theme.semanticColors.bodyFrameBackground,
        zIndex: 60,
        borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
        paddingLeft: 26,
        paddingRight: 16,
    }],
    txt: {
        fontSize: 16, 
        fontWeight: 600,
    },
    value: {
        fontSize: 16,
        fontWeight: 600,
    },
}));