import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Dropdown, PrimaryButton, Spinner, Stack, Text, useTheme } from '@fluentui/react';
import type { IButtonStyles, IDropdownOption, IDropdownStyleProps, IDropdownStyles, ITextStyles } from '@fluentui/react';
import { IRequestStatus, useSendChangeRequest } from './hooks/useChangeRequest';
import { IStyleFunctionOrObject } from '@uifabric/merge-styles';
import { useValidateStrictVersions } from './hooks/useValidateStrictVersions';
import { useMutation } from '@tanstack/react-query';
import { QueryPair, UpgradeCycle } from './types';
import { AppPackage, UpgradePackageGroup, UpgradePackage, UpgradeCycle as ApiUpgradeCycle, UpgradePackagesPayload } from '../../api/generated/data-contracts';

const noopFunction = () => {
    /* This is a noop */
};

const stageOptions = Object.keys(UpgradeCycle).map((name, index) => ({
    key: name,
    text: name,
    index,
}));

export interface IReleaseChangerStyles {
    dropDownStyles?: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles>;
    sendRequestButtonStyles?: IButtonStyles;
    validationErrorStyles?: ITextStyles;
}

export interface ITranlateReleaseChanger {
    selectStageText?: string;
    networkErrorText?: string;
    productionVersionDropdownText?: string;
    stageDropdownText?: string;
    statusText?: string;
    timeLeftText?: string;
    sendRequestButtonText?: string;
    patchInvalidText?: string;
    minorInvalidText?: string;
    majorInvalidText?: string;
}

export type FetcherPairs = {
    getControllersByStage: QueryPair<(chosenStage: string | number) => Promise<UpgradePackageGroup[]>>;
    sendUpgradeRequest: QueryPair<(controllerPackagesToRequest: UpgradePackagesPayload) => Promise<void>>;
};

export interface IReleaseChangerProps {
    /**
     * Gets called with the packages currently chosen. Return false to cancel request.
     * It is recommended to lock the page for user interaction here
     */
    onBeforeRequest?: (controllers: UpgradePackagesPayload) => boolean | void;

    /**
     * It is recommended to reload the page to take new versions in effect here
     */
    onAfterRequest?: (status: IRequestStatus) => void;

    /**
     * Get status during request to see e.g. time left
     */
    onDuringRequest?: (status: IRequestStatus) => any;

    /**
     * Gets the sendRequest function to execute it externally later
     * e.g after user confirmation.
     *
     * NOTE: Remember that state setters runs a function if provided,
     * so be sure to wrap the sendRequest in a function if kept
     * in state e.g: sendRequest => setState(() => sendRequest),
     * otherwise it will run imediately.
     */
    onConfirm?: (sendRequest: () => void) => void;

    /**
     * Gets all the controller to do sorting, filtering, mapping etc.
     */
    onMapControllers?: (controllers: UpgradePackageGroup[]) => UpgradePackageGroup[];

    onUpgradeError?: (error: any) => void;

    /**
     * Gets the date in UTC format.
     */
    packageNameFormatter?: (name: string, lastModified: string) => string;

    onSelectedPackage?: (selectedVersion: string | null) => void;

    translationTexts?: ITranlateReleaseChanger;

    /**
     * Time in seconds to wait for services to reboot.
     * Defaults to 60s
     */
    waitTime?: number;
    stylesForComponents?: IReleaseChangerStyles;
    strictMajorVersions?: boolean;
    strictMinorVersions?: boolean;
    strictPatchVersions?: boolean;
    showStatus?: boolean;
    /**
     * Disable network calls so nothing gets called accidentally.
     * Writes the request to the console.
     */
    developmentMode?: boolean;

    /**
     * Auto select the latest package versions for all controllers
     * when the stage selection changes. Defaults to true
     */
    defaultSelectLatestVersionsInStage?: boolean;

    fetchers: FetcherPairs;
}

const defaultPackageNameFormatter = (name: string, lastModified: string) => `${name.slice(0, name.lastIndexOf('.'))} (${lastModified})`;

export const ReleaseChanger = ({
    translationTexts: {
        selectStageText = 'Select release stage',
        networkErrorText = 'Network error',
        productionVersionDropdownText = 'Choose production version',
        stageDropdownText = 'version',
        statusText = 'Status:',
        timeLeftText = 'Time left:',
        sendRequestButtonText = 'Change versions',
        majorInvalidText = 'major',
        minorInvalidText = 'minor',
        patchInvalidText = 'patch',
    } = {},
    onBeforeRequest = noopFunction,
    onAfterRequest = noopFunction,
    onDuringRequest,
    onConfirm,
    onMapControllers,
    packageNameFormatter = defaultPackageNameFormatter,
    onUpgradeError,
    onSelectedPackage,
    waitTime = 60,
    stylesForComponents: { dropDownStyles, sendRequestButtonStyles, validationErrorStyles } = {},
    strictMajorVersions,
    strictMinorVersions,
    strictPatchVersions,
    showStatus = true,
    developmentMode,
    defaultSelectLatestVersionsInStage = true,
    fetchers: {
        getControllersByStage: [fetchControllers, key = ['get-controllers-by-stage']],
        sendUpgradeRequest: [sendUpgradeRequest, upgradeRequestQueryKey = ['upgrade-request']],
    },
}: IReleaseChangerProps) => {
    const [chosenStage, setChosenStage] = useState<null | IDropdownOption>(null);

    const defaultControllersByStage = useRef([] as UpgradePackageGroup[]).current;
    const {
        data: rawControllersByStage = defaultControllersByStage,
        isLoading: loading,
        error,
        mutate: GetUpgradePackages,
    } = useMutation(key, (chosenStage: string | number) => fetchControllers(chosenStage));

    const [controllerPackagesToRequest, setControllerPackagesToRequest] = useState<UpgradePackage[]>([]);
    const theme = useTheme();

    const validationErrorStyles_ = useMemo(
        () => validationErrorStyles ?? { root: { margin: `${theme.spacing.s1} 0`, color: theme.palette.red } },
        [theme.palette.red, theme.spacing.s1, validationErrorStyles],
    );

    const { majorValid, minorValid, patchValid } = useValidateStrictVersions(controllerPackagesToRequest, strictMajorVersions, strictMinorVersions, strictPatchVersions);

    const isValidRequest = useMemo(() => {
        const isValid = !controllerPackagesToRequest.some(controller => !controller.package);
        return Boolean(controllerPackagesToRequest.length) && isValid && majorValid && minorValid && patchValid;
    }, [controllerPackagesToRequest, majorValid, minorValid, patchValid]);

    const { requestStatus, sendChangeStageRequest } = useSendChangeRequest([sendUpgradeRequest, upgradeRequestQueryKey], waitTime, onUpgradeError);

    const requestInProgress = requestStatus.status === 'pending' || requestStatus.status === 'rebooting';

    const isProductionStage = chosenStage?.key === UpgradeCycle.Production;

    const hasRunAfterFn = useRef(false);

    const cachedFilterFn = useRef(onMapControllers);

    useEffect(() => {
        cachedFilterFn.current = onMapControllers;
    }, [onMapControllers]);

    const sendRequest = useCallback(
        (confirmed?: boolean): void => {
            if (isValidRequest) {
                if (onConfirm && !confirmed) {
                    // Let parent component do user confirmation and return a function to start the request
                    return onConfirm(() => sendRequest(true));
                }
                const result = onBeforeRequest(controllerPackagesToRequest);
                if (result === false) {
                    return;
                }
                hasRunAfterFn.current = false;
                if (developmentMode) {
                    console.debug('ReleaseChanger development mode');
                    return console.log('This gets sent:', controllerPackagesToRequest);
                }
                sendChangeStageRequest(controllerPackagesToRequest);
            }
        },
        [controllerPackagesToRequest, developmentMode, isValidRequest, onBeforeRequest, onConfirm, sendChangeStageRequest],
    );

    const setPackageInControllerForRequest = (index: number, Packages: any[]) => (e: any, Package: any) => {
        if (onSelectedPackage) {
            onSelectedPackage(Package.key);
        }
        if (isProductionStage) {
            return setControllerPackagesToRequest(
                controllersByStageWithPackages.map(cont => ({
                    controllerName: cont.controllerName,
                    package: { ...cont.packages[Package.index] },
                    releaseCycle: cont.releaseCycle as any,
                })),
            );
        }
        setControllerPackagesToRequest(oldCont => {
            const newCont = [...oldCont];
            newCont[index] = {
                ...newCont[index],
                package: { ...Packages[Package.index] },
            };
            return newCont;
        });
    };

    const setStage = useCallback(
        (e: any, option?: IDropdownOption) => {
            if (onSelectedPackage) {
                onSelectedPackage('');
            }
            if (option) {
                // TODO incomment this again
                // setControllerPackagesToRequest([]);
                setChosenStage(option);
            }
        },
        [onSelectedPackage],
    );

    const controllersByStageWithPackages = useMemo((): {
        packages: AppPackage[];
        controllerName?: string;
        releaseCycle?: UpgradeCycle | ApiUpgradeCycle;
    }[] => {
        const controllers = cachedFilterFn.current ? cachedFilterFn.current(rawControllersByStage) : rawControllersByStage;
        // Align packages if production stage is chosen
        if (isProductionStage) {
            const lowestPackageCountInController = Math.min(...controllers.map(controller => controller!.packages!.length));

            return controllers.map(controller => {
                // Latest first desc.
                const packages = [...controller.packages].reverse();
                // Setting the length to a lower number cuts off the tail.
                packages.length = lowestPackageCountInController;
                return {
                    ...controller,
                    packages,
                } as {
                    packages: AppPackage[];
                    controllerName?: string;
                    releaseCycle?: UpgradeCycle | ApiUpgradeCycle;
                };
            });
        }

        return controllers.map(controller => {
            // Latest first desc.
            const packages = [...controller.packages].reverse();
            return {
                ...controller,
                packages,
            } as {
                packages: AppPackage[];
                controllerName?: string;
                releaseCycle?: UpgradeCycle | ApiUpgradeCycle;
            };
        });
    }, [isProductionStage, rawControllersByStage]);

    useEffect(() => {
        if (chosenStage) {
            // GetUpgradePackages('', chosenStage.key as string);
            GetUpgradePackages(chosenStage.key as string);
        }
    }, [GetUpgradePackages, chosenStage]);

    useEffect(() => {
        setControllerPackagesToRequest(
            controllersByStageWithPackages.map(controller => {
                if (onSelectedPackage) {
                    onSelectedPackage(defaultSelectLatestVersionsInStage && controller?.packages?.length ? controller.packages[0].name : null);
                }
                return {
                    controllerName: controller.controllerName,
                    package: defaultSelectLatestVersionsInStage && controller?.packages?.length ? controller.packages[0]! : null,
                    releaseCycle: controller.releaseCycle as any,
                } as UpgradePackage;
            }),
        );
    }, [controllersByStageWithPackages, defaultSelectLatestVersionsInStage, onSelectedPackage]);

    useEffect(() => {
        if (requestStatus.status === 'success' || requestStatus.status === 'error') {
            if (onDuringRequest) {
                // Call onDuringRequest one last time to let the
                // timeLeft prop get to 0 for implementations that
                // needs to show elapsed time in different places.
                onDuringRequest(requestStatus);
            }
            if (!hasRunAfterFn.current) {
                onAfterRequest(requestStatus);
                hasRunAfterFn.current = true;
            }
        }
    }, [onAfterRequest, onDuringRequest, requestStatus]);

    useEffect(() => {
        if (requestInProgress && onDuringRequest) {
            onDuringRequest(requestStatus);
        }
    }, [onDuringRequest, requestInProgress, requestStatus]);

    const versionWarning = useMemo((): string => {
        let warningText = `Please choose matching `;

        if (!majorValid) {
            warningText += majorInvalidText;
        }

        if (!minorValid) {
            if (!majorValid) {
                warningText += ', ';
            }
            warningText += minorInvalidText;
        }

        if (!patchValid) {
            if (!minorValid) {
                warningText += ', ';
            }
            warningText += patchInvalidText;
        }

        warningText += ' version.';

        return warningText;
    }, [majorInvalidText, majorValid, minorInvalidText, minorValid, patchInvalidText, patchValid]);

    return (
        <>
            <Dropdown label={selectStageText} options={stageOptions} onChange={setStage} disabled={requestInProgress} />
            {loading && (
                <Stack styles={{ root: { padding: `${theme.spacing.m} 0` } }}>
                    <Spinner />
                </Stack>
            )}
            {error && <Text>{networkErrorText}</Text>}
            {chosenStage &&
                !loading &&
                !error &&
                (isProductionStage
                    ? controllersByStageWithPackages[0] && (
                          <Dropdown
                              label={productionVersionDropdownText}
                              options={controllersByStageWithPackages[0].packages.map((Package, index: number) => {
                                  return {
                                      text: packageNameFormatter(Package.name, Package.lastModified.toString()),
                                      key: Package.name,
                                      index,
                                  };
                              })}
                              selectedKey={controllerPackagesToRequest[0]?.package ? controllerPackagesToRequest[0].package.name : null}
                              onChange={setPackageInControllerForRequest(0, [])}
                              disabled={requestInProgress}
                              styles={dropDownStyles}
                          />
                      )
                    : controllersByStageWithPackages.map((controller, index) => {
                          return (
                              <Dropdown
                                  key={controller.controllerName}
                                  //   label={`${controller.controllerName} ${chosenStage.text} ${stageDropdownText}`}
                                  label={`${controller.controllerName} ${stageDropdownText}`}
                                  options={controller.packages.map((Package, index: number) => {
                                      return {
                                          text: packageNameFormatter(Package.name, Package.lastModified.toString()),
                                          key: Package.name,
                                          index,
                                      };
                                  })}
                                  selectedKey={controllerPackagesToRequest[index]?.package ? controllerPackagesToRequest[index].package.name : null}
                                  onChange={setPackageInControllerForRequest(index, controller.packages)}
                                  disabled={requestInProgress}
                              />
                          );
                      }))}
            {requestInProgress && showStatus && (
                <div>
                    <p>
                        {statusText} {requestStatus.status}
                    </p>
                    {requestStatus.status === 'rebooting' && <p>{timeLeftText + ' ' + requestStatus.timeLeft}s</p>}
                </div>
            )}
            <br />
            {(!majorValid || !minorValid || !patchValid) && <Text styles={validationErrorStyles_}>{versionWarning}</Text>}
            <PrimaryButton styles={sendRequestButtonStyles} text={sendRequestButtonText} onClick={() => sendRequest()} disabled={requestInProgress || !isValidRequest} />
        </>
    );
};
