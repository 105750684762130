import React, { useEffect, useState } from 'react';
import { makeStyles } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { PrimaryButton } from '@fluentui/react';
import { TextField, Callout, FontWeights, Text, DirectionalHint } from '@fluentui/react';

interface propsApproverCommentCell {
    labelText: string;
    defaultValue: string;
    onUpdated: Function;
    reportPeriodId: string;
    disabled: boolean;
    commentDisabled: boolean;
}

function ApproverCommentCell(props: propsApproverCommentCell) {
    const styles = getStyles();
    const targetId = useId();

    const [showdetailsflyout, setShowdetailsflyout] = useState<boolean>(false);
    const [cellcomment, setComment] = useState<string>(props.defaultValue ?? '');
    // const [commentLabelText, setCommentLabelText] = useState<string>(props.labelText);

    useEffect(() => {
        setComment(props.defaultValue);
    }, []);

    return (
        <>
            <div className="periodComment">
                <PrimaryButton
                    id={targetId}
                    style={{ color: 'white', minWidth: 50, width: 50 }}
                    iconProps={{ iconName: 'Comment' }}
                    className="periodCommentIcon"
                    onClick={() => {
                        setShowdetailsflyout(true);
                    }}
                    disabled={props.disabled || (props.commentDisabled && (cellcomment == '' || cellcomment == undefined || cellcomment == null))}
                />
            </div>
            {showdetailsflyout && (
                <Callout
                    target={`#${targetId}`}
                    className={styles.callout}
                    ariaLabelledBy="{labelId}"
                    ariaDescribedBy="{descriptionId}"
                    role="alertdialog"
                    gapSpace={0}
                    directionalHint={DirectionalHint.leftBottomEdge}
                    onDismiss={() => {
                        setShowdetailsflyout(false);
                    }}
                    setInitialFocus
                    dismissOnTargetClick={true}
                >
                    <div className={styles.header}>
                        <Text className={styles.title}>{props.labelText}</Text>
                    </div>
                    <div className={styles.inner}>
                        <TextField
                            value={cellcomment}
                            disabled={props.commentDisabled}
                            onChange={
                                props.commentDisabled
                                    ? () => {}
                                    : (e, v) => {
                                          setComment(v);
                                          props.onUpdated(v);
                                      }
                            }
                            multiline
                            autoAdjustHeight
                        />
                    </div>
                </Callout>
            )}
        </>
    );
}

export default ApproverCommentCell;

const getStyles = makeStyles(theme => ({
    callout: {
        maxWidth: 800,
        minWidth: 300,
    },
    header: {
        padding: '18px 24px 12px',
        color: theme.semanticColors.bodyText,
    },
    title: [
        theme.fonts.mediumPlus,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
        },
    ],
    inner: {
        height: '100%',
        padding: '0 24px 20px',
    },
}));
