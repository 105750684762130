import { TimeUser } from '../../../../api/generated/data-contracts';

export const isUserChanged = (originalUser: TimeUser, currentUser: TimeUser) => {
    if (currentUser.userNumber !== originalUser.userNumber) {
        return true;
    }
    return false;
};

export interface IUpdateUserProps {
    user: TimeUser;
}

export const ResourceListColumns = () => {
    return [
        {
            key: 'name',
            name: 'Name',
            isRowHeader: true,
            fieldName: 'name',
            minWidth: 175,
            maxWidth: 300,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
        },
        {
            key: 'email',
            name: 'E-Mail',
            fieldName: 'email',
            minWidth: 175,
            maxWidth: 300,
            isPadded: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
        },
        {
            key: 'principalName',
            name: 'User Principal Name',
            fieldName: 'principalName',
            minWidth: 175,
            maxWidth: 300,
            isResizable: true,
            isPadded: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
        },
        {
            key: 'userNumber',
            name: 'User Number',
            fieldName: 'userNumber',
            minWidth: 175,
            maxWidth: 300,
            isResizable: true,
            isPadded: false,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
        },
        {
            key: 'update',
            name: 'Update User',
            fieldName: 'update',
            minWidth: 150,
            isResizable: true,
            isPadded: false,
        },
    ];
};
