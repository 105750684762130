import * as React from "react";
// import { useTheme } from "@fluentui/react-theme-provider";
import { mobileConstants } from "./mobileConstants";
import { useUiContext } from "../components/Contexts/UiContext";
import { useTheme } from "@fluentui/react";

export const TimeOpenIconMobile: React.FC = (props) => {
    const uiCtx = useUiContext();
    const theme = useTheme();

    return (
        <svg
            width={mobileConstants.topbarSvgSize}
            height={mobileConstants.topbarSvgSize}
            viewBox="0 0 80 80"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit={2}
            {...props}
        >
            <path
                d="M40 7.171c19.65 0 35.603 15.953 35.603 35.603 0 19.65-15.953 35.603-35.603 35.603-19.65 0-35.603-15.953-35.603-35.603C4.397 23.124 20.35 7.171 40 7.171zm0 7.756c15.37 0 27.847 12.478 27.847 27.847 0 15.37-12.478 27.847-27.847 27.847-15.369 0-27.847-12.478-27.847-27.847 0-15.369 12.478-27.847 27.847-27.847z"
                fill={uiCtx.isTeams ? "rgba(255, 255, 255, 0.9)" : theme.semanticColors.bodyText}
                // fill={theme.semanticColors.bodySubtext}
                // fill="#0f447b"
            />
            <path
                d="M40.764 39.848c-.38 0-.739.066-1.09.157L25.38 25.552a2.136 2.136 0 00-3.014-.021v.002a2.1 2.1 0 00-.022 2.98l14.32 14.503a4.293 4.293 0 00-.158 1.09 4.257 4.257 0 004.258 4.257 4.257 4.257 0 004.258-4.257 4.257 4.257 0 00-4.258-4.258z"
                // fill={theme.semanticColors.disabledBodyText}
                fill={uiCtx.isTeams ? "rgba(255, 255, 255, 0.7)" : theme.semanticColors.disabledBodyText}
                // fill="#bfbebf"
                fillRule="nonzero"
            />
            <path
                d="M59.086 9.404a1.496 1.496 0 00-.719-1.99c-1.099-.516-2.64-1.24-3.74-1.755a1.496 1.496 0 00-1.99.719v.001a1.496 1.496 0 00.718 1.99l3.74 1.755c.748.351 1.64.03 1.99-.718l.001-.002zM44.97 3.723a2.1 2.1 0 00-2.1-2.1h-5.74a2.1 2.1 0 100 4.2h5.74a2.1 2.1 0 002.1-2.1z"
                fill={uiCtx.isTeams ? "rgba(255, 255, 255, 0.9)" : theme.semanticColors.bodyText}
                // fill={theme.semanticColors.bodySubtext}
                // fill="#0f447b"
            />
        </svg>
    )
};

