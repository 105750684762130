import React from 'react';

// The type for events in react are a bit confusing hence the type constraint
export function stopPropagation<Event extends { stopPropagation: () => void }>(e: Event) {
    e.stopPropagation();
}

export function preventDefault<Event extends { preventDefault: () => void }>(e: Event) {
    e.preventDefault();
}

export function killEvent<Event extends { stopPropagation: () => void; preventDefault: () => void }>(e: Event) {
    stopPropagation(e);
    preventDefault(e);
}

export const killEventProps = Object.freeze({
    onClick: killEvent,
    onMouseMove: killEvent,
    onKeyUp: killEvent,
});

export function disableAllSpellchecks(e: React.FormEvent<any>) {
    const { target } = e;
    if (target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement) {
        target.setAttribute('spellcheck', 'false');
        // Don't remember passwords
        // const isPasswordField = target.getAttribute("type") === "password";
        // if (isPasswordField) {
        //     target.setAttribute("autocomplete", "off");
        // }
    }
}

export function disableAllAutoComplete(e: React.FormEvent<any>) {
    const { target } = e;
    if (target instanceof HTMLInputElement || target instanceof HTMLTextAreaElement) {
        target.setAttribute('autocomplete', 'off');
    }
}

export const disableAllSpellchecksProps = Object.freeze({
    onInput: disableAllSpellchecks,
});

export const disableAllAutoCompleteProps = Object.freeze({
    onInput: disableAllAutoComplete,
});

export const disableAllSpellchecksAndAutoCompleteProp = Object.freeze({
    onInput: mergeEventHandlers(disableAllSpellchecks, disableAllAutoComplete),
});

export function mergeEventHandlers(...handlers: ((e: React.FormEvent<any>) => void)[]) {
    return (e: React.FormEvent<any>) => {
        handlers.forEach(handler => handler(e));
    };
}
