import { TimeEntry } from '../../../api/generated/data-contracts';
import { validationRules } from '../../validationRules';
import { ValidateResponse } from '../../Validator';
import { NoMoreThenXHoursConfigValues } from './NoMoreThenXHoursConfig';

interface NoMoreThenXHoursProps {
    timeEntries: TimeEntry[];
    [key: string]: any;
}

export const NoMoreThenXHours = (props: NoMoreThenXHoursProps): ValidateResponse => {
    let maxHours = props[NoMoreThenXHoursConfigValues.MaxHours] || 38;

    let sum = props.timeEntries.reduce((total, val) => (total += val.hours), 0);

    let valid = sum <= maxHours;

    let response: ValidateResponse = {
        reason: sum > maxHours ? `It is not allowed to report more than ${maxHours} hours per period` : null,
        valid: valid,
        name: validationRules.NoMoreThenXHours,
        rejected: !valid,
    };

    return response;
};
