import { useRowMetaContext } from '../../../context/RowMetaContext';
import { RowHeader } from './components/RowHeader';
import { Dropdown, useTheme } from '@fluentui/react';
import { PseudoRow, isApprovalRow } from '../../../../../../../_grid/grid2';
import { useMemo } from 'react';
import { useGridLayoutContext } from '../../../context/LayoutProvider';
import { useTimeUIConfig } from '../../../../../../../hooks/useTimeUIConfig';

export const GroupingRow = ({ row }: { row: PseudoRow }) => {
    const { subrowFamily } = useRowMetaContext();
    const missingApprovalCount = useMemo(() => subrowFamily.filter(isApprovalRow).filter(row => row.approvalState !== 'Approved').length, [subrowFamily]);
    const theme = useTheme();
    const { gridRowStyles } = useGridLayoutContext();
    const { config } = useTimeUIConfig();
    return (
        <div style={gridRowStyles}>
            <RowHeader />
            <HoursColumn />
            {missingApprovalCount === 0 && row.type === 'project' && (
                <>
                    <div />
                    {config?.approval?.projectManagerApproval?.useRates && (
                        //
                        <div>{/* rate column */}</div>
                    )}
                    <div />
                    {config?.approval?.projectManagerApproval?.useRates && (
                        //
                        <div>{/* activiry column */}</div>
                    )}
                    <div>
                        <Dropdown
                            options={[{ key: 1, text: 'Approved' }]}
                            disabled
                            selectedKey={1}
                            styles={{ title: { backgroundColor: theme.palette.green } }}
                            onRenderCaretDown={() => null}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

const HoursColumn = () => {
    const { row } = useRowMetaContext();
    const hours = useMemo(() => row.timeEntry.hours.toNumber(), [row.timeEntry.hours]);
    return <div>{hours}</div>;
};
