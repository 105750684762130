import { IUiContext } from '../../../components/Contexts/UiContext';
import { MathToFixed } from '../../../components/Utils';
import { TimeEntry } from '../../../api/generated/data-contracts';
import User from '../../../Model/User';
import { validationRules } from '../../validationRules';
import { ValidateResponse } from '../../Validator';
import { MaxPercentOfCapacityConfigValues } from './MaxPercentOfCapacityConfig';

interface MaxPercentOfCapacityProps {
    timeEntries: TimeEntry[];
    uiContext: IUiContext;
    [key: string]: any;
}

export const MaxPercentOfCapacity = (props: MaxPercentOfCapacityProps): ValidateResponse => {
    const maxPercent = props[MaxPercentOfCapacityConfigValues.MaxPercent] || 100;

    const userReportingPeriod = props.uiContext.selectedTimePeriod;

    let workingDays: Date[] = [];

    function addDays(dateToAdd) {
        const date = new Date(dateToAdd);
        date.setDate(date.getDate() + 1);
        return date;
    }

    function getDates(startDate: Date, endDate: Date) {
        let currentDate: Date = startDate;
        while (currentDate <= endDate) {
            workingDays.push(currentDate);
            currentDate = addDays(currentDate);
        }
    }

    getDates(userReportingPeriod.start, userReportingPeriod.finish);
    workingDays = workingDays.filter(d => isWorkDay(d));

    const periodAvailability = props.uiContext.user.user.availability;
    const totalCapacity = workingDays.length * periodAvailability;

    const totalReported = MathToFixed(
        props.timeEntries.reduce((sum, current) => sum + current.hours, 0),
        2,
    );

    const percentRegistered = (totalReported / totalCapacity) * 100;

    const valid = percentRegistered <= maxPercent;

    const response: ValidateResponse = {
        reason: !valid ? `You cannot report more than ${maxPercent}% of capacity per period` : null,
        valid: valid,
        name: validationRules.MaxPercentOfCapacity,
        rejected: !valid,
    };

    return response;
};

const isWorkDay = (date: Date): boolean => {
    return date.toLocaleDateString('en-US', { weekday: 'long' }) !== 'Saturday' || date.toLocaleDateString('en-US', { weekday: 'long' }) !== 'Sunday';
};
