import { useCallback, useMemo } from 'react';
import { TOP_HEADERS_HEIGHT } from '../CONSTANTS';
import { IconButton, Stack, Text, useTheme } from '@fluentui/react';
// import { ExpandedRows, isPseudoRow, useGridExpandedRowsState } from '../../../../../_grid/grid';
import { useProjectApprovalData } from '../context/ProjectApprovalDataContext';
import { ExpandedRows, isPseudoRow, useApprovalStore } from '../../../../../_grid/grid2';
import shallow from 'zustand/shallow';
import { useGridLayoutContext } from '../context/LayoutProvider';
import { useTimeUIConfig } from '../../../../../hooks/useTimeUIConfig';

export const Headers = ({ start }: { start: number }) => {
    const theme = useTheme();
    const { totalWidth, gridRowStyles } = useGridLayoutContext();
    const styles = useMemo(() => {
        return {
            ...gridRowStyles,
            position: 'sticky',
            transform: `translateY(${start}px)`,
            height: TOP_HEADERS_HEIGHT,
            top: 0,
            left: 0,
            boxSizing: 'border-box',
            backgroundColor: theme.semanticColors.bodyBackground,
            zIndex: 9999,
            alignItems: 'center',
            borderBottom: `1px solid ${theme.semanticColors.disabledBackground}`,
            width: totalWidth,
        } as React.CSSProperties;
    }, [gridRowStyles, start, theme.semanticColors.bodyBackground, theme.semanticColors.disabledBackground, totalWidth]);

    const { allRows } = useProjectApprovalData();
    const [expandedState, setExpandedState] = useApprovalStore(store => [store.expandedRows, store.setExpandedRows] as const, shallow);

    const checkState = useMemo(() => {
        const subrowIds = allRows.filter(isPseudoRow).map(row => row.id);
        if (subrowIds.length && subrowIds.every(id => expandedState[id])) {
            return false;
        }
        return true;
    }, [allRows, expandedState]);

    const onChange = useCallback(() => {
        if (checkState) {
            setExpandedState(
                allRows.reduce((newState, row) => {
                    newState[row.id] = true;
                    return newState;
                }, {} as ExpandedRows),
            );
        } else {
            setExpandedState(
                allRows.reduce((newState, row) => {
                    newState[row.id] = false;
                    return newState;
                }, {} as ExpandedRows),
            );
        }
    }, [setExpandedState, allRows, checkState]);

    const { config } = useTimeUIConfig();

    return (
        <div style={styles}>
            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                <IconButton
                    iconProps={{
                        iconName: checkState ? 'ChevronDown' : 'ChevronUp',
                        styles: { root: { color: theme.semanticColors.bodyText } },
                    }}
                    onClick={onChange}
                />
                <Text>
                    <strong>Name/Task Name</strong>
                </Text>
            </Stack>
            <Text>
                <strong>Hours</strong>
            </Text>
            <Text>
                <strong>Date</strong>
            </Text>
            {config?.approval?.projectManagerApproval?.useRates && (
                <Text>
                    <strong>Rate</strong>
                </Text>
            )}
            <Text>
                <strong>Comment</strong>
            </Text>
            {config?.approval?.projectManagerApproval?.useRates && (
                <Text>
                    <strong>Activity</strong>
                </Text>
            )}
            <Text>
                <strong>Status</strong>
            </Text>
        </div>
    );
};
