import { IUiContext } from '../../components/Contexts/UiContext';
import { validationRules } from '../validationRules';
import { ValidateResponse } from '../Validator';

interface NoCellErrorsProps {
    uiContext: IUiContext;
}

export const NoCellErrors = (props: NoCellErrorsProps): ValidateResponse => {
    let valid = true;

    if (props.uiContext.cellsWithError.length > 0) {
        valid = false;
    }

    let response: ValidateResponse = {
        reason: !valid ? `Timesheet must not contain cells with errors. Please review the cells with red borders below.` : null,
        valid: valid,
        name: validationRules.NoCellErrors,
        rejected: !valid,
    };

    return response;
};
