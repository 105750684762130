import { TimeEntryApprovalDto } from '../../api/generated/data-contracts';
import Big from 'big.js';
import { TimeEntryApprovalDtoState, PseudoRow, TimeRow } from '../grid2';

export const makeRow = (approval: TimeEntryApprovalDto): TimeEntryApprovalDtoState => ({
    id: approval.id,
    // name: 'Time entry: ' + approval.task.name,
    name: approval.task.name,
    ...approval,
    // The grouping is user specific
    parentId: approval.task.id + approval.user.id,
    timeEntry: { ...approval.timeEntry, hours: new Big(String(approval.timeEntry.hours)) },
    type: 'approval',
});

const addRowTimeEntries = (row1: PseudoRow | TimeEntryApprovalDtoState, row2: PseudoRow | TimeEntryApprovalDtoState) => {
    return row1.timeEntry.hours.add(row2.timeEntry.hours);
};

export const buildGridRows = (approvals: TimeEntryApprovalDto[]) => {
    const rows: TimeRow[] = [];

    const weaveRows = (start: number, ...newRows: TimeRow[]) => {
        rows.splice(start, 0, ...newRows);
        return start + newRows.length - 1;
    };

    const getProjectRowIdx = (approval: TimeEntryApprovalDtoState): number => {
        const existingRowIndex = rows.findIndex(row => row.id === approval.project.id);
        if (existingRowIndex !== -1) {
            const existingRow = rows[existingRowIndex] as PseudoRow;
            existingRow.timeEntry.hours = addRowTimeEntries(existingRow, approval);
            return existingRowIndex;
        }
        const row: PseudoRow = {
            id: approval.project.id,
            name: approval.project.name,
            timeEntry: { ...approval.timeEntry },
            pseudoRow: true,
            type: 'project',
            project: approval.project,
            task: approval.task,
        };
        rows.push(row);
        return rows.length - 1;
    };

    const getUserRowIdx = (approval: TimeEntryApprovalDtoState, parentIdx: number): number => {
        const parentId = rows[parentIdx].id;
        const existingRowIndex = rows.findIndex(row => row.id === approval.user.id + parentId);
        if (existingRowIndex !== -1) {
            const existingRow = rows[existingRowIndex] as PseudoRow;
            existingRow.timeEntry.hours = addRowTimeEntries(existingRow, approval);
            return existingRowIndex;
        }
        const row: PseudoRow = {
            id: approval.user.id + parentId,
            name: approval.user.name,
            timeEntry: { ...approval.timeEntry },
            parentId,
            pseudoRow: true,
            type: 'user',
            project: approval.project,
            task: approval.task,
        };
        const insertionPoint = parentIdx + 1;
        return weaveRows(insertionPoint, row);
    };

    const getTaskGroupRowIdx = (approval: TimeEntryApprovalDtoState, parentIdx: number) => {
        // Find the user specific row
        const existingRowIndex = rows.findIndex(row => row.id === approval.task.id + approval.user.id);
        if (existingRowIndex !== -1) {
            const existingRow = rows[existingRowIndex] as PseudoRow;
            existingRow.timeEntry.hours = addRowTimeEntries(existingRow, approval);
            return existingRowIndex;
        }
        const parentId = rows[parentIdx].id;
        const row: PseudoRow = {
            // Make the row user specific
            id: approval.task.id + approval.user.id,
            name: approval.task.name,
            timeEntry: { ...approval.timeEntry },
            parentId,
            pseudoRow: true,
            type: 'group',
            project: approval.project,
            task: approval.task,
        };
        const insertionPoint = parentIdx + 1;
        return weaveRows(insertionPoint, row);
    };

    // Build relations
    approvals.forEach(approval => {
        const row = makeRow(approval);
        const projectRowIdx = getProjectRowIdx(row);
        const userRowIdx = getUserRowIdx(row, projectRowIdx);
        const taskGroupRowIdx = getTaskGroupRowIdx(row, userRowIdx);
        rows.splice(taskGroupRowIdx + 1, 0, row);
        // weaveRows(taskGroupRowIdx + 1, row)
    });

    return rows;
};
