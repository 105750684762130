import { PrimaryButton, Stack } from '@fluentui/react';
import { FC, useEffect, useState } from 'react';
import { TimeSpinner } from '../../../../../_components/Loader/TimeSpinner';
import { useStore } from '../../../../../store/useStore';
import { IUpdateUserProps, isUserChanged } from '../resourceHelpers';
import { useUpdateUser } from '../hooks/useUpdateUser';

export const UpdateUser: FC<IUpdateUserProps> = ({ user }) => {
    const [userIsChanged, setUserIsChanged] = useState<boolean>(false);

    useEffect(() => {
        const originalUser = useStore.getState().originalUsers.find(_ => _.id === user.id);
        setUserIsChanged(isUserChanged(originalUser, user));
    }, [user]);

    const { mutateUpdateUser, isLoadingUpdateUserMutation } = useUpdateUser();

    return (
        <Stack tokens={{ childrenGap: 20 }}>
            {!isLoadingUpdateUserMutation ? (
                <PrimaryButton
                    style={!userIsChanged ? { color: 'lightgray', cursor: 'no-drop' } : { cursor: 'pointer' }}
                    disabled={!userIsChanged}
                    text="Update"
                    onClick={() => mutateUpdateUser(user)}
                ></PrimaryButton>
            ) : (
                <PrimaryButton text="Updating">
                    <TimeSpinner> </TimeSpinner>
                </PrimaryButton>
            )}
        </Stack>
    );
};
