import React, { Fragment, useEffect, useState } from 'react';
import { Stack, Label, ILabelStyles, Spinner, Separator, IStackStyles } from '@fluentui/react';
import { useUiContext } from '../components/Contexts/UiContext';

const loadingStackStyle: IStackStyles = { root: { height: '600px' } };

const labelStyles: ILabelStyles = {
    root: { marginTop: 10 },
};

const spinnerStyles = {
    circle: {
        height: 75,
        width: 75,
        borderWidth: 5,
    },
    label: {
        'font-size': 16,
    },
};

function HelpVideos() {
    const [loadingcomplete, setLoadingcomplete] = useState<boolean>(false);
    const [videos, setVideos] = useState<any[]>([]);

    const uiCtx = useUiContext();

    useEffect(() => {
        uiCtx.timeApi.getVideos().then(json => {
            const allvideos: any[] = [];
            let anyvidsfound: boolean = false;
            json.map((vid: any, index: number) => {
                allvideos.push({ header: vid.header, description: vid.description, url: vid.url, source: vid.source, order: vid.order });
                anyvidsfound = true;
            });
            if (!anyvidsfound) {
                allvideos.push({
                    header: 'Video',
                    description: 'Unsure on how to use Time for Teams. Please watch the video below to go through Time for Teams step by step.',
                    url: 'https://www.youtube.com/embed/UC9nlBCC3wc',
                    source: 'youtube',
                    order: 1,
                });
            }
            const sortedvideos: any[] = (allvideos as any[]).sort((a: any, b: any) => {
                return a.order < b.order ? -1 : 1;
            });
            setVideos(sortedvideos);
            setLoadingcomplete(true);
        });
    }, []);

    return (
        <div style={{ overflowY: 'auto', height: 'calc(100vh - 100px)' }}>
            {!loadingcomplete ? (
                <Stack verticalAlign="center" horizontalAlign="center" styles={loadingStackStyle}>
                    <Spinner styles={spinnerStyles} label="Getting help..." />
                </Stack>
            ) : (
                <Stack horizontalAlign="center">
                    {videos.map((vid, index) => {
                        return (
                            <Fragment key={index}>
                                <br />
                                <h2>{vid.header}</h2>
                                <Separator styles={{ root: { width: '100%' } }} />
                                <Label styles={labelStyles}>{vid.description}</Label>
                                {vid.source === 'msstream' || vid.source === 'youtube' ? (
                                    <iframe width="1248" height="702" src={vid.url} allowFullScreen={true} style={{ border: 'none' }}></iframe>
                                ) : (
                                    <h3>Not supported format</h3>
                                )}
                            </Fragment>
                        );
                    })}
                </Stack>
            )}
        </div>
    );
}

export default HelpVideos;
