import React, { useMemo } from "react";
import { useRef } from "react";
import { Text, Stack, AnimationStyles, makeStyles } from "@fluentui/react";
import { mobileConstants } from "../../../../mobileConstants";
import { valueStyles } from "../../../../sharedStyles";
import { IMyTimeViewTask } from "../../../../mobileTypes";
import { useMobile } from "../../../../useMobile";
import { useUiContext } from "../../../../../components/Contexts/UiContext";
import { convertHoursToProcent, getCapacityPrDay, parseNumber } from "../../../../../components/WeekOverview/Cell.helpers";
import { MathToFixed } from "../../../../../components/Utils";
import { useMobileContext } from "../../../../MobileContext";
import { formatNumberToDisplay, formatNumberToDisplayPercent } from "../../../../mobileUtils";

interface IProps extends IMyTimeViewTask {};

export const TaskRow: React.FC<IProps> = (props) => {
    const uiCtx = useUiContext();
    const mobCtx = useMobileContext();
    const totalValue = useMobile(state => state.totalValues?.[props.dateString]?.[props.projectId]?.[props.taskKey]);
    const classes = getStyles();

    const ref = useRef<any>();

    const value = useMemo(() => {
        if (!totalValue || Number.isNaN(Number(totalValue))) return "";

        if (mobCtx.showAsPercent) {
            return formatNumberToDisplayPercent(totalValue, mobCtx.selectedPeriodDays, uiCtx)
            // const procent = convertHoursToProcent(Number(totalValue), mobCtx.selectedPeriodDays.length, getCapacityPrDay(mobCtx.selectedPeriodDays, uiCtx));
            // return `${MathToFixed(parseNumber(procent.toString().replace(/\,/g, '.')), 1)}`;
        } else {
            return formatNumberToDisplay(totalValue, uiCtx);
            // return MathToFixed(parseNumber(totalValue.toString().replace(/\,/g, '.')), 2)
        }
    }, [totalValue, mobCtx.selectedPeriodDays, uiCtx.gridInputType]);

    return (
        <div
            ref={ref}
            id={`${props.taskKey}_${props.dateString}`}
            // id={props.taskKey}
            className={classes.root}
            onClick={() => useMobile.getState().setEditTask({ taskId: props.taskId, taskKey: props.taskKey, newSelectedDate: props.dayDate })}
            onAnimationEnd={() => {
                // remove isUpdated class after animation end
                if (!ref.current) return;
                if (ref.current.classList.contains("taskIsUpdated")) {
                    ref.current.classList.remove("taskIsUpdated");
                };
                if (ref.current.classList.contains("restartTaskIsUpdated")) {
                    ref.current.classList.remove("restartTaskIsUpdated");
                };
            }}
        >
            <div className={classes.container}>
                <Stack horizontal grow verticalAlign="center" className={ classes.titleContainer }>
                    <Text className={ classes.title }>
                        {props.taskName}
                    </Text>
                </Stack>
                <Text className={ classes.value }>
                    {/* { totalValue || "" } */}
                    {`${value}${(mobCtx.showAsPercent && value) ? " %" : ""}`}
                </Text>
            </div>
        </div>
    );
};

const getStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        height: mobileConstants.gridRowHeight,
        paddingLeft: 66,
        paddingRight: 16,
        backgroundColor: theme.semanticColors.bodyFrameBackground,
    },
    container: {
        ...AnimationStyles.fadeIn200,
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "100%",
    },
    titleContainer: {
        overflowX: "hidden",
        paddingRight: theme.spacing.s1,
    },
    title: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        minWidth: 100,
        ".isDarkmode &": {
            color: `${theme.semanticColors.bodySubtext} !important`,
        }
    },
    value: {
        ...valueStyles,
        ".isDarkmode &": {
            color: `${theme.semanticColors.bodySubtext} !important`,
        }
    },
}));