import { Stack, TextField } from '@fluentui/react';
import { FC } from 'react';
import { useStore } from '../../../../../store/useStore';
import { IUpdateUserProps } from '../resourceHelpers';

export const UpdateUserNumber: FC<IUpdateUserProps> = ({ user }) => {
    return (
        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }}>
            <TextField
                onChange={(e, v) => {
                    const currentUsers = useStore.getState().currentUsers;
                    useStore.getState().setCurrentUsers(
                        currentUsers.map(currentUser => ({
                            ...currentUser,
                            userNumber: currentUser.id === user.id ? v : currentUser.userNumber,
                        })),
                    );
                }}
                value={user.userNumber}
            ></TextField>
        </Stack>
    );
};
