import React, { useEffect, useState } from 'react';
import { Text } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import { Callout, Stack } from '@fluentui/react';
import { useUiContext } from '../Contexts/UiContext';
import { useId } from '@fluentui/react-hooks';
import '../../Styles/FilterFlyout.scss';

interface propsFilterFlyout {
    choices: string[];
    defaultchoice: string;
    children?: any;
    callback: Function;
}

function FilterFlyout(props: propsFilterFlyout) {
    const uiCtx = useUiContext();

    const [choices, setchoices] = useState<string[]>([]);
    const [selected, ssetselected] = useState<string>('');
    const [allselected, setallselected] = useState<string>('');
    const [floyoutclicked, setfloyoutclicked] = useState<boolean>(false);
    const targetId = useId();
    useEffect(() => {
        setallselected(props.defaultchoice);
    }, []);
    useEffect(() => {
        setchoices([...props.choices]);
        ssetselected(props.defaultchoice);
    }, [props.choices]);

    return (
        <>
            <span
                id={targetId}
                onClick={() => {
                    setfloyoutclicked(!floyoutclicked);
                }}
                style={{ cursor: 'pointer', userSelect: 'none' }}
            >
                {props.children}
            </span>
            {selected != allselected ? <Icon style={{ fontSize: '12px', marginLeft: '8px' }} iconName="FilterSolid" /> : <></>}
            {floyoutclicked && (
                <Callout style={{ backgroundColor: 'white' }} gapSpace={0} target={`#${targetId}`} onDismiss={() => setfloyoutclicked(!floyoutclicked)}>
                    <Stack>
                        {choices.map((v, i) => {
                            return (
                                <Text
                                    key={v}
                                    className="selectableitemstyle"
                                    onClick={e => {
                                        ssetselected(v);
                                        props.callback(v);
                                    }}
                                >
                                    <span className="selectableiteminnerstyle" style={{ fontWeight: selected == v ? 600 : 400 }}>
                                        {v}
                                    </span>
                                </Text>
                            );
                        })}
                    </Stack>
                </Callout>
            )}
        </>
    );
}
export default FilterFlyout;
