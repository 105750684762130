import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { TextField, Callout, FontWeights, Text, DirectionalHint, TooltipHost, FontIcon, makeStyles, useTheme } from '@fluentui/react';
import TimeRow from '../../Model/TimePeriodRowM';
import { GetNumberSep, MathToFixed, getEmptyGuid } from '../Utils';
import { useId } from '@fluentui/react-hooks';
import { constants } from '../../Styles/constants';
import PeriodDay from '../../Model/PeriodDay';
import { useCellCtx } from '../Contexts/CellContext';
import { useUiContext } from '../Contexts/UiContext';
import { parseNumber, convertHoursToProcent, getPeriodHours, convertProcentToHours, isValidNumber, getCapacityPrDay } from './Cell.helpers';
import { EndPointSolidIcon } from '@fluentui/react-icons-mdl2';
import { roundToNearestValueStep } from '../../Mobile/mobileUtils';
import { TimeEntry } from '../../api/generated/data-contracts';

interface propsCell {
    time: TimeRow;
    timeentries: TimeEntry[];
    // day: PeriodDay,
    perioddays: PeriodDay[];
    updated: Function;
    readonly: boolean;
    readonlyreason: string;
    propertyUpdated: string;
    timeLowerLimitConfig: string;
    cellDataNeedsSave: Function;
    reportPeriodId: string;
}

const CellWeek = React.forwardRef<any, propsCell>((props, ref) => {
    const theme = useTheme();
    const cellCtx = useCellCtx();
    const uiCtx = useUiContext();
    const styles = getStyles();
    const textFieldRef = useRef<any>();
    const targetId = useId();

    const [celltext, setCelltext] = useState('');
    const [cellFlagged, setCellFlagged] = useState<boolean>(props.timeentries[0]?.flagged || false);
    const [showFlagIcon, setShowFlagIcon] = useState<boolean>(props.timeentries[0]?.flagged || false);
    const [cellnumber, setCellnumber] = useState<number>(0);
    const [cellcomment, setComment] = useState<string>('');
    const [cellnumberlastsaved, setCellnumberlastsaved] = useState<number>(0);
    const [commentlastsaved, setCommentlastsaved] = useState<string>('');
    const [cellIsError, setCellIsError] = useState<string>('');
    const [showdetailsicon, setShowdetailsicon] = useState<boolean>(false);
    const [showdetailsflyout, setShowdetailsflyout] = useState<boolean>(false);
    const [textinfocus, setTextinfocus] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    const [anyChanges, setAnyChanges] = useState<boolean>(false);
    const [dataSaved, setdatasaved] = useState<boolean>(false);

    useEffect(() => {
        const hours = getPeriodHours(props.timeentries);
        const formattedCellValue = uiCtx.gridInputType === 'percent' ? convertHoursToProcent(hours, props.perioddays.length, getCapacityPrDay(props.perioddays, uiCtx)) : hours;
        const roundedCellValue = roundToNearestValueStep(formattedCellValue, uiCtx.timeLowerLimitConfig);
        const value = uiCtx.gridInputType === 'percent' ? convertProcentToHours(roundedCellValue, props.perioddays.length, getCapacityPrDay(props.perioddays, uiCtx)) : roundedCellValue;
        setCelltext(`${roundedCellValue}`);
        setCellnumber(value);
        setCellnumberlastsaved(value);

        const newComment = props.timeentries?.[0]?.comment || '';
        setComment(newComment);
        setCommentlastsaved(newComment);

        setLoaded(true);
    }, [props.timeentries, props.timeentries?.[0]]);

    useEffect(() => {
        // const hours = getPeriodHours(props.timeentries);
        // const value = uiCtx.gridInputType === "percent" ? convertHoursToProcent(hours, props.perioddays.length, getCapacityPrDay(props.perioddays, uiCtx)) : hours;
        // setCelltext(`${MathToFixed(parseNumber(value.toString().replace(/\,/g, '.')), 1)}`);
        const hours = getPeriodHours(props.timeentries);
        const formattedCellValue = uiCtx.gridInputType === 'percent' ? convertHoursToProcent(hours, props.perioddays.length, getCapacityPrDay(props.perioddays, uiCtx)) : hours;
        const roundedCellValue = roundToNearestValueStep(formattedCellValue, uiCtx.timeLowerLimitConfig);
        // const value = uiCtx.gridInputType === "percent" ? convertProcentToHours(roundedCellValue, props.perioddays.length, getCapacityPrDay(props.perioddays, uiCtx)) : roundedCellValue;
        setCelltext(`${roundedCellValue}`);
    }, [props.propertyUpdated]);

    useEffect(() => {
        if (showdetailsflyout === false && textinfocus === false && loaded === true) {
            console.debug('Cell is error changed: ' + cellIsError);

            const formattedCellValue = uiCtx.gridInputType === 'percent' ? convertHoursToProcent(cellnumber, props.perioddays.length, getCapacityPrDay(props.perioddays, uiCtx)) : cellnumber;
            const roundedCellValue = roundToNearestValueStep(formattedCellValue, uiCtx.timeLowerLimitConfig);
            const hours = uiCtx.gridInputType === 'percent' ? convertProcentToHours(roundedCellValue, props.perioddays.length, getCapacityPrDay(props.perioddays, uiCtx)) : roundedCellValue;
            if (cellIsError == '' && (hours !== cellnumberlastsaved || cellcomment !== commentlastsaved)) {
                setdatasaved(true);
                saveData(hours, cellcomment, cellnumberlastsaved, cellFlagged);
                setAnyChanges(false);
            } else {
                const formattedCellValue =
                    uiCtx.gridInputType === 'percent' ? convertHoursToProcent(cellnumberlastsaved, props.perioddays.length, getCapacityPrDay(props.perioddays, uiCtx)) : cellnumberlastsaved;
                const roundedCellValue = roundToNearestValueStep(formattedCellValue, uiCtx.timeLowerLimitConfig);
                const value = uiCtx.gridInputType === 'percent' ? convertProcentToHours(roundedCellValue, props.perioddays.length, getCapacityPrDay(props.perioddays, uiCtx)) : roundedCellValue;
                setCelltext(`${roundedCellValue}`);
                setCellnumber(value);
                setCellnumberlastsaved(value);
            }
            props.cellDataNeedsSave(false);
        }
    }, [showdetailsflyout, textinfocus]);

    useEffect(() => {
        if (cellIsError == '') {
            // Check if ctx contains cell, if so, remove it
            var newArray = uiCtx.cellsWithError.filter(c => c != targetId);
            uiCtx.setCellsWithError(newArray);
        } else {
            //Check if ctx contains cell, if not, add it
            if (uiCtx.cellsWithError.filter(c => c == targetId).length < 1) {
                var newArray = uiCtx.cellsWithError;
                newArray.push(targetId);
                uiCtx.setCellsWithError(newArray);
            }
        }
    }, [cellIsError]);

    useEffect(() => {
        if (textinfocus) {
            textFieldRef.current.select();
        }
    }, [textinfocus]);

    const onCellUpdated = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        if (isAllowedKey(e)) {
            setAnyChanges(true);
            const hours = uiCtx.gridInputType === 'percent' ? convertProcentToHours(Number(text), props.perioddays.length, getCapacityPrDay(props.perioddays, uiCtx)) : text;

            isValid(GetNumberSep() === ',' ? text.replace(/\./g, ',') : text.replace(/\,/g, '.'));
            setCelltext(GetNumberSep() === ',' ? text.replace(/\./g, ',') : text.replace(/\,/g, '.'));
            // setCellnumber(MathToFixed(parseNumber(hours.toString().replace(/\,/g, '.')), 2));
            setCellnumber(parseNumber(hours.toString().replace(/\,/g, '.')));
        }
    };

    const onCellUpdatedTest = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        if (isAllowedKey(e)) {
            setAnyChanges(true);
            const formattedValue = Number(text.replace(/\,/g, '.'));
            if (!Number.isNaN(formattedValue)) {
                const newValue = roundToNearestValueStep(formattedValue, uiCtx.timeLowerLimitConfig);
                const newValueAsText = `${GetNumberSep() === ',' ? `${newValue}`.replace(/\./g, ',') : `${newValue}`.replace(/\,/g, '.')}`;
                isValid(newValueAsText);
                setCelltext(newValueAsText);
                setCellnumber(newValue);
            }
        }
    };

    const onCommentUpdated = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        setComment(text);
    };

    const onBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        setTimeout(() => {
            setTextinfocus(false);
            setShowdetailsicon(false);
            setShowFlagIcon(cellFlagged);
        }, 500);
    };

    const isValid = (value: string) => {
        const parsed = value.replace(/,/g, '.');
        const newVal = uiCtx.gridInputType === 'percent' ? `${convertProcentToHours(Number(parsed), props.perioddays.length, getCapacityPrDay(props.perioddays, uiCtx))}` : parsed;

        if (isValidNumber(newVal)) {
            let newValNum = parseFloat(newVal);
            if (newValNum > 24 * props.perioddays.length) {
                setCellIsError('Max 24 hours pr day');
                return '';
            }
            if (newValNum < 0) {
                setCellIsError('Negative numbers not allowed');
                return '';
            }
            // if (props.timeLowerLimitConfig != '' && newValNum != 0 && !isNaN(newValNum)) {
            //     if (parseFloat(newVal) < (parseFloat(props.timeLowerLimitConfig) * props.perioddays.length)) {
            //         if (uiCtx.gridInputType === "percent") {
            //             setCellIsError('Must be over ' + Format2DecimalValue(`${convertHoursToProcent(Number(Format2DecimalValue(props.timeLowerLimitConfig)), 1, getCapacityPrDay(props.perioddays, uiCtx))}`) + " %");
            //         } else {
            //             setCellIsError('Must be over ' + Format2DecimalValue(props.timeLowerLimitConfig));
            //         }
            //         // setCellIsError('Must be over ' + Format2DecimalValue(props.timeLowerLimitConfig));
            //         return "";
            //     }
            // }
            setCellIsError('');
            return '';
        } else {
            setCellIsError('Whoops, not a number');
            return '';
            // return " ";
        }
    };

    const onFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        props.cellDataNeedsSave(true);
        setShowdetailsicon(true);
        setShowFlagIcon(true);
        setTextinfocus(true);
    };

    const onIconClick = (ev: React.MouseEvent<HTMLElement, MouseEvent>): void => {
        setShowdetailsflyout(true);
        setShowdetailsicon(true);
        setShowFlagIcon(true);
    };

    const onFlagIconClick = () => {
        const newFlagValue = !cellFlagged;
        saveData(cellnumber, cellcomment, cellnumberlastsaved, newFlagValue);
    };

    const flyoutDismissed = (ev: any): void => {
        setShowdetailsflyout(false);
    };

    const focusCellIfExists = (cellKey: string) => {
        // If cellKey is defined, it was found in the Map containing all cells
        // and it can be focused
        if (cellKey) {
            const destinationCell = cellCtx.inputNodes.get(cellKey);
            destinationCell.setFocus();
        }
    };

    const getKeyForTargetDay = (currentCellKey: string, daysFromCurrent: number) => {
        let keys = [...cellCtx.inputNodes.keys()];
        const indexOfCurrent = keys.indexOf(currentCellKey);

        return keys[indexOfCurrent + daysFromCurrent];
    };

    const focusBelowCell = (currentRowId: any, currentDay: any) => {
        let keys = [...cellCtx.inputNodes.keys()];
        let highest = 0;

        keys.some(function (key: string) {
            // get day index of the current key - what is after the last underscore
            const dayIndex: number = +key.substring(key.lastIndexOf('_') + 1);

            if (dayIndex >= highest) {
                highest = dayIndex;
            } else {
                // we found the highest dayIndex - return true to break .some() loop
                return true;
            }
        });

        const oneWeekAheadKey = getKeyForTargetDay(`${currentRowId}_${currentDay}`, highest + 1);
        focusCellIfExists(oneWeekAheadKey);
    };

    const focusAboveCell = (currentRowId: any, currentDay: any) => {
        let keys = [...cellCtx.inputNodes.keys()];
        const daysInPeriod = getDaysInWeek(keys);
        const oneWeekBeforeKey = getKeyForTargetDay(`${currentRowId}_${currentDay}`, -(daysInPeriod + 1));
        focusCellIfExists(oneWeekBeforeKey);
    };

    const onKeyDown = (event: any): void => {
        let workTypeId = props.time.workType == null || props.time.workType == undefined ? getEmptyGuid() : props.time.workType.id;
        const rowId = `${props.time.taskId}_${workTypeId}`;

        switch (event.keyCode) {
            case 13:
                // Enter key
                focusBelowCell(rowId, 0);
                break;

            case 37:
                // Left arrow
                // focusPreviousCell(rowId, props.day.id);
                focusAboveCell(rowId, 0);
                break;

            case 38:
                // Up arrow
                focusAboveCell(rowId, 0);
                break;

            case 39:
                // Right arrow
                // focusNextCell(rowId, props.day.id);
                focusBelowCell(rowId, 0);
                break;

            case 40:
                // Down arrow
                focusBelowCell(rowId, 0);
                break;

            default:
                return;
        }

        if (cellnumber !== cellnumberlastsaved || cellcomment !== commentlastsaved) {
            if (cellIsError == '') {
                setdatasaved(true);
                saveData(cellnumber, cellcomment, cellnumberlastsaved, cellFlagged);
                setAnyChanges(false);
            }
        }
    };

    const saveData = (hours: number, comment: string, previousHours: number, isFlagged: boolean): void => {
        let hoursToBeSaved = hours;
        let commentToBeSaved = comment;
        const flagged = previousHours >= 0 && hoursToBeSaved === 0 ? false : isFlagged;

        if (!showdetailsflyout) {
            props.updated(
                props.time,
                props.perioddays,
                props.timeentries?.[0],
                MathToFixed(hoursToBeSaved / props.perioddays.length, 2) as number,
                hoursToBeSaved,
                commentToBeSaved,
                flagged,
                previousHours,
                () => {
                    setTimeout(() => {
                        setdatasaved(false);
                        setShowFlagIcon(flagged);
                        setCellFlagged(flagged);
                    }, 200);
                },
            );
            setCellnumberlastsaved(hoursToBeSaved);
            setCommentlastsaved(commentToBeSaved);
        }
    };

    useImperativeHandle(ref, () => ({
        setFocus: () => textFieldRef.current && textFieldRef.current.focus(),
    }));

    return (
        <div className={styles.root}>
            {uiCtx.allowCellComment && (showdetailsicon || showdetailsflyout || cellcomment !== '') ? (
                <FontIcon id={targetId} iconName="Comment" className={'dayDetailsIcon' + (props.readonly ? ' dayDetailsIconDisabled' : '')} onClick={onIconClick} />
            ) : null}
            <TooltipHost content={cellIsError !== '' ? cellIsError : props.readonly ? props.readonlyreason : ''} closeDelay={250}>
                <TextField
                    componentRef={textFieldRef}
                    className="cellInput"
                    value={`${celltext !== '0' && celltext !== '' ? celltext : ''}${
                        uiCtx.gridInputType === 'percent' ? (!celltext || celltext === '0' || celltext === '' || textinfocus ? '' : ' %') : ''
                    }`}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onChange={onCellUpdated}
                    onKeyDown={onKeyDown}
                    disabled={props.readonly}
                    autoComplete="off"
                    style={{ textAlign: 'center' }}
                    styles={{
                        root: {
                            // margin: "0 auto",
                            // maxWidth: constants.cellInputMaxWidth,
                        },
                        fieldGroup: {
                            borderColor: `${cellIsError != '' ? 'rgba(155,42,49,0.75)' : 'rgba(0,0,0,0)'}`,
                            backgroundColor: `${dataSaved ? 'rgba(16, 124, 16, 0.6)!important' : 'rgba(0,0,0,0)'}`,
                            borderWidth: '2px',
                            '&:hover': {
                                borderColor: `${cellIsError != '' ? 'rgba(155,42,49,1)' : 'rgba(0,0,0,0)'}`,
                                backgroundColor: `${dataSaved ? 'rgba(16, 124, 16, 0.6)!important' : 'rgba(0,0,0,0)'}`,
                                // backgroundColor: theme.semanticColors.inputBackground,
                                // backgroundColor: workday ? theme.semanticColors.inputBackground : theme.palette.neutralLighterAlt,
                            },
                            '&::after': {
                                borderColor: `${cellIsError != '' ? 'rgba(155,42,49,1)' : 'rgba(96,97,170,1)'}`,
                            },
                        },
                        field: {
                            textAlign: 'right',
                            paddingRight: theme.spacing.s1,
                            paddingLeft: 7,
                        },
                    }}
                />
                {uiCtx.allowCellFlag && showFlagIcon ? (
                    <EndPointSolidIcon
                        id={targetId}
                        style={{ color: cellFlagged ? theme.palette.red : theme.palette.themeLight, cursor: props.readonly ? 'no-drop' : 'pointer' }}
                        onClick={() => {
                            if (!props.readonly) {
                                onFlagIconClick();
                            }
                        }}
                        className={'dayflagsIcon' + (props.readonly ? ' dayflagsIconDisabled' : '')}
                    />
                ) : null}
            </TooltipHost>

            {showdetailsflyout && (
                <Callout
                    className={styles.callout}
                    gapSpace={0}
                    target={`#${targetId}`}
                    directionalHint={DirectionalHint.bottomCenter}
                    onDismiss={flyoutDismissed}
                    setInitialFocus
                    dismissOnTargetClick={true}
                >
                    <div className={styles.header}>
                        <Text className={styles.title}>Comment</Text>
                    </div>
                    <div className={styles.inner}>
                        <TextField value={cellcomment} onChange={onCommentUpdated} multiline autoAdjustHeight disabled={props.readonly} />
                    </div>
                </Callout>
            )}
        </div>
    );
});

export default CellWeek;

const getStyles = makeStyles(theme => ({
    root: [
        'cellInner',
        {
            position: 'relative',
            height: '100%',
            width: '100%',
            maxWidth: constants.cellInputMaxWidthWeekOverview,
            margin: '0 auto',
            display: 'flex',
        },
    ],
    buttonArea: {
        verticalAlign: 'top',
        display: 'inline-block',
        textAlign: 'center',
        margin: '0 100px',
        minWidth: 130,
        height: 32,
    },
    callout: {
        maxWidth: 800,
        minWidth: 300,
    },
    header: {
        padding: '18px 24px 12px',
        color: theme.semanticColors.bodyText,
    },
    title: [
        theme.fonts.mediumPlus,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
        },
    ],
    inner: {
        height: '100%',
        padding: '0 24px 20px',
    },
    actions: {
        position: 'relative',
        marginTop: 20,
        width: '100%',
        whiteSpace: 'nowrap',
    },
    subtext: [
        theme.fonts.small,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
        },
    ],
    link: [
        theme.fonts.medium,
        {
            color: theme.palette.neutralPrimary,
        },
    ],
}));

function getDaysInWeek(keys: any[]) {
    let indexes: number[] = [];

    keys.forEach((key: string) => {
        // convert dayIndex to number
        const dayIndex: number = +key.substring(key.lastIndexOf('_') + 1);
        indexes.push(dayIndex);
    });

    // this indicates how many days we have in the period
    const daysInWeek = Math.max(...indexes);
    return daysInWeek;
}

export const isAllowedKey = (e: any) => {
    const allowed = '1234567890,.';

    if (allowed.includes(e.nativeEvent.data) || !e.nativeEvent.data) {
        return true;
    } else {
        return false;
    }
};
