//import { Stack, Text } from "@fluentui/react";
import React from "react";
import { useMemo } from "react";
// import { makeStyles } from "@fluentui/react-theme-provider";
import { MessageBar, MessageBarType, Label } from "@fluentui/react";
// import { mobileConstants } from "../mobileConstants";
import { useMobileContext } from "../MobileContext";
import { TimeState } from "../../Model/State";
import { ValidationErrors } from "../../Validation/Components/ValidationErrors";

export const TimeStateMessage: React.FC = () => {
    const mobCtx = useMobileContext();

    const Component = useMemo(() => {
        if (!mobCtx.selectedTimePeriod || !mobCtx.timeState) {
            return () => null;
        };
        
        if (mobCtx.selectedTimePeriod.isClosed) {
            return () => (
                <MessageBar
                    messageBarType={MessageBarType.info}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                    truncated={false}
                    overflowButtonAriaLabel="See more"
                >
                    <b>This period is closed.</b>
                </MessageBar>
            )
        } else {
            switch (mobCtx.timeState) {
                case TimeState.open: 
                    return mobCtx.validationErrors.length === 0
                    ? () => null
                    : () => (
                        <MessageBar
                            messageBarType={MessageBarType.warning}
                            isMultiline={false}
                            dismissButtonAriaLabel="Close"
                            truncated={false}
                            overflowButtonAriaLabel="See more"
                        >
                            <b>Timesheet is open with warning.</b> <i>Please review the following comments and submit the timesheet again: {mobCtx.validationErrors.length === 0 ? mobCtx.periodComment : <ValidationErrors validationResponses={mobCtx.validationErrors} />}</i>
                        </MessageBar>
                    );

                case TimeState.submitted: 
                    return () => (
                        <MessageBar
                            messageBarType={MessageBarType.info}
                            isMultiline={false}
                            dismissButtonAriaLabel="Close"
                            truncated={false}
                            overflowButtonAriaLabel="See more"
                        >
                            <b>Timesheet is submitted and are awaiting approval.</b>
                        </MessageBar>
                    );

                case TimeState.approved: 
                    return () => (
                        <MessageBar
                            messageBarType={MessageBarType.success}
                            isMultiline={false}
                            dismissButtonAriaLabel="Close"
                            truncated={false}
                            overflowButtonAriaLabel="See more"
                        >
                            <b>Timesheet is approved.</b>
                            {mobCtx.periodComment === "" ? <></> : <><Label>The following message was given with the approval.</Label> <h5>{mobCtx.periodComment}</h5></>}
                        </MessageBar>
                    );

                case TimeState.rejected: 
                    return () => (
                        <MessageBar
                            messageBarType={MessageBarType.blocked}
                            isMultiline={false}
                            dismissButtonAriaLabel="Close"
                            truncated={false}
                            overflowButtonAriaLabel="See more"
                        >
                            <b>Timesheet is rejected.</b> <Label>Please review the following comments and submit the timesheet again.</Label> {mobCtx.validationErrors.length === 0 ? <h5>{mobCtx.periodComment}</h5> : <ValidationErrors validationResponses={mobCtx.validationErrors} />}
                        </MessageBar>
                    );

                default: 
                    return () => null;
            };
        };
    }, [mobCtx.timeState, mobCtx.selectedTimePeriod, mobCtx.validationErrors, mobCtx.periodComment]);

    return <Component />;
    
};

// const getStyles = makeStyles(theme => ({
//     root: {
//         backgroundColor: theme.palette.themePrimary,
//         color: theme.palette.white,
//         height: mobileConstants.topbarHeight,
//         padding: `0 ${theme.spacing.s2}`,
//         boxShadow: theme.effects.elevation8,
//         // padding: `0 ${mobileConstants.sideSpacing / 2}px`,
//     },
//     headline: {
//         ...theme.fonts.xLarge,
//         // fontSize: 26,
//         // fontWeight: 500,
//         letterSpacing: '1px',
//         color: theme.palette.white,
//     },
//     backBtn: {
//         color: theme.palette.white,
//         height: "100%",
//         width: "100%",
//     },
//     submitBtn: {
//         color: theme.palette.white,
//         height: "100%",
//         width: "100%",
//     },
//     buttonContainer: {
//         height: mobileConstants.topbarHeight,
//         width: mobileConstants.topbarHeight,
//         padding: theme.spacing.s2,
//         "i": {
//             fontSize: 26,
//             verticalAlign: "center",
//             // fontWeight: 700
//         },
//     }
// }));

// const getStyles = makeStyles(theme => ({
//     root: {
//         backgroundColor: '#6061A4',
//         color: 'white',
//         height: '5vh',
//     },
//     headline: {
//         fontSize: '5vw',
//         // fontFamily: "Segoe UI",
//         fontWeight: '500',
//         letterSpacing: '0.3vw',
//         color: 'white',
//         marginTop: '0.5vh',
//     },
//     backBtn: {
//         fontSize: '5vw',
//         marginLeft: '5vw',
//         fontFamily: "Segoe UI",
//         color: 'white',
//         marginTop: '1.5vh',
//         transform: 'scale(3.5)',
//     },
//     submitBtn: {
//         color: 'white',
//         marginTop: '1.5vh',
//         transform: 'scale(3.5)',
//         marginRight: '5vw'
//     },
//     buttonContainer: {}
// }));
