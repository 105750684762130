import React, { FC, useRef } from 'react';
import { IFileUploadProps } from '../types/timeEntryTypes';
import { Stack, PrimaryButton } from '@fluentui/react';

export const FileUpload: FC<IFileUploadProps> = ({ setFileData: setFormData }) => {
    const inputRef = useRef(null);

    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files[0];
        setFormData(file);
    };

    const fileUpload = () => {
        inputRef.current.click();
    };

    return (
        <Stack tokens={{ childrenGap: 32 }} horizontalAlign="center">
            <h1>Important!</h1>
            <p>Only use this upload function if you know how the file structure must be</p>
            <PrimaryButton onClick={fileUpload} text="Upload File"></PrimaryButton>
            <input hidden ref={inputRef} type="file" accept="application/JSON" onChange={onFileInputChange} />
        </Stack>
    );
};
