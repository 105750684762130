import React, { useEffect, useState } from 'react';
import { useUiContext } from '../components/Contexts/UiContext';
import AdminCalendars from '../Admin/CalendarConfig/AdminCalendars';
import { Calendar } from '../Model/NewModels/Calendar';

function AppAdminCalendarConfig() {
    const uiCtx = useUiContext();

    const [calendars, setCalendars] = useState<Calendar[]>();

    useEffect(() => {
        uiCtx.timeApi.getCalendars().then(calendars => {
            setCalendars(calendars);
            uiCtx.setLoading(false);
        });
    }, []);

    return <>{calendars && <AdminCalendars calendars={calendars} />}</>;
}

export default AppAdminCalendarConfig;
