import { useState, useEffect, useMemo } from 'react';
import { useUiContext } from '../../../../components/Contexts/UiContext';
import utils, { IColumnProps, IConfigDictionaryProject, IExpandedComboBoxOption, ISectionProps, IValueData, configObj } from '../../utils/utils';
import { ComboBox, Spinner, Stack, TextField } from '@fluentui/react';

export const Projects = (props: ISectionProps) => {
    const config: IConfigDictionaryProject = useMemo(() => props.values[props.configKey], [props.values, props.configKey]);
    const configMappingObj = useMemo(() => configObj[props.configKey], [props.configKey]);

    const [projectStepAdded, setProjectStepAdded] = useState<boolean>(!(config.tableName === null || !config.tableName));

    const [fields, setFields] = useState<IExpandedComboBoxOption[]>();
    const uiCtx = useUiContext();

    useEffect(() => {
        if (!config.tableName) {
            return;
        }
        utils.getFields(config.tableName.key as string, uiCtx.timeApi).then(setFields);
    }, [config.tableName]);

    return (
        <Stack>
            <h1>Projects</h1>
            <>
                <ComboBox
                    label="Dataverse Project Table"
                    allowFreeform
                    autoComplete="on"
                    options={props.tables}
                    onChange={(e, opt, index, value) => {
                        if (opt) {
                            props.updateSteps(opt, props.configKey);
                            setProjectStepAdded(true);
                            props.updateValue('enabled', true, props.configKey);
                        } else if (!value) {
                            props.updateSteps(null, props.configKey);
                            setProjectStepAdded(false);
                            props.updateValue('enabled', false, props.configKey);
                        }
                    }}
                    selectedKey={config.tableName ? config.tableName.key : ''}
                />
                {config.tableName && !fields && <Spinner />}
                <>
                    <h5 style={{ marginTop: 5 }}>Columns:</h5>
                    <ProjectInputs
                        configMappingObj={configMappingObj}
                        updateValue={props.updateValue}
                        config={config}
                        fields={fields ?? []}
                        configKey={props.configKey}
                        stepAdded={projectStepAdded}
                    />
                </>
            </>
        </Stack>
    );
};

interface IProjectColumnProps extends IColumnProps {
    config: IConfigDictionaryProject;
}

const ProjectInputs = (props: IProjectColumnProps) => {
    const getErrorMessage = (fieldValue: IValueData) => {
        if (!props.stepAdded) {
            return '';
        }
        if (!fieldValue || props.fields.filter(_ => _.key === fieldValue.key).length === 0) {
            return 'Field is required';
        }
        return '';
    };

    return (
        <>
            <ComboBox
                label="Project Id *"
                allowFreeform
                autoComplete="on"
                options={props.fields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('idColumn', opt, props.configKey);
                    } else if (value === '') {
                        props.updateValue('idColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.stepAdded && props.config.idColumn ? props.config.idColumn.key : ''}
                errorMessage={getErrorMessage(props.config.idColumn)}
            />
            <ComboBox
                label="Project Name *"
                allowFreeform
                autoComplete="on"
                options={props.fields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('nameColumn', opt, props.configKey);
                    } else if (value === '') {
                        props.updateValue('nameColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.stepAdded && props.config.nameColumn ? props.config.nameColumn.key : ''}
                errorMessage={getErrorMessage(props.config.nameColumn)}
            />
            <ComboBox
                label="Metadata"
                allowFreeform
                autoComplete="on"
                options={props.fields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        console.log('dadasdad', opt);
                        props.updateValue('metadataColumn', opt, props.configKey);
                    } else if (value === '') {
                        props.updateValue('metadataColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.stepAdded && props.config.metadataColumn ? props.config.metadataColumn.key : ''}
            />
            {/* Add project manager */}
            <ComboBox
                label="Project Manager"
                allowFreeform
                autoComplete="on"
                options={props.fields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('managerColumn', opt, props.configKey);
                    } else if (value === '') {
                        props.updateValue('managerColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.stepAdded && props.config.managerColumn ? props.config.managerColumn.key : ''}
            />
            {/* Add project number */}
            <ComboBox
                label="Project Number"
                allowFreeform
                autoComplete="on"
                options={props.fields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('numberColumn', opt, props.configKey);
                    } else if (value === '') {
                        props.updateValue('numberColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.stepAdded && props.config.numberColumn ? props.config.numberColumn.key : ''}
            />
            <ComboBox
                label="Project Closed"
                allowFreeform
                autoComplete="on"
                options={props.fields}
                onChange={(e, opt, index, value) => {
                    if (opt) {
                        props.updateValue('closedColumn', opt, props.configKey);
                    } else if (value === '') {
                        props.updateValue('closedColumn', null, props.configKey);
                    }
                }}
                disabled={!props.stepAdded}
                selectedKey={props.stepAdded && props.config.closedColumn ? props.config.closedColumn.key : ''}
            />
            <TextField
                label={'Project Closed Compare Value'}
                onChange={(e, value) => props.updateValue('closedValue', value, props.configKey)}
                value={props.stepAdded ? props.config.closedValue : ''}
                disabled={!props.stepAdded}
            />
        </>
    );
};
