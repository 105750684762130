import { createTheme, ITheme } from '@fluentui/react';

const palette = {
    themePrimary: '#6061aa',
    themeLighterAlt: '#f7f7fc',
    themeLighter: '#e1e1f2',
    themeLight: '#c7c8e6',
    themeTertiary: '#9797cd',
    themeSecondary: '#6f70b5',
    themeDarkAlt: '#56579a',
    themeDark: '#494a82',
    themeDarker: '#363660',
    neutralLighterAlt: '#f8f8f8',
    neutralLighter: '#f4f4f4',
    // neutralLight: '#eaeaea',
    neutralLight: '#EDEBE9',
    neutralQuaternaryAlt: '#dadada',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c8c8',
    neutralTertiary: '#b6b0b0',
    neutralSecondary: '#9f9797',
    neutralPrimaryAlt: '#877f7f',
    neutralPrimary: '#282424',
    neutralDark: '#585151',
    black: '#403b3b',
    white: '#fff',
};

export const TeamsTheme: ITheme = createTheme({
    palette,
    semanticColors: {
        bodyStandoutBackground: palette.neutralLighterAlt,
        bodyBackground: 'rgb(245, 245, 245)',
        // bodyBackground: "#f3f2f1",
        defaultStateBackground: '#ffffff',
        buttonBackground: 'transparent',
        // buttonBackgroundHovered: '#bdbdbd', // er normalt slået til
        // buttonBackgroundPressed: '#a7a7a7', // er normalt slået til
        bodyText: '#292828',
        buttonText: '#252424',
        buttonTextPressed: '#252424',
        buttonTextHovered: '#252424',
        warningBackground: '#e6e6e6',
        buttonBorder: '#bdbdbd',
        variantBorder: '#e6e6e6',
    },
});
