import React from 'react';
import Approver from './Approver/Approver';
import Org from './Model/Org';
import NavHeader from './components/NavHeader';
import DelegateArea from './components/DelegateArea';
import { ThemeProvider } from '@fluentui/react';
import { useUiContext } from './components/Contexts/UiContext';
import { TeamsTheme } from './Theme/TeamsTheme';
import { TeamsDarkTheme } from './Theme/TeamsDarkTheme';
import { DefaultTheme } from './Theme/DefaultTheme';

function AppApprover() {
    const uiCtx = useUiContext();

    return (
        <>
            <>
                {/* <NavHeader /> */}
                <Approver />
                <DelegateArea />
                {/*<Approver user={{id:"0A13F60C-8B51-45D2-89AA-E7BFA3ACFDDC", surname:"", displayName:"", jobTitle:"", mail:"", userPrincipalName:"", officeLocation:"", mobilePhone:"", givenName:""}}></Approver>*/}
            </>
        </>
    );
}

export default AppApprover;
