import React, { useCallback } from "react";
import { TextField, makeStyles } from "@fluentui/react";
import { useMobile } from "../../useMobile";
import TimePeriodRowM from "../../../Model/TimePeriodRowM";
import { useUiContext } from "../../../components/Contexts/UiContext";
import { useMobileContext } from "../../MobileContext";
// import { makeStyles, useTheme } from "@fluentui/react-theme-provider";
import { periodFakeDay } from "../../mobileConstants";

interface IProps {
    comment: string;
    editTask: TimePeriodRowM;
    selectedDate: Date;
    isReadOnly: boolean;
    hours: number;
};

export const Comment: React.FC<IProps> = (props) => {
    const uiCtx = useUiContext();
    const mobCtx = useMobileContext();
    const classes = getStyles();

    const onSaveNormal = (newComment: string) => {
        useMobile.getState().setIsloading({ active: true, overlay: false });
        const timeEntry = props.editTask.timeEntries?.[0];

        if (timeEntry) {
            useMobile.getState().updateTask({ 
                newTask: {...props.editTask, timeEntries: [{...timeEntry, comment: newComment}]}, 
                updateEditTask: true,
            });
        };

        useMobile.getState().updateCell({
            taskId: props.editTask.taskId,
            date: props.selectedDate,
            hours: timeEntry?.hours ?? 0,
            flagged: timeEntry?.flagged ?? false,
            comment: newComment ?? timeEntry?.comment ?? "",
            timePeriodId: mobCtx.selectedTimePeriod.reportPeriodId, 
            workType: props.editTask.workType,
            uiCtx: uiCtx,
            onSuccess: (newTimeEntry) => {
                console.debug("success", newTimeEntry);
                useMobile.getState().setIsloading({ active: false, overlay: false });
                mobCtx.onUpdateSuccessHighlightRow(props.editTask.taskId, null, newTimeEntry.date, props.editTask.workType?.id);
                // onSuccess(newTimeEntry);
            },
            updateTask: timeEntry ? false : true,
        });
    };

    
    const onSavePeriod = useCallback((newComment: string) => {
        useMobile.getState().setIsloading({ active: true, overlay: false });
        const timeEntry = props.editTask.timeEntries?.[0];

        useMobile.getState().updateCellPeriod({
            taskId: props.editTask.taskId,
            totalHours: props.hours,
            periodDays: mobCtx.selectedPeriodDays,
            comment: newComment,
            flagged: timeEntry?.flagged ?? false,
            selectedTimePeriod: mobCtx.selectedTimePeriod, 
            workType: props.editTask.workType,
            uiCtx: uiCtx,
            onSuccess: () => {
                useMobile.getState().setIsloading({ active: false, overlay: false });
                mobCtx.onUpdateSuccessHighlightRow(props.editTask.taskId, periodFakeDay, null, props.editTask.workType?.id);
            },
            // updateTask: timeEntry ? false : true,
            updateTask: true,
        });
    }, [props.editTask.timeEntries, props.editTask, mobCtx.selectedTimePeriod, mobCtx.selectedPeriodDays]);

    const onSave = (newComment: string) => {
        if (mobCtx.isPeriodView) {
            onSavePeriod(newComment);
        } else {
            onSaveNormal(newComment);
        }
    };;

    return (
        <div className={classes.root}>
            <TextField
                placeholder="Comment"
                defaultValue={props.comment}
                disabled={props.isReadOnly}
                onBlur={(e) => {
                    if (props.comment !== e.target.value) {
                        onSave(e.target.value);
                    };
                }}
                styles={{
                    fieldGroup: classes.fieldGroup,
                    field: classes.field,
                }}
            />
        </div>
    );
};

const getStyles = makeStyles(theme => ({
    root: {
        padding: `${theme.spacing.s1} ${theme.spacing.l1}`,
        paddingBottom: theme.spacing.l1,
        // backgroundColor: theme.semanticColors.bodyDivider,
        // backgroundColor: theme.palette.neutralLighter,
        // backgroundColor: theme.semanticColors.bodyFrameBackground,
    },
    fieldGroup: {
        // backgroundColor: theme.semanticColors.bodyDivider,
        // borderColor: theme.semanticColors.bodyDivider,
        // backgroundColor: theme.palette.neutralLighter,
        // borderColor: theme.palette.neutralLighter,


        backgroundColor: `${theme.semanticColors.bodyFrameBackground} !important`,
        borderColor: theme.palette.neutralLighter,


        // background: theme.semanticColors.bodyFrameBackground,
        // borderColor: theme.semanticColors.bodyFrameBackground,

        height: 38,
        borderRadius: 4,
    },
    field: {
        // backgroundColor: theme.semanticColors.bodyDivider,
        // borderColor: theme.semanticColors.bodyDivider,
        // backgroundColor: theme.palette.neutralLighter,
        // borderColor: theme.palette.neutralLighter,


        backgroundColor: `${theme.semanticColors.bodyFrameBackground} !important`,
        borderColor: theme.palette.neutralLighter,


        // background: theme.semanticColors.bodyFrameBackground,
        // borderColor: theme.semanticColors.bodyFrameBackground,


        borderRadius: 4,
    },
}));