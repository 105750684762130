import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';

export const CSSTransitionWithRefRenderProps = ({ children, ...props }: CSSTransitionProps & { children: (ref: any) => JSX.Element }): JSX.Element => {
    const ref = useRef(null);
    return (
        <CSSTransition {...props} nodeRef={ref}>
            {children(ref)}
        </CSSTransition>
    );
};
