import React from 'react';
import { Checkbox, Label, Stack, StackItem } from '@fluentui/react';
import { IUiContext } from '../../../components/Contexts/UiContext';
import { TimeConfigKeys } from '../../../constants/TimeConfigKeys';

export interface WorkDay {
    name: string;
    enabled: boolean;
}

interface WorkdaySelectorProps {
    uiContext: IUiContext;
}

export const WorkdaySelector: React.FC<WorkdaySelectorProps> = ({ uiContext }) => {
    const saveWorkDays = (dayName: string, value: boolean) => {
        const data = [...uiContext.workdays];

        data.find(d => d.name === dayName).enabled = value;

        console.log('Saving workdays', data);
        uiContext.timeApi.setConfig(TimeConfigKeys.WorkDays, JSON.stringify(data)).then(res => {
            if (res) {
                uiContext.setWorkdays(data);
            }
            console.log('Workdays setting saved', res);
        });
    };

    return (
        <>
            <Stack>
                <StackItem>
                    <Label>Work days</Label>
                </StackItem>
                <Stack horizontal>
                    {uiContext.workdays.map(day => {
                        return <Checkbox styles={{ label: { paddingRight: 6 } }} key={day.name} label={day.name} checked={day.enabled} onChange={(e, checked) => saveWorkDays(day.name, checked)} />;
                    })}
                </Stack>
            </Stack>
        </>
    );
};
