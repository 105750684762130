import React, { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { Icon, IIconStyles } from '@fluentui/react/lib/Icon';
import { Separator } from '@fluentui/react/lib/Separator';
import { useUiContext } from '../../../../../components/Contexts/UiContext';
import AdminHelpSupporter from './AdminHelpSupporter';
import AdminSupporterM from '../../../../../Model/AdminSupporterM';
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { createNewGuid } from '../../../../../components/Utils';

interface propsAdminSupporters {
    supporters: AdminSupporterM[];
}

function AdminSupporters(props: propsAdminSupporters) {
    const uiCtx = useUiContext();

    const [supporters, setSupporters] = useState<AdminSupporterM[]>([]);

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setSupporters(
            props.supporters.sort((a, b) => {
                return a.order < b.order ? -1 : 1;
            }),
        );
        setLoading(false);
    }, [props.supporters]);

    const ReorderSupporter = (s: AdminSupporterM, direction: number) => {
        //console.log("projects!", projects);
        let neworder: number = -1;

        if (direction > 0) {
            neworder = s.order < supporters.length ? s.order + 1 : supporters.length;
        } else {
            neworder = s.order > 1 ? s.order - 1 : 1;
        }

        let ordernumber: number = 1;
        for (let i = 0; i < supporters.length; i++) {
            if (neworder === ordernumber) {
                ordernumber = ordernumber + 1;
            }
            if (supporters[i].id === s.id) {
                supporters[i].order = neworder;
            } else {
                supporters[i].order = ordernumber;
                ordernumber = ordernumber + 1;
            }
        }

        setSupporters(
            [...supporters].sort((a, b) => {
                return a.order < b.order ? -1 : 1;
            }),
        );
    };
    const RemoveSupporter = (s: AdminSupporterM) => {
        setSupporters(supporters.filter(sup => sup.id !== s.id));
    };
    const SupporterUpdated = (id: string, header: string, description: string, web: string, email: string, call: string) => {
        for (let i = 0; i < supporters.length; i++) {
            if (supporters[i].id === id) {
                supporters[i].header = header;
                supporters[i].description = description;
                supporters[i].web = web;
                supporters[i].email = email;
                supporters[i].call = call;
            }
        }
        setSupporters(
            [...supporters].sort((a, b) => {
                return a.order < b.order ? -1 : 1;
            }),
        );
    };
    const saveChanges = () => {
        setLoading(true);
        uiCtx.timeApi.setSupporters(supporters).then(supporters => {
            console.log('saved');
            setLoading(false);
        });
    };
    const AddSupporterClick = () => {
        const newSupporter: AdminSupporterM = { id: createNewGuid(), header: 'New Supporter', description: '', web: '', email: '', call: '', order: supporters.length + 1 };
        supporters.push(newSupporter);
        setSupporters([...supporters]);
    };
    return (
        <div style={outerDivStyle}>
            <h3>Supporters</h3>
            <Separator>
                <Icon iconName="ProjectCollection" styles={separatorIconStyles} />
            </Separator>
            <br />
            {loading ? (
                <Stack className="logindialog" horizontalAlign="center" verticalAlign="center">
                    <Spinner label="Loading..." />
                </Stack>
            ) : (
                <>{renderAdminSupportersConfig()}</>
            )}
        </div>
    );

    function renderAdminSupportersConfig(): JSX.Element {
        return (
            <div style={{ display: 'inline-block' }}>
                <Stack tokens={{ childrenGap: 10 }}>
                    {supporters.map((supporter, index) => {
                        return (
                            <AdminHelpSupporter
                                key={index}
                                ReorderSupporter={ReorderSupporter}
                                RemoveSupporter={RemoveSupporter}
                                SupporterUpdated={SupporterUpdated}
                                Supporter={supporter}
                            ></AdminHelpSupporter>
                        );
                    })}
                    <Separator>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Stack.Item align="center">
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    <DefaultButton text="Add Supporter" onClick={AddSupporterClick}></DefaultButton>
                                    <PrimaryButton onClick={saveChanges} text="Save Supporters" />
                                </Stack>
                            </Stack.Item>
                        </Stack>
                    </Separator>
                </Stack>
                <br />
                <br />
                <br />
            </div>
        );
    }
}

export default AdminSupporters;

const outerDivStyle = {
    margin: '20px 0px 0px 0px',
    display: 'block',
    justifyContent: 'center',
    textAlign: 'center',
    // backgroundColor: 'white',
} as React.CSSProperties;

const personaAprroverConfigContainerDivStyle = {
    display: 'inline-flex',
};

const separatorIconStyles: IIconStyles = {
    root: {
        fontSize: '24px',
        height: '24px',
        width: '24px',
    },
};
