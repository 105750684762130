import React, { Fragment, useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Stack, Link, Label, Text, Spinner, Separator, IStackTokens, IStackStyles } from '@fluentui/react';
import { useUiContext } from '../components/Contexts/UiContext';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            //fontWeight: theme.typography.fontWeightRegular,
        },
    }),
);

const loadingStackStyle: IStackStyles = { root: { height: '600px' } };

const spinnerStyles = {
    circle: {
        height: 75,
        width: 75,
        borderWidth: 5,
    },
    label: {
        'font-size': 16,
    },
};

const stackTokens: IStackTokens = { childrenGap: 12 };

function HelpSupport() {
    const [loadingcomplete, setLoadingcomplete] = useState<boolean>(false);
    const [support, setSupport] = useState<any[]>([]);

    const uiCtx = useUiContext();

    useEffect(() => {
        uiCtx.timeApi.getSupport().then(json => {
            let allSupport: any[] = [];
            let anySupportsfound: boolean = false;
            json.map((sup: any, index: number) => {
                allSupport.push({ header: sup.header, description: sup.description, web: sup.web, email: sup.email, call: sup.call, order: sup.order });
                anySupportsfound = true;
            });
            if (!anySupportsfound) {
                allSupport.push({
                    header: 'Contact',
                    description: 'If you cannot find answers in the Video/Guide/FAQ, please contact us on one of the methods below.',
                    web: 'https://projectumsupport.freshdesk.com/a/forums',
                    email: 'mail@projectum.com',
                    call: '+45 2613 7433',
                    order: 1,
                });
            }
            let sortedSupport: any[] = (allSupport as any[]).sort((a: any, b: any) => {
                return a.order < b.order ? -1 : 1;
            });
            setSupport(sortedSupport);
            setLoadingcomplete(true);
        });
    }, []);

    return (
        <>
            {!loadingcomplete ? (
                <Stack verticalAlign="center" horizontalAlign="center" styles={loadingStackStyle}>
                    <Spinner styles={spinnerStyles} label="Getting help..." />
                </Stack>
            ) : (
                // <div style={{width:'1248px', margin:'auto'}}>
                <div>
                    <br />
                    <Stack verticalAlign="center" horizontalAlign="center" tokens={stackTokens} style={{ color: uiCtx.theme.semanticColors.bodyText, padding: 15 }}>
                        {support.map((sup, index) => {
                            return (
                                <Fragment key={index}>
                                    <Text
                                        styles={{
                                            root: {
                                                color: uiCtx.theme.semanticColors.bodyText,
                                            },
                                        }}
                                        variant="large"
                                    >
                                        {sup.header}
                                    </Text>
                                    <Separator styles={{ root: { width: '100%' } }} />
                                    <Text
                                        styles={{
                                            root: {
                                                color: uiCtx.theme.semanticColors.bodyText,
                                            },
                                        }}
                                        variant="large"
                                    >
                                        {sup.description}
                                    </Text>
                                    <Link href={sup.web} target="_blank">
                                        Access our support page
                                    </Link>
                                    <Text
                                        styles={{
                                            root: {
                                                color: uiCtx.theme.semanticColors.bodyText,
                                            },
                                        }}
                                        variant="large"
                                    >
                                        <a href={'mailto:' + sup.email + '?subject=Help with Time for Teams&body=Please specify your inquery here and include screenshots if applicable.'}>
                                            {sup.email}
                                        </a>
                                    </Text>
                                    <Text
                                        styles={{
                                            root: {
                                                color: uiCtx.theme.semanticColors.bodyText,
                                            },
                                        }}
                                        variant="large"
                                    >
                                        Call us at <a href={'tel:' + sup.call}>{sup.call}</a>
                                    </Text>
                                </Fragment>
                            );
                        })}
                    </Stack>
                </div>
            )}
        </>
    );
}

export default HelpSupport;
