import React, { useEffect, useState } from 'react';
import { Text, makeStyles } from '@fluentui/react';
import { constants } from '../../Styles/constants';
import PeriodDay from '../../Model/PeriodDay';
import { convertHoursToProcent, getCapacityPrDay } from './Cell.helpers';
import { useUiContext } from '../Contexts/UiContext';
import { roundToNearestValueStep } from '../../Mobile/mobileUtils';

interface IProps {
    value: string | number;
    periodDays: PeriodDay[];
}

export const DisplayCell: React.FC<IProps> = props => {
    const uiCtx = useUiContext();

    return uiCtx.gridInputType === 'percent' ? <DisplayProcentCell {...props} /> : <DisplayValueCell {...props} />;
};

const DisplayValueCell: React.FC<IProps> = props => {
    const uiCtx = useUiContext();
    const styles = getStyles();

    const [celltext, setCelltext] = useState('');

    useEffect(() => {
        if (Number.isNaN(Number(props.value))) {
            setCelltext('');
            return;
        }
        const roundedCellValue = roundToNearestValueStep(Number(props.value), uiCtx.timeLowerLimitConfig);
        setCelltext(`${roundedCellValue}`);
    }, [props.value]);

    return (
        <div className={styles.root}>
            <Text className={styles.cell}>{celltext && celltext !== '0' && celltext !== '' && `${celltext}`}</Text>
        </div>
    );
};

const DisplayProcentCell: React.FC<IProps> = props => {
    const uiCtx = useUiContext();
    const styles = getStyles();

    const [celltext, setCelltext] = useState('');

    useEffect(() => {
        if (Number.isNaN(Number(props.value))) {
            setCelltext('');
            return;
        }
        const procent = convertHoursToProcent(Number(props.value), props.periodDays.length, getCapacityPrDay(props.periodDays, uiCtx));
        const roundedCellValue = roundToNearestValueStep(procent, uiCtx.timeLowerLimitConfig);
        setCelltext(`${roundedCellValue}`);
    }, [props.value]);

    return (
        <div className={styles.root}>
            <Text className={styles.cell}>{celltext && celltext !== '0' && celltext !== '' && `${celltext} %`}</Text>
        </div>
    );
};

const getStyles = makeStyles(theme => ({
    root: [
        'cellInner',
        {
            position: 'relative',
            height: '100%',
            width: '100%',
            maxWidth: constants.cellInputMaxWidthWeekOverview,
            margin: '0 auto',
            display: 'flex',
        },
    ],
    cell: {
        paddingRight: theme.spacing.s1,
        paddingLeft: 7,
        fontWeight: 600,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
