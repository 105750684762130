import { Stack, Spinner, MessageBar, MessageBarType, MessageBarButton, useTheme } from '@fluentui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePersistSettings, useGetSettings } from '../../_contexts/settings/settingsContext';
import { useBeforeUnload } from 'react-router-dom';
import { useControlledState } from '../../hooks/useControlledState';
// import { UNSAFE_NavigationContext, unstable_useBlocker, unstable_usePrompt } from 'react-router-dom';
// import type { History, Blocker, Transition } from 'history';

// export function useBlocker(blocker: Blocker, when = true): void {
// function useBlocker(): void {
//     const navigator = useContext(UNSAFE_NavigationContext).navigator;
//     console.log('🚀 ~ useBlocker ~ navigator', navigator);
// 	// navigator.listen()

//     //   useEffect(() => {
//     //     if (!when) return;

//     //     const unblock = navigator.block((tx: Transition) => {
//     //       const autoUnblockingTx = {
//     //         ...tx,
//     //         retry() {
//     //           unblock();
//     //           tx.retry();
//     //         },
//     //       };

//     //       blocker(autoUnblockingTx);
//     //     });

//     //     return unblock;
//     //   }, [navigator, blocker, when]);
// }

export const SaveSettingsMessageBar = () => {
    const { settings, editableSettings } = useGetSettings();
    // useBlocker();

    const { persistSettings, revertChanges, error, isLoading, isSuccess } = usePersistSettings();
    const settingsHasChanged = useMemo(() => settings !== editableSettings, [editableSettings, settings]);
    const [hide, setHide] = useState(true);
    const skipNextTimeout = useRef(false);
    // unstable_usePrompt({when: settingsHasChanged, message: "You have unsaved changes"})
    // unstable_useBlocker(settingsHasChanged)

    const [localError, setLocalError] = useControlledState(() => error || null, [error]);

    useEffect(() => {
        if (hide && settingsHasChanged) {
            setHide(false);
        }
    }, [hide, settingsHasChanged]);

    useEffect(() => {
        if (skipNextTimeout.current) {
            skipNextTimeout.current = false;
            return;
        }
        if (!hide && !settingsHasChanged && !localError) {
            const timer = setTimeout(() => {
                setHide(true);
            }, 5000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [localError, hide, settingsHasChanged]);

    useBeforeUnload(
        useCallback(
            e => {
                if (settingsHasChanged) {
                    e.preventDefault();
                    const confirmationMessage =
                        'It looks like you have been editing something. ' +
                        //
                        'If you leave before saving, your changes will be lost.';

                    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
                    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
                }
            },
            [settingsHasChanged],
        ),
    );

    const onSave = useCallback(() => {
        persistSettings();
        setLocalError(null);
    }, [persistSettings, setLocalError]);

    const onRevert = useCallback(() => {
        skipNextTimeout.current = true;
        revertChanges();
        setHide(true);
        setLocalError(null);
    }, [revertChanges, setLocalError]);

    useEffect(() => {
        const savehandler = (e: KeyboardEvent) => {
            if (e.key.toLowerCase() === 's' && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                if (settingsHasChanged) {
                    onSave();
                }
            }
        };
        window.addEventListener('keydown', savehandler);
        return () => {
            window.removeEventListener('keydown', savehandler);
        };
    }, [onSave, settingsHasChanged]);

    const theme = useTheme();

    return hide ? null : (
        <MessageBar
            messageBarType={localError ? MessageBarType.error : isSuccess && !settingsHasChanged ? MessageBarType.success : MessageBarType.warning}
            actions={
                <div>
                    {(settingsHasChanged || localError) && (
                        <>
                            <MessageBarButton onClick={onSave} disabled={isLoading}>
                                Save
                            </MessageBarButton>
                            <MessageBarButton onClick={onRevert} disabled={isLoading}>
                                Revert
                            </MessageBarButton>
                        </>
                    )}
                </div>
            }
            isMultiline={false}
            styles={{
                root: {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    zIndex: 99999999,
                    color: isSuccess && !settingsHasChanged ? theme.semanticColors.bodyBackground : theme.semanticColors.bodyText,
                },
            }}
        >
            {localError ? (
                <>Error occured while saving{localError?.message && `: ${localError.message}`}</>
            ) : isLoading ? (
                <Stack horizontal>
                    <Spinner />
                    &nbsp; Saving settings
                </Stack>
            ) : isSuccess && !settingsHasChanged ? (
                'Settings successfully saved'
            ) : (
                'Settings changed, click to save'
            )}
        </MessageBar>
    );
};
