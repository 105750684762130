import { useQuery } from '@tanstack/react-query';
import { SEARCH_USERS } from '../../../../../constants/queryKeys';
import { ApiCalls } from '../../../../../api/api';
import { useStore } from '../../../../../store/useStore';
import { useEffect, useRef } from 'react';

export const useSearchUsers = () => {
    const searchText = useStore(state => state.searchText);
    const defaultData = useRef([]).current;

    const { data: users = defaultData, isFetching } = useQuery([SEARCH_USERS, searchText], () =>
        ApiCalls.searchUsers({ searchText: searchText }).then(res => {
            return res.data;
        }),
    );

    useEffect(() => {
        if (users && !isFetching) {
            useStore.getState().setCurrentUsers(users);
            useStore.getState().setOriginalUsers(users);
        }
    }, [users, isFetching]);

    return { isFetching };
};
