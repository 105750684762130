import React, { useCallback, useEffect, useState } from 'react';
import { useUiContext } from '../../components/Contexts/UiContext';
import TimeLowerLimitConfig from './TimeConfigModules/TimeLowerLimitConfig';
import { Checkbox, Dropdown } from '@fluentui/react';
import { DefaultButton, Stack, TooltipHost } from '@fluentui/react';
import { PageHeader, PageContent, ContentSection } from '../../components/LayoutElements';
import { TextField, Text } from '@fluentui/react';
import moment from 'moment';
import { ComboBox, IComboBoxOption } from '@fluentui/react';
import { TimeConfigKeys } from '../../constants/TimeConfigKeys';
import { WorkdaySelector } from './TimeConfigModules/WorkdaysSelector';
import { ApiCalls } from '../../api/api';

function AdminTimeConfig() {
    const uiCtx = useUiContext();

    const [datetimeformat, setdatetimeformat] = useState(null);
    const [dateformat, setdateformat] = useState(null);
    const [dateshortformat, setdateshortformat] = useState(null);
    const [demodataloaded, setdemodataloaded] = useState<boolean>(false);
    const [gridView, setgridView] = useState(uiCtx.gridView);
    const [gridInputType, setGridInputType] = useState(uiCtx.gridInputType);

    useEffect(() => {
        const setDTFValue = uiCtx.allConfig.find(_ => _.key?.toString()?.toLowerCase() === 'datetimeformat')?.value;
        setdatetimeformat(setDTFValue !== null && setDTFValue !== undefined && setDTFValue !== '' ? setDTFValue : 'DD/MM/YYYY HH:mm');

        const setDFValue = uiCtx.allConfig.find(_ => _.key?.toString()?.toLowerCase() === 'dateformat')?.value;
        setdateformat(setDFValue !== null && setDFValue !== undefined && setDFValue !== '' ? setDFValue : 'DD/MM/YYYY');

        const setDSFValue = uiCtx.allConfig.find(_ => _.key?.toString()?.toLowerCase() === 'shortdateformat')?.value;
        setdateshortformat(setDSFValue !== null && setDSFValue !== undefined && setDSFValue !== '' ? setDSFValue : 'D MMM');

        if (uiCtx.allConfig !== null && uiCtx.allConfig !== undefined && uiCtx.loading) {
            uiCtx.setLoading(false);
        }
    }, [uiCtx.allConfig]);

    const baseUrl = window.location.href.includes('localhost:3000') ? 'https://localhost:44341' : '';

    const setOnlyAssigned = value => {
        uiCtx.timeApi.setConfig(TimeConfigKeys.OnlyAssignedSearch, value).then(res => console.log(res));
        uiCtx.setOnlyAssignedSearch(value);
    };

    const setDisablePeriodAutoCreation = (value: boolean) => {
        uiCtx.setAutoCreationDisabled(value);
        uiCtx.timeApi.setConfig(TimeConfigKeys.AutoCreationDisabled, value ? 'false' : 'true').then(res => console.log(res));
    };

    const setWorkTypeEnabled = (value: boolean) => {
        uiCtx.setWorkTypeEnabled(value);
        uiCtx.timeApi.setConfig(TimeConfigKeys.EnableWorkType, value ? 'true' : 'false').then(res => console.log(res));
    };

    const setEnableCoachmarks = value => {
        uiCtx.setEnableCoachmarks(value);
        uiCtx.timeApi.setConfig(TimeConfigKeys.EnableCoachmarks, value).then(res => console.log(res));
    };

    //LHA
    const setAllowTelemitry = value => {
        uiCtx.setAllowTelemitry(value);
        uiCtx.timeApi.setConfig(TimeConfigKeys.AllowTelemitry, value).then(res => console.log(res));
    };

    const setDisableAutoUserCreation = value => {
        uiCtx.setAutoCreateNewUsers(value);
        ApiCalls.configurationUpsertCreate({ key: TimeConfigKeys.AutoCreateNewUsers, value: value.toString() }).then(res => console.log(res));
    };

    const saveDatetimeformat = value => {
        if (value !== undefined && value != null && value !== '') {
            console.log('Saving datetime format', value);
            uiCtx.timeApi.setConfig('datetimeformat', value).then(res => console.log('Date format saved', res));
        }
    };

    const saveDateformat = value => {
        if (value !== undefined && value != null && value !== '') {
            console.log('Saving date format', value);
            uiCtx.timeApi.setConfig('dateformat', value).then(res => console.log('Date format saved', res));
        }
    };

    const saveShortDateformat = value => {
        if (value !== undefined && value != null && value !== '') {
            console.log('Saving short date format', value);
            uiCtx.timeApi.setConfig('shortdateformat', value).then(res => console.log('Date format saved', res));
        }
    };

    const saveGridInputType = value => {
        if (value !== undefined && value != null && value !== '') {
            uiCtx.setGridInputType(value);
            console.log('Saving grid input type', value);
            uiCtx.timeApi.setConfig(TimeConfigKeys.GridInputType, value).then(res => console.log('Grid input type saved', res));
            setGridInputType(value);
        }
    };

    const saveShowPlanned = value => {
        if (value !== undefined && value != null && value !== '') {
            uiCtx.setShowPlanned(value);
            console.log('Saving show planned', value);
            uiCtx.timeApi.setConfig(TimeConfigKeys.ShowPlanned, value).then(res => console.log('Show planned saved', res));
        }
    };

    const saveGridView = value => {
        if (value !== undefined && value != null && value !== '') {
            uiCtx.setGridView(value);
            console.log('Saving grid view', value);
            uiCtx.timeApi.setConfig(TimeConfigKeys.GridView, value).then(res => console.log('Grid view saved', res));
            setgridView(value);
        }
    };

    const saveWorkDayBoundaries = (value: boolean) => {
        if (value !== undefined && value != null) {
            uiCtx.setWorkDayBoundaries(value);
            console.log('Saving workday boundaries', value);
            uiCtx.timeApi.setConfig(TimeConfigKeys.WorkDayBoundaries, value.toString()).then(res => console.log('Workday boundaries setting saved', res));
        }
    };

    const saveWorkDayMinutesInterval = (value: number) => {
        if (value !== undefined && value != null) {
            uiCtx.setWorkDayMinutesInterval(value);
            console.log('Saving workday minutes interval', value);
            uiCtx.timeApi
                .setConfig(TimeConfigKeys.WorkDayMinutesInterval, value.toString())
                .then(res => console.log('Workday minutes interval setting saved', res));
        }
    };

    const saveTimesheeRAGsEnabled = (value: boolean) => {
        if (value !== undefined && value != null) {
            uiCtx.setTimesheetRAGsEnabled(value);
            console.log('Saving TimesheetRAGsEnabled', value);
            uiCtx.timeApi.setConfig(TimeConfigKeys.TimesheetRAGsEnabled, value.toString()).then(res => console.log('TimesheetRAGsEnabled setting saved', res));
        }
    };

    const saveNativeChartsEnabled = (value: boolean) => {
        if (value !== undefined && value != null) {
            uiCtx.setNativeChartsEnabled(value);
            console.log('Saving NativeChartsEnabled', value);
            uiCtx.timeApi.setConfig(TimeConfigKeys.NativeChartsEnabled, value.toString()).then(res => console.log('NativeChartsEnabled setting saved', res));
        }
    };

    const saveOnlyallowtimesheetwithintaskstartend = (value: boolean) => {
        if (value !== undefined && value != null) {
            uiCtx.setOnlyallowtimesheetwithintaskstartend(value);
            console.log('Saving only allow time sheet with task start and end', value);
            uiCtx.timeApi
                .setConfig(TimeConfigKeys.Onlyallowtimesheetwithintaskstartend, value.toString())
                .then(res => console.log('Enable only allow time sheet with task start and end', res));
        }
    };

    const saveEnableTimeEntriesImport = (value: boolean) => {
        if (value !== undefined && value != null) {
            uiCtx.setEnableTimeEntriesImport(value);
            console.log('Saving time entries import', value);
            uiCtx.timeApi
                .setConfig(TimeConfigKeys.TimeEntriesImport, value.toString())
                .then(res => console.log('Enable time entries import setting saved', res));
        }
    };

    const saveSubmitPeriodOnImport = (value: boolean) => {
        if (value !== undefined && value != null) {
            uiCtx.setEnableSubmitPeriodOnImport(value);
            console.log('Saving time entry import add approval', value);
            uiCtx.timeApi
                .setConfig(TimeConfigKeys.SubmitPeriodOnImport, value.toString())
                .then(res => console.log('Enable time entry import add approval setting saved', res));
        }
    };

    const saveInheritApproverRights = (value: boolean) => {
        if (value !== undefined && value != null) {
            uiCtx.setInheritApproverRights(value);
            console.log('Saving inherit approver rights', value);
            uiCtx.timeApi.setConfig(TimeConfigKeys.InheritApproverRights, value.toString()).then(res => console.log('inherit approver rights saved', res));
        }
    };

    const saveWorkDayAMPM = (value: boolean) => {
        if (value !== undefined && value != null) {
            uiCtx.setWorkDayAMPM(value);
            console.log('Saving workday AM/PM', value);
            uiCtx.timeApi.setConfig(TimeConfigKeys.WorkDayAMPM, value.toString()).then(res => console.log('Workday AM/PM setting saved', res));
        }
    };

    const saveCellComment = (value: boolean) => {
        if (value !== undefined && value != null) {
            uiCtx.setAllowCellComment(value);
            console.log('Saving cell comment allowed', value);
            uiCtx.timeApi.setConfig(TimeConfigKeys.AllowCellComment, value.toString()).then(res => console.log('Cell comment allowed setting saved', res));
        }
    };

    const saveCellFlag = (value: boolean) => {
        if (value !== undefined && value != null) {
            uiCtx.setAllowCellFlag(value);
            console.log('Saving cell flag allowed', value);
            uiCtx.timeApi.setConfig(TimeConfigKeys.AllowCellFlag, value.toString()).then(res => console.log('Cell flag allowed setting saved', res));
        }
    };

    const saveTimeSheetComment = (value: boolean) => {
        if (value !== undefined && value != null) {
            uiCtx.setAllowTimeSheetComment(value);
            console.log('Saving timesheet comment allowed', value);
            uiCtx.timeApi
                .setConfig(TimeConfigKeys.AllowTimeSheetComment, value.toString())
                .then(res => console.log('Yimesheet comment allowed setting saved', res));
        }
    };

    const downloadTeamsApp = useCallback(async () => {
        try {
            const response = await ApiCalls.downloadMsteamsappList({ format: 'blob' }).then(response => response.data);

            const url = window.URL.createObjectURL(response);

            const link = document.createElement('a');
            link.href = url;
            // Name the file
            link.setAttribute('download', 'Time For Teams App.zip');

            document.body.appendChild(link);

            link.click();

            link.parentNode?.removeChild(link);
        } catch (error) {
            console.error('There was an error downloading the file!', error);
        }
    }, []);

    if (uiCtx.allConfig === null || uiCtx.allConfig === undefined) {
        return (
            <div className="centeredtext">
                <h2>Loading</h2>
            </div>
        );
    }

    return (
        <>
            <PageHeader title="Time configuration" icon="Calendar" />

            <PageContent>
                <ContentSection>
                    <Stack tokens={{ childrenGap: 32 }}>
                        <TimeLowerLimitConfig />
                        <Stack tokens={{ childrenGap: 16 }}>
                            <ComboBox
                                label="Register time as (grid input)"
                                selectedKey={uiCtx.gridInputType}
                                onChange={(e, option) => saveGridInputType(option.key)}
                                options={
                                    [
                                        { key: 'percent', text: 'Percent', disabled: gridView === 'days' },
                                        { key: 'hours', text: 'Hours' },
                                    ] as IComboBoxOption[]
                                }
                            />
                            <ComboBox
                                label="View granularity"
                                selectedKey={uiCtx.gridView}
                                onChange={(e, option) => saveGridView(option.key)}
                                options={
                                    [
                                        { key: 'days', text: 'Days', disabled: gridInputType === 'percent' },
                                        { key: 'period', text: 'Period' },
                                    ] as IComboBoxOption[]
                                }
                            />

                            <Checkbox onChange={(e, value) => saveShowPlanned(value)} checked={uiCtx.showPlanned} label="Show planned/remaining/actual work" />
                            <WorkdaySelector uiContext={uiCtx} />
                            <Checkbox onChange={(e, value) => saveWorkDayBoundaries(value)} checked={uiCtx.workDayBoundaries} label="Workday start/end time" />
                            <Checkbox onChange={(e, value) => saveWorkDayAMPM(value)} checked={uiCtx.workDayAMPM} label="Workday start/end time AM/PM" />
                            <Dropdown
                                label="Workday start/end time minutes interval"
                                defaultSelectedKey={uiCtx.workDayMinutesInterval}
                                options={[
                                    { key: 1, text: '1' },
                                    { key: 5, text: '5' },
                                    { key: 10, text: '10' },
                                    { key: 15, text: '15' },
                                    { key: 20, text: '20' },
                                    { key: 30, text: '30' },
                                ]}
                                onChange={(e, value) => saveWorkDayMinutesInterval(Number(value.key))}
                            />
                            <Checkbox
                                onChange={(e, value) => setOnlyAssigned(value)}
                                defaultChecked={uiCtx.onlyAssignedSearch}
                                label="Only search assigned tasks"
                            />
                            <Checkbox
                                onChange={(e, value) => saveTimeSheetComment(value)}
                                defaultChecked={uiCtx.allowTimeSheetComment}
                                label="Allow Timesheet Submit Comment"
                            />
                            <Checkbox onChange={(e, value) => saveCellComment(value)} defaultChecked={uiCtx.allowCellComment} label="Allow Cell Comment" />
                            <Checkbox onChange={(e, value) => saveCellFlag(value)} defaultChecked={uiCtx.allowCellFlag} label="Allow Cell Flag" />
                            <Checkbox
                                onChange={(e, value) => saveOnlyallowtimesheetwithintaskstartend(value)}
                                defaultChecked={uiCtx.onlyallowtimesheetwithintaskstartend}
                                label="Only allow time sheet within task start and end"
                            />
                            <Checkbox
                                onChange={(e, value) => setDisablePeriodAutoCreation(value)}
                                defaultChecked={uiCtx.autoCreationDisabled}
                                label="Enable Auto Period Creation"
                            />
                            <Checkbox
                                onChange={(e, value) => setDisableAutoUserCreation(value)}
                                defaultChecked={uiCtx.autoCreateNewUsers}
                                label="Enable Auto User Creation"
                            />
                            <Checkbox onChange={(e, value) => setWorkTypeEnabled(value)} defaultChecked={uiCtx.workTypeEnabled} label="Enable WorkType" />
                            <Checkbox onChange={(e, value) => setEnableCoachmarks(value)} defaultChecked={uiCtx.enableCoachmarks} label="Enable Coachmarks" />
                            <Checkbox onChange={(e, value) => setAllowTelemitry(value)} defaultChecked={uiCtx.allowTelemitry} label="Allow Telemetry" />
                            <Checkbox
                                onChange={(e, value) => saveTimesheeRAGsEnabled(value)}
                                checked={uiCtx.timesheetRAGsEnabled}
                                label="Enable RAG indicators in timesheet"
                            />
                            <Checkbox
                                onChange={(e, value) => saveNativeChartsEnabled(value)}
                                checked={uiCtx.nativeChartsEnabled}
                                label="Enable Native Charts"
                            />
                            <Checkbox
                                onChange={(e, value) => saveEnableTimeEntriesImport(value)}
                                checked={uiCtx.enableTimeEntriesImport}
                                label="Enable Time Entries Import"
                            />
                            <Checkbox
                                onChange={(e, value) => saveSubmitPeriodOnImport(value)}
                                checked={uiCtx.enableSubmitPeriodOnImport}
                                label="Enable Add Approval on Time Entry Import"
                            />
                            <Checkbox
                                onChange={(e, value) => saveInheritApproverRights(value)}
                                checked={uiCtx.inheritApproverRights}
                                label="Inherit approver rights"
                            />
                            <Stack>
                                <TooltipHost
                                    tooltipProps={{
                                        onRenderContent: () => (
                                            <>
                                                The date and time format to be shown where a timestamp is needed. Ex. on the Approver's page in the column
                                                "Submitted"
                                            </>
                                        ),
                                    }}
                                >
                                    <Stack>
                                        <TextField
                                            label="Datetime format"
                                            placeholder="DD/MM/YYYY HH:mm:ss"
                                            onChange={(e, v) => {
                                                setdatetimeformat(v);
                                            }}
                                            onBlur={e => {
                                                saveDatetimeformat(e.target.value);
                                            }}
                                            defaultValue={datetimeformat}
                                        ></TextField>
                                        <Text variant="small">Ex. {moment(new Date()).format(datetimeformat)}</Text>
                                    </Stack>
                                </TooltipHost>
                                <TooltipHost tooltipProps={{ onRenderContent: () => <>The date format to be shown.</> }}>
                                    <Stack>
                                        <TextField
                                            label="Normal date format"
                                            placeholder="DD/MM/YYYY"
                                            onChange={(e, v) => {
                                                setdateformat(v);
                                            }}
                                            onBlur={e => {
                                                saveDateformat(e.target.value);
                                            }}
                                            defaultValue={dateformat}
                                        ></TextField>
                                        <Text variant="small">Ex. {moment(new Date()).format(dateformat)}</Text>
                                    </Stack>
                                </TooltipHost>
                                <TooltipHost
                                    tooltipProps={{
                                        onRenderContent: () => (
                                            <>The date format to be shown when a short date is displayed. Ex. on the Time page when you click on the day name</>
                                        ),
                                    }}
                                >
                                    <Stack>
                                        <TextField
                                            label="Short date format"
                                            placeholder="D MMM"
                                            value={dateshortformat}
                                            onChange={(e, v) => {
                                                setdateshortformat(v);
                                            }}
                                            onBlur={e => {
                                                saveShortDateformat(dateshortformat);
                                            }}
                                            // defaultValue={dateshortformat}
                                        ></TextField>
                                        <Text variant="small">Ex. {moment(new Date()).format(dateshortformat)}</Text>
                                    </Stack>
                                </TooltipHost>
                            </Stack>
                        </Stack>
                        <Stack tokens={{ childrenGap: 16 }}>
                            <DefaultButton text="Download MS Teams App" onClick={downloadTeamsApp} />
                            <DefaultButton
                                text="Download PowerPPM Integration"
                                href={'https://projectumcdnstorage.blob.core.windows.net/timepackages/common/TimeforTeams_Managed.zip'}
                            />
                            <DefaultButton
                                text="Download DevOps Integration"
                                href={'https://projectumcdnstorage.blob.core.windows.net/timepackages/common/TimeforTeamsDevOps_Managed.zip'}
                            />
                            <DefaultButton
                                text="Download Project for the web Integration"
                                href={'https://projectumcdnstorage.blob.core.windows.net/timepackages/common/TimeforTeamsProjectfortheweb_managed.zip'}
                            />
                            <DefaultButton
                                text="Load Demo Data"
                                disabled={demodataloaded ? true : false}
                                onClick={() => {
                                    setdemodataloaded(true);
                                    uiCtx.timeApi.createDemoData().then(r => {
                                        console.log('Load Demo Data', r);
                                        if (r === true) {
                                            alert('Demo data have been created.');

                                            document.location.reload();
                                        } else {
                                            alert('Please delete existing demo data and data first.');
                                        }
                                    });
                                }}
                            />
                        </Stack>
                    </Stack>
                </ContentSection>
            </PageContent>
        </>
    );
}

export default AdminTimeConfig;
