import { TimeEntry } from '../../api/generated/data-contracts';
import { validationRules } from '../validationRules';
import { ValidateResponse } from '../Validator';

interface NoMoreThanXHoursPerDayProps {
    timeEntries: TimeEntry[];
    [key: string]: any;
}

export const NoMoreThanXHoursPerDay = (props: NoMoreThanXHoursPerDayProps): ValidateResponse => {
    let maxHours = 24; //props[NoMoreThenXHoursConfigValues.MaxHours] || 24;

    let daysSum = {};
    let valid = true;

    props.timeEntries.forEach(function (val) {
        let dayKey = new Date(val.date).getTime();
        if (daysSum.hasOwnProperty(dayKey)) {
            daysSum[dayKey] = daysSum[dayKey] + val.hours;
        } else {
            daysSum[dayKey] = val.hours;
        }
        if (daysSum[dayKey] > maxHours) {
            valid = false;
        }
    });

    let response: ValidateResponse = {
        reason: !valid ? `It is not allowed to report more than  ${maxHours} hours per day` : null,
        valid: valid,
        name: validationRules.NoMoreThenXHours,
        rejected: !valid,
    };

    return response;
};
