import React, { useEffect, useState } from 'react';
import NavHeader from '../../components/NavHeader';
import ValidationConfig from './ValidationConfig';

const ValidationConfiguration = () => {
    return (
        <>
            {/* <NavHeader /> */}
            <ValidationConfig />
        </>
    );
};

export default ValidationConfiguration;
