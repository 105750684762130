import React from 'react';
import { useUiContext } from '../Contexts/UiContext';
// import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
// import { CSSTransition as ReactCSSTransitionGroup } from 'react-transition-group';
import { Stack, Text } from '@fluentui/react';
import PeriodDay from '../../Model/PeriodDay';
import { IsDatesNoTimeEqual, IsDeadline, IsHoliday, IsWeekend } from '../Utils';
// import Cell from '../Cell';
import { WorkDayCell } from './WorkDayCell';
import { WorkDay } from '../../Workaround/TimeApiWorkaround';

export interface WorkDayBarProps {
    reportingPeriodId: string;
    workDays: WorkDay[];
    periodDays: PeriodDay[];
    deadlineDate: Date;
    today: Date;
    startDate: Date;
}

export const WorkDayBar: React.FC<WorkDayBarProps> = props => {
    const uiCtx = useUiContext();

    return (
        <>
            <tr className={`groupHeadRow${props.today === props.startDate ? ' isMonday' : ''}`} style={{ cursor: 'auto' }}>
                <td className="rowHeader noselect">
                    <Stack horizontal className="rowHeaderContainer">
                        <div className="expander" />
                        <Stack.Item grow>
                            <Text className="subGroupName textOverflow">Working Hours</Text>
                        </Stack.Item>
                    </Stack>
                </td>

                {!uiCtx.workTypeEnabled && (
                    <td className="rowTotalContainer worktypeContainer noselect">
                        <div className="cell day">
                            {/* <WorkTypePicker readOnly={submittedOrPeriodClosed || time.projectClosed || time.taskClosed} row={time} existingTaskTimeRows={times} onChange={props.updateWorkType} /> */}
                        </div>
                    </td>
                )}

                {uiCtx.plannedActive && (
                    <td className={`rowTotalContainer parContainer noselect`}>
                        <div className="cell parCell day">
                            {/* <Text className="cellInner parCellInner" as="div">
                                {MathToFixed(time.plannedWork, 2)}
                            </Text> */}
                        </div>
                    </td>
                )}
                {uiCtx.actualActive && (
                    <td className={`rowTotalContainer parContainer noselect`}>
                        <div className="cell parCell day">
                            {/* <Text className="cellInner parCellInner" as="div">
                                {MathToFixed(time.actualWork, 2)}
                            </Text> */}
                        </div>
                    </td>
                )}
                {uiCtx.remainingActive && (
                    <td className={`rowTotalContainer parContainer noselect`}>
                        <div className="cell parCell day">
                            {/* <Text className="cellInner parCellInner" as="div">
                                {time.actualWork != null && time.plannedWork != null && time.plannedWork !== 0 ? (MathToFixed(time.plannedWork, 2) - MathToFixed(time.actualWork, 2) < 0 ? 0 : MathToFixed(time.plannedWork, 2) - MathToFixed(time.actualWork, 2)) : null}
                            </Text> */}
                        </div>
                    </td>
                )}

                {uiCtx.workDayBoundaries &&
                    props.periodDays.map((day: PeriodDay, index) => {
                        return (
                            <td
                                key={day.id}
                                className={`rowTotalContainer noselect${IsWeekend(day.date) ? ' weekend' : ''}${
                                    IsDeadline(day.date, props.deadlineDate) ? ' deadline' : ''
                                }${IsHoliday(day.date, uiCtx.user.user.calendar) ? ' holiday' : ''}${
                                    IsDatesNoTimeEqual(props.today, day.date) ? ' today' : ''
                                }`}
                            >
                                <div className="cell day" style={{ padding: 2 }}>
                                    <WorkDayCell workDay={getWorkDay(props.workDays, day.date)} reportingPeriodId={props.reportingPeriodId} periodDay={day} />
                                </div>
                            </td>
                        );
                    })}
                <td className="rowTotalContainer noselect">
                    <div className="rowTotal totalCell">
                        {/* <TotalCell
                            initialValue={MathToFixed(GetTotalFromTimeEntriesArray(time.timeEntries), 2)}
                            periodDays={props.perioddays}
                            // readonly={submittedOrPeriodClosed || time.projectClosed || time.taskClosed || (time.taskStart == undefined ? false : (props.perioddays.length > 0 ? props.perioddays[0].date < new Date(time.taskStart) : true)) || (time.taskEnd == undefined ? false : (props.perioddays.length > 0 ? props.perioddays[props.perioddays.length - 1].date > new Date(time.taskEnd) : true)) || !workTypeAllowed(time)}
                            readonly={
                                (props.perioddays[0].date < uiCtx.user.start && new Date(uiCtx.user.start).getFullYear() > 1900) ||
                                (props.perioddays[props.perioddays.length - 1].date > uiCtx.user.end && new Date(uiCtx.user.end).getFullYear() > 1900) ||
                                submittedOrPeriodClosed ||
                                time.projectClosed ||
                                time.taskClosed ||
                                props.perioddays.length < 1 ||
                                (time.taskStart === undefined ? false : props.perioddays[0].date < DateAsStartOfDay(new Date(time.taskStart))) ||
                                (time.taskEnd === undefined ? false : props.perioddays[props.perioddays.length - 1].date > DateAsStartOfDay(new Date(time.taskEnd))) ||
                                !workTypeAllowed(time)
                            }
                            updated={onCellUpdated}
                            timeRow={time}
                            timeLowerLimitConfig={props.timeLowerLimitConfig}
                        /> */}
                    </div>
                </td>
            </tr>
            <tr className="rowSpacer" />
        </>
    );
};

function getWorkDay(workDays: WorkDay[], date: Date) {
    return workDays.find(wd => wd.date.getDay() === date.getDay() && wd.date.getMonth() === date.getMonth() && wd.date.getFullYear() === date.getFullYear());
}
