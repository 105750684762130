import React, { useState } from 'react';
import { Stack } from '@fluentui/react';
import PeriodDay from '../../Model/PeriodDay';
import { WorkDayStartEndCell } from './WorkDayStartEndCell';
import { WorkDay } from '../../Workaround/TimeApiWorkaround';
import { useTheme } from '@fluentui/react';

interface WorkDayCellProps {
    reportingPeriodId: string;
    periodDay: PeriodDay;
    workDay: WorkDay;
}

export const WorkDayCell: React.FC<WorkDayCellProps> = props => {
    const theme = useTheme();
    const [startTime, setStartTime] = useState<Date | null>(props.workDay?.start);
    const [endTime, setEndTime] = useState<Date | null>(props.workDay?.end);

    return (
        <Stack
            horizontal
            verticalAlign="center"
            horizontalAlign="center"
            tokens={{ childrenGap: 4 }}
            styles={{
                root: {
                    width: '100%',
                    '&:hover div': {
                        backgroundColor: theme.semanticColors.inputBackground,
                        color: theme.semanticColors.bodyText,
                    },
                    '&:hover input::-webkit-calendar-picker-indicator': {
                        opacity: 0.95,
                    },
                },
            }}
        >
            <WorkDayStartEndCell
                reportingPeriodId={props.reportingPeriodId}
                periodDay={props.periodDay}
                type={'start'}
                setStartTime={setStartTime}
                setEndTime={setEndTime}
                startTime={startTime}
                endTime={endTime}
            />

            <WorkDayStartEndCell
                reportingPeriodId={props.reportingPeriodId}
                periodDay={props.periodDay}
                type={'end'}
                setStartTime={setStartTime}
                setEndTime={setEndTime}
                startTime={startTime}
                endTime={endTime}
            />
        </Stack>
    );
};
