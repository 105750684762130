import { forwardRef, useCallback } from 'react';
import { Stack, Text, Icon, Toggle, useTheme } from '@fluentui/react';
import { getEventTextFromEnum } from '../../getEventTextFromEnum';
import { getEventIconFromEnum } from '../../getEventIconFromEnum';

export type IntegrationCardHandle = { open: () => void; close: () => void; toggle: (toggle?: boolean) => void };

export const IntegrationCard = forwardRef<
    IntegrationCardHandle,
    {
        // eventType: EventType;
        enabled: boolean;
        children?: React.ReactNode;
        notReady?: boolean;
        toggleEvent: (state?: boolean) => void;
    }
>(({ enabled, children, notReady, toggleEvent }, ref) => {
    // const [expanded, setExpanded] = useState(enabled);
    const onChange = useCallback(() => toggleEvent(), [toggleEvent]);

    // const toggle = useCallback((toggle?: boolean) => {
    //     setExpanded(e => (toggle !== undefined ? toggle : !e));
    // }, []);

    // useImperativeHandle(
    //     ref,
    //     () => {
    //         return {
    //             close: () => toggle(false),
    //             open: () => toggle(true),
    //             toggle,
    //         };
    //     },
    //     [toggle],
    // );

    const theme = useTheme();

    return (
        <Stack
            styles={{
                root: {
                    width: 250,
                    border: '1px solid rgba(0,0,0,0.3)',
                    padding: 10,
                    backgroundColor: theme.semanticColors.disabledBackground,
                },
            }}
            // id={getEventTextFromEnum(eventType).replaceAll(' ', '_')} // used for testing
        >
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                <Stack
                    horizontal
                    verticalAlign="center"
                    tokens={{ childrenGap: 10 }}
                    // styles={{ root: { cursor: 'pointer', userSelect: 'none' } }}
                    // onClick={() => toggle()}
                >
                    {/* <IconButton iconProps={{ iconName: expanded ? 'ChevronUp' : 'ChevronDown' }} /> */}
                    <Icon iconName={getEventIconFromEnum('Actuals')} />
                    <Text>{getEventTextFromEnum('Actuals')}</Text>
                </Stack>
                <Stack horizontal verticalAlign="center">
                    <Toggle disabled={notReady} onChange={onChange} checked={enabled} styles={{ root: { margin: '0 0 0 5px' } }} />
                </Stack>
            </Stack>
            {/* <div
                //
                style={{
                    //
                    position: 'relative',
                    maxHeight: expanded ? '100vh' : '0px',
                    overflow: 'hidden',
                    transition: 'all .5s ease',
                }}
            >
                {children}
            </div> */}
        </Stack>
    );
});
