import { useEffect, useState } from 'react';
import { useUiContext } from '../../../components/Contexts/UiContext';
import { FileUpload } from './components/FileUpload';
import { ImportResult } from './components/ImportResult';
import { AccessDenied } from '../../../_pages/AccessDenied';
import { PageHeader, PageContent, ContentSection } from '../../../components/LayoutElements';
import { Spinner, Stack } from '@fluentui/react';
import { useImportTimeEntries } from './hooks/useImportTimeEntries';
import { ImportError } from './components/ImportError';

const TimeEntriesUpload = () => {
    const uiCtx = useUiContext();
    const [fileData, setFileData] = useState<File | undefined>(undefined);

    const { mutateImportTimeEntries, isLoadingImportTimeEntries, importErrors, projectumProblem, setProjectumProblem } = useImportTimeEntries();

    useEffect(() => {
        if (!fileData) {
            return;
        }
        mutateImportTimeEntries(fileData);
    }, [fileData]);

    if (isLoadingImportTimeEntries) {
        return (
            <>
                <PageHeader title="Time Entries Import" icon="Upload" />
                <Spinner label="Import in progress" />
            </>
        );
    }

    if (projectumProblem) {
        return <ImportError problemDetails={projectumProblem} setProblemDetails={setProjectumProblem} setFileData={setFileData}></ImportError>;
    }

    return (
        <>
            <PageHeader title="Time Entries Import" icon="Upload" />

            <PageContent>
                <ContentSection>
                    {uiCtx.user.originalUser.isAdmin ? (
                        <>{fileData ? <ImportResult importErrors={importErrors} setFileData={setFileData} /> : <FileUpload setFileData={setFileData} />}</>
                    ) : (
                        <AccessDenied />
                    )}
                </ContentSection>
            </PageContent>
        </>
    );
};

export default TimeEntriesUpload;
