import { buildRoute } from '../../helpers/routeHelpers';
import { TSettingsCategory } from '../components/Dashboard';

export type TSettingsCategoryMeta = {
    breadCrumb: string;
    route: string;
} & TSettingsCategory;

export function flattenCategories(config: TSettingsCategory, rootPath = '') {
    const flatCat = new Map<string, TSettingsCategoryMeta>();
    const recurse = (config: TSettingsCategory, path: string, breadCrumbs = '') => {
        const absLink = buildRoute(path, config.link).slice(1);
        const breadCrumb = makeBreadCrumbString(breadCrumbs, config.title);

        if (flatCat.has(absLink)) {
            const existing = flatCat.get(absLink);
            throw new Error(`Conflicting URLS! "${absLink}" is both used by "${existing.title}" and "${config.title}"`);
        }

        if (Array.isArray(config.children)) {
            config.children.forEach(item => recurse(item, absLink, breadCrumb));
        } else {
            flatCat.set(absLink, { ...config, breadCrumb, route: absLink });
        }
    };
    recurse(config, rootPath);
    return [...flatCat.values()];
}

function makeBreadCrumbString(...titles: string[]) {
    // return titles.reduce((breadCrumb, title) => (breadCrumb ? breadCrumb + ` > ${title}` : title), '');
    return titles.join(' > ');
}
