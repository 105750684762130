import { memo, useMemo, useRef, useCallback, StrictMode } from 'react';
import { defaultRangeExtractor, useVirtualizer } from '@tanstack/react-virtual';
import { ROW_HEIGHT, ROW_STICKY_COUNT, TOP_HEADERS_HEIGHT } from './CONSTANTS';
import { ScrollToContextProvider } from './context/ScrollToContext';
import { ProjectApprovalData, ProjectDto, TimeUserDto } from '../../../../api/generated/data-contracts';
import { ActivitiesProvider } from './context/ActivitiesProvider';
import { SelectionBar } from '../SelectionBar/SelectionBar';
import { RowWrapper } from './components/Rows/RowWrapper/RowWrapper';
import { ProjectApprovalDataContext, ProjectApprovalDataProvider } from './context/ProjectApprovalDataContext';
import { Headers } from './components/Headers';
import { isApprovalRow, isPseudoRow, useApprovalStore, useGridRenderableRows, useInitializeApprovalStore } from '../../../../_grid/grid2';
import { IDropdownOption, IRenderFunction, Stack } from '@fluentui/react';
import shallow from 'zustand/shallow';
import { FilterDropdown } from '../FilterDropdown/FilterDropdown';

export const ApprovalGrid = memo(function ApprovalGrid({ data: { activities, approvals } }: { data: ProjectApprovalData }) {
    const allRows = useInitializeApprovalStore(approvals);

    const renderableRows = useGridRenderableRows(allRows);

    const scrollRef = useRef<HTMLDivElement>(null);
    const activeStickyIndexRef = useRef(0);

    const stickyIndexes = useMemo(() => [0], []);

    const rowVirtualizer = useVirtualizer({
        count: renderableRows.length + ROW_STICKY_COUNT,
        getScrollElement: () => scrollRef.current,
        overscan: 5,
        estimateSize: useCallback((index: number) => {
            if (!index) {
                return TOP_HEADERS_HEIGHT;
            }
            return ROW_HEIGHT;
        }, []),
        rangeExtractor: useCallback(
            range => {
                activeStickyIndexRef.current = [...stickyIndexes].reverse().find(index => range.startIndex >= index);

                const next = new Set([activeStickyIndexRef.current, ...defaultRangeExtractor(range)]);

                return [...next].sort((a, b) => a - b);
            },
            [stickyIndexes],
        ),
    });

    const rows = rowVirtualizer.getVirtualItems();
    const totalHeight = rowVirtualizer.getTotalSize();

    const render = useMemo(() => {
        if (!renderableRows.length) {
            return (
                <>
                    <Headers start={0} />
                    <div>Nothing to approve</div>
                </>
            );
        }
        return rows.map(({ index, start, key }) => {
            if (!index) {
                return <Headers key={key} start={start} />;
            }
            const rowIndex = index - ROW_STICKY_COUNT;
            const row = renderableRows[rowIndex];
            return <RowWrapper key={key} row={row} start={start} rows={allRows} />;
        });
    }, [renderableRows, rows, allRows]);

    const innerScrollContainerStyles = useMemo(
        (): React.CSSProperties => ({
            position: 'relative',
            width: '100%',
            height: `${totalHeight}px`,
            // height: `100%`,
        }),
        [totalHeight],
    );

    const projectApprovalDataContext = useMemo((): ProjectApprovalDataContext => {
        return { activities, allRows, approvalRows: allRows.filter(isApprovalRow), groupRows: allRows.filter(isPseudoRow) };
    }, [activities, allRows]);

    return (
        <StrictMode>
            <ScrollToContextProvider value={scrollTo}>
                <ActivitiesProvider value={activities}>
                    <ProjectApprovalDataProvider value={projectApprovalDataContext}>
                        <FilterBar />
                        <SelectionBar />
                        <div ref={scrollRef} style={scrollContainerStyle}>
                            <div style={innerScrollContainerStyles}>{render}</div>
                        </div>
                    </ProjectApprovalDataProvider>
                </ActivitiesProvider>
            </ScrollToContextProvider>
        </StrictMode>
    );
});

const scrollContainerStyle: React.CSSProperties = {
    height: 'calc(100% - 50px - 60px - 40px)',
    width: `100%`,
    overflow: 'auto',
    maxWidth: '100%',
};

const FilterBar = () => {
    const { selectedProjectIds, selectedUserIds, setSelectedProjectIds, setSelectedUserIds, projects, users } = useApprovalStore(
        store => ({
            selectedProjectIds: store.selectedProjectIds,
            selectedUserIds: store.selectedUserIds,
            setSelectedProjectIds: store.setSelectedProjectIds,
            setSelectedUserIds: store.setSelectedUserIds,
            projects: store.projects,
            users: store.users,
        }),
        shallow,
    );
    const projectFilterOptions = useMemo(
        (): IDropdownOption<ProjectDto>[] =>
            projects.map(project => {
                return {
                    key: project.id,
                    text: project.name,
                    data: project,
                };
            }),
        [projects],
    );
    const userFilterOptions = useMemo(
        (): IDropdownOption<TimeUserDto>[] =>
            users.map(user => {
                return {
                    key: user.id,
                    text: user.name,
                    data: user,
                };
            }),
        [users],
    );
    return (
        <Stack horizontal horizontalAlign="end" styles={{ root: { height: 40 } }}>
            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                <AllOptionsDropdown options={projectFilterOptions} setSelectedKeys={setSelectedProjectIds} selectedKeys={selectedProjectIds} />
                <AllOptionsDropdown options={userFilterOptions} setSelectedKeys={setSelectedUserIds} selectedKeys={selectedUserIds} />
            </Stack>
        </Stack>
    );
};

const TOGGLE_ALL_KEY = 'TOGGLE_ALL_KEY';
const AllOptionsDropdown = ({
    options,
    selectedKeys,
    setSelectedKeys,
}: {
    options: IDropdownOption<any>[];
    selectedKeys: string[];
    setSelectedKeys: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
    const allSelected = useMemo(() => options.length && options.every(option => selectedKeys.includes(option.key as string)), [options, selectedKeys]);

    const keys = useMemo(() => {
        const keys = [...selectedKeys];
        if (allSelected) {
            keys.unshift(TOGGLE_ALL_KEY);
        }
        return keys;
    }, [allSelected, selectedKeys]);

    const opts = useMemo((): IDropdownOption<any>[] => {
        return [
            {
                key: TOGGLE_ALL_KEY,
                text: allSelected ? 'Deselect all' : 'Select all',
            },
            ...options,
        ];
    }, [allSelected, options]);

    const onChange = useCallback(
        (e: any, opt?: IDropdownOption<any>) => {
            if (opt) {
                if (opt.selected) {
                    if (opt.key === TOGGLE_ALL_KEY) {
                        setSelectedKeys(options.map(opt => opt.key as string));
                    } else {
                        setSelectedKeys(states => [...states, opt.key as string]);
                    }
                } else {
                    if (opt.key === TOGGLE_ALL_KEY) {
                        setSelectedKeys([]);
                    } else {
                        setSelectedKeys(states => states.filter(state => state !== opt.key));
                    }
                }
            }
        },
        [options, setSelectedKeys],
    );

    return (
        <FilterDropdown
            options={opts}
            onChange={onChange}
            selectedKeys={keys}
            multiSelect
            styles={{ root: { width: 180 } }}
            onRenderTitle={onRenderTitleWithDeselect}
        />
    );
};

const onRenderTitleWithDeselect: IRenderFunction<IDropdownOption<any>[]> = (props, defaultRender) => {
    return defaultRender(props.filter(opt => opt.key !== TOGGLE_ALL_KEY));
};
