import React from 'react';
import { Outlet } from 'react-router';
import { useUiContext } from '../../components/Contexts/UiContext';
import { UserNotActive } from '../../_pages/UserNotActive';

export const UserActiveRoute = ({ children }) => {
    const uiCtx = useUiContext();

    if (uiCtx.user.user?.isNotActive) {
        return <UserNotActive />;
    }

    return children ? children : <Outlet />;
};
