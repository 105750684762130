import { Dropdown, Icon, IconButton, IDropdownOption, PrimaryButton, Stack, TextField, TooltipHost } from '@fluentui/react';
import React, { useEffect, useState, useMemo, ReactElement, useRef } from 'react';
import { useUiContext } from '../components/Contexts/UiContext';
import { ApproverCustomFilter } from '../Model/NewModels/ApproverCustomFilter';
import { createNewGuid } from '../components/Utils';

interface propsApproverCustomFiltersContainer {
    currentURPStateFilterArr: string[];
    currentUserFilterArr: string[];
    customFilterChanged: boolean;
    applyFilter: (appliedFilter: ApproverCustomFilter) => void;
    filterSaved: () => void;
}

function ApproverCustomFiltersContainer(props: propsApproverCustomFiltersContainer) {
    const uiCtx = useUiContext();

    const [saveAsInitiated, setSaveAsInitiated] = useState<boolean>(false);
    const [renameInitiated, setRenameInitiated] = useState<boolean>(false);
    const [nameInputValue, setNameInputValue] = useState<string>('');
    const [customFilters, setCustomFilters] = useState<ApproverCustomFilter[]>([]);
    const [selectedCustomFilter, setSelectedCustomFilter] = useState<string>(null);

    const [currentURPStateFilterArr, setCurrentURPStateFilterArr] = useState<string[]>(props.currentURPStateFilterArr);
    const [currentUserFilterArr, setCurrentUserFilterArr] = useState<string[]>(props.currentUserFilterArr);

    const [filterChanged, setFilterChanged] = useState<boolean>(props.customFilterChanged);

    const noCustomFilterOption = { key: 'nocustomfilter', text: 'No custom filter' };

    const itemStyles: React.CSSProperties = {
        alignItems: 'left',
        display: 'flex',
        height: 40,
        width: 1000,
        justifyContent: 'left',
    };

    useEffect(() => {
        uiCtx.timeApi.getApproverCustomFilterByUser().then((userACFs: ApproverCustomFilter[]) => {
            setCustomFilters(userACFs ?? []);
            if (userACFs.length > 0) {
                setSelectedCustomFilter(userACFs.filter(_ => _.default)[0]?.id ?? noCustomFilterOption.key);
            } else {
                setSelectedCustomFilter(noCustomFilterOption.key);
            }
        });
    }, []);

    useEffect(() => {
        setCurrentURPStateFilterArr(props.currentURPStateFilterArr);
        setCurrentUserFilterArr(props.currentUserFilterArr);
    }, [props.currentURPStateFilterArr, props.currentUserFilterArr]);

    useEffect(() => {
        if (props.customFilterChanged === null || props.customFilterChanged === undefined) return;
        setFilterChanged(props.customFilterChanged);
    }, [props.customFilterChanged]);

    useEffect(() => {
        if (customFilters.length < 1 || selectedCustomFilter === null) {
            props.applyFilter(null);
            return;
        }
        const filterToApply = customFilters.filter(_ => _.id === selectedCustomFilter)[0];
        if (filterToApply === null || filterToApply === undefined) {
            props.applyFilter(null);
            return;
        }
        props.applyFilter(filterToApply);
    }, [selectedCustomFilter]);

    // const customFilterChanged = (option: IDropdownOption<any>, index?: number) => {

    // }

    const customFilterSaveAs = async () => {
        const filterName = nameInputValue;
        const cURPSF = currentURPStateFilterArr.filter(_ => _ != 'selectalloption');
        const cUsersF = currentUserFilterArr.filter(_ => _ != 'selectalloption');

        let newUid = null;
        try {
            newUid = (crypto as any).randomUUID();
        } catch (err) {
            newUid = createNewGuid();
        }

        const newACF = {
            id: newUid,
            name: filterName,
            default: false,
            userId: uiCtx.user.user.id,
            urpStateFilter: cURPSF,
            approverUsersFilter: cUsersF,
        } as ApproverCustomFilter;

        const success = await uiCtx.timeApi.saveAsApproverCustomFilter(newACF);

        if (success) {
            setCustomFilters(prev => [...prev, newACF]);
            setSelectedCustomFilter(newUid);
        }

        setNameInputValue('');
        setSaveAsInitiated(false);
    };

    const customFilterSave = async () => {
        const filterToSaveId = selectedCustomFilter;
        const cURPSF = currentURPStateFilterArr.filter(_ => _ != 'selectalloption');
        const cUsersF = currentUserFilterArr.filter(_ => _ != 'selectalloption');

        const changedACF = {
            id: filterToSaveId,
            urpStateFilter: cURPSF,
            approverUsersFilter: cUsersF,
        } as ApproverCustomFilter;

        const success = await uiCtx.timeApi.saveApproverCustomFilter(changedACF);

        if (success) {
            setCustomFilters(prev =>
                prev.map(filter => {
                    if (filter.id === filterToSaveId) {
                        filter.urpStateFilter = changedACF.urpStateFilter;
                        filter.approverUsersFilter = changedACF.approverUsersFilter;
                    }
                    return filter;
                }),
            );
            props.filterSaved();
        }
    };

    const customFilterRename = async () => {
        const filterName = nameInputValue;
        const filterToRenameId = selectedCustomFilter;

        const success = await uiCtx.timeApi.renameApproverCustomFilter(filterToRenameId, filterName);

        if (success) {
            setCustomFilters(prev =>
                prev.map(filter => {
                    if (filter.id === filterToRenameId) filter.name = filterName;
                    return filter;
                }),
            );
        }

        setNameInputValue('');
        setRenameInitiated(false);
    };

    const customFilterSetDefault = async (id: string) => {
        if (id === noCustomFilterOption.key) id = null;
        const success = await uiCtx.timeApi.setApproverDefaultFilter(id);
        if (success) {
            setCustomFilters(prev =>
                prev.map(filter => {
                    if (filter.id === id) {
                        filter.default = true;
                    } else {
                        filter.default = false;
                    }
                    return filter;
                }),
            );
        }
    };

    const customFilterDelete = async (id: string) => {
        const success = await uiCtx.timeApi.deleteApproverCustomFilter(id);
        if (success) {
            setCustomFilters(prev => [...prev.filter(_ => _.id !== id)]);
        }
    };

    return (
        <Stack horizontal horizontalAlign="space-between" styles={{ root: { width: '100%' } }}>
            <span style={itemStyles}>
                <Dropdown
                    placeholder="No custom filter"
                    options={[
                        noCustomFilterOption,
                        ...customFilters.map((cf: ApproverCustomFilter) => {
                            return { key: cf.id, text: cf.name };
                        }),
                    ]}
                    selectedKey={selectedCustomFilter}
                    styles={{ dropdown: { width: 354, margin: '0px 15px 0px 0px' } }}
                    onChange={(e, o, i) => {
                        setSelectedCustomFilter(o.key as string);
                    }}
                    onRenderOption={(props, defaultRender) => {
                        let isDefault = (customFilters.filter(_ => _.default)[0]?.id ?? null) === props.key;
                        const isSelected = selectedCustomFilter === props.key;
                        const isNoCustomFilter = props.key === 'nocustomfilter';
                        if (isNoCustomFilter && customFilters.filter(_ => _.default).length < 1) isDefault = true;
                        const favoriteIconFriendlyName = isDefault ? 'FavoriteStarFill' : 'FavoriteStar';
                        return (
                            <>
                                {defaultRender(props)}
                                <div style={{ position: 'absolute', right: '0px' }}>
                                    <IconButton
                                        iconProps={{ iconName: favoriteIconFriendlyName }}
                                        onClick={e => {
                                            e.stopPropagation();
                                            if (!isDefault) {
                                                customFilterSetDefault(props.key as string);
                                            } else {
                                                customFilterSetDefault(null);
                                            }
                                        }}
                                    />
                                    {isNoCustomFilter ? (
                                        <></>
                                    ) : isSelected ? (
                                        <TooltipHost
                                            content={
                                                <div>
                                                    Currently applied filter can't be deleted
                                                    <br />
                                                    Apply another filter first
                                                </div>
                                            }
                                            closeDelay={250}
                                        >
                                            <IconButton
                                                iconProps={{ iconName: 'Delete' }}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }}
                                                styles={{
                                                    icon: { color: 'rgb(200, 200, 200) !important' },
                                                    iconHovered: { color: 'rgb(200, 200, 200) !important' },
                                                    iconDisabled: { color: 'rgb(200, 200, 200) !important' },
                                                }}
                                            />
                                        </TooltipHost>
                                    ) : (
                                        <IconButton
                                            iconProps={{ iconName: 'Delete' }}
                                            onClick={e => {
                                                e.stopPropagation();
                                                if (!isSelected) customFilterDelete(props.key as string);
                                            }}
                                        />
                                    )}
                                </div>
                            </>
                        );
                    }}
                    onRenderTitle={(props, defaultRender) => {
                        let isDefault = (customFilters.filter(_ => _.default)[0]?.id ?? null) === props[0].key;
                        const isNoCustomFilter = props[0].key === 'nocustomfilter';
                        if (isNoCustomFilter && customFilters.filter(_ => _.default).length < 1) isDefault = true;
                        const favoriteIconFriendlyName = isDefault ? 'FavoriteStarFill' : 'FavoriteStar';
                        return (
                            <div style={{ display: 'flex', position: 'relative' }}>
                                <div>
                                    {defaultRender(props)}
                                    {filterChanged && ' * (Modified)'}
                                </div>
                                <div style={{ position: 'absolute', right: '0px' }}>
                                    <Icon
                                        iconName={favoriteIconFriendlyName}
                                        styles={{ root: { color: 'rgb(96, 97, 170)' } }}
                                        onClick={e => {
                                            e.stopPropagation();
                                            if (!isDefault) {
                                                customFilterSetDefault(props[0].key as string);
                                            } else {
                                                customFilterSetDefault(null);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    }}
                />
                {!saveAsInitiated && !renameInitiated && (
                    <PrimaryButton
                        text="Save"
                        width={100}
                        styles={{ root: { margin: '0px 15px 0px 0px' } }}
                        disabled={selectedCustomFilter === null || !filterChanged || selectedCustomFilter === noCustomFilterOption.key}
                        onClick={customFilterSave}
                    />
                )}
                {(saveAsInitiated || renameInitiated) && (
                    <TextField
                        placeholder="New filter name"
                        width={300}
                        styles={{ root: { margin: '0px 15px 0px 0px' } }}
                        value={nameInputValue}
                        onChange={(e, n) => setNameInputValue(n)}
                    />
                )}
                {saveAsInitiated ? (
                    <></>
                ) : renameInitiated ? (
                    <PrimaryButton
                        text="Rename"
                        width={100}
                        styles={{ root: { margin: '0px 15px 0px 0px' } }}
                        disabled={selectedCustomFilter === null || selectedCustomFilter === noCustomFilterOption.key}
                        onClick={customFilterRename}
                    />
                ) : (
                    <PrimaryButton
                        text="Rename"
                        width={100}
                        styles={{ root: { margin: '0px 15px 0px 0px' } }}
                        disabled={selectedCustomFilter === null || selectedCustomFilter === noCustomFilterOption.key}
                        onClick={() => setRenameInitiated(true)}
                    />
                )}
                {renameInitiated ? (
                    <></>
                ) : saveAsInitiated ? (
                    <PrimaryButton text="Save as" width={100} styles={{ root: { margin: '0px 15px 0px 0px' } }} onClick={customFilterSaveAs} />
                ) : (
                    <PrimaryButton text="Save as" width={100} styles={{ root: { margin: '0px 15px 0px 0px' } }} onClick={() => setSaveAsInitiated(true)} />
                )}
                {(saveAsInitiated || renameInitiated) && (
                    <PrimaryButton
                        text="Cancel"
                        width={300}
                        styles={{ root: { margin: '0px 15px 0px 0px' } }}
                        onClick={() => {
                            setSaveAsInitiated(false);
                            setRenameInitiated(false);
                        }}
                    />
                )}
            </span>
        </Stack>
    );
}

export default ApproverCustomFiltersContainer;
