import { createCtx } from '../../../../../components/Contexts/createCtx';

export type ScrollToHandler = (info: {
    //
    x?: number;
    y?: number;
    columnIndex?: number;
    rowIndex?: number;
    behavior?: 'smooth' | 'auto';
    align?: 'center' | 'auto' | 'end' | 'start';
}) => void;
export type ScrollToContext = ScrollToHandler;
export const [useScrollToContext, ScrollToContextProvider] = createCtx<ScrollToContext>();
