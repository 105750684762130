import { Outlet } from 'react-router';
import { TimeError } from '../../Model/TimeError';
import { TimeWarning } from '../../Model/TimeWarning';
import { useUiContext } from '../Contexts/UiContext';
import { ErrorDialog } from '../ErrorDialog';
import NavHeader from '../NavHeader';
import { WarningDialog } from '../WarningDialog';
import React, { Suspense } from 'react';
import { ErrorBoundary } from '../../_components/ErrorBoundary';
import { TimeSpinner } from '../../_components/Loader/TimeSpinner';

export const Layout: React.FC = () => {
    const uiCtx = useUiContext();

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
                <NavHeader />
                <div className="main">
                    <ErrorBoundary>
                        <Suspense fallback={<TimeSpinner />}>
                            <Outlet />
                        </Suspense>
                    </ErrorBoundary>
                </div>
            </div>
            {uiCtx.timeError && uiCtx.timeError instanceof TimeError && <ErrorDialog error={uiCtx.timeError} />}
            {uiCtx.timeError && uiCtx.timeError instanceof TimeWarning && <WarningDialog warning={uiCtx.timeError} />}
        </>
    );
};
