import { makeStyles } from "@fluentui/react";
import * as React from "react";
// import { makeStyles } from "@fluentui/react-theme-provider";

interface IProps {};

export const PageWrapper: React.FC<IProps> = (props) => {
    const classes = getStyles();

    return (
        <div className={classes.root}>
            { props.children }
        </div>
    )
};


const getStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        display: "flex",
        flexGrow: "1",
        flexDirection: "column",
        width: "100%",
        height: "100%",
    },
}));
