import React from 'react';
// import { makeStyles, useTheme } from '@fluentui/react-theme-provider';
// import { mergeStyles } from 'office-ui-fabric-react';
import { mobileConstants } from '../../mobileConstants';
import { makeStyles } from '@fluentui/react';
// import { useGetStyles, makeStyles } from '../../hooks/useGetStyles';

interface IProps {
    grow?: true | number;
    // noSideSpacing?: boolean;
    // maxWidth?: "medium" | "large";
    // isDetailslist?: boolean;
    // noPadding?: boolean;
    // noPaddingBottom?: boolean;
    noPadding?: boolean;
    noPaddingTop?: boolean;
    // alignContent?: "center" | "flex-start" | "flex-end" | "stretch";
    // stickyTop?: number;
    // detailslistStickyHeader?: boolean;
}

export const ContentSection: React.FC<IProps> = (props) => {
    // export const ContentSection: React.FC<IProps> = React.forwardRef((props, ref: any) => {

    // const theme = useTheme();
    // // const classes = useGetStyles(styles, props);
    const growStyle = typeof props.grow === "number" ? { flexGrow: props.grow } : typeof props.grow === "boolean" && props.grow ? { flexGrow: 1 } : {};
    const paddingStyle = props.noPadding ? { padding: 0 } : {};
    const paddingTopStyle = props.noPaddingTop ? { paddingTop: 0 } : {};
    // const alignStyle =  { alignItems: props.alignContent ? props.alignContent : "center" };
    // const stickyTopStyle: React.CSSProperties = props.stickyTop !== undefined ? { top: props.stickyTop, position: "sticky", zIndex: 2 } : {};

    // const detailslistStickyHeaderClass = mergeStyles({".ms-DetailsList-headerWrapper": { position: "sticky", top: 0, zIndex: 2 }});

    const classes = getStyles();

    return (
        <section className={classes.root} style={{...paddingTopStyle, ...paddingStyle}}>
            {/* <div className="flex_content" style={{...growStyle, ...paddingStyle}}> */}
            <div className="flex_content" style={{...growStyle}}>
                {props.children}
            </div>
        </section>
    );
};

const getStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: `${theme.spacing.m} ${mobileConstants.sideSpacing}px`,
    },
}));

// return (
//     <section className={`content_section${props.isDetailslist ? " is_detailslist" : " side_spacing"}${props.detailslistStickyHeader ? ` ${detailslistStickyHeaderClass}` : ""}${(props.grow === true || props.isDetailslist) ? " grow1" : ""}${(props.noPadding || props.noPaddingTop || props.isDetailslist) ? "" : " padding_top"}${(props.noPadding || props.noPaddingBottom || props.isDetailslist) ? "" : " padding_bottom"}`} style={{ ...growStyle, ...stickyTopStyle, backgroundColor: theme.semanticColors.bodyBackground }}>
//         <div className="flex_content" style={alignStyle}>
//             { props.children }
//         </div>
//     </section>
// );


// const styles = makeStyles((theme, props) => ({
//     root: [
//         'content-section',
//         {
//             flexGrow: 1,
//             // flexGrow: String(props.grow && props.grow),
//             // padding: theme.spacing.m,
//             display: "flex",
//             flexDirection: "column",
//             width: '100%',
//             position: 'relative',
//             margin: "0 auto",
//             padding: props.noPadding ? 0 : props.padding ? `${props.padding}` : `${theme.spacing.m} ${theme.spacing.l2}`,
//             maxWidth: props.maxWidth && props.maxWidth,
//             maxHeight: props.maxHeight && props.maxHeight,
//         }
//     ],
// }));