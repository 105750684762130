import React, { useCallback, useEffect, useRef, useState } from 'react';
import TimeRow from '../Model/TimePeriodRowM';
import ReportPeriodRowM from '../Model/ReportPeriodRowM';
import TimePeriod from './TimePeriod';
import Search from './Search/Search';
import { getEmptyGuid, createNewGuid, ShowRememberSubmitCallout, WorkDaysTypes } from './Utils';
import { TimeGridMode } from '../Model/TimeGridMode';
import { Label, MessageBar, MessageBarType, PrimaryButton, Stack } from '@fluentui/react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import TimeStatePicker from './TimeStatePicker';
import PeriodPicker from './PeriodPicker';
import { QuotesLoader } from './QuotesLoader';
import { TimeState } from '../Model/State';
import { timeApi, WorkDay } from '../Workaround/TimeApiWorkaround';
import { useUiContext } from './Contexts/UiContext';
import PeriodDay from '../Model/PeriodDay';
import Period from '../Model/Period';
import WorkType from '../Model/NewModels/WorkType';
import { CellContextProvider } from './Contexts/CellContext';
import { GetValidatorParameters } from '../Validation/helpers';
import { ValidateResponse, Validator } from '../Validation/Validator';
import { ValidationWarningDialog } from '../Validation/Components/ValidationWarningDialog';
import { TimeWarning } from '../Model/TimeWarning';
import { PageHeader } from './LayoutElements/PageHeader';
import { PageContent } from './LayoutElements/PageContent';
import { useTheme } from '@fluentui/react';
import { ContentSection } from './LayoutElements/ContentSection';
import { InformationDialog } from './InformationDialog';
import { TimeInformation } from '../Model/TimeInformation';
import { ValidationErrors } from '../Validation/Components/ValidationErrors';
import { TFTMainCoachmarks } from './Guide/Coachmarks/TFTMainCoachmarks';
import { TimePeriodWeekOverview } from './WeekOverview/TimePeriodWeekOverview';
import { ApiCalls } from '../api/api';
import { RegisterTimeDto, TimeEntry, UserReportingPeriodState } from '../api/generated/data-contracts';

// TODO: fix
const showWeekOverview = false;

function Time() {
    const theme = useTheme();
    const uiCtx = useUiContext();

    const [selectedTimePeriod, setSelectedTimePeriod] = useState<ReportPeriodRowM>();
    const [today, setToday] = useState<Date>(null);
    const [onlineprojects, setOnlineProjects] = useState<TimeRow[]>([]);
    const [filteredprojects, setFilteredProjects] = useState<TimeRow[]>([]);
    const [mytimeprojects, setMytimeprojects] = useState<TimeRow[]>([]);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [periodIsClosed, setPeriodIsClosed] = useState<boolean>(false);
    const [searchtext, setSearchtext] = useState<string>('');
    const [loadingcomplete, setLoadingcomplete] = useState<boolean>(false);
    const [periodloadingcomplete, setPeriodloadingcomplete] = useState<boolean>(true);
    // const [allprojectsexpaneded, setAllprojectsexpaneded] = useState<boolean>(false);
    const [hideDublicateTimeDlg, setHideDublicateTimeDlg] = useState<boolean>(true);
    const [timestate, setTimestate] = useState<TimeState>(TimeState.open);
    const [periodStart, setPeriodStart] = React.useState<Date>(new Date());
    const [periodEnd, setPeriodEnd] = React.useState<Date>(new Date());
    const [periodDeadlineDate, setPeriodDeadlineDate] = React.useState<Date>(null);
    const [searchActivate, setSearchActivate] = React.useState<number>(0);
    const [comment, setComment] = useState<string>('');
    const [periodNotCreated, setPeriodNotCreated] = useState(null);
    const [allPeriods, setAllPeriods] = useState<Period[]>(null);
    const [validationErrors, setValidationErrors] = useState<ValidateResponse[]>([]);
    const [showValidationWarningDialog, setShowValidationWarningDialog] = useState<boolean>(false);
    const [showInformationDialog, setShowInformationDialog] = useState<boolean>(false);
    const [informationDialogInformation, setInformationDialogInformation] = useState<TimeInformation>(null);

    const [lastPeriodNotCreated, setLastPeriodNotCreated] = useState(null);
    const [lastPeriodIsClosed, setLastPeriodIsClosed] = useState<boolean>(false);
    const [lastPeriodEnd, setLastPeriodEnd] = React.useState<Date>(null);
    const [lastPeriodState, setLastPeriodState] = React.useState<TimeState>(TimeState.open);

    const validator = useRef(new Validator()).current;

    useEffect(() => {
        //MOVE
        (window as any).validator = validator;

        const rules = uiCtx.enabledRules.filter(_ => _.enabled);

        rules.forEach(rule => {
            validator.addRule(rule.name);
        });
    }, []);

    useEffect(() => {
        uiCtx.timeApi.getPeriods().then(periods => {
            setAllPeriods(periods);
        });
    }, []);

    const currentday = new Date();

    useEffect(() => {
        console.debug('Time.tsx ready');
        setLoadingcomplete(false);
    }, []);

    useEffect(() => {
        let today = new Date();
        today = new Date(today.getFullYear(), today.getMonth(), today.getDate());

        timePeriodSelected(today, today);
    }, []);

    const addTaskFromSearch = (time: TimeRow, pinned: boolean, mode: TimeGridMode) => {
        const newmytime = new TimeRow(
            time.key,
            time.timeId,
            time.projectId,
            time.projectName,
            time.projectNumber,
            time.projectSource,
            false,
            time.taskId,
            time.taskName,
            time.taskStart,
            time.taskEnd,
            false,
            time.taskIsDefault,
            time.taskDescription,
            time.pinned,
            '',
            time.projectOrder,
            time.taskOrder,
            time.plannedWork,
            time.remainingWork,
            time.actualWork,
            time.timeEntries,
            time.workType,
            time.projectWorkType,
        ); //TODO: Choose worktype

        mytimeprojects.forEach(mytime => {
            if (mytime.projectId === newmytime.projectId && mytime.taskId === newmytime.taskId) {
                mytime.changetoken = '' + Math.random();
            }
        });

        // newmytime.workType = GetNextAviableWorkType(uiCtx.allowedWorkTypes, mytimeprojects, time);

        setMytimeprojects(mytimetasks => [...mytimetasks, newmytime].sort((a: TimeRow, b: TimeRow) => (a.projectName > b.projectName ? -1 : 1)));
    };

    const pinTime = (time: TimeRow, pinned: boolean, mode: TimeGridMode) => {
        uiCtx.timeApi.setPinned(time.taskId, pinned, time.workType?.id);

        //We need to update the state (This is used to chose pin state on assignment with new workType)
        setMytimeprojects(mp =>
            mp.map(timeRow => {
                if (timeRow.key !== time.key) {
                    return timeRow;
                }
                return { ...timeRow, pinned: pinned };
            }),
        );
    };

    const DeleteTime = (time: TimeRow, perioddays: any[]) => {
        console.debug('Should delete row');

        // DELETE TIME ROW TIME
        const weekDays = perioddays; //.filter(p => IsWeekDay(p.date));
        console.log('TotalCellChange should be propagated and saved', 0, 0);

        // if (weekDays.length == 0) weekDays = perioddays;

        //Ugly Assignment not created fix
        if (time.timeId === getEmptyGuid()) {
            const [firstDay, ...rest] = weekDays;

            const selectedTimeEntry = time.timeEntries?.filter(te => new Date(te.date).getTime() === firstDay.date.getTime())[0];

            onCellUpdated(time, firstDay, selectedTimeEntry, 0, undefined, selectedTimeEntry?.flagged, 0, 0, () => {
                // setdatasaved(false);
                console.debug('Data saved false');
            });

            setTimeout(() => {
                rest.forEach(async (periodDay, index) => {
                    const selectedTimeEntry = time.timeEntries?.filter(te => new Date(te.date).getTime() === periodDay.date.getTime())[0];

                    onCellUpdated(time, periodDay, selectedTimeEntry, 0, undefined, selectedTimeEntry?.flagged, 0, 0, () => {
                        console.debug('Data saved false');
                    });
                });
            }, 500);

            return;
        }

        weekDays.forEach(async periodDay => {
            const selectedTimeEntry = time.timeEntries?.filter(te => new Date(te.date).getTime() === periodDay.date.getTime())[0];
            onCellUpdated(time, periodDay, selectedTimeEntry, 0, undefined, selectedTimeEntry?.flagged, 0, 0, () => {
                console.debug('Data saved false');
            });
        });

        // UNPIN TASK
        uiCtx.timeApi.setPinned(time.taskId, false, time.workType?.id);

        setMytimeprojects(mytimeprojects.filter(_ => _ !== time));

        // timeApi.deleteTimeRow(time.taskId, time.workType?.id);

        // //We need to update the state (This is used to chose pin state on assignment with new workType)
        // setMytimeprojects((mp) => mp.map((timeRow) => {
        //     if (timeRow.key != time.key) return timeRow;
        //     return { ...timeRow, pinned: pinned }
        // }))
    };

    const onCellUpdated = async (
        time: TimeRow,
        day: PeriodDay,
        te: any,
        value: number,
        comment: string,
        flagged: boolean,
        mode: TimeGridMode,
        prevVal: number,
        saveSuccess: Function,
    ): Promise<void> => {
        console.debug('saving', new Date());

        //this is for total update
        if (comment === undefined) comment = te?.comment;

        const response = null;

        // const res: RegisterTimeResponseDto = await uiCtx.timeApi.updateTime(time.taskId, day.date.removeTimeZone(), value, comment, flagged, selectedTimePeriod.reportPeriodId, time.workType?.id || getEmptyGuid());
        ApiCalls.registerTimeEntry({
            taskId: time.taskId,
            date: day.date.removeTimeZone().toISOString(),
            hours: value,
            comment,
            flagged,
            userReportingPeriodId: selectedTimePeriod.reportPeriodId,
            workTypeId: time.workType?.id || getEmptyGuid(),
        }).then(response => {
            console.log('saved', new Date());
            if (saveSuccess != undefined && saveSuccess != null) {
                saveSuccess();
            }

            switch (response.data.registrationStatusCode) {
                case 'Success':
                    saveSuccess();
                    break;
                case 'PeriodIsClosed':
                    uiCtx.setTimeError(new TimeWarning('Period is closed', 'The period for the changed timesheet has been closed. Please refresh.'));
                    return;
                case 'InsufficientPermissions':
                    uiCtx.setTimeError(new TimeWarning('Insufficient permissions', 'You do not have permission to do this. Please refresh.'));
                    return;
                case 'DbError':
                    uiCtx.setTimeError(new TimeWarning('Database error', 'Something tried to violate a database constraint. Please refresh and try again.'));
                    return;
                default:
                    uiCtx.setTimeError(
                        new TimeWarning(
                            'Unknown Error',
                            'An unknown error occoured, please refresh and try again. Report this, if issue continues or reappears.',
                        ),
                    );
                    return;
            }
        });

        //CHF -> This may decrese performance a lot
        setMytimeprojects(mp => {
            const row = mp.find(_ => _.key === time.key);

            //Fix to see if created in db
            row.timeId = undefined;

            //CHF -> Find another place to do this?
            if (!te) {
                //TODO: FIX!
                te = {
                    id: '',
                    date: day.date,
                    hours: value,
                    comment: comment,
                    flagged: flagged,
                    userReportingPeriod: selectedTimePeriod,
                    assignment: { workType: row.workType },
                };

                row.timeEntries.push(te);
            }

            row.timeEntries = row.timeEntries.map(t => {
                if (new Date(t.date).getTime() !== day.date.getTime()) return t;
                t.hours = value;
                t.comment = comment;
                t.flagged = flagged;
                return t;
            });
            if (prevVal < value) {
                row.actualWork += value - prevVal;
            } else {
                row.actualWork += (prevVal - value) * -1;
            }

            return mp.map(tr => {
                if (tr.key !== time.key) return tr;
                if (response) row.key = response.assignment.id;
                return row;
            });
        });
    };

    const onCellWeekUpdated = async (
        time: TimeRow,
        periodDays: PeriodDay[],
        te: any,
        cellValue: number,
        allCellsValue: number,
        comment: string,
        flagged: boolean,
        prevVal: number,
        saveSuccess: Function,
    ): Promise<void> => {
        // console.debug('saving', new Date());

        //this is for total update
        if (comment === undefined) {
            comment = te?.comment;
        }

        const response = null;

        // uiCtx.timeApi
        //     .updateTime(time.taskId, day.date.removeTimeZone(), value, comment, selectedTimePeriod.reportPeriodId, time.workType?.id || getEmptyGuid())
        //     .then((te) => {
        //         console.debug('saved', new Date(), te);
        //         if (te == null) {
        //             uiCtx.setTimeError(new TimeWarning('Period is close', 'The current period has been closed. Please refresh the grid.'));
        //         } else if (saveSuccess !== undefined && saveSuccess != null) {
        //             saveSuccess();
        //         }
        //     });
        uiCtx.timeApi
            .saveCellsPeriodOverview({
                taskId: time.taskId,
                days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
                hours: allCellsValue,
                comment,
                flagged,
                userReportingPeriodId: selectedTimePeriod.reportPeriodId,
                workTypeId: time.workType.id,
            })
            .then(res => {
                // console.debug('saved', new Date(), te);
                if (res == null) {
                    uiCtx.setTimeError(new TimeWarning('Period is close', 'The current period has been closed. Please refresh the grid.'));
                } else if (saveSuccess !== undefined && saveSuccess != null) {
                    saveSuccess();
                }
            });

        //CHF -> This may decrese performance a lot
        setMytimeprojects(mp => {
            const row = mp.find(_ => _.key === time.key);

            //Fix to see if created in db
            row.timeId = undefined;

            //CHF -> Find another place to do this?
            const newTimeEntriesToAdd = [];
            periodDays.forEach(day => {
                if (!row.timeEntries.find(timeEntry => new Date(timeEntry.date).getTime() == day.date.getTime())) {
                    newTimeEntriesToAdd.push({
                        id: '',
                        date: day.date,
                        hours: cellValue,
                        comment: comment,
                        flagged: flagged,
                        userReportingPeriod: selectedTimePeriod,
                        assignment: { workType: row.workType },
                    });
                }
            });
            row.timeEntries = [...row.timeEntries, ...newTimeEntriesToAdd];

            row.timeEntries = row.timeEntries.map(t => {
                t.hours = cellValue;
                t.comment = comment;
                t.flagged = flagged;
                return t;
            });

            if (prevVal < allCellsValue) {
                row.actualWork += allCellsValue - prevVal;
            } else {
                row.actualWork += (prevVal - allCellsValue) * -1;
            }

            return mp.map(tr => {
                if (tr.key !== time.key) {
                    return tr;
                }
                if (response) {
                    row.key = response.assignment.id;
                }
                return row;
            });
        });
    };

    const updateWorkType = async (row, workType, changeCompleted) => {
        const oldWorkTypeId = !row.workType ? getEmptyGuid() : row.workType.id;

        // Returns a boolean indicating if a worktype change is allowed on the existing assignment.
        // If not, a new assignment is created with the selected worktype.
        const response = await ApiCalls.changeWorkTypeFromKey({ taskId: row.taskId, workTypeId: oldWorkTypeId, newWorkTypeId: workType.id });
        const canChange = response.data;

        // If the backend indicates a change was made (canChange is false), adjust the frontend accordingly
        if (!canChange) {
            // If there are any time entries or time entries with any hour amount, create a new row with the new work type
            if (row.timeEntries.length > 0) {
                const newTimeRow = new TimeRow(
                    createNewGuid(),
                    getEmptyGuid(),
                    row.projectId,
                    row.projectName,
                    row.projectNumber,
                    row.projectSource,
                    false,
                    row.taskId,
                    row.taskName,
                    row.taskStart,
                    row.taskEnd,
                    false,
                    row.taskIsDefault,
                    row.taskDescription,
                    true,
                    '',
                    row.projectOrder,
                    row.taskOrder,
                    row.plannedWork,
                    row.remainingWork,
                    row.actualWork,
                    [],
                    workType,
                    row.projectWorkType,
                );

                // Add the new row with the updated work type
                setMytimeprojects(tp => [...tp, newTimeRow]);
                changeCompleted();
                return;
            }

            // No time entries, just change the work type
            if (row.timeEntries.length === 0) {
                setMytimeprojects(tp => {
                    return tp.map(r => {
                        if (r.key === row.key) {
                            r.workType = workType;
                        }
                        return r;
                    });
                });
                changeCompleted();
                return;
            }
        }
    };

    //TODO: Refactor to another component
    const handleValidationError = (submit: boolean, submitComment: string = '') => {
        setShowValidationWarningDialog(false);
        if (submit) {
            let state = TimeState.submitted;
            if (!uiCtx.user.user.approver || uiCtx.user.user.autoApprove) {
                state = TimeState.approved;
                setTimeout(() => {
                    setTimestate(TimeState.approved);
                    timestatechanged(TimeState.approved);
                }, 1000);
            }

            setSubmitted(true);
            setTimestate(TimeState.submitted);

            uiCtx.timeApi.updateStatusByPeriod(selectedTimePeriod.reportPeriodId, state, submitComment).then(t => {
                console.log(t);
            });

            // let filtereditems = searchtext ? onlineprojects.filter(i => ((i.taskName.toLowerCase().indexOf(searchtext) > -1) || (i.projectName.toLowerCase().indexOf(searchtext) > -1))) : onlineprojects;

            // setFilteredProjects(filtereditems);
        } else {
            setTimestate(TimeState.open);
        }
    };

    const timestatechanged = async (state: TimeState, submitComment: string = ''): Promise<void> => {
        let nextystate = state;
        if (state === TimeState.submitted) {
            //TODO: Refactor to another component
            const allEntries = mytimeprojects.flatMap(_ => _.timeEntries);
            const validatorParams = GetValidatorParameters(allEntries, uiCtx, mytimeprojects, selectedTimePeriod.reportPeriodId);
            const validationResponses = await validator.validate(validatorParams);
            const valErrors = validationResponses.filter(_ => !_.valid);
            const valRejected = validationResponses.filter(_ => _.rejected);
            setValidationErrors(valErrors);
            if (valRejected.length > 0) {
                setTimestate(TimeState.rejected);
                return;
            }
            if (valErrors.length > 0) {
                setShowValidationWarningDialog(true);
                return;
            }
            //TODO: Refactor to another component END
            if (!uiCtx.user.user.approver || uiCtx.user.user.autoApprove) {
                nextystate = TimeState.approved;
                setTimeout(() => {
                    setTimestate(TimeState.approved);
                    //timestatechanged(TimeState.approved); -- Nextystage handles this.
                }, 1000);
            }
        }

        setSubmitted(state === TimeState.submitted || state === TimeState.approved ? true : false);
        setTimestate(state);
        console.log('timeApi', timeApi);

        // Map TimeState to UserReportingPeriodState
        let stateSet: UserReportingPeriodState;
        switch (state) {
            case TimeState.submitted:
                stateSet = 'Submitted';
                break;
            case TimeState.approved:
                stateSet = 'Approved';
                break;
            case TimeState.rejected:
                stateSet = 'Rejected';
                break;
            default:
                stateSet = 'Open';
                break;
        }

        let nextStateSet: UserReportingPeriodState;
        switch (nextystate) {
            case TimeState.submitted:
                nextStateSet = 'Submitted';
                break;
            case TimeState.approved:
                nextStateSet = 'Approved';
                break;
            case TimeState.rejected:
                nextStateSet = 'Rejected';
                break;
            default:
                nextStateSet = 'Open';
                break;
        }

        await ApiCalls.setReportingPeriodState({
            userReportingPeriodId: selectedTimePeriod.reportPeriodId,
            state: stateSet,
            nextState: nextStateSet,
            submitComment,
        });

        const filtereditems = searchtext
            ? onlineprojects.filter(i => i.taskName.toLowerCase().indexOf(searchtext) > -1 || i.projectName.toLowerCase().indexOf(searchtext) > -1)
            : onlineprojects;

        setFilteredProjects(filtereditems);
    };

    const timePeriodSelected = (start: Date, end: Date) => {
        // console.debug("timePeriodSelected " + start.toLocaleDateString() + " - " + end.toLocaleDateString());

        setPeriodloadingcomplete(false);
        setShowValidationWarningDialog(false);
        setValidationErrors([]);

        const utcStart: any = start.getISOStringMidnight();

        uiCtx.timeApi
            .getPeriodByDates(utcStart)
            // timeApi.getPeriodByDates(nonTimezoneStart, userid)
            .then(rpPeriod => {
                //If period is not created
                setPeriodNotCreated(rpPeriod === null);

                if (rpPeriod === null) {
                    setLoadingcomplete(true);
                    uiCtx.setLoading(false);
                    return;
                }

                if (rpPeriod.comment) {
                    setComment(rpPeriod.comment);
                } else {
                    setComment('');
                }
                // debugger;
                const period = {
                    periodId: rpPeriod.period.id,
                    name: rpPeriod.period.name,
                    state: Number(rpPeriod.state),
                    reportPeriodId: rpPeriod.id,
                    start: rpPeriod.period.startDate,
                    finish: rpPeriod.period.endDate,
                    deadlineDate: rpPeriod.period.deadlineDate,
                    isClosed: rpPeriod.period.state === 1 || rpPeriod.period.state === 2,
                };

                const currentperiod: ReportPeriodRowM = {
                    periodId: period.periodId,
                    name: period.name,
                    state: period.state,
                    reportPeriodId: period.reportPeriodId,
                    start: new Date(period.start),
                    finish: new Date(period.finish),
                    isClosed: period.isClosed,
                    workDays: WorkDaysTypes(rpPeriod.workDays),
                } as ReportPeriodRowM;

                setPeriodStart(currentperiod.start);
                setPeriodEnd(currentperiod.finish);
                setPeriodDeadlineDate(period.deadlineDate);
                console.debug('current period selected', currentperiod);

                setPeriodIsClosed(currentperiod.isClosed);

                if (currentperiod.state === 0) {
                    setTimestate(TimeState.open);
                    setSubmitted(false);
                } else if (currentperiod.state === 1) {
                    setTimestate(TimeState.submitted);
                    setSubmitted(true);
                } else if (currentperiod.state === 3) {
                    setTimestate(TimeState.rejected);
                    setSubmitted(false);
                } else {
                    setTimestate(TimeState.approved);
                    setSubmitted(true);
                }

                setSelectedTimePeriod(currentperiod);

                // This will only happen if period is changed in the UI
                uiCtx.setSelectedTimePeriod(currentperiod);

                if (currentperiod.start <= currentday && currentperiod.finish >= currentday) {
                    setToday(currentday);
                } else {
                    setToday(currentday);
                }

                return currentperiod;
            })
            .then(period => {
                if (!period) {
                    console.log('!period', !period);
                    return;
                }
                // console.log(period);
                uiCtx.timeApi.getTimeByReportPeriodCustomPeriod(period.reportPeriodId).then(json => {
                    // console.log(json);
                    let allItems: TimeRow[] = [];
                    const mytime: TimeRow[] = [];

                    // console.log('order problem', json);
                    json.forEach((time: any, index: number) => {
                        //TODO: Better way to cast dates?
                        allItems.push(
                            new TimeRow(
                                time.assignmentId,
                                time.timeId,
                                time.projectId,
                                time.projectName,
                                time.projectNumber,
                                time.projectSource,
                                time.projectClosed,
                                time.taskId,
                                time.taskName,
                                time.taskStart,
                                time.taskEnd,
                                time.taskClosed,
                                time.taskIsDefault,
                                time.taskDescription,
                                time.pinned,
                                '',
                                time.projectOrder,
                                time.taskOrder,
                                time.plannedWork,
                                time.remainingWork,
                                time.actualWork,
                                time.timeEntries.map(_ => {
                                    return { ..._, date: new Date(_.date) };
                                }),
                                time.workType,
                                time.projectWorkType,
                            ),
                        );

                        if (time.pinned === true || time.timeEntries.some(_ => _.hours !== 0)) {
                            mytime.push(
                                new TimeRow(
                                    time.assignmentId,
                                    time.timeId,
                                    time.projectId,
                                    time.projectName,
                                    time.projectNumber,
                                    time.projectSource,
                                    time.projectClosed,
                                    time.taskId,
                                    time.taskName,
                                    time.taskStart,
                                    time.taskEnd,
                                    time.taskClosed,
                                    time.taskIsDefault,
                                    time.taskDescription,
                                    time.pinned,
                                    '',
                                    time.projectOrder,
                                    time.taskOrder,
                                    time.plannedWork,
                                    time.remainingWork,
                                    time.actualWork,
                                    time.timeEntries.map(_ => {
                                        return { ..._, date: new Date(_.date) };
                                    }),
                                    time.workType,
                                    time.projectWorkType,
                                ),
                            );
                        }
                    });
                    allItems = allItems.sort((a: TimeRow, b: TimeRow) => (a.projectName > b.projectName ? -1 : 1));

                    setMytimeprojects(mytime);
                    setOnlineProjects(allItems);
                    // setFilteredProjects(allItems);
                    setPeriodloadingcomplete(true);
                    setLoadingcomplete(true);
                    uiCtx.setLoading(false);

                    // Delayed
                    uiCtx.timeApi.getPeriods().then(periods => {
                        setAllPeriods(periods);
                        console.log('loading complete was set');
                    });
                });
                return period;
            })
            .then((curPeriod: ReportPeriodRowM) => {
                if (!curPeriod) {
                    console.log('curPeriod', curPeriod);
                    return;
                }
                const lastPeriodStartDate = new Date(curPeriod.start); // Start by setting newPeriodStartDate var to old period start date
                lastPeriodStartDate.setDate(lastPeriodStartDate.getDate() - 1); // Subtract exact one day from old period start date
                const lastPeriodUtcStart: any = lastPeriodStartDate.getISOStringMidnight();
                uiCtx.timeApi
                    .getPeriodByDates(lastPeriodUtcStart)
                    // timeApi.getPeriodByDates(nonTimezoneStart, userid)
                    .then(lrpPeriod => {
                        //If last period is not created
                        setLastPeriodNotCreated(lrpPeriod === null);

                        // debugger;
                        const lPeriod = {
                            periodId: lrpPeriod.period.id,
                            name: lrpPeriod.period.name,
                            state: Number(lrpPeriod.state),
                            reportPeriodId: lrpPeriod.id,
                            start: lrpPeriod.period.startDate,
                            finish: lrpPeriod.period.endDate,
                            isClosed: lrpPeriod.period.state === 1 || lrpPeriod.period.state === 2,
                        };
                        const lastPeriod: ReportPeriodRowM = new ReportPeriodRowM(
                            lPeriod.periodId,
                            lPeriod.name,
                            lPeriod.state,
                            lPeriod.reportPeriodId,
                            new Date(lPeriod.start),
                            new Date(lPeriod.finish),
                            lPeriod.isClosed,
                            WorkDaysTypes(lrpPeriod.workDays),
                        );

                        setLastPeriodEnd(lastPeriod.finish);
                        console.debug('prior period to the selected one', lastPeriod);

                        setLastPeriodIsClosed(lastPeriod.isClosed);

                        if (lastPeriod.state === 0) {
                            setLastPeriodState(TimeState.open);
                        } else if (lastPeriod.state === 1) {
                            setLastPeriodState(TimeState.submitted);
                        } else if (lastPeriod.state === 3) {
                            setLastPeriodState(TimeState.rejected);
                        } else {
                            setLastPeriodState(TimeState.approved);
                        }
                    });
            });
    };

    //TODO: NIV - Make real fix, this is a quickfix for copy timesheet
    const refreshCurrentPeriod = async (repPeriodId: any) => {
        uiCtx.timeApi.getTimeByReportPeriodCustomPeriod(repPeriodId).then(json => {
            console.log(json);
            let allItems: TimeRow[] = [];
            const mytime: TimeRow[] = [];

            console.log('order problem', json);
            json.forEach((time: any, index: number) => {
                //TODO: Better way to cast dates?
                allItems.push(
                    new TimeRow(
                        time.assignmentId,
                        time.timeId,
                        time.projectId,
                        time.projectName,
                        time.projectNumber,
                        time.projectSource,
                        time.projectClosed,
                        time.taskId,
                        time.taskName,
                        time.taskStart,
                        time.taskEnd,
                        time.taskClosed,
                        time.taskIsDefault,
                        time.taskDescription,
                        time.pinned,
                        '',
                        time.projectOrder,
                        time.taskOrder,
                        time.plannedWork,
                        time.remainingWork,
                        time.actualWork,
                        time.timeEntries.map(_ => {
                            return { ..._, date: new Date(_.date) };
                        }),
                        time.workType,
                        time.projectWorkType,
                    ),
                );

                if (time.pinned === true || time.timeEntries.some(_ => _.hours !== 0)) {
                    mytime.push(
                        new TimeRow(
                            time.assignmentId,
                            time.timeId,
                            time.projectId,
                            time.projectName,
                            time.projectNumber,
                            time.projectSource,
                            time.projectClosed,
                            time.taskId,
                            time.taskName,
                            time.taskStart,
                            time.taskEnd,
                            time.taskClosed,
                            time.taskIsDefault,
                            time.taskDescription,
                            time.pinned,
                            '',
                            time.projectOrder,
                            time.taskOrder,
                            time.plannedWork,
                            time.remainingWork,
                            time.actualWork,
                            time.timeEntries.map(_ => {
                                return { ..._, date: new Date(_.date) };
                            }),
                            time.workType,
                            time.projectWorkType,
                        ),
                    );
                }
            });
            allItems = allItems.sort((a: TimeRow, b: TimeRow) => (a.projectName > b.projectName ? -1 : 1));

            setMytimeprojects(mytime);
            setOnlineProjects(allItems);
            setFilteredProjects(allItems);
            setPeriodloadingcomplete(true);
            setLoadingcomplete(true);

            // Delayed
            uiCtx.timeApi.getPeriods().then(periods => {
                setAllPeriods(periods);
                console.log('loading complete was set');
            });
        });
    };

    const handleCopyTimesheet = async () => {
        const response = await ApiCalls.copyTimeEntriesFromPreviousUserPeriod({
            toReportingPeriodId: selectedTimePeriod.reportPeriodId,
        });

        if (!response.ok) {
            setInformationDialogInformation(new TimeInformation('No last period.', 'When copying timesheet from last period, a last period must exist.'));
            setShowInformationDialog(true);
            return;
        }

        if (!response.data) {
            // alert("Your timesheet is not empty, please remove all entries first");
            setInformationDialogInformation(
                new TimeInformation(
                    'Your timesheet is not empty, please remove all entries first.',
                    'When copying timesheet from last period, the current period must have no entries.',
                ),
            );
            setShowInformationDialog(true);
            return;
        } else if (response.data.length === 0) {
            setInformationDialogInformation(
                new TimeInformation(
                    'No time entries from last week, or no matching days between periods.',
                    'When copying timesheet from last period, the days in the periods must match, and time entries must exist in the previous period.',
                ),
            );
            setShowInformationDialog(true);
            return;
        }

        //TODO: NIV - Make real fix, this is a quickfix for copy timesheet (OLD CODE OUTCOMMENTED UNDERNEATH, DO NOT REMOVE YET)
        await refreshCurrentPeriod(selectedTimePeriod.reportPeriodId);

        // let allItems = timeEntries.map((time: any) =>
        //     new TimeRow(time.assignmentId, time.timeId, time.projectId, time.projectName, time.projectNumber, time.projectSource, time.projectClosed, time.taskId, time.taskName, time.taskStart, time.taskEnd, time.taskClosed, time.taskDescription,
        //         time.pinned, "", time.projectOrder, time.taskOrder, time.timeEntries.map(_ => { return { ..._, date: new Date(_.date) } }), time.workType, time.projectWorkType)
        // );
        // allItems = allItems.sort((a: TimeRow, b: TimeRow) => a.projectName > b.projectName ? -1 : 1);

        // let projectTimeRowsNotFromLastWeek = mytimeprojects.filter(mp => !allItems.some(i => i.key == mp.key));

        // setMytimeprojects([...allItems, ...projectTimeRowsNotFromLastWeek]);
    };

    const closeInformationDialog = () => {
        setShowInformationDialog(false);
    };

    const RenderStatuses = () => {
        // PeriodIsClosed overrules all other statuses
        if (periodIsClosed) {
            return (
                <MessageBar
                    messageBarType={MessageBarType.info}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                    truncated={false}
                    overflowButtonAriaLabel="See more"
                >
                    <b>This period is closed.</b>
                </MessageBar>
            );
        } else {
            return (
                <>
                    {timestate === TimeState.open ? <div className="messeagebarreplace"></div> : <></>}
                    {timestate === TimeState.open && validationErrors.length > 0 && (
                        <MessageBar
                            messageBarType={MessageBarType.warning}
                            isMultiline={false}
                            dismissButtonAriaLabel="Close"
                            truncated={false}
                            overflowButtonAriaLabel="See more"
                        >
                            <b>Timesheet is open with warning.</b>{' '}
                            <i>
                                Please review the following comments and submit the timesheet again:{' '}
                                {validationErrors.length === 0 ? comment : <ValidationErrors validationResponses={validationErrors} />}
                            </i>
                        </MessageBar>
                    )}
                    {timestate === TimeState.submitted ? (
                        <MessageBar
                            messageBarType={MessageBarType.info}
                            styles={{ root: { backgroundColor: '#9cee94' } }}
                            isMultiline={false}
                            dismissButtonAriaLabel="Close"
                            truncated={false}
                            overflowButtonAriaLabel="See more"
                        >
                            <b>Timesheet is submitted and are awaiting approval.</b>
                        </MessageBar>
                    ) : (
                        <></>
                    )}
                    {timestate === TimeState.approved ? (
                        <MessageBar
                            messageBarType={MessageBarType.success}
                            styles={{ root: { backgroundColor: '#9cee94' } }}
                            isMultiline={false}
                            dismissButtonAriaLabel="Close"
                            truncated={false}
                            overflowButtonAriaLabel="See more"
                        >
                            <b>Timesheet is approved.</b>
                            {comment === '' ? (
                                <></>
                            ) : (
                                <>
                                    <Label>The following message was given with the approval.</Label> <h5>{comment}</h5>
                                </>
                            )}
                        </MessageBar>
                    ) : (
                        <></>
                    )}
                    {timestate === TimeState.rejected ? (
                        <MessageBar
                            messageBarType={MessageBarType.blocked}
                            styles={{ root: { backgroundColor: '#f39494' } }}
                            isMultiline={false}
                            dismissButtonAriaLabel="Close"
                            truncated={false}
                            overflowButtonAriaLabel="See more"
                        >
                            <Label
                                styles={{
                                    root: {
                                        color: uiCtx.theme.semanticColors.warningBackground,
                                    },
                                }}
                            >
                                Timesheet is rejected.
                            </Label>
                            <Label
                                styles={{
                                    root: {
                                        color: uiCtx.theme.semanticColors.warningBackground,
                                    },
                                }}
                            >
                                Please review the following comments and submit the timesheet again.
                            </Label>
                            {validationErrors.length === 0 ? <h5>{comment}</h5> : <ValidationErrors validationResponses={validationErrors} />}
                        </MessageBar>
                    ) : (
                        <></>
                    )}
                </>
            );
        }
    };

    if (!loadingcomplete) {
        return <QuotesLoader loadingtext="Getting periods" />;
    }

    return (
        <>
            <PageHeader noPaddingBottom>
                <Stack horizontal wrap horizontalAlign="space-between" styles={{ root: { paddingBottom: theme.spacing.m } }} /*className="filterSection"*/>
                    <Stack verticalAlign="center" horizontal>
                        <Search
                            alreadyAddedProjects={mytimeprojects}
                            timeAdd={addTaskFromSearch}
                            active={timestate === TimeState.open || timestate === TimeState.rejected ? true : false}
                        />
                    </Stack>

                    <Stack verticalAlign="center">
                        {allPeriods && (
                            <PeriodPicker
                                periodPicked={timePeriodSelected}
                                defaultStartDate={periodStart}
                                defaultEndDate={periodEnd}
                                allPeriods={allPeriods}
                                currentTimeState={timestate}
                                showRememberSubmitCallout={ShowRememberSubmitCallout(
                                    new Date(lastPeriodEnd).removeTimeZone(),
                                    new Date().removeTimeZone(),
                                    lastPeriodState,
                                    lastPeriodIsClosed,
                                    lastPeriodNotCreated,
                                    new Date(uiCtx.user.user.start),
                                )}
                            />
                        )}
                    </Stack>

                    <TimeStatePicker
                        state={timestate}
                        stateChanged={timestatechanged}
                        periodIsClosed={periodIsClosed}
                        comment={comment}
                        mytimeprojects={mytimeprojects}
                    />
                </Stack>
            </PageHeader>
            {periodNotCreated === true ? (
                <div className="centeredtext">
                    <h2>Period not created (Contact administrator)</h2>
                </div>
            ) : !periodloadingcomplete ? (
                <QuotesLoader loadingtext="Getting timeentries" />
            ) : (
                <CellContextProvider onCellUpdated={onCellUpdated}>
                    <ContentSection noPadding>
                        <div className="messeagebarContainer">
                            <div className="messeagebarContainerInner">
                                <RenderStatuses />
                            </div>
                        </div>
                    </ContentSection>
                    <PageContent>
                        <ContentSection isDetailslist>
                            {uiCtx.gridView === 'period' ? (
                                <TimePeriodWeekOverview
                                    search={searchActivate}
                                    times={mytimeprojects}
                                    onCellWeekUpdated={onCellWeekUpdated}
                                    mode={TimeGridMode.MyTime}
                                    pinTime={pinTime}
                                    deleteTime={DeleteTime}
                                    today={today}
                                    submitted={submitted}
                                    reportPeriodId={selectedTimePeriod.reportPeriodId}
                                    periodIsClosed={periodIsClosed}
                                    startDate={selectedTimePeriod.start}
                                    endDate={selectedTimePeriod.finish}
                                    deadlineDate={periodDeadlineDate}
                                    timesheetstate={timestate}
                                    timesheetmessage={comment}
                                    updateWorkType={updateWorkType}
                                    validationErrors={validationErrors}
                                    copyFromLastWeek={handleCopyTimesheet}
                                />
                            ) : (
                                <TimePeriod
                                    search={searchActivate}
                                    times={mytimeprojects}
                                    onCellUpdated={onCellUpdated}
                                    mode={TimeGridMode.MyTime}
                                    pinTime={pinTime}
                                    deleteTime={DeleteTime}
                                    today={today}
                                    submitted={submitted}
                                    workDays={selectedTimePeriod.workDays}
                                    reportPeriodId={selectedTimePeriod.reportPeriodId}
                                    periodIsClosed={periodIsClosed}
                                    startDate={selectedTimePeriod.start}
                                    endDate={selectedTimePeriod.finish}
                                    deadlineDate={periodDeadlineDate}
                                    timesheetstate={timestate}
                                    timesheetmessage={comment}
                                    updateWorkType={updateWorkType}
                                    validationErrors={validationErrors}
                                    copyFromLastWeek={handleCopyTimesheet}
                                />
                            )}
                        </ContentSection>
                    </PageContent>
                </CellContextProvider>
            )}
            <Dialog
                hidden={hideDublicateTimeDlg}
                onDismiss={() => setHideDublicateTimeDlg(!hideDublicateTimeDlg)}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: 'Task already added',
                    subText: 'The selected task is already added to the active view.',
                }}
            >
                <DialogFooter>
                    <PrimaryButton text="OK" onClick={() => setHideDublicateTimeDlg(!hideDublicateTimeDlg)} />
                </DialogFooter>
            </Dialog>
            <ValidationWarningDialog hidden={!showValidationWarningDialog} handleDialog={handleValidationError} warnings={validationErrors} />
            <InformationDialog show={showInformationDialog} closeDialog={closeInformationDialog} information={informationDialogInformation} />
            {!uiCtx.isTeams && uiCtx.enableCoachmarks && <TFTMainCoachmarks />}
        </>
    );
}

export default Time;
