import { TimeEntry } from '../api/generated/data-contracts';
import { IUiContext } from '../components/Contexts/UiContext';
import TimePeriodRowM from '../Model/TimePeriodRowM';
import { validationRulesParameters } from '../Validation/validationRules';
import { RuleParams } from './Validator';

export const GetValidatorParameters = (timeEntries: TimeEntry[], uiContext: IUiContext, timeRows: TimePeriodRowM[], userPeriodId: string) => {
    const rules = uiContext.enabledRules.filter(_ => _.enabled);

    const ruleParameters = rules.map(rule => ({ ruleName: rule.name, parameters: validationRulesParameters[rule.name] }));

    const rulesWithParamsWithValues = ruleParameters.map(ruleParam => {
        const paramValues = uiContext.allConfig.filter(c => ruleParam.parameters.some(p => p == c.key));

        const defaultParams = [
            { key: 'user', value: uiContext.user },
            { key: 'timeEntries', value: timeEntries },
            { key: 'timeRows', value: timeRows },
            { key: 'uiContext', value: uiContext },
            { key: 'userPeriodId', value: userPeriodId },
        ];

        return { ...ruleParam, values: [...paramValues, ...defaultParams] };
    });

    const mappedParams = values => values.reduce((acc, cur: any) => ({ ...acc, [cur.key]: cur.value }), {});

    const validatorParams: RuleParams = rulesWithParamsWithValues.reduce((acc, cur: any) => ({ ...acc, [cur.ruleName]: mappedParams(cur.values) }), {});

    return validatorParams;
};
