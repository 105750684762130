import { IComboBoxOption, ITag } from '@fluentui/react';

const updateValueFromItems = (items: any[], setState: (value) => void, configName: string, api: any) => {
    const value = items.length == 0 ? null : items[0];
    setState(value);
    // api.setConfig(configName, JSON.stringify(value));
};
const updateValueFromItemsApi = (items: any[], setState: (value) => void, configName: string, api: any) => {
    const value = items.length == 0 ? null : items[0];
    setState(value);
    api.setConfig(configName, JSON.stringify(value));
};
const updateValue = (value: any, setState: (value) => void, configName: string, api: any) => {
    setState(value);
    api.setConfig(configName, JSON.stringify(value));
};

const getFields = async (tableName: string, api: any): Promise<IExpandedComboBoxOption[]> => {
    const fields: IFieldMetadata[] = await api.entityMetadata(tableName);
    return fields.map(field => ({
        key: field.displayName,
        text: field.displayName,
        isLookUp: field.isLookUp,
        name: field.isLookUp ? field.logicalName : field.displayName,
    }));
};

const getTables = async (api: any): Promise<IExpandedComboBoxOption[]> => {
    const tables: tableFromApi[] = await api.listEntities();

    if (tables.length >= 1 && tables[0].entityLogicalName == 'ERROR') {
        throw new Error('Error when fetching tables');
    }

    const tableOptions = tables.map(table => ({
        key: table.entityLogicalName,
        text: table.entityLogicalName,
        logicalCollectionName: table.logicalCollectionName,
        name: table.entityLogicalName,
    }));

    return tableOptions;
};

export const validateForm = (dictionary: IConfigDictionary): boolean => {
    if (dictionary.Project.tableName) {
        if (!dictionary.Project.idColumn || !dictionary.Project.nameColumn) {
            return false;
        }
    }
    if (dictionary.Task.tableName) {
        if (!dictionary.Task.idColumn || !dictionary.Task.nameColumn || !dictionary.Task.projectIdColumn) {
            return false;
        }
    }
    if (dictionary.Assignment.tableName) {
        if (!dictionary.Assignment.idColumn || !dictionary.Assignment.taskIdColumn || !dictionary.Assignment.resourceIdColumn) {
            return false;
        }
        if (dictionary.Assignment.resourceAzureIdAlternateColumnEnabled) {
            if (!dictionary.Assignment.resourceAzureIdTable || !dictionary.Assignment.resourceAzureIdColumn) {
                return false;
            }
        }
    }
    if (dictionary.ProjectTeam.tableName) {
        if (!dictionary.ProjectTeam.idColumn || !dictionary.ProjectTeam.projectIdColumn || !dictionary.ProjectTeam.resourceIdColumn) {
            return false;
        }
        if (dictionary.ProjectTeam.resourceAzureIdAlternateColumnEnabled) {
            if (!dictionary.ProjectTeam.resourceAzureIdTable || !dictionary.ProjectTeam.resourceAzureIdColumn) {
                return false;
            }
        }
    }

    return true;
};

export const GetConfigDic = (configs: any[]) => {
    const configValues = configs;
    const configDic = configValues.reduce((prev, curr) => {
        let value: any = null;
        try {
            value = JSON.parse(curr.value);
        } catch {
            value = curr.value;
        }
        return { ...prev, [curr.key]: value };
    }, {});
    return {
        // Resource: {
        //     tableName: configDic[configObj.Resource.tableName],
        //     idColumn: configDic[configObj.Resource.idColumn],
        //     nameColumn: configDic[configObj.Resource.nameColumn],
        //     enabled: configDic[configObj.Resource.enabled],
        //     principalNameColumn: configDic[configObj.Resource.principalNameColumn],
        //     emailColumn: configDic[configObj.Resource.emailColumn],
        //     azureIdColumn: configDic[configObj.Resource.azureIdColumn],
        // },
        Project: {
            tableName: configDic[configObj.Project.tableName],
            idColumn: configDic[configObj.Project.idColumn],
            nameColumn: configDic[configObj.Project.nameColumn],
            enabled: configDic[configObj.Project.enabled],
            metadataColumn: configDic[configObj.Project.metadataColumn],
            managerColumn: configDic[configObj.Project.managerColumn],
            numberColumn: configDic[configObj.Project.numberColumn],
            closedColumn: configDic[configObj.Project.closedColumn],
            closedValue: configDic[configObj.Project.closedValue],
        },
        Assignment: {
            tableName: configDic[configObj.Assignment.tableName],
            idColumn: configDic[configObj.Assignment.idColumn],
            taskIdColumn: configDic[configObj.Assignment.taskIdColumn],
            resourceIdColumn: configDic[configObj.Assignment.resourceIdColumn],
            enabled: configDic[configObj.Assignment.enabled],
            plannedWorkColumn: configDic[configObj.Assignment.plannedWorkColumn],
            remainingWorkColumn: configDic[configObj.Assignment.remainingWorkColumn],
            rateColumn: configDic[configObj.Assignment.rateColumn],
            resourceAzureIdAlternateColumnEnabled: configDic[configObj.Assignment.resourceAzureIdAlternateColumnEnabled],
            resourceAzureIdTable: configDic[configObj.Assignment.resourceAzureIdTable],
            resourceAzureIdColumn: configDic[configObj.Assignment.resourceAzureIdColumn],
        },
        Task: {
            tableName: configDic[configObj.Task.tableName],
            idColumn: configDic[configObj.Task.idColumn],
            nameColumn: configDic[configObj.Task.nameColumn],
            enabled: configDic[configObj.Task.enabled],
            projectIdColumn: configDic[configObj.Task.projectIdColumn],
            startColumn: configDic[configObj.Task.startColumn],
            endColumn: configDic[configObj.Task.endColumn],
            closedColumn: configDic[configObj.Task.closedColumn],
            closedValue: configDic[configObj.Task.closedValue],
        },
        ProjectTeam: {
            tableName: configDic[configObj.ProjectTeam.tableName],
            idColumn: configDic[configObj.ProjectTeam.idColumn],
            projectIdColumn: configDic[configObj.ProjectTeam.projectIdColumn],
            resourceIdColumn: configDic[configObj.ProjectTeam.resourceIdColumn],
            allTasksOrProjectSummaryTask: configDic[configObj.ProjectTeam.allTasksOrProjectSummaryTask],
            enabled: configDic[configObj.ProjectTeam.enabled],
            closedColumn: configDic[configObj.ProjectTeam.closedColumn],
            closedValue: configDic[configObj.ProjectTeam.closedValue],
            resourceAzureIdAlternateColumnEnabled: configDic[configObj.ProjectTeam.resourceAzureIdAlternateColumnEnabled],
            resourceAzureIdTable: configDic[configObj.ProjectTeam.resourceAzureIdTable],
            resourceAzureIdColumn: configDic[configObj.ProjectTeam.resourceAzureIdColumn],
        },
        Actuals: {
            enabled: configDic[configObj.Actuals.enabled],
        },
    } as IConfigDictionary;
};

export interface IConfigDictionary {
    Project: IConfigDictionaryProject;
    Task: IConfigDictionaryTask;
    Assignment: IConfigDictionaryAssignment;
    ProjectTeam: IConfigDictionaryProjectTeam;
    Actuals: IConfigDictionaryActuals;
}

export interface IConfigDictionaryProject {
    tableName: IValueData;
    idColumn: IValueData;
    nameColumn: IValueData;
    enabled: boolean;
    metadataColumn: IValueData;
    managerColumn: IValueData;
    numberColumn: IValueData;
    closedColumn: IValueData;
    closedValue: string;
}

export interface IConfigDictionaryTask {
    tableName: IValueData;
    idColumn: IValueData;
    nameColumn: IValueData;
    enabled: boolean;
    projectIdColumn: IValueData;
    taskTypeNameColumn: IValueData;
    startColumn: IValueData;
    endColumn: IValueData;
    closedColumn: IValueData;
    closedValue: string;
}

export interface IConfigDictionaryAssignment {
    tableName: IValueData;
    idColumn: IValueData;
    taskIdColumn: IValueData;
    resourceIdColumn: IValueData;
    enabled: boolean;
    plannedWorkColumn: IValueData;
    remainingWorkColumn: IValueData;
    rateColumn: IValueData;
    resourceAzureIdAlternateColumnEnabled: boolean;
    resourceAzureIdTable: IValueData;
    resourceAzureIdColumn: IValueData;
}

export interface IConfigDictionaryProjectTeam {
    tableName: IValueData;
    idColumn: IValueData;
    projectIdColumn: IValueData;
    resourceIdColumn: IValueData;
    enabled: boolean;
    allTasksOrProjectSummaryTask: string;
    closedColumn: IValueData;
    closedValue: string;
    resourceAzureIdAlternateColumnEnabled: boolean;
    resourceAzureIdTable: IValueData;
    resourceAzureIdColumn: IValueData;
}

export interface IConfigDictionaryActuals {
    enabled: boolean;
}

export default {
    updateValueFromItems,
    updateValue,
    getFields,
    getTables,
    GetConfigDic,
    updateValueFromItemsApi,
};

interface tableFromApi {
    entityLogicalName: string;
    entityDisplayNames: string;
    logicalCollectionName: string;
}

interface IFieldMetadata {
    logicalName: string;
    displayName: string;
    isLookUp?: boolean;
}

export interface IValueData {
    key: string;
    name: string;
    logicalCollectionName?: string;
}

export interface IExpandedComboBoxOption extends IComboBoxOption {
    isLookUp?: boolean;
    name?: string;
    logicalCollectionName?: string;
}

export const configObj = {
    // Resource: {
    //     tableName: "resource_tableName",
    //     idColumn: "resource_idColumn",
    //     nameColumn: "resource_nameColumn",
    //     principalNameColumn: "resource_principalNameColumn",
    //     emailColumn: "resource_emailColumn",
    //     azureIdColumn: "resource_azureIdColumn",
    //     enabled: "resource_enabled"
    // },
    Project: {
        tableName: 'PowerPPM_project_tableName',
        idColumn: 'PowerPPM_project_idColumn',
        nameColumn: 'PowerPPM_project_nameColumn',
        metadataColumn: 'PowerPPM_project_metadataColumn',
        managerColumn: 'PowerPPM_project_managerColumn',
        numberColumn: 'PowerPPM_project_numberColumn',
        closedColumn: 'PowerPPM_project_closedColumn',
        closedValue: 'PowerPPM_project_closedValue',
        enabled: 'PowerPPM_project_enabled',
    },
    Assignment: {
        tableName: 'PowerPPM_assignment_tableName',
        idColumn: 'PowerPPM_assignment_idColumn',
        taskIdColumn: 'PowerPPM_assignment_taskIdColumn',
        resourceIdColumn: 'PowerPPM_assignment_resourceIdColumn',
        plannedWorkColumn: 'PowerPPM_assignment_plannedWorkColumn',
        remainingWorkColumn: 'PowerPPM_assignment_remainingWorkColumn',
        rateColumn: 'PowerPPM_assignment_rateColumn',
        enabled: 'PowerPPM_assignment_enabled',
        resourceAzureIdAlternateColumnEnabled: 'PowerPPM_assignment_resourceAzureIdAlternateColumnEnabled',
        resourceAzureIdTable: 'PowerPPM_assignment_resourceAzureIdTable',
        resourceAzureIdColumn: 'PowerPPM_assignment_resourceAzureIdColumn',
    },
    Task: {
        tableName: 'PowerPPM_task_tableName',
        idColumn: 'PowerPPM_task_idColumn',
        nameColumn: 'PowerPPM_task_nameColumn',
        projectIdColumn: 'PowerPPM_task_projectIdColumn',
        startColumn: 'PowerPPM_task_startColumn',
        endColumn: 'PowerPPM_task_endColumn',
        closedColumn: 'PowerPPM_task_closedColumn',
        closedValue: 'PowerPPM_task_closedValue',
        enabled: 'PowerPPM_task_enabled',
    },
    ProjectTeam: {
        tableName: 'PowerPPM_projectTeam_tableName',
        idColumn: 'PowerPPM_projectTeam_idColumn',
        projectIdColumn: 'PowerPPM_projectTeam_projectIdColumn',
        resourceIdColumn: 'PowerPPM_projectTeam_resourceIdColumn',
        allTasksOrProjectSummaryTask: 'PowerPPM_projectTeam_allTasksOrProjectSummaryTask',
        closedColumn: 'PowerPPM_projectTeam_closedColumn',
        closedValue: 'PowerPPM_projectTeam_closedValue',
        enabled: 'PowerPPM_projectTeam_enabled',
        resourceAzureIdAlternateColumnEnabled: 'PowerPPM_projectTeam_resourceAzureIdAlternateColumnEnabled',
        resourceAzureIdTable: 'PowerPPM_projectTeam_resourceAzureIdTable',
        resourceAzureIdColumn: 'PowerPPM_projectTeam_resourceAzureIdColumn',
    },
    Actuals: {
        enabled: 'PowerPPM_actuals_enabled',
    },
};

export const configTypes = {
    // Resource: "Resource",
    Project: 'Project',
    Assignment: 'Assignment',
    Task: 'Task',
    ProjectTeam: 'ProjectTeam',
    Actuals: 'Actuals',
};

export interface IInputProps {
    configMappingObj: Record<string, any>;
    config: Record<string, any>;
    fields: ITag[];
    onChange: (updatedValues: any) => void;
}

// export interface ISectionProps {
//     configKey: string;
//     config: Record<string, any>;
//     tables: ITag[];
//     onChange: (updatedValues: any) => void;
// }

export interface ISectionProps {
    values: IConfigDictionary;
    configKey: string;
    tables: IExpandedComboBoxOption[];
    setValues: React.Dispatch<React.SetStateAction<IConfigDictionary>>;
    addStepFunc: (key: string, table: IValueData) => void;
    removeStepFunc: (key: string, table: IValueData) => void;
    updateSteps: (opt: IExpandedComboBoxOption, configKey: string) => void;
    updateValue: (key: string, opt: IExpandedComboBoxOption | boolean | string, configKey: string) => void;
}

export interface IColumnProps {
    configMappingObj: Record<string, any>;
    fields: IExpandedComboBoxOption[];
    config: Record<string, any>;
    updateValue: (key: string, value: IExpandedComboBoxOption | boolean | string, configKey: string) => void;
    configKey: string;
    tables?: IExpandedComboBoxOption[];
    stepAdded: boolean;
}
