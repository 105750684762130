import React, { createContext, useContext, useState, useCallback } from 'react';
import { getEmptyGuid } from '../Utils';
import TimePeriodRowM from '../../Model/TimePeriodRowM';
import PeriodDay from '../../Model/PeriodDay';
import { TimeEntry } from '../../api/generated/data-contracts';
import { TimeGridMode } from '../../Model/TimeGridMode';

export interface ICellContext {
    inputNodes: any;
    addInputNode: any;
    onCellUpdated: (
        time: TimePeriodRowM,
        day: PeriodDay,
        te: TimeEntry,
        value: number,
        comment: string,
        flagged: boolean,
        mode: TimeGridMode,
        prevVal: number,
        saveSuccess: Function,
    ) => Promise<void>;
}

interface IProps {
    onCellUpdated: (
        time: TimePeriodRowM,
        day: PeriodDay,
        te: TimeEntry,
        value: number,
        comment: string,
        flagged: boolean,
        mode: TimeGridMode,
        prevVal: number,
        saveSuccess: Function,
    ) => Promise<void>;
}

const CellContext = createContext<ICellContext | undefined>({
    inputNodes: [],
    addInputNode: () => null,
} as ICellContext);

export const useCellCtx = () => useContext(CellContext) as ICellContext;

export const CellContextProvider: React.FC<IProps> = React.memo(({ children, onCellUpdated }) => {
    const [inputNodes] = useState(() => new Map());
    //console.log(inputNodes)
    const addInputNode = (taskId: any, workTypeId: string, dayIndex: any, ref: any) => {
        const wtid = workTypeId == null || workTypeId == undefined ? getEmptyGuid() : workTypeId;
        inputNodes.set(`${taskId}_${wtid}_${dayIndex}`, ref);
    };

    return (
        <CellContext.Provider
            value={{
                inputNodes,
                addInputNode,
                onCellUpdated,
            }}
        >
            {children}
        </CellContext.Provider>
    );
});

export default CellContext;
