import React, { useEffect, useState } from 'react';
import TimeRow from '../Model/TimePeriodRowM';
import TimeRowGroup from '../Model/TimePeriodGroupM';
import PeriodDay from '../Model/PeriodDay';
import MyTimeAdd from './MyTimeAdd';
import Cell from './Cell';
import TotalCell from './TotalCell';
import { TimeGridMode } from '../Model/TimeGridMode';
import { DirectionalHint, Stack, Text, TooltipHost, FontIcon } from '@fluentui/react';
import '../Styles/TimePeriod.scss';
import {
    GetTotalFromTimeRowInDayArray,
    GetTotalFromTimeEntriesArray,
    GetTotalFromTimeRowArray,
    IsDatesNoTimeEqual,
    FormatValue,
    MathToFixed,
    IsWeekend,
    getEmptyGuid,
    DateAsStartOfDay,
    RoundToDecOrEmpty,
    IsHoliday,
    IsDeadline,
} from './Utils';
import { WorkTypePicker } from './WorkTypePicker';
import WorkType from '../Model/NewModels/WorkType';
import { useUiContext } from './Contexts/UiContext';
import MyTimeDelete from './MyTimeDelete';
import { useCellCtx } from './Contexts/CellContext';
import ProjectSourceIcon from '../Icons/ProjectSourceIcon';
import { DisplayCell } from './WeekOverview/DisplayCell';
import { TimeEntry } from '../api/generated/data-contracts';
import { TimeConfigKeys } from '../constants/TimeConfigKeys';

interface propsTimePeriodGroup {
    group: TimeRowGroup;
    expanded: boolean;
    onCellUpdated: Function;
    mode: TimeGridMode;
    pinMyTime: Function;
    deleteMyTime: Function;
    today: Date;
    submitted: boolean;
    periodIsClosed: boolean;
    timeLowerLimitConfig: string;
    startDate: Date;
    endDate: Date;
    deadlineDate: Date;
    perioddays: PeriodDay[];
    reportPeriodId: string;
    updateWorkType: (row: TimeRow, workType: WorkType, changeCompleted: Function) => void;
}

function TimePeriodGroup(props: propsTimePeriodGroup) {
    const [times, setTimes] = useState<TimeRow[]>([]);
    // const [deletedTimeRows, setDeletedTimeRows] = useState<TimeRow[]>([]);
    const [expanded, setExpanded] = useState<boolean>(true);
    const [expandMiddleman, setExpandMiddleman] = useState<boolean>(true);
    const [groupiconrotation, setGroupiconrotation] = useState<string>('rotate(-90deg)');
    const submittedOrPeriodClosed = props.submitted ? true : props.periodIsClosed ? true : false;
    const [cellDataNeedsSave, setCellDataNeedsSave] = useState<boolean>(false);
    const [onlyTimesheetWithinStartEnd, setOnlyTimesheetWithinStartEnd] = useState<boolean>(true);

    //TODO FIX:
    const [updateTotal, setUpdateTotal] = useState<boolean>(true);

    const uiCtx = useUiContext();
    const cellCtx = useCellCtx();

    const workTypeAllowed = (timeRow: TimeRow) => {
        if (timeRow === undefined) {
            return true;
        }

        if (!uiCtx.workTypeEnabled) {
            return true;
        }

        const TimeEntriesMustHaveWorkType = uiCtx.enabledRules.find(_ => _.name === 'TimeEntriesMustHaveWorkType');

        if (uiCtx.workTypeEnabled && !TimeEntriesMustHaveWorkType?.enabled) {
            return true;
        }

        if (TimeEntriesMustHaveWorkType?.enabled && (!timeRow.workType || timeRow.workType.id === getEmptyGuid())) {
            return false;
        }

        if (timeRow.timeEntries.some(_ => _.assignment.timeTask?.isDefault === true)) {
            return true;
        }
        const userWorkTypes = [...uiCtx.allowedWorkTypes];
        if (userWorkTypes.length === 0) {
            return true;
        }

        const projectWorkTypes = uiCtx.projectWorkTypes.find(_ => _.id === timeRow.projectWorkType?.id)?.allowedWorkTypes || [];

        const userWorkTypesInProject = userWorkTypes.filter(uw => projectWorkTypes.some(pw => pw.id === uw.id));
        const projectWorkTypesInUser = projectWorkTypes.filter(uw => userWorkTypes.some(pw => pw.id === uw.id));
        const avaiableWorkWtype = [...projectWorkTypesInUser, ...userWorkTypesInProject].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);

        const rowWorkType = timeRow.workType;
        const workTypeAllowedvar = avaiableWorkWtype?.find(wt => wt.id === rowWorkType?.id);
        if (!workTypeAllowedvar) {
            return false;
        } else {
            return true;
        }
    };
    useEffect(() => {
        const tsfconfig = uiCtx.onlyallowtimesheetwithintaskstartend;
        setOnlyTimesheetWithinStartEnd(tsfconfig);
    }, []);

    useEffect(() => {
        setTimes([...props.group.TimeRows]);
    }, [props.group, updateTotal]);

    useEffect(() => {
        // setExpanded(props.expanded);
        setExpandMiddleman(props.expanded);
    }, [props.expanded]);

    useEffect(() => {
        if (expanded) {
            setGroupiconrotation('rotate(0deg)');
        } else {
            setGroupiconrotation('rotate(-90deg)');
        }
    }, [expanded]);

    useEffect(() => {
        if (!cellDataNeedsSave) {
            setExpanded(expandMiddleman);
        }
    }, [expandMiddleman, cellDataNeedsSave]);

    const groupGollapsedExpanded = (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>): void => {
        setExpandMiddleman(!expandMiddleman);
    };

    const onCellUpdated = (
        time: TimeRow,
        day: PeriodDay,
        te: TimeEntry,
        value: number,
        comment: string,
        flagged: boolean,
        previousValue: number,
        saveSuccess: Function,
    ): void => {
        props.onCellUpdated(time, day, te, value, comment, flagged, previousValue, saveSuccess);
        setUpdateTotal(t => !t);
    };

    const PinMyTime = (time: TimeRow, pinned: boolean) => {
        props.pinMyTime(time, pinned);
    };

    const DeleteMyTime = (time: TimeRow) => {
        // setTimes(currentArray => [...currentArray.filter(c => c != time)])
        // setDeletedTimeRows(currentArray => [...currentArray, time]);
        props.deleteMyTime(time);
    };

    const setCellDataNeedsSaveFunc = (val: boolean) => {
        setCellDataNeedsSave(val);
    };

    // const isHoliday = (date: Date) => {
    //     var currentDate = new Date(date);
    //     currentDate.setHours(0,0,0,0);
    //     let isHoliday = uiCtx.user.calendar?.holidays && uiCtx.user.calendar?.holidays.filter(_ => new Date(_.date).getTime() == currentDate.getTime()).length > 0;
    //     return isHoliday;
    // }

    //console.info(props.group);

    return (
        <>
            <tr
                onClick={groupGollapsedExpanded}
                // transitionName="fade"
                // transitionEnterTimeout={500}
                // transitionLeaveTimeout={300}
                // transitionAppearTimeout={500}
                // transitionAppear={true}
                // component="tr"
                className={`groupHeadRow${props.today === props.startDate ? ' isMonday' : ''}`}
                // className={`groupHeadRow${props.today === props.startDate ? " isMonday" : ""}${deletedTimeRows.length >= times.length ? " deletedRow" : ""}`}
            >
                <td className="rowHeader noselect">
                    <Stack horizontal verticalAlign="center" className="rowHeaderContainer">
                        <Stack.Item className="expander">
                            <div className="expanderInner">
                                {/* <Stack.Item className={`expander${props.expanded ? " activePin" : ""}`}> */}
                                <FontIcon
                                    iconName="ChevronDown"
                                    style={{
                                        transform: groupiconrotation,
                                        transition: 'transform .2s ease 0s',
                                    }}
                                />
                            </div>
                        </Stack.Item>
                        <Text className="groupName textOverflow">{props.group.GroupName}</Text>
                        {props.group?.ProjectSource?.iconUrl && (
                            <Stack.Item>
                                <ProjectSourceIcon
                                    id={`${props.group.GroupName}_${props.group.ProjectSource.name}`}
                                    projectSource={props.group.ProjectSource}
                                />
                            </Stack.Item>
                        )}
                    </Stack>
                </td>
                <td className="cellContainer worktypeContainer noselect" hidden={!uiCtx.workTypeEnabled}>
                    <Stack horizontal className="cell day">
                        <Text className="groupName textOverflow"> {/* White space for work type (Strange layout if empty...) */}</Text>
                    </Stack>
                </td>
                {uiCtx.plannedActive && uiCtx.showPlanned && (
                    <td className={`rowTotalContainer noselect`}>
                        <div className="rowTotal totalCell">
                            <Text className="totalCellInner" as="div">
                                <DisplayCell
                                    value={MathToFixed(
                                        times.reduce((prev, cur) => prev + (cur.plannedWork || 0), 0),
                                        2,
                                    )}
                                    periodDays={props.perioddays}
                                />
                            </Text>
                        </div>
                    </td>
                )}
                {uiCtx.actualActive && uiCtx.showPlanned && (
                    <td className={`rowTotalContainer noselect`}>
                        <div className="rowTotal totalCell">
                            <Text className="totalCellInner" as="div">
                                <DisplayCell
                                    value={MathToFixed(
                                        times.reduce((prev, cur) => prev + (cur.actualWork || 0), 0),
                                        2,
                                    )}
                                    periodDays={props.perioddays}
                                />
                            </Text>
                        </div>
                    </td>
                )}
                {uiCtx.remainingActive && uiCtx.showPlanned && (
                    <td className={`rowTotalContainer noselect`}>
                        <div className="rowTotal totalCell">
                            <Text className="totalCellInner" as="div">
                                <DisplayCell
                                    value={MathToFixed(
                                        times.reduce(
                                            (prev, cur) =>
                                                prev +
                                                ((cur.plannedWork || 0) - (cur.actualWork || 0) < 0 ? 0 : (cur.plannedWork || 0) - (cur.actualWork || 0)),
                                            0,
                                        ),
                                        2,
                                    )}
                                    periodDays={props.perioddays}
                                />
                            </Text>
                        </div>
                    </td>
                )}
                {props.perioddays.map((date: PeriodDay, index) => (
                    <td
                        key={date.id}
                        className={`rowTotalContainer noselect${IsDatesNoTimeEqual(props.today, date.date) ? ' today' : ''}${
                            IsDeadline(date.date, props.deadlineDate) ? ' deadline' : ''
                        }${IsHoliday(date.date, uiCtx.user.user.calendar) ? ' holiday' : ''}`}
                    >
                        <div className="rowTotal totalCell">
                            <Text className="totalCellInner" as="div">
                                {RoundToDecOrEmpty(GetTotalFromTimeRowInDayArray(times, date.date), 5)}
                            </Text>
                        </div>
                    </td>
                ))}
                <td className="rowTotalContainer noselect">
                    <div className="rowTotal totalCell">
                        <Text as="div" className="totalCellInner">
                            {GetTotalFromTimeRowArray(times) != null ? FormatValue(GetTotalFromTimeRowArray(times)) : ''}
                        </Text>
                    </div>
                </td>
            </tr>
            {times.map((time: TimeRow, index) => {
                if (!expanded) {
                    return null;
                }

                return (
                    <tr
                        key={time.key}
                        // transitionName="fade"
                        // transitionEnterTimeout={500}
                        // transitionLeaveTimeout={300}
                        // transitionAppearTimeout={500}
                        // transitionAppear={true}
                        // component="tr"
                        className={`subRow${props.today === props.startDate ? ' isMonday' : ''}${index === times.length - 1 ? ' lastSubRow' : ''}`}
                        // className={`subRow${props.today === props.startDate ? " isMonday" : ""}${index === times.length - 1 ? " lastSubRow" : ""}${deletedTimeRows.indexOf(time) > -1 ? " deletedRow" : ""}`}
                    >
                        {/* <td></td> */}

                        <td className="rowHeader noselect">
                            <Stack horizontal className="rowHeaderContainer">
                                <div className="expander" />
                                <Stack.Item grow>
                                    <TooltipHost
                                        directionalHint={DirectionalHint.topLeftEdge}
                                        content={
                                            time.taskName.length < 70
                                                ? time.taskDescription
                                                : time.taskName + (time.taskDescription ? ' - ' + time.taskDescription : '')
                                        }
                                        closeDelay={250}
                                    >
                                        <Text className="subGroupName textOverflow">
                                            {time.taskName.length < 70 ? time.taskName : time.taskName.substr(0, 68) + '...'}
                                        </Text>
                                    </TooltipHost>
                                </Stack.Item>

                                <Stack.Item className="noselect rowDelete">
                                    <MyTimeDelete Time={time} deleteMyTime={DeleteMyTime} enabled={!submittedOrPeriodClosed} />
                                </Stack.Item>

                                <Stack.Item className="noselect pin">
                                    <MyTimeAdd key={index} timeId={time.timeId} pinned={time.pinned} Time={time} pinMyTime={PinMyTime} isMyTime={true} />
                                </Stack.Item>
                            </Stack>
                        </td>

                        {uiCtx.workTypeEnabled && (
                            // <td className="cellContainer noselect weekend" hidden={uiCtx.workTypesDisabled}>
                            <td className="cellContainer worktypeContainer noselect">
                                <div className="cell day">
                                    <WorkTypePicker
                                        readOnly={submittedOrPeriodClosed || time.projectClosed || time.taskClosed}
                                        row={time}
                                        existingTaskTimeRows={times}
                                        onChange={props.updateWorkType}
                                    />
                                </div>
                            </td>
                        )}

                        {uiCtx.plannedActive && uiCtx.showPlanned && (
                            <td className={`cellContainer parContainer noselect`}>
                                <div className="cell parCell day">
                                    <Text className="cellInner parCellInner" as="div">
                                        {(time.plannedWork || 0) <= 0 ? null : MathToFixed(time.plannedWork, 2)}
                                    </Text>
                                </div>
                            </td>
                        )}
                        {uiCtx.actualActive && uiCtx.showPlanned && (
                            <td className={`cellContainer parContainer noselect`}>
                                <div className="cell parCell day">
                                    <Text className="cellInner parCellInner" as="div">
                                        {(time.actualWork || 0) <= 0 ? null : MathToFixed(time.actualWork, 2)}
                                    </Text>
                                </div>
                            </td>
                        )}
                        {uiCtx.remainingActive && uiCtx.showPlanned && (
                            <td className={`cellContainer parContainer noselect`}>
                                <div className="cell parCell day">
                                    <Text className="cellInner parCellInner" as="div">
                                        {
                                            (time.plannedWork || 0) <= 0
                                                ? null
                                                : MathToFixed(
                                                      (time.plannedWork || 0) - (time.actualWork || 0) < 0
                                                          ? 0
                                                          : (time.plannedWork || 0) - (time.actualWork || 0),
                                                      2,
                                                  )
                                            // time.actualWork != null && time.plannedWork != null && time.plannedWork !== 0 ?
                                            //     MathToFixed(time.plannedWork, 2) - MathToFixed(time.actualWork, 2) < 0 ?
                                            //         0 :
                                            //         MathToFixed(time.plannedWork, 2) - MathToFixed(time.actualWork, 2) :
                                            //     null
                                        }
                                    </Text>
                                </div>
                            </td>
                        )}

                        {props.perioddays.map((day: PeriodDay, index) => {
                            return (
                                <td
                                    key={day.id}
                                    className={`cellContainer noselect${IsWeekend(day.date) ? ' weekend' : ''}${
                                        IsDeadline(day.date, props.deadlineDate) ? ' deadline' : ''
                                    }${IsHoliday(day.date, uiCtx.user.user.calendar) ? ' holiday' : ''}${
                                        IsDatesNoTimeEqual(props.today, day.date) ? ' today' : ''
                                    }`}
                                >
                                    <div className="cell day">
                                        <Cell
                                            time={time}
                                            timeentry={time.timeEntries?.filter(te => new Date(te.date).getTime() === day.date.getTime())[0]} //[0] @kra is risky - please improve to check for existance of a TimeEntry -> I think this is fine, we just need to handle undefined in Celll
                                            // cju: by doing that, you are just sending an error further into the app, instead of handling it here, which will do it more complex to read and understand the code.
                                            // you know timeEntries is an array, so add '?? []' to the end, would fix it here, instead of timeentry have the possibility of being undefined
                                            day={day}
                                            updated={onCellUpdated}
                                            propertyUpdated={time.changetoken}
                                            readonly={
                                                submittedOrPeriodClosed
                                                    ? true
                                                    : uiCtx.user.user.start === null || uiCtx.user.user.end === null
                                                    ? false
                                                    : onlyTimesheetWithinStartEnd &&
                                                      !time.taskIsDefault &&
                                                      (time.taskStart === undefined || time.taskEnd === undefined)
                                                    ? true
                                                    : (props.perioddays[0].date < new Date(uiCtx.user.user.start) &&
                                                          new Date(uiCtx.user.user.start).getFullYear() > 1900) ||
                                                      (props.perioddays[props.perioddays.length - 1].date > new Date(uiCtx.user.user.end) &&
                                                          new Date(uiCtx.user.user.end).getFullYear() > 1900) ||
                                                      time.projectClosed ||
                                                      time.taskClosed ||
                                                      (onlyTimesheetWithinStartEnd &&
                                                          (time.taskStart === undefined
                                                              ? false
                                                              : props.perioddays[0].date < DateAsStartOfDay(new Date(time.taskStart)))) ||
                                                      (onlyTimesheetWithinStartEnd &&
                                                          (time.taskEnd === undefined
                                                              ? false
                                                              : props.perioddays[props.perioddays.length - 1].date >
                                                                DateAsStartOfDay(new Date(time.taskEnd)))) ||
                                                      !workTypeAllowed(time)
                                            } //          ((time.taskStart == null || time.taskEnd == null) ? false : props.today > time.taskStart && time.taskEnd > props.today)}
                                            readonlyreason={
                                                day.date < new Date(uiCtx.user.user.start) && new Date(uiCtx.user.user.start).getFullYear() > 1900
                                                    ? 'You are not working at this time'
                                                    : day.date > new Date(uiCtx.user.user.end) && new Date(uiCtx.user.user.end).getFullYear() > 1900
                                                    ? 'You are not working at this time'
                                                    : submittedOrPeriodClosed
                                                    ? 'time period is submitted, approved or closed'
                                                    : time.projectClosed
                                                    ? 'Project is closed or deleted'
                                                    : time.taskClosed
                                                    ? 'Task is closed, deleted or you are no longer assigned the task'
                                                    : onlyTimesheetWithinStartEnd &&
                                                      time.taskStart === undefined &&
                                                      onlyTimesheetWithinStartEnd &&
                                                      time.taskEnd === undefined
                                                    ? 'Task has no start or end date'
                                                    : onlyTimesheetWithinStartEnd && time.taskStart === undefined
                                                    ? 'Task has no start date'
                                                    : onlyTimesheetWithinStartEnd && time.taskEnd === undefined
                                                    ? 'Task has no end date'
                                                    : onlyTimesheetWithinStartEnd &&
                                                      time.taskStart !== undefined &&
                                                      props.perioddays[0].date < new Date(time.taskStart)
                                                    ? 'Task is not yet started'
                                                    : onlyTimesheetWithinStartEnd &&
                                                      time.taskEnd !== undefined &&
                                                      props.perioddays[props.perioddays.length - 1].date > new Date(time.taskEnd)
                                                    ? 'Task has ended'
                                                    : !workTypeAllowed(time)
                                                    ? 'Worktype is not allowed'
                                                    : ''
                                            }
                                            timeLowerLimitConfig={props.timeLowerLimitConfig}
                                            ref={n => cellCtx.addInputNode(time.taskId, time.workType?.id, index, n)}
                                            cellDataNeedsSave={setCellDataNeedsSaveFunc}
                                        />
                                    </div>
                                </td>
                            );
                        })}
                        <td className="rowTotalContainer noselect">
                            <div className="rowTotal totalCell">
                                <TotalCell
                                    initialValue={MathToFixed(GetTotalFromTimeEntriesArray(time.timeEntries), 2)}
                                    periodDays={props.perioddays}
                                    // readonly={submittedOrPeriodClosed || time.projectClosed || time.taskClosed || (time.taskStart == undefined ? false : (props.perioddays.length > 0 ? props.perioddays[0].date < new Date(time.taskStart) : true)) || (time.taskEnd == undefined ? false : (props.perioddays.length > 0 ? props.perioddays[props.perioddays.length - 1].date > new Date(time.taskEnd) : true)) || !workTypeAllowed(time)}
                                    readonly={
                                        submittedOrPeriodClosed
                                            ? true
                                            : uiCtx.user.user.start === null || uiCtx.user.user.end === null
                                            ? false
                                            : onlyTimesheetWithinStartEnd && !time.taskIsDefault && (time.taskStart === undefined || time.taskEnd === undefined)
                                            ? true
                                            : (props.perioddays[0].date < new Date(uiCtx.user.user.start) &&
                                                  new Date(uiCtx.user.user.start).getFullYear() > 1900) ||
                                              (props.perioddays[props.perioddays.length - 1].date > new Date(uiCtx.user.user.end) &&
                                                  new Date(uiCtx.user.user.end).getFullYear() > 1900) ||
                                              time.projectClosed ||
                                              time.taskClosed ||
                                              props.perioddays.length < 1 ||
                                              (time.taskStart === undefined ? false : props.perioddays[0].date < DateAsStartOfDay(new Date(time.taskStart))) ||
                                              (time.taskEnd === undefined
                                                  ? false
                                                  : props.perioddays[props.perioddays.length - 1].date > DateAsStartOfDay(new Date(time.taskEnd))) ||
                                              (onlyTimesheetWithinStartEnd &&
                                                  (time.taskStart === undefined
                                                      ? false
                                                      : props.perioddays[0].date < DateAsStartOfDay(new Date(time.taskStart)))) ||
                                              (onlyTimesheetWithinStartEnd &&
                                                  (time.taskEnd === undefined
                                                      ? false
                                                      : props.perioddays[props.perioddays.length - 1].date > DateAsStartOfDay(new Date(time.taskEnd)))) ||
                                              !workTypeAllowed(time)
                                    }
                                    updated={onCellUpdated}
                                    timeRow={time}
                                    timeLowerLimitConfig={props.timeLowerLimitConfig}
                                />
                            </div>
                        </td>
                    </tr>
                );
            })}
        </>
    );
}

export default TimePeriodGroup;
