import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import AppApprover from '../AppApprover';
import AppAdminDelegatesConfig from '../AppAdmin/AppAdminDelegatesConfig';
import AppAdminTimeConfig from '../AppAdmin/AppAdminTimeConfig';
import HelpApp from '../Help/HelpApp';
import ValidationConfiguration from '../Validation/Configuration/ValidationConfiguration';
import Charts from '../Charts/Charts';
import PMCharts from '../ProjectManager/PMCharts';
import AppAdminCalendarConfig from '../AppAdmin/AppAdminCalendarConfig';
import AppAdminSyncConfig from '../AppAdmin/AppAdminSyncConfig';
import AppAdminImportActuals from '../AppAdmin/AppAdminImportActuals';
import VersionConfig from '../Admin/VersionConfig/VersionConfig';
import { PowerPPMSync } from '../Admin/PowerPPMSync/PowerPPMSync';
import PowerHubSettings from '../Admin/PowerHubSettings/PowerHubSettings';
import Admin from '../Admin/Admin';
import { AdminRoute } from './components/AdminRoute';
import { AdminOrDelegatesRoute } from './components/AdminOrDelegatesRoute';
import { Layout } from '../components/Layout/Layout';
import { Overview } from '../_pages/Overview/Overview';
import { AdminAdminConfig } from '../_pages/Admin/components/AdminConfig/AdminConfig';
import { AdminProjects } from '../Admin/AdminProjects/AdminProjects';
import { UserActiveRoute } from './components/UserActiveRoute';
import { UsersConfig } from '../_pages/Admin/components/UsersConfig/UsersConfig';
import { SystemInformation } from '../_pages/Admin/components/SystemInformation/SystemInformation';
import { HelpConfig } from '../_pages/Admin/components/HelpConfig/HelpConfig';
import { PeriodConfig } from '../_pages/Admin/components/PeriodConfig/PeriodConfig';
import { ApproverConfig } from '../_pages/Admin/components/ApproverConfig/ApproverConfig';
import TimeEntriesUpload from '../Admin/ImportData/TimeEntries/TimeEnitriesUpload';
import Configuration from '../Configuration/Configuration';
import { ResourceOverview } from '../_pages/Admin/components/ResourceOverview/ResourceOverview';
import { ProjectApproval } from '../_pages/ProjectApproval/ProjectApproval';
import { ProjectManagerRoute } from './components/ProjectManagerRoute';
import { useTimeUIConfig } from '../hooks/useTimeUIConfig';
import { AccessDenied } from '../_pages/AccessDenied';

export const routePaths = {
    root: '/',
    approve: '/approve',
    projectApproval: '/project-approval',
    help: '/help',
    charts: '/charts',
    pmCharts: '/pm-charts',
    admin: {
        root: '/admin',
        users: '/admin/users',
        adminUsers: '/admin/admin-users',
        resourceoverview: '/admin/resourceoverview',
        delegates: '/admin/delegates',
        projects: '/admin/projects',
        sync: '/admin/sync',
        powerppm: '/admin/powerppm',
        powerhub: '/admin/powerhub',
        jiraupload: '/admin/jiraupload',
        importData: '/admin/import-data', // data-import or just import??
        systemInformation: '/admin/system-information',
        settings: '/admin/settings',
        configuration: {
            approver: '/admin/configuration/approver',
            period: '/admin/configuration/period',
            time: '/admin/configuration/time',
            version: '/admin/configuration/version',
            validation: '/admin/configuration/validation',
            help: '/admin/configuration/help',
            calendar: '/admin/configuration/calendar',
        },
    },
};

const FeatureRoute = ({ children }: { children: React.ReactNode }) => {
    const { config } = useTimeUIConfig();

    if (!config.approval.projectManagerApproval.enabled) {
        return <AccessDenied />;
    }

    return children ? <>{children}</> : <Outlet />;
};

export const Router: React.FC = () => {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <UserActiveRoute>
                        <Layout />
                    </UserActiveRoute>
                }
            >
                <Route index element={<Overview />} />
                <Route path="approve" element={<AppApprover />} />
                <Route
                    path="project-approval"
                    element={
                        <ProjectManagerRoute>
                            <FeatureRoute>
                                <ProjectApproval />
                            </FeatureRoute>
                        </ProjectManagerRoute>
                    }
                />
                <Route path="help" element={<HelpApp />} />
                <Route path="charts" element={<Charts />} />
                <Route path="pm-charts" element={<PMCharts />} />
                {/* check if user is admin or has permission delegates */}
                <Route
                    path="admin"
                    element={
                        <AdminOrDelegatesRoute>
                            <Outlet />
                        </AdminOrDelegatesRoute>
                    }
                >
                    <Route index element={<Admin />} />
                    <Route path="delegates" element={<AppAdminDelegatesConfig />} />
                    {/* admins only routes */}
                    <Route
                        element={
                            <AdminRoute>
                                <Outlet />
                            </AdminRoute>
                        }
                    >
                        <Route path="admin-users" element={<AdminAdminConfig />} />
                        <Route path="resourceoverview" element={<ResourceOverview />} />
                        <Route path="users" element={<UsersConfig />} />
                        {/* <Route path="users" element={<AppAdminUsersConfig />} /> */}
                        <Route path="projects" element={<AdminProjects />} />
                        <Route path="sync" element={<AppAdminSyncConfig />} />
                        <Route path="powerppm" element={<PowerPPMSync />} />
                        <Route path="powerhub" element={<PowerHubSettings />} />
                        <Route path="jiraupload" element={<TimeEntriesUpload />} />
                        <Route path="import-data" element={<AppAdminImportActuals />} />
                        {/* <Route path="system-information" element={<AppAdminSystemInformation />} /> */}
                        <Route path="system-information" element={<SystemInformation />} />
                        <Route path="settings/*" element={<Configuration />} />

                        <Route path="configuration">
                            <Route index element={<ApproverConfig />} />
                            <Route path="approver" element={<ApproverConfig />} />
                            <Route path="period" element={<PeriodConfig />} />
                            <Route path="time" element={<AppAdminTimeConfig />} />
                            <Route path="version" element={<VersionConfig />} />
                            <Route path="validation" element={<ValidationConfiguration />} />
                            <Route path="help" element={<HelpConfig />} />
                            <Route path="calendar" element={<AppAdminCalendarConfig />} />
                            <Route path="*" element={<Navigate to="" replace />} />
                        </Route>
                    </Route>
                    <Route path="*" element={<Navigate to="" replace />} />
                </Route>
                <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
        </Routes>
    );
};

// const oldRoutings = [
//     { path: '/', component: App },
//     { path: '/approve', component: AppApprover },
//     { path: '/help', component: HelpApp },
//     { path: '/admin', component: Admin },
//     { path: '/admin/approverconfig', component: AppAdminApproverConfig },
//     { path: '/admin/adminconfig', component: AppAdminAdminConfig },
//     { path: '/admin/periodconfig', component: AppAdminPeriodConfig },
//     { path: '/admin/timeconfig', component: AppAdminTimeConfig },
//     { path: '/admin/versionconfig', component: VersionConfig },
//     { path: '/admin/delegates', component: AppAdminDelegatesConfig },
//     { path: '/admin/users', component: AppAdminUsersConfig },
//     { path: '/admin/adminprojects', component: AppAdminAdminProjects },
//     { path: '/admin/sync', component: AppAdminSyncConfig },
//     { path: '/admin/powerppmsync', component: PowerPPMSyncWrapper },
//     { path: '/admin/powerhubsettings', component: PowerHubSettings },
//     { path: '/admin/importdata', component: AppAdminImportActuals },
//     { path: '/admin/validationconfiguration', component: ValidationConfiguration },
//     { path: '/admin/helpconfiguration', component: AppAdminHelpConfig },
//     // { path: "/admin/calendar", component: CalendarView },
//     { path: '/admin/calendarconfig', component: AppAdminCalendarConfig },
//     { path: '/admin/systeminformation', component: AppAdminSystemInformation },
//     { path: '/charts', component: Charts },
//     { path: '/pmcharts', component: PMCharts },
// ];
