import { Coachmark, TeachingBubbleContent } from '@fluentui/react';
import React, { FunctionComponent, useMemo } from 'react';
import { CoachmarkInfo } from './interfaces/CoachmarkInfo';
import { coachmarkArray, CoachmarkGridSeenKey, CoachmarkVisibilityKey } from './TFTMainCoachmarks';
import { LocalStorage } from './utils/LocalStorage';

interface IProps {
    CoachmarkInfo: CoachmarkInfo;
    SetSelectedCoachmark: React.Dispatch<React.SetStateAction<number>>;
    currentCoachmark: number;
    coachmarks: CoachmarkInfo[];
}

export const PFCoachmark: FunctionComponent<IProps> = ({ CoachmarkInfo, SetSelectedCoachmark, currentCoachmark, coachmarks }) => {
    //WHEN IN DOUBT: USE PREVENT DEFAULT AND|STOP PROPAGATION
    const { hasNextBtn, hasPrevBtn, isLast } = useMemo(() => {
        const hasPrevBtn = currentCoachmark > 0;
        const isLast = currentCoachmark === coachmarks.length - 1;
        const getHasNextBtn = () => {
            let current = currentCoachmark + 1;
            let isDone = false;
            let hasFound = false;
            while (!isDone) {
                const currCoach = coachmarkArray?.[current];
                if (currCoach && currCoach.Target) {
                    const element = document.querySelector(`${currCoach.Target}`);
                    if (element) {
                        hasFound = true;
                        isDone = true;
                    } else {
                        current++;
                    }
                } else {
                    isDone = true;
                }
            }
            return hasFound;
        };

        return {
            hasNextBtn: getHasNextBtn(),
            hasPrevBtn,
            isLast,
        };
    }, [currentCoachmark, coachmarks.length]);

    const onClickNext = () => {
        //Find out if the DOM element of the next element in the array is present
        //If it is, set the next element
        //If not, figure out how many elements to skip
        const skipBy = () => {
            let current = currentCoachmark + 1;
            let isDone = false;
            let counter = 1;
            while (!isDone) {
                const currCoach = coachmarkArray?.[current];
                if (currCoach && currCoach.Target) {
                    const element = document.querySelector(`${currCoach.Target}`);
                    if (element) {
                        isDone = true;
                    } else {
                        counter++;
                        current++;
                    }
                } else {
                    isDone = true;
                }
            }

            return counter;
        };
        SetSelectedCoachmark((s) => s + skipBy());
    };
    const onClickPrev = () => {
        //Find out if the DOM element of the previous element in the array is present
        //If it is, set the previous element
        //If not, figure out how many elements to skip
        const skipBy = () => {
            let current = currentCoachmark - 1;
            let isDone = false;
            let counter = 1;
            while (!isDone) {
                const currCoach = coachmarkArray?.[current];
                if (currCoach) {
                    const element = document.querySelector(`${currCoach.Target}`);
                    if (element) {
                        isDone = true;
                    } else {
                        counter++;
                        current--;
                    }
                } else {
                    isDone = true;
                }
            }

            return counter;
        };
        SetSelectedCoachmark((s) => s - skipBy());
    };

    const getWasGridCoachmarksSeen = () => {
        if (document.querySelector('.cellInner:not(.hidden)')) {
            LocalStorage.Set(CoachmarkGridSeenKey, true);
        } else {
            LocalStorage.Set(CoachmarkGridSeenKey, false);
        }
    };

    const onClickComplete = () => {
        SetSelectedCoachmark(null);
        LocalStorage.Set(CoachmarkVisibilityKey, true);
        getWasGridCoachmarksSeen();
    };

    const onDismiss = () => {
        onClickComplete();
    };

    return CoachmarkInfo ? (
        <Coachmark
            delayBeforeMouseOpen={1000}
            target={CoachmarkInfo.Target}
            positioningContainerProps={{
                directionalHint: CoachmarkInfo.DirectionalHint,
            }}
            persistentBeak
        >
            <TeachingBubbleContent
                headline={CoachmarkInfo.Item.Headline}
                hasCloseButton
                closeButtonAriaLabel={'Close'}
                primaryButtonProps={isLast ? { text: 'Complete', onClick: onClickComplete } : hasNextBtn ? { text: 'Next', onClick: onClickNext } : null}
                secondaryButtonProps={hasPrevBtn ? { text: 'Back', onClick: onClickPrev } : null}
                onDismiss={() => {
                    onDismiss();
                }}
            >
                {CoachmarkInfo.Item.Text}
            </TeachingBubbleContent>
        </Coachmark>
    ) : (
        <React.Fragment />
    );
};
