import { getEmptyGuid } from '../../components/Utils';
import { validationRules } from '../validationRules';
import { ValidateResponse } from '../Validator';
import TimePeriodRowM from '../../Model/TimePeriodRowM';
import { IUiContext } from '../../components/Contexts/UiContext';
import { TimeEntry } from '../../api/generated/data-contracts';

interface TimeEntriesMustHaveWorkTypeProps {
    timeEntries: TimeEntry[];
    timeRows: TimePeriodRowM[];
    uiContext: IUiContext;
}

export const TimeEntriesMustHaveWorkType = (props: TimeEntriesMustHaveWorkTypeProps): ValidateResponse => {
    const isNotDefaultAndHasNoWorkType = (timeEntry: TimeEntry) => {
        return timeEntry.assignment.timeTask?.isDefault == false && timeEntry.assignment.workType?.id == getEmptyGuid();
    };
    const isAvailableWorktype = (timeRow: TimePeriodRowM) => {
        if (timeRow == undefined) {
            return true;
        }
        if (timeRow.timeEntries?.length <= 0 || timeRow.timeEntries.some(_ => _.assignment.timeTask?.isDefault == true)) {
            return true;
        }
        const userWorkTypes = [...props.uiContext.allowedWorkTypes];
        if (userWorkTypes.length == 0) {
            return true;
        }
        const projectWorkTypes = props.uiContext.projectWorkTypes.find(_ => _.id == timeRow.projectWorkType?.id)?.allowedWorkTypes || [];
        if (projectWorkTypes.length == 0) {
            return true;
        } else if (projectWorkTypes.length == 1 && projectWorkTypes[0].id == getEmptyGuid()) {
            return true;
        }
        const userWorkTypesInProject = userWorkTypes.filter(uw => projectWorkTypes.some(pw => pw.id == uw.id));
        const projectWorkTypesInUser = projectWorkTypes.filter(uw => userWorkTypes.some(pw => pw.id == uw.id));
        let avaiableWorkWtype = [...projectWorkTypesInUser, ...userWorkTypesInProject].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);

        const rowWorkType = timeRow.workType;
        let workTypeAllowed = avaiableWorkWtype?.find(wt => wt.id == rowWorkType?.id);
        if (!workTypeAllowed) {
            return false;
        } else {
            return true;
        }
    };

    let anyTimeEntriesWithoutWorktType = props.timeEntries.filter(_ => _.hours != 0).some(_ => !_.assignment.workType || isNotDefaultAndHasNoWorkType(_));
    let anyTimeEntriesWithUnavailableWorktType = props.timeRows.filter(_ => _.timeEntries.some(_ => _.hours != 0) == true).some(_ => !isAvailableWorktype(_));
    let tests = props.timeRows.filter(_ => _.timeEntries.some(_ => _.hours != 0) == true).find(_ => !isAvailableWorktype(_));
    isAvailableWorktype(tests);
    let response: ValidateResponse = {
        reason: anyTimeEntriesWithoutWorktType || anyTimeEntriesWithUnavailableWorktType ? 'Please select work type before submitting your timesheet' : null,
        valid: !(anyTimeEntriesWithoutWorktType || anyTimeEntriesWithUnavailableWorktType),
        name: validationRules.TimeEntriesMustHaveWorkType,
        rejected: anyTimeEntriesWithoutWorktType || anyTimeEntriesWithUnavailableWorktType,
    };

    return response;
};
