import React, { useEffect, useState, useRef, ReactElement, FormEvent } from 'react';
import { timeApi } from '../../../Workaround/TimeApiWorkaround';

import { ComboBox, IComboBoxOption, IComboBox } from '@fluentui/react';
import { useUiContext } from '../../../components/Contexts/UiContext';

interface propsTimeLowerLimitConfigProps {}

function TimeLowerLimitConfig(props: propsTimeLowerLimitConfigProps) {
    const [selectedComboKey, setSelectedComboKey] = useState<string>(null);
    const [comboboxError, setComboboxError] = useState<string>('');

    const uiCtx = useUiContext();

    const [DEFAULT_OPTIONS, setDEFAULT_OPTIONS] = useState<IComboBoxOption[]>([
        { key: '0', text: 'Default rounding' },
        { key: '1', text: 'No decimals' },
        { key: '0.1', text: '0.1' },
        { key: '0.25', text: '0.25' },
        { key: '0.5', text: '0.5' },
    ]);

    useEffect(() => {
        uiCtx.timeApi.getConfigByKey('TimeLowerLimitConfig').then(result => {
            if (result != null) {
                if (DEFAULT_OPTIONS.find(o => o.key === result.value) === undefined) {
                    setDEFAULT_OPTIONS(prevState => {
                        return prevState.concat([{ key: result.value, text: result.value }]);
                    });
                }
                setSelectedComboKey(result.value);
            }
        });
    }, []);

    const moduleDivStyle = {
        // display: 'inline-flex'
    };

    const errorSpanStyle = {
        fontFamily: '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
        fontSize: '12px',
        // fontWeight: '400px',
        color: 'rgb(164,38,44)',
    } as React.CSSProperties;

    const onComboChange = (event: FormEvent<IComboBox>, option: IComboBoxOption, index: number, value: string): void => {
        let finalComboOption = undefined;

        if (option != undefined) {
            finalComboOption = option;
        } else {
            try {
                let validatedValue = parseFloat(value);
                if (validatedValue < 0 || isNaN(validatedValue)) {
                    setComboboxError('Woops, input needs to be a positive float value.');
                    return;
                }
            } catch (error) {
                setComboboxError('Woops, input needs to be a positive float value.');
                return;
            }
            if (DEFAULT_OPTIONS.find(o => o.key === value) === undefined) {
                setDEFAULT_OPTIONS(prevState => {
                    return prevState.concat([{ key: value, text: value }]);
                });
                finalComboOption = { key: value, text: value };
            }
        }

        if (finalComboOption != undefined) {
            setSelectedComboKey(finalComboOption.key);
            uiCtx.timeApi.setConfig('TimeLowerLimitConfig', finalComboOption.key);
        }

        setComboboxError('');
    };

    return (
        <div style={moduleDivStyle}>
            <ComboBox
                label="Time Entry Interval (rounds to nearest interval)"
                // label="Lower limit timeregistration (Freeform allowed)"
                // key={'' + autocom}
                multiSelect={false}
                allowFreeform={true}
                autoComplete={'off'}
                options={DEFAULT_OPTIONS}
                selectedKey={selectedComboKey}
                onChange={onComboChange}
            />
            <span style={errorSpanStyle} hidden={comboboxError === '' ? true : false}>
                {comboboxError}
            </span>
        </div>
    );
}

export default TimeLowerLimitConfig;
