import React from "react";
import { DayRow } from "./DayRow";
import { TotalRow } from "./TotalRow";
import { useMobile } from "../../../../useMobile";
import { useMobileContext } from "../../../../MobileContext";
import { ProjectRow } from "./ProjectRow";
import { makeStyles } from "@fluentui/react";

export const TimeGrid: React.FC = () => {
    const mobCtx = useMobileContext();
    const myTimeView = useMobile(state => state.myTimeView);
    const classes = getStyles();
    
    return (
        <div className={classes.root}>
            <TotalRow />

            {!mobCtx.isPeriodView && myTimeView && myTimeView.map(day => {
                return (
                    <DayRow key={day.dateString} {...day} />
                )
            })}
            {mobCtx.isPeriodView && myTimeView && myTimeView[0]?.projects && (
                myTimeView[0].projects.map(project => (
                    <ProjectRow key={`${project.projectId}_${project.dateString}`} {...project} />
                ))
            )}
        </div>
    );
};

const getStyles = makeStyles(theme => ({
    root: ["timeGrid", {
        display: "flex",
        flexDirection: "column",
        height: "fit-content",
    }],
}));
