import * as React from "react"
import { constants } from '../Styles/constants';

export const SearchIconInactive: React.FC = (props) => {
  return (
    <svg
      width={constants.menuIconSize}
      height={constants.menuIconSize}
      viewBox="0 0 80 80"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <path
        fill="url(#prefix___Linear1)"
        d="M8.317 59.369h35.554v5.834H8.317z"
        transform="matrix(.793 -.78113 .70175 .71242 -44.402 36.268)"
      />
      <path
        d="M779.854 397.725c347.426 0 629.486 282.064 629.486 629.485 0 347.43-282.06 629.49-629.486 629.49-347.423 0-629.487-282.06-629.487-629.49 0-347.421 282.064-629.485 629.487-629.485zm0 137.139c271.736 0 492.346 220.614 492.346 492.346 0 271.74-220.61 492.35-492.346 492.35-271.734 0-492.348-220.61-492.348-492.35 0-271.732 220.614-492.346 492.348-492.346z"
        fill="url(#prefix___Linear2)"
        transform="matrix(.04136 0 0 .04136 17.854 -12.673)"
      />
      <defs>
        <linearGradient
          id="prefix___Linear1"
          x1={0}
          y1={0}
          x2={1}
          y2={0}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-68.4141 .30254 -.2718 -76.1526 82.266 62.549)"
        >
          <stop offset={0} stopColor="#7f7e7f" />
          <stop offset={1} stopColor="#7f7e7f" />
        </linearGradient>
        <linearGradient
          id="prefix___Linear2"
          x1={0}
          y1={0}
          x2={1}
          y2={0}
          gradientUnits="userSpaceOnUse"
          gradientTransform="scale(-1841.23) rotate(-44.795 -.774 .555)"
        >
          <stop offset={0} stopColor="#7f7e7f" />
          <stop offset={1} stopColor="#7f7e7f" />
        </linearGradient>
      </defs>
    </svg>
  )
}

// export default SearchIconInactive



// <svg width="40px" height="40px" viewBox="0 0 80 80" version="1.1" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
//     <g transform="matrix(1,0,0,1,-1.60209,1.68167)">
//         <g transform="matrix(0.793004,-0.781129,0.701752,0.712421,-42.7997,34.586)">
//             <rect x="8.317" y="59.369" width="35.554" height="5.834" style="fill:url(#_Linear1);"/>
//         </g>
//         <g transform="matrix(0.0413599,0,0,0.0413599,19.4556,-14.3548)">
//             <path d="M779.854,397.725C1127.28,397.725 1409.34,679.789 1409.34,1027.21C1409.34,1374.64 1127.28,1656.7 779.854,1656.7C432.431,1656.7 150.367,1374.64 150.367,1027.21C150.367,679.789 432.431,397.725 779.854,397.725ZM779.854,534.864C1051.59,534.864 1272.2,755.478 1272.2,1027.21C1272.2,1298.95 1051.59,1519.56 779.854,1519.56C508.12,1519.56 287.506,1298.95 287.506,1027.21C287.506,755.478 508.12,534.864 779.854,534.864Z" style="fill:url(#_Linear2);"/>
//         </g>
//     </g>
//     <defs>
//         <linearGradient id="_Linear1" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(-68.4141,0.302544,-0.2718,-76.1526,82.2658,62.5487)"><stop offset="0" style="stop-color:rgb(53,95,153);stop-opacity:1"/><stop offset="1" style="stop-color:rgb(15,68,123);stop-opacity:1"/></linearGradient>
//         <linearGradient id="_Linear2" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(-1306.59,1297.29,-1297.29,-1306.59,1133.36,707.005)"><stop offset="0" style="stop-color:rgb(53,95,153);stop-opacity:1"/><stop offset="1" style="stop-color:rgb(15,68,123);stop-opacity:1"/></linearGradient>
//     </defs>
// </svg>
