export async function handleJsonResponse(response) {
    if (response.ok) {
        return response.json();
    } else {
        console.error('Error calling api: ', response);
        throw new Error(`Developer Mode - Error calling api - ${response.statusText}`);
    }
}

export async function handleEmptyResponse(response) {
    if (response.ok) {
        return true;
    } else {
        console.error('Error calling api: ', response);
        throw new Error(`Developer Mode - Error calling api - ${response.statusText}`);
    }
}
export async function handleStringResponse(response) {
    if (response.ok) {
        return response.text();
    } else {
        console.error('Error calling api: ', response);
        throw new Error(`Developer Mode - Error calling api - ${response.statusText}`);
    }
}

export function handleError(error, endpoint, body) {
    console.error('API call failed, Endpoint was: ' + endpoint, error);
    console.log(body);
    throw error;
}

export function getBoolean(value) {
    switch (value) {
        case true:
        case 'true':
        case 'True':
        case 1:
        case '1':
        case 'yes':
            return true;

        case false:
        case 'false':
        case 0:
        case '0':
        case 'no':
            return false;
        default:
            return false;
    }
}
