import { useCallback, useState } from 'react';
import { Stack, Text, DefaultButton, Modal, Spinner, SpinnerSize, IconButton, PrimaryButton } from '@fluentui/react';
import { DataverseLogo } from '../../components/Logos/DataverseLogo';
import { SaveAndCloseButton } from '../SaveAndCloseButton';
import { DataverseLayout } from './DataverseLayout';
import { ErrorModal } from '../ErrorModal';
// import { Integration, IntegrationEventUpdateRequest } from '../../../../api/generated/data-contracts';
import { useControlledState } from '../../../../hooks/useControlledState';
import { useMutation } from '@tanstack/react-query';
import { ApiCalls } from '../../../../api/api';
import { DataverseIntegration } from '../../../../api/generated/data-contracts';

export const EditDataverseIntegration = ({
    integration: integrationProps,
    close,
    editIntegrations,
    setEditableIntegration,
}: {
    integration: DataverseIntegration;
    close: () => void;
    editIntegrations: (updater: (data: DataverseIntegration[]) => DataverseIntegration[]) => void;
    setEditableIntegration: React.Dispatch<React.SetStateAction<DataverseIntegration>>;
}) => {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [integration, setIntegration] = useControlledState(() => integrationProps, [integrationProps]);
    const { dataverseUrl, timeActualsSyncEnabled } = integration.configuration;
    const [closePressed, setClosePressed] = useState(false);

    const hasChanged = integration !== integrationProps;

    const {
        mutateAsync: saveIntegration,
        isLoading,
        error: updateIntegrationError,
        reset: resetUpdateError,
    } = useMutation({
        mutationFn: async () => {
            const update: DataverseIntegration = {
                configuration: {
                    timeActualsSyncEnabled,
                    dataverseUrl,
                },
                id: integration.id,
                name: integration.name,
                subscribedEvents: integration.subscribedEvents,
                type: integration.type,
            };
            return ApiCalls.integrationEventUpdate(update).then(() => update);
        },
        onSuccess: integration => {
            editIntegrations(integrations =>
                integrations.map(existingIntegration => {
                    if (existingIntegration.id === integration.id) {
                        return integration;
                    }
                    return existingIntegration;
                }),
            );
            setEditableIntegration(integration);
        },
    });

    const {
        mutate: deleteIntegration,
        isLoading: isDeleting,
        error: deleteError,
        reset: resetDeleteError,
    } = useMutation({
        mutationFn: async () => ApiCalls.integrationEventDelete(integration.id),
        onSuccess: () => {
            editIntegrations(integrations => integrations.filter(integration => integration.configuration.dataverseUrl !== dataverseUrl));
            close();
        },
    });

    const handleClose = useCallback(() => {
        if (hasChanged) {
            setClosePressed(true);
        } else {
            close();
        }
    }, [close, hasChanged]);

    return (
        <>
            <Stack horizontal horizontalAlign="center" verticalAlign="center" styles={{ root: { gap: 10, paddingTop: 20, paddingBottom: 20 } }}>
                {isLoading && <Spinner size={SpinnerSize.large} />}
                <DataverseLogo height={'30px'} width={'30px'} />
                <Text variant="xxLarge">
                    {dataverseUrl}
                    {hasChanged && '*'}
                </Text>
                <IconButton iconProps={{ iconName: 'Cancel' }} styles={{ root: { position: 'absolute', right: 0, top: 0 } }} onClick={handleClose} />
            </Stack>
            <DataverseLayout integration={integration} setIntegration={setIntegration} isLoading={isLoading} />
            <Stack horizontal horizontalAlign="space-between" tokens={{ padding: '0 20px 20px', childrenGap: 10 }}>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <DefaultButton disabled={isLoading} onClick={() => setConfirmDelete(true)}>
                        Delete
                    </DefaultButton>
                    <DefaultButton disabled={!hasChanged} onClick={() => setIntegration(integrationProps as DataverseIntegration)}>
                        Revert changes
                    </DefaultButton>
                </Stack>
                <SaveAndCloseButton disabled={!hasChanged} close={close} save={saveIntegration} />
            </Stack>
            <Modal isOpen={closePressed} onDismiss={() => setClosePressed(false)}>
                <Stack verticalAlign="space-between" styles={{ root: { height: 200, padding: 10 } }}>
                    <Text variant="xLarge">You currently have changes to "{dataverseUrl}"</Text>
                    <Stack horizontal horizontalAlign="end" tokens={{ padding: '50px 0 0 0', childrenGap: 10 }}>
                        <DefaultButton onClick={() => setClosePressed(false)}>Cancel</DefaultButton>
                        <PrimaryButton onClick={close} disabled={isDeleting}>
                            Discard changes
                        </PrimaryButton>
                    </Stack>
                </Stack>
            </Modal>
            <Modal isOpen={confirmDelete} {...(!isDeleting && { onDismiss: () => setConfirmDelete(false) })}>
                <Stack verticalAlign="space-between" styles={{ root: { height: 200, padding: 10 } }}>
                    <Text variant="xLarge">Are you sure you want to delete "{dataverseUrl}"?</Text>
                    {isDeleting && <Spinner size={SpinnerSize.large} label={`Deleting "${dataverseUrl}"....`} />}
                    <Stack horizontal horizontalAlign="end" tokens={{ padding: '50px 0 0 0', childrenGap: 10 }}>
                        <DefaultButton
                            styles={{
                                root: { backgroundColor: 'rgba(255, 0, 0, 1)', color: 'white' },
                                rootHovered: { backgroundColor: 'rgba(255, 0, 0, .5)' },
                                rootPressed: { backgroundColor: 'rgba(200, 0, 0, .5)' },
                            }}
                            onClick={() => deleteIntegration()}
                            disabled={isDeleting}
                        >
                            Yes
                        </DefaultButton>
                        <DefaultButton onClick={() => setConfirmDelete(false)} disabled={isDeleting}>
                            Cancel
                        </DefaultButton>
                    </Stack>
                </Stack>
            </Modal>
            <ErrorModal reset={resetUpdateError} error={updateIntegrationError} />
            <ErrorModal reset={resetDeleteError} error={deleteError} />
        </>
    );
};
