import { useState, useEffect } from 'react';
import { Stack, Text, DefaultButton, PrimaryButton, Spinner, SpinnerSize, Modal, Icon, AnimationClassNames, IconButton } from '@fluentui/react';
import { DataverseLogo } from '../../components/Logos/DataverseLogo';
import { DataverseLayout } from './DataverseLayout';
import { ConnectionIcon } from '../../components/Connection';
import { ErrorModal } from '../ErrorModal';
import { useMutation } from '@tanstack/react-query';
import { ApiCalls } from '../../../../api/api';
import { DataverseIntegration, IntegrationCreateRequest, IntegrationDto } from '../../../../api/generated/data-contracts';

export const NewDataverseIntegration = ({
    close,
    addIntegration,
}: {
    close: () => void;
    addIntegration: (updater: (data: DataverseIntegration[]) => DataverseIntegration[]) => void;
}) => {
    const [integration, setIntegration] = useState<DataverseIntegration>(() => ({
        configuration: {
            dataverseUrl: '',
            timeActualsSyncEnabled: false,
        },
        type: 'Dataverse',
    }));
    const [addedIntegration, setAddedIntegration] = useState(false);

    const {
        mutate: createIntegration,
        isLoading,
        error,
        reset,
    } = useMutation<DataverseIntegration>({
        mutationFn: async () => {
            const request: IntegrationCreateRequest = {
                type: integration.type,
                configuration: integration.configuration,
                name: integration.name,
            };

            return ApiCalls.integrationEventCreate(request).then(res => res.data as IntegrationDto);
        },
        onSuccess: integration => {
            addIntegration(integrations => [integration, ...integrations]);
            setAddedIntegration(true);
        },
    });

    const dataverseUrl = integration.configuration.dataverseUrl;

    return (
        <>
            <Stack horizontal horizontalAlign="center" verticalAlign="center" styles={{ root: { gap: 10, paddingTop: 20, paddingBottom: 20 } }}>
                {isLoading && <Spinner size={SpinnerSize.large} />}
                <DataverseLogo height={'30px'} width={'30px'} />
                <Text variant="xxLarge">
                    {isLoading && 'Creating: '} {dataverseUrl || 'New Dataverse integration'}*
                </Text>
                <IconButton iconProps={{ iconName: 'Cancel' }} styles={{ root: { position: 'absolute', right: 0, top: 0 } }} onClick={close} />
            </Stack>
            <DataverseLayout integration={integration} isLoading={isLoading} setIntegration={setIntegration} />
            <Stack horizontal horizontalAlign="end" tokens={{ padding: '0 20px 20px', childrenGap: 10 }}>
                <DefaultButton disabled={isLoading} onClick={close}>
                    Cancel
                </DefaultButton>
                <PrimaryButton disabled={isLoading || !dataverseUrl} onClick={() => createIntegration()}>
                    Save
                </PrimaryButton>
            </Stack>
            <SuccessModal addedIntegration={addedIntegration} close={close} name={dataverseUrl} />
            <ErrorModal reset={reset} error={error} />
        </>
    );
};

const SuccessModal = ({ addedIntegration, name, close }: { addedIntegration: boolean; name: string; close: () => void }) => {
    const [connected, setConnected] = useState(false);

    useEffect(() => {
        if (addedIntegration) {
            const timer = setTimeout(() => {
                setConnected(true);
            }, 800);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [addedIntegration]);

    useEffect(() => {
        if (connected) {
            const timer = setTimeout(() => {
                close();
            }, 2000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [close, connected]);

    return (
        <Modal isOpen={addedIntegration} className={AnimationClassNames.fadeIn400}>
            <Stack horizontalAlign="center" verticalAlign="center" styles={{ root: { minWidth: 500, padding: 20, gap: 20 } }}>
                <Text variant="xxLarge">
                    <Icon iconName="Accept" /> Successfully created {name}
                </Text>
                <ConnectionIcon connected={connected} height={400} width={400} />
            </Stack>
        </Modal>
    );
};
