import React from 'react';
import { Outlet } from 'react-router';
import { useUiContext } from '../../components/Contexts/UiContext';
import { AccessDenied } from '../../_pages/AccessDenied';

export const AdminRoute = ({ children }) => {
    const uiCtx = useUiContext();

    if (!uiCtx.user?.originalUser.isAdmin) {
        return <AccessDenied />;
    }

    return children ? children : <Outlet />;
};
