import { IntegrationEventType } from '../../api/generated/data-contracts';

export const getEventIconFromEnum = (type: IntegrationEventType) => {
    switch (type) {
        case 'Project': {
            return 'IssueTracking';
        }
        case 'Actuals': {
            return 'StackedBarChart';
        }
        default:
            return 'Error';
    }
};
