import React, { useEffect } from 'react';
import { useUiContext } from '../components/Contexts/UiContext';
import ImportActuals from '../Admin/ImportData/ImportActuals';

function AppAdminImportActuals() {
    const uiCtx = useUiContext();

    useEffect(() => {
        uiCtx.setLoading(false);
    }, []);

    return <ImportActuals />;
}

export default AppAdminImportActuals;
