export class TimeError {
    
    name: string;
    pluginId: string;
    message: string;
    stackTrace: string;

    constructor (name: string, pluginId: string, message: string, stackTrace: string) {
        this.name = name;
        this.pluginId = pluginId;
        this.message = message;
        this.stackTrace = stackTrace;
    }
}