import React from 'react';
import { useEffect, useLayoutEffect, useMemo, useState, useCallback } from 'react';
import { makeStyles, useTheme } from '@fluentui/react';
import { AnimationClassNames, IconButton, Stack, Text } from '@fluentui/react';
import { useMobile } from '../../useMobile';
import { DaySelectorDropDown } from './DaySelectorDropDown';
import { Comment } from './Comment';
import { useUiContext } from '../../../components/Contexts/UiContext';
import { DateAsStartOfDay, MathToFixed } from '../../../components/Utils';
import { useMobileContext } from '../../MobileContext';
import { TimeState } from '../../../Model/State';
import { CSSTransition } from 'react-transition-group';
import { useLocation } from 'react-router';
import { TimeSlider } from './TimeSlider';
import moment from 'moment';
import { periodFakeDay } from '../../mobileConstants';
import { getPeriodHours, parseNumber } from '../../../components/WeekOverview/Cell.helpers';

export const EditTaskOverlay: React.FC = () => {
    const location = useLocation();
    const uiCtx = useUiContext();
    const mobCtx = useMobileContext();
    const theme = useTheme();
    const classes = getStyles();

    const selectedDate = useMobile(state => state.selectedDate);
    const editTask = useMobile(state => state.editTask);

    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        console.debug('editingTask: ', editTask);
    }, [editTask]);

    const hours = useMemo(() => {
        const value = mobCtx.isPeriodView ? getPeriodHours(editTask?.timeEntries || []) : editTask?.timeEntries?.[0]?.hours || 0;
        return MathToFixed(parseNumber(value.toString().replace(/\,/g, '.')), 1);
    }, [mobCtx.isPeriodView, editTask?.timeEntries, editTask?.timeEntries?.[0]?.hours]);

    useLayoutEffect(() => {
        let timer;
        if (editTask && !showContent) {
            if (location.state?.fromProjectsOverview && mobCtx.isFirstOpenAfterProjectsOverview.current) {
                mobCtx.isFirstOpenAfterProjectsOverview.current = false;
                timer = setTimeout(() => {
                    setShowContent(true);
                }, 400);
            } else {
                setShowContent(true);
            }
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [editTask]);

    useEffect(() => {
        // scroll task into view
        let timer;
        if (!showContent || !editTask) {
            return;
        }
        const dateString = mobCtx.isPeriodView ? periodFakeDay : moment(new Date(selectedDate)).format(mobCtx.dateFormat);
        const taskDiv = document.getElementById(`${editTask.taskId}_${editTask.workType?.id}_${dateString}`);
        if (taskDiv) {
            // check if scroll container is fully scrolled
            // if it is, set scroll into view to start after the open editTask animation has ended
            // const scrollContainer = document.getElementById("timeoverview");
            // const isFullyScrolledDown = (scrollContainer.offsetHeight + scrollContainer.scrollTop) >= scrollContainer.scrollHeight;
            timer = setTimeout(() => {
                taskDiv.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }, 250);
            // }, isFullyScrolledDown ? 250 : 50);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [showContent, selectedDate]);

    const isReadOnly = useMemo(() => {
        if (!editTask) {
            return true;
        }

        const onlyTimesheetWithinStartEnd = uiCtx.onlyallowtimesheetwithintaskstartend;

        if (
            // TODO: fix this!
            // (selectedDate < uiCtx.user.start && new Date(uiCtx.user.start).getFullYear() > 1900)
            // || (selectedDate > uiCtx.user.end && new Date(uiCtx.user.end).getFullYear() > 1900)
            mobCtx.timeState === TimeState.approved ||
            mobCtx.timeState === TimeState.submitted ||
            editTask.projectClosed ||
            editTask.taskClosed ||
            (onlyTimesheetWithinStartEnd && (editTask.taskStart == undefined ? false : selectedDate < DateAsStartOfDay(new Date(editTask.taskStart)))) ||
            (onlyTimesheetWithinStartEnd && (editTask.taskEnd == undefined ? false : selectedDate > DateAsStartOfDay(new Date(editTask.taskEnd))))
            // TODO: make this!
            // || !workTypeAllowed(editTask)
        ) {
            return true;
        }

        return false;
    }, [editTask, selectedDate, mobCtx.timeState, uiCtx.user?.user.start, uiCtx.user?.user.end]);

    const onClickPin = useCallback(() => {
        uiCtx.timeApi
            .setPinned(editTask.taskId, editTask.pinned, editTask.workType?.id)
            .then(res => useMobile.getState().togglePinned({ task: editTask }))
            .catch(err => console.log(err));
    }, [editTask]);

    return (
        <>
            {editTask && (
                <div
                    className={`${classes.overlay} ${showContent ? AnimationClassNames.fadeIn200 : AnimationClassNames.fadeOut200}`}
                    onClick={() => setShowContent(false)}
                />
            )}

            <CSSTransition
                in={showContent}
                timeout={{ enter: 300, exit: 200 }}
                unmountOnExit
                onExited={() => useMobile.getState().setEditTask({ taskId: undefined, taskKey: undefined })}
                classNames={{
                    enter: classes.enter,
                    enterActive: classes.enterActive,
                    exit: classes.exit,
                    exitActive: classes.exitActive,
                }}
            >
                <div className={classes.root}>
                    <Stack className={`${classes.container}`}>
                        <Stack
                            horizontal
                            tokens={{ childrenGap: theme.spacing.m }}
                            className={classes.header}
                            // the empty onClick is needed because of some weird bug
                            // the onClick 'area' on fluent's TextField is way bigger than the actual element
                            // i have no idea why, but this empty onClick fixes it
                            onClick={() => {}}
                        >
                            <Stack grow styles={{ root: { maxWidth: '100%', display: 'grid' } }}>
                                <Text styles={{ root: classes.taskName }}>{editTask?.taskName}</Text>
                                <Text styles={{ root: classes.projectName }}>{editTask?.projectName}</Text>
                                {editTask?.workType?.name && <Text styles={{ root: classes.workTypeName }}>{editTask.workType.name}</Text>}
                            </Stack>
                            <Stack horizontal>
                                <IconButton
                                    iconProps={{ iconName: editTask?.pinned ? 'Unpin' : 'Pinned' }}
                                    onClick={onClickPin}
                                    styles={{ root: { color: `${uiCtx.isDarkmode ? theme.palette.themeSecondary : theme.palette.themePrimary} !important` } }}
                                />
                            </Stack>
                        </Stack>
                        <Stack tokens={{ childrenGap: theme.spacing.m }} className={`${classes.content}`}>
                            {!mobCtx.isPeriodView && <DaySelectorDropDown />}
                            <TimeSlider
                                // force new component to prevent 'save loop'
                                key={selectedDate.getDate()}
                                hours={hours}
                                editTask={editTask}
                                selectedDate={selectedDate}
                                isReadOnly={isReadOnly}
                            />
                        </Stack>
                        <Comment
                            // force new component to prevent 'save loop'
                            key={selectedDate.getDate()}
                            comment={editTask?.timeEntries?.[0]?.comment || ''}
                            editTask={editTask}
                            hours={hours}
                            selectedDate={selectedDate}
                            isReadOnly={isReadOnly}
                        />
                    </Stack>
                </div>
            </CSSTransition>
        </>
    );
};

const getStyles = makeStyles(theme => ({
    root: {
        zIndex: 310,
        touchAction: 'none',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.2)',
        zIndex: 300,
    },
    container: {
        position: 'relative',
        width: '100%',
        backgroundColor: theme.semanticColors.bodyFrameBackground,
        borderRadius: '20px 20px 0 0',
        boxShadow: '0 -20px 40px 0 rgba(0, 0, 0, 0.04), 0 -10px 20px 0 rgba(0, 0, 0, 0.04), 0 -5px 10px 0 rgba(0, 0, 0, 0.04)',
    },
    header: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
        padding: `${theme.spacing.m} ${theme.spacing.m} ${theme.spacing.s1} ${theme.spacing.l1}`,
        // borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
    },
    content: {
        padding: theme.spacing.l1,
        paddingTop: theme.spacing.s1,
        paddingBottom: theme.spacing.s1,
    },
    taskName: {
        fontSize: 15,
        fontWeight: 600,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
        paddingTop: theme.spacing.s2,
    },
    projectName: {
        fontSize: 14,
        paddingTop: 2,
        color: theme.semanticColors.bodySubtext,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
    },
    workTypeName: {
        fontSize: 12,
        fontWeight: 300,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
        paddingTop: 2,
        color: theme.semanticColors.bodySubtext,
        opacity: 0.8,
    },
    enter: {
        maxHeight: 0,
        transform: 'translateY(100%)',
    },
    enterActive: {
        maxHeight: '50vh',
        transform: 'translateY(0%)',
        transition: '300ms ease-out',
    },
    exit: {
        maxHeight: '50vh',
        transform: 'translateY(0%)',
    },
    exitActive: {
        maxHeight: 0,
        transform: 'translateY(100%)',
        transition: '200ms ease-in',
    },
}));
