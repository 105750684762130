import React, { useEffect, useState, useRef, ReactElement } from 'react';
import { IPersonaProps } from '@fluentui/react/lib/Persona';
import { CompactPeoplePicker, IBasePickerSuggestionsProps, ValidationState } from '@fluentui/react/lib/Pickers';
import User from '../../../../Model/User';
import Org from '../../../../Model/Org';
// import { isNullOrUndefined } from 'util';
import { useUiContext } from '../../../../components/Contexts/UiContext';
import { timeApi } from '../../../../Workaround/TimeApiWorkaround';
import { isNullOrUndefined } from '../../../../helpers/isNullOrUndefined';
import { ApiCalls } from '../../../../api/api';

interface propsAdminPicker {
    allUsersPersonas: IPersonaProps[];
    currentAdminPersonas: IPersonaProps[];
}

function AdminPicker(props: propsAdminPicker) {
    const [usersPersonas, setUsersPersonas] = useState<IPersonaProps[]>(props.allUsersPersonas);
    const [selectedPersonas, setSelectedPersonas] = useState<IPersonaProps[]>(props.currentAdminPersonas);

    const uiCtx = useUiContext();

    const suggestionProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested People',
        mostRecentlyUsedHeaderText: 'Suggested Contacts',
        noResultsFoundText: 'No results found',
        loadingText: 'Loading',
        showRemoveButtons: false,
        suggestionsAvailableAlertText: 'People Picker Suggestions available',
        suggestionsContainerAriaLabel: 'Suggested contacts',
    };

    useEffect(() => {
        setSelectedPersonas(props.currentAdminPersonas);
    }, [props.currentAdminPersonas]);

    useEffect(() => {
        setUsersPersonas(props.allUsersPersonas);
    }, [props.allUsersPersonas]);

    const picker = React.useRef(null);

    const onFilterChanged = (filterText: string, currentPersonas: IPersonaProps[], limitResults?: number): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (filterText) {
            let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText);

            filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
            filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
            return filterPromise(filteredPersonas);
        } else {
            return [];
        }
    };

    const filterPersonasByText = (filterText: string): IPersonaProps[] => {
        return usersPersonas.filter(
            item =>
                doesTextContain(item.text as string, filterText) ||
                doesTextContain(item.optionalText, filterText) ||
                doesTextContain(item.secondaryText as string, filterText),
        );
    };

    const filterPromise = (personasToReturn: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
        return personasToReturn;
    };

    // const returnMostRecentlyUsed = (currentPersonas: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
    //     return filterPromise(removeDuplicates(mostRecentlyUsed, currentPersonas));
    // };

    const onRemoveSuggestion = (item: IPersonaProps): void => {
        const indexPeopleList: number = usersPersonas.indexOf(item);
        // const indexMostRecentlyUsed: number = mostRecentlyUsed.indexOf(item);

        if (indexPeopleList >= 0) {
            const newPeople: IPersonaProps[] = usersPersonas.slice(0, indexPeopleList).concat(usersPersonas.slice(indexPeopleList + 1));
            setUsersPersonas(newPeople);
        }

        // if (indexMostRecentlyUsed >= 0) {
        //     const newSuggestedPeople: IPersonaProps[] = mostRecentlyUsed
        //         .slice(0, indexMostRecentlyUsed)
        //         .concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1));
        //     setMostRecentlyUsed(newSuggestedPeople);
        //}
    };

    const onAdminsChanged = (adminsAsArray: IPersonaProps[]): void => {
        const preSelectedPersonas = selectedPersonas;
        let newlyAddedOrRemovedAdminPersona = null;
        if (preSelectedPersonas.length < adminsAsArray.length) {
            // An persona has been added
            newlyAddedOrRemovedAdminPersona = adminsAsArray
                .map((admin: IPersonaProps, index: any) => {
                    if (selectedPersonas.indexOf(admin) === -1) {
                        return admin;
                    }
                })
                .find(aU => !isNullOrUndefined(aU));
            if (adminsAsArray.length > 0) {
                // Call API to change Persona/User admin status to true;
                ApiCalls.setIsAdmin({ userId: newlyAddedOrRemovedAdminPersona.itemID, isAdmin: true });
                setSelectedPersonas(adminsAsArray);
            }
        } else {
            // An Persona has been removed
            newlyAddedOrRemovedAdminPersona = selectedPersonas
                .map((admin: IPersonaProps, index: any) => {
                    if (adminsAsArray.indexOf(admin) === -1) {
                        return admin;
                    }
                })
                .find(aU => !isNullOrUndefined(aU));
            if ((newlyAddedOrRemovedAdminPersona.itemID as string).toLowerCase() != uiCtx.user.user.id.toLowerCase() && adminsAsArray.length > 0) {
                // Call API to change Persona/User admin status to false;
                ApiCalls.setIsAdmin({ userId: newlyAddedOrRemovedAdminPersona.itemID, isAdmin: false });
                setSelectedPersonas(adminsAsArray);
            }
        }
    };

    return (
        <CompactPeoplePicker
            styles={{ root: { width: 300 } }}
            onChange={onAdminsChanged}
            selectedItems={selectedPersonas}
            onResolveSuggestions={onFilterChanged}
            getTextFromItem={getTextFromItem}
            pickerSuggestionsProps={suggestionProps}
            className={'ms-PeoplePicker'}
            onRemoveSuggestion={onRemoveSuggestion}
            onValidateInput={validateInput}
            inputProps={{
                onBlur: (ev: React.FocusEvent<HTMLInputElement>) => console.log('onBlur called'),
                onFocus: (ev: React.FocusEvent<HTMLInputElement>) => console.log('onFocus called'),
                'aria-label': 'People Picker',
                placeholder: 'Search here to add an admin...',
            }}
            componentRef={picker}
            resolveDelay={500}
            disabled={false}
        />
    );

    function doesTextStartWith(text: string, filterText: string): boolean {
        return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
    }

    function doesTextContain(text: string, filterText: string): boolean {
        return text.toLowerCase().indexOf(filterText.toLowerCase()) != -1;
    }

    function removeDuplicates(personas: IPersonaProps[], possibleDupes: IPersonaProps[]) {
        return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
    }

    function listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
        if (!personas || !personas.length || personas.length === 0) {
            return false;
        }
        return personas.filter(item => item.text === persona.text).length > 0;
    }

    function convertResultsToPromise(results: IPersonaProps[]): Promise<IPersonaProps[]> {
        return new Promise<IPersonaProps[]>((resolve, reject) => setTimeout(() => resolve(results), 2000));
    }

    function getTextFromItem(persona: IPersonaProps): string {
        return persona.text as string;
    }

    function validateInput(input: string): ValidationState {
        if (input.indexOf('@') !== -1) {
            return ValidationState.valid;
        } else if (input.length > 1) {
            return ValidationState.warning;
        } else {
            return ValidationState.invalid;
        }
    }
}

export default AdminPicker;
