import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { ENVIRONMENT } from '../../constants/queryKeys';
import { ApiCalls } from '../../api/api';
import { EnvironmentOptions } from '../../api/generated/data-contracts';

class NotReadyError extends Error {}

export const useEnvironment = () => {
    const instance = useQuery({
        queryKey: [ENVIRONMENT],
        queryFn: async () => {
            const data = await ApiCalls.environmentList().then(res => {
                return res.data;
            });
            if (!data.isReady) {
                throw new NotReadyError();
            }
            return data;
        },
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: (count, error) => {
            if (error instanceof NotReadyError) {
                return true;
            }
            return count <= 4;
        },
        retryDelay: 3000,
        // retryDelay: count => 3000,
        suspense: true,
    });
    return { ...instance, env: instance.data } as { env: EnvironmentOptions } & UseQueryResult<EnvironmentOptions, unknown>;
};
