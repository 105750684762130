import { TimeEntry } from '../api/generated/data-contracts';
import WorkProjectType from './NewModels/WorkProjectType';
import WorkType from './NewModels/WorkType';
import { ProjectSource } from './ProjectSource';

export default class TimePeriodRowM {
    constructor(
        public key: string,
        public timeId: string,
        public projectId: string,
        public projectName: string,
        public projectNumber: string,
        public projectSource: ProjectSource,
        public projectClosed: boolean,
        public taskId: string,
        public taskName: string,
        public taskStart: Date,
        public taskEnd: Date,
        public taskClosed: boolean,
        public taskIsDefault: boolean,
        public taskDescription: string,
        public pinned: boolean,
        public changetoken: string,
        public projectOrder: number,
        public taskOrder: number,
        public plannedWork: number,
        public remainingWork: number,
        public actualWork: number,
        public timeEntries: TimeEntry[],
        public workType: WorkType | undefined,
        public projectWorkType: WorkProjectType,
    ) {}
    // static Blank(key:string,timeId:string, projectId:string, projectName:string, taskId:string, taskName:string,): TimePeriodRowM  {
    //     let newBlank = new TimePeriodRowM(key, timeId, projectId, projectName, taskId, taskName, "",
    //         0, "", 0, "", 0, "", 0, "",
    //         0, "", 0, "", 0, "",
    //         false, "", 9999, 9999);
    //     return newBlank;
    //   }
}
