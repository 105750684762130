import React, { useEffect, useState } from 'react';
import { useUiContext } from '../../components/Contexts/UiContext';
import { PrimaryButton, TextField } from '@fluentui/react';
import { PageHeader, PageContent, ContentSection } from '../../components/LayoutElements';
import { DisplayLogging } from './DisplayLogging';

var gimportlog = 'Import Started\n';
function ImportActuals() {
    const uiCtx = useUiContext();

    const [importing, setimporting] = useState<boolean>(false);
    const [log, setlog] = useState<string>('');

    useEffect(() => {
        DisplayLogging.Init(_ => setDeugMessages(_));
    }, []);

    const setDeugMessages = (dto: any) => {
        console.log(dto);
        if (dto.properties.hasOwnProperty('source') && dto.properties['source'] == 'importactuals') {
            gimportlog = gimportlog + (new Date().toLocaleTimeString() + ': \t') + dto.message + '\n';
            setlog(gimportlog);
            if (dto.properties.hasOwnProperty('log') && dto.properties['log'] != '') {
                debugger;
                gimportlog = gimportlog + '\t\t\tLog: ' + dto.properties['log'].replaceAll('\n', '\n\t\t\t') + '\n';
                setlog(gimportlog);
            }
        }
    };
    const btnImportClicked = () => {
        console.log('Importing...');
        setimporting(true);
        uiCtx.timeApi.importData();
    };

    return (
        <>
            <PageHeader title="Improt Actuals" icon="CloudImportExport" />

            <PageContent>
                <ContentSection>
                    <PrimaryButton disabled={importing} onClick={btnImportClicked}>
                        Import Now
                    </PrimaryButton>
                    {importing && <TextField multiline rows={40} label="Log" cols={200} readOnly={true} value={log}></TextField>}
                </ContentSection>
            </PageContent>
        </>
    );
}

export default ImportActuals;
