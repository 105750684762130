import React from 'react';
// import { useHistory } from 'react-router-dom';
// import { makeStyles } from "@fluentui/react-theme-provider";
import { Text, Stack, AnimationStyles, makeStyles } from '@fluentui/react';
import TimePeriodRowM from '../../../../../Model/TimePeriodRowM';
import { useMobile } from '../../../../useMobile';
import { mobileConstants } from '../../../../mobileConstants';
import { useMobileContext } from '../../../../MobileContext';
import { useNavigate } from 'react-router';

interface IProps extends TimePeriodRowM {
    keyId: string;
}

export const TaskRow: React.FC<IProps> = props => {
    const history = useNavigate();
    const mobCtx = useMobileContext();
    const classes = getStyles();

    const onEditTask = () => {
        useMobile.getState().addTask({
            setEditTask: true,
            expand: true,
            newTask: new TimePeriodRowM(
                props.keyId,
                props.timeId,
                props.projectId,
                props.projectName,
                props.projectNumber,
                props.projectSource,
                props.projectClosed,
                props.taskId,
                props.taskName,
                props.taskStart,
                props.taskEnd,
                props.taskClosed,
                props.taskIsDefault,
                props.taskDescription,
                props.pinned,
                '',
                props.projectOrder,
                props.taskOrder,
                props.plannedWork,
                props.remainingWork,
                props.actualWork,
                [],
                props.workType,
                props.projectWorkType,
            ),
        });
        mobCtx.isFirstOpenAfterProjectsOverview.current = true;
        // history.push('/');
        history('/', {
            // pathname: '/',
            state: {
                fromProjectsOverview: true,
            },
        });
    };

    return (
        <Stack horizontal verticalAlign="center" className={classes.root} onClick={onEditTask}>
            <Text className={classes.title}>{props.taskName}</Text>
        </Stack>
    );
};
const getStyles = makeStyles(theme => ({
    root: {
        ...AnimationStyles.fadeIn200,
        height: mobileConstants.gridRowHeight,
        paddingLeft: 66,
        paddingRight: 16,
        overflowX: 'hidden',
    },
    title: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        minWidth: 100,
        '.isDarkmode &': {
            color: `${theme.semanticColors.bodySubtext} !important`,
        },
    },
}));
