import create, { GetState, SetState } from 'zustand';
import produce, { current, enableMapSet } from 'immer';
import { daysArr, periodFakeDay } from './mobileConstants';
import { IMyTimeViewDay, IPeriodDay, IUpdateCell } from './mobileTypes';
import { MathToFixed, getEmptyGuid } from '../components/Utils';
import { TimeWarning } from '../Model/TimeWarning';
import { addValueToTotalValue, getCleanValueDiff, round2Number } from './mobileUtils';
import TimePeriodRowM from '../Model/TimePeriodRowM';
import { IUiContext } from '../components/Contexts/UiContext';
import WorkType from '../Model/NewModels/WorkType';
import { IMobileContext } from './MobileContext';
import moment from 'moment';
import { RegisterTimeResponseCode, RegisterTimeResponseDto } from '../Model/RegisterTimeResponseDto';
import { RegisterTimeByPeriodDayResponseCode, RegisterTimeByPeriodDayResponseDto } from '../Model/RegisterTimeByPeriodDayResponseDto';
import { getPeriodHours } from '../components/WeekOverview/Cell.helpers';
import { ApiCalls } from '../api/api';
import { TimeRegistrationResult } from '../api/generated/data-contracts';
enableMapSet();
export interface IUseMobile {
    isLoading: {
        active: boolean;
        overlay?: boolean;
    };
    setIsloading: (args: { active: boolean; overlay?: boolean }) => void;
    dateFormat: string;
    timeLowerLimitConfig: number;
    isPeriodView: boolean;
    myTimeView: IMyTimeViewDay[];
    totalValues: { [key: string]: any };
    allTasks: Map<string, TimePeriodRowM> | undefined;
    selectedDate: Date | undefined;
    setSelectedDate: (args: { newSelectedDate: Date; setEditTask?: boolean; expandTask?: boolean; mobCtx?: IMobileContext }) => void;
    setExpanded: (args: { dateString: string; expandDay?: boolean; projectId?: string; expandProject?: boolean }) => void;
    editTask: TimePeriodRowM | undefined;
    setEditTask: (args: { taskId: string; taskKey: string; newSelectedDate?: Date }) => void;
    updateCell: (args: {
        taskId: string;
        date: Date;
        hours: number;
        comment: string;
        flagged: boolean;
        timePeriodId: string;
        workType: any;
        uiCtx: IUiContext;
        onSuccess?: (timeEntry: any) => void;
        updateTask?: boolean;
    }) => void;
    updateCellPeriod: (args: {
        taskId: string;
        totalHours: number;
        periodDays: IPeriodDay[];
        comment: string;
        flagged: boolean;
        selectedTimePeriod: any;
        workType: any;
        uiCtx: IUiContext;
        onSuccess?: () => void;
        updateTask?: boolean;
    }) => void;
    addTask: (args: { newTask: TimePeriodRowM; setEditTask?: boolean; expand?: boolean }) => void;
    updateTask: (args: { newTask: TimePeriodRowM; updateEditTask?: boolean }) => void;
    togglePinned: (args: { task: TimePeriodRowM }) => void;
}

export const useMobile = create<IUseMobile>((set: SetState<IUseMobile>, get: GetState<IUseMobile>) => ({
    isLoading: { active: false, overlay: false },
    setIsloading: ({ active, overlay }) =>
        set(state => {
            state.isLoading = { active, overlay };
        }),
    dateFormat: 'DD[.] MMM',
    timeLowerLimitConfig: 0,
    isPeriodView: false,
    myTimeView: [],
    allTasks: undefined,
    totalValues: { all: 0 },
    selectedDate: new Date(),
    setSelectedDate: ({ newSelectedDate, setEditTask, expandTask }) =>
        set(
            produce((state: IUseMobile) => {
                state.selectedDate = new Date(newSelectedDate);

                if (setEditTask) {
                    const task = get().allTasks.get(`${state.editTask?.taskId}_${state.editTask?.workType?.id}`);

                    if (task) {
                        state.editTask = {
                            ...task,
                            timeEntries: state.isPeriodView
                                ? task.timeEntries
                                : task.timeEntries?.filter(timeEntry => moment(new Date(timeEntry.date)).format(state.dateFormat) === moment(new Date(newSelectedDate)).format(state.dateFormat)) || [],
                        };
                        if (expandTask) {
                            expandToTask({ state, task, date: newSelectedDate });
                        }
                    }
                }
            }),
        ),
    setExpanded: ({ dateString, expandDay, projectId, expandProject }) =>
        set(
            produce((state: IUseMobile) => {
                const dayIndex = state.myTimeView?.findIndex(day => day.dateString === dateString);
                if (dayIndex > -1) {
                    if (expandDay !== undefined) {
                        state.myTimeView[dayIndex].isExpanded = expandDay;
                    }
                    if (projectId && expandProject !== undefined) {
                        const projectIndex = state.myTimeView[dayIndex].projects.findIndex(project => project.projectId === projectId);
                        if (projectIndex > -1) {
                            state.myTimeView[dayIndex].projects[projectIndex].isExpanded = expandProject;
                        }
                    }
                }
            }),
        ),
    editTask: undefined,
    setEditTask: ({ taskId, taskKey, newSelectedDate }) =>
        set(
            produce((state: IUseMobile) => {
                if (!taskId) {
                    state.editTask = undefined;
                } else {
                    const task = get().allTasks.get(taskKey);

                    if (task && state.editTask) {
                        state.editTask.timeEntries = state.isPeriodView
                            ? task.timeEntries
                            : task.timeEntries?.filter(
                                  timeEntry => moment(new Date(timeEntry.date)).format(state.dateFormat) === moment(new Date(newSelectedDate || state.selectedDate)).format(state.dateFormat),
                              ) || [];
                    } else if (task) {
                        state.editTask = {
                            ...task,
                            timeEntries: state.isPeriodView
                                ? task.timeEntries
                                : task.timeEntries?.filter(
                                      timeEntry => moment(new Date(timeEntry.date)).format(state.dateFormat) === moment(new Date(newSelectedDate || state.selectedDate)).format(state.dateFormat),
                                  ) || [],
                        };
                    }

                    if (task && newSelectedDate) {
                        state.selectedDate = new Date(newSelectedDate);
                    }
                }
            }),
        ),
    // updateCell: ({ taskId, date, hours, comment, timePeriodId, workType, uiCtx, onSuccess, updateTask }) => {
    //     uiCtx.timeApi.updateTime(taskId, new Date(date).removeTimeZone(), hours, comment, timePeriodId, workType?.id || getEmptyGuid())
    //     .then((te) => {
    //         console.debug("saved", new Date(), te);
    //         if (te == null) {
    //             uiCtx.setTimeError(new TimeWarning("Period is close", "The current period has been closed. Please refresh the grid."));
    //             return null;
    //         } else if (onSuccess) {
    //             onSuccess({...te, date: new Date(date).removeTimeZone()});
    //         };
    //         if (updateTask) {
    //             const task = get().editTask;
    //             if (task) {
    //                 get().updateTask({ updateEditTask: true, newTask: {...task, timeEntries: [{...te, date: new Date(date).removeTimeZone()}] } })
    //                 // get().updateTask({ editTask: true, newTask: {...task, timeEntries: [te] } })
    //             };
    //         };

    //         return {...te, date: new Date(date).removeTimeZone()};
    //     })
    //     .catch(err => console.log("error: ", err));
    // },
    updateCell: ({ taskId, date, hours, comment, flagged, timePeriodId, workType, uiCtx, onSuccess, updateTask }) => {
        // ApiCalls.registerTimeEntry(
        //     {
        //         taskId: taskId,
        //         date: new Date(date).removeTimeZone().toString(),
        //         hours,
        //         comment,
        //         flagged,
        //         userReportingPeriodId: timePeriodId,
        //         workTypeId: workType?.id || getEmptyGuid()
        //     }
        // )
        uiCtx.timeApi
            .updateTime(taskId, new Date(date).removeTimeZone(), hours, comment, flagged, timePeriodId, workType?.id || getEmptyGuid())
            .then((res: TimeRegistrationResult) => {
                console.debug('saved', new Date(), res.timeEntry);

                if (res === null || res === undefined || (res as any)?.stackTrace || res.registrationStatusCode === 'UnknownError') {
                    // Unknown Error Situations
                    uiCtx.setTimeError(new TimeWarning('Unknown Error', 'An unknown error occoured, please refresh and try again. Report this, if issue continues or reappears.'));
                    return;
                }

                switch (res.registrationStatusCode) {
                    case 'Success':
                        onSuccess({ ...res.timeEntry, date: new Date(date).removeTimeZone() });
                        break;
                    case 'PeriodIsClosed':
                        uiCtx.setTimeError(new TimeWarning('Period is closed', 'The period for the changed timesheet has been closed. Please refresh.'));
                        return;
                    case 'InsufficientPermissions':
                        uiCtx.setTimeError(new TimeWarning('Insufficient permissions', 'You do not have permission to do this. Please refresh.'));
                        return;
                    case 'DbError':
                        uiCtx.setTimeError(new TimeWarning('Database error', 'Something tried to violate a database constraint. Please refresh and try again.'));
                        return;
                    default:
                        uiCtx.setTimeError(new TimeWarning('Unknown Error', 'An unknown error occoured, please refresh and try again. Report this, if issue continues or reappears.'));
                        return;
                }

                if (updateTask) {
                    const task = get().editTask;
                    if (task) {
                        const assignment = res.timeEntry.assignment;
                        assignment.id = res.timeEntry.assignment.id;

                        get().updateTask({
                            updateEditTask: true,
                            newTask: {
                                ...task,
                                timeEntries: [
                                    {
                                        id: res.timeEntry.id,
                                        date: new Date(date).removeTimeZone().toString(),
                                        assignment: res.timeEntry.assignment,
                                        comment: res.timeEntry.comment,
                                        flagged: res.timeEntry.flagged,
                                        hours: res.timeEntry.hours,
                                        userReportingPeriod: res.timeEntry.userReportingPeriod,
                                    },
                                ],
                            },
                        });
                        // get().updateTask({ editTask: true, newTask: {...task, timeEntries: [te] } })
                    }
                }
                return { ...res.timeEntry, date: new Date(date).removeTimeZone() };
            })
            .catch(err => console.log('error: ', err));
    },
    updateCellPeriod: ({ taskId, totalHours, periodDays, comment, flagged, selectedTimePeriod, workType, uiCtx, onSuccess, updateTask }) => {
        const hoursPrDay = MathToFixed(totalHours / periodDays.length, 2);

        uiCtx.timeApi
            .saveCellsPeriodOverview({
                taskId: taskId,
                days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
                hours: totalHours,
                comment,
                flagged,
                userReportingPeriodId: selectedTimePeriod.reportPeriodId,
                // userReportingPeriodId: selectedTimePeriod.reportPeriodId,
                workTypeId: workType?.id || getEmptyGuid(),
            })
            .then((res: RegisterTimeByPeriodDayResponseDto) => {
                if (res === null || res === undefined || (res as any)?.stackTrace || res.responseCode === RegisterTimeByPeriodDayResponseCode.UnknownError) {
                    // Unknown Error Situations
                    uiCtx.setTimeError(new TimeWarning('Unknown Error', 'An unknown error occoured, please refresh and try again. Report this, if issue continues or reappears.'));
                    return;
                }

                switch (res.responseCode) {
                    case RegisterTimeByPeriodDayResponseCode.Success:
                        onSuccess();
                        break;
                    case RegisterTimeByPeriodDayResponseCode.PeriodIsClosed:
                        uiCtx.setTimeError(new TimeWarning('Period is closed', 'The period for the changed timesheet has been closed. Please refresh.'));
                        return;
                    case RegisterTimeByPeriodDayResponseCode.InsufficientPermissions:
                        uiCtx.setTimeError(new TimeWarning('Insufficient permissions', 'You do not have permission to do this. Please refresh.'));
                        return;
                    case RegisterTimeByPeriodDayResponseCode.DbError:
                        uiCtx.setTimeError(new TimeWarning('Database error', 'Something tried to violate a database constraint. Please refresh and try again.'));
                        return;
                    default:
                        uiCtx.setTimeError(new TimeWarning('Unknown Error', 'An unknown error occoured, please refresh and try again. Report this, if issue continues or reappears.'));
                        return;
                }

                if (updateTask) {
                    const task = get().editTask;
                    if (task) {
                        let oldEntries = [...task.timeEntries];
                        periodDays.forEach((day, i) => {
                            const indexOfEntry = oldEntries?.findIndex(entry => moment(new Date(entry.date)).format(get().dateFormat) === moment(new Date(day.dayDate)).format(get().dateFormat));

                            if (indexOfEntry !== -1 && indexOfEntry !== undefined) {
                                oldEntries = oldEntries.map((entry, i) => {
                                    if (i === indexOfEntry) {
                                        return {
                                            ...entry,
                                            hours: hoursPrDay,
                                            comment: comment,
                                            flagged: flagged,
                                        };
                                    }
                                    return entry;
                                });
                            } else {
                                oldEntries.push({
                                    id: res.timeEntryIds[i],
                                    assignment: { workType: workType } as any,
                                    date: day.dayDate.toString(),
                                    hours: hoursPrDay,
                                    comment: comment,
                                    flagged: flagged,
                                    userReportingPeriod: selectedTimePeriod,
                                });
                            }
                        });
                        get().updateTask({ updateEditTask: true, newTask: { ...task, timeEntries: oldEntries } });
                    }
                }
            })
            .catch(err => console.log('error: ', err));
    },
    addTask: ({ newTask, setEditTask, expand }) =>
        set(
            produce((state: IUseMobile) => {
                const task = state.allTasks.get(`${newTask.taskId}_${newTask.workType?.id}`);

                if (!task) {
                    state.allTasks.set(`${newTask.taskId}_${newTask.workType?.id}`, newTask);
                }
                const currentTask = task || newTask;
                if (setEditTask) {
                    state.editTask = {
                        ...currentTask,
                        timeEntries: state.isPeriodView
                            ? currentTask.timeEntries
                            : currentTask.timeEntries?.filter(
                                  timeEntry => moment(new Date(timeEntry.date)).format(state.dateFormat) === moment(new Date(state.selectedDate)).format(state.dateFormat),
                              ) || [],
                    };
                }
                // needed for scroll into view else the task might not be rendered
                if (expand) {
                    expandToTask({ state, task: currentTask, date: state.selectedDate });
                }
            }),
        ),
    updateTask: ({ newTask, updateEditTask }) =>
        set(
            produce((state: IUseMobile) => {
                let newValue: number | undefined;
                let valueDiff: number | undefined;
                const taskKey = `${newTask.taskId}_${newTask.workType?.id}`;

                if (!state.allTasks.has(taskKey)) {
                    state.allTasks.set(taskKey, newTask);
                    if (state.isPeriodView) {
                        const periodHours = getPeriodHours(newTask.timeEntries);
                        newValue = round2Number(periodHours);
                        valueDiff = round2Number(periodHours);
                    } else {
                        newValue = newTask.timeEntries?.[0].hours;
                        valueDiff = newTask.timeEntries?.[0].hours;
                    }
                } else {
                    const oldTask = state.allTasks.get(taskKey);

                    if (state.isPeriodView) {
                        const oldValue = round2Number(getPeriodHours(oldTask.timeEntries));
                        const newValueFromPeriod = round2Number(getPeriodHours(newTask.timeEntries));
                        valueDiff = getCleanValueDiff(newValueFromPeriod, oldValue);
                        newValue = newValueFromPeriod;
                        state.allTasks.set(taskKey, { ...oldTask, ...newTask });
                        // state.allTasks.set(taskKey, { ...oldTask, timeEntries: oldTask.timeEntries });
                    } else {
                        const oldTimeEntry = oldTask.timeEntries?.find(timeEntry => timeEntry.id === newTask.timeEntries?.[0]?.id);

                        if (oldTimeEntry) {
                            valueDiff = getCleanValueDiff(newTask.timeEntries?.[0].hours, oldTimeEntry.hours);
                            newValue = newTask.timeEntries?.[0].hours;
                        } else {
                            newValue = newTask.timeEntries?.[0].hours;
                            valueDiff = newTask.timeEntries?.[0].hours;
                        }

                        const oldTimeEntryIndex = oldTask.timeEntries?.findIndex(timeEntry => timeEntry.id === newTask.timeEntries?.[0]?.id);

                        if (oldTimeEntryIndex > -1) {
                            oldTask.timeEntries[oldTimeEntryIndex] = newTask.timeEntries?.[0];
                            state.allTasks.set(taskKey, { ...oldTask, timeEntries: oldTask.timeEntries });
                        } else {
                            state.allTasks.set(taskKey, { ...oldTask, timeEntries: [...oldTask.timeEntries, newTask.timeEntries[0]] } as TimePeriodRowM);
                        }
                    }
                }

                if (updateEditTask) {
                    state.editTask = { ...newTask };
                }
                const isPeriodViewDayString = state.isPeriodView ? periodFakeDay : undefined;
                if (newValue !== undefined && valueDiff !== undefined && valueDiff !== 0) {
                    updateTotals({ state, dayString: isPeriodViewDayString, date: new Date(state.selectedDate), task: newTask, valueDiff: valueDiff, value: newValue });
                }

                updateMyTimeView({ state, dayString: isPeriodViewDayString, date: new Date(state.selectedDate), task: newTask });
            }),
        ),
    togglePinned: ({ task }) =>
        set(
            produce((state: IUseMobile) => {
                state.editTask.pinned = !state.editTask.pinned;

                const taskKey = `${task.taskId}_${task.workType?.id}`;
                const oldTask = state.allTasks.get(taskKey);
                if (oldTask) {
                    state.allTasks.set(taskKey, { ...oldTask, pinned: !oldTask.pinned } as TimePeriodRowM);
                }
            }),
        ),
}));

interface IUpdateMyTimeView {
    state: any;
    dayString?: string;
    date: Date;
    // projectId: string;
    task: TimePeriodRowM;
}
const updateMyTimeView = ({ state, dayString, date, task }: IUpdateMyTimeView): void => {
    const dayName = daysArr[new Date(date).getDay()];
    const dateString = dayString || moment(new Date(date)).format(state.dateFormat);
    const dayIndex = state.isPeriodView ? 0 : state.myTimeView.findIndex(day => day.dateString === dateString);
    const indexOfProject = state.myTimeView[dayIndex].projects?.findIndex(project => project.projectId === task.projectId);
    const indexOfTask =
        indexOfProject === -1 ? undefined : state.myTimeView[dayIndex].projects[indexOfProject].tasks.findIndex(t => `${t.taskId}_${t.workType?.id}` === `${task.taskId}_${task.workType?.id}`);

    if (indexOfTask > -1) {
        return;
    } else if (indexOfTask === undefined) {
        state.myTimeView[dayIndex].projects.push({
            projectId: task.projectId,
            projectName: task.projectName,
            dayName,
            dayDate: date,
            dateString: dateString,
            tasks: [
                {
                    taskKey: `${task.taskId}_${task.workType?.id}`,
                    taskId: task.taskId,
                    taskName: task.taskName,
                    workType: task.workType,
                    projectId: task.projectId,
                    dayName,
                    dayDate: date,
                    dateString: dateString,
                },
            ],
        });
        // .sort((a: any, b: any) => a.projectName > b.projectName ? 1 : -1);
    } else if (indexOfTask === -1) {
        state.myTimeView[dayIndex].projects[indexOfProject].tasks.push({
            taskKey: `${task.taskId}_${task.workType?.id}`,
            taskId: task.taskId,
            taskName: task.taskName,
            workType: task.workType,
            projectId: task.projectId,
            dayName,
            dayDate: date,
            dateString: dateString,
        });
        // .sort((a: any, b: any) => a.taskName > b.taskName ? 1 : -1);

        // } else if (indexOfTask === undefined) {
    } else if (indexOfProject === null || indexOfProject === undefined || indexOfProject === -1) {
        state.myTimeView[dayIndex].projects = [
            {
                projectId: task.projectId,
                projectName: task.projectName,
                dayName,
                dayDate: date,
                dateString: dateString,
                tasks: [
                    {
                        taskKey: `${task.taskId}_${task.workType?.id}`,
                        taskId: task.taskId,
                        taskName: task.taskName,
                        workType: task.workType,
                        projectId: task.projectId,
                        dayName,
                        dayDate: date,
                        dateString: dateString,
                    },
                ],
            },
        ];
    }
};

interface IExpandToTask {
    state: any;
    task: any;
    date: Date;
}

const expandToTask = ({ state, task, date }: IExpandToTask) => {
    const selectedDayName = daysArr[new Date(date).getDay()];
    const dateString = state.isPeriodView ? periodFakeDay : moment(new Date(date)).format(state.dateFormat);
    const dayIndex = state.isPeriodView ? 0 : state.myTimeView?.findIndex(day => day.dateString === dateString);
    const projectIndex = state.myTimeView[dayIndex].projects.findIndex(project => project.projectId === task.projectId);

    state.myTimeView[dayIndex].isExpanded = true;

    if (projectIndex > -1) {
        state.myTimeView[dayIndex].projects[projectIndex].isExpanded = true;
    } else {
        state.myTimeView[dayIndex].projects.push({
            projectId: task.projectId,
            projectName: task.projectName,
            dayName: selectedDayName,
            dayDate: date,
            dateString: dateString,
            isExpanded: true,
            tasks: [
                {
                    taskKey: `${task.taskId}_${task.workType?.id}`,
                    taskId: task.taskId,
                    taskName: task.taskName,
                    workType: task.workType,
                    projectId: task.projectId,
                    dayName: selectedDayName,
                    dayDate: date,
                    dateString: dateString,
                },
            ],
        });
    }
};

interface IUpdateTask {
    state: any;
    date: Date;
    projectId: string;
    taskId: string;
    valueDiff: number;
    value: number;
    comment: string;
    timePeriodId: string;
    workType: any;
}

interface IUpdateTotals {
    state: any;
    dayString?: string;
    date: Date;
    // projectId: string;
    task: any;
    valueDiff: number;
    value: number;
}

const updateTotals = ({ state, dayString, date, task, valueDiff, value }: IUpdateTotals): void => {
    const dayName = daysArr[new Date(date).getDay()];
    const dateString = dayString || moment(new Date(date)).format(state.dateFormat);
    state.totalValues.all = addValueToTotalValue(state.totalValues.all, valueDiff);

    if (!state.totalValues[dateString]) {
        state.totalValues[dateString] = { value: round2Number(value) };
    } else {
        state.totalValues[dateString].value = addValueToTotalValue(state.totalValues[dateString].value, valueDiff);
    }

    if (!state.totalValues[dateString][task.projectId]) {
        state.totalValues[dateString][task.projectId] = { value: round2Number(value) };
    } else {
        state.totalValues[dateString][task.projectId].value = addValueToTotalValue(state.totalValues[dateString][task.projectId].value, valueDiff);
    }

    if (!state.totalValues[dateString][task.projectId][`${task.taskId}_${task.workType?.id}`]) {
        state.totalValues[dateString][task.projectId][`${task.taskId}_${task.workType?.id}`] = round2Number(value);
    } else {
        state.totalValues[dateString][task.projectId][`${task.taskId}_${task.workType?.id}`] = addValueToTotalValue(
            state.totalValues[dateString][task.projectId][`${task.taskId}_${task.workType?.id}`],
            valueDiff,
        );
    }
};
