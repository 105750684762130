export const GET_USERS = 'GET_USERS';

export const GET_ADMIN_PROJECT_AND_TASKS_FOR_CONFIG = 'GET_ADMIN_PROJECT_AND_TASKS_FOR_CONFIG';

export const GET_PROJECT_SOURCES = 'GET_PROJECT_SOURCES';

export const GET_USERS_WITH_CALENDARS = 'GET_USERS_WITH_CALENDARS';

export const GET_CALENDARS = 'GET_CALENDARS';

export const GET_APP_VERSION = 'GET_APP_VERSION';

export const GET_CONFIG = 'GET_CONFIG';

export const GET_HELP_SUPPORT = 'GET_HELP_SUPPORT';
export const GET_HELP_VIDEOS = 'GET_HELP_VIDEOS';
export const GET_HELP_FAQ = 'GET_HELP_FAQ';

export const GET_ALL_PERIODS_AS_ADMIN_PERIODS = 'GET_ALL_PERIODS_AS_ADMIN_PERIODS';

export const GET_USERS_WITH_APPROVERS = 'GET_USERS_WITH_APPROVERS';

export const ENVIRONMENT = 'ENVIRONMENT';

export const GET_AD_GROUPS = 'GET_AD_GROUPS';

export const RELEASE_NOTES = 'RELEASE_NOTES';

export const INTEGRATIONS_SETTINGS = 'INTEGRATIONS_SETTINGS';
export const INTEGRATIONS_MICROSOFT_ENTRAID_GROUPS = 'INTEGRATIONS_MICROSOFT_ENTRAID_GROUPS';

export const INTEGRATION_DATAVERSE_ENV_LIST = 'INTEGRATION_DATAVERSE_ENV_LIST';
export const SEARCH_USERS = 'SEARCH_USERS';
export const SET_USER_NUMBER = 'SET_USER_NUMBER';
export const PROJECT_APPROVALS = 'PROJECT_APPROVALS';
export const IMPORT_TIME_ENTRIES = 'IMPORT_TIME_ENTRIES';
export const CONFIGURATION_FULL = 'CONFIGURATION_FULL';
export const CONFIGURATION_BASIC = 'CONFIGURATION_BASIC';
