import React, { useEffect, useState, Fragment } from 'react';
import TimeRow from '../Model/TimePeriodRowM';
import TimeRowGroup from '../Model/TimePeriodGroupM';
import TimePeriodGroup from './TimePeriodGroup';
import '../Styles/TimePeriod.scss';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { TimeGridMode } from '../Model/TimeGridMode';
import {
    SortTimeRows,
    NumberMax2DecimalFormatAsString,
    GetTotalFromTimeRowInDayArray,
    IsDatesNoTimeEqual,
    MathToFixed,
    FormatValue,
    IsFirstDateLessEqualThanSecondDate,
    IsWeekend,
    IsHoliday,
    GetHolidayName,
    IsDeadline,
} from './Utils';
import EDays from '../Model/EDays';
import { useUiContext } from './Contexts/UiContext';
import PeriodDay from '../Model/PeriodDay';
import { ScrollablePane, Stack, Text, TooltipHost, IconButton, DirectionalHint } from '@fluentui/react';
import { TimeState } from '../Model/State';
import WorkType from '../Model/NewModels/WorkType';
import { ValidateResponse } from '../Validation/Validator';
import moment from 'moment';
import { WorkDayBar } from './WorkDayBar/WorkDayBar';
import { WorkDay } from '../Workaround/TimeApiWorkaround';
import { TimeEntry } from '../api/generated/data-contracts';
// import { getCleanDate } from '../Mobile/MobileContext';

interface propsTimePeriod {
    times: TimeRow[];
    onCellUpdated: Function;
    mode: TimeGridMode;
    pinTime: Function;
    deleteTime: Function;
    today: Date;
    submitted: boolean;
    reportPeriodId: string;
    search: number;
    periodIsClosed: boolean;
    startDate: Date;
    endDate: Date;
    deadlineDate: Date;
    timesheetstate: TimeState;
    timesheetmessage: string;
    updateWorkType: (row: TimeRow, workType: WorkType, changeCompleted: Function) => void;
    validationErrors: ValidateResponse[];
    copyFromLastWeek: () => void;
    workDays: WorkDay[];
}

function TimePeriod(props: propsTimePeriod) {
    const uiCtx = useUiContext();

    const [splitBarMouseDown, setSplitBarMouseDown] = useState<boolean>(false);
    const [splitBarStartPos, setSplitBarStartPos] = useState({ mouseStart: 0, barLastPos: 0 });
    const [timerowgroups, setTimerowsgroups] = useState<TimeRowGroup[]>([]);
    const [expanded, setExpanded] = useState<boolean>(true);
    const [groupiconrotation, setGroupiconrotation] = useState<string>('rotate(0deg)');
    const [timeLowerLimitConfig, setTimeLowerLimitConfig] = useState<string>('');
    const [splitterbarposition, setsplitterbarposition] = useState<number>(400);

    const submittedOrPeriodClosed = props.submitted ? true : props.periodIsClosed ? true : false;

    const [totalarray, setTotalarray] = useState<any>(null);

    const [searchActivate, setSearchActivate] = React.useState<number>(0);

    const [perioddays, setPerioddays] = useState<any[]>([]);
    const [dateformat, setdateformat] = useState<string>();
    const [headersNameOrDate, setheadersNameOrDate] = useState<number>(0);
    const [loadingcomplete, setLoadingcomplete] = useState<boolean>(true);

    const deadlineNameText = uiCtx.allConfig.find(_ => _.key.toLowerCase() === 'perioddeadlinealternatetext')?.value?.toString() ?? 'Deadline';
    const workDayBarActive =
        uiCtx.allConfig
            .find(_ => _.key.toLowerCase() === 'workdayboundaries')
            ?.value?.toString()
            .toLowerCase() === 'true';

    useEffect(() => {
        const dformat = uiCtx.allConfig.find(_ => _.key.toLowerCase() === 'shortdateformat');
        console.log('dformat', dformat);
        if (dformat !== undefined && dformat != null && dformat.value.length >= 2) {
            setdateformat(dformat.value);
        } else {
            setdateformat('DD MMM');
        }
    }, []);

    useEffect(() => {
        if (perioddays.length === 0) {
            return;
        }
        const sortedTasks: TimeRow[] = SortTimeRows(props.times);
        let timeTaskRowsCount = 0;
        const groups: TimeRowGroup[] = [];
        let group: TimeRowGroup = null;

        for (const element of sortedTasks) {
            const groupExists = groups.find(_ => _.GroupId === element.projectId);

            if (!groupExists) {
                const showProjectName = element.projectName + `${element.projectNumber ? ` (${element.projectNumber})` : ''}`;

                group = new TimeRowGroup(element.projectId, showProjectName, element.projectSource, [], true);
                groups.push(group);
                group.TimeRows.push(element);
            } else {
                const existingGroup = groups.find(_ => _.GroupId === element.projectId);
                existingGroup.TimeRows.push(element);
            }
            timeTaskRowsCount++;
        }

        setTimerowsgroups(prevState => {
            if (prevState.length > 0) {
                const previousGroups = prevState.filter(group => groups.some(ps => ps.GroupId === group.GroupId));
                const newGroups = groups.map(newGroup => {
                    const existingGroup = previousGroups.find(previousGroup => previousGroup.GroupId === newGroup.GroupId);
                    if (!existingGroup) {
                        return newGroup;
                    }
                    if (newGroup.TimeRows.length !== existingGroup.TimeRows.length) {
                        return { ...newGroup, Expanded: true };
                    }
                    return { ...newGroup, Expanded: existingGroup.Expanded };
                });

                return newGroups;
            } else {
                // Here you can set the max rows before starting as collapsed
                if (groups.length + timeTaskRowsCount > 20) {
                    setExpanded(false);
                }
                return groups;
            }
        });

        const mtotalarray = {};

        for (let pi = 0; pi < perioddays.length; pi++) {
            const pdate: number = perioddays[pi].id;
            const oldval = mtotalarray[pdate] | 0;
            mtotalarray[pdate] = oldval + GetTotalFromTimeRowInDayArray(sortedTasks, perioddays[pi].date);
        }
        setTotalarray(mtotalarray);
    }, [props.times, perioddays]);

    useEffect(() => {
        const periodDaysArr: PeriodDay[] = [];
        const currentday: Date = new Date(props.startDate);
        let index: number = 0;
        while (currentday <= props.endDate) {
            const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            const dayName = days[currentday.getDay()];
            periodDaysArr.push({ id: index, date: new Date(currentday), name: dayName, day: EDays[currentday.getDay()] });
            index++;
            currentday.setDate(currentday.getDate() + 1);
        }
        setPerioddays(periodDaysArr);
    }, [props.startDate, props.endDate]);

    useEffect(() => {
        if (props.search > 0) {
            setSearchActivate(props.search);
            setExpanded(false);
        }
    }, [props.search]);

    useEffect(() => {
        uiCtx.timeApi.getConfigByKey('TimeLowerLimitConfig').then(result => {
            if (result != null) {
                setTimeLowerLimitConfig(result.value);
            }
        });
        // console.log(uiCtx.user);
        setsplitterbarposition(parseInt(uiCtx.splitBarPos));
    }, []);

    useEffect(() => {
        setTimerowsgroups(groups => {
            return groups.map(g => ({ ...g, Expanded: expanded }));
        });
    }, [expanded]);

    const onCellUpdated = (
        time: TimeRow,
        day: PeriodDay,
        te: TimeEntry,
        value: number,
        comment: string,
        flagged: boolean,
        previousValue: number,
        saveSuccess: Function,
    ): void => {
        props.onCellUpdated(time, day, te, value, comment, flagged, props.mode, previousValue, saveSuccess);
        // let diffValue = value - previousValue;
        // let newValue = totalarray[day.id] + diffValue;
        // setTotalarray((ta) => {
        //     return { ...ta, [day.id]: newValue };
        // });
    };

    const splitbarMouseDownEvent = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        console.debug('splitbarMouseDownEvent');
        event.preventDefault();
        if (!splitBarMouseDown) {
            setSplitBarMouseDown(true);
            setSplitBarStartPos({ mouseStart: event.pageX, barLastPos: uiCtx.splitBarPos });
            setsplitterbarposition(parseInt(uiCtx.splitBarPos));
        }
    };

    const splitbarGridMouseUpEvent = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setSplitBarMouseDown(false);
        console.debug('splitbarGridMouseMoveEvent');
        uiCtx.setSplitBarPos(splitterbarposition);
    };

    const splitbarGridMouseMoveEvent = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (splitBarMouseDown) {
            console.debug('splitbarGridMouseMoveEvent');
            const xmove = splitBarStartPos.barLastPos - (splitBarStartPos.mouseStart - event.pageX);
            if (xmove > 75 && xmove < 2000 && xmove !== splitterbarposition && xmove % 2 === 0) {
                setsplitterbarposition(xmove);
            }
        }
    };

    const groupGollapsedExpanded = (ev: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>): void => {
        if (expanded) {
            setGroupiconrotation('rotate(-90deg)');
        } else {
            setGroupiconrotation('rotate(0deg)');
        }
        setExpanded(e => !e);
    };

    const PinMyTime = (time: TimeRow, pinned: boolean) => {
        props.pinTime(time, pinned, props.mode);
    };

    const DeleteMyTime = (time: TimeRow) => {
        // let newTimeRowGroups = timerowgroups.map(tRG => {
        //     let tRGUpdatedTimeRows = tRG.TimeRows.filter(tr => tr != time);
        //     let nTRG = tRG;
        //     nTRG.TimeRows = tRGUpdatedTimeRows;
        //     return nTRG;
        // });
        // setTimerowsgroups(newTimeRowGroups);
        props.deleteTime(time, perioddays);
    };

    const RenderDayTotalWithAvailabilityScore = (numberTotal: number, periodDateToRender: Date, isWeeked: boolean) => {
        const uAvailability = uiCtx.user?.user.availability;
        if (numberTotal === undefined || numberTotal == null) {
            return <></>;
        }
        const numberTotalFormatted = NumberMax2DecimalFormatAsString(numberTotal);

        const actualDatePeriodToRenderDate = new Date(periodDateToRender);

        let ispast = true;

        try {
            ispast = IsFirstDateLessEqualThanSecondDate(actualDatePeriodToRenderDate, props.today);
        } catch (error) {
            console.log('Date comparison failed, will show availability score on all dates', error, actualDatePeriodToRenderDate, props.today);
            ispast = false;
        }

        // debugger;
        if (!ispast || uAvailability === null || uAvailability === undefined || uAvailability === 0 || !uiCtx.timesheetRAGsEnabled) {
            return (
                <Text className="totalCellInner" as="div">
                    {numberTotalFormatted}
                </Text>
            );
        }

        let iconClass = 'availabilityStatusIcon';
        if (numberTotal >= uAvailability * 0.6 && numberTotal <= uAvailability * 1.4) {
            iconClass += 'Green';
        } else if (numberTotal >= uAvailability * 0.4 && numberTotal <= uAvailability * 1.6) {
            iconClass += 'Amber';
        } else {
            iconClass += 'Red';
        }

        return (
            <Text
                className="totalCellInner"
                as="div"
                styles={{
                    root: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
                }}
            >
                <Text
                    styles={{
                        root: { position: 'relative', fontWeight: 'inherit' },
                    }}
                >
                    {!isWeeked && (
                        <TooltipHost
                            content={
                                <div style={{ textAlign: 'left' }}>
                                    <div>Your availability is {uAvailability} hours per day.</div>
                                    <div>You have timesheeted {numberTotalFormatted} hours on this day.</div>
                                    <br />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td width={45}>Red</td>
                                                <td>
                                                    Below {MathToFixed(uAvailability * 0.4 - 0.1, 1)} or above {MathToFixed(uAvailability * 1.6 + 0.1, 1)} hours
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Amber</td>
                                                <td>
                                                    Between {MathToFixed(uAvailability * 0.4, 1)} - {MathToFixed(uAvailability * 0.6 - 0.1, 1)} or between{' '}
                                                    {MathToFixed(uAvailability * 1.4 + 0.1, 1)} - {MathToFixed(uAvailability * 1.6, 1)} hours
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Green</td>
                                                <td>
                                                    Between {MathToFixed(uAvailability * 0.6, 1)} - {MathToFixed(uAvailability * 1.4, 1)} hours
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            }
                            closeDelay={250}
                            styles={{
                                root: { position: 'absolute', right: '100%' },
                            }}
                        >
                            <FontIcon className={iconClass} iconName="StatusCircleOuter" />
                        </TooltipHost>
                    )}
                    {numberTotalFormatted}
                </Text>
            </Text>
        );
    };

    // const RenderPlannedActive = () => {
    //     return (
    //         <Checkbox label="Planned" checked={uiCtx.plannedActive} onChange={() => {uiCtx.setPlannedActive(!uiCtx.plannedActive)}} />
    //     );
    // }

    /*if (!loadingcomplete)
        return <QuotesLoader />;*/
    return (
        <div className="timeperiod" onMouseUp={splitbarGridMouseUpEvent} onMouseMove={splitbarGridMouseMoveEvent}>
            <ScrollablePane>
                <div className="tableWrapper">
                    {/* <RenderStatuses /> */}
                    <table>
                        <thead>
                            {workDayBarActive && (
                                <WorkDayBar
                                    reportingPeriodId={props.reportPeriodId}
                                    workDays={props.workDays}
                                    periodDays={perioddays}
                                    deadlineDate={props.deadlineDate}
                                    today={props.today}
                                    startDate={props.startDate}
                                />
                            )}
                            <tr className={`noselect topHeadRow${props.today.getTime() === props.startDate.getTime() ? ' isMonday' : ''}`}>
                                <th className="headerContainer noselect" style={uiCtx.workTypeEnabled ? {} : {}}>
                                    <Stack horizontal className="headerCell" styles={{ root: { flexWrap: 'nowrap' } }}>
                                        <div className="expander" onClick={groupGollapsedExpanded}>
                                            <div className="expanderInner">
                                                <FontIcon
                                                    iconName="ChevronDown"
                                                    style={{
                                                        transform: groupiconrotation,
                                                        transition: 'transform .2s ease 0s',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <Text className="groupName textOverflow">All Tasks</Text>
                                        <IconButton
                                            id={`tft-utils`}
                                            menuIconProps={{ iconName: 'More' }}
                                            menuProps={
                                                uiCtx.showPlanned
                                                    ? {
                                                          items: [
                                                              {
                                                                  key: 'copyFromLastWeek',
                                                                  text: 'Copy from last week',
                                                                  iconProps: { iconName: 'Copy' },
                                                                  disabled: submittedOrPeriodClosed,
                                                                  onClick: () => props.copyFromLastWeek(),
                                                              },
                                                              {
                                                                  key: 'plannedActive',
                                                                  text: 'Planned',
                                                                  iconProps: { iconName: uiCtx.plannedActive ? 'CheckboxCompositeReversed' : 'Checkbox' },
                                                                  onClick: () => uiCtx.setPlannedActive(!uiCtx.plannedActive),
                                                              },
                                                              {
                                                                  key: 'actualActive',
                                                                  text: 'Actual',
                                                                  iconProps: { iconName: uiCtx.actualActive ? 'CheckboxCompositeReversed' : 'Checkbox' },
                                                                  onClick: () => uiCtx.setActualActive(!uiCtx.actualActive),
                                                              },
                                                              {
                                                                  key: 'remainingActive',
                                                                  text: 'Remaining',
                                                                  iconProps: { iconName: uiCtx.remainingActive ? 'CheckboxCompositeReversed' : 'Checkbox' },
                                                                  onClick: () => uiCtx.setRemainingActive(!uiCtx.remainingActive),
                                                              },
                                                          ],

                                                          // By default, the menu will be focused when it opens. Uncomment the next line to prevent this.
                                                          // shouldFocusOnMount: false
                                                      }
                                                    : {
                                                          items: [
                                                              {
                                                                  key: 'copyFromLastWeek',
                                                                  text: 'Copy from last week',
                                                                  iconProps: { iconName: 'Copy' },
                                                                  disabled: submittedOrPeriodClosed,
                                                                  onClick: () => props.copyFromLastWeek(),
                                                              },
                                                          ],

                                                          // By default, the menu will be focused when it opens. Uncomment the next line to prevent this.
                                                          // shouldFocusOnMount: false
                                                      }
                                            }
                                        ></IconButton>
                                        {uiCtx.workTypeEnabled && (
                                            <div className="adjustwidthsplitter" style={{ position: 'absolute', right: 0, height: '100%' }}>
                                                <div onMouseDown={splitbarMouseDownEvent}>
                                                    <div className="splitbar"></div>
                                                </div>
                                            </div>
                                        )}
                                    </Stack>
                                </th>
                                {/* {uiCtx.workTypeEnabled && ( */}
                                <th hidden={!uiCtx.workTypeEnabled} className="headerContainer noselect">
                                    <Stack
                                        horizontal
                                        className="headerCell"
                                        verticalAlign="center"
                                        horizontalAlign="center"
                                        styles={{ root: { flexWrap: 'nowrap', height: '100%', position: 'relative' } }}
                                    >
                                        <Text as="div" className="headerCell workType" style={{ flexGrow: 1 }}>
                                            Work Type
                                        </Text>
                                        <div className="adjustwidthsplitter" style={{ position: 'absolute', right: 0, height: '100%' }}>
                                            <div onMouseDown={splitbarMouseDownEvent}>
                                                <div className="splitbar"></div>
                                            </div>
                                        </div>
                                    </Stack>
                                </th>
                                {/* )} */}
                                {uiCtx.plannedActive && uiCtx.showPlanned && (
                                    <th className="headerContainer noselect">
                                        <Stack
                                            horizontal
                                            className="headerCell"
                                            verticalAlign="center"
                                            horizontalAlign="center"
                                            styles={{ root: { flexWrap: 'nowrap', height: '100%', position: 'relative' } }}
                                        >
                                            <Text as="div" className="headerCell planned" style={{ flexGrow: 1 }}>
                                                Planned
                                            </Text>
                                        </Stack>
                                    </th>
                                )}
                                {uiCtx.actualActive && uiCtx.showPlanned && (
                                    <th className="headerContainer noselect">
                                        <Stack
                                            horizontal
                                            className="headerCell"
                                            verticalAlign="center"
                                            horizontalAlign="center"
                                            styles={{ root: { flexWrap: 'nowrap', height: '100%', position: 'relative' } }}
                                        >
                                            <Text as="div" className="headerCell actual" style={{ flexGrow: 1 }}>
                                                Actual
                                            </Text>
                                        </Stack>
                                    </th>
                                )}
                                {uiCtx.remainingActive && uiCtx.showPlanned && (
                                    <th className="headerContainer noselect">
                                        <Stack
                                            horizontal
                                            className="headerCell"
                                            verticalAlign="center"
                                            horizontalAlign="center"
                                            styles={{ root: { flexWrap: 'nowrap', height: '100%', position: 'relative' } }}
                                        >
                                            <Text as="div" className="headerCell remaining" style={{ flexGrow: 1 }}>
                                                Remaining
                                            </Text>
                                        </Stack>
                                    </th>
                                )}
                                {/* <th className="columnSpacer"><div /></th> */}
                                {perioddays.map((date: any, index) => (
                                    <th
                                        key={date.date}
                                        className={`headerContainer noselect${IsWeekend(date.date) ? ' weekend' : ''}${
                                            IsDeadline(date.date, props.deadlineDate) ? ' deadline' : ''
                                        }${IsHoliday(date.date, uiCtx.user.user.calendar) ? ' holiday' : ''}${
                                            IsDatesNoTimeEqual(props.today, date.date) ? ' today' : ''
                                        }`}
                                    >
                                        {IsDeadline(date.date, props.deadlineDate) ? (
                                            IsHoliday(date.date, uiCtx.user.user.calendar) ? (
                                                <TooltipHost
                                                    directionalHint={DirectionalHint.topCenter}
                                                    content={(GetHolidayName(date.date, uiCtx.user.user.calendar)?.name ?? date.name) + ` (${date.name})`}
                                                    closeDelay={400}
                                                >
                                                    <Text
                                                        as="div"
                                                        className="headerCell day"
                                                        onClick={() => {
                                                            setheadersNameOrDate(headersNameOrDate === 0 ? 1 : 0);
                                                        }}
                                                    >
                                                        {headersNameOrDate === 0 ? deadlineNameText : moment(new Date(date.date)).format(dateformat)}
                                                    </Text>
                                                </TooltipHost>
                                            ) : (
                                                <TooltipHost directionalHint={DirectionalHint.topCenter} content={date.name} closeDelay={400}>
                                                    <Text
                                                        as="div"
                                                        className="headerCell day"
                                                        onClick={() => {
                                                            setheadersNameOrDate(headersNameOrDate === 0 ? 1 : 0);
                                                        }}
                                                    >
                                                        {headersNameOrDate === 0 ? deadlineNameText : moment(new Date(date.date)).format(dateformat)}
                                                    </Text>
                                                </TooltipHost>
                                            )
                                        ) : IsHoliday(date.date, uiCtx.user.user.calendar) ? (
                                            <TooltipHost directionalHint={DirectionalHint.topCenter} content={date.name} closeDelay={400}>
                                                <Text
                                                    as="div"
                                                    className="headerCell day"
                                                    onClick={() => {
                                                        setheadersNameOrDate(headersNameOrDate === 0 ? 1 : 0);
                                                    }}
                                                >
                                                    {headersNameOrDate === 0
                                                        ? GetHolidayName(date.date, uiCtx.user.user.calendar)?.name ?? date.name
                                                        : moment(new Date(date.date)).format(dateformat)}
                                                </Text>
                                            </TooltipHost>
                                        ) : (
                                            <Text
                                                as="div"
                                                className="headerCell day"
                                                onClick={() => {
                                                    setheadersNameOrDate(headersNameOrDate === 0 ? 1 : 0);
                                                }}
                                            >
                                                {headersNameOrDate === 0 ? date.name : moment(new Date(date.date)).format(dateformat)}
                                            </Text>
                                        )}
                                    </th>
                                ))}
                                <th className="headerContainer rowTotal noselect">
                                    <Text as="div" className="headerCell rowTotal">
                                        Period Total
                                    </Text>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {timerowgroups.map((group: TimeRowGroup, index: number) => {
                                return (
                                    <Fragment key={group.GroupId}>
                                        <TimePeriodGroup
                                            group={group}
                                            today={props.today}
                                            expanded={group.Expanded}
                                            onCellUpdated={onCellUpdated}
                                            mode={props.mode}
                                            pinMyTime={PinMyTime}
                                            deleteMyTime={DeleteMyTime}
                                            submitted={props.submitted}
                                            periodIsClosed={props.periodIsClosed}
                                            timeLowerLimitConfig={timeLowerLimitConfig}
                                            startDate={props.startDate}
                                            endDate={props.endDate}
                                            deadlineDate={props.deadlineDate}
                                            perioddays={perioddays}
                                            reportPeriodId={props.reportPeriodId}
                                            updateWorkType={props.updateWorkType}
                                        />
                                        <tr className="rowSpacer" />
                                        {/* {index !== timerowgroups.length - 1 && <tr className="rowSpacer" />} */}
                                    </Fragment>
                                );
                            })}
                        </tbody>
                        <tfoot>
                            <tr className={`noselect totalRow${props.today === props.startDate ? ' isMonday' : ''}`}>
                                <td
                                    className="rowHeader noselect"
                                    style={
                                        uiCtx.workTypeEnabled
                                            ? { width: splitterbarposition }
                                            : { width: splitterbarposition > 800 ? splitterbarposition - 400 : splitterbarposition / 2 }
                                    }
                                >
                                    <Stack horizontal className="rowHeaderContainer">
                                        <div className="expander" />
                                        <Text className="groupName">Total</Text>
                                    </Stack>
                                </td>
                                <td
                                    hidden={!uiCtx.workTypeEnabled}
                                    className="cellContainer worktypeContainer noselect"
                                    style={
                                        uiCtx.workTypeEnabled
                                            ? { width: splitterbarposition }
                                            : { width: splitterbarposition > 800 ? 400 : splitterbarposition / 2 }
                                    }
                                >
                                    <Stack horizontal className="cell">
                                        <div className="expander" />
                                        <Text className="groupName">{/* Empty for work type */}</Text>
                                    </Stack>
                                </td>
                                {uiCtx.plannedActive && uiCtx.showPlanned && (
                                    <td className="cellContainer worktypeContainer noselect">
                                        <Stack horizontal className="cell">
                                            <div className="expander" />
                                            <Text className="groupName">{/* Empty for Planned */}</Text>
                                        </Stack>
                                    </td>
                                )}
                                {uiCtx.actualActive && uiCtx.showPlanned && (
                                    <td className="cellContainer worktypeContainer noselect">
                                        <Stack horizontal className="cell">
                                            <div className="expander" />
                                            <Text className="groupName">{/* Empty for Actual */}</Text>
                                        </Stack>
                                    </td>
                                )}
                                {uiCtx.remainingActive && uiCtx.showPlanned && (
                                    <td className="cellContainer worktypeContainer noselect">
                                        <Stack horizontal className="cell">
                                            <div className="expander" />
                                            <Text className="groupName">{/* Empty for Remaining */}</Text>
                                        </Stack>
                                    </td>
                                )}

                                {totalarray &&
                                    perioddays.map((periodday, index) => {
                                        return (
                                            <td
                                                key={periodday.id}
                                                className={`noselect${IsWeekend(periodday.date) ? ' weekend' : ''}${
                                                    IsDatesNoTimeEqual(props.today, periodday.date) ? ' today' : ''
                                                }`}
                                            >
                                                <div className="totalCell">
                                                    {RenderDayTotalWithAvailabilityScore(totalarray[periodday.id], periodday.date, IsWeekend(periodday.date))}
                                                </div>
                                            </td>
                                        );
                                    })}
                                {totalarray && (
                                    <td className="rowTotal periodTotal noselect">
                                        <div className="totalCell">
                                            <Text as="div" className="totalCellInner">
                                                {FormatValue(Number(Object.values(totalarray).reduce((acc: number, cur: number) => acc + cur, 0)))}
                                            </Text>
                                        </div>
                                    </td>
                                )}
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </ScrollablePane>
        </div>
    );
}

export default TimePeriod;
