import { Dropdown, IDropdownOption, IRenderFunction, Icon, Stack, Text } from '@fluentui/react';
import { memo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PROJECT_APPROVALS } from '../../constants/queryKeys';
import { ApiCalls } from '../../api/api';
import { ApprovalGrid } from './components/Grid/ApprovalGrid';
import addDate from 'date-fns/add';
import subDate from 'date-fns/sub';
import { GridLayoutContextProvider } from './components/Grid/context/LayoutProvider';
import { CUSTOM_RATE_ID } from './components/Grid/CONSTANTS';

export const ProjectApproval = () => {
    return <ProjectApprovalInner />;
};

const today = new Date();
today.setHours(0, 0, 0, 1); // 1. ms of 1. min of 1. hour of today

const getMonthOptions = (
    localeName: string = navigator.language,
    monthFormat: 'long' | 'numeric' | '2-digit' | 'short' | 'narrow' = 'long',
): IDropdownOption<Date>[] => {
    const format = new Intl.DateTimeFormat(localeName, { month: monthFormat }).format;
    const startDate = subDate(today, { months: 6 });
    return Array.from({ length: 7 }, (_, i) => {
        const date = addDate(startDate, { months: i + 1 });
        const month = format(date);
        const year = date.getFullYear();
        return {
            key: date.getTime(),
            text: `${year} ${month}`,
            data: date,
        };
    });
};

const monthOptions = getMonthOptions();
const todayOption = monthOptions.find(opt => opt.data.getFullYear() === today.getFullYear() && opt.data.getMonth() === today.getMonth());

export const ProjectApprovalInner = memo(function ProjectApprovalInner() {
    const [year, setYear] = useState(() => today.getFullYear());
    const [month, setMonth] = useState(() => today.getMonth());
    const [selectedKey, setSelectedKey] = useState<string | number>(todayOption.key);
    const { data } = useQuery({
        queryKey: [PROJECT_APPROVALS, year, month],
        queryFn: () =>
            ApiCalls.approvalProjectapprovalGetProjectManagerApprovalsForMonthList({ month: month + 1, year }).then(({ data: { activities, approvals } }) => ({
                approvals,
                activities: activities.map(activity => {
                    if (activity.id === CUSTOM_RATE_ID) {
                        return activity;
                    }
                    return {
                        ...activity,
                        name: `${activity.name} (${activity.rate})`,
                    };
                }),
            })),
    });

    return (
        <Stack styles={{ root: { flexGrow: 1, height: '100%', padding: '30px 50px' } }}>
            <Dropdown
                options={monthOptions}
                onChange={(e, opt: IDropdownOption<Date>) => {
                    if (opt) {
                        setMonth(opt.data.getMonth());
                        setYear(opt.data.getFullYear());
                        setSelectedKey(opt.key);
                    }
                }}
                selectedKey={selectedKey}
                styles={{ root: { width: 180 } }}
                onRenderTitle={onRenderTitle}
            />
            <GridLayoutContextProvider>{data ? <ApprovalGrid data={data} /> : <div>Loading..</div>}</GridLayoutContextProvider>
        </Stack>
    );
});

const onRenderTitle: IRenderFunction<IDropdownOption<any>[]> = ([props]) => {
    return (
        <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
            <Text>{props.text}</Text>
            <Icon iconName="Calendar" />
        </Stack>
    );
};
