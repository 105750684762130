

export enum UpgradeCycle {
    Production = 'Production',
    ReleaseCandidate = 'ReleaseCandidate',
    Beta = 'Beta',
    Alpha = 'Alpha',
}

export enum UpgradeCycleNumeric {
    Production = 0,
    ReleaseCandidate = 1,
    Beta = 2,
    Alpha = 3,
}