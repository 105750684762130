import { TextField } from "@fluentui/react"
import React from "react"

export interface ValidationConfigProps {
    setConfig: (name, value) => void
    allConfig: any[]
}


export const MinPercentPerDayConfigValues = {
    MinPercent: "MinPercentPerDay_MinPercent"
}

export const MinPercentPerDayConfig = (props: ValidationConfigProps) => {
    
    const configKey = MinPercentPerDayConfigValues.MinPercent;

    const onChange = (value) => {        
        props.setConfig(configKey, value);
    }

    if (!props.allConfig) return;


    return <div>
        <TextField onBlur={(e) => onChange(e.target.value)} label="Min. % per day" defaultValue={props.allConfig.find(_ => _.key == configKey)?.value || `5`}></TextField>
    </div>
}

