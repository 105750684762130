import React, { Fragment, useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Stack, Spinner, Separator, IStackStyles } from '@fluentui/react';
import { useUiContext } from '../components/Contexts/UiContext';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            //fontWeight: theme.typography.fontWeightRegular,
        },
    }),
);

const loadingStackStyle: IStackStyles = { root: { height: '600px' } };

const spinnerStyles = {
    circle: {
        height: 75,
        width: 75,
        borderWidth: 5,
    },
    label: {
        'font-size': 16,
    },
};

function HelpFAQ() {
    const classes = useStyles();

    const [loadingcomplete, setLoadingcomplete] = useState<boolean>(false);
    const [FAQ, setFAQ] = useState<any[]>([]);

    const uiCtx = useUiContext();

    useEffect(() => {
        uiCtx.timeApi.getFAQ().then(json => {
            const allFAQ: any[] = [];
            let anyfaqsfound: boolean = false;
            json.map((faq: any, index: number) => {
                allFAQ.push({ question: faq.question, response: faq.response, order: faq.order });
                anyfaqsfound = true;
            });
            if (!anyfaqsfound) {
                allFAQ.push({
                    question: 'Where do i find projects I am not assigned to',
                    response:
                        'In the top left corner of the application there is a magnifying glass. Click this and type in the name of the project or task you want to time register on.',
                    order: 1,
                });
                allFAQ.push({
                    question: 'Why is all tasks and cells read only',
                    response:
                        'If you have submitted your timesheet all cells becomes read only. If the period is not closed you can still open your timesheet by clicking on the Open icon in the top right corner',
                    order: 1,
                });
            }
            const sortedFAQ: any[] = (allFAQ as any[]).sort((a: any, b: any) => {
                return a.order < b.order ? -1 : 1;
            });
            setFAQ(sortedFAQ);
            setLoadingcomplete(true);
        });
    }, []);

    return (
        <>
            {!loadingcomplete ? (
                <Stack verticalAlign="center" horizontalAlign="center" styles={loadingStackStyle}>
                    <Spinner styles={spinnerStyles} label="Getting help..." />
                </Stack>
            ) : (
                <div style={{ width: '1248px', margin: 'auto', height: 'calc(100vh - 100px)', overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
                    <br />
                    <h2>Frequently Asked Questions</h2>
                    <Separator styles={{ root: { width: '100%' } }} />
                    <Stack verticalAlign="start" horizontalAlign="center" grow styles={{ root: { overflowY: 'auto', overflowX: 'hidden' } }}>
                        {FAQ.map((faq, index) => {
                            return (
                                <Fragment key={index}>
                                    <Accordion style={{ minWidth: '1248px' }}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                            <Typography className={classes.heading}>{faq.question}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div style={{ textAlign: 'left' }}>
                                                <ReactMarkdown linkTarget="_blank">{faq.response}</ReactMarkdown>
                                                <Typography>
                                                    {/* <pre dangerouslySetInnerHTML={{__html: faq.response}} style={{ fontFamily: 'inherit', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                                                        
                                                    </pre> */}
                                                </Typography>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </Fragment>
                            );
                        })}
                    </Stack>
                </div>
            )}
        </>
    );
}

export default HelpFAQ;
