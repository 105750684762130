import * as React from 'react';
import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Dropdown, PrimaryButton, Spinner, Stack, Text } from '@fluentui/react';
import type { IButtonStyles, IDropdownOption, IDropdownStyleProps, IDropdownStyles, ITextStyles } from '@fluentui/react';
import useFetch from 'use-http';
import { IRequestStatus, useSendChangeRequest } from './hooks/useChangeRequest';
import { IStyleFunctionOrObject } from '@uifabric/merge-styles';
import { useTheme } from '@fluentui/react';
import { useValidateStrictVersions } from './hooks/useValidateStrictVersions';
import { UpgradeCycle } from './types/types';

const stageOptions = Object.keys(UpgradeCycle).map((name, index) => ({
    key: name,
    text: name,
    index,
}));

export interface IReleaseChangerStyles {
    dropDownStyles?: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles>;
    sendRequestButtonStyles?: IButtonStyles;
    validationErrorStyles?: ITextStyles;
}

export interface ITranlateReleaseChanger {
    selectStageText?: string;
    networkErrorText?: string;
    productionVersionDropdownText?: string;
    stageDropdownText?: string;
    statusText?: string;
    timeLeftText?: string;
    sendRequestButtonText?: string;
    patchInvalidText?: string;
    minorInvalidText?: string;
    majorInvalidText?: string;
}

export interface IReleaseChangerProps {
    /**
     * Gets called with the packages currently chosen. Return false to cancel request.
     * It is recommended to lock the page for user interaction here
     */
    onBeforeRequest?: (controllers: any) => any;

    /**
     * It is recommended to reload the page to take new versions in effect here
     */
    onAfterRequest?: (status: IRequestStatus) => void;

    /**
     * Get status during request to see e.g. time left
     */
    onDuringRequest?: (status: IRequestStatus) => any;

    /**
     * Gets the sendRequest function to execute it externally later
     * e.g after user confirmation.
     *
     * NOTE: Remember that state setters runs a function if provided,
     * so be sure to wrap the sendRequest in a function if kept
     * in state e.g: sendRequest => setState(() => sendRequest),
     * otherwise it will run imediately.
     */
    onConfirm?: (sendRequest: () => void) => void;

    /**
     * Gets all the controller to do sorting, filtering, mapping etc.
     */
    onMapControllers?: (controllers: any[]) => any[];

    /**
     * Gets the date in UTC format.
     */
    dateFormatter?: (date: string) => string;
    translationTexts?: ITranlateReleaseChanger;

    /**
     * Time in seconds to wait for services to reboot.
     * Defaults to 90s
     */
    waitTime?: number;
    stylesForComponents?: IReleaseChangerStyles;
    strictMajorVersions?: boolean;
    strictMinorVersions?: boolean;
    strictPatchVersions?: boolean;
    showStatus?: boolean;
    /**
     * Disable network calls so nothing gets called accidentally.
     * Writes the request to the console.
     */
    developmentMode?: boolean;

    /**
     * Auto select the latest package versions for all controllers
     * when the stage selection changes. Defaults to true
     */
    defaultSelectLatestVersionsInStage?: boolean;
}

export const ReleaseChanger: React.FC<IReleaseChangerProps> = ({
    translationTexts: {
        selectStageText = 'Select release stage',
        networkErrorText = 'Network error',
        productionVersionDropdownText = 'Choose production version',
        stageDropdownText = 'version',
        statusText = 'Status:',
        timeLeftText = 'Time left:',
        sendRequestButtonText = 'Change versions',
        majorInvalidText = 'Please choose matching patch versions.',
        minorInvalidText = 'Please choose matching minor versions.',
        patchInvalidText = 'Please choose matching patch versions.',
    } = {},
    onBeforeRequest = () => {},
    onAfterRequest = () => {},
    onDuringRequest,
    onConfirm,
    onMapControllers,
    dateFormatter,
    waitTime,
    stylesForComponents: { dropDownStyles, sendRequestButtonStyles, validationErrorStyles } = {},
    strictMajorVersions,
    strictMinorVersions,
    strictPatchVersions,
    showStatus = true,
    developmentMode,
    defaultSelectLatestVersionsInStage = true,
}) => {
    const [chosenStage, setChosenStage] = useState<null | IDropdownOption>(null);
    const { data: rawControllersByStage, post: GetUpgradePackages, loading, error } = useFetch('/api/plugin/time/61a357dd-27bc-4d67-a5fb-999684b596e3', { data: [] });
    const [controllerPackagesToRequest, setControllerPackagesToRequest] = useState<any[]>([]);
    const theme = useTheme();

    const validationErrorStyles_ = validationErrorStyles ?? { root: { margin: `${theme.spacing.s1} 0`, color: theme.palette.red } };

    const { majorValid, minorValid, patchValid } = useValidateStrictVersions(controllerPackagesToRequest, strictMajorVersions, strictMinorVersions, strictPatchVersions);

    const isValidRequest = useMemo(() => {
        const isValid = !controllerPackagesToRequest.some((controller: any) => !controller.package);
        return Boolean(controllerPackagesToRequest.length) && isValid && majorValid && minorValid && patchValid;
    }, [controllerPackagesToRequest, majorValid, minorValid, patchValid]);

    const { requestStatus, sendChangeStageRequest } = useSendChangeRequest(waitTime);

    const requestInProgress = requestStatus.status === 'pending' || requestStatus.status === 'rebooting';

    const isProductionStage = chosenStage?.key === UpgradeCycle.Production;

    const hasRunAfterFn = useRef(false);

    const cachedFilterFn = useRef(onMapControllers);

    useEffect(() => {
        cachedFilterFn.current = onMapControllers;
    }, [onMapControllers]);

    const sendRequest = useCallback(
        (confirmed?: boolean): void => {
            if (isValidRequest) {
                if (onConfirm && !confirmed) {
                    // Let parent component do user confirmation and return a function to start the request
                    return onConfirm(() => sendRequest(true));
                }
                const result = onBeforeRequest(controllerPackagesToRequest);
                if (result === false) {
                    return;
                }
                hasRunAfterFn.current = false;
                if (developmentMode) {
                    console.debug('ReleaseChanger development mode');
                    return console.log('This gets sent:', controllerPackagesToRequest);
                }

                sendChangeStageRequest(controllerPackagesToRequest);
            }
        },
        [controllerPackagesToRequest, developmentMode, isValidRequest, onBeforeRequest, onConfirm, sendChangeStageRequest],
    );

    const setPackageInControllerForRequest = (index: number, packages: any[]) => (e: any, selectedPackage: any) => {
        if (isProductionStage) {
            return setControllerPackagesToRequest(
                controllersByStageWithPackages.map((cont: any) => ({
                    controllerName: cont.controllerName,
                    package: { ...cont.packages[selectedPackage.index] },
                    releaseCycle: cont.releaseCycle,
                })),
            );
        }

        setControllerPackagesToRequest(oldCont => {
            const newCont = [...oldCont];
            newCont[index] = {
                ...newCont[index],
                package: { ...packages[selectedPackage.index] },
            };
            return newCont;
        });
    };

    const setStage = (e: any, option?: IDropdownOption) => {
        if (option) {
            setControllerPackagesToRequest([]);
            setChosenStage(option);
        }
    };

    const controllersByStageWithPackages: any[] = useMemo(() => {
        const controllers = cachedFilterFn.current ? cachedFilterFn.current(rawControllersByStage) : rawControllersByStage;
        // Align packages if production stage is chosen
        if (isProductionStage) {
            const lowestPackageCountInController = controllers.reduce((lowest: number, controller: any) => {
                if (controller.packages.length < lowest) {
                    return controller.packages.length;
                }
                return lowest;
            }, Infinity);

            return controllers.map((controller: any) => {
                // Latest first desc.
                const Packages = [...controller.packages].reverse();
                // Setting the length to a lower number cuts off the tail.
                Packages.length = lowestPackageCountInController;
                return {
                    ...controller,
                    Packages,
                };
            });
        }

        return controllers.map((controller: any) => {
            // Latest first desc.
            const packages = [...controller.packages].reverse();
            return {
                ...controller,
                packages,
            };
        });
    }, [isProductionStage, rawControllersByStage]);

    useEffect(() => {
        if (chosenStage) {
            GetUpgradePackages('', chosenStage.key as string);
        }
    }, [GetUpgradePackages, chosenStage]);

    useEffect(() => {
        setControllerPackagesToRequest(
            controllersByStageWithPackages.map((controller: any) => {
                return {
                    controllerName: controller.controllerName,
                    package: defaultSelectLatestVersionsInStage ? controller.packages[0] : null,
                    releaseCycle: controller.releaseCycle,
                };
            }),
        );
    }, [controllersByStageWithPackages, defaultSelectLatestVersionsInStage]);

    useEffect(() => {
        if (requestStatus.status === 'success' || requestStatus.status === 'error') {
            if (onDuringRequest) {
                // Call onDuringRequest one last time to let the
                // timeLeft prop get to 0 for implementations that
                // needs to show elapsed time in different places.
                onDuringRequest(requestStatus);
            }
            if (!hasRunAfterFn.current) {
                onAfterRequest(requestStatus);
                hasRunAfterFn.current = true;
            }
        }
    }, [onAfterRequest, onDuringRequest, requestStatus]);

    useEffect(() => {
        if (requestInProgress && onDuringRequest) {
            onDuringRequest(requestStatus);
        }
    }, [onDuringRequest, requestInProgress, requestStatus]);

    return (
        <>
            <Dropdown label={selectStageText} options={stageOptions} onChange={setStage} disabled={requestInProgress} />
            {loading && (
                <Stack styles={{ root: { padding: `${theme.spacing.m} 0` } }}>
                    <Spinner />
                </Stack>
            )}
            {error && <Text>{networkErrorText}</Text>}
            {chosenStage &&
                !loading &&
                !error &&
                (isProductionStage
                    ? controllersByStageWithPackages[0] && (
                          <React.Fragment>
                              <Dropdown
                                  label={productionVersionDropdownText}
                                  options={controllersByStageWithPackages[0].packages.map((pkg: any, index: number) => {
                                      const modifiedDate = dateFormatter ? dateFormatter(pkg.lastModified) : `(${pkg.lastModified})`;
                                      return {
                                          text: pkg.name.slice(0, pkg.name.lastIndexOf('.')) + ' ' + modifiedDate,
                                          key: pkg.name,
                                          index,
                                      };
                                  })}
                                  selectedKey={controllerPackagesToRequest[0]?.package ? controllerPackagesToRequest[0].package.name : null}
                                  onChange={setPackageInControllerForRequest(0, [])}
                                  disabled={requestInProgress}
                                  styles={dropDownStyles}
                              />
                          </React.Fragment>
                      )
                    : controllersByStageWithPackages.map((controller: any, index) => {
                          return (
                              <React.Fragment key={controller.controllerName}>
                                  <Dropdown
                                      label={`${controller.controllerName} ${chosenStage.text} ${stageDropdownText}`}
                                      options={controller.packages.map((pkg: any, index: number) => {
                                          const modifiedDate = dateFormatter ? dateFormatter(pkg.lastModified) : `(${pkg.lastModified})`;
                                          return {
                                              text: pkg.name + ' ' + modifiedDate,
                                              key: pkg.name,
                                              index,
                                          };
                                      })}
                                      selectedKey={controllerPackagesToRequest[index]?.package ? controllerPackagesToRequest[index].package.name : null}
                                      onChange={setPackageInControllerForRequest(index, controller.packages)}
                                      disabled={requestInProgress}
                                  />
                              </React.Fragment>
                          );
                      }))}
            {requestInProgress && showStatus && (
                <div>
                    <p>
                        {statusText} {requestStatus.status}
                    </p>
                    {requestStatus.status === 'rebooting' && <p>{timeLeftText + ' ' + requestStatus.timeLeft}s</p>}
                </div>
            )}
            {!majorValid && <Text styles={validationErrorStyles_}>{majorInvalidText}</Text>}
            {!minorValid && <Text styles={validationErrorStyles_}>{minorInvalidText}</Text>}
            {!patchValid && <Text styles={validationErrorStyles_}>{patchInvalidText}</Text>}
            <PrimaryButton styles={sendRequestButtonStyles} text={sendRequestButtonText} onClick={() => sendRequest()} disabled={requestInProgress || !isValidRequest} />
        </>
    );
};
