import React from "react";
import { getCleanDate } from "../../MobileContext";
import { useMobile } from "../../useMobile";
import { Stack } from "@fluentui/react";

export const DaySelectorDropDown: React.FC = () => {
    const selectedDate = useMobile(state => state.selectedDate);
    const myTimeView = useMobile(state => state.myTimeView);

    return (
        // the empty onClick is needed because of some weird bug
        // the onClick 'area' on fluent's TextField is way bigger than the actual element
        // i have no idea why, but this empty onClick fixes it
        <Stack horizontalAlign="center" onClick={() => {}}>
            <select
                className="sliderDaySelector" 
                value={selectedDate.toUTCString()}
                onChange={(e) => useMobile.getState().setSelectedDate({ newSelectedDate: getCleanDate(new Date(e.target.value)), setEditTask: true, expandTask: true })}
            >
                {myTimeView.map(day => (
                    <option key={day.dateString} value={day.dayDate.toUTCString()} >
                        { `${day.dayName}  ${day.dateString}` }
                    </option>
                ))} 
            </select>
        </Stack>
    );
};



