import React from 'react';
import { useState, useRef } from 'react';
import { SearchBox } from '@fluentui/react';
import { IProjects } from '../../../mobileTypes';
import { useUiContext } from '../../../../components/Contexts/UiContext';
import TimeSearchRowM from '../../../../Model/TimeSearchRowM';
import { useMobileContext } from '../../../MobileContext';
import TimePeriodRowM from '../../../../Model/TimePeriodRowM';
import { useMobile } from '../../../useMobile';

interface IProps {
    shownProjects: IProjects[];
    setShownProjects: React.Dispatch<React.SetStateAction<IProjects[]>>;
    isDefaultProjects: boolean;
    setIsDefaultProjects: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Search: React.FC<IProps> = props => {
    const mobCtx = useMobileContext();
    const uiCtx = useUiContext();

    const [inputValue, setInputValue] = useState('');

    const searchInProgress = useRef(false);
    const lastSearchQuery = useRef('');
    const parkedSearch = useRef<() => void | undefined>();

    const onSearch = () => {
        if (inputValue === '') {
            if (!props.isDefaultProjects) {
                props.setIsDefaultProjects(true);
            }
            props.setShownProjects(mobCtx.allProjects);
        } else {
            if (props.isDefaultProjects) {
                props.setIsDefaultProjects(false);
            }
            useMobile.getState().setIsloading({ active: true, overlay: true });
            search(inputValue);
        }
    };

    const search = (query: string): void => {
        if (!searchInProgress.current) {
            searchInProgress.current = true;
            lastSearchQuery.current = query;

            // let searchEndpoint = onlyAssignedSearch ? uiCtx.timeApi.searchByQueryAssigned : uiCtx.timeApi.searchByQuery;
            const searchEndpoint = uiCtx.timeApi.searchByQuery;

            searchEndpoint(query).then(json => {
                const searchResultItems: TimePeriodRowM[] = [];
                const localmetadata1values: string[] = [];

                json.map((time: any) => {
                    searchResultItems.push(
                        new TimePeriodRowM(
                            time.key,
                            time.timeId,
                            time.projectId,
                            time.projectName,
                            time.projectNumber,
                            time.projectSource,
                            time.projectClosed,
                            time.taskId,
                            time.taskName,
                            time.taskStart,
                            time.taskEnd,
                            time.taskClosed,
                            time.taskIsDefault,
                            time.taskDescription,
                            time.pinned,
                            '',
                            time.projectOrder,
                            time.taskOrder,
                            time.plannedWork,
                            time.remainingWork,
                            time.actualWork,
                            [],
                            time.workType,
                            time.projectWorkType,
                        ),
                    );
                    if (!localmetadata1values.includes(time.metadata1)) {
                        localmetadata1values.push(time.metadata1);
                    }
                });

                const groupedTasks = groupByProject(searchResultItems);
                console.log('groupedTasks: ', groupedTasks);
                console.log('mobCtx projects: ', props.shownProjects);
                searchInProgress.current = false;
                if (parkedSearch.current) {
                    const parked = parkedSearch.current;
                    parked();
                    parkedSearch.current = undefined;
                } else {
                    props.setShownProjects(groupedTasks);
                    useMobile.getState().setIsloading({ active: false, overlay: false });
                }
            });
        } else {
            if (lastSearchQuery.current != query) {
                parkedSearch.current = () => {
                    search(query);
                };
            }
        }
    };

    return (
        <div>
            <SearchBox
                placeholder="Search..."
                value={inputValue}
                onChange={(e: any, newValue: string) => setInputValue(newValue)}
                onSearch={onSearch}
                // componentRef={searchFieldRef}
                // value={searchInputValue}
                // underlined={true}
                // onSelect={searchFor}
                // onChange={charsTyped}
                // onSearch={onsearch}
                // onFocus={searchFocus}
                // iconProps={filterIcon}
                // styles={searchBoxStyles}
                styles={{
                    root: {
                        ...(uiCtx.isTeams || uiCtx.isDarkmode ? { border: 'none' } : {}),
                        borderRadius: 4,
                    },
                }}
            />
        </div>
    );
};

const groupByProject = (allTasks: TimePeriodRowM[]) => {
    const allProjects = [];

    allTasks.forEach(task => {
        const indexOfProject = allProjects.findIndex(project => project.projectId === task.projectId);

        if (indexOfProject == -1) {
            allProjects.push({
                projectId: task.projectId,
                projectName: task.projectName,
                tasks: [{ ...task }],
            });
        } else {
            allProjects[indexOfProject].tasks.push({ ...task });
        }
    });

    const sortedAllProjects = allProjects
        .map(project => ({
            ...project,
            tasks: [...project.tasks.sort((a: TimePeriodRowM, b: TimePeriodRowM) => (a.taskName > b.taskName ? 1 : -1))],
        }))
        .sort((a: any, b: any) => (a.projectName > b.projectName ? 1 : -1));

    return sortedAllProjects;
};
