import React, { useEffect, useState } from 'react';
import { useUiContext } from '../components/Contexts/UiContext';
import UserProjectHoursByWeekBarChart from './UserProjectHoursByWeekBarChart';
import UserProjectsPerWeekLineChart from './UserProjectsPerWeekLineChart';
import { DirectionalHint, Icon, IIconStyles, Separator, Stack, Text, DatePicker, IDatePicker, DayOfWeek } from '@fluentui/react';
import { PageHeader, PageContent, ContentSection } from '../components/LayoutElements';
import '../Styles/Charts.scss';
import UserTaskHoursSunburst from './UserTaskHoursSunburst';
import DelegateArea from '../components/DelegateArea';
import { QuotesLoader } from '../components/QuotesLoader';
const itemStyles: React.CSSProperties = {
    alignItems: 'center',
    display: 'flex',
    height: 60,
    width: 354,
    justifyContent: 'center',
};
const nowMinusXMonths = (months?: number): Date => {
    let now = new Date();
    if (months == null || months == 0) {
        return now;
    }
    now.setMonth(now.getMonth() - months);
    return now;
};

function Charts() {
    const uiCtx = useUiContext();
    const [barChartData, setBarChartData] = useState<any[]>([]);
    const [projectSelected, setProjectSelected] = useState<string>(null);
    const [fromDateValue, setFromDateValue] = useState<Date>(nowMinusXMonths(3));
    const [toDateValue, setToDateValue] = useState<Date>(nowMinusXMonths());
    const [loading, setLoading] = useState<boolean>(true);

    let projectrSelected = (id: string) => {
        console.log('proejct selected', id);
        setProjectSelected(id);
    };
    useEffect(() => {
        const start: any = fromDateValue.getISOStringMidnight();
        const end: any = toDateValue.getISOStringMidnight();

        uiCtx.timeApi.getUserChartDataByWeek(start, end).then(periodsJson => {
            setBarChartData(periodsJson);
            setLoading(false);
            uiCtx.setLoading(false);
        });
    }, [fromDateValue, toDateValue]);

    if (!uiCtx.nativeChartsEnabled) {
        return (
            <div className="centeredtext">
                <h2>Disabled by Admin</h2>
            </div>
        );
    }

    return (
        <>
            <ContentSection>
                <Stack horizontal horizontalAlign="space-between" styles={{ root: { width: '100%' } }}>
                    <span style={itemStyles}>
                        <DatePicker
                            //componentRef={FromDatePickerRef}
                            allowTextInput
                            //label="From date"
                            firstDayOfWeek={DayOfWeek.Monday}
                            ariaLabel="Select from date" //Might not be needed as it should default to a date
                            placeholder="Select from date" //Might not be needed as it should default to a date
                            value={fromDateValue}
                            onSelectDate={setFromDateValue as (date: Date | null | undefined) => void}
                            styles={{ textField: { width: 150 } }}
                        />

                        <Icon iconName="ChromeMinimize" styles={{ root: { fontSize: '24px', height: '30px', width: '24px', margin: '0px 15px 0px 15px' } }} />

                        <DatePicker
                            //componentRef={ToDatePickerRef}
                            allowTextInput
                            //label="To date"
                            firstDayOfWeek={DayOfWeek.Monday}
                            ariaLabel="Select to date" //Might not be needed as it should default to a date
                            placeholder="Select to date" //Might not be needed as it should default to a date
                            value={toDateValue}
                            onSelectDate={setToDateValue as (date: Date | null | undefined) => void}
                            styles={{ textField: { width: 150 } }}
                        />
                    </span>
                </Stack>
            </ContentSection>

            <PageContent>
                {loading ? (
                    <QuotesLoader loadingtext="Getting data..." />
                ) : (
                    <ContentSection>
                        <Stack
                            tokens={{ childrenGap: 50 }}
                            verticalAlign="baseline"
                            styles={{ root: { width: '100%' } }}
                            onClick={() => {
                                setProjectSelected(null);
                            }}
                        >
                            <Stack.Item>
                                <Stack className="chartsheader">
                                    <Stack.Item align="center">
                                        <Text variant="large" styles={{ root: { display: 'block', paddingBottom: 8 } }}>
                                            Hours per Project
                                        </Text>
                                    </Stack.Item>
                                </Stack>
                                <div style={{ height: 400, marginTop: -20, cursor: 'pointer' }}>
                                    <UserProjectHoursByWeekBarChart BarChartData={barChartData} NewProjectSelected={projectrSelected} ProjectSelected={projectSelected} />
                                </div>
                            </Stack.Item>
                            <Stack.Item>
                                <div style={{ height: 400, marginTop: -20 }}>
                                    <Stack horizontal tokens={{ childrenGap: 50 }} verticalAlign="baseline" styles={{ root: { width: '100%' } }}>
                                        <Stack.Item grow={3}>
                                            <Stack className="chartsheader">
                                                <Stack.Item align="center">
                                                    <Text variant="large" styles={{ root: { display: 'block', paddingBottom: 8 } }}>
                                                        Actuals vs. Planned
                                                    </Text>
                                                </Stack.Item>
                                            </Stack>
                                            <div style={{ height: 400, width: '100%' }}>
                                                <UserProjectsPerWeekLineChart LineChartData={barChartData} ProjectSelected={projectSelected} />
                                            </div>
                                        </Stack.Item>
                                        <Stack.Item grow={1}>
                                            <Stack className="chartsheader">
                                                <Stack.Item align="center">
                                                    <Text variant="large" styles={{ root: { display: 'block', paddingBottom: 8 } }}>
                                                        Projects & Tasks
                                                    </Text>
                                                </Stack.Item>
                                            </Stack>
                                            <div style={{ height: 400, width: '100%', cursor: 'pointer' }}>
                                                <UserTaskHoursSunburst SunburstChartData={barChartData} ProjectSelected={projectSelected} NewProjectSelected={projectrSelected} />
                                            </div>
                                        </Stack.Item>
                                    </Stack>
                                </div>
                            </Stack.Item>
                        </Stack>
                    </ContentSection>
                )}
            </PageContent>
            <DelegateArea />
        </>
    );
}

export default Charts;
