import React, { useEffect, useState, useRef, useContext } from 'react';
import { TextField, Callout, FontWeights, Text, DirectionalHint, DefaultButton, PrimaryButton, TooltipHost } from '@fluentui/react';
import { makeStyles, useTheme } from '@fluentui/react';
import TimeRow from '../Model/TimePeriodRowM';
import { FormatValue, GetNumberSep, Format2DecimalValue, MathToFixed, getEmptyGuid, IsWeekDay } from './Utils';
import { useId } from '@fluentui/react-hooks';
import { constants } from '../Styles/constants';
import PeriodDay from '../Model/PeriodDay';
import { TimeGridMode } from '../Model/TimeGridMode';
import { TimeEntry } from '../api/generated/data-contracts';
import { useUiContext } from './Contexts/UiContext';
import { useCellCtx } from './Contexts/CellContext';

interface propsTotalCell {
    initialValue: number;
    periodDays: PeriodDay[];
    timeRow: TimeRow;
    readonly: boolean;
    updated: (
        time: TimeRow,
        day: PeriodDay,
        te: TimeEntry,
        value: number,
        comment: string,
        flagged: boolean,
        mode: TimeGridMode,
        saveSuccess: Function,
    ) => void;
    timeLowerLimitConfig: string;
}

function TotalCell(props: propsTotalCell) {
    const theme = useTheme();
    const styles = getStyles();
    const textFieldRef = useRef<any>();
    const targetId = useId();

    const uiCtx = useUiContext();
    // const { onCellUpdated } = useCellCtx();

    const [celltext, setCelltext] = useState('');
    const [cellnumber, setCellnumber] = useState<number>(0);

    const [cellIsError, setCellIsError] = useState<string>('');

    const [cellnumberlastsaved, setCellnumberlastsaved] = useState<number>(0);
    const [textinfocus, setTextinfocus] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    const [anyChanges, setAnyChanges] = useState<boolean>(false);
    const [dataSaved, setdatasaved] = useState<boolean>(false);

    const [showTotalChangedCallOut, setShowTotalChangedCallOut] = useState<boolean>(false);
    const [callOutShownCellNumber, setCallOutShownCellNumber] = useState<number>(0);

    useEffect(() => {
        setCelltext(FormatValue(props.initialValue));
        setCellnumber(props.initialValue);
        setCellnumberlastsaved(props.initialValue);

        // Extras to change for totalcell
        setCellIsError('');
        setTextinfocus(false);

        setLoaded(true);
    }, [props.initialValue]);

    useEffect(() => {
        // debugger;
        if (textinfocus === false && loaded === true) {
            if (cellnumber !== cellnumberlastsaved) {
                if (cellIsError === '') {
                    saveData(cellnumber, cellnumberlastsaved);
                    setAnyChanges(false);
                    setTimeout(() => {
                        setdatasaved(true);
                    }, 150);
                    setTimeout(() => {}, 500);
                }
            }
        }
    }, [textinfocus]);

    const onCellUpdatedInternal = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        setAnyChanges(true);

        isValid(GetNumberSep() === ',' ? text.replace(/\./g, ',') : text.replace(/\,/g, '.'));
        setCelltext(GetNumberSep() === ',' ? text.replace(/\./g, ',') : text.replace(/\,/g, '.'));
        setCellnumber(MathToFixed(parseNumber(text.replace(/\,/g, '.')), 2));
    };

    const onBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        if (cellIsError === '') {
            if (cellnumber !== cellnumberlastsaved) {
                setCallOutShownCellNumber(cellnumber);
                setShowTotalChangedCallOut(true);
                return;
            }
        }
        // Set TextInFocus false, as it won't open dialog for change
        setTextinfocus(false);
    };

    const isValid = (value: string): void => {
        const newVal = value.replace(/,/g, '.');

        if (isValidNumber(newVal)) {
            const newValNum = parseFloat(newVal);

            // Max hours on period
            let weekDays = props.periodDays.filter(p => IsWeekDay(p.date));
            if (weekDays.length === 0) {
                weekDays = props.periodDays;
            }
            const periodMaxHours = weekDays.length * 24;

            if (newValNum > periodMaxHours) {
                setCellIsError(`Max ${periodMaxHours} hours`);
                return;
            }
            if (newValNum < 0) {
                setCellIsError('Negative hours not allowed');
                return;
            }
            if (props.timeLowerLimitConfig !== '' && newValNum !== 0 && !isNaN(newValNum)) {
                if (parseFloat(newVal) < parseFloat(props.timeLowerLimitConfig)) {
                    setCellIsError('Must be over ' + Format2DecimalValue(props.timeLowerLimitConfig));
                    return;
                }
            }
            setCellIsError('');
            return;
        } else {
            setCellIsError('Whoops, not a number');
            return;
            // return " ";
        }
    };

    const onFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        setTextinfocus(true);
        if (textFieldRef.current) {
            textFieldRef.current.select();
        }
    };

    const onYesClick = (): void => {
        setCellnumber(callOutShownCellNumber);
        setCelltext(FormatValue(callOutShownCellNumber));

        setTimeout(() => {
            setTextinfocus(false);
        }, 300);

        setShowTotalChangedCallOut(false);
    };

    const onNoClick = (): void => {
        setCellnumber(cellnumberlastsaved);
        setCelltext(FormatValue(cellnumberlastsaved));

        setTimeout(() => {
            setTextinfocus(false);
        }, 300);

        setShowTotalChangedCallOut(false);
    };

    // const isWeekDay = (date: Date) => {
    //     let day = date.getDay();
    //     if (day == 0 || day == 6) return false;
    //     return true;
    // }

    const saveData = (hours: number, previousHours: number): void => {
        const enabledWorkdays = uiCtx.workdays.filter(w => w.enabled).map(w => w.name.toLowerCase());
        // let weekDays = props.periodDays.filter(p => IsWeekDay(p.date));
        let weekDays = props.periodDays.filter(p => enabledWorkdays.indexOf(p.name.toLowerCase()) > -1);
        let nonWeekDays = props.periodDays.filter(p => enabledWorkdays.indexOf(p.name.toLowerCase()) < 0);
        console.log('TotalCellChange should be propagated and saved', hours, previousHours);

        if (weekDays.length === 0) {
            weekDays = props.periodDays;
            nonWeekDays = [];
        }

        const rounded = MathToFixed(Number(cellnumber / weekDays.length), 2);

        //Ugly Assignment not created fix
        if (props.timeRow.timeId === getEmptyGuid()) {
            const [firstDay, ...rest] = weekDays;

            const selectedTimeEntry = props.timeRow.timeEntries?.filter(te => new Date(te.date).getTime() === firstDay.date.getTime())[0];

            props.updated(props.timeRow, firstDay, selectedTimeEntry, rounded, undefined, selectedTimeEntry?.flagged ?? false, previousHours, () => {
                setdatasaved(false);
            });

            setTimeout(() => {
                rest.forEach((periodDay, index) => {
                    const selectedTimeEntry = props.timeRow.timeEntries?.filter(te => new Date(te.date).getTime() === periodDay.date.getTime())[0];

                    props.updated(props.timeRow, periodDay, selectedTimeEntry, rounded, undefined, selectedTimeEntry?.flagged ?? false, 0, () => {
                        //preiousHours replaced by 0 to only minus the first on the Actual Work column
                        setdatasaved(false);
                    });
                });
                nonWeekDays.forEach((periodDay, index) => {
                    const selectedTimeEntry = props.timeRow.timeEntries?.filter(te => new Date(te.date).getTime() === periodDay.date.getTime())[0];

                    props.updated(props.timeRow, periodDay, selectedTimeEntry, 0, undefined, selectedTimeEntry?.flagged ?? false, 0, () => {
                        //preiousHours replaced by 0 to only minus the first on the Actual Work column
                        setdatasaved(false);
                    });
                });
            }, 500);
            return;
        }

        let firstCounter = 0;
        weekDays.forEach(async periodDay => {
            const selectedTimeEntry = props.timeRow.timeEntries?.filter(te => new Date(te.date).getTime() === periodDay.date.getTime())[0];
            props.updated(
                props.timeRow,
                periodDay,
                selectedTimeEntry,
                rounded,
                undefined,
                selectedTimeEntry?.flagged,
                firstCounter === 0 ? previousHours : 0,
                () => {
                    setdatasaved(false);
                },
            );
            firstCounter++;
        });
        nonWeekDays.forEach(async periodDay => {
            const selectedTimeEntry = props.timeRow.timeEntries?.filter(te => new Date(te.date).getTime() === periodDay.date.getTime())[0];
            props.updated(props.timeRow, periodDay, selectedTimeEntry, 0, undefined, selectedTimeEntry?.flagged, firstCounter === 0 ? previousHours : 0, () => {
                setdatasaved(false);
            });
            firstCounter++;
        });
    };

    const onKeyDown = (event: any): void => {
        if (event.keyCode === 13) {
            // Enter key
            if (cellIsError === '') {
                if (cellnumber !== cellnumberlastsaved) {
                    setCallOutShownCellNumber(cellnumber);
                    setShowTotalChangedCallOut(true);
                    return;
                }
            }
            // Set TextInFocus false, as it won't open dialog for change
            setTextinfocus(false);
        }
    };

    return (
        <div className={styles.root}>
            <TooltipHost content={cellIsError} closeDelay={250}>
                <TextField
                    id={targetId}
                    componentRef={textFieldRef}
                    className="cellInput"
                    value={celltext}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onKeyDown={onKeyDown}
                    onChange={onCellUpdatedInternal}
                    // onGetErrorMessage={isValid}
                    disabled={props.readonly}
                    autoComplete="off"
                    style={{ textAlign: 'center' }}
                    // iconProps={{
                    //     iconName: "Comment",
                    //     onClick: onIconClick,
                    // }}
                    styles={{
                        root: {
                            // margin: "0 auto",
                            // maxWidth: constants.cellInputMaxWidth,
                        },
                        fieldGroup: {
                            borderColor: `${cellIsError !== '' ? 'rgba(155,42,49,0.75)' : 'rgba(0,0,0,0)'}`,
                            backgroundColor: `${dataSaved ? 'rgba(16, 124, 16, 0.6)!important' : 'rgba(0,0,0,0)'}`,
                            borderWidth: '2px',
                            '&:hover': {
                                borderColor: `${cellIsError !== '' ? 'rgba(155,42,49,1)!important' : 'rgba(0,0,0,0)'}`,
                                backgroundColor: `${dataSaved ? 'rgba(16, 124, 16, 0.6)' : 'rgba(0,0,0,0)'}`,
                                // backgroundColor: theme.semanticColors.inputBackground,
                                // backgroundColor: workday ? theme.semanticColors.inputBackground : theme.palette.neutralLighterAlt,
                            },
                            '&::after': {
                                borderColor: `${cellIsError !== '' ? 'rgba(155,42,49,1)' : 'rgba(96,97,170,1)'}`,
                            },
                        },
                        field: {
                            textAlign: 'right',
                            paddingRight: theme.spacing.s1,
                            paddingLeft: 7,
                        },
                    }}
                />
            </TooltipHost>
            {showTotalChangedCallOut && (
                <Callout
                    target={`#${targetId}`}
                    className={styles.callout}
                    ariaLabelledBy="{labelId}"
                    ariaDescribedBy="{descriptionId}"
                    role="alertdialog"
                    gapSpace={0}
                    directionalHint={DirectionalHint.leftBottomEdge}
                    // onDismiss={()=>{setShowTotalChangedCallOut(false);}}
                    setInitialFocus
                    dismissOnTargetClick={false}
                >
                    <div className={styles.header}>
                        <Text className={styles.title}>Are you sure you want to spread {callOutShownCellNumber} hours across the workweek days?</Text>
                    </div>
                    <div className={styles.inner} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <PrimaryButton text={'Yes'} onClick={onYesClick} className="calloutYesBtn" />
                        <DefaultButton text={'No'} onClick={onNoClick} className="calloutNoBtn" />
                    </div>
                </Callout>
            )}
        </div>
    );
}

export default TotalCell;

const isValidNumber = (num: string) => {
    if (num === '') {
        return true;
    }
    if (isNaN(parseFloat(num))) {
        return false;
    }
    return true;
};

const parseNumber = (num: string) => {
    if (num === '') {
        return 0;
    }
    if (isNaN(parseFloat(num))) {
        return 0;
    }
    return parseFloat(num);
};

const getStyles = makeStyles(theme => ({
    root: [
        'cellInner',
        {
            position: 'relative',
            height: '100%',
            width: '100%',
            maxWidth: constants.cellInputMaxWidth,
            margin: '0 auto',
            display: 'flex',
        },
    ],
    buttonArea: {
        verticalAlign: 'top',
        display: 'inline-block',
        textAlign: 'center',
        margin: '0 100px',
        minWidth: 130,
        height: 32,
    },
    callout: {
        maxWidth: 800,
        minWidth: 300,
    },
    header: {
        padding: '18px 24px 12px',
        color: theme.semanticColors.bodyText,
    },
    title: [
        theme.fonts.mediumPlus,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
        },
    ],
    inner: {
        height: '100%',
        padding: '0 24px 20px',
    },
    actions: {
        position: 'relative',
        marginTop: 20,
        width: '100%',
        whiteSpace: 'nowrap',
    },
    subtext: [
        theme.fonts.small,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
        },
    ],
    link: [
        theme.fonts.medium,
        {
            color: theme.palette.neutralPrimary,
        },
    ],
}));
