  
// import { createTheme, ICustomizations } from '@fluentui/react';
// import { addVariants } from '@fluentui/scheme-utilities';
import { createTheme, ITheme } from '@fluentui/react';

const palette = {
    themePrimary: '#6061aa',
    themeLighterAlt: '#f7f7fc',
    themeLighter: '#e1e1f2',
    themeLight: '#c7c8e6',
    themeTertiary: '#9797cd',
    themeSecondary: '#6f70b5',
    themeDarkAlt: '#56579a',
    themeDark: '#494a82',
    themeDarker: '#363660',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
};

export const DefaultTheme: ITheme = createTheme({
    palette,
    semanticColors: {
        bodyStandoutBackground: palette.neutralLighterAlt,
        defaultStateBackground: "#fff",
    },
});
// export const DefaultTheme: ITheme = createTheme({
//     palette: {
//         themePrimary: '#6061aa',
//         themeLighterAlt: '#f7f7fc',
//         themeLighter: '#e1e1f2',
//         themeLight: '#c7c8e6',
//         themeTertiary: '#9797cd',
//         themeSecondary: '#6f70b5',
//         themeDarkAlt: '#56579a',
//         themeDark: '#494a82',
//         themeDarker: '#363660',
//         // neutralLighterAlt: '#f8f8f8',
//         // neutralLighter: '#f4f4f4',
//         // neutralLight: '#eaeaea',
//         // neutralQuaternaryAlt: '#dadada',
//         // neutralQuaternary: '#d0d0d0',
//         // neutralTertiaryAlt: '#c8c8c8',
//         // neutralTertiary: '#b6b0b0',
//         // neutralSecondary: '#9f9797',
//         // neutralPrimaryAlt: '#877f7f',
//         // neutralPrimary: '#282424',
//         // neutralDark: '#585151',
//         // black: '#403b3b',
//         // white: '#fff',
//     },
// });