import { useCallback, useMemo } from 'react';
import { DefaultButton, IContextualMenuProps } from '@fluentui/react';

export const SaveAndCloseButton = ({ close, save, disabled }: { save: () => Promise<any>; close: () => void; disabled?: boolean }) => {
    const handleSaveAndClose = useCallback(() => {
        save().then(close);
    }, [close, save]);

    const menuProps = useMemo((): IContextualMenuProps => {
        return {
            items: [
                {
                    key: 'save',
                    text: 'Save',
                    onClick: () => void save(),
                    iconProps: { iconName: 'Save' },
                },
            ],
        };
    }, [save]);

    return <DefaultButton text="Save and close" primary split onClick={handleSaveAndClose} menuProps={menuProps} disabled={disabled} />;
};
