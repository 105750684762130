import React, { useEffect, useState } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Separator } from '@fluentui/react/lib/Separator';
import AdminFAQM from '../../../../../Model/AdminFAQM';
import { TextField } from '@fluentui/react/lib/TextField';
import { IconButton } from '@fluentui/react';

interface propsAdminFAQ {
    FAQ: AdminFAQM;
    ReorderFAQ: Function;
    RemoveFAQ: Function;
    FAQUpdated: Function;
}
function AdminFAQ(props: propsAdminFAQ) {
    const [fAQ, setFAQ] = useState<AdminFAQM>(null);
    const [fAQQuestion, setFAQQuestion] = useState<string>(props.FAQ.question);
    const [fAQResponse, setFAQResponse] = useState<string>(props.FAQ.response);
    const [fAQOrder, setFAQOrder] = useState<number>(props.FAQ.order);

    useEffect(() => {
        setFAQ(props.FAQ);
        setFAQQuestion(props.FAQ.question);
        setFAQResponse(props.FAQ.response);
        setFAQOrder(props.FAQ.order);
    }, [props.FAQ]);

    const moveUpClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement, MouseEvent>) => {
        props.ReorderFAQ(fAQ, -1);
    };
    const moveDownClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement, MouseEvent>) => {
        props.ReorderFAQ(fAQ, 1);
    };
    const removeClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement, MouseEvent>) => {
        props.RemoveFAQ(fAQ, 1);
    };
    const newUpdateFAQ = () => {
        props.FAQUpdated(fAQ.id, fAQQuestion, fAQResponse);
    };
    const fAQQuestionUpdate = (event, value) => {
        setFAQQuestion(value);
        // props.fAQUpdated(fAQ.id, fAQQuestion, fAQEmail, fAQPhone);
    };
    const fAQResponseUpdate = (event, value) => {
        setFAQResponse(value);
        // props.fAQUpdated(fAQ.id, fAQQuestion, fAQEmail, fAQPhone);
    };

    return (
        <div style={{ width: 660 }}>
            <Stack tokens={{ childrenGap: 20 }} horizontal verticalAlign="baseline">
                <Stack.Item grow={1}>
                    <Stack horizontal tokens={{ childrenGap: 0 }} verticalAlign="baseline">
                        <h4 style={{ cursor: 'pointer', textDecoration: 'none' }}>{fAQQuestion.substr(0, 20) + '...'}</h4>
                    </Stack>
                </Stack.Item>
                <Stack.Item grow={5}>
                    <Separator />
                </Stack.Item>
                <Stack.Item grow={1} styles={{ root: { alignItems: 'center', display: 'flex', justifyContent: 'flex-end' } }}>
                    <Stack tokens={{ childrenGap: 0 }} horizontal>
                        <IconButton
                            iconProps={{ iconName: 'SkypeCircleArrow' }}
                            checked={false}
                            style={{ fontSize: 32, color: 'var(--palette-black)', transform: 'rotate(-90deg)' }}
                            onClick={moveDownClick}
                        />
                        <IconButton
                            iconProps={{ iconName: 'SkypeCircleArrow' }}
                            checked={false}
                            style={{ fontSize: 32, color: 'var(--palette-black)', transform: 'rotate(90deg)' }}
                            onClick={moveUpClick}
                        />
                        <IconButton iconProps={{ iconName: 'SkypeCircleMinus' }} checked={false} style={{ fontSize: 32, color: 'var(--palette-black)' }} onClick={removeClick} />
                    </Stack>
                </Stack.Item>
            </Stack>

            <div>
                <Stack tokens={{ childrenGap: 10 }} verticalAlign="start" style={{ margin: 10 }}>
                    <TextField width={300} placeholder={'Question'} value={fAQQuestion} onChange={fAQQuestionUpdate} onBlur={newUpdateFAQ} />
                    <TextField width={300} placeholder={'Answer'} value={fAQResponse} onChange={fAQResponseUpdate} onBlur={newUpdateFAQ} multiline autoAdjustHeight />

                    <br />
                </Stack>
            </div>
        </div>
    );
}

export default AdminFAQ;
