import { useMemo, useCallback, useState } from 'react';
import { Callout, Checkbox, Icon, IconButton, PersonaCoin, PersonaSize, Stack, Text, useTheme } from '@fluentui/react';
import { useRowMetaContext } from '../../../../context/RowMetaContext';
import { getLocaleFormattedDate } from '../../../../../../../../_helpers/getLocaleFormattedDate';
import { PseudoRow, isApprovalRow, useApprovalStore, useGridExpandedRowState } from '../../../../../../../../_grid/grid2';
import shallow from 'zustand/shallow';

export const RowHeader = () => {
    const { nestingLevel, subrowFamily, row } = useRowMetaContext<PseudoRow>();
    const [selectedRows, setSelectedRows] = useApprovalStore(store => [store.selectedRows, store.setSelectedRows] as const, shallow);
    // const [selectedRows, setSelectedRows] = useGridExpandedRowState(row.id);
    const onChange = useCallback(
        (e: any, newValue?: boolean) => {
            const subrowIds = subrowFamily.filter(isApprovalRow).map(row => row.id);
            setSelectedRows(ids => {
                if (newValue) {
                    return new Set([...ids, ...subrowIds]);
                }
                return new Set(
                    [...ids].filter(id => {
                        return subrowIds.indexOf(id) === -1;
                    }),
                );
            });
        },
        [setSelectedRows, subrowFamily],
    );

    const checkState = useMemo((): {
        indeterminate: boolean;
        checked: boolean;
    } => {
        const subrowIds = subrowFamily.filter(isApprovalRow).map(row => row.id);
        if (selectedRows.size && subrowIds.every(id => selectedRows.has(id))) {
            return {
                indeterminate: false,
                checked: true,
            };
        }
        if (selectedRows.size && subrowIds.some(id => selectedRows.has(id))) {
            return {
                indeterminate: true,
                checked: false,
            };
        }

        return {
            indeterminate: false,
            checked: false,
        };
    }, [selectedRows, subrowFamily]);

    const initials = useMemo(() => {
        const initials = (row.name || '').match(/\b\w/g);
        return ((initials?.shift() ?? '') + (initials?.pop() ?? '')).toUpperCase();
    }, [row]);

    return (
        <Stack style={{ paddingLeft: 20 * nestingLevel }} horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
            <ExpandCollapseRowIcon />
            <Checkbox {...checkState} onChange={onChange} />
            {/* {missingApprovalCount === 0 && (
                <TooltipHost content={'All entries approved'}>
                    <Icon iconName="CheckMark" />
                </TooltipHost>
            )} */}
            {row.type === 'user' && <PersonaCoin imageInitials={initials} text={row.name} size={PersonaSize.size32} />}
            <Text>{row.name}</Text>
            {row.type !== 'user' && <InfoIcon />}
        </Stack>
    );
};

const ExpandCollapseRowIcon = () => {
    const { row } = useRowMetaContext();
    // const { expanded, setExpanded } = useRowExpandContext();
    const [expanded, setExpanded] = useGridExpandedRowState(row.id);
    const theme = useTheme();
    return (
        <IconButton
            iconProps={{ iconName: expanded ? 'ChevronUp' : 'ChevronDown', styles: { root: { color: theme.semanticColors.bodyText } } }}
            onClick={() => setExpanded(e => !e)}
        />
    );
};

const InfoIcon = () => {
    const { row } = useRowMetaContext<PseudoRow>();
    const render = useMemo(() => {
        if (row.type === 'project') {
            return (
                <Stack styles={{ root: { gap: 10, paddingTop: 10 } }}>
                    <DetailsLine>
                        <Text>Project Name</Text>
                        <Text>{row.name}</Text>
                    </DetailsLine>
                    <DetailsLine>
                        <Text>Project Manager</Text>
                        <Text>{row.project.manager.name}</Text>
                    </DetailsLine>
                    {row.project.projectNumber !== null && (
                        <DetailsLine>
                            <Text>Visma ID</Text>
                            <Text>{row.project.projectNumber}</Text>
                        </DetailsLine>
                    )}
                </Stack>
            );
        }
        if (row.type === 'group') {
            return (
                <Stack styles={{ root: { paddingTop: 10, gap: 10 } }}>
                    <DetailsLine>
                        <Text>Task Name</Text>
                        <Text>{row.task.name}</Text>
                    </DetailsLine>
                    <DetailsLine>
                        <Text>Start Date</Text>
                        <Text>{getLocaleFormattedDate(row.task.startDate)}</Text>
                    </DetailsLine>
                    <DetailsLine>
                        <Text>End Date</Text>
                        <Text>{getLocaleFormattedDate(row.task.endDate)}</Text>
                    </DetailsLine>
                </Stack>
            );
        }
        return <div>Unknown row type</div>;
    }, [row.name, row.project.manager, row.project.projectNumber, row.task.endDate, row.task.name, row.task.startDate, row.type]);
    const id = 'info-' + row.id;
    const [showCallout, setShowCallout] = useState(false);
    const theme = useTheme();
    return (
        <>
            <Icon
                id={id}
                iconName="Info"
                styles={{ root: { cursor: 'help' } }}
                onMouseEnter={() => setShowCallout(true)}
                onMouseLeave={() => setShowCallout(false)}
            />
            {/* <Callout hidden={!showCallout} isBeakVisible={true} target={`#${id}`} styles={{container: {backgroundColor: theme.semanticColors.disabledBackground}}}> */}
            <Callout
                //
                hidden={!showCallout}
                isBeakVisible={true}
                target={`#${id}`}
                style={{
                    backgroundColor: theme.semanticColors.disabledBackground,
                }}
            >
                <Stack tokens={{ padding: 10 }}>
                    <Text variant="large">Details</Text>
                    {render}
                </Stack>
            </Callout>
        </>
    );
};

const DetailsLine = ({ children }: { children: readonly [React.ReactNode, React.ReactNode] }) => {
    return <div style={{ display: 'grid', gridTemplateColumns: '150px auto', gap: 30 }}>{children}</div>;
};
