import { Checkbox, Label, Stack, Text } from '@fluentui/react';
import { SettingState, useSettingState } from '../../../_contexts/settings/settingsContext';
import { ProjectManagerApproval } from '../../../api/generated/data-contracts';

const useResourceSettingsState: SettingState<ProjectManagerApproval> = key => {
    return useSettingState(
        settings => settings.userFeatures.approval.projectManagerApproval[key],
        (settings, newValue) => {
            settings.userFeatures.approval.projectManagerApproval[key] = newValue;
        },
    );
};

export const ApprovalGridConfig = () => {
    const [enabled, setEnabled] = useResourceSettingsState('enabled');
    const [ratesEnabled, setRatesEnabled] = useResourceSettingsState('useRates');

    return (
        <Stack tokens={{ padding: 10, childrenGap: 10 }}>
            <Text variant="large">Project Approval view (Preview)</Text>
            <Label>Enable Project Approval view to approve time registered on projects per user and task</Label>
            <Checkbox checked={enabled} onChange={(e, c) => setEnabled(Boolean(c))} label="Enable Project Approval View" />
            {enabled && (
                <Stack tokens={{ childrenGap: 10 }}>
                    <Label>Enable rate card on Project Approval view (Preview)</Label>
                    <Checkbox checked={ratesEnabled} onChange={(e, c) => setRatesEnabled(Boolean(c))} label="Enable rate card Project Approval view" />
                </Stack>
            )}
        </Stack>
    );
};
