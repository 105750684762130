import React, { Children, useEffect, useState } from 'react';
import { ResponsiveSunburst } from '@nivo/sunburst';
import { useUiContext } from '../components/Contexts/UiContext';
import { CollectionsOutlined } from '@material-ui/icons';
import { MathToFixed } from '../components/Utils';
// import { node } from '../../config/webpack.config.build';

interface propsUserTaskHoursSunburst {
    SunburstChartData: any[];
    ProjectSelected: string;
    NewProjectSelected: Function,
}

function UserTaskHoursSunburst(props: propsUserTaskHoursSunburst) {
    const [sunburstChartData, setSunburstChartData] = useState<any>({ name: 'nivo' });

    const uiCtx = useUiContext();


    useEffect(() => {
        if (props.SunburstChartData != null && props.SunburstChartData != undefined) {
            if (props.SunburstChartData.length > 0) {
                console.debug("Raw Sunburst Chart Data", props.SunburstChartData, props.ProjectSelected);

                var chartData = { name: 'nivo', children: [] };
                let rawdata = [...props.SunburstChartData];
                rawdata?.forEach(perioddata => {
                    let newprojactualsdata = {};

                    perioddata?.chartDataProjects?.forEach(projectdata => {
                        let project: any = chartData.children.filter(projs => { return projs.name === projectdata.projectname });
                        if (project != null && project != undefined && project.length <= 0) {
                            project = { name: projectdata.projectname, children: [] };
                            chartData.children.push(project);
                        } else {
                            project = project[0];
                        }
                        if (props.ProjectSelected == null || props.ProjectSelected == projectdata.projectname) {


                            projectdata?.chartDataTasks?.forEach(taskdata => {
                                let actualhours = 0;
                                taskdata?.chartDataTimes?.forEach(timedata => {
                                    actualhours = actualhours + timedata.actual;

                                });

                                let task: any = project.children.filter(t => { return t.name === taskdata.taskname });

                                if (task != null && task != undefined && task.length <= 0) {
                                    task = { name: taskdata.taskname, Hours: 0 };
                                    project.children.push(task);
                                } else {
                                    task = task[0];
                                }
                                task["Hours"] = task["Hours"] + actualhours;
                            });

                        }


                    });
                });
                console.debug("Sunburst Chart Data", chartData);
                chartData.children = chartData.children.sort((a, b) => { return (a.name > b.name) ? -1 : 1 });
                setSunburstChartData(chartData);
            }

        }
    }, [props.SunburstChartData, props.ProjectSelected]);
    const CenteredMetric = ({ nodes, centerX, centerY }: any) => {
        const total2 = nodes.reduce((total, datum) => {
            if (datum.depth == 2)
                return total + datum.value;
            else
                return total;
        }, 0);
        console.log("nodes", nodes)
        return (
            <>
                <text
                    x={centerX}
                    y={centerY}
                    textAnchor="middle"
                    dominantBaseline="central"
                    fill={uiCtx.isDarkmode ? '#ffffff' : ''}
                    style={{
                        fontSize: '3em',
                        fontWeight: 400,
                    }}
                >
                    {MathToFixed(total2, 2)}
                </text>
                <text x={centerX}
                    y={centerY + 30}
                    textAnchor="middle"
                    dominantBaseline="central"
                    fill={uiCtx.isDarkmode ? '#ffffff' : ''}
                    style={{
                        fontSize: '1em',
                        fontWeight: 200
                    }}
                >
                    hours
                </text>
            </>
        )
    }
    return (

        <ResponsiveSunburst
            data={sunburstChartData}
            onClick={(data, e) => {
                console.debug(`Project Selected: ${data["id"]}, selected data: ${data["value"]}`, e, data);
                if (data["path"] != undefined && data["path"].length == 2) {
                    e.stopPropagation();
                    props.NewProjectSelected(data["id"]);
                }
            }}
            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
            id="name"
            isInteractive={true}
            //colors={["#e8c1a0","#97e3d5","#61cdbb","#e8a838","#f1e15b","#f47560", "#66c0d1", "#50a7d9", "#f29180", "#bcd95f"]}
            colors={["#31B6FD", "#4584D3", "#5BD078", "#A5D028", "#F5C040", "#05E0DB", "#3153FD", "#4C45D3", "#5BD0B0", "#54D028", "#D0F540", "#057BE0"]}
            theme={uiCtx.isDarkmode ? {
                textColor: '#ffffff',
                // legends: {text: {color: '#ffffff'}},
                // labels: {text: {color: '#ffffff'}},
                // annotations: {text: {color: '#ffffff'}},
                // markers: {textColor: '#ffffff'},
                tooltip: { basic: { color: '#000000' } }
            } : {}}
            animate={true}
            valueFormat=" >-,.10~"
            value="Hours"
            cornerRadius={0}
            borderColor="white"
            borderWidth={1}
            childColor={{ from: 'color', modifiers: [['brighter', 0.4]] }}
            enableArcLabels={true}
            arcLabel="id"
            arcLabelsSkipAngle={40}
            arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2.0]] }}
            layers={['arcs', 'arcLabels', CenteredMetric]}
        />
    );
}

export default UserTaskHoursSunburst;
