import { Checkbox, DefaultButton, Dialog, DialogFooter, Dropdown, IDropdownOption, Icon, PrimaryButton, Stack, Text, useTheme } from '@fluentui/react';
import { useProjectApprovalData } from '../Grid/context/ProjectApprovalDataContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useActivities } from '../Grid/context/ActivitiesProvider';
import { Activity, ProjectDto, SetTimeEntryApprovalActivityDto, SetTimeEntryApprovalStateDto } from '../../../../api/generated/data-contracts';
import { useMutation } from '@tanstack/react-query';
import { ApiCalls } from '../../../../api/api';
import { CUSTOM_RATE_ID } from '../Grid/CONSTANTS';
import { TimeEntryApprovalDtoState, useApprovalStore } from '../../../../_grid/grid2';
import shallow from 'zustand/shallow';
import { useTimeUIConfig } from '../../../../hooks/useTimeUIConfig';

export const SelectionBar = () => {
    const { selectedIds, setSelectedIds, setRows } = useApprovalStore(
        store => ({
            selectedIds: store.selectedRows,
            setSelectedIds: store.setSelectedRows,
            // rows: store.rows,
            setRows: store.setRows,
        }),
        shallow,
    );
    const { approvalRows } = useProjectApprovalData();
    const [selectedActivity, setSelectedActivity] = useState<IDropdownOption<Activity> | null>(null);
    const selectedApprovalRowStates = useMemo(() => approvalRows.filter(approvalRow => selectedIds.has(approvalRow.id)), [approvalRows, selectedIds]);

    useEffect(() => {
        if (!selectedIds.size) {
            setSelectedActivity(null);
        }
    }, [selectedIds]);

    const checkBoxState = useMemo(() => {
        if (!selectedIds.size) {
            return {
                indeterminate: false,
                checked: false,
            };
        }
        if (selectedIds.size === approvalRows.length) {
            return {
                indeterminate: false,
                checked: true,
            };
        }
        return {
            indeterminate: true,
            checked: false,
        };
    }, [approvalRows.length, selectedIds.size]);

    const theme = useTheme();
    const activities = useActivities();
    const options = useMemo(
        (): IDropdownOption<Activity>[] =>
            activities
                .filter(act => act.id !== CUSTOM_RATE_ID)
                .map(activity => {
                    return {
                        key: activity.id,
                        text: activity.name,
                        data: activity,
                    };
                }),
        [activities],
    );

    const { mutate: mutateActivities } = useMutation((approvals: TimeEntryApprovalDtoState[]) =>
        ApiCalls.setMultipleActivity({
            approvals: approvals.map((appr): SetTimeEntryApprovalActivityDto => {
                return {
                    selectedActivity: appr.activity,
                    timeEntryId: appr.timeEntry.id,
                };
            }),
        }),
    );

    const { mutate: mutateApprovalStates } = useMutation((approvals: TimeEntryApprovalDtoState[]) =>
        ApiCalls.setMultipleApprovalState({
            approvals: approvals.map((appr): SetTimeEntryApprovalStateDto => {
                return {
                    selectedApprovalState: appr.approvalState,
                    timeEntryId: appr.timeEntry.id,
                };
            }),
        }),
    );

    const [bulkApprovals, setBulkApprovals] = useState<BulkApprovals | null>(null);

    const bulkApprove = useCallback(async () => {
        const finishedProjects = getFinishedProjectsFromSelection(approvalRows, selectedApprovalRowStates);
        if (finishedProjects.length) {
            setBulkApprovals({ finishedProjects, selectedRowStates: selectedApprovalRowStates });
        } else {
            const nextApprovalStates = selectedApprovalRowStates.map((row): TimeEntryApprovalDtoState => {
                return {
                    ...row,
                    approvalState: 'Approved',
                };
            });
            setRows(rows => {
                return rows.map(row => {
                    return nextApprovalStates.find(nextrow => nextrow.id === row.id) || row;
                });
            });
            mutateApprovalStates(nextApprovalStates);
        }
    }, [approvalRows, mutateApprovalStates, selectedApprovalRowStates, setRows]);

    const onChangeActivity = useCallback(
        async (e: any, opt: IDropdownOption<Activity>) => {
            if (opt?.data) {
                setSelectedActivity(opt);
                setSelectedIds(new Set());
                const nextSelectedApprovalsState: TimeEntryApprovalDtoState[] = selectedApprovalRowStates
                    .filter(row => row.approvalState !== 'Approved')
                    .map(appr => {
                        return {
                            ...appr,
                            activity: opt.data,
                        };
                    });
                setRows(rows => {
                    return rows.map(row => {
                        return nextSelectedApprovalsState.find(nextrow => nextrow.id === row.id) || row;
                    });
                });
                mutateActivities(nextSelectedApprovalsState);
            }
        },
        [mutateActivities, selectedApprovalRowStates, setRows, setSelectedIds],
    );
    const { config } = useTimeUIConfig();
    return (
        <>
            <Stack
                horizontal
                horizontalAlign="space-between"
                styles={{ root: { padding: '0 8px', border: `1px solid ${theme.semanticColors.disabledBackground}`, height: 60 } }}
            >
                <Stack horizontal verticalAlign="center">
                    <Checkbox
                        {...checkBoxState}
                        onChange={(e, val) => {
                            if (val) {
                                setSelectedIds(new Set(approvalRows.map(approval => approval.id)));
                            } else {
                                setSelectedIds(new Set());
                            }
                        }}
                    />
                    <Text>Select all</Text>
                    {selectedIds.size !== 0 && (
                        <>
                            <div
                                style={{
                                    display: 'inline-block',
                                    height: '1rem',
                                    width: 1,
                                    backgroundColor: theme.semanticColors.bodyText,
                                    marginLeft: 10,
                                    marginRight: 10,
                                }}
                            />
                            <Text>{selectedIds.size} Selected</Text>
                        </>
                    )}
                </Stack>
                <Stack horizontal verticalAlign="center" styles={{ root: { height: '100%', gap: 20 } }}>
                    {selectedIds.size !== 0 && (
                        <>
                            {Boolean(config.approval.projectManagerApproval.useRates) && (
                                <Dropdown
                                    options={options}
                                    styles={{ root: { width: 200 } }}
                                    placeholder="Bulk edit activity"
                                    onChange={onChangeActivity}
                                    selectedKey={selectedActivity?.key as string}
                                />
                            )}
                            <PrimaryButton styles={{ root: { backgroundColor: theme.semanticColors.successIcon } }} onClick={bulkApprove}>
                                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
                                    <Text>Approve</Text>
                                    <Icon iconName="CheckMark" />
                                </Stack>
                            </PrimaryButton>
                        </>
                    )}
                </Stack>
            </Stack>
            {bulkApprovals && (
                <BulkApprovalDialog
                    bulkApprovals={bulkApprovals}
                    onApprove={() => {
                        const nextApprovalStates = bulkApprovals.selectedRowStates.map((row): TimeEntryApprovalDtoState => {
                            return {
                                ...row,
                                approvalState: 'Approved',
                            };
                        });
                        setRows(rows => {
                            return rows.map(row => {
                                return nextApprovalStates.find(nextrow => nextrow.id === row.id) || row;
                            });
                        });
                        mutateApprovalStates(nextApprovalStates);
                        setBulkApprovals(null);
                    }}
                    onDismiss={() => setBulkApprovals(null)}
                />
            )}
        </>
    );
};

const getFinishedProjectsFromSelection = (allApprovals: TimeEntryApprovalDtoState[], selectedApprovals: TimeEntryApprovalDtoState[]) => {
    const projects = [...new Map<string, ProjectDto>(allApprovals.map(approval => [approval.project.id, approval.project] as const)).values()];
    return projects.filter(project => {
        const missingApprovalsForProject = allApprovals.filter(approval => approval.project.id === project.id && approval.approvalState !== 'Approved');
        return (
            missingApprovalsForProject.length &&
            missingApprovalsForProject.every(missingApproval => selectedApprovals.find(selectedApproval => selectedApproval.id === missingApproval.id))
        );
    });
};

type BulkApprovals = { selectedRowStates: TimeEntryApprovalDtoState[]; finishedProjects: ProjectDto[] };

const BulkApprovalDialog = ({
    bulkApprovals: { finishedProjects },
    onApprove,
    onDismiss,
}: {
    bulkApprovals: BulkApprovals;
    onDismiss: () => void;
    onApprove: () => void;
}) => {
    const theme = useTheme();
    return (
        <Dialog onDismiss={onDismiss} hidden={false} dialogContentProps={{ title: 'Approve and close time registration?' }} minWidth={500}>
            <Stack tokens={{ childrenGap: 10 }}>
                <Text>
                    After approving all hours, time registration will be closed for the given time period and restricted from further changes on the following
                    projects:
                </Text>
                <ul>
                    {finishedProjects.map(project => {
                        return (
                            <li key={project.id}>
                                <Text>{project.name}</Text>
                            </li>
                        );
                    })}
                </ul>
            </Stack>

            <DialogFooter>
                <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
                <PrimaryButton styles={{ root: { backgroundColor: theme.semanticColors.successIcon } }} onClick={onApprove}>
                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
                        <Text>Approve</Text>
                        <Icon iconName="CheckMark" />
                    </Stack>
                </PrimaryButton>
            </DialogFooter>
        </Dialog>
    );
};
