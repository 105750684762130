export enum Coachmark {
    GridCell = 0,
    GridRowTotal = 1,
    GroupPin = 2,
    PeriodPicker = 3,
    Search = 4,
    Submit = 5,
    Approve = 6,
    GridUtils = 7,
    SiteNavigation = 8,
}