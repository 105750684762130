import * as React from 'react';
import { Text } from '@fluentui/react';

interface IProps {
    title: string;
    textAlign?: 'left' | 'center' | 'right';
}

export const SectionTitle: React.FC<IProps> = props => {
    return (
        <Text
            variant="xLarge"
            styles={{
                root: {
                    padding: '4px 0 8px 0',
                    display: 'inline-block',
                    fontWeight: 400,
                    textAlign: props.textAlign ? props.textAlign : 'center',
                },
            }}
        >
            {props.title}
        </Text>
    );
};
