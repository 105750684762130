import React, { useEffect, useState, useRef, ReactElement } from 'react';
import ApproverCellDetailLine from './ApproverCellDetailLine';
import { GetApproverDetailsListGroups } from '../components/Utils';
import { Stack, Callout, getTheme, FontWeights, mergeStyleSets, Text, DetailsList, SelectionMode, IColumn, Icon, Spinner, IStackStyles, Label } from '@fluentui/react';
import { useUiContext } from '../components/Contexts/UiContext';
import { convertHoursToProcent, getCapacityPrDay } from '../components/WeekOverview/Cell.helpers';
import { ApproverFlaggedColumn } from './ApproverFlaggedColumn';
import { roundToNearestValueStep } from '../Mobile/mobileUtils';

const theme = getTheme();
const styles = mergeStyleSets({
    buttonArea: {
        verticalAlign: 'top',
        display: 'inline-block',
        textAlign: 'center',
        margin: '0 100px',
        minWidth: 130,
        height: 32,
    },
    callout: {
        maxWidth: 800,
        minWidth: 300,
    },
    header: {
        padding: '18px 24px 12px',
        color: theme.semanticColors.bodyText,
    },
    title: [
        theme.fonts.mediumPlus,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
        },
    ],
    inner: {
        height: '100%',
        padding: '0 24px 20px',
    },
    actions: {
        position: 'relative',
        marginTop: 20,
        width: '100%',
        whiteSpace: 'nowrap',
    },
    subtext: [
        theme.fonts.small,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
        },
    ],
    link: [
        theme.fonts.medium,
        {
            color: theme.palette.neutralPrimary,
        },
    ],
});
const loadingStackStyle: IStackStyles = { root: { height: '150px', width: '640px' } };
const spinnerStyles = {
    circle: {
        height: 25,
        width: 25,
    },
    label: {
        'font-size': 12,
    },
};

interface propsApproverCell {
    value: string;
    day: string;
    date?: Date;
    reportperiodid: string;
    userid: string;
    hasUserComments: boolean;
    disabled: boolean;
    periodDays?: any[];
}
function ApprovalCell(props: propsApproverCell) {
    const [showdetailsflyout, setShowdetailsflyout] = useState<boolean>(false);
    const [loadingdetails, setloadingdetails] = useState<boolean>(true);

    const textdRef = useRef(null);

    const [detailitems, setDetailitems] = useState<ApproverCellDetailLine[]>([]);
    const [detailitemgroups, setSetailitemgroups] = useState<any[]>([]);

    const [svalue, setsValue] = useState<string>('');

    const uiCtx = useUiContext();

    useEffect(() => {
        const value = Number(props.value);
        const newValue = uiCtx.gridInputType === 'percent' ? convertHoursToProcent(value, props.periodDays.length, getCapacityPrDay(props.periodDays, uiCtx)) : value;
        const roundedCellValue = roundToNearestValueStep(newValue, uiCtx.timeLowerLimitConfig);
        setsValue(`${roundedCellValue}`);
        // if (uiCtx.gridInputType === "percent") {
        //     const procent = convertHoursToProcent(Number(props.value), props.periodDays.length, getCapacityPrDay(props.periodDays, uiCtx));
        //     setsValue(`${MathToFixed(parseNumber(procent.toString().replace(/\,/g, '.')), 1)}`);
        // } else {
        //     setsValue(Format2DecimalValue(props.value));
        // }
    }, [props.value]);

    let onClick = (ev: React.MouseEvent<HTMLElement, MouseEvent>): void => {
        setShowdetailsflyout(true);
        setloadingdetails(true);
        const utcStart: any = props.date?.getISOStringMidnight();
        uiCtx.timeApi
            .getApprovalLinesByUserCustomPeriod(props.reportperiodid, utcStart)
            // dirty fix to get the comment from the first day of the period
            // it is used for the period view only
            .then(async json => {
                let detailLinesFirstDayOfPeriod: any;
                if (uiCtx.gridView === 'period') {
                    const firstDayOfPeriod = await uiCtx.timeApi.getApprovalLinesByUserCustomPeriod(props.reportperiodid, props.periodDays[0].date.getISOStringMidnight());
                    detailLinesFirstDayOfPeriod = firstDayOfPeriod;
                }
                return { json, detailLinesFirstDayOfPeriod };
            })
            .then(({ json, detailLinesFirstDayOfPeriod }) => {
                let detaillines: ApproverCellDetailLine[] = [];
                json.map((detailline: any, index: number) => {
                    if (detailLinesFirstDayOfPeriod) {
                        const currentLine = detailLinesFirstDayOfPeriod.find((line: any) => line.taskId === detailline.taskId);
                        detailline.comments = currentLine?.comments;
                    }
                    // fix end
                    detaillines.push(
                        new ApproverCellDetailLine(detailline.projectId, detailline.projectName, detailline.taskId, detailline.taskName, detailline.total, detailline.comments, detailline.flagged),
                    );
                });
                let sortedlines: ApproverCellDetailLine[] = detaillines;
                setDetailitems(sortedlines);
                if (sortedlines.length > 0) {
                    let grouped: any = [];
                    grouped = GetApproverDetailsListGroups(sortedlines);
                    setSetailitemgroups(grouped);
                } else {
                    setSetailitemgroups([]);
                }
                setShowdetailsflyout(true);
                setloadingdetails(false);
            });
    };
    let onRenderColumn = (item: ApproverCellDetailLine, index: number, column: IColumn): ReactElement => {
        const fieldContent = item[column.fieldName as keyof ApproverCellDetailLine] as string;
        switch (column.key) {
            case 'hours':
                const value =
                    uiCtx.gridInputType === 'percent' ? convertHoursToProcent(Number(fieldContent), props.periodDays.length, getCapacityPrDay(props.periodDays, uiCtx)) : Number(fieldContent);
                const roundedCellValue = roundToNearestValueStep(value, uiCtx.timeLowerLimitConfig);
                return <span>{roundedCellValue}</span>;
            // return <span>{uiCtx.gridInputType === "percent" ? `${MathToFixed(parseNumber(value.toString().replace(/\,/g, '.')), 1)}` : Format2DecimalValue(`${value}`)}</span>;
            case 'flagged':
                return <ApproverFlaggedColumn flagged={item.flagged} alignLeft />;
            default:
                return <span>{fieldContent}</span>;
        }
    };
    let flyoutDismissed = (ev: any): void => {
        setShowdetailsflyout(false);
    };
    return (
        <>
            <Label>
                <span
                    ref={textdRef}
                    className={!props.disabled ? 'approvalcelltextspan' : 'approvalcelltextspandisabled'}
                    onClick={!props.disabled ? onClick : () => {}}
                    style={{ textAlign: 'center', display: 'block' }}
                >
                    {uiCtx.gridInputType === 'percent' ? `${svalue} %` : svalue}{' '}
                    {props.hasUserComments && <Icon iconName="Comment" styles={{ root: { fontSize: '12px', height: '10px', width: '14px', marginTop: '-4px', position: 'absolute' } }} />}
                </span>
            </Label>
            {showdetailsflyout && !props.disabled && (
                <Callout
                    className={styles.callout}
                    ariaLabelledBy="{labelId}"
                    ariaDescribedBy="{descriptionId}"
                    role="alertdialog"
                    gapSpace={0}
                    target={textdRef}
                    onDismiss={flyoutDismissed}
                    setInitialFocus
                >
                    <div className={styles.header}>
                        <Text className={styles.title}>Details</Text>
                    </div>
                    {loadingdetails ? (
                        <Stack verticalAlign="center" horizontalAlign="center" styles={loadingStackStyle}>
                            <Spinner styles={spinnerStyles} label="Getting time..." />
                        </Stack>
                    ) : (
                        <div className={styles.inner}>
                            <DetailsList
                                //componentRef={this._root}
                                items={detailitems}
                                groups={detailitemgroups}
                                onRenderItemColumn={onRenderColumn}
                                columns={[
                                    { key: 'name', name: 'Project/Task', fieldName: 'taskName', minWidth: 200, maxWidth: 400, isResizable: true },
                                    { key: 'hours', name: uiCtx.gridInputType === 'percent' ? '%' : 'Hours', fieldName: 'total', minWidth: 100, maxWidth: 200, isResizable: true },
                                    ...(uiCtx.allowCellFlag ? [{ key: 'flagged', name: 'Flag', fieldName: 'flagged', minWidth: 100, isResizable: true }] : []),
                                    { key: 'comments', name: 'Comments', fieldName: 'comments', minWidth: 200, maxWidth: 400, isResizable: true },
                                ]}
                                selectionMode={SelectionMode.none}
                                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                ariaLabelForSelectionColumn="Toggle selection"
                                checkButtonAriaLabel="Row checkbox"
                                groupProps={{
                                    showEmptyGroups: true,
                                }}
                                compact={true}
                            />
                        </div>
                    )}
                </Callout>
            )}
        </>
    );
}

export default ApprovalCell;
