import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { TextField, Callout, FontWeights, Text, DirectionalHint, TooltipHost, FontIcon, makeStyles, useTheme } from '@fluentui/react';
import TimeRow from '../Model/TimePeriodRowM';
import { FormatValue, GetNumberSep, Format2DecimalValue, MathToFixed, getEmptyGuid } from './Utils';
import { constants } from '../Styles/constants';
import PeriodDay from '../Model/PeriodDay';
import EDays from '../Model/EDays';
import { useCellCtx } from './Contexts/CellContext';
import { useUiContext } from './Contexts/UiContext';
import { EndPointSolidIcon } from '@fluentui/react-icons-mdl2';
import { roundToNearestValueStep } from '../Mobile/mobileUtils';
import { useId } from '@fluentui/react-hooks';
import { TimeEntry } from '../api/generated/data-contracts';

interface propsCell {
    time: TimeRow;
    timeentry: TimeEntry;
    day: PeriodDay;
    updated: Function;
    readonly: boolean;
    readonlyreason: string;
    propertyUpdated: string;
    timeLowerLimitConfig: string;
    cellDataNeedsSave: Function;
}

const Cell = React.forwardRef<any, propsCell>((props, ref) => {
    const theme = useTheme();
    const styles = getStyles();
    const textFieldRef = useRef<any>();
    const targetId = useId();

    const [celltext, setCelltext] = useState('');
    const [cellnumber, setCellnumber] = useState<number>(0);
    const [cellcomment, setComment] = useState<string>('');
    const [cellFlagged, setCellFlagged] = useState<boolean>(props.timeentry?.flagged || false);

    const [cellnumberlastsaved, setCellnumberlastsaved] = useState<number>(0);
    const [commentlastsaved, setCommentlastsaved] = useState<string>('');

    const [cellIsError, setCellIsError] = useState<string>('');

    const [workday, setWorkday] = useState(true);
    const [showdetailsicon, setShowdetailsicon] = useState<boolean>(false);
    const [showdetailsflyout, setShowdetailsflyout] = useState<boolean>(false);
    const [showFlagIcon, setShowFlagIcon] = useState<boolean>(props.timeentry?.flagged || false);
    const [textinfocus, setTextinfocus] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    const [anyChanges, setAnyChanges] = useState<boolean>(false);
    const [dataSaved, setdatasaved] = useState<boolean>(false);

    const cellCtx = useCellCtx();
    const uiCtx = useUiContext();

    useEffect(() => {
        //For total update?
        let hours = props.timeentry?.hours || 0;
        setCelltext(FormatValue(hours));
        setCellnumber(hours);
        setCellnumberlastsaved(hours);
    }, [props.timeentry?.hours]);

    useEffect(() => {
        let hours = props.timeentry?.hours || 0;
        let comment = props.timeentry?.comment || '';
        setCelltext(FormatValue(hours));
        setCellnumber(hours);
        setCellnumberlastsaved(hours);
        setComment(comment);
        setCommentlastsaved(comment);
        if (props.day.day === EDays.Saturday || props.day.name === EDays.Sunday) {
            setWorkday(false);
        }

        setLoaded(true);
    }, [props.timeentry]);

    useEffect(() => {
        let hours = props.timeentry?.hours || 0;
        setCelltext(FormatValue(hours));
    }, [props.timeentry, props.day, props.propertyUpdated]);

    useEffect(() => {
        if (showdetailsflyout === false && textinfocus === false && loaded === true) {
            console.debug('Cell is error changed: ' + cellIsError);
            const roundedCellNumber = roundToNearestValueStep(cellnumber, uiCtx.timeLowerLimitConfig);
            if (cellIsError == '' && (roundedCellNumber !== cellnumberlastsaved || cellcomment !== commentlastsaved)) {
                setdatasaved(true);
                saveData(roundedCellNumber, cellcomment, cellnumberlastsaved, cellFlagged);
                setAnyChanges(false);
            } else {
                setCelltext(FormatValue(cellnumberlastsaved));
                setCellnumber(cellnumberlastsaved);
            }
            props.cellDataNeedsSave(false);
        }
    }, [showdetailsflyout, textinfocus]);

    useEffect(() => {
        if (textinfocus && textFieldRef.current) {
            textFieldRef.current.select();
        }
    }, [textinfocus]);

    useEffect(() => {
        if (cellIsError == '') {
            // Check if ctx contains cell, if so, remove it
            var newArray = uiCtx.cellsWithError.filter(c => c != targetId);
            uiCtx.setCellsWithError(newArray);
        } else {
            //Check if ctx contains cell, if not, add it
            if (uiCtx.cellsWithError.filter(c => c == targetId).length < 1) {
                var newArray = uiCtx.cellsWithError;
                newArray.push(targetId);
                uiCtx.setCellsWithError(newArray);
            }
        }
        //console.debug('niv test ctx ---', uiCtx.cellsWithError);
    }, [cellIsError]);

    const onCellUpdated = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        setAnyChanges(true);
        // setCelltext(GetNumberSep() === ',' ? text.replace(/\./g, ',') : text.replace(/\,/g, '.'));
        // setCellnumber(parseNumber(text.replace(/\,/g, '.')));

        isValid(GetNumberSep() === ',' ? text.replace(/\./g, ',') : text.replace(/\,/g, '.'));
        setCelltext(GetNumberSep() === ',' ? text.replace(/\./g, ',') : text.replace(/\,/g, '.'));
        setCellnumber(MathToFixed(parseNumber(text.replace(/\,/g, '.')), 2));
    };

    const onCommentUpdated = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        setComment(text);
    };

    const onBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        setTimeout(() => {
            setTextinfocus(false);
            setShowdetailsicon(false);
            setShowFlagIcon(cellFlagged);
        }, 500);
    };

    const isValid = (value: string): void => {
        let newVal = value.replace(/,/g, '.');

        if (isValidNumber(newVal)) {
            let newValNum = parseFloat(newVal);
            if (newValNum > 24) {
                setCellIsError('Max 24 hours');
                return;
            }
            if (newValNum < 0) {
                setCellIsError('Negative hours not allowed');
                return;
            }
            // if (props.timeLowerLimitConfig != '' && newValNum != 0 && !isNaN(newValNum)) {
            //     if (parseFloat(newVal) < parseFloat(props.timeLowerLimitConfig)) {
            //         setCellIsError('Must be over ' + Format2DecimalValue(props.timeLowerLimitConfig));
            //         return;
            //     }
            // }
            setCellIsError('');
            return;
        } else {
            setCellIsError('Whoops, not a number');
            return;
            // return " ";
        }
    };

    const isValidOLD = (value: string): string => {
        let newVal = value.replace(/,/g, '.');

        if (isValidNumber(newVal)) {
            let newValNum = parseFloat(newVal);
            if (newValNum > 24) {
                return 'max 24 hours';
            }
            if (props.timeLowerLimitConfig != '' && newValNum != 0 && !isNaN(newValNum)) {
                if (parseFloat(newVal) < parseFloat(props.timeLowerLimitConfig)) {
                    return 'Must be over ' + Format2DecimalValue(props.timeLowerLimitConfig);
                }
            }
            return '';
        } else {
            return 'Ups (Number)';
            // return " ";
        }
    };

    const onFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        props.cellDataNeedsSave(true);
        setShowdetailsicon(true);
        setShowFlagIcon(true);
        setTextinfocus(true);
    };

    const onIconClick = (ev: React.MouseEvent<HTMLElement, MouseEvent>): void => {
        setShowdetailsflyout(true);
        setShowdetailsicon(true);
        setShowFlagIcon(true);
    };

    const onFlagIconClick = () => {
        const newFlagValue = !cellFlagged;
        saveData(cellnumber, cellcomment, cellnumberlastsaved, newFlagValue);
    };

    const flyoutDismissed = (ev: any): void => {
        setShowdetailsflyout(false);
    };

    const focusCellIfExists = (cellKey: string) => {
        // If cellKey is defined, it was found in the Map containing all cells
        // and it can be focused
        if (cellKey) {
            const destinationCell = cellCtx.inputNodes.get(cellKey);
            destinationCell.setFocus();
        }
    };

    const getKeyForTargetDay = (currentCellKey: string, daysFromCurrent: number) => {
        let keys = [...cellCtx.inputNodes.keys()];
        const indexOfCurrent = keys.indexOf(currentCellKey);

        return keys[indexOfCurrent + daysFromCurrent];
    };

    // If standing in Friday cell, move to next row
    const focusNextCell = (currentRowId: any, currentDay: any) => {
        const nextCellKey = getKeyForTargetDay(`${currentRowId}_${currentDay}`, 1);
        focusCellIfExists(nextCellKey);
    };

    const focusPreviousCell = (currentRowId: any, currentDay: any) => {
        const previousKey = getKeyForTargetDay(`${currentRowId}_${currentDay}`, -1);
        focusCellIfExists(previousKey);
    };

    const focusBelowCell = (currentRowId: any, currentDay: any) => {
        let keys = [...cellCtx.inputNodes.keys()];
        let highest = 0;

        keys.some(function (key: string) {
            // get day index of the current key - what is after the last underscore
            const dayIndex: number = +key.substring(key.lastIndexOf('_') + 1);

            if (dayIndex >= highest) {
                highest = dayIndex;
            } else {
                // we found the highest dayIndex - return true to break .some() loop
                return true;
            }
        });

        const oneWeekAheadKey = getKeyForTargetDay(`${currentRowId}_${currentDay}`, highest + 1);
        focusCellIfExists(oneWeekAheadKey);
    };

    const focusAboveCell = (currentRowId: any, currentDay: any) => {
        let keys = [...cellCtx.inputNodes.keys()];
        const daysInPeriod = getDaysInWeek(keys);
        const oneWeekBeforeKey = getKeyForTargetDay(`${currentRowId}_${currentDay}`, -(daysInPeriod + 1));
        focusCellIfExists(oneWeekBeforeKey);
    };

    const onKeyDown = (event: any): void => {
        let workTypeId = props.time.workType == null || props.time.workType == undefined ? getEmptyGuid() : props.time.workType.id;
        const rowId = `${props.time.taskId}_${workTypeId}`;

        switch (event.keyCode) {
            case 13:
                // Enter key
                focusBelowCell(rowId, props.day.id);
                break;

            case 37:
                // Left arrow
                focusPreviousCell(rowId, props.day.id);
                break;

            case 38:
                // Up arrow
                focusAboveCell(rowId, props.day.id);
                break;

            case 39:
                // Right arrow
                focusNextCell(rowId, props.day.id);
                break;

            case 40:
                // Down arrow
                focusBelowCell(rowId, props.day.id);
                break;

            default:
                return;
        }

        if (cellnumber !== cellnumberlastsaved || cellcomment !== commentlastsaved) {
            if (cellIsError == '') {
                setdatasaved(true);
                saveData(cellnumber, cellcomment, cellnumberlastsaved, cellFlagged);
                setAnyChanges(false);
            }
        }
    };

    const saveData = (hours: number, comment: string, previousHours: number, isFlagged: boolean): void => {
        //clearTimeout(savetimer);
        let hoursToBeSaved = hours;
        let commentToBeSaved = comment;
        const flagged = previousHours >= 0 && hoursToBeSaved === 0 ? false : isFlagged;

        console.debug('saving timer startet...');
        //savetimer = setTimeout(() => {
        if (!showdetailsflyout) {
            props.updated(props.time, props.day, props.timeentry, hoursToBeSaved, commentToBeSaved, flagged, previousHours, () => {
                setTimeout(() => {
                    setdatasaved(false);
                    setShowFlagIcon(flagged);
                    setCellFlagged(flagged);
                }, 200);
            });
            setCellnumberlastsaved(hoursToBeSaved);
            setCommentlastsaved(commentToBeSaved);
            console.debug('saving (' + hoursToBeSaved + ', ' + commentToBeSaved + ')...');
        }
    };

    useImperativeHandle(ref, () => ({
        setFocus: () => textFieldRef.current && textFieldRef.current.focus(),
    }));

    return (
        <div className={styles.root}>
            {uiCtx.allowCellComment && (showdetailsicon || showdetailsflyout || cellcomment !== '') ? (
                <FontIcon id={targetId} iconName="Comment" className={'dayDetailsIcon' + (props.readonly ? ' dayDetailsIconDisabled' : '')} onClick={onIconClick} />
            ) : null}
            <TooltipHost content={cellIsError !== '' ? cellIsError : props.readonly ? props.readonlyreason : ''} closeDelay={250}>
                <TextField
                    componentRef={textFieldRef}
                    className="cellInput"
                    value={celltext}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onChange={onCellUpdated}
                    onKeyDown={onKeyDown}
                    disabled={props.readonly}
                    autoComplete="off"
                    style={{ textAlign: 'center' }}
                    styles={{
                        root: {},
                        fieldGroup: {
                            borderColor: `${cellIsError != '' ? 'rgba(155,42,49,0.75)' : 'rgba(0,0,0,0)'}`,
                            backgroundColor: `${dataSaved ? 'rgba(16, 124, 16, 0.6)!important' : 'rgba(0,0,0,0)'}`,
                            borderWidth: '2px',
                            '&:hover': {
                                borderColor: `${cellIsError != '' ? 'rgba(155,42,49,1)' : 'rgba(0,0,0,0)'}`,
                                backgroundColor: `${dataSaved ? 'rgba(16, 124, 16, 0.6)!important' : 'rgba(0,0,0,0)'}`,
                                // backgroundColor: theme.semanticColors.inputBackground,
                                // backgroundColor: workday ? theme.semanticColors.inputBackground : theme.palette.neutralLighterAlt,
                            },
                            '&::after': {
                                borderColor: `${cellIsError != '' ? 'rgba(155,42,49,1)' : 'rgba(96,97,170,1)'}`,
                            },
                        },
                        field: {
                            textAlign: 'right',
                            paddingRight: theme.spacing.s1,
                            paddingLeft: 7,
                        },
                    }}
                />
                {uiCtx.allowCellFlag && showFlagIcon ? (
                    <EndPointSolidIcon
                        id={targetId}
                        style={{ color: cellFlagged ? theme.palette.red : theme.palette.themeLight, cursor: props.readonly ? 'no-drop' : 'pointer' }}
                        onClick={() => {
                            if (!props.readonly) {
                                onFlagIconClick();
                            }
                        }}
                        className={'dayflagsIcon' + (props.readonly ? ' dayflagsIconDisabled' : '')}
                    />
                ) : null}
            </TooltipHost>

            {showdetailsflyout && (
                <Callout
                    className={styles.callout}
                    ariaLabelledBy="{labelId}"
                    ariaDescribedBy="{descriptionId}"
                    role="alertdialog"
                    gapSpace={0}
                    target={`#${targetId}`}
                    directionalHint={DirectionalHint.bottomCenter}
                    onDismiss={flyoutDismissed}
                    setInitialFocus
                    dismissOnTargetClick={true}
                >
                    <div className={styles.header}>
                        <Text className={styles.title}>Comment</Text>
                    </div>
                    <div className={styles.inner}>
                        <TextField value={cellcomment} onChange={onCommentUpdated} multiline autoAdjustHeight disabled={props.readonly} />
                    </div>
                </Callout>
            )}
        </div>
    );
});

export default Cell;

const isValidNumber = (num: string) => {
    if (num === '') {
        return true;
    }
    if (isNaN(parseFloat(num))) {
        return false;
    }
    return true;
};

const parseNumber = (num: string) => {
    if (num === '') {
        return 0;
    }
    if (isNaN(parseFloat(num))) {
        return 0;
    }
    return parseFloat(num);
};

const getStyles = makeStyles(theme => ({
    root: [
        'cellInner',
        {
            position: 'relative',
            height: '100%',
            width: '100%',
            maxWidth: constants.cellInputMaxWidth,
            margin: '0 auto',
            display: 'flex',
        },
    ],
    buttonArea: {
        verticalAlign: 'top',
        display: 'inline-block',
        textAlign: 'center',
        margin: '0 100px',
        minWidth: 130,
        height: 32,
    },
    callout: {
        maxWidth: 800,
        minWidth: 300,
    },
    header: {
        padding: '18px 24px 12px',
        color: theme.semanticColors.bodyText,
    },
    title: [
        theme.fonts.mediumPlus,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
        },
    ],
    inner: {
        height: '100%',
        padding: '0 24px 20px',
    },
    actions: {
        position: 'relative',
        marginTop: 20,
        width: '100%',
        whiteSpace: 'nowrap',
    },
    subtext: [
        theme.fonts.small,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
        },
    ],
    link: [
        theme.fonts.medium,
        {
            color: theme.palette.neutralPrimary,
        },
    ],
}));

function getDaysInWeek(keys: any[]) {
    let indexes: number[] = [];

    keys.forEach((key: string) => {
        // convert dayIndex to number
        const dayIndex: number = +key.substring(key.lastIndexOf('_') + 1);
        indexes.push(dayIndex);
    });

    // this indicates how many days we have in the period
    const daysInWeek = Math.max(...indexes);
    return daysInWeek;
}
