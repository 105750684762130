import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TimeUser } from '../../../../../api/generated/data-contracts';
import { ApiCalls } from '../../../../../api/api';
import { SEARCH_USERS } from '../../../../../constants/queryKeys';
import { useStore } from '../../../../../store/useStore';

export const useUpdateUser = () => {
    const queryClient = useQueryClient();

    const { mutate: mutateUpdateUser, isLoading: isLoadingUpdateUserMutation } = useMutation(
        (user: TimeUser): Promise<TimeUser> =>
            ApiCalls.updateChangedFieldsOnUser(user)
                .then(res => res.data)
                .then(res => {
                    return res;
                }),
        {
            onSuccess: (result, entity) => {
                const searchText = useStore.getState().searchText;
                const users = queryClient.getQueryData<[TimeUser]>([SEARCH_USERS, searchText]);

                if (users) {
                    const newUsers = users.map((user: TimeUser) => {
                        if (user.id === entity.id) {
                            return result;
                        } else {
                            return user;
                        }
                    });

                    queryClient.setQueryData<TimeUser[]>([SEARCH_USERS, searchText], () => {
                        return [...newUsers];
                    });
                }
            },
            onError: () => {
                const searchText = useStore.getState().searchText;
                const users = queryClient.getQueryData<TimeUser[]>([SEARCH_USERS, searchText]);

                if (users) {
                    queryClient.setQueryData<TimeUser[]>([SEARCH_USERS, searchText], () => {
                        return users.map(user => user);
                    });
                }
            },
        },
    );

    return { mutateUpdateUser, isLoadingUpdateUserMutation };
};
