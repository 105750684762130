import React from 'react';
import { Route, Routes, useLocation } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import '../Styles/AppMobile.scss';
import { MobileContextProvider } from './MobileContext';
import { EditTaskOverlay } from './components/EditTaskOverlay/EditTaskOverlay';
import { useUiContext } from '../components/Contexts/UiContext';
import { LoadingSpinner } from './components/LoadingSpinner';
import { makeStyles } from '@fluentui/react';
import { mobileRouting } from './mobileRouting';

const renderMobileRoutes = mobileRouting.map(({ path, component: Component }) => <Route key={path} path={path} element={<Component />} />);

export const AppMobile: React.FC = () => {
    const location = useLocation();
    const classes = getStyles();

    return (
        <div className={classes.root}>
            <TransitionGroup component={null}>
                <CSSTransition
                    key={location.key}
                    timeout={300}
                    mountOnEnter
                    unmountOnExit
                    classNames={{
                        appear: location.pathname === '/' ? classes.enterReverse : classes.enter,
                        appearActive: classes.enterActive,
                        enter: location.pathname === '/' ? classes.enterReverse : classes.enter,
                        enterActive: classes.enterActive,
                        exit: classes.exit,
                        exitActive: location.pathname === '/' ? classes.exitActiveReverse : classes.exitActive,
                    }}
                >
                    <Routes location={location}>{renderMobileRoutes}</Routes>
                </CSSTransition>
            </TransitionGroup>
            <EditTaskOverlay />
            <LoadingSpinner />
        </div>
    );
};

export const AppMobileWrapper: React.FC = () => {
    const uiCtx = useUiContext();
    let mae = uiCtx.allConfig.find(_ => _.key?.toLowerCase() === 'mobileaccess')?.value?.toLowerCase() !== 'disabled';

    return (
        <MobileContextProvider>
            {mae ? (
                <AppMobile />
            ) : (
                <>
                    <div className="centeredtext">
                        <h2>Mobile access have been disabled</h2>
                    </div>
                </>
            )}
        </MobileContextProvider>
    );
};

const sharedStyle: any = {
    width: '100vw',
    position: 'fixed !important',
    top: 0,
    left: 0,
};

const getStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
        // overflowX: "hidden",
        // overflowY: "auto",
        width: '100%',
        height: '100%',
    },
    wrapper: {
        position: 'relative',
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
        // overflowX: "hidden",
        // overflowY: "auto",
        width: '100%',
        height: '100%',
        minHeight: '100vh',
    },
    enter: {
        ...sharedStyle,
        transform: 'translateX(100%)',
    },
    enterActive: {
        ...sharedStyle,
        transform: 'translateX(0%) !important',
        transition: 'all 300ms ease-in-out',
    },
    exit: {
        ...sharedStyle,
        transform: 'translateX(0%)',
    },
    exitActive: {
        ...sharedStyle,
        transform: 'translateX(100%) !important',
        transition: 'all 300ms ease-in-out',
    },
    enterReverse: {
        ...sharedStyle,
        transform: 'translateX(-100%)',
    },
    exitActiveReverse: {
        ...sharedStyle,
        transform: 'translateX(-100%)',
        transition: 'all 300ms ease-in-out',
    },
}));
