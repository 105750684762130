import { createCtx } from '../../../../components/Contexts/createCtx';

export type FilterDropdownContext = {
    onFilterInput: (str: string) => void;
};

const [useCtx, Provider] = createCtx<FilterDropdownContext>();

export const useFilterDropdown = useCtx;

export const FilterDropdownContextProvider = Provider;
