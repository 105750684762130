import { useMutation } from '@tanstack/react-query';
import { ApiCalls } from '../../../../api/api';
import { ProjectumProblemDetails, TimeEntryError } from '../../../../api/generated/data-contracts';
import { useState } from 'react';

export const useImportTimeEntries = () => {
    const [projectumProblem, setProjectumProblem] = useState<ProjectumProblemDetails>(undefined);

    const {
        mutate: mutateImportTimeEntries,
        isLoading: isLoadingImportTimeEntries,
        data: importErrors = [],
    } = useMutation(
        (file: File): Promise<TimeEntryError[]> =>
            ApiCalls.importTimeEntry({ file })
                .then(res => res.data)
                .then(res => {
                    return res;
                }),
        {
            onSuccess: (result, entity) => {
                return result;
            },
            onError: (error: any) => {
                setProjectumProblem(error.error);
            },
        },
    );

    return { mutateImportTimeEntries, isLoadingImportTimeEntries, importErrors, projectumProblem, setProjectumProblem };
};
