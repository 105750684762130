import { Icon, Stack, Text, useTheme } from '@fluentui/react';
import {
    //
    useCallback,
    useRef,
    forwardRef,
    // useEffect,
    // useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import { buildRoute } from '../../../helpers/routeHelpers';
// import { useTheme } from '../../../Themes/themeContext';
// import { useCardContainerContext } from './ContainerContext';
import { TSettingsCategory } from './Dashboard';
import { buildRoute } from '../../helpers/routeHelpers';

// type TTransition = {
//     top: number;
//     left: number;
//     height: number;
//     width: number;
//     // bottom: number;
//     // right: number;
//     // event: any;
// };

// const transitionTime = 4000;
// const transitionTime = 400;

// export type TSettingsCardProps = {
//     // iconName: string;
//     // // transition?: TTransition;
//     // title
//     iconName: string;
//     title: string;
//     link: string;
//     description?: string;
// };

export const SettingsCard = forwardRef<HTMLDivElement, TSettingsCategory>(({ iconName, link, title, shortDescription }, ref) => {
    // const {
    //     //
    //     containerRef,
    // } = useCardContainerContext();
    const theme = useTheme();
    const iconRef = useRef<HTMLDivElement>(null);
    const { pathname } = useLocation();
    const linkHandler = useNavigate();
    // const [runTransition, setRunTransition] = useState(false);
    // const [transitionProps, setTransitionProps] = useState<TTransition | null>(null);

    const handler = useCallback(
        (event: any) => {
            linkHandler(buildRoute(pathname, link));
            // if (containerRef && !runTransition) {
            //     const { top, left, height, width } = iconRef.current.getBoundingClientRect();
            //     setTransitionProps({
            //         top,
            //         left,
            //         height,
            //         width,
            //     });
            // }
        },
        [link, linkHandler, pathname],
        // [containerRef, runTransition],
    );

    // useEffect(() => {
    //     if (transitionProps && !runTransition) {
    //         const { top, left, height, width } = containerRef.getBoundingClientRect();
    //         const {
    //             //
    //             left: iconLeft,
    //             top: iconTop,
    //         } = transitionProps;
    //         setTransitionProps({
    //             top: top - iconTop,
    //             left: left - iconLeft - width / 2,
    //             height,
    //             width,
    //         });
    //         setRunTransition(true);
    //     }
    // }, [containerRef, linkHandler, runTransition, transitionProps]);

    // useEffect(() => {
    //     if (runTransition) {
    //         const timeout = setTimeout(() => {
    //             linkHandler(buildRoute(pathname, link));
    //             // goTo(Math.random())
    //         }, transitionTime);
    //         return () => {
    //             clearTimeout(timeout);
    //         };
    //     }
    // }, [link, linkHandler, pathname, runTransition]);

    return (
        <div
            ref={ref}
            style={{
                padding: 20,
                cursor: 'pointer',
                backgroundColor: theme.semanticColors.bodyBackground,
                borderRadius: 5,
            }}
            className="card"
            onClick={handler}
        >
            <Stack horizontalAlign="center">
                <Text styles={{ root: { color: 'inherit' } }} variant="xLarge">
                    {title}
                </Text>
                <Stack horizontalAlign="center">
                    <div
                        ref={iconRef}
                        // style={{
                        //     opacity: runTransition ? 0 : 1,
                        // }}
                    >
                        <Icon
                            className="settings-icon"
                            iconName={iconName}
                            styles={{
                                root: {
                                    fontSize: '4rem',
                                },
                            }}
                        />
                    </div>
                    {/* {transitionProps && (
                    <Icon
                        className="settings-icon"
                        iconName={iconName}
                        styles={{
                            root: {
                                backgroundColor: runTransition ? theme.semanticColors.bodyBackgroundHovered : 'unset',
                                transition: `all ${transitionTime}ms ease`,
                                fontSize: runTransition ? '2rem' : '4rem',

                                transform: 'translate3d(0px,0px,0px)',
                                // position: 'absolute',
                                position: 'fixed',
                                ...(transitionProps && {
                                    zIndex: 9999999999999,
                                    ...(runTransition && {
                                        transition: `all ${transitionTime}ms ease`,
                                        transform: `translate3d(${transitionProps.left}px,${transitionProps.top}px,0)`,
                                    }),
                                }),
                            },
                        }}
                    />
                )} */}
                </Stack>
                <Text styles={{ root: { color: 'inherit', textAlign: 'center' } }} variant="large">
                    {shortDescription}
                </Text>
            </Stack>
        </div>
    );
});
