import React, { useEffect, useState } from 'react';
import { useUiContext } from '../../components/Contexts/UiContext';
import { QuotesLoader } from '../../components/QuotesLoader';
import { AdminSyncADGroup } from './AdminSyncADGroup';
import { DataverseUserSync } from './Dataverse/DataverseUserSync';
import { ConfigDictionary, ConfigDictionaryAsString, validateForm } from './utils';
import { DefaultButton, PrimaryButton, Stack, Separator, mergeStyleSets, FontWeights, Checkbox } from '@fluentui/react';
import { AccessDenied } from '../../_pages/AccessDenied';
import { PageHeader, PageContent, ContentSection } from '../../components/LayoutElements';
import { useNavigate } from 'react-router';
import { ApiCalls } from '../../api/api';

function AdminSync() {
    const uiCtx = useUiContext();
    const navigate = useNavigate();

    const [userSyncConfig, setUserSyncConfig] = useState<Record<ConfigDictionary, string>>();
    const [loadingstate, setloadingstate] = useState<number>(0);
    const [fullsyncrunning, setfullsyncrunning] = useState<boolean>(false);
    const [formIsValid, setFormIsValid] = useState<boolean>();

    const isFormValid = () => {
        return validateForm(userSyncConfig);
    };

    useEffect(() => {
        if (userSyncConfig) {
            setFormIsValid(isFormValid);
        }
    }, [userSyncConfig]);

    useEffect(() => {
        uiCtx.timeApi.getAllConfigsAdmin().then(c => {
            console.log('Configs', c);
            let configs = {};

            for (let i = 0; i < c.length; i++) {
                configs[c[i].key] = c[i].value;
            }

            setUserSyncConfig({
                ADSync_Enabled: configs[ConfigDictionaryAsString.ADSYNC_ENABLED] ?? '',
                DataverseSync_Enabled: configs[ConfigDictionaryAsString.DATAVERSYNC_ENABLED] ?? '',
                ADSyncO365_GroupName: configs[ConfigDictionaryAsString.ADSYNC0365_GROUPNAME] ?? '',
                DataverseResourceEntity: configs[ConfigDictionaryAsString.RESOURCEENTITY] ?? '',
                DataverseColumnResourceId: configs[ConfigDictionaryAsString.RESOURCEID] ?? '',
                DataverseColumnResourceName: configs[ConfigDictionaryAsString.RESOURCENAME] ?? '',
                DataverseResourceColumnPrincipalname: configs[ConfigDictionaryAsString.PRINCIPALNAME] ?? '',
                DataverseResourceColumnEmail: configs[ConfigDictionaryAsString.RESOURCEEMAIL] ?? '',
                DataverseResourceColumnAzureId: configs[ConfigDictionaryAsString.RESOURCEAZUREID] ?? '',
            });

            setloadingstate(1);
        });
    }, []);

    const saveSyncSettings = () => {
        setloadingstate(2);

        const configValues: any = [];
        Object.keys(userSyncConfig).forEach(key => {
            configValues.push({ key: key, value: userSyncConfig[key] });
        });

        uiCtx.timeApi.setConfigList(configValues).then(() => {
            setloadingstate(3);
        });
    };

    if (loadingstate == 0) {
        return (
            <div className="centeredtext">
                <QuotesLoader loadingtext="Getting Configuration..." />
            </div>
        );
    }
    if (loadingstate == 2) {
        return (
            <div className="centeredtext">
                <h2>{'Saving...'}</h2>
            </div>
        );
    }
    if (loadingstate == 3) {
        return (
            <div className="centeredtext">
                <h4 style={{ marginBottom: '30px' }}>{'Synchronization Updated'}</h4>
                <DefaultButton
                    text={fullsyncrunning ? 'Synchronize started, you can close' : 'Start Full Sync'}
                    disabled={fullsyncrunning}
                    onClick={() => {
                        setfullsyncrunning(true);
                        ApiCalls.integrationAadSyncusersCreate();
                    }}
                    style={{ marginRight: 10 }}
                />
                <PrimaryButton
                    text="Close"
                    onClick={() => {
                        navigate('/admin');
                    }}
                />
            </div>
        );
    }

    return (
        <>
            <PageHeader title="User sync" icon="Group" />
            <PageContent>
                <ContentSection>
                    {uiCtx.user.originalUser.isAdmin ? (
                        <Stack tokens={{ childrenGap: 32 }}>
                            <Stack tokens={{ childrenGap: 16 }}>
                                <Stack>
                                    <AdminSyncADGroup config={userSyncConfig} setConfig={setUserSyncConfig} />
                                </Stack>
                                <Stack>
                                    <Checkbox
                                        onChange={(e, value) =>
                                            setUserSyncConfig(prevState => ({
                                                ...prevState,
                                                DataverseSync_Enabled: value ? 'true' : 'false',
                                            }))
                                        }
                                        checked={userSyncConfig.DataverseSync_Enabled === 'true'}
                                        label="Sync Users from azure active directory with Dataverse"
                                    />
                                    {userSyncConfig.DataverseSync_Enabled === 'true' && <DataverseUserSync config={userSyncConfig} setConfig={setUserSyncConfig} />}
                                </Stack>
                            </Stack>
                            <Separator>
                                <Stack tokens={{ childrenGap: 10 }}>
                                    <Stack.Item align="center">
                                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                                            <DefaultButton text="Cancel" onClick={() => navigate('/admin')} />
                                            <PrimaryButton disabled={!formIsValid} text={'Save settings'} onClick={() => saveSyncSettings()} />
                                        </Stack>
                                    </Stack.Item>
                                </Stack>
                            </Separator>
                        </Stack>
                    ) : (
                        <AccessDenied />
                    )}
                </ContentSection>
            </PageContent>
        </>
    );
}

export default AdminSync;

const styles = mergeStyleSets({
    callout: {
        padding: '20px 24px',
    },
    title: {
        marginBottom: 12,
        fontWeight: FontWeights.semilight,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 20,
    },
});
