// import { createTheme, ICustomizations } from '@fluentui/react';
// import { addVariants } from '@fluentui/scheme-utilities';
import { PartialTheme } from '@fluentui/react';
import { createTheme, ITheme } from '@fluentui/react';
const palette = {
    // themePrimary: '#6061aa',
    // themeLighterAlt: '#f7f7fc',
    // themeLighter: '#e1e1f2',
    // themeLight: '#c7c8e6',
    // themeTertiary: '#9797cd',
    // themeSecondary: '#6f70b5',
    // themeDarkAlt: '#56579a',
    // themeDark: '#494a82',
    // themeDarker: '#363660',

    // neutralLighterAlt: '#3B3A39',
    // neutralLighter: '#292828',
    // neutralLight: '#201F1F',
    // neutralQuaternaryAlt: '#dadada',
    // neutralQuaternary: '#d0d0d0',
    // neutralTertiaryAlt: '#c8c8c8',
    // neutralTertiary: '#b6b0b0',
    // neutralSecondary: '#9f9797',
    // neutralPrimaryAlt: '#877f7f',
    // neutralPrimary: '#282424',
    // neutralDark: '#585151',
    // black: '#fff',
    // white: '#201F1F',

    themePrimary: '#6061aa',
    themeLighterAlt: '#f7f7fc',
    themeLighter: '#e1e1f2',
    themeLight: '#c7c8e6',
    themeTertiary: '#9797cd',
    themeSecondary: '#6f70b5',
    themeDarkAlt: '#56579a',
    themeDark: '#494a82',
    themeDarker: '#363660',

    neutralLighterAlt: 'hsl(0, 1%, 24%)', // #2D2C2C / hsl(0, 1%, 17%)
    neutralLighter: 'hsl(0, 1%, 19%)', // #292828 / hsl(0, 1%, 16%)
    neutralLight: 'hsl(0, 2%, 16%)', // #201F1F / hsl(0, 2%, 12%)
    // neutralLight: '#201F1F',
    neutralQuaternaryAlt: '#2f2f2f',
    neutralQuaternary: '#373737',
    neutralTertiaryAlt: '#595959',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#201F1F',
};

export const TeamsDarkTheme: PartialTheme = createTheme({
    palette,
    // components,
    semanticColors: {
        // bodyBackground: "#f3f2f1",
        // bodyFrameBackground: "#f3f2f1",
        bodyStandoutBackground: palette.neutralLighter,
        // defaultStateBackground: "#3d3d3d",
        defaultStateBackground: palette.neutralLighterAlt,
        buttonBackground: 'transparent',
        primaryButtonText: palette.black,
        primaryButtonTextHovered: palette.black,
        primaryButtonTextPressed: palette.black,
        variantBorderHovered: '#3B3A39',
        bodyText: '#f7f7fc',
        warningBackground: '#3B3A39',
        // variantBorder: "#3B3A39",
        // buttonBackgroundHovered: '#bdbdbd',
        // buttonBackgroundPressed: '#a7a7a7',

        // buttonText: '#252424',
        // buttonTextPressed: '#252424',
        // buttonTextHovered: '#252424',

        // buttonBorder: '#bdbdbd',
    },
});
