import React, { useEffect, useState, Fragment } from 'react';
import TimeRow from '../../Model/TimePeriodRowM';
import TimeRowGroup from '../../Model/TimePeriodGroupM';
import '../../Styles/TimePeriod.scss';
import { TimeGridMode } from '../../Model/TimeGridMode';
import { SortTimeRows, GetTotalFromTimeRowInDayArray } from '../Utils';
import EDays from '../../Model/EDays';
import { useUiContext } from '../Contexts/UiContext';
import PeriodDay from '../../Model/PeriodDay';
import { ScrollablePane, Stack, Text, FontIcon } from '@fluentui/react';
import { TimeState } from '../../Model/State';
import WorkType from '../../Model/NewModels/WorkType';
import { ValidateResponse } from '../../Validation/Validator';
import TimePeriodGroupWeek from './TimePeriodGroupWeek';
import { DisplayCell } from './DisplayCell';
import { IconButton } from '@fluentui/react';

interface IProps {
    times: TimeRow[];
    onCellWeekUpdated: Function;
    mode: TimeGridMode;
    pinTime: Function;
    deleteTime: Function;
    today: Date;
    submitted: boolean;
    reportPeriodId: string;
    search: number;
    periodIsClosed: boolean;
    startDate: Date;
    endDate: Date;
    deadlineDate: Date;
    timesheetstate: TimeState;
    timesheetmessage: string;
    updateWorkType: (row: TimeRow, workType: WorkType, changeCompleted: Function) => void;
    validationErrors: ValidateResponse[];
    copyFromLastWeek: () => void;
}

export const TimePeriodWeekOverview: React.FC<IProps> = props => {
    const uiCtx = useUiContext();

    const [splitBarMouseDown, setSplitBarMouseDown] = useState<boolean>(false);
    const [splitBarStartPos, setSplitBarStartPos] = useState({ mouseStart: 0, barLastPos: 0 });
    const [timerowgroups, setTimerowsgroups] = useState<TimeRowGroup[]>([]);
    const [expanded, setExpanded] = useState<boolean>(true);
    const [groupiconrotation, setGroupiconrotation] = useState<string>('rotate(0deg)');
    const [timeLowerLimitConfig, setTimeLowerLimitConfig] = useState<string>('');
    const [splitterbarposition, setsplitterbarposition] = useState<number>(400);

    const submittedOrPeriodClosed = props.submitted ? true : props.periodIsClosed ? true : false;

    const [totalarray, setTotalarray] = useState<any>(null);

    const [searchActivate, setSearchActivate] = React.useState<number>(0);

    const [perioddays, setPerioddays] = useState<any[]>([]);
    const [dateformat, setdateformat] = useState<string>();
    const [headersNameOrDate, setheadersNameOrDate] = useState<number>(0);
    const [loadingcomplete, setLoadingcomplete] = useState<boolean>(true);

    // const deadlineNameText = uiCtx.allConfig.find(_ => _.key.toLowerCase() === 'perioddeadlinealternatetext')?.value?.toString() ?? 'Deadline';

    useEffect(() => {
        const dformat = uiCtx.allConfig.find(_ => _.key.toLowerCase() === 'shortdateformat');
        console.log('dformat', dformat);
        if (dformat !== undefined && dformat != null && dformat.value.length >= 2) {
            setdateformat(dformat.value);
        } else {
            setdateformat('DD MMM');
        }
    }, []);

    useEffect(() => {
        if (perioddays.length === 0) {
            return;
        }
        const sortedTasks: TimeRow[] = SortTimeRows(props.times);
        let timeTaskRowsCount = 0;
        const groups: TimeRowGroup[] = [];
        let group: TimeRowGroup = null;

        for (const element of sortedTasks) {
            const groupExists = groups.find(_ => _.GroupId === element.projectId);

            if (!groupExists) {
                const showProjectName = element.projectName + `${element.projectNumber ? ` (${element.projectNumber})` : ''}`;

                group = new TimeRowGroup(element.projectId, showProjectName, element.projectSource, [], true);
                groups.push(group);
                group.TimeRows.push(element);
            } else {
                const existingGroup = groups.find(_ => _.GroupId === element.projectId);
                existingGroup.TimeRows.push(element);
            }
            timeTaskRowsCount++;
        }

        setTimerowsgroups(prevState => {
            if (prevState.length > 0) {
                const previousGroups = prevState.filter(group => groups.some(ps => ps.GroupId === group.GroupId));
                const newGroups = groups.map(newGroup => {
                    const existingGroup = previousGroups.find(previousGroup => previousGroup.GroupId === newGroup.GroupId);
                    if (!existingGroup) {
                        return newGroup;
                    }
                    if (newGroup.TimeRows.length !== existingGroup.TimeRows.length) {
                        return { ...newGroup, Expanded: true };
                    }
                    return { ...newGroup, Expanded: existingGroup.Expanded };
                });

                return newGroups;
            } else {
                // Here you can set the max rows before starting as collapsed
                if (groups.length + timeTaskRowsCount > 20) {
                    setExpanded(false);
                }
                return groups;
            }
        });

        const mtotalarray = {};

        for (let pi = 0; pi < perioddays.length; pi++) {
            const pdate: number = perioddays[pi].id;
            const oldval = mtotalarray[pdate] || 0;
            mtotalarray[pdate] = oldval + GetTotalFromTimeRowInDayArray(sortedTasks, perioddays[pi].date);
        }
        setTotalarray(mtotalarray);
    }, [props.times, perioddays]);

    useEffect(() => {
        const periodDaysArr: PeriodDay[] = [];
        const currentday: Date = new Date(props.startDate);
        let index: number = 0;
        while (currentday <= props.endDate) {
            const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            const dayName = days[currentday.getDay()];
            periodDaysArr.push({ id: index, date: new Date(currentday), name: dayName, day: EDays[currentday.getDay()] });
            index++;
            currentday.setDate(currentday.getDate() + 1);
        }
        setPerioddays(periodDaysArr);
    }, [props.startDate, props.endDate]);

    useEffect(() => {
        if (props.search > 0) {
            setSearchActivate(props.search);
            setExpanded(false);
        }
    }, [props.search]);

    useEffect(() => {
        uiCtx.timeApi.getConfigByKey('TimeLowerLimitConfig').then(result => {
            if (result != null) {
                setTimeLowerLimitConfig(result.value);
            }
        });
        // console.log(uiCtx.user);
        setsplitterbarposition(parseInt(uiCtx.splitBarPos));
    }, []);

    useEffect(() => {
        setTimerowsgroups(groups => {
            return groups.map(g => ({ ...g, Expanded: expanded }));
        });
    }, [expanded]);

    // const onCellUpdated = (time: TimeRow, day: PeriodDay, te: TimeEntry, value: number, comment: string, previousValue: number, saveSuccess: Function): void => {
    //     props.onCellUpdated(time, day, te, value, comment, previousValue, saveSuccess);
    //     // let diffValue = value - previousValue;
    //     // let newValue = totalarray[day.id] + diffValue;
    //     // setTotalarray((ta) => {
    //     //     return { ...ta, [day.id]: newValue };
    //     // });
    // };

    const splitbarMouseDownEvent = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        if (!splitBarMouseDown) {
            setSplitBarMouseDown(true);
            setSplitBarStartPos({ mouseStart: event.pageX, barLastPos: uiCtx.splitBarPos });
            setsplitterbarposition(parseInt(uiCtx.splitBarPos));
        }
    };

    const splitbarGridMouseUpEvent = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setSplitBarMouseDown(false);
        uiCtx.setSplitBarPos(splitterbarposition);
    };

    const splitbarGridMouseMoveEvent = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (splitBarMouseDown) {
            const xmove = splitBarStartPos.barLastPos - (splitBarStartPos.mouseStart - event.pageX);
            if (xmove > 75 && xmove < 2000 && xmove !== splitterbarposition && xmove % 2 === 0) {
                setsplitterbarposition(xmove);
            }
        }
    };

    const groupGollapsedExpanded = (ev: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>): void => {
        if (expanded) {
            setGroupiconrotation('rotate(-90deg)');
        } else {
            setGroupiconrotation('rotate(0deg)');
        }
        setExpanded(e => !e);
    };

    const PinMyTime = (time: TimeRow, pinned: boolean) => {
        props.pinTime(time, pinned, props.mode);
    };

    const DeleteMyTime = (time: TimeRow) => {
        props.deleteTime(time, perioddays);
    };

    return (
        <div className="timeperiod" onMouseUp={splitbarGridMouseUpEvent} onMouseMove={splitbarGridMouseMoveEvent}>
            <ScrollablePane>
                <div className="tableWrapper">
                    <table>
                        <thead>
                            <tr className={`noselect topHeadRow`}>
                                <th className="headerContainer noselect" style={uiCtx.workTypeEnabled ? {} : {}}>
                                    <Stack horizontal className="headerCell" styles={{ root: { flexWrap: 'nowrap' } }}>
                                        <div className="expander" onClick={groupGollapsedExpanded}>
                                            <div className="expanderInner">
                                                <FontIcon
                                                    iconName="ChevronDown"
                                                    style={{
                                                        transform: groupiconrotation,
                                                        transition: 'transform .2s ease 0s',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <Text className="groupName textOverflow">All Tasks</Text>
                                        <IconButton
                                            menuIconProps={{ iconName: 'More' }}
                                            menuProps={
                                                uiCtx.showPlanned && uiCtx.gridInputType !== 'percent'
                                                    ? {
                                                          items: [
                                                              {
                                                                  key: 'copyFromLastWeek',
                                                                  text: 'Copy from last week',
                                                                  iconProps: { iconName: 'Copy' },
                                                                  disabled: submittedOrPeriodClosed,
                                                                  onClick: () => props.copyFromLastWeek(),
                                                              },
                                                              {
                                                                  key: 'plannedActive',
                                                                  text: 'Planned',
                                                                  iconProps: { iconName: uiCtx.plannedActive ? 'CheckboxCompositeReversed' : 'Checkbox' },
                                                                  onClick: () => uiCtx.setPlannedActive(!uiCtx.plannedActive),
                                                              },
                                                              {
                                                                  key: 'actualActive',
                                                                  text: 'Actual',
                                                                  iconProps: { iconName: uiCtx.actualActive ? 'CheckboxCompositeReversed' : 'Checkbox' },
                                                                  onClick: () => uiCtx.setActualActive(!uiCtx.actualActive),
                                                              },
                                                              {
                                                                  key: 'remainingActive',
                                                                  text: 'Remaining',
                                                                  iconProps: { iconName: uiCtx.remainingActive ? 'CheckboxCompositeReversed' : 'Checkbox' },
                                                                  onClick: () => uiCtx.setRemainingActive(!uiCtx.remainingActive),
                                                              },
                                                          ],

                                                          // By default, the menu will be focused when it opens. Uncomment the next line to prevent this.
                                                          // shouldFocusOnMount: false
                                                      }
                                                    : {
                                                          items: [
                                                              {
                                                                  key: 'copyFromLastWeek',
                                                                  text: 'Copy from last week',
                                                                  iconProps: { iconName: 'Copy' },
                                                                  disabled: submittedOrPeriodClosed,
                                                                  onClick: () => props.copyFromLastWeek(),
                                                              },
                                                          ],

                                                          // By default, the menu will be focused when it opens. Uncomment the next line to prevent this.
                                                          // shouldFocusOnMount: false
                                                      }
                                            }
                                        ></IconButton>
                                        {uiCtx.workTypeEnabled && (
                                            <div className="adjustwidthsplitter" style={{ position: 'absolute', right: 0, height: '100%' }}>
                                                <div onMouseDown={splitbarMouseDownEvent}>
                                                    <div className="splitbar"></div>
                                                </div>
                                            </div>
                                        )}
                                    </Stack>
                                </th>
                                {uiCtx.workTypeEnabled && (
                                    <th className="headerContainer noselect" style={uiCtx.workTypeEnabled ? {} : {}}>
                                        <Stack
                                            horizontal
                                            className="headerCell"
                                            verticalAlign="center"
                                            horizontalAlign="center"
                                            styles={{ root: { flexWrap: 'nowrap', height: '100%', position: 'relative' } }}
                                        >
                                            <Text as="div" className="headerCell workType" style={{ flexGrow: 1 }}>
                                                Work Type
                                            </Text>
                                            <div className="adjustwidthsplitter" style={{ position: 'absolute', right: 0, height: '100%' }}>
                                                <div onMouseDown={splitbarMouseDownEvent}>
                                                    <div className="splitbar"></div>
                                                </div>
                                            </div>
                                        </Stack>
                                    </th>
                                )}
                                {uiCtx.plannedActive && uiCtx.showPlanned && uiCtx.gridInputType !== 'percent' && (
                                    <th className={`headerContainer noselect`}>
                                        <Text as="div" className="headerCell day">
                                            Planned Work
                                        </Text>
                                    </th>
                                )}
                                {uiCtx.actualActive && uiCtx.showPlanned && uiCtx.gridInputType !== 'percent' && (
                                    <th className={`headerContainer noselect`}>
                                        <Text as="div" className="headerCell day">
                                            Actual Work
                                        </Text>
                                    </th>
                                )}
                                {uiCtx.remainingActive && uiCtx.showPlanned && uiCtx.gridInputType !== 'percent' && (
                                    <th className={`headerContainer noselect`}>
                                        <Text as="div" className="headerCell day">
                                            Remaining Work
                                        </Text>
                                    </th>
                                )}
                                <th className="headerContainer noselect">
                                    <Text as="div" className="headerCell rowTotal">
                                        {uiCtx.gridInputType === 'percent' ? '% of Work' : 'Work'}
                                    </Text>
                                </th>
                                {/* {
                                    uiCtx.plannedActive &&
                                    <th className={`headerContainer noselect`}>
                                        <Text as="div" className="headerCell day">
                                            Planned Work
                                        </Text>
                                    </th>
                                }
                                {
                                    uiCtx.actualActive &&
                                    <th className={`headerContainer noselect`}>
                                        <Text as="div" className="headerCell day">
                                            Actual Work
                                        </Text>
                                    </th>
                                }
                                {
                                    uiCtx.remainingActive &&
                                    <th className={`headerContainer noselect`}>
                                        <Text as="div" className="headerCell day">
                                            Remaining Work
                                        </Text>
                                    </th>
                                } */}
                            </tr>
                        </thead>
                        <tbody>
                            {timerowgroups.map((group: TimeRowGroup) => {
                                return (
                                    <Fragment key={group.GroupId}>
                                        <TimePeriodGroupWeek
                                            group={group}
                                            today={props.today}
                                            expanded={group.Expanded}
                                            onCellWeekUpdated={props.onCellWeekUpdated}
                                            mode={props.mode}
                                            pinMyTime={PinMyTime}
                                            deleteMyTime={DeleteMyTime}
                                            submitted={props.submitted}
                                            periodIsClosed={props.periodIsClosed}
                                            timeLowerLimitConfig={timeLowerLimitConfig}
                                            startDate={props.startDate}
                                            endDate={props.endDate}
                                            deadlineDate={props.deadlineDate}
                                            perioddays={perioddays}
                                            reportPeriodId={props.reportPeriodId}
                                            updateWorkType={props.updateWorkType}
                                        />
                                        <tr className="rowSpacer" />
                                        {/* {index !== timerowgroups.length - 1 && <tr className="rowSpacer" />} */}
                                    </Fragment>
                                );
                            })}
                        </tbody>
                        <tfoot>
                            <tr className={`noselect totalRow`}>
                                <td
                                    className="rowHeader noselect"
                                    style={
                                        uiCtx.workTypeEnabled
                                            ? { width: splitterbarposition }
                                            : { width: splitterbarposition > 800 ? splitterbarposition - 400 : splitterbarposition / 2 }
                                    }
                                >
                                    <Stack horizontal className="rowHeaderContainer">
                                        <div className="expander" />
                                        <Text className="groupName">Total</Text>
                                    </Stack>
                                </td>
                                <td
                                    hidden={uiCtx.workTypeEnabled}
                                    className="cellContainer worktypeContainer noselect"
                                    style={
                                        uiCtx.workTypeEnabled
                                            ? { width: splitterbarposition }
                                            : { width: splitterbarposition > 800 ? 400 : splitterbarposition / 2 }
                                    }
                                >
                                    <Stack horizontal className="cell noborder">
                                        <div className="expander" />
                                        <Text className="groupName">{/* Empty for work type */}</Text>
                                    </Stack>
                                </td>
                                {uiCtx.plannedActive && uiCtx.showPlanned && uiCtx.gridInputType !== 'percent' && (
                                    <td className={`noselect`}>
                                        <div className="totalCell">
                                            <DisplayCell value={0} periodDays={perioddays} />
                                        </div>
                                    </td>
                                )}
                                {uiCtx.actualActive && uiCtx.showPlanned && uiCtx.gridInputType !== 'percent' && (
                                    <td className={`noselect`}>
                                        <div className="totalCell">
                                            <DisplayCell value={0} periodDays={perioddays} />
                                        </div>
                                    </td>
                                )}
                                {uiCtx.remainingActive && uiCtx.showPlanned && uiCtx.gridInputType !== 'percent' && (
                                    <td className={`noselect`}>
                                        <div className="totalCell">
                                            <DisplayCell value={0} periodDays={perioddays} />
                                        </div>
                                    </td>
                                )}
                                <td className={`noselect`}>
                                    <div className="totalCell">
                                        {totalarray && (
                                            <DisplayCell
                                                value={Number(Object.values(totalarray).reduce((acc: number, cur: number) => acc + cur, 0))}
                                                periodDays={perioddays}
                                            />
                                        )}
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </ScrollablePane>
        </div>
    );
};
