import React from 'react';
import { useMemo } from 'react';
import { TaskRow } from './TaskRow';
import { makeStyles, useTheme } from '@fluentui/react';
import { IconButton, Text, Stack, Persona, PersonaSize, AnimationStyles } from '@fluentui/react';
import { valueStyles } from '../../../../sharedStyles';
import { useMobile } from '../../../../useMobile';
import { mobileConstants } from '../../../../mobileConstants';
import { IMyTimeViewProject } from '../../../../mobileTypes';
import { useMobileContext } from '../../../../MobileContext';
import { useUiContext } from '../../../../../components/Contexts/UiContext';
import { formatNumberToDisplay, formatNumberToDisplayPercent } from '../../../../mobileUtils';

interface IProps extends IMyTimeViewProject {}

export const ProjectRow: React.FC<IProps> = props => {
    const mobCtx = useMobileContext();
    const theme = useTheme();

    const classes = getStyles();
    const totalValue = useMobile(state => state.totalValues?.[props.dateString]?.[props.projectId]?.value);

    const uiCtx = useUiContext();

    const value = useMemo(() => {
        if (!totalValue || Number.isNaN(Number(totalValue))) {
            return '';
        }

        if (mobCtx.showAsPercent) {
            return formatNumberToDisplayPercent(totalValue, mobCtx.selectedPeriodDays, uiCtx);
            // const procent = convertHoursToProcent(Number(totalValue), mobCtx.selectedPeriodDays.length, getCapacityPrDay(mobCtx.selectedPeriodDays, uiCtx));
            // return `${MathToFixed(parseNumber(procent.toString().replace(/\,/g, '.')), 1)}`;
        } else {
            return formatNumberToDisplay(totalValue, uiCtx);
            // return MathToFixed(parseNumber(totalValue.toString().replace(/\,/g, '.')), 2)
        }
    }, [totalValue, mobCtx.selectedPeriodDays, uiCtx.gridInputType]);

    return (
        <div className={classes.div}>
            <Stack
                className={`${classes.root} ${mobCtx.isPeriodView ? classes.periodViewTop : classes.top}`}
                // className={classes.root}
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: theme.spacing.s1 }}
            >
                <Stack
                    grow
                    horizontal
                    verticalAlign="center"
                    tokens={{ childrenGap: theme.spacing.s2 }}
                    className={classes.container}
                    onClick={() => useMobile.getState().setExpanded({ dateString: props.dateString, projectId: props.projectId, expandProject: !props.isExpanded })}
                >
                    <IconButton iconProps={{ iconName: 'ChevronRight' }} styles={{ root: classes.icon }} className={props.isExpanded ? classes.iconOpen : ''} />

                    <Persona
                        text={props.projectName}
                        size={PersonaSize.size32}
                        styles={{
                            root: { '.ms-Persona-initials': { borderRadius: 4 } },
                            details: { '.ms-TooltipHost': { fontWeight: 600 } },
                        }}
                    />
                </Stack>

                <Text className={classes.value}>
                    {`${value}${mobCtx.showAsPercent && value ? ' %' : ''}`}
                    {/* { totalValue || "" } */}
                </Text>
            </Stack>

            {props.isExpanded && props.tasks.map(task => <TaskRow key={`${task.taskKey}_${task.dateString}`} {...task} />)}
        </div>
    );
};

const getStyles = makeStyles(theme => ({
    root: [
        'projectRow',
        {
            ...AnimationStyles.fadeIn200,
            // minHeight: mobileConstants.gridRowHeight,
            height: mobileConstants.gridRowHeight,
            // maxHeight: mobileConstants.gridRowHeight,
            position: 'sticky',
            // top: mobileConstants.gridRowHeight * 2,
            backgroundColor: theme.semanticColors.bodyFrameBackground,
            zIndex: 40,
            paddingLeft: 4,
            paddingRight: 16,
        },
    ],
    top: {
        top: mobileConstants.gridRowHeight * 2,
    },
    periodViewTop: {
        top: mobileConstants.gridRowHeight,
    },
    div: {
        transform: 'translate3d(0,0,0)',
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
    },
    value: {
        ...valueStyles,
        fontWeight: 500,
    },
    container: {
        overflowX: 'hidden',
    },
    icon: {
        width: 18,
        i: {
            fontSize: 12,
            color: theme.semanticColors.disabledBodySubtext,
            transform: 'rotate(0deg)',
            transition: '100ms',
        },
    },
    iconOpen: {
        i: {
            transform: 'rotate(90deg)',
        },
    },
}));
